import React from 'react';
import cn from 'classnames';

import './page-container.scss';

const PageContainer = ({ children, className }) => {
    return <div className={cn('page-container', className)}>{children}</div>;
};

export default PageContainer;
