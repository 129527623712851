import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../../../../Langs';
import { getResponseErrorMessage } from '../../../../../../Functions/utils';

import { useAddFlowMutation } from '../../../../../../redux/api/flowsApi';

import Label from '../../../../../Overall/Forms/Inputs/Label';
import SelectInput from '../../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../../Overall/Forms/Inputs/TextInput';
import Btn, { AddBtn } from '../../../../../Overall/UI/Btn/Btn';

import '../flow-rules.scss';

const FlowValueSelectInput = ({
    values,
    workspaceId,
    onChange,
    flowsList,
    flowValue,
    handleFlowChange,
    setIsProcessing
}) => {
    const txt = Langs[global.lng];

    const [isRenameMode, setIsRenameMode] = useState(false);
    const [flowName, setFlowName] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const nameInputRef = useRef(null);

    const onNameChange = e => {
        if (!isTouched) setIsTouched(true);
        setFlowName(e.target.value);
    };

    const [addFlow, { data: addedFlow, isSuccess: isAddFlowSuccess, isError: isAddFlowError, error: addFlowError }] =
        useAddFlowMutation();

    useEffect(() => {
        if (isAddFlowSuccess) {
            toast.success(txt.toasts.savedAsFlow);
            setIsRenameMode(false);
            onChange(addedFlow.id, 'flowValue');
            setIsProcessing(false);
        }
    }, [isAddFlowSuccess]);

    useEffect(() => {
        if (isAddFlowError) {
            toast.error(getResponseErrorMessage(addFlowError.data, txt.toasts.saveError));
            setIsProcessing(false);
        }
    }, [isAddFlowError]);

    const onConfirm = async () => {
        if (!flowName) {
            if (!isTouched) setIsTouched(true);
            return;
        }

        setIsProcessing(true);

        addFlow({ data: { ...values, workspaceId, name: flowName, campaigns: [], campaignsToAdd: [] } });
    };

    useEffect(() => {
        if (isRenameMode) nameInputRef.current?.focus();
    }, [isRenameMode]);

    return (
        <div className="flow-value-select-input">
            <Label label={txt.labels.rotationFlow} hint={txt.hints.rotationFlow} />
            {!isRenameMode ? (
                <SelectInput
                    dataTest="flow-rules_flow-value-select-input"
                    items={flowsList}
                    value={flowValue}
                    onChange={handleFlowChange}
                    className="flow-rules__rotation-field"
                    isSearchable
                />
            ) : (
                <TextInput
                    dataTest="flow-rules_flow_name-input"
                    ref={nameInputRef}
                    value={flowName}
                    placeholder={txt.placeholders.typeFlowName}
                    onChange={e => onNameChange(e)}
                    error={isTouched && !flowName}
                    className="flow-rules__new-flow-name"
                />
            )}

            {flowValue === 'custom' ? (
                <div className="flow-rules_buttons-block">
                    {!isRenameMode ? (
                        <AddBtn
                            dataTest="flow-rules_save-as-flow-btn"
                            className="flow-rules__save-as-flow-btn"
                            onClick={() => setIsRenameMode(true)}
                        >
                            Save as flow
                        </AddBtn>
                    ) : (
                        <>
                            <Btn
                                onClick={() => {
                                    setIsRenameMode(false);
                                    setFlowName('');
                                    setIsTouched(false);
                                }}
                            >
                                {txt.buttons.cancel}
                            </Btn>
                            <Btn type="filled" className="flow-rules__save-btn" onClick={onConfirm}>
                                {txt.buttons.save}
                            </Btn>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default FlowValueSelectInput;
