import React, { useEffect, useRef } from 'react';

import Langs from '../../../Langs';
import { formatCardNumber } from '../../../Functions/utils';
import {
    CARD_SYMBOLS_MIN_QUANTITY,
    DATE_SYMBOLS_QUANTITY,
    CVV_SYMBOLS_QUANTITY,
    CREDIT_CARD_RULE
} from '../../../Constants';

import JustAlert from '../../../components/just-alert/just-alert';
import TextInput from '../Forms/Inputs/TextInput';
import CheckBoxInput from '../Forms/Inputs/CheckBoxInput';
import useFocus from '../../../Functions/hooks/useFocus';

import Visa from '../../../../../media/images/paymentMethods/visa.svg';
import MasterCard from '../../../../../media/images/paymentMethods/mastercard.svg';
import AmericanExpress from '../../../../../media/images/paymentMethods/americanexpress.svg';
import Discover from '../../../../../media/images/paymentMethods/discover.svg';
import cvc from '../../../../../media/images/paymentMethods/cvc.svg';

import './AddCard.scss';

const icons = {
    Visa,
    MasterCard,
    AmericanExpress,
    Discover,
    cvc
};

const getCreditCardTypes = card => {
    const creditCards = Object.entries(CREDIT_CARD_RULE);
    const filteredCreditCards = creditCards.reduce((acc, [type, re]) => (re.test(card) ? [...acc, type] : acc), []);
    if (!filteredCreditCards.length) {
        return creditCards.map(([type]) => type);
    } else {
        return filteredCreditCards;
    }
};

const AddCard = ({
    cardInfo,
    onCardNumberChange,
    onCardDateChange,
    onCardCodeChange,
    onCardDateBlur,
    onCardSaveChange,
    setResponseError,
    errors = {},
    isTouch,
    autoFocus,
    isAddSaveCheckBox,
    disabled,
    isHiddenAlerts
}) => {
    const txt = Langs[global.lng];
    const errorList = Object.values(errors).filter(error => !!error);

    const creditCardTypes = getCreditCardTypes(cardInfo.cardNumber);
    const creditCardType = creditCardTypes.length === 1 ? creditCardTypes[0] : 'unknown';
    const creditCardsMatchDefaultCVCSymbols = [txt.texts.americanExpress];
    const currentCVVSymbolsQuantity = creditCardsMatchDefaultCVCSymbols.includes(creditCardType)
        ? CVV_SYMBOLS_QUANTITY.DEFAULT
        : CVV_SYMBOLS_QUANTITY.COMMON;

    const [cardNumberRef, setCardNumberFocus] = useFocus();
    const [cardCodeRef, setCardCodeFocus] = useFocus();
    const inputDateRef = useRef(null);

    const getErrorsText = () => {
        if (errorList.length === 1) {
            return errorList[0];
        }

        return (
            <ul className="add-card__alert-list">
                {errorList.map((el, idx) => (
                    <li key={el + idx}>{el}</li>
                ))}
            </ul>
        );
    };

    useEffect(() => {
        setTimeout(setCardNumberFocus, 0);
    }, []);

    useEffect(() => {
        if (cardInfo.cardDate.length === 5 && inputDateRef.current === document.activeElement) setCardCodeFocus();
        if (errors?.responseError) setResponseError({});
    }, [cardInfo]);

    return (
        <div className="add-card">
            <p className="add-card__title">{txt.texts.cardInformation}</p>

            <div className="add-card__card-number">
                <TextInput
                    dataTest="upd-payment-method-modal_card-number-input"
                    placeholder="1234 1234 1234 1234"
                    ref={cardNumberRef}
                    value={formatCardNumber(cardInfo.cardNumber, false, creditCardType)}
                    onChange={onCardNumberChange}
                    autoFocus={autoFocus}
                    error={isTouch && cardInfo.cardNumber.length < CARD_SYMBOLS_MIN_QUANTITY}
                    disabled={disabled}
                />
                <div className="add-card__card-types">
                    {creditCardTypes.map(card =>
                        icons[card] ? (
                            <div key={card} className="add-card__card-type">
                                <img src={icons[card]} width="30" height="20" alt={card} />
                            </div>
                        ) : null
                    )}
                </div>
            </div>

            <div className="add-card__card-info">
                <TextInput
                    dataTest="upd-payment-method-modal_card-number-input"
                    placeholder="MM / YY"
                    ref={inputDateRef}
                    value={cardInfo.cardDate}
                    onChange={onCardDateChange}
                    error={isTouch && (cardInfo.cardDate.length < DATE_SYMBOLS_QUANTITY || !!errors.cardDate)}
                    onBlur={onCardDateBlur}
                    disabled={disabled}
                />
                <div className="add-card__card-cvc">
                    <TextInput
                        dataTest="upd-payment-method-modal_card-number-input"
                        placeholder="CVC"
                        ref={cardCodeRef}
                        value={cardInfo.cardCode}
                        onChange={onCardCodeChange}
                        error={isTouch && cardInfo.cardCode.length < currentCVVSymbolsQuantity}
                        disabled={disabled}
                    />
                    <div className="add-card__card-type">
                        <img src={icons.cvc} width="30" height="20" alt="cvc" />
                    </div>
                </div>
            </div>

            {isAddSaveCheckBox && (
                <CheckBoxInput
                    dataTest="login_remember-checkbox"
                    label={txt.labels.securelySaveCard}
                    className="add-card__remember"
                    value={cardInfo.isSave}
                    onChange={onCardSaveChange}
                    disabled={disabled}
                />
            )}

            {isTouch && errorList.length > 0 && !isHiddenAlerts && (
                <JustAlert className="mb5" type="danger">
                    {getErrorsText()}
                </JustAlert>
            )}
        </div>
    );
};

export default AddCard;
