/* eslint-disable no-console */
import React, { useMemo, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import produce from 'immer';

import Langs from '../../../Langs';
import useSearch from '../../../Functions/hooks/useNewSearch';
import { CHAPTER_REPORTS } from '../../../Constants';
import { clone, getResponseErrorMessage } from '../../../Functions/utils';
import { hideModal } from '../../../redux/operations';

import { useChangeStorageMutation } from '../../../redux/api/storeApi';

import { storage as storageTemplate } from '../../../redux/initialState/storage';

import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Btn from '../../Overall/UI/Btn/Btn';
import ColumnsListV from './ColumnsListV';
import Loader from '../../Loader';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from([...list]);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const ColumnItem = React.forwardRef((props, ref) => (
    <div ref={ref} {...props} className="columns-order__item">
        {props.children}
    </div>
));

const Column = ({ column, index }) => {
    const txt = Langs[global.lng];

    return (
        <Draggable draggableId={column.key} index={index}>
            {provided => (
                <ColumnItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {column.label}
                    {column.isIntegrationCell ? ` (${txt.labels.integration})` : ''}
                </ColumnItem>
            )}
        </Draggable>
    );
};

const ColumnsList = React.memo(({ columns }) => {
    return columns.map((column, index) => <Column column={column} index={index} key={column.key} />);
});

const ColumnsSettings = ({
    chapter,
    columns,
    isColoredRows: isColored,
    isVerticalBorders: isVertical,
    storage,
    customSave,
    onClose
}) => {
    const txt = Langs[global.lng];

    const [isLoading, setIsLoading] = useState(false);

    const [
        changeStorage,
        { isSuccess: ischangeStorageSuccess, isError: isChangeStorageError, error: changeStorageError }
    ] = useChangeStorageMutation();

    useEffect(() => {
        if (ischangeStorageSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [ischangeStorageSuccess]);

    useEffect(() => {
        if (isChangeStorageError) {
            toast.error(getResponseErrorMessage(changeStorageError.data, txt.toasts.saveError));
            setIsLoading(false);
        }
    }, [isChangeStorageError]);

    const [allColumns, setAllColumns] = useState(columns);
    const [orderedColumns, setOrderedColumns] = useState(
        columns.filter(column => !column.important).sort((a, b) => a.order - b.order)
    );

    const [isColoredRows, setIsColoredRows] = useState(isColored);
    const [isVerticalBorders, setVerticalBorders] = useState(isVertical);

    const filteredColumns = orderedColumns.filter(column => !column.isHide);
    const isAllColumnsVisible = useMemo(() => allColumns?.every(({ isHide }) => !isHide), [allColumns]);

    const refreshOrderedColumns = newColumns =>
        setOrderedColumns(newColumns.filter(column => !column.important).sort((a, b) => a.order - b.order));
    const updateOrder = items => items.map((el, i) => ({ ...el, order: i + 1 }));

    const onDragEnd = ({ destination, source, draggableId }) => {
        if (!destination || destination.index === source.index) return;

        const shift = source.index - destination.index;
        const index = orderedColumns.findIndex(el => el.key === draggableId);

        let hiddenIndex = 0;
        let hiddenShift = 0;
        for (let i = 1; hiddenIndex < Math.abs(shift) || i === 1000; i++) {
            if (shift > 0) {
                if (!orderedColumns[index - i].isHide) hiddenIndex++;
                hiddenShift = i;
            }

            if (shift < 0) {
                if (!orderedColumns[index + i].isHide) hiddenIndex++;
                hiddenShift = i;
            }
        }

        const newOrder = reorder(orderedColumns, index, index - (shift > 0 ? hiddenShift : -hiddenShift));

        setOrderedColumns([...updateOrder(newOrder)].sort((a, b) => a.order - b.order));
    };

    const onColumnVisbilityChange = (checked, field) => {
        setAllColumns(oldColumns => {
            const index = oldColumns.findIndex(el => el === field);

            const newColumns = produce(oldColumns, draft => {
                draft[index].isHide = !checked;
            });

            refreshOrderedColumns(newColumns);

            return newColumns;
        });
    };

    const toggleSelectAll = ({ target: { checked } }) => {
        setAllColumns(oldColumns => {
            const newColumns = produce(oldColumns, draft => {
                draft.forEach(el => {
                    el.isHide = el.important || el.disabled ? el.isHide : !checked;
                });
            });

            refreshOrderedColumns(newColumns);

            return newColumns;
        });
    };

    const resetOrderedColumns = cols => {
        const key = CHAPTER_REPORTS.includes(chapter) ? 'chapter_reports' : chapter;

        const columnsTemplate = storageTemplate[key].columns;

        const newCols = cols.map(column => ({
            ...column,
            order: columnsTemplate.find(el => el.key === column.key)?.order ?? 10000
        }));

        refreshOrderedColumns(newCols);
    };

    const toggleResetToDefaults = () => {
        setAllColumns(oldColumns => {
            const newColumns = produce(oldColumns, draft => {
                draft.forEach(el => {
                    if (el.important || el.default) el.isHide = false;
                    else el.isHide = true;
                });
            });

            resetOrderedColumns(newColumns);

            return newColumns;
        });
    };

    const handleSubmit = () => {
        setIsLoading(true);

        if (customSave) {
            customSave({
                isColoredRows,
                isVerticalBorders,
                columns: orderedColumns.map(el => ({ key: el.key, isHide: el.isHide, order: el.order }))
            });

            hideModal();
            return;
        }

        const pathPart = CHAPTER_REPORTS.includes(chapter) ? 'chapter_reports' : chapter;

        const newStorage = clone(storage);

        newStorage[pathPart].columns = orderedColumns.map(el => ({ key: el.key, isHide: el.isHide, order: el.order }));
        newStorage.common.isColoredRows = isColoredRows;
        newStorage.common.isVerticalBorders = isVerticalBorders;

        changeStorage(newStorage);
    };

    const {
        filteredItems: filteredBySearchColumns,
        setSearchByForInput: handleSearchByColumns,
        searchByValue
    } = useSearch(allColumns, ({ label }) => label);

    const renderColumnSearch = () => (
        <div className="columns-settings__left">
            <div className="modal__title">{txt.labels.columnsVisibility}</div>
            <TextInput
                dataTest="columns-settings_search-for-column-input"
                className="mt15"
                placeholder={txt.placeholders.searchForColumn}
                onChange={handleSearchByColumns}
                value={searchByValue}
            />
        </div>
    );

    const SecondSubHeader = () => (
        <>
            <div className="pl15">
                <div>
                    <div>
                        <CheckBoxInput
                            dataTest="columns-settings_color-in-the-row-checkbox"
                            label={txt.labels.colorInTheRow}
                            value={isColoredRows}
                            onChange={({ target: { checked } }) => setIsColoredRows(checked)}
                        />
                    </div>
                    <div>
                        <CheckBoxInput
                            dataTest="columns-settings_color-in-the-row-checkbox"
                            label={txt.labels.verticalBorders}
                            value={isVerticalBorders}
                            onChange={({ target: { checked } }) => setVerticalBorders(checked)}
                        />
                    </div>
                </div>
                <div className="j4">
                    <CheckBoxInput
                        dataTest="columns-settings_select-all-checkbox"
                        label={`${txt.labels.selectAll} (${txt.labels.selected} ${txt.texts.columnsNumber(
                            allColumns.filter(el => el.key !== 'checked').filter(el => !el.isHide).length,
                            allColumns.filter(el => el.key !== 'checked').length
                        )})`}
                        value={isAllColumnsVisible}
                        onChange={toggleSelectAll}
                    />
                </div>
            </div>
            <div>
                <Btn dataTest="columns-settings_reset-to-defaults-btn" className="ml15" onClick={toggleResetToDefaults}>
                    {txt.buttons.resetToDefaults}
                </Btn>
            </div>
        </>
    );

    const ThirdSubHeader = () => (
        <div>
            <div className="modal__title">{txt.labels.columnsOrder}</div>
            <div className="mt15">
                <Btn
                    dataTest="columns-settings_reset-default-order-btn"
                    className="plr15"
                    onClick={() => resetOrderedColumns(allColumns)}
                >
                    {txt.buttons.resetDefaultOrder}
                </Btn>
            </div>
        </div>
    );

    return (
        <>
            <div className="columns-settings">
                <div className="columns-item columns-item--left">
                    <div className="columns-settings__subheader">
                        {renderColumnSearch()}
                        <SecondSubHeader />
                    </div>
                    <div className="columns-settings__content">
                        <ColumnsListV fields={filteredBySearchColumns} onChange={onColumnVisbilityChange} />
                    </div>
                </div>
                <div className="columns-item">
                    <div className="columns-settings__subheader">
                        <ThirdSubHeader />
                    </div>
                    <div className="columns-settings__content">
                        <div className="columns-order">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <ColumnsList columns={filteredColumns} />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>

                {isLoading && <Loader isFetching />}
            </div>
            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onConfirm={handleSubmit}
                onReject={onClose}
            />
        </>
    );
};

export default ColumnsSettings;
