import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import Langs from '../../../Langs';
import { EMAIL_RULE, USER_ROLES } from '../../../Constants';
import { trimObjValues, compare, getResponseErrorMessage } from '../../../Functions/utils';

import {
    useChangeUserSettingsEmailMutation,
    useChangeUserSettingsProfileMutation,
    useGetUserSettingsQuery
} from '../../../redux/api/settingsApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import LabeledField from '../../Overall/Forms/LabeledField';
import Loader from '../../Loader';
import FakeAutocomplete from '../../../components/fake-autocomlete/fake-autocomlete';
import Btn from '../../Overall/UI/Btn/Btn';

import './user-settings.scss';

const UserSettings = ({ isSupportMode }) => {
    const txt = Langs[global.lng];

    const [emailData, setEmailData] = useState({ email: '', password: null });
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        companyName: ''
    });

    const [isProfileEditing, setIsProfileEditing] = useState(false);
    const [isEmailEditing, setIsEmailEditing] = useState(false);
    const [isEmailSubmit, setIsEmailSubmit] = useState(false);

    const {
        data: userSettings,
        isFetching: isUserSettingsFetching,
        isSuccess: isUserSettingsLoaded
    } = useGetUserSettingsQuery();

    const userRole = userSettings?.role;

    useEffect(() => {
        if (isUserSettingsLoaded) {
            setProfileData({
                firstName: userSettings.firstName,
                lastName: userSettings.lastName,
                companyName: userSettings.companyName
            });

            setEmailData({ email: userSettings.email, password: null });
        }
    }, [userSettings]);

    const onProfileDataChange = useCallback((newProfileData, prop) => {
        setProfileData(
            produce(draft => {
                draft[prop] = newProfileData;
            })
        );
    }, []);

    const onEmailDataChange = useCallback((newEmailData, prop) => {
        setEmailData(
            produce(draft => {
                draft[prop] = newEmailData;
            })
        );
    }, []);

    const onProfileEditCancel = useCallback(() => {
        setIsProfileEditing(false);
        setProfileData({
            firstName: userSettings.firstName,
            lastName: userSettings.lastName,
            companyName: userSettings.companyName
        });
    }, [userSettings]);

    const onEmailEditCancel = useCallback(() => {
        setEmailData({ email: userSettings.email });
        setIsEmailEditing(false);
        setIsEmailSubmit(false);
    }, [userSettings]);

    const getEmailErrorText = email => {
        if (!email) return txt.errors.required(txt.settings.email);
        else if (!EMAIL_RULE.test(email.toLowerCase())) return txt.errors.email;
        return '';
    };

    const isProfileFieldsValid = profileData.firstName && profileData.lastName;
    const isEmailFieldValid = emailData.email && !getEmailErrorText(emailData.email.trim());

    const [
        changeProfileSettings,
        {
            isLoading: isChangeProfileSettingsLoading,
            isSuccess: isChangeProfileSettingsSuccess,
            isError: isChangeProfileSettingsError,
            error: changeProfileSettingsError
        }
    ] = useChangeUserSettingsProfileMutation();

    useEffect(() => {
        if (isChangeProfileSettingsSuccess) {
            toast.success(txt.toasts.saved);
            setIsProfileEditing(false);
        }
    }, [isChangeProfileSettingsSuccess]);

    useEffect(() => {
        if (isChangeProfileSettingsError)
            toast.error(getResponseErrorMessage(changeProfileSettingsError.data, txt.toasts.saveError));
    }, [isChangeProfileSettingsError]);

    const onProfileSubmit = () => {
        const trimmedProfileData = trimObjValues(profileData);

        const isSame = compare(
            {
                firstName: userSettings.firstName,
                lastName: userSettings.lastName,
                companyName: userSettings.companyName
            },
            trimmedProfileData
        );

        if (!isSame) {
            if (isProfileFieldsValid) changeProfileSettings(trimmedProfileData);
        } else setIsProfileEditing(false);
    };

    const [
        changeEmail,
        {
            isLoading: isChangeEmailLoading,
            isSuccess: isChangeEmailSuccess,
            isError: isChangeEmailError,
            error: changeEmailError
        }
    ] = useChangeUserSettingsEmailMutation();

    useEffect(() => {
        if (isChangeEmailSuccess) {
            toast.success('Follow the link in the email to confirm your new email address');
            setEmailData({ email: userSettings.email, password: null });
            setIsEmailSubmit(false);
            setIsEmailEditing(false);
        }
    }, [isChangeEmailSuccess]);

    useEffect(() => {
        if (isChangeEmailError) toast.error(getResponseErrorMessage(changeEmailError.data, txt.toasts.saveError));
    }, [isChangeEmailError]);

    const onEmailSubmit = () => {
        setIsEmailSubmit(true);

        if (isEmailFieldValid && emailData.password) {
            const trimmedEmailData = trimObjValues(emailData);
            changeEmail(trimmedEmailData);
        }
    };

    const sectionHeaderProfile = (
        <SectionHeader
            title={txt.settings.profileInfo}
            button={{
                label: txt.buttons.edit,
                onClick: () => setIsProfileEditing(true),
                disabled: false
            }}
        />
    );

    const sectionHeaderEmail = (
        <SectionHeader
            title={txt.settings.email}
            button={{
                label: txt.buttons.edit,
                onClick: () => setIsEmailEditing(true),
                disabled: userRole !== USER_ROLES.OWNER
            }}
        />
    );

    if (!isUserSettingsLoaded) return <Loader />;

    return (
        <>
            <Section Header={sectionHeaderProfile} className="mb25 user-settings">
                <LabeledField
                    field={{
                        label: txt.labels.firstname,
                        value: profileData.firstName,
                        error: !profileData.firstName.trim() ? txt.errors.required(txt.labels.firstname) : ''
                    }}
                    onChange={e => onProfileDataChange(e, 'firstName')}
                    isEditing={isProfileEditing}
                    className="mb15"
                />

                <LabeledField
                    field={{
                        label: txt.labels.lastname,
                        value: profileData.lastName,
                        error: !profileData.lastName.trim() ? txt.errors.required(txt.labels.lastName) : ''
                    }}
                    onChange={e => onProfileDataChange(e, 'lastName')}
                    isEditing={isProfileEditing}
                    className="mb15"
                />

                <LabeledField
                    field={{ label: txt.labels.company, value: profileData.companyName }}
                    onChange={e => onProfileDataChange(e, 'companyName')}
                    isEditing={isProfileEditing && userRole === USER_ROLES.OWNER}
                    className="mb15"
                />

                {isProfileEditing && (
                    <div className="user-settings__buttons">
                        <Btn onClick={onProfileEditCancel} className="mr8">
                            {txt.buttons.cancel}
                        </Btn>

                        <Btn type="filled" onClick={onProfileSubmit}>
                            {txt.buttons.save}
                        </Btn>
                    </div>
                )}

                {(isChangeProfileSettingsLoading || isUserSettingsFetching) && <Loader isFetching />}
            </Section>

            {!isSupportMode && (
                <Section Header={sectionHeaderEmail} className="mb25 user-settings">
                    <FakeAutocomplete />

                    <LabeledField
                        field={{
                            label: txt.settings.email,
                            value: emailData.email.trim(),
                            error: isEmailSubmit ? getEmailErrorText(emailData.email.trim()) : ''
                        }}
                        onChange={e => onEmailDataChange(e, 'email')}
                        isEditing={isEmailEditing}
                        className="mb15"
                    />

                    {isEmailEditing && (
                        <LabeledField
                            field={{
                                label: txt.settings.confirmWithPassword,
                                value: emailData.password,
                                type: 'password',
                                error:
                                    isEmailSubmit && !emailData.password
                                        ? txt.errors.required(txt.settings.confirmWithPassword)
                                        : ''
                            }}
                            onChange={e => onEmailDataChange(e, 'password')}
                            isEditing={isEmailEditing}
                        />
                    )}

                    {isEmailEditing && (
                        <div className="user-settings__buttons">
                            <Btn onClick={onEmailEditCancel} className="mr8">
                                {txt.buttons.cancel}
                            </Btn>

                            <Btn type="filled" onClick={onEmailSubmit}>
                                {txt.buttons.save}
                            </Btn>
                        </div>
                    )}

                    {isChangeEmailLoading && <Loader isFetching />}
                </Section>
            )}
        </>
    );
};

export default UserSettings;
