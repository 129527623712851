import React from 'react';

import Langs from '../../../../../Langs';

import AddBillingInfoModal from '../../../add-billing-info-modal/add-billing-info-modal';

import './add-payment-billing-info.scss';

const AddPaymentBillingInfo = ({ onClose, onConfirm }) => {
    const txt = Langs[global.lng];

    return (
        <AddBillingInfoModal
            className="add-payment-billing-info"
            handleConfirmation={onConfirm}
            noRejectBtn
            isIntegrated={{ addBackBtn: true, btnText: txt.buttons.cancel, onBackBtnClick: onClose }}
        />
    );
};

export default AddPaymentBillingInfo;
