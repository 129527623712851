import { createApi } from '@reduxjs/toolkit/query/react';
import { getCustomBaseQuery, apiFullPath } from '../services';

export const RESET_TAG = 'RESET';

export const RESET_TAG_TYPE = {
    SOFT: 'SOFT',
    HARD: 'HARD'
};

const indexApi = createApi({
    reducerPath: 'api',
    baseQuery: getCustomBaseQuery({
        baseUrl: apiFullPath
    }),
    tagTypes: [RESET_TAG],
    endpoints: () => ({})
});

export default indexApi;
