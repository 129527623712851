import React from 'react';
import { Gift } from 'react-feather';

import Langs from '../../../Langs';
import { getIntlFormattedValue } from '../../../Functions/utils';

import './promo-balance-modal.scss';

const PromoBalanceModal = ({ onClose, balance }) => {
    const txt = Langs[global.lng];

    return (
        <>
            <div className="promo-balance-modal">
                <div className="promo-balance-modal__wrapper">
                    <div className="promo-balance-modal__icon">
                        <Gift size={24} stroke="#fff" />
                    </div>
                    <div className="promo-balance-modal__content">
                        <h2 className="promo-balance-modal__title">{txt.titles.congratulations}!</h2>
                        <h2 className="promo-balance-modal__title promo-balance-modal__title--balance">
                            {txt.texts.balanceWithPromocodeTitle(`$${getIntlFormattedValue(balance)}`)}
                        </h2>
                        <p className="promo-balance-modal__text">{txt.texts.balanceWithPromocodeText}</p>
                        <button className="promo-balance-modal__btn" onClick={onClose}>
                            {txt.buttons.ok}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PromoBalanceModal;
