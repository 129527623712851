import React, { useRef } from 'react';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import Langs from '../../../../../Langs';
import { getIntlFormattedValue, capitalize, omitKeys } from '../../../../../Functions/utils';
import { showModal, hideModal } from '../../../../../redux/operations';

import { useGetProjectInfoQuery } from '../../../../../redux/api/projectApi';

import { AccordionTitle } from '../../../../Overall/Accordion/Accordion';
import HintIcon from '../../../../Overall/Forms/Inputs/HintIcon';
import Icon from '../../../../Overall/Icon';
// import Label from '../../../../Overall/Forms/Inputs/Label';
// import SelectInput from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Switcher from '../../../../Overall/UI/Switcher/Switcher';
import Btn, { AddBtn } from '../../../../Overall/UI/Btn/Btn';
import Path from './Path/Path';
import { getAdviceWeight } from './Path/common';
import WeightsOptimizationSettingsModal from '../../../../Modals/WeightsOptimizationSettingsModal/WeightsOptimizationSettingsModal';

import './flow-path.scss';

// const calculateMethods = ['auto', 'roi', 'epv', 'cv'];

const FlowPath = ({
    paths,
    // calculationMethod,
    referrerDefaultHideType,
    workspaceId,
    publicWorkspaceId,
    isPathOptimization,
    isRuleOptimizedData,
    onChange,
    trackingMethod,
    currentCampaignInfo,
    lastOptimizationAt,
    optimizationSettings,
    entityType,

    isPathInsideRule,
    defaultPaths,

    landersList,
    offersList,
    affiliateNetworksList,
    countries,
    currencies
}) => {
    const txt = Langs[global.lng];
    const ref = useRef();

    const { data: projectInfo } = useGetProjectInfoQuery();

    const VISITS_OPTIMIZATION_TYPE = {
        calculated: 'calculated',
        manual: 'manual'
    };

    const VISITS_OPTIMIZATION_ALGORITHM = {
        last: 'last',
        every: 'every'
    };

    let countPathsWithoutOptimization = 0;
    const pathsWithOptimization = [];
    const activePaths = paths.filter(el => !el.isDeleted && el.isEnabled);

    activePaths.forEach(el => {
        if (el.isOptimizationWeights) pathsWithOptimization.push(el);
        else countPathsWithoutOptimization++;
    });

    const calculatedValueFromOptimizedPaths = pathsWithOptimization.reduce((acc, el) => {
        let totalValue = 0;

        switch (el.weightsOptimization) {
            case 'landers':
                totalValue = el.landers.length * projectInfo.baseCountVisits;
                break;

            case 'offers':
                totalValue = el.offers.length * projectInfo.baseCountVisits;
                break;

            case 'bothSeparately':
                if (el.landers.length > el.offers.length) totalValue = el.landers.length * projectInfo.baseCountVisits;
                else totalValue = el.offers.length * projectInfo.baseCountVisits;
                break;

            case 'bothCombined':
                totalValue = el.landers.length * el.offers.length * projectInfo.baseCountVisits;
                break;

            default:
                throw new Error(`Invalid weightsOptimization method: ${el.weightsOptimization}`);
        }

        return acc + totalValue;
    }, 0);

    const resultCalculatedValue =
        countPathsWithoutOptimization * projectInfo.baseCountVisits + calculatedValueFromOptimizedPaths;

    const lastOptimization = lastOptimizationAt
        ? `${moment(lastOptimizationAt).format('L')} ${moment(lastOptimizationAt).format('LT')}`
        : `${capitalize(txt.texts.waiting)}...`;

    const isManualOptimizationType = optimizationSettings?.visitsOptimizationType === VISITS_OPTIMIZATION_TYPE.manual;
    const isWarningOptimizationValue = optimizationSettings?.visitsOptimizationValue < resultCalculatedValue;

    const onPathChange = (data, prop, pathId) => {
        const index = paths.findIndex(el => el.id === pathId);
        const newValues = [...paths];

        let formattedPath = { ...paths[index] };

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                formattedPath = { ...formattedPath, [el.prop]: el.value };
            });
        } else {
            formattedPath = { ...formattedPath, [prop]: data };
        }

        newValues.splice(index, 1, formattedPath);
        onChange(newValues, 'paths');
    };

    return (
        <>
            <div className="flow-path">
                {!isPathInsideRule && (
                    <div className="j4 mb10">
                        <span className="text-uppercase mr5 text-medium">{txt.labels.defaultPaths}</span>
                        <HintIcon hint={txt.hints.defaultPaths} />
                    </div>
                )}

                <AccordionTitle
                    label={txt.labels.paths}
                    topComponent={
                        <>
                            {(isPathOptimization || pathsWithOptimization.length > 0) && (
                                <div className="flow-path__optimization-settings">
                                    <div className="flow-path__optimization-settings-wrapper">
                                        <div className="flow-path__optimization-settings-item">
                                            <p className="flow-path__optimization-settings-title">
                                                {getIntlFormattedValue(
                                                    isManualOptimizationType
                                                        ? optimizationSettings?.visitsOptimizationValue
                                                        : resultCalculatedValue
                                                )}{' '}
                                                (
                                                {capitalize(
                                                    optimizationSettings?.visitsOptimizationType ??
                                                        VISITS_OPTIMIZATION_TYPE.calculated
                                                )}
                                                )
                                            </p>
                                            <span className="flow-path__optimization-settings-descr">
                                                {txt.texts.numberVisitsForOptimization}
                                            </span>
                                        </div>

                                        <div className="flow-path__optimization-settings-item">
                                            <p className="flow-path__optimization-settings-title">
                                                {capitalize(
                                                    optimizationSettings?.visitsOptimizationAlgorithm ??
                                                        VISITS_OPTIMIZATION_ALGORITHM.last
                                                )}{' '}
                                                {getIntlFormattedValue(
                                                    isManualOptimizationType
                                                        ? optimizationSettings?.visitsOptimizationValue
                                                        : resultCalculatedValue
                                                )}{' '}
                                                {txt.texts.visits}
                                            </p>
                                            <span className="flow-path__optimization-settings-descr">
                                                {txt.texts.algorithm}
                                            </span>
                                        </div>

                                        {entityType === 'campaigns' && (
                                            <div className="flow-path__optimization-settings-item">
                                                <p className="flow-path__optimization-settings-title">
                                                    {lastOptimization}
                                                </p>
                                                <span className="flow-path__optimization-settings-descr">
                                                    {txt.texts.lastOptimization}
                                                </span>
                                            </div>
                                        )}

                                        <Btn
                                            className="ml-auto"
                                            type="link"
                                            onClick={() => {
                                                showModal({
                                                    title: txt.titles.optimizationSettings,
                                                    body: props => (
                                                        <WeightsOptimizationSettingsModal
                                                            {...props}
                                                            optimizationSettings={optimizationSettings}
                                                            resultCalculatedValue={resultCalculatedValue}
                                                            onConfirm={data => {
                                                                onChange(
                                                                    {
                                                                        ...omitKeys(data, ['isManual']),
                                                                        visitsOptimizationCalculatedValue:
                                                                            countPathsWithoutOptimization *
                                                                                projectInfo.baseCountVisits +
                                                                            calculatedValueFromOptimizedPaths
                                                                    },
                                                                    'optimizationSettings'
                                                                );
                                                                hideModal();
                                                            }}
                                                            VISITS_OPTIMIZATION_TYPE={VISITS_OPTIMIZATION_TYPE}
                                                            VISITS_OPTIMIZATION_ALGORITHM={
                                                                VISITS_OPTIMIZATION_ALGORITHM
                                                            }
                                                        />
                                                    )
                                                });
                                            }}
                                        >
                                            {txt.texts.editSettings}
                                        </Btn>
                                    </div>

                                    {isManualOptimizationType && isWarningOptimizationValue && (
                                        <div className="flow-path__optimization-settings-wrapper">
                                            <div className="flow-path__optimization-settings-alert">
                                                <Icon.AlertTriangle color="#F06D8D" size={16} />
                                                <span>
                                                    Recommended Number of visits for optimization=
                                                    {getIntlFormattedValue(resultCalculatedValue)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {paths.length > 1 && (
                                <div className="j46 mb8 flow-path__optimization">
                                    {isPathOptimization && paths.filter(({ isDeleted }) => !isDeleted).length < 2 && (
                                        <HintIcon
                                            hint={txt.hints.pathsOptimization}
                                            type="bottom"
                                            iconType="alert"
                                            className="flow-path__optimization-hint"
                                        />
                                    )}
                                    <Switcher
                                        dataTest="flow-path_path-optimization"
                                        label={txt.labels.pathsOptimization}
                                        isSwitchOn={isPathOptimization}
                                        onChange={e => onChange(e, 'isPathOptimization')}
                                    />
                                    {/* {isPathOptimization && (
                                <div className="j4">
                                    <Label
                                        label={txt.labels.calculationMethod}
                                        hint={txt.hints.calculationMethod}
                                    />
                                    <SelectInput
                                        dataTest="flow-path_calculation-method-select-input"
                                        items={calculateMethods}
                                        value={calculationMethod}
                                        onChange={e => onChange(e, 'calculationMethod')}
                                        className="flow-path__calculation-method"
                                    />
                                </div>
                                    )} */}
                                </div>
                            )}
                        </>
                    }
                >
                    {paths.map((path, index) => (
                        <Path
                            index={index}
                            key={path.id ?? path.value}
                            ref={{ ...ref }}
                            path={path}
                            activePathsCount={activePaths.length}
                            onPathByIdChange={onPathChange}
                            trackingMethod={trackingMethod}
                            workspaceId={workspaceId}
                            publicWorkspaceId={publicWorkspaceId}
                            defaultPaths={defaultPaths}
                            referrerDefaultHideType={referrerDefaultHideType}
                            isPathOptimization={isPathOptimization}
                            isRuleOptimizedData={isRuleOptimizedData}
                            pathAdviceWeight={path.isEnabled ? getAdviceWeight(path.weight, paths) : 0}
                            isPathInsideRule={isPathInsideRule}
                            isWeightReadOnly={paths.filter(el => !el.isDeleted).length <= 1}
                            currentCampaignInfo={currentCampaignInfo}
                            entityType={entityType}
                            landersList={landersList}
                            offersList={offersList}
                            affiliateNetworksList={affiliateNetworksList}
                            countries={countries}
                            currencies={currencies}
                        />
                    ))}
                </AccordionTitle>
            </div>
            {trackingMethod?.value === 'redirect' && (
                <AddBtn
                    dataTest="flow-path_add-path-btn"
                    type="filled"
                    className="mt8"
                    onClick={() => {
                        onChange(
                            [
                                ...paths,
                                {
                                    id: uuid(),
                                    name: `New path ${paths.length + 1}`,
                                    isReferHide: referrerDefaultHideType !== 'without',
                                    isEnabled: true,
                                    hideReferer:
                                        referrerDefaultHideType !== 'without'
                                            ? referrerDefaultHideType
                                            : 'meta_refresh',
                                    weight: 100,
                                    isOptimizationWeights: false,
                                    weightsOptimization: 'offers',
                                    weightsOptimizationMethod: 'auto',
                                    landers: [],
                                    offers: []
                                }
                            ],
                            'paths'
                        );
                    }}
                >
                    Path
                </AddBtn>
            )}
        </>
    );
};

export default FlowPath;
