import React from 'react';
// import { RefreshCw } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../../../Constants';
import { showModal } from '../../../../../redux/operations';

import { changeMainTablePagination } from '../../../../../redux/main-table-slice';

// import indexApi from '../../../../../redux/api/indexApi';

import TableControlContainer from '../../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Overall/UI/Btn/Btn';
import TablePagination from '../../../../Overall/UI/TablePagination/TablePagination';
import CreateWorkspaceModal from '../../../../Modals/create-workspace-modal/create-workspace-modal';
import ConfirmDeleteModal from '../../../../Modals/confirm-delete-modal/confirm-delete-modal';

const TableControls = ({ chapter, isLoading, pagination, activeRow, deleteWorkspace }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const isPrivateWorkspace = activeRow?.workspaceType === 'Private';

    const showCreateWorkspaceModal = mode => {
        showModal({
            title: mode === ENTITY_MODE_STATUS.EDIT ? 'Edit Workspace' : 'Create Workspace',
            headerType: 'primary',
            body: props => <CreateWorkspaceModal {...props} item={activeRow} mode={mode} />
        });
    };

    const onDeleteWorkspaceClick = () => {
        showModal({
            title: 'Delete workspace?',
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(activeRow.name, true)}
                    entity={'workspace'}
                    onConfirm={() => deleteWorkspace({ id: activeRow.value })}
                    autoClose={true}
                />
            )
        });
    };

    return (
        <TableControlContainer>
            <Btn
                dataTest="table-control_add-btn_workspace"
                type="filled"
                disabled={isLoading}
                onClick={() => showCreateWorkspaceModal(ENTITY_MODE_STATUS.CREATE)}
            >
                {txt.buttons.addWorkspace}
            </Btn>

            <div className="table-control-container__links">
                <Btn
                    dataTest="table-control_edit-btn_workspace"
                    type="link"
                    disabled={isLoading || !activeRow || !isPrivateWorkspace}
                    onClick={() => showCreateWorkspaceModal(ENTITY_MODE_STATUS.EDIT)}
                >
                    {txt.buttons.edit}
                </Btn>

                <Btn
                    dataTest="table-control_delete-btn_workspace"
                    type="link"
                    disabled={isLoading || !activeRow || !isPrivateWorkspace}
                    onClick={onDeleteWorkspaceClick}
                >
                    {txt.buttons.delete}
                </Btn>
            </div>

            {/* <Btn
                dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                type="filled"
                disabled={isLoading}
                className="ml8"
                onClick={() =>
                    dispatch(indexApi.util.invalidateTags([{ type: workspacesApiTagTypes.workspaces, id: 'LIST' }]))
                }
            >
                <RefreshCw />
                {txt.buttons.refresh}
            </Btn> */}

            <div className="table-control-container__pagination">
                <TablePagination
                    value={pagination}
                    onChange={data => {
                        dispatch(
                            changeMainTablePagination({
                                chapter,
                                value: data
                            })
                        );
                    }}
                    disabled={isLoading}
                />
            </div>
        </TableControlContainer>
    );
};

export default TableControls;
