import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import ConfirmDeleteModal from '../../../../Modules/Modals/confirm-delete-modal/confirm-delete-modal';
import RestoreCampaignsModal from '../../../../Modules/Modals/restore-campaigns-modal/restore-campaigns-modal';

const RestoreButton = ({ chapter, entityId, entityName, isDisabled, onClick, type, onRestoreConfirm }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') return onClick();

        switch (chapter) {
            case 'campaigns':
                showModal({
                    title: `Restore ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
                    body: props => (
                        <RestoreCampaignsModal
                            {...props}
                            entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                            entityId={entityId}
                            entityName={entityName}
                        />
                    )
                });
                break;

            default:
                showModal({
                    title: `Restore ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
                    body: props => (
                        <ConfirmDeleteModal
                            {...props}
                            title={txt.texts.getWillBeRestoredSubtitle(entityName)}
                            entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                            type="restore"
                            onConfirm={onRestoreConfirm}
                        />
                    )
                });
        }
    };

    return (
        <Btn
            dataTest={`table-control__restore-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.restore}
        </Btn>
    );
};

export default RestoreButton;
