import React from 'react';
import cn from 'classnames';

import './section.scss';

const Section = ({ Header, children, className }) => {
    const isNotEmpty = children && children?.some(el => el);

    return (
        <section className={cn('section', className, { 'section--empty': !isNotEmpty })}>
            {Header}
            {isNotEmpty && <div className="section__wrapper">{children}</div>}
        </section>
    );
};

export default Section;
