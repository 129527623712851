import React, { useState, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Langs from '../../../../../../Langs';
import {
    ALERT_TYPE,
    ENTITY_MODE_STATUS,
    LANDER_AND_OFFER_OPTIMIZATION_LIMIT,
    LANDER_PATH_LIMIT,
    MODAL_TYPES,
    OFFER_PATH_LIMIT,
    TRACKING_METHOD
} from '../../../../../../Constants/index';
import { showModal } from '../../../../../../redux/operations';
import { clone } from '../../../../../../Functions/utils';

import useToggle from '../../../../../../Functions/hooks/useToggle';
import { landersForNewItemTransformer, offersForNewItemTransformer } from '../../../../../../redux/transformers';

import showItemsModal from '../../../../showItemsModal';
import ActionsGroup from '../../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import Label from '../../../../../Overall/Forms/Inputs/Label';
import RadioInput from '../../../../../Overall/Forms/Inputs/RadioInput';
import SelectTable from '../../../../../Overall/SelectTable/SelectTable';
import Alert from '../../../../../Overall/UI/Alert/Alert';
import Btn, { AddBtn } from '../../../../../Overall/UI/Btn/Btn';
import Switcher from '../../../../../Overall/UI/Switcher/Switcher';
// import SelectInput from '../../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import { HIDE_REFERRER_DEFAULT_TYPES } from '../../CampaignMainOption/CampaignMainOption';
import { EditablePath, EditPlainField, GhostOffer } from './common';
import Offer from './Offer';
import Lander, { getIsLanderVerified } from './Lander';
import ActualWeightsModal from '../../../../../Modals/ActualWeightsModal/ActualWeightsModal';

import './path.scss';

// const weightsOptimizationMethods = ['auto', 'manual'];

const Path = ({
    path,
    landersList,
    offersList,
    affiliateNetworksList,
    countries,
    currencies,
    activePathsCount,
    onPathByIdChange,
    isPathOptimization,
    isRuleOptimizedData,
    pathForwardedRef,
    pathAdviceWeight,
    index,
    isWeightReadOnly,
    isPathOutside,
    isPathInsideRule,
    trackingMethod,
    workspaceId,
    publicWorkspaceId,
    defaultPaths,
    currentCampaignInfo,
    entityType
}) => {
    if (!path) return null;

    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const {
        name,
        isReferHide,
        isEnabled,
        hideReferer,
        optimizedWeightData,
        weightsOptimization,
        isOptimizationWeights,
        // weightsOptimizationMethod,
        landers: pathLanders,
        offers: pathOffers,
        isDeleted,
        isOpened = true
    } = path;

    const onPathChange = (data, prop) => onPathByIdChange(data, prop, path.id);

    const onLanderByIdChange = (data, prop, landerId) => {
        const idx = pathLanders.findIndex(el => el.landerValue === landerId);
        const newValues = [...pathLanders];
        newValues.splice(idx, 1, { ...pathLanders[idx], [prop]: data });
        onPathChange(newValues, 'landers');
    };

    const onOfferByIdChange = (data, prop, offerId) => {
        const idx = pathOffers.findIndex(el => el.offerValue === offerId);
        const newValues = [...pathOffers];
        newValues.splice(idx, 1, { ...pathOffers[idx], [prop]: data });
        onPathChange(newValues, 'offers');
    };

    const [initialWeightOptimizationMethod] = useState(weightsOptimization);

    const landers = useMemo(() => landersForNewItemTransformer(landersList), [landersList]);

    const offers = useMemo(
        () => offersForNewItemTransformer(offersList, affiliateNetworksList),
        [offersList, affiliateNetworksList]
    );

    const workspaceAvailableLanders = useMemo(
        () => landers.filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId) ?? [],
        [landers, workspaceId, publicWorkspaceId]
    );

    const workspaceAvailableOffers = useMemo(
        () => offers.filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId) ?? [],
        [offers, workspaceId, publicWorkspaceId]
    );

    const isIncludeAvailableOffers = useMemo(() => {
        const filteredOffers = workspaceAvailableOffers.filter(el => !el.isArchived);

        return filteredOffers.length > 0 || pathLanders.length !== 0;
    }, [workspaceAvailableOffers, pathOffers]);

    const isIncludeAvailableLanders = useMemo(() => {
        const filteredLanders = workspaceAvailableLanders.filter(el => !el.isArchived);

        return filteredLanders.length > 0 || pathLanders.length !== 0;
    }, [workspaceAvailableLanders, pathLanders]);

    const hasManyClickUrls = (
        trackingMethod?.value === TRACKING_METHOD.DIRECT &&
        trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS &&
        isPathInsideRule
            ? defaultPaths?.[0]?.landers
            : pathLanders
    )
        ?.filter(el => el.isEnabled && !el.isDeleted)
        .some(el => landers.find(item => item.value === el.landerValue)?.numberOfOffers > 1);

    const weightsOptimizationTypes = [
        {
            label: 'landers',
            value: 'landers',
            hint: txt.hints.weightsOptimizationOption(txt.labels.landers),
            disabled: trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS || !pathLanders.length
        },
        {
            label: 'offers',
            value: 'offers',
            hint: txt.hints.weightsOptimizationOption(txt.labels.offers),
            disabled: hasManyClickUrls
        },
        {
            label: 'bothSeparately',
            value: 'bothSeparately',
            hint: txt.hints.weightsOptimizationBothSeparately,
            disabled:
                trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS ||
                !pathLanders.length ||
                !pathOffers.length ||
                hasManyClickUrls
        },
        {
            label: 'bothCombined',
            value: 'bothCombined',
            hint: txt.hints.weightsOptimizationBothCombined,
            disabled:
                trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS ||
                !pathLanders.length ||
                !pathOffers.length ||
                hasManyClickUrls
        }
    ];

    const { isToggled: isEdited, toggle: toggleSetIsEdited } = useToggle();

    const lastChangeType = useRef(null);
    const lastChangeTypeFixed = useRef(null);
    const isOneItemChange = useRef(false);
    const isOneItemDelete = useRef(null);
    const isOneItemAdded = useRef(null);
    const oneItemDirectionUp = useRef(null);

    const isBothCombinedActive = weightsOptimization === 'bothCombined';

    const activeLandersInPath = pathLanders.filter(item => !item.isDeleted);
    const activeOffersInPath = pathOffers.filter(item => !item.isDeleted);

    const maxLandersInPath = (() => {
        if (!isOptimizationWeights || !isBothCombinedActive) return LANDER_PATH_LIMIT;
        if (lastChangeType.current === 'landers' && !isOneItemChange.current) return LANDER_PATH_LIMIT;

        const getDirection = () => {
            if (isOneItemChange.current && lastChangeType.current === 'landers' && oneItemDirectionUp.current) {
                if (activeOffersInPath.length === 10 || activeOffersInPath.length === 8) return -2;
                return -1;
            }

            if (isOneItemChange.current && lastChangeType.current === 'offers' && oneItemDirectionUp.current) {
                return 1;
            }

            if (
                isOneItemChange.current &&
                lastChangeType.current === 'offers' &&
                isOneItemDelete.current &&
                pathOffers.length > activeOffersInPath.length
            ) {
                return 1;
            }

            if (isOneItemChange.current && lastChangeType.current === 'landers' && isOneItemAdded.current) {
                return -1;
            }

            return 0;
        };

        const result = Math.floor(
            LANDER_AND_OFFER_OPTIMIZATION_LIMIT / (activeOffersInPath.length + getDirection() || 1)
        );

        if (result >= LANDER_PATH_LIMIT) return LANDER_PATH_LIMIT;
        return result;
    })();

    const maxOffersInPath = (() => {
        if (!isOptimizationWeights || !isBothCombinedActive) return OFFER_PATH_LIMIT;
        if (lastChangeType.current === 'offers' && !isOneItemChange.current) return OFFER_PATH_LIMIT;

        const getDirection = () => {
            if (isOneItemChange.current && lastChangeType.current === 'offers' && oneItemDirectionUp.current) {
                if (activeLandersInPath.length === 10 || activeLandersInPath.length === 8) return -2;
                return -1;
            }

            if (isOneItemChange.current && lastChangeType.current === 'landers' && oneItemDirectionUp.current) {
                return 1;
            }

            if (
                isOneItemChange.current &&
                lastChangeType.current === 'landers' &&
                isOneItemDelete.current &&
                pathLanders.length > activeLandersInPath.length
            ) {
                return 1;
            }

            if (isOneItemChange.current && lastChangeType.current === 'offers' && isOneItemAdded.current) {
                return -1;
            }

            return 0;
        };

        const result = Math.floor(
            LANDER_AND_OFFER_OPTIMIZATION_LIMIT / (activeLandersInPath.length + getDirection() || 1)
        );

        if (result >= OFFER_PATH_LIMIT) return OFFER_PATH_LIMIT;
        return result;
    })();

    const availablePathLanders = useMemo(() => {
        return pathLanders.slice(0, maxLandersInPath);
    }, [maxOffersInPath, maxLandersInPath, pathLanders]);

    const unAvailablePathLanders = useMemo(() => {
        return pathLanders.slice(maxLandersInPath);
    }, [maxOffersInPath, maxLandersInPath, pathLanders]);

    const availablePathOffers = useMemo(() => {
        return pathOffers.slice(0, maxOffersInPath);
    }, [maxOffersInPath, maxLandersInPath, pathOffers]);

    const unAvailablePathOffers = useMemo(() => {
        return pathOffers.slice(maxOffersInPath);
    }, [maxOffersInPath, maxLandersInPath, pathOffers]);

    useEffect(() => {
        if (!isOptimizationWeights || !isBothCombinedActive) return;
        const newLanders = clone(pathLanders);
        newLanders.forEach(el => {
            el.isDeleted = false;
        });

        const newOffers = clone(pathOffers);
        newOffers.forEach(el => {
            el.isDeleted = false;
        });

        unAvailablePathLanders.forEach(lander => {
            const item = newLanders.find(el => el.landerValue === lander.landerValue);
            if (item) item.isDeleted = true;
        });

        unAvailablePathOffers.forEach(offer => {
            const item = newOffers.find(el => el.offerValue === offer.offerValue);
            if (item) item.isDeleted = true;
        });

        isOneItemChange.current = false;
        lastChangeType.current = null;
        isOneItemDelete.current = null;
        isOneItemAdded.current = null;
        oneItemDirectionUp.current = null;

        onPathChange(null, [
            {
                prop: 'landers',
                value: newLanders
            },
            {
                prop: 'offers',
                value: newOffers
            }
        ]);
    }, [
        activeOffersInPath.length,
        pathLanders.length,
        maxOffersInPath,
        isOptimizationWeights,
        activeLandersInPath.length,
        pathOffers.length,
        maxLandersInPath,
        isOneItemChange.current
    ]);

    useEffect(() => {
        const isNeedReset = !pathOffers.length && weightsOptimization !== 'landers';
        if ((!pathLanders.length || isNeedReset) && weightsOptimization !== 'offers') {
            onPathChange('offers', 'weightsOptimization');
        }
    }, [pathLanders, pathOffers]);

    const clickUrlsMax = (
        trackingMethod?.value === TRACKING_METHOD.DIRECT &&
        trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS &&
        isPathInsideRule
            ? defaultPaths?.[0]?.landers
            : pathLanders
    )
        ?.filter(el => el.isEnabled && !el.isDeleted)
        .reduce((acc, el) => {
            const numberOfOffers = landers.find(item => item.value === el.landerValue)?.numberOfOffers;
            if (numberOfOffers > acc) return numberOfOffers;
            return acc;
        }, 0);

    useEffect(() => {
        if (hasManyClickUrls) {
            onPathChange(null, [
                {
                    prop: 'offers',
                    value: pathOffers.filter(el => el.isEnabled)
                },
                {
                    prop: 'weightsOptimization',
                    value: 'landers'
                }
            ]);
        }
    }, [hasManyClickUrls]);

    const onDragEnd = (itemsType, dragProps) => {
        const { destination, source, draggableId } = dragProps;
        if (!source || !destination) return;

        if (source.droppableId !== destination.droppableId) {
            lastChangeType.current = itemsType;
            lastChangeTypeFixed.current = itemsType;
            isOneItemChange.current = true;
            oneItemDirectionUp.current = source.droppableId > destination.droppableId;
        }

        const newItems = itemsType === 'landers' ? [...pathLanders] : [...pathOffers];
        newItems.splice(source.index, 1);
        let draggableItem;

        if (itemsType === 'landers') {
            draggableItem = pathLanders.find(l => l.landerValue === draggableId);
        } else {
            draggableItem = pathOffers.find(o => o.offerValue === draggableId);
        }

        if (draggableItem) newItems.splice(destination.index, 0, draggableItem);

        onPathChange(newItems, itemsType);
    };

    const showSelectItemsModal = (type, replacementId, limit) => {
        const selectedItems = type === MODAL_TYPES.LANDERS ? clone(path.landers) : clone(path.offers);

        const propName = type === MODAL_TYPES.LANDERS ? 'landerValue' : 'offerValue';

        const selectedIds = selectedItems.map(item => item[propName]);

        const items = (type === MODAL_TYPES.LANDERS ? workspaceAvailableLanders : workspaceAvailableOffers).filter(
            el => !el.isArchived || selectedIds.includes(el.value)
        );

        const itemsLimit = type === MODAL_TYPES.LANDERS ? LANDER_PATH_LIMIT : OFFER_PATH_LIMIT;

        const handleConfirm = data => {
            let newItems;
            if (replacementId) {
                const newSelectedItems = selectedItems.filter(el => el[propName] !== data);
                const replaceable = newSelectedItems.find(el => el[propName] === replacementId);
                const replaceableIdx = newSelectedItems.findIndex(el => el[propName] === replacementId);
                newItems = [
                    ...newSelectedItems.slice(0, replaceableIdx),
                    { ...replaceable, [propName]: data },
                    ...newSelectedItems.slice(replaceableIdx + 1)
                ];
            } else {
                const newIds = data.filter(id => !selectedIds.includes(id));

                selectedItems.forEach(el => {
                    el.isDeleted = false;
                });

                newItems = [
                    ...selectedItems.filter(item => data.includes(item[propName])),
                    ...newIds.map(id => ({
                        [propName]: id,
                        isEnabled: true,
                        weight: 100,
                        isDeleted: false
                    }))
                ];
            }
            lastChangeType.current = type;
            lastChangeTypeFixed.current = type;
            onPathChange(newItems, type);
            return true;
        };

        showModal({
            title: type === MODAL_TYPES.LANDERS ? txt.titles.chooseLanders : txt.titles.chooseOffers,
            body: props => (
                <SelectTable
                    {...props}
                    type={type}
                    rows={items}
                    selectedIds={selectedIds}
                    onConfirm={handleConfirm}
                    replacementId={replacementId}
                    limit={limit}
                    addAlert={trackingMethod.value === TRACKING_METHOD.REDIRECT}
                    itemsLimit={itemsLimit}
                />
            )
        });
    };

    const showCreateUpdateModal = async (type, id, mode = ENTITY_MODE_STATUS.EDIT) => {
        // if need change modal title
        // const getItem = id ? await fetchData(`${type}/${id}`, null, transform[type]?.import) : null;
        // title: id ? `${txt.titles.edit} ${showByValue(countries, getItem?.countryTag)} - ${getItem?.name}` : title,

        const action = mode === ENTITY_MODE_STATUS.CREATE ? 'new' : id;

        showItemsModal(
            type,
            action,
            {},
            null,
            async (data, body) => {
                const currentItems = type === MODAL_TYPES.LANDERS ? path.landers : path.offers;

                if (!id) {
                    const newItems = [
                        {
                            [`${type.substring(0, type.length - 1)}Value`]: body.id,
                            isEnabled: true,
                            weight: 100
                        },
                        ...currentItems
                    ];

                    lastChangeType.current = type;
                    lastChangeTypeFixed.current = type;
                    isOneItemChange.current = true;
                    isOneItemAdded.current = true;

                    onPathChange(newItems, type);
                }
            },
            dispatch
        );
    };

    const isPathWarning = useMemo(
        () =>
            !isDeleted &&
            !isOpened &&
            (!!pathLanders
                .filter(el => !el.isDeleted)
                .filter(({ landerValue }) =>
                    landers?.find(lander => lander.value === landerValue && !getIsLanderVerified(lander))
                ).length ||
                !!pathOffers
                    .filter(el => !el.isDeleted)
                    .filter(({ offerValue }) =>
                        offers?.find(
                            offer =>
                                offer.value === offerValue &&
                                offer.offerUrl?.indexOf('{click_id') < 0 &&
                                offer.offerUrl?.indexOf('{clickid') < 0
                        )
                    ).length),
        [offers, landers, pathLanders, pathOffers, isDeleted, isOpened]
    );

    const landerLimit = Math.floor(LANDER_AND_OFFER_OPTIMIZATION_LIMIT / (activeOffersInPath.length || 1));
    const offerLimit = Math.floor(LANDER_AND_OFFER_OPTIMIZATION_LIMIT / (activeLandersInPath.length || 1));

    const renderLanders = () => (
        <>
            <div className="j7">
                <Label
                    label={txt.labels.landingPages}
                    hint={txt.hints.landingPages}
                    className="path__subtitle flow-path__subtitle--green"
                />
                {pathOffers.length > 1 && (
                    <Label
                        label={
                            isOptimizationWeights && weightsOptimization === 'bothCombined'
                                ? txt.labels.maxLandersAndOffers(
                                      landerLimit > LANDER_PATH_LIMIT ? LANDER_PATH_LIMIT : landerLimit,
                                      activeOffersInPath.length
                                  )
                                : txt.labels.dragToChangePriority(txt.labels.landers)
                        }
                        className="color--grey-gull text-small font-weight-normal ml3"
                    />
                )}
            </div>

            <DragDropContext onDragEnd={dragProps => onDragEnd('landers', dragProps)}>
                <Droppable droppableId={index?.toString() ?? 'list'}>
                    {droppableProvided => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {landers &&
                                availablePathLanders.map((lander, landerIndex) => {
                                    return (
                                        <Lander
                                            lander={lander}
                                            landers={landers}
                                            countries={countries}
                                            onLanderByIdChange={onLanderByIdChange}
                                            pathLanders={pathLanders}
                                            pathOffers={pathOffers}
                                            landerIndex={landerIndex}
                                            showSelectItemsModal={showSelectItemsModal}
                                            showCreateUpdateModal={showCreateUpdateModal}
                                            hasManyClickUrls={hasManyClickUrls}
                                            onPathChange={onPathChange}
                                            isOptimizationWeights={isOptimizationWeights}
                                            isRuleOptimizedData={isRuleOptimizedData}
                                            weightsOptimization={weightsOptimization}
                                            initialWeightOptimizationMethod={initialWeightOptimizationMethod}
                                            trackingMethod={trackingMethod}
                                            lastChangeType={lastChangeType}
                                            isOneItemChange={isOneItemChange}
                                            isOneItemDelete={isOneItemDelete}
                                            entityType={entityType}
                                            isDraggable
                                            dataTest={`${name
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}_${index}_${lander.landerValue
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}`}
                                            key={lander.landerValue}
                                        />
                                    );
                                })}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>

                {unAvailablePathLanders.length ? (
                    <p className="path__items-limit-info">{txt.texts.pathOverLimit(txt.labels.landers)}</p>
                ) : null}

                <Droppable droppableId={(index + 1)?.toString()}>
                    {droppableProvided => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                            {landers &&
                                unAvailablePathLanders.map((lander, landerIndex) => (
                                    <Lander
                                        lander={lander}
                                        landers={landers}
                                        countries={countries}
                                        onLanderByIdChange={onLanderByIdChange}
                                        pathLanders={pathLanders}
                                        pathOffers={pathOffers}
                                        landerIndex={availablePathLanders.length + landerIndex}
                                        showSelectItemsModal={showSelectItemsModal}
                                        showCreateUpdateModal={showCreateUpdateModal}
                                        hasManyClickUrls={hasManyClickUrls}
                                        onPathChange={onPathChange}
                                        isOptimizationWeights={isOptimizationWeights}
                                        weightsOptimization={weightsOptimization}
                                        trackingMethod={trackingMethod}
                                        entityType={entityType}
                                        isDraggable
                                        dataTest={`${name
                                            ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                            .toLowerCase()}_${index}_${lander.landerValue
                                            ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                            .toLowerCase()}`}
                                        key={lander.landerValue}
                                    />
                                ))}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );

    if (isPathOutside) {
        return trackingMethod.type === TRACKING_METHOD.OFFER ? (
            <div className="mb25 flow-rules__offers">
                <div className="j7 mt15">
                    <Label label="Offer" className="path__subtitle" />
                </div>
                {offers &&
                    pathOffers.map((offer, offerIndex) => (
                        <Offer
                            offer={offer}
                            offers={offers}
                            countries={countries}
                            currencies={currencies}
                            isPathOutside={isPathOutside}
                            className={cn({ mt4: offerIndex === clickUrlsMax && hasManyClickUrls })}
                            onOfferByIdChange={onOfferByIdChange}
                            pathOffers={pathOffers}
                            key={offerIndex}
                            offerIndex={offerIndex}
                            showSelectItemsModal={showSelectItemsModal}
                            showCreateUpdateModal={showCreateUpdateModal}
                            hasManyClickUrls={hasManyClickUrls}
                            onPathChange={onPathChange}
                            isOptimizationWeights={isOptimizationWeights}
                            weightsOptimization={weightsOptimization}
                            isSwitchable={false}
                            dataTest={`${name
                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                .toLowerCase()}_${index}_${offer.offerValue
                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                .toLowerCase()}`}
                        />
                    ))}
                {pathOffers.filter(item => !(item.isDeleted || !item.isEnabled)).length < 1 && (
                    <div className="path__offers--btn-box j4">
                        {isIncludeAvailableOffers ? (
                            <AddBtn
                                dataTest="path_add-offer-btn"
                                onClick={() => showSelectItemsModal(MODAL_TYPES.OFFERS, null, 1)}
                                className="mr8 mt8"
                                type="faded"
                            >
                                {txt.labels.offer}
                            </AddBtn>
                        ) : null}
                        <AddBtn
                            dataTest="path_new-offer-btn"
                            className="mt8 mr8"
                            type="filled"
                            isCreateType
                            onClick={() => showCreateUpdateModal(MODAL_TYPES.OFFERS, null, ENTITY_MODE_STATUS.CREATE)}
                        >
                            {txt.labels.newOffer}
                        </AddBtn>

                        {pathOffers.filter(item => !(item.isDeleted || !item.isEnabled)).length < 1 && (
                            <Alert
                                className="path__offers-alert"
                                message={txt.alerts.selectOffer}
                                type={ALERT_TYPE.DANGER}
                            />
                        )}
                    </div>
                )}
                {pathOffers?.length &&
                offers?.find(item => pathOffers?.[0]?.offerValue === item.value)?.isCapEnabled ? (
                    <Alert
                        type={ALERT_TYPE.WARNING}
                        message={txt.alerts.capOfferDoesntWorkWithDirect}
                        className="mt8"
                    />
                ) : null}
            </div>
        ) : (
            <div className="mb25 flow-rules__landers">
                <Label
                    label={txt.labels.landingPage}
                    hint={txt.hints.offers}
                    className="path__subtitle flow-path__subtitle--green"
                />

                {landers &&
                    pathLanders.map((lander, landerIndex) => (
                        <Lander
                            lander={lander}
                            landers={landers}
                            countries={countries}
                            onLanderByIdChange={onLanderByIdChange}
                            pathLanders={pathLanders}
                            pathOffers={pathOffers}
                            landerIndex={landerIndex}
                            showSelectItemsModal={showSelectItemsModal}
                            showCreateUpdateModal={showCreateUpdateModal}
                            hasManyClickUrls={hasManyClickUrls}
                            onPathChange={onPathChange}
                            isOptimizationWeights={isOptimizationWeights}
                            weightsOptimization={weightsOptimization}
                            isHideWeights={trackingMethod.type === TRACKING_METHOD.LANDER_OFFERS}
                            trackingMethod={trackingMethod}
                            lastChangeType={lastChangeType}
                            isOneItemChange={isOneItemChange}
                            isOneItemDelete={isOneItemDelete}
                            entityType={entityType}
                            dataTest={`${name
                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                .toLowerCase()}_${index}_${lander.landerValue
                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                .toLowerCase()}`}
                            key={lander.landerValue}
                        />
                    ))}

                {pathLanders.filter(item => !(item.isDeleted || !item.isEnabled)).length < 1 && (
                    <div className="path__offers--btn-box j4">
                        {isIncludeAvailableLanders ? (
                            <AddBtn
                                dataTest="path_add-lander-btn"
                                onClick={() => showSelectItemsModal(MODAL_TYPES.LANDERS, null, 1)}
                                className="mr8 mt8"
                                type="sub-success"
                            >
                                {txt.labels.lander}
                            </AddBtn>
                        ) : null}
                        <AddBtn
                            dataTest="path_new-lander-btn"
                            className="mt8 mr8"
                            type="success"
                            isCreateType
                            onClick={() => showCreateUpdateModal(MODAL_TYPES.LANDERS, null, ENTITY_MODE_STATUS.CREATE)}
                        >
                            {txt.labels.newLander}
                        </AddBtn>

                        <Alert
                            className="path__offers-alert"
                            message={txt.alerts.selectLander}
                            type={ALERT_TYPE.DANGER}
                        />
                    </div>
                )}
            </div>
        );
    }

    const getPathWeight = () => {
        if (isPathOptimization) {
            const optimizedPathWeight = (() => {
                if (isRuleOptimizedData) {
                    if (optimizedWeightData?.weight >= 0) return optimizedWeightData.weight;
                    else return -1;
                } else if (!isEnabled || isDeleted) return 0;
                else return 100 / activePathsCount;
            })();

            return (
                <div>
                    {txt.labels.optimized}{' '}
                    {entityType === 'campaigns' && (
                        <span className="color--grey-gull">
                            {optimizedPathWeight >= 0 ? `(${optimizedPathWeight.toFixed(2)}%)` : ''}
                        </span>
                    )}
                </div>
            );
        }

        return (
            <EditPlainField
                value={path.weight}
                className="path__edit-plain-field"
                text={() => (
                    <>
                        <span>{path.weight}</span>
                        <span className="ml2 color--grey-gull">{`(${pathAdviceWeight}%)`}</span>
                    </>
                )}
                onChange={e => onPathChange(e, 'weight', true)}
                isReadOnly={isWeightReadOnly}
            />
        );
    };

    const weightsBtn = optimizedWeightData ? (
        <Btn
            className="mr8 ml-auto"
            type={'link'}
            onClick={() => {
                const itemsToWeightModal = optimizedWeightData.landers.map(el => {
                    const lander = landers.find(it => el.id === it.value);

                    if (!lander && !el.offers?.length) return null;

                    const offersToLander = el.offers.map(elem => {
                        const offer = offers.find(it => elem.id === it.value);

                        return {
                            ...offer,
                            optimizedWeight: elem.weight
                        };
                    });

                    return {
                        ...lander,
                        optimizedWeight: el.weight,
                        optimizedOffers: offersToLander
                    };
                });

                showModal({
                    title: `${name}: ${txt.texts.bothCombinedActualWeights}`,
                    body: props => (
                        <ActualWeightsModal
                            {...props}
                            landers={itemsToWeightModal}
                            offers={offers}
                            currentCampaignInfo={currentCampaignInfo}
                            pathInfo={{ id: path.id, name: path.name }}
                        />
                    )
                });
            }}
        >
            {txt.buttons.showActualWeights}
        </Btn>
    ) : null;

    return (
        <Alert
            isWarning={isPathWarning}
            type={ALERT_TYPE.SECONDARY}
            onChange={e => onPathChange(e, 'isOpened')}
            ref={pathForwardedRef}
            disabled={path.isDeleted}
            opened={isOpened}
            className={cn('stickable', { 'stickable--off': isPathInsideRule })}
            LeftComponent={
                <EditablePath
                    isEditing={isEdited}
                    name={name}
                    onChange={onPathChange}
                    toggleOffEditing={toggleSetIsEdited}
                    isReadOnly={isWeightReadOnly}
                />
            }
            RightComponent={
                path.isDeleted ? (
                    <ActionsGroup
                        dataTest={`${name?.replace(/[\s,]/g, e => (e === ',' ? '' : '-')).toLowerCase()}_${index}`}
                        onUndo={() => onPathChange(false, 'isDeleted')}
                    />
                ) : (
                    <>
                        <div
                            className={cn('path__alert-action', {
                                'path__alert-action--optimized': isPathOptimization
                            })}
                        >
                            {getPathWeight()}
                        </div>

                        <div className={cn({ 'path__actions-disable': isEnabled && activePathsCount < 2 })}>
                            <ActionsGroup
                                dataTest={`${name
                                    ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                    .toLowerCase()}_${index}`}
                                onDelete={() => onPathChange(true, 'isDeleted')}
                                isSwitchOn={isEnabled}
                                onSwitch={e => onPathChange(e, 'isEnabled')}
                                onEdit={toggleSetIsEdited}
                            />
                        </div>
                    </>
                )
            }
        >
            <div className="path__alert-content">
                <div className="path__switch-content">
                    <Switcher
                        dataTest="path_hide-referer"
                        label={txt.labels.hideReferer}
                        hint={txt.hints.hideReferer}
                        isSwitchOn={isReferHide}
                        onChange={e => onPathChange(e, 'isReferHide')}
                    >
                        <RadioInput
                            dataTest="path_hide-referer"
                            type="row"
                            value={hideReferer}
                            items={HIDE_REFERRER_DEFAULT_TYPES.slice(1)}
                            onChange={({ target: { value } }) => onPathChange(value, 'hideReferer')}
                        />
                    </Switcher>
                </div>

                <div className="path__switch-content">
                    <Switcher
                        dataTest="path_weights-optimization"
                        label={txt.labels.weightsOptimization}
                        hint={txt.hints.weightsOptimization}
                        isSwitchOn={isOptimizationWeights}
                        disabled={!pathOffers.length}
                        onChange={e => {
                            if (e && isBothCombinedActive)
                                lastChangeType.current = lastChangeTypeFixed.current ?? 'landers';
                            onPathChange(e, 'isOptimizationWeights');
                        }}
                    >
                        <div className="df">
                            <RadioInput
                                dataTest="path_weights-optimization"
                                type="row"
                                value={weightsOptimization}
                                items={weightsOptimizationTypes}
                                onChange={({ target: { value } }) => {
                                    if (value === 'bothCombined') lastChangeType.current = 'landers';
                                    onPathChange(value, 'weightsOptimization');
                                }}
                            />
                            {/* <SelectInput
                                dataTest="path_weight-optimization-method-select-input"
                                items={weightsOptimizationMethods}
                                value={weightsOptimizationMethod}
                                className="path__optimization-method"
                                onChange={e => onPathChange(e, 'weightsOptimizationMethod')}
                            /> */}
                        </div>
                    </Switcher>

                    {isOptimizationWeights && isBothCombinedActive ? (
                        <Alert
                            className="path__offers-alert path__offers-alert--weights"
                            message={txt.texts.bothCombinedLimit}
                            type={ALERT_TYPE.WARNING}
                            RightComponent={initialWeightOptimizationMethod === weightsOptimization && weightsBtn}
                        />
                    ) : null}
                </div>
            </div>

            <div className="path__alert-content">
                {trackingMethod?.value === TRACKING_METHOD.REDIRECT && (
                    <div className="mb15">
                        {renderLanders()}

                        <div className="path__offers--btn-box j4">
                            {isIncludeAvailableLanders ? (
                                <AddBtn
                                    dataTest="path_add-lander-btn"
                                    onClick={() => showSelectItemsModal(MODAL_TYPES.LANDERS)}
                                    className="mr8 mt8"
                                    type="sub-success"
                                    disabled={activeLandersInPath.length >= LANDER_PATH_LIMIT}
                                >
                                    {txt.labels.lander}
                                </AddBtn>
                            ) : null}

                            <AddBtn
                                dataTest="path_new-lander-btn"
                                className="mt8 mr8"
                                type="success"
                                isCreateType
                                onClick={() =>
                                    showCreateUpdateModal(MODAL_TYPES.LANDERS, null, ENTITY_MODE_STATUS.CREATE)
                                }
                                disabled={activeLandersInPath.length >= LANDER_PATH_LIMIT}
                            >
                                {txt.labels.newLander}
                            </AddBtn>

                            {activeLandersInPath.length >= LANDER_PATH_LIMIT ? (
                                <Alert
                                    className="path__offers-alert"
                                    message={txt.alerts.maxItemsInPath(LANDER_PATH_LIMIT, 'landers')}
                                    type={ALERT_TYPE.WARNING}
                                />
                            ) : null}
                        </div>
                    </div>
                )}

                <div className="j7">
                    <Label label={txt.labels.offers} hint={txt.hints.offers} className="path__subtitle" />
                    {pathOffers.length > 1 && (
                        <Label
                            label={
                                isOptimizationWeights && weightsOptimization === 'bothCombined'
                                    ? txt.labels.maxLandersAndOffers(
                                          activeLandersInPath.length,
                                          offerLimit > OFFER_PATH_LIMIT ? OFFER_PATH_LIMIT : offerLimit,
                                          true
                                      )
                                    : txt.labels.dragToChangePriority(txt.labels.offers)
                            }
                            className="color--grey-gull text-small font-weight-normal ml3"
                        />
                    )}
                </div>

                <DragDropContext onDragEnd={dragProps => onDragEnd('offers', dragProps)}>
                    <Droppable droppableId={index.toString()}>
                        {droppableProvided => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                {offers &&
                                    availablePathOffers.map((offer, offerIndex) => (
                                        <Offer
                                            offer={offer}
                                            offers={offers}
                                            countries={countries}
                                            currencies={currencies}
                                            className={cn(
                                                { mt4: offerIndex === clickUrlsMax && hasManyClickUrls },
                                                {
                                                    'alert_offer--inactive':
                                                        offerIndex + 1 > clickUrlsMax && hasManyClickUrls
                                                },
                                                'alert_offer--draggable'
                                            )}
                                            onOfferByIdChange={onOfferByIdChange}
                                            pathOffers={pathOffers}
                                            key={offer.offerValue}
                                            offerIndex={offerIndex}
                                            showSelectItemsModal={showSelectItemsModal}
                                            showCreateUpdateModal={showCreateUpdateModal}
                                            hasManyClickUrls={hasManyClickUrls}
                                            onPathChange={onPathChange}
                                            isOptimizationWeights={isOptimizationWeights}
                                            weightsOptimization={weightsOptimization}
                                            isRuleOptimizedData={isRuleOptimizedData}
                                            initialWeightOptimizationMethod={initialWeightOptimizationMethod}
                                            lastChangeType={lastChangeType}
                                            isOneItemChange={isOneItemChange}
                                            isOneItemDelete={isOneItemDelete}
                                            entityType={entityType}
                                            isDraggable
                                            dataTest={`${name
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}_${index}_${offer.offerValue
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}`}
                                        />
                                    ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {unAvailablePathOffers.length ? (
                        <p className="path__items-limit-info">{txt.texts.pathOverLimit(txt.labels.offers)}</p>
                    ) : null}

                    <Droppable droppableId={(index + 1).toString()}>
                        {droppableProvided => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                {offers &&
                                    unAvailablePathOffers.map((offer, offerIndex) => (
                                        <Offer
                                            offer={offer}
                                            offers={offers}
                                            countries={countries}
                                            currencies={currencies}
                                            className={cn(
                                                { mt4: offerIndex === clickUrlsMax && hasManyClickUrls },
                                                {
                                                    'alert_offer--inactive':
                                                        offerIndex + 1 > clickUrlsMax && hasManyClickUrls
                                                },
                                                'alert_offer--draggable',
                                                'alert_offer--disabled'
                                            )}
                                            onOfferByIdChange={onOfferByIdChange}
                                            pathOffers={pathOffers}
                                            key={offer.offerValue}
                                            offerIndex={availablePathOffers.length + offerIndex}
                                            showSelectItemsModal={showSelectItemsModal}
                                            showCreateUpdateModal={showCreateUpdateModal}
                                            hasManyClickUrls={hasManyClickUrls}
                                            onPathChange={onPathChange}
                                            isOptimizationWeights={isOptimizationWeights}
                                            weightsOptimization={weightsOptimization}
                                            entityType={entityType}
                                            isDraggable
                                            dataTest={`${name
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}_${index}_${offer.offerValue
                                                ?.replace(/[\s,]/g, e => (e === ',' ? '' : '-'))
                                                .toLowerCase()}`}
                                        />
                                    ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {pathOffers.length < clickUrlsMax &&
                        [...Array(clickUrlsMax).keys()]
                            .filter(key => key >= pathOffers.length)
                            .map(el => <GhostOffer key={el} index={el + 1} hasWarning={pathOffers.length > 0} />)}
                </DragDropContext>

                <div className="path__offers--btn-box j4">
                    {isIncludeAvailableOffers ? (
                        <AddBtn
                            dataTest="path_add-offer-btn"
                            onClick={() => showSelectItemsModal(MODAL_TYPES.OFFERS)}
                            className="mr8 mt8"
                            type="faded"
                            disabled={activeOffersInPath.length >= OFFER_PATH_LIMIT}
                        >
                            {txt.labels.offer}
                        </AddBtn>
                    ) : null}

                    <AddBtn
                        dataTest="path_new-offer-btn"
                        className="mt8 mr8"
                        type="filled"
                        isCreateType
                        onClick={() => showCreateUpdateModal(MODAL_TYPES.OFFERS, null, ENTITY_MODE_STATUS.CREATE)}
                        disabled={activeOffersInPath.length >= OFFER_PATH_LIMIT}
                    >
                        {txt.labels.newOffer}
                    </AddBtn>

                    {pathOffers.filter(item => !(item.isDeleted || !item.isEnabled)).length < 1 && (
                        <Alert
                            className="path__offers-alert"
                            message={txt.alerts.selectOffer}
                            type={ALERT_TYPE.DANGER}
                        />
                    )}

                    {activeOffersInPath.length >= OFFER_PATH_LIMIT ? (
                        <Alert
                            className="path__offers-alert"
                            message={txt.alerts.maxItemsInPath(OFFER_PATH_LIMIT, 'offers')}
                            type={ALERT_TYPE.WARNING}
                        />
                    ) : null}
                </div>
            </div>
        </Alert>
    );
};

export default React.forwardRef((props, ref) => <Path {...props} pathForwardedRef={ref} />);
