import React from 'react';
import { useHistory } from 'react-router-dom';

import Langs from '../../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../../Constants';
import { camelToKebabCase } from '../../../../Functions/utils';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';

const DuplicateButton = ({ chapter, entityId, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    const history = useHistory();

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            history.push(`/home/${camelToKebabCase(chapter)}/new`, {
                mode: ENTITY_MODE_STATUS.DUPLICATE,
                id: entityId
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__duplicate-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.duplicate}
        </Btn>
    );
};

export default DuplicateButton;
