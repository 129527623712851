import indexApi from './indexApi';

export const infiniteSelectScrollApi = indexApi.injectEndpoints({
    endpoints: builder => ({
        getInfiniteScrollData: builder.query({
            query: url => url
        }),

        getSuggestData: builder.mutation({
            query: ({ url, string, params }) => ({
                url: `${url}/${string}${params}`,
                method: 'GET'
            })
        })
    })
});

export const { useGetInfiniteScrollDataQuery, useGetSuggestDataMutation } = infiniteSelectScrollApi;
