import React from 'react';

import Icon from '../../Icon';
import Btn from '../../UI/Btn/Btn';

const TdEdit = ({ onClick, type = 'button', dataTest, disabled }) => {
    if (type === 'button') {
        return (
            <Btn dataTest={`td-actions_${dataTest}_edit-btn`} type="icon" onClick={onClick} disabled={disabled}>
                <Icon.Edit3 className="td-actions--edit" />
            </Btn>
        );
    }
    return (
        <span onClick={onClick} className="td-actions">
            <Icon.Edit3 className="td-actions--edit" />
        </span>
    );
};

export default TdEdit;
