import indexApi from './indexApi';
import { transform } from '../transformers';

export const sharedReportsApiTagTypes = {
    sharedReports: 'sharedReports'
};

const sharedReportsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(sharedReportsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getSharedReports: builder.query({
                query: params => `/shared-reports${params || ''}`,
                transformResponse: response => ({
                    items: response.items.map(el => ({ ...el, value: el.id })),
                    pagination: response.pagination
                }),
                providesTags: () => [{ type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' }]
            }),

            getSharedReportById: builder.query({
                query: id => `/shared-reports/${id}`,
                providesTags: (result, error, id) => [{ type: sharedReportsApiTagTypes.sharedReports, id }]
            }),

            // check rtkQueryErrorLogger.js if change name
            getSharedReportStats: builder.query({
                query: ({ id, params }) => `/shared-reports/show/${id}${params || ''}`,
                transformResponse: response => ({
                    report: {
                        items: response.report.items.map(el => transform.reportStatistics.import(el)),
                        total: response.report.total,
                        pagination: transform.pagination.import(response.report.pagination)
                    },
                    reportDetails: response.reportDetails
                }),
                providesTags: () => [{ type: sharedReportsApiTagTypes.sharedReports, id: 'SHARED_REPORT_STATS' }]
            }),

            getSharedReportChart: builder.query({
                query: id => `/shared-reports/chart/${id}`,
                providesTags: () => [{ type: sharedReportsApiTagTypes.sharedReports, id: 'SHARED_REPORT_CHART' }]
            }),

            addSharedReport: builder.mutation({
                query: ({ data }) => ({
                    url: `/shared-reports`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' }];
                }
            }),

            editSharedReport: builder.mutation({
                query: ({ data, id }) => ({
                    url: `/shared-reports/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: sharedReportsApiTagTypes.sharedReports, id },
                            { type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' }
                        ];
                }
            }),

            deleteSharedReport: builder.mutation({
                query: ({ id }) => ({
                    url: `/shared-reports/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' }];
                }
            }),

            stopAccessSharedReport: builder.mutation({
                query: ({ id }) => ({
                    url: `/shared-reports/stop/${id}`,
                    method: 'PATCH'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error) {
                        return [
                            { type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' },
                            { type: sharedReportsApiTagTypes.sharedReports, id }
                        ];
                    }
                }
            }),

            activateAccessSharedReport: builder.mutation({
                query: ({ id }) => ({
                    url: `/shared-reports/activate/${id}`,
                    method: 'PATCH'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error) {
                        return [
                            { type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' },
                            { type: sharedReportsApiTagTypes.sharedReports, id }
                        ];
                    }
                }
            })
        })
    });

export const {
    useGetSharedReportsQuery,
    useGetSharedReportByIdQuery,
    useGetSharedReportStatsQuery,
    useGetSharedReportChartQuery,
    useAddSharedReportMutation,
    useEditSharedReportMutation,
    useDeleteSharedReportMutation,
    useStopAccessSharedReportMutation,
    useActivateAccessSharedReportMutation
} = sharedReportsApi;
