import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { USER_ROLES, MODAL_TYPES } from '../../../Constants';
import { clone, getResponseErrorMessage } from '../../../Functions/utils';
import { showModal, hideModal } from '../../../redux/operations';

import indexApi from '../../../redux/api/indexApi';
import { useGetStorageQuery } from '../../../redux/api/storeApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import {
    useDeleteCustomConversionMutation,
    useGetCustomConversionListQuery
} from '../../../redux/api/customConversionsApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustTable from '../../../components/just-table/just-table';
import AddCustomConversionModal from '../../Modals/add-custom-conversion-modal/add-custom-conversion-modal';
import ArchiveModal from '../../Modals/ArchiveModal';
import Loader from '../../Loader';

import customConversionsColumns from './table-constructor';

import './custom-conversions.scss';

const CustomConversions = () => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const {
        data: customConversionss,
        isSuccess: isCustomConversionsLoaded,
        isFetching: isCustomConversionsFetching
    } = useGetCustomConversionListQuery();

    const customConversions = customConversionss ?? [];

    const inactiveCols = customConversionsColumns.map(col => (col.type === 'actions' ? { ...col, type: null } : col));
    const colsWithRoles = userSettings.role === USER_ROLES.USER ? inactiveCols : customConversionsColumns;

    const rows = customConversions.map((row, index) => ({
        ...row,
        coloredTags: row.tags.map(tag => ({ label: tag })),
        incrementId: index + 1,
        actions: [{ type: 'editRow' }, { type: 'deleteRow' }]
    }));

    const updateStorageConversions = async toastSuccessText => {
        const conversionColumnsListQuery = dispatch(indexApi.endpoints.getCustomConversionColumnsList.initiate());
        const res = await conversionColumnsListQuery;

        if (res.isSuccess) {
            const newStorage = clone(storage);
            newStorage.common.customConversions = res.data;

            const res2 = await dispatch(indexApi.endpoints.changeStorage.initiate(newStorage));

            if (res2.data) {
                toast.success(toastSuccessText);
                hideModal();
            } else toast.error(txt.errors.saveError);
        } else toast.error(txt.errors.noCustomConversionsList);

        conversionColumnsListQuery.unsubscribe();
    };

    const showCreateEventModal = (item = null) => {
        showModal({
            title: txt.titles.customConversionEvent,
            body: props => <AddCustomConversionModal {...props} item={item} updateStorage={updateStorageConversions} />
        });
    };

    const [
        deleteConversion,
        { isSuccess: isDeleteConversionSuccess, isError: isDeleteConversionError, error: deleteConversionError }
    ] = useDeleteCustomConversionMutation();

    useEffect(() => {
        if (isDeleteConversionSuccess) updateStorageConversions(txt.toasts.deleted);
    }, [isDeleteConversionSuccess]);

    useEffect(() => {
        if (isDeleteConversionError)
            toast.error(getResponseErrorMessage(deleteConversionError.data, txt.toasts.saveError));
    }, [isDeleteConversionError]);

    const onActionClick = async (type, item) => {
        switch (type) {
            case 'editRow':
                showCreateEventModal(item);
                break;
            case 'deleteRow':
                showModal({
                    title: txt.titles.deleteConversion,
                    body: props => (
                        <ArchiveModal
                            {...props}
                            type={MODAL_TYPES.CONVERSION}
                            subtitle={txt.texts.getWillBeArchivedSubtitle(item.eventName, true)}
                            onConfirm={() => deleteConversion({ id: item.id })}
                        />
                    )
                });
                break;
            default:
                break;
        }
    };

    const isLoaded = isStorageLoaded && isUserSettingsLoaded && isCustomConversionsLoaded;

    const sectionHeader = (
        <SectionHeader
            title={txt.labels.customConversions}
            button={{
                label: txt.buttons.add,
                onClick: () => showCreateEventModal(),
                hidden: userSettings.role === USER_ROLES.USER
            }}
        />
    );

    if (!isLoaded) return <Loader />;

    return (
        <Section Header={sectionHeader} className="mb25">
            <span className="text-default-light">{txt.texts.customConversions}</span>

            {customConversions.length ? (
                <JustTable className="mt16" cols={colsWithRoles} rows={rows} onActionClick={onActionClick} />
            ) : null}

            {isCustomConversionsFetching && <Loader isFetching />}
        </Section>
    );
};

export default CustomConversions;
