import React, { useState } from 'react';
import { Lock } from 'react-feather';
import moment from 'moment-timezone';

import Langs from '../../../../Langs';
import { REMAINING_FREE_EVENTS } from '../../../../Constants';
import { getIntlFormattedValue } from '../../../../Functions/utils';
import { hideModal, showModal } from '../../../../redux/operations';

import ModalButtonBar from '../../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import AddBillingInfoModal from '../../add-billing-info-modal/add-billing-info-modal';
import AddPaymentMethodModal from '../../add-payment-method-modal/add-payment-method-modal';
import LimitProgressBar from '../../../Overall/LimitProgerssBar/LimitProgressBar';
import ChagePlanModal from '../../change-plan-modal/change-plan-modal';
import Notice, { NOTICE_TYPES } from '../../../Overall/UI/Notice/Notice';

import icon from '../../../../../../media/images/lock-red.svg';

const PLAN_MODAL_TYPES = {
    DEFAULT: 'DEFAULT',
    CHOOSE: 'CHOOSE',
    CONTACT: 'CONTACT'
};

const EventLimitModal = ({ data, isOwner, onClose, userEmail }) => {
    const txt = Langs[global.lng];

    const openedModal = (() => {
        if (data.isBlocked) return isOwner ? { type: PLAN_MODAL_TYPES.CHOOSE } : { type: PLAN_MODAL_TYPES.CONTACT };
        return { type: PLAN_MODAL_TYPES.DEFAULT };
    })();

    const [isAddBillingInfo, setIsAddBillingInfo] = useState(false);
    const [isAddPaymentMethod, setIsAddPaymentMethod] = useState(false);

    const confirmInvoiceDetails = isOk => {
        if (isOk) {
            setIsAddBillingInfo(false);
            setIsAddPaymentMethod(true);
        }
    };

    const confirmPaymentMethod = () => onClose(null, true);

    const onChagePlanModalClose = isSucces => {
        hideModal();
        if (!isSucces) data.onModalPlanClose();
    };

    switch (openedModal.type) {
        case PLAN_MODAL_TYPES.CHOOSE:
            return (
                <ChagePlanModal
                    customClose={onChagePlanModalClose}
                    isBlockedByEvents={true}
                    blockedByEventsData={data}
                    onPlanChanged={onClose}
                />
            );

        case PLAN_MODAL_TYPES.DEFAULT:
            return (
                <div className="block-modal">
                    <div className="block-modal__remainder">
                        <LimitProgressBar
                            amount={data.freeEvents - REMAINING_FREE_EVENTS}
                            totalAmount={data.freeEvents}
                        >
                            <Lock stroke="#ffcad6" />
                            <div className="block-modal__remainder-value">
                                {Math.round((data.freeEvents - REMAINING_FREE_EVENTS) / 1000)}
                                <sup>k</sup>
                            </div>
                            <div className="block-modal__remainder-text">{txt.texts.events}</div>
                        </LimitProgressBar>
                    </div>

                    <div className="block-modal__container">
                        <h2 className="block-modal__title">{txt.titles.updPaymentMethod}</h2>

                        <p className="block-modal__text">
                            {txt.labels.blockModals.eventsLimit.text(getIntlFormattedValue(data.freeEvents))}
                        </p>

                        {!isOwner && (
                            <>
                                <hr />

                                <p className="block-modal__text">
                                    {txt.labels.blockModals.trial.contactOwnerToUpdate(data.ownerEmail)}
                                </p>
                            </>
                        )}

                        {isAddBillingInfo && (
                            <AddBillingInfoModal
                                className="block-modal__billing-info"
                                handleConfirmation={confirmInvoiceDetails}
                                isIntegrated={{
                                    addBackBtn: true,
                                    onBackBtnClick: () => {
                                        setIsAddBillingInfo(false);
                                    }
                                }}
                            />
                        )}

                        {isAddPaymentMethod && (
                            <AddPaymentMethodModal
                                onAdded={confirmPaymentMethod}
                                userEmail={userEmail}
                                isIntegrated={{
                                    onBtnClick: () => {
                                        setIsAddBillingInfo(true);
                                        setIsAddPaymentMethod(false);
                                    }
                                }}
                            />
                        )}

                        {!isAddBillingInfo && !isAddPaymentMethod && isOwner && (
                            <ModalButtonBar
                                rejectButtonText={txt.titles.updPaymentMethod}
                                onReject={() => setIsAddBillingInfo(true)}
                                onConfirm={() => {
                                    hideModal(); // hide events modal

                                    showModal({
                                        noHeader: true,
                                        className: 'chage-plan-modal',
                                        onClose: onChagePlanModalClose,
                                        body: () => (
                                            <ChagePlanModal
                                                customClose={onChagePlanModalClose}
                                                onPlanChanged={onClose}
                                            />
                                        )
                                    });
                                }}
                                confirmButtonText={txt.labels.upgradePlan}
                                separator={txt.labels.or.toLowerCase()}
                                className={'modal-button-bar--big'}
                            />
                        )}
                    </div>
                </div>
            );

        case PLAN_MODAL_TYPES.CONTACT:
            return (
                <div className="block-modal">
                    <div className="block-modal__icon">
                        <img src={icon} width="24" height="24" alt="lock" />
                    </div>

                    <div className="block-modal__container block-modal__container--large">
                        <h2 className="block-modal__title">{txt.labels.billing.accountBlocked}</h2>

                        <Notice type={NOTICE_TYPES.DANGER}>
                            Your campaigns were stopped on <b>{moment(data.blockedAt).format('MMMM DD, YYYY')}</b>{' '}
                            because you have more than {getIntlFormattedValue(data.freeEvents)} events over Free plan
                            limit.
                        </Notice>

                        <p className="block-modal__text mt16">
                            Please contact{' '}
                            <a href={`mailto:${data.ownerEmail}`} className="block-modal__owner">
                                {data.ownerEmail}
                            </a>{' '}
                            to upgrade account plan.
                        </p>
                    </div>
                </div>
            );

        default:
            throw new Error('Invalid modal type');
    }
};

export default EventLimitModal;
