import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, USER_ROLES, WORKSPACE_TYPE } from '../../../Constants/index';
import { checkUrlCharacters, getOnlyASCIICodeText, compare } from '../../../Functions/utils';
import { getCreatedUpdatedUserNames } from '../../../redux/operations';
import useFocus from '../../../Functions/hooks/useFocus';

import indexApi from '../../../redux/api/indexApi';
import { useGetTrafficSourceByIdQuery, useGetTrafficSourcesTokensQuery } from '../../../redux/api/trafficSourcesApi';
import { useGetTrafficSourcesTemplateListQuery } from '../../../redux/api/templatesApi';
import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetWorkspacesAllNamesQuery } from '../../../redux/api/workspacesApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetDefaultDomainsByTypeQuery } from '../../../redux/api/domainsApi';

import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Switcher from '../../Overall/UI/Switcher/Switcher';
import TSParameters from './components/TSParameters';
import HintIcon from '../../Overall/Forms/Inputs/HintIcon';
import Loader from '../../Loader';
import TSPostbackUrl from './components/TSPostbackUrl';
import TSIntegration from './components/TSIntegration';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import ModalTemplates from '../../../components/modal-templates/modal-templates';
import CreatedUpdatedUserInfo from '../../Overall/CreatedUpdatedUserInfo/CreatedUpdatedUserInfo';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

import trafficSourceTemplate from './traffic-source-template';

import './create-traffic-sources.scss';

const trafficSourceInitialTemplate = {
    id: 'custom-traffic-source',
    templateName: 'Custom Traffic Source',
    pixel: null,
    postback: null,
    integrationAuthFields: [],
    isIntegrationAvailable: false,
    isCustomTemplate: true,
    variables: [
        {
            internalToken: 'external_id',
            name: 'External ID',
            queryKey: '',
            tsToken: '',
            isTracked: true
        },
        {
            internalToken: 'cost',
            name: 'Cost',
            queryKey: '',
            tsToken: '',
            isTracked: true
        },
        {
            internalToken: 'var1',
            name: '',
            queryKey: '',
            tsToken: '',
            isTracked: false
        }
    ]
};

const CreateTrafficSource = React.memo(({ id, mode, onConfirm, onTouch, onClose }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const [activeTemplateId, setActiveTemplateId] = useState('custom-traffic-source');
    const [isLoading, setIsLoading] = useState(!!id);
    const [isFetching, setIsFetching] = useState(false);
    const [isNeedValidate, setIsNeedValidate] = useState(false);

    const errorsRef = useRef({});
    const [nameInputRef, setNameFocus] = useFocus();
    const [postbackUrlInputRef, setPostbackUrlFocus] = useFocus();
    const [pixelRedirectUrlInputRef, setPixelRedirectUrlFocus] = useFocus();

    const [trafficSource, setTrafficSource] = useState({ ...trafficSourceTemplate, ...trafficSourceInitialTemplate });

    const originalEditableTrafficSource = useRef(trafficSource);
    const customTrafficSource = useRef(trafficSource);
    const savedCustomTrafficSource = useRef(trafficSource);

    errorsRef.current.name = !trafficSource.name?.trim();
    errorsRef.current.integration = trafficSource.isIntegration ? !trafficSource.integrationId : false;

    const updateTrafficSource = useCallback(updatedFields => {
        setTrafficSource(prevTrafficSource => {
            const updatedTrafficSource = { ...prevTrafficSource, ...updatedFields };

            if (prevTrafficSource.isCustomTemplate) {
                customTrafficSource.current = updatedTrafficSource;
            }

            return updatedTrafficSource;
        });
    }, []);

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: trafficSourceTokens, isSuccess: isTrafficSourceTokensLoaded } = useGetTrafficSourcesTokensQuery();
    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();
    const { data: trackingDomain, isSuccess: isTrackingDomainLoaded } = useGetDefaultDomainsByTypeQuery('tracking');
    const { data: templates, isSuccess: isTemplatesLoaded } = useGetTrafficSourcesTemplateListQuery(undefined, {
        skip: id
    });
    const { data: trafficSourcesData, isSuccess: isTrafficSourcesDataLoaded } = useGetTrafficSourceByIdQuery(id, {
        skip: !id
    });

    useEffect(() => {
        (async () => {
            if (isTrafficSourcesDataLoaded) {
                const createdUpdatedUserNames = await getCreatedUpdatedUserNames(trafficSourcesData, dispatch);

                let templateItemData = {};

                if (trafficSourcesData.templateId) {
                    const templateQuery = dispatch(
                        indexApi.endpoints.getTrafficSourcesTemplate.initiate(trafficSourcesData.templateId)
                    );
                    templateItemData = await templateQuery;
                    templateQuery.unsubscribe();
                }

                const isNeedReplaceWorkspaceId =
                    mode === ENTITY_MODE_STATUS.DUPLICATE &&
                    userSettings.role !== USER_ROLES.OWNER &&
                    trafficSourcesData.workspaceId === userSettings.publicWorkspaceId;

                const resultTrafficSource = {
                    ...trafficSourcesData,
                    integrationAuthFields: templateItemData.data?.integrationAuthFields,
                    templateName: templateItemData.data?.templateName,
                    value: mode === ENTITY_MODE_STATUS.DUPLICATE ? null : trafficSourcesData.value,
                    workspaceId: isNeedReplaceWorkspaceId
                        ? userSettings.initialWorkspaceId
                        : trafficSourcesData.workspaceId,
                    ...createdUpdatedUserNames,
                    ...(mode === ENTITY_MODE_STATUS.DUPLICATE ? { name: `${trafficSourcesData.name} Copy` } : {}),
                    ...(mode === ENTITY_MODE_STATUS.EDIT && trafficSourcesData.isArchived ? { isRestore: true } : {})
                };

                setTrafficSource(resultTrafficSource);
                originalEditableTrafficSource.current = resultTrafficSource;
                setIsLoading(false);
            }
        })();
    }, [trafficSourcesData]);

    useEffect(() => {
        if (isUserSettingsLoaded && isWorkspacesListLoaded && !trafficSource.workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            updateTrafficSource({ workspaceId: initialWorkspaceId });

            originalEditableTrafficSource.current = {
                ...originalEditableTrafficSource.current,
                workspaceId: initialWorkspaceId
            };

            savedCustomTrafficSource.current = {
                ...savedCustomTrafficSource.current,
                workspaceId: initialWorkspaceId
            };
        }
    }, [userSettings, workspacesList]);

    useEffect(() => {
        if (trafficSource.isPostbackUrl && !trafficSource.postbackUrl) setPostbackUrlFocus();
    }, [trafficSource.isPostbackUrl]);

    useEffect(() => {
        if (trafficSource.isPixelUrl && !trafficSource.pixelUrl) setPixelRedirectUrlFocus();
    }, [trafficSource.isPixelUrl]);

    const onNameChange = useCallback(({ target: { value } }) => {
        const transformedValue = getOnlyASCIICodeText(value?.trimStart());
        updateTrafficSource({ name: transformedValue || '' });
    }, []);

    const onCostCurrencyChange = useCallback(
        ({ target: { value } }) => updateTrafficSource({ costCurrency: value }),
        []
    );

    const onWorkspaceChange = useCallback(({ target: { value } }) => updateTrafficSource({ workspaceId: value }), []);

    const onIsPostbackUrlChange = useCallback(status => updateTrafficSource({ isPostbackUrl: status }), []);

    const onIsAutoGeneratePostbackUrlChange = useCallback(
        status =>
            updateTrafficSource({
                isAutoGeneratePostbackUrl: status,
                postbackUrl: !status
                    ? trafficSourcesData?.postbackUrl ?? trafficSourceTemplate.postbackUrl
                    : trafficSource.postbackUrl
            }),
        [trafficSourcesData, trafficSource.postbackUrl]
    );

    const onIsPixelUrlChange = useCallback(status => updateTrafficSource({ isPixelUrl: status }), []);

    const onPixelChange = useCallback(({ target: { value } }) => {
        const transformedValue = getOnlyASCIICodeText(value);
        updateTrafficSource({ pixelUrl: transformedValue || '' });
    }, []);

    const onIsImpressionChange = useCallback(status => updateTrafficSource({ isImpressionTracking: status }), []);
    const onIsDirectChange = useCallback(status => updateTrafficSource({ isDirectTracking: status }), []);

    const getConfirmButtonText = () => {
        const isHideSaveButtons =
            mode === ENTITY_MODE_STATUS.EDIT &&
            userSettings.role === USER_ROLES.USER &&
            trafficSourcesData?.workspaceId === userSettings.publicWorkspaceId;

        if (isHideSaveButtons) return null;
        if (mode === ENTITY_MODE_STATUS.EDIT && trafficSource?.isArchived) return txt.buttons.saveAndRestore;
        if (id) return txt.buttons.save;
        return txt.buttons.apply;
    };

    const onTemplateClick = template => {
        const resultTemplate = template.isCustomTemplate ? customTrafficSource.current : template;

        setTrafficSource({ ...trafficSourceTemplate, ...resultTemplate, workspaceId: trafficSource.workspaceId });
        setActiveTemplateId(template.id);

        originalEditableTrafficSource.current = {
            ...trafficSourceTemplate,
            ...template,
            workspaceId: trafficSource.workspaceId
        };
    };

    useEffect(() => {
        const isCompare =
            compare(trafficSource, originalEditableTrafficSource.current) &&
            compare(savedCustomTrafficSource.current, customTrafficSource.current);

        onTouch(!isCompare);
    }, [trafficSource]);

    const onModalConfirm = async () => {
        setIsNeedValidate(true);

        if (Object.values(errorsRef.current).every(el => !el)) {
            setIsFetching(true);

            const ok = await onConfirm(trafficSource);
            if (!ok) setIsFetching(false);

            return ok;
        }
    };

    const filteredWorkspaceList = useMemo(() => {
        if (workspacesList && userSettings?.role === USER_ROLES.USER) {
            return workspacesList.map(el => {
                if (el.workspaceType === WORKSPACE_TYPE.PUBLIC) return { ...el, disabled: true };
                return el;
            });
        }

        return workspacesList;
    }, [workspacesList, userSettings]);

    const domain = `${trackingDomain?.domain?.ssl ? 'https://' : 'http://'}${trackingDomain?.domain?.name}`;
    const autoGeneratedUrl = `${domain}/conversion-upload?integrationId=${trafficSource.integrationId}&payout={payout}&click_id={external_id}&currency={currency}`;

    const isLoaded =
        isUserSettingsLoaded &&
        isCurrenciesLoaded &&
        isTrafficSourceTokensLoaded &&
        isWorkspacesListLoaded &&
        isTrackingDomainLoaded &&
        (!id ? isTemplatesLoaded : true);

    useEffect(() => {
        if (trafficSource.isCustomTemplate && !trafficSource.name && !id) {
            setTimeout(setNameFocus, 0);
        }
    }, [trafficSource.name, isLoaded]);

    return (
        <>
            <div className="template-modal">
                {isLoading || !isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        {mode === ENTITY_MODE_STATUS.CREATE && (
                            <ModalTemplates
                                templates={[trafficSourceInitialTemplate, ...templates]}
                                activeTemplateId={activeTemplateId}
                                onTemplateClick={onTemplateClick}
                                isIntegrationStatusSplit
                            />
                        )}

                        <div className="template-modal__content">
                            <div className="create-ts-content">
                                <div className="create-ts-content__item">
                                    <SelectInput
                                        dataTest="traffic-source_workspace-select-input"
                                        label={txt.labels.workspace}
                                        items={filteredWorkspaceList}
                                        value={trafficSource.workspaceId}
                                        hint={txt.hints.workspace(txt.labels.trafficSource)}
                                        onChange={onWorkspaceChange}
                                        className="create-ts-content__item--short mb15"
                                    />

                                    <TextInput
                                        dataTest="traffic-source_name-input"
                                        ref={nameInputRef}
                                        id="name"
                                        label={txt.labels.name}
                                        value={trafficSource.name}
                                        onChange={onNameChange}
                                        error={
                                            isNeedValidate &&
                                            !trafficSource.name?.trim() &&
                                            txt.errors.required(txt.labels.name)
                                        }
                                    />
                                </div>

                                {trafficSource.isIntegrationAvailable && (
                                    <div className="create-ts-content__item">
                                        <TSIntegration
                                            modalFields={trafficSource.integrationAuthFields}
                                            templateName={trafficSource.templateName}
                                            templateId={trafficSource.templateId}
                                            isIntegration={trafficSource.isIntegration}
                                            selectedIntegration={trafficSource.integrationId}
                                            updateTrafficSource={updateTrafficSource}
                                            isSendCkick={isNeedValidate}
                                            originalEditableTrafficSource={originalEditableTrafficSource}
                                        />
                                    </div>
                                )}

                                <TSParameters
                                    parameters={trafficSource.variables}
                                    updateTrafficSource={updateTrafficSource}
                                    isSendCkick={isNeedValidate}
                                    errorsRef={errorsRef}
                                />

                                <div className="create-ts-content__item create-ts-content__item--short">
                                    <SelectInput
                                        dataTest="traffic-source_cost-currency-select-input"
                                        label={txt.columnsLabels.costCurrency}
                                        items={currencies}
                                        value={trafficSource.costCurrency}
                                        hint={txt.hints.costCurrency}
                                        onChange={onCostCurrencyChange}
                                        isSearchable
                                    />
                                </div>

                                <div className="ts-params__title j4">
                                    {txt.labels.passingConversion}
                                    <HintIcon className="ml5" hint={txt.hints.passingConversion} />
                                </div>

                                <div className="create-ts-content__item">
                                    <Switcher
                                        dataTest="traffic-source_postback-url"
                                        isSwitchOn={trafficSource.isPostbackUrl}
                                        onChange={onIsPostbackUrlChange}
                                        hint={txt.hints.hasPostbackUrl}
                                        label={txt.labels.hasPostbackUrl}
                                    >
                                        {trafficSource.templateName === 'Facebook' && (
                                            <div className="create-ts-content__postback-generate-block">
                                                <CheckBoxInput
                                                    dataTest="traffic-source_generate_postback-url"
                                                    label="Autogenerate Facebook postback URL"
                                                    className="mt15 ml15"
                                                    value={trafficSource.isAutoGeneratePostbackUrl}
                                                    disabled={!trafficSource.integrationId}
                                                    onChange={({ target: { checked } }) =>
                                                        onIsAutoGeneratePostbackUrlChange(checked)
                                                    }
                                                />

                                                {!trafficSource.integrationId && (
                                                    <span className="create-ts-content__postback-generate">
                                                        Select Integration to enable
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        <TSPostbackUrl
                                            postbackUrl={
                                                trafficSource.isAutoGeneratePostbackUrl
                                                    ? autoGeneratedUrl
                                                    : trafficSource.postbackUrl
                                            }
                                            trafficSourceTokens={trafficSourceTokens}
                                            postbackUrlInputRef={postbackUrlInputRef}
                                            isPostbackApproved={trafficSource.isPostbackApproved}
                                            isPostbackNew={trafficSource.isPostbackNew}
                                            isPostbackDeclined={trafficSource.isPostbackDeclined}
                                            isPostbackChargeback={trafficSource.isPostbackChargeback}
                                            parameters={trafficSource.variables}
                                            isAutoGeneratePostbackUrl={trafficSource.isAutoGeneratePostbackUrl}
                                            updateTrafficSource={updateTrafficSource}
                                        />
                                    </Switcher>

                                    <div className="mt15">
                                        <Switcher
                                            dataTest="traffic-source_pixel-redirect-url"
                                            isSwitchOn={trafficSource.isPixelUrl}
                                            onChange={onIsPixelUrlChange}
                                            hint={txt.hints.pixelRedirectUrl}
                                            label={txt.labels.pixelRedirectUrl}
                                        >
                                            <TextInput
                                                dataTest="traffic-source_pixel-redirect-url-input"
                                                value={trafficSource.pixelUrl || 'https://'}
                                                ref={pixelRedirectUrlInputRef}
                                                onChange={onPixelChange}
                                                checkCorrectSymbol={checkUrlCharacters}
                                                className="mt10"
                                                placeholder={txt.placeholders.exampleCom}
                                            />
                                        </Switcher>
                                    </div>
                                </div>

                                <div className="ts-params__title">{txt.labels.moreTrackingOptions}</div>

                                <div className="create-ts-content__item">
                                    {/* <TextInput
                                            dataTest="traffic-source_pixel-traffic-loss-pct-input"
                                            value={trafficLossPct}
                                            label={txt.labels.trafficLossPct}
                                            hint={txt.hints.trafficLossPct}
                                            hintType="bottom"
                                            onChange={e => onChange(e, 'trafficLossPct')}
                                            className="create-ts-content__item--short mb15"
                                        /> */}
                                    <Switcher
                                        dataTest="traffic-source_impression-tracking"
                                        isSwitchOn={trafficSource.isImpressionTracking}
                                        onChange={onIsImpressionChange}
                                        hint={txt.hints.impressionTracking}
                                        hintType="bottom"
                                        label={txt.labels.impressionTracking}
                                        className="mb15"
                                    />

                                    <Switcher
                                        dataTest="traffic-source_direct-tracking"
                                        isSwitchOn={trafficSource.isDirectTracking}
                                        onChange={onIsDirectChange}
                                        hint={txt.hints.directTracking}
                                        hintType="bottom"
                                        label={txt.labels.directTracking}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {isFetching && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={getConfirmButtonText()}
                isConfirmDisabled={isLoading || isFetching}
                leftSettings={
                    id && trafficSource?.createdAtUserId ? (
                        <CreatedUpdatedUserInfo
                            createdUserName={trafficSource.createdUserName}
                            updatedUserName={trafficSource.updatedUserName}
                            createdAt={trafficSource.createdAt}
                            updatedAt={trafficSource.updatedAt}
                        />
                    ) : null
                }
                onConfirm={onModalConfirm}
                onReject={onClose}
            />
        </>
    );
});

CreateTrafficSource.displayName = 'CreateTrafficSource';
export default CreateTrafficSource;
