import cn from 'classnames';
import React from 'react';

export const TdProfit = React.memo(({ value, rounding }) => {
    const formattedValue =
        typeof value === 'number'
            ? new Intl.NumberFormat('en-EN', {
                  minimumFractionDigits: rounding || 0,
                  maximumFractionDigits: rounding || 0
              }).format(value)
            : value;
    return (
        <span
            className={cn(
                'items-table__td-text',
                { 'items-table__td-text--positive': value > 0 },
                { 'items-table__td-text--negative': value < 0 }
            )}
        >
            {/* {value < 0 && '(-)'} */}
            {/* {value ? '$' : ''} */}
            {formattedValue}
        </span>
    );
});
