export default [
    {
        key: 'name',
        label: 'Integration name',
        type: 'integrationName'
    },
    {
        key: 'isActive',
        label: 'Data transfer',
        type: 'switcherWithStatus'
    },
    {
        key: 'bindedTrafficSources',
        label: 'Tr. Sources',
        className: 'text-align-right',
        cellClassName: 'text-align-right'
    },
    {
        key: 'bindedCampaigns',
        label: 'Campaigns',
        className: 'text-align-right',
        cellClassName: 'text-align-right'
    },
    {
        key: 'updatedAt',
        label: 'Last update',
        type: 'date'
    },
    {
        key: 'actions',
        label: 'Actions',
        type: 'actions'
    }
];
