import React from 'react';
import { RefreshCw } from 'react-feather';

import Langs from '../../../../Langs';
import { getActualDateRange } from '../../../../redux/operations';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import Icon from '../../../../Modules/Overall/Icon';
import DateRangeInput from '../../../../Modules/Overall/Forms/Inputs/DateRangeInput';

const TableControls = ({
    isLoading,
    report,
    reportPeriod,
    reportAvailablePeriod,
    pagination,
    setPagination,
    isShowCompare,
    setIsShowCompare,
    setReportPeriod,
    isShowChart,
    setIsShowChart,
    refresh,
    onShowSettingsClick
}) => {
    const txt = Langs[global.lng];

    const contentSwitcher = 'shared-report-page';

    return (
        <TableControlContainer>
            {report?.isShowPeriod && (
                <DateRangeInput
                    value={{ ...reportPeriod, compare: isShowCompare }}
                    onChange={data => {
                        setIsShowCompare(data.compare);
                        setReportPeriod(getActualDateRange({ ...data, compare: true }));
                    }}
                    disabled={isLoading}
                    containerClassName="mr20"
                    isCompareHidden={!report?.isCompareWithPreviousPeriod}
                    isReadOnly={!report?.isAllowEditPeriod}
                    availableInterval={{ start: reportAvailablePeriod.start, end: reportAvailablePeriod.end }}
                    hideArrows
                    isSharedReportPage
                />
            )}

            <Btn
                dataTest={`table-control_charts-btn_${contentSwitcher}`}
                type={isShowChart ? 'black-link' : 'link'}
                className="mr20"
                onClick={() => setIsShowChart(!isShowChart)}
                disabled={isLoading}
            >
                <Icon.PieChart />
                {isShowChart ? txt.buttons.closeChart : txt.buttons.charts}
            </Btn>

            <Btn
                dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                type="filled"
                disabled={isLoading}
                onClick={refresh}
            >
                <RefreshCw />
                {txt.buttons.refresh}
            </Btn>

            <div className="table-control-container__pagination">
                <TablePagination value={pagination} onChange={setPagination} disabled={isLoading} />

                <Btn
                    dataTest={`table-control_pagination-settings-btn_${contentSwitcher}`}
                    type="icon"
                    onClick={onShowSettingsClick}
                    disabled={isLoading}
                >
                    <Icon.Settings />
                </Btn>
            </div>
        </TableControlContainer>
    );
};

export default TableControls;
