import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { DollarSign } from 'react-feather';
import { toast } from 'react-toastify';

import Langs from '../../../../../Langs';
import { uppercase, getResponseErrorMessage } from '../../../../../Functions/utils';

import { useGetCurrenciesQuery } from '../../../../../redux/api/systemApi';
import { useChangeCampaignsCostFromIntegrationMutation } from '../../../../../redux/api/campaignsApi';

import JustAlert from '../../../../../components/just-alert/just-alert';
import Loader from '../../../../Loader';
import useClickOutside from '../../../../../Functions/hooks/useNewClickOutside';
import ModalButtonBar from '../../../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './campaign-equal-block.scss';

export const CampaignEqualBlock = ({ className, bidAlertData, initialCurrency }) => {
    const txt = Langs[global.lng];

    const blockRef = useRef();
    const [tooltipStatus, setTooltipStatus] = useState({ open: false, top: false });

    const { data: currencies } = useGetCurrenciesQuery();

    const currency = currencies?.find(el => el.value === initialCurrency) ?? {
        value: 'USD',
        label: 'US Dollar',
        symbol: '$'
    };
    const currencySymbol = currency && currency.symbol !== currency.value ? currency.symbol : '';

    const getCurrencyWithValue = value => {
        if (value === null || value === undefined) return '';
        return `${currencySymbol}${value}${!currencySymbol ? currency?.value ?? '' : ''}`;
    };

    const onTooltipClose = e => {
        if (e) e.stopPropagation();
        blockRef.current.parentElement.parentElement.parentElement.parentElement.style.zIndex = 3;
        setTooltipStatus({ open: false, top: false });
    };

    const [
        changeCost,
        {
            isLoading: isChangeCostLoading,
            isSuccess: isChangeCostSuccess,
            isError: isChangeCostError,
            error: changeCostError
        }
    ] = useChangeCampaignsCostFromIntegrationMutation();

    useEffect(() => {
        if (isChangeCostSuccess) {
            toast.success(txt.toasts.saved);
            onTooltipClose();
        }
    }, [isChangeCostSuccess]);

    useEffect(() => {
        if (isChangeCostError) {
            toast.error(getResponseErrorMessage(changeCostError.data, txt.toasts.saveError));
        }
    }, [isChangeCostError]);

    const outsideRef = useClickOutside(
        () => {
            if (tooltipStatus.open) onTooltipClose();
        },
        { addCheckScroll: true }
    );

    const onIconClick = e => {
        e.stopPropagation();

        const POINTER_WRAPPER_HEIGHT = 166;

        const { height, top } = e.currentTarget.getBoundingClientRect();
        const isElementWillFit = window.innerHeight - (top + height) < POINTER_WRAPPER_HEIGHT;

        setTooltipStatus({ open: true, top: isElementWillFit });

        blockRef.current.parentElement.parentElement.parentElement.parentElement.style.zIndex = 4;
    };

    const confirmButtonText = `Apply ${uppercase(bidAlertData.trafficSourceCostMode)} ${getCurrencyWithValue(
        bidAlertData.trafficSourcePrice
    )}`;

    return (
        <div className={cn('campaign-equal-block', className)} ref={blockRef}>
            <div className={cn('rel campaign-equal-block__icon')} style={{ minWidth: 16 }} onClick={onIconClick}>
                <div>
                    <DollarSign size={12} stroke="#fff" />
                </div>
            </div>

            <div
                ref={outsideRef}
                onClick={e => e.stopPropagation()}
                className={cn('campaign-equal-block__tooltip-container', {
                    'campaign-equal-block__tooltip-container--top': tooltipStatus.top,
                    'campaign-equal-block__tooltip-container--open': tooltipStatus.open
                })}
            >
                <JustAlert>
                    <div className="campaign-equal-block__tooltip">
                        <div>
                            <b>Campaign cost</b> and <b>TS Campaign</b> cost (or cost model) are not equal:
                        </div>

                        <div>
                            <b>Campaign:</b>{' '}
                            <span className="campaign-equal-block__value">
                                {uppercase(bidAlertData.campaignCostMode)}{' '}
                                {getCurrencyWithValue(bidAlertData.campaignPrice)}
                            </span>
                        </div>

                        <div>
                            <b>TS Campaign:</b>{' '}
                            <span className="campaign-equal-block__value">
                                {uppercase(bidAlertData.trafficSourceCostMode)}{' '}
                                {getCurrencyWithValue(bidAlertData.trafficSourcePrice)}
                            </span>
                        </div>
                    </div>
                </JustAlert>

                <ModalButtonBar
                    rejectButtonText={txt.buttons.close}
                    confirmButtonText={confirmButtonText}
                    onConfirm={() => changeCost({ ids: [bidAlertData.id], campaignIds: [bidAlertData.campaignId] })}
                    onReject={onTooltipClose}
                />

                {isChangeCostLoading && <Loader isFetching />}
            </div>
        </div>
    );
};

export default CampaignEqualBlock;
