import React from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';

import Langs from '../../../Langs';

import CopyBtn from '../UI/CopyBtn/CopyBtn';
import HintIcon from './Inputs/HintIcon';
import TextInput from './Inputs/TextInput';

const ClipboardField = props => {
    const txt = Langs[global.lng];

    const {
        label,
        rightLabel,
        isRightLabelAbsolute,
        value,
        onChange = () => {},
        readOnly = true,
        type = 'normal',
        hint,
        hintType,
        dataTest,
        className
    } = props;

    return (
        <div className={cn('form-label', className)}>
            <div className="j46 clipboard-field-label-wrapper">
                <div className="j4">
                    {label}
                    {hint && <HintIcon className="ml5" hint={txt?.hints[hint] || hint} type={hintType} />}
                </div>
                {rightLabel && (
                    <div
                        className={cn('j4 clipboard-field-label clipboard-field-label--right', {
                            'clipboard-field-label--absolute': isRightLabelAbsolute
                        })}
                    >
                        {rightLabel}
                    </div>
                )}
                {label === txt.labels.landingPixel ? (
                    <div className="pixelGenerator" onClick={() => {}}>
                        <Icon.HelpCircle size={16} className="mr5" />
                        {txt.labels.pixelGenerator}
                    </div>
                ) : null}
            </div>
            <div className="clipboard-field">
                <TextInput
                    dataTest={`clipboard-field_${dataTest}-input`}
                    value={value}
                    readOnly={readOnly}
                    onChange={onChange}
                    className={type === 'link' ? 'form-input--btn-right' : null}
                />
                <div className={type === 'link' ? 'clipboard-field__btn-wrapper' : null}>
                    <CopyBtn
                        dataTest={`clipboard-field_${dataTest}_copy-btn`}
                        className={type !== 'link' ? 'ml5' : null}
                        copyText={value}
                        type={type}
                        isIconHidden={type !== 'link'}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClipboardField;
