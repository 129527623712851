import React from 'react';

import TabItem from './TabItem';

const HeaderTabs = React.forwardRef((props, ref) => {
    const { tabs, style, refresh, scrollTo, reports } = props;

    return (
        <div className="scroll-wrapper">
            <div className="menu-header-tabs menu-header-tabs--inner" style={style} ref={ref}>
                {tabs.map((tab, index) => {
                    return (
                        <TabItem
                            key={index}
                            tab={tab}
                            reports={reports}
                            tabs={tabs}
                            refresh={refresh}
                            scrollTo={scrollTo}
                        />
                    );
                })}
            </div>
        </div>
    );
});

HeaderTabs.displayName = 'HeaderTabs';
export default HeaderTabs;
