import React from 'react';
import cn from 'classnames';

import { MENU_TYPES } from '../../Constants/index';

const ContextMenu = ({ contextMenuRef, contextMenuSettings, buttons, children }) => {
    return (
        <div
            className={cn('table-context-menu', {
                'table-context-menu--open': contextMenuSettings.isOpen
            })}
            style={{
                top: contextMenuSettings.position.top,
                left: contextMenuSettings.position.left
            }}
            ref={contextMenuRef}
        >
            {children ||
                buttons.map((el, idx) => {
                    if (el === 'hr') return <hr key={idx} />;

                    if (typeof el === 'function') {
                        const result = el(MENU_TYPES.CONTEXT_MENU);

                        if (Array.isArray(result)) {
                            return result.map((element, i) => {
                                if (element?.props?.disabled) return null;

                                return (
                                    <div className="table-context-menu__item" key={i}>
                                        {element}
                                    </div>
                                );
                            });
                        }

                        if (result?.props?.disabled) return null;

                        return (
                            <div className="table-context-menu__item" key={idx}>
                                {result}
                            </div>
                        );
                    }

                    return null;
                })}
        </div>
    );
};

export default ContextMenu;
