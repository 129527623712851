import React from 'react';
import cn from 'classnames';
import { Cpu } from 'react-feather';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import CampaignLinks from '../../../Items/Campaigns/CampaignLinks';
import WarningBlock from './WarningBlock';
import Icon from '../../../Overall/Icon';
import CopyBlock from './copy-block/copy-block';
import CampaignEqualBlock from './campaign-equal-block/campaign-equal-block';

const TdName = ({ chapter, value, row, integrationData, onChange, isSharedReportPage }) => {
    const txt = Langs[global.lng];

    const campaignLinksModal = (id, links) => {
        showModal({
            title: txt.titles.campaignLinks,
            headerType: 'primary',
            body: prop => <CampaignLinks {...prop} id={id} links={links} chapter={chapter} />
        });
    };

    switch (chapter) {
        case 'campaigns':
            return (
                <div className="j4">
                    <span className="items-table__td-text">{value}</span>

                    {!isSharedReportPage && (
                        <>
                            <span
                                data-test={`main-table-body_${chapter}_${row.value}-link-icon`}
                                className="campaignLinks-icon"
                            >
                                <Icon.Link
                                    size={16}
                                    className="color--primary"
                                    onClick={() => campaignLinksModal(row.value, row.links)}
                                />
                            </span>

                            {row.integration?.bidAlertData && (
                                <CampaignEqualBlock
                                    className="ml5"
                                    bidAlertData={row.integration?.bidAlertData}
                                    initialCurrency={row.integration?.apiData?.currency}
                                />
                            )}

                            {row.trafficSourceCampaignId && row.integration ? (
                                <div
                                    className={cn('ml5 rel', {
                                        'integration-td-error-icon-wrapper': integrationData?.isSuccessful === false
                                    })}
                                    style={{ minWidth: 16, marginTop: 3 }}
                                >
                                    {integrationData?.isSuccessful === false ? (
                                        <>
                                            <Icon.IntegratedError />
                                            <div className="integration-icon-tooltip">
                                                {txt.texts.integrationLoadError}
                                            </div>
                                        </>
                                    ) : (
                                        <Cpu size={15} stroke={row.integration?.isActive ? '#78C58D' : '#9AA0B9'} />
                                    )}
                                </div>
                            ) : null}

                            <WarningBlock row={row} onChange={onChange} className="ml5" />
                        </>
                    )}
                </div>
            );

        case 'trafficSources':
            return (
                <div className="j4">
                    <span className="items-table__td-text">{value}</span>

                    {row.isIntegration && !isSharedReportPage ? (
                        <div className="ml5" style={{ minWidth: 16 }}>
                            <Cpu size={15} stroke={row.integration?.isActive ? '#78C58D' : '#9AA0B9'} />
                        </div>
                    ) : null}
                </div>
            );

        case 'sharedReports':
            return (
                <div className="j4">
                    <span className="items-table__td-text">{value}</span>

                    <CopyBlock row={row} onChange={onChange} />
                </div>
            );

        default:
            return <span className="items-table__td-text">{value}</span>;
    }
};

export default TdName;
