import React from 'react';
import { Cpu, Check } from 'react-feather';

import './integration-features.scss';

const IntegrationFeatures = ({ title, features }) => {
    return (
        <>
            <div className="integration-features__header">
                <Cpu size={16} stroke="#9AA0B9" />
                <p className="integration-features__title">{title}</p>
            </div>

            <div className="integration-features__features">
                {features.map(el => (
                    <div className="integration-features__feature" key={el}>
                        <Check size={16} stroke="#00B031" />
                        <span>{el}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default IntegrationFeatures;
