import React from 'react';
import cn from 'classnames';

import Langs from '../../../../../Langs';

import { SELECT_INPUT_OPTIONS_TYPE } from '../../../Forms/Inputs/SelectInput/SelectItem';
import SelectInput from '../../../Forms/Inputs/SelectInput/SelectInput';
import MenuItem from '../menu-item/menu-item';

const AdditionalMenu = ({ menu, activeItem, exceptions = [], isReport, onChange, isLoading }) => {
    const txt = Langs[global.lng].labels;

    return menu.map(menuItem => {
        const { icon, items, label } = menuItem;
        const activeChild = items && items.find(item => item.label === activeItem)?.label;
        const filteredItems = items?.map(item => item.label).filter(item => !exceptions.includes(item));

        return filteredItems
            ? filteredItems.length > 0 && (
                  <div key={label} className={cn('top-menu__select', activeChild && 'top-menu__select--active')}>
                      <SelectInput
                          dataTest={`top-menu_${activeChild || label}-select-input`}
                          value={activeChild || label}
                          items={filteredItems}
                          disabled={isLoading}
                          isHoverable
                          optionsType={isReport ? '' : SELECT_INPUT_OPTIONS_TYPE.LINK}
                          onChange={({ target: { value } }) => onChange(value)}
                      />
                  </div>
              )
            : !exceptions.includes(label) && (
                  <MenuItem
                      dataTest={`top-menu_${label}_div`}
                      key={label}
                      label={txt[label] || label}
                      link={label}
                      icon={icon}
                      isActive={label === activeItem}
                      disabled={isLoading}
                      isReport={isReport}
                      onClick={() => onChange(label)}
                  />
              );
    });
};

export default AdditionalMenu;
