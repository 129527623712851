export const ADDITIONAL_REVENUE_KEY = 'additionalRevenue';

export const mainTableConstructor = {
    trafficSources: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            apiKey: 'incrementId',
            label: 'ID',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'name',
            type: 'displayName',
            label: 'Name',
            important: true,
            default: true,
            sortable: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        }
    ],
    affiliateNetworks: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            apiKey: 'incrementId',
            sortable: true,
            label: 'ID',
            important: true,
            default: true,
            className: 'underline  items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'name',
            label: 'Name',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        }
        // {
        //     key: 'suspicious',
        //     label: 'Suspicious visits',
        //     type: 'profitProgress',
        // }
    ],
    campaigns: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            apiKey: 'incrementId',
            sortable: true,
            label: 'ID',
            important: true,
            default: true,
            className: 'underline  items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'displayName',
            type: 'displayName',
            label: 'Name',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        },
        {
            key: 'rotationName',
            label: 'Rotation',
            className: 'items-table__th--name',
            cellClassName: 'items-table__td--name',
            default: true
            // className: 'text-center',
            // cellClassName: 'text-center'
        },
        {
            key: 'cpa',
            label: 'CPA',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'cpc',
            label: 'CPC',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'cpm',
            label: 'CPM',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'domainName',
            label: 'Domain',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'trafficSourceName',
            label: 'Traffic Source',
            className: 'text-left',
            cellClassName: 'text-left'
        }
        // {
        //     key: 'suspicious',
        //     label: 'Suspicious visits',
        //     type: 'profitProgress',
        // }
    ],
    flows: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            apiKey: 'incrementId',
            sortable: true,
            label: 'ID',
            important: true,
            default: true,
            className: 'underline  items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'name',
            label: 'Name',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        },
        {
            key: 'campaignsCount',
            label: 'Campaigns',
            default: true
            // className: 'text-center',
            // cellClassName: 'text-center'
        }
        // {
        //     key: 'suspicious',
        //     label: 'Suspicious visits',
        //     type: 'profitProgress',
        // }
    ],
    landers: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            label: 'ID',
            apiKey: 'incrementId',
            sortable: true,
            important: true,
            default: true,
            className: 'underline  items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'name',
            label: 'Name',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        },
        {
            key: 'numberOfOffers',
            label: 'Number of Offers',
            // className: 'items-table__th--name',
            default: true
            // sortable: true,
            // filter: true
        }
        // {
        //     key: 'suspicious',
        //     label: 'Suspicious visits',
        //     type: 'profitProgress',
        //
        // }
    ],
    offers: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'id',
            apiKey: 'incrementId',
            sortable: true,
            label: 'ID',
            important: true,
            default: true,
            className: 'underline  items-table__td--id',
            cellClassName: 'text-gray items-table__td--id',
            disabled: true
        },
        {
            key: 'name',
            label: 'Name',
            sortable: true,
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            disabled: true
        },
        {
            key: 'affiliateNetworkName',
            label: 'Aff. Network',
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            default: true,
            sortable: true
        },
        {
            key: 'url',
            label: 'URL',
            sortable: true,
            default: true,
            className: 'underline text-left',
            cellClassName: 'font-weight-normal items-table__td--url',
            type: 'url'
        },
        {
            key: 'payoutPrice',
            label: 'Payout',
            type: 'payout',
            default: true,
            cellClassName: 'font-weight-normal items-table__td--payout',
            sortable: true,
            rounding: 2
        },
        {
            key: 'conversionCap',
            label: 'Conversion CAP',
            default: true,
            sortable: true,
            rounding: 0
        },
        {
            key: 'clickCap',
            label: 'Click CAP',
            default: true,
            sortable: true,
            rounding: 0
        }
        // {
        //     key: 'suspicious',
        //     label: 'Suspicious visits',
        //     type: 'profitProgress',
        //
        // }
    ],
    triggers: [
        {
            key: 'id',
            apiKey: 'incrementId',
            label: 'ID',
            cellClassName: 'text-left select-table-items-body__td--id',
            sortable: true,
            className: 'text-left'
        },
        {
            key: 'status',
            label: 'Status',
            type: 'status',
            className: 'text-left',
            cellClassName: 'select-table-items-body__checkbox',
            sortable: true
        },
        {
            key: 'name',
            label: 'Name',
            cellClassName: 'text-left table__td--strong',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'group',
            label: 'Group',
            cellClassName: 'text-left',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'lastAction',
            label: 'Last action',
            cellClassName: 'text-left',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'object',
            label: 'Object',
            cellClassName: 'text-left',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'objectState',
            label: 'Object State',
            type: 'doubleValue',
            cellClassName: 'text-left',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'condition',
            label: 'Condition',
            type: 'badges',
            cellClassName: 'text-left badge--yellow select-table-items-body__td--triggers-badges',
            className: 'text-left',
            sortable: true
        },
        {
            key: 'action',
            label: 'Action',
            sortable: true
        }
    ],
    reports: [
        {
            key: 'name',
            label: 'Name',
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            sortable: true,
            disabled: true
        }
        // {
        //     key: 'visitsChange',
        //     label: 'Visits change',
        //     type: 'change',
        //
        //     rounding: 2
        // },
        // {
        //     key: 'conversionsChange',
        //     label: 'Conversions change',
        //     type: 'change',
        //     filter: true,
        //     default: true,
        //
        //     rounding: 2
        // }
    ],
    users: [
        // {
        //     key: 'id',
        //     label: 'ID',
        //     default: true,
        //     className: 'underline items-table__td--id',
        //     cellClassName: 'text-gray items-table__td--id',
        //
        //     sortable: true,
        //     important: true,
        //     disabled: true
        // },
        {
            key: 'name',
            label: 'Name',
            default: true,
            className: 'text-left',
            cellClassName: 'text-left',
            sortable: true,
            // important: true,
            disabled: true
        },
        {
            key: 'email',
            label: 'Email',
            className: 'text-left',
            cellClassName: 'text-left',
            sortable: true
        },
        {
            key: 'role',
            label: 'Role',
            className: 'text-left',
            cellClassName: 'text-left',
            type: 'label',
            sortable: true
        }
        /*{
            key: 'workspaces',
            label: 'Workspaces',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            type: 'workspaces',
            sortable: true
        },
        {
            key: 'clicks',
            label: 'Clicks',
            filter: true,
            sortable: true
        },
        {
            key: 'lpCtr',
            label: 'LP CTR',
            sortable: true
        },
        {
            key: 'leads',
            label: 'Leads',
            sortable: true
        },
        {
            key: 'cr',
            label: 'CR',
            cellClassName: 'colored',
            sortable: true,
            type: 'percentage'
        },
        {
            key: 'epc',
            label: 'EPC',
            sortable: true
        },
        {
            key: 'cpc',
            label: 'CPC',
            sortable: true
        },
        {
            key: 'revenue',
            label: 'Revenue',
            sortable: true
        },
        {
            key: 'cost',
            label: 'Cost',
            sortable: true
        },
        {
            key: 'profit',
            label: 'Profit',
            sortable: true,
            className: 'colored',
            cellClassName: 'font-weight-normal colored'
        },
        {
            key: 'roi',
            label: 'ROI',
            sortable: true,
            default: true,
            className: 'colored',
            cellClassName: 'font-weight-normal colored',
            type: 'percentage'
        }*/
    ],
    invitations: [
        {
            key: 'status',
            label: 'Status',
            className: 'text-left',
            cellClassName: 'text-left',
            type: 'status',
            sortable: true
            // important: true
        },
        {
            key: 'email',
            label: 'Email',
            className: 'text-left',
            cellClassName: 'text-left',
            sortable: true
        },
        {
            key: 'userRole',
            label: 'Role',
            className: 'text-left',
            cellClassName: 'text-left',
            type: 'label',
            sortable: true
        },
        /*{
            key: 'workspaces',
            label: 'Workspaces',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            type: 'workspaces',
            sortable: true
        },*/
        {
            key: 'createdAt',
            label: 'Invited on',
            className: 'text-left',
            cellClassName: 'text-left',
            type: 'date',
            sortable: true
        }
    ],
    workspaces: [
        {
            key: 'name',
            label: 'Name',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'workers',
            label: 'Assigned workers',
            className: 'text-left',
            cellClassName: 'text-left',
            type: 'badgesHidingContainer'
        },
        {
            key: 'domains',
            label: 'Domains',
            className: 'text-left',
            cellClassName: 'text-left workspaces__domains-cell',
            type: 'badgesHidingContainer',
            typeModifier: 'yellow'
        },
        {
            key: 'campaignsCount',
            label: 'Campaigns'
        },
        {
            key: 'landersCount',
            label: 'Landers'
        },
        {
            key: 'offersCount',
            label: 'Offers'
        },
        {
            key: 'affiliateNetworksCount',
            label: 'Aff. networks'
        },
        {
            key: 'trafficSourcesCount',
            label: 'Tr. sources'
        }
    ],
    eventLog: [
        {
            key: 'userName',
            label: 'User name',
            sortable: true,
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'entityType',
            label: 'Entity type',
            sortable: true,
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'actionType',
            label: 'Action type',
            type: 'capitalize',
            sortable: true,
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'entityName',
            label: 'Entity name',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'entityIncrementId',
            label: 'Entity ID',
            sortable: true,
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'changedAt',
            label: 'Date&Time',
            sortable: true,
            type: 'date',
            className: 'text-left'
        }
    ],
    sharedReports: [
        {
            key: 'name',
            type: 'displayName',
            label: 'Name',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'userName',
            label: 'Created by user',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'createdAt',
            label: 'Created',
            type: 'date',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'availableDateTo',
            label: 'Available Until',
            type: 'date',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'status',
            label: 'Access status',
            className: 'text-left',
            cellClassName: 'text-left'
        },
        {
            key: 'counterViews',
            label: 'Visits'
        }
    ],
    conversions: [
        {
            key: 'checked',
            label: '',
            type: 'checkField',
            className: 'items-table__td--checkbox',
            cellClassName: 'items-table__td--checkbox',
            default: true,
            important: true,
            disabled: true
        },
        {
            key: 'clickId',
            label: 'Click ID',
            className: 'items-table__th--clickId',
            cellClassName: 'font-weight-normal items-table__td--name',
            sortable: true,
            important: true
        },
        {
            key: 'status',
            label: 'Status',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left items-table__td-conversions-status',
            sortable: true
        },
        {
            key: 'externalId',
            label: 'External ID',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left conversions_externalId',
            sortable: true
        },
        {
            key: 'transactionId',
            label: 'Transaction ID',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            type: 'label',
            sortable: true
        },
        {
            key: 'postback',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Postback timestamp',
            sortable: true,
            type: 'date'
        },
        {
            key: 'visit',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Visit timestamp',
            sortable: true,
            type: 'date'
        },
        {
            key: 'timeSinceClick',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Time since click',
            sortable: true,
            type: 'timeFormat'
        },
        {
            key: 'campaign',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Campaign',
            sortable: false
        },
        {
            key: 'trafficSource',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Traffic source',
            sortable: false
        },
        {
            key: 'offerName',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'Offer',
            sortable: false
        },
        {
            key: 'payout',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-right',
            label: 'Payout',
            sortable: true,
            rounding: 2
        },
        {
            key: 'geo',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            label: 'GEO',
            sortable: true
        },
        {
            key: 'clientIp',
            label: 'IP',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left',
            sortable: true
        }
    ],
    errorLog: [
        {
            key: 'createdAt',
            label: 'Timestamp',
            className: 'items-table__th--timestamp',
            cellClassName: 'items-table__td--timestamp font-weight-normal',
            type: 'date',
            sortable: true,
            default: true,
            important: true
        },
        {
            key: 'campaignName',
            label: 'Campaign',
            className: 'text-left',
            cellClassName: 'text-left font-weight-normal',
            sortable: true
        },
        {
            key: 'category',
            label: 'Category',
            className: 'text-left',
            cellClassName: 'text-left font-weight-normal',
            sortable: true
        },
        {
            key: 'details',
            label: 'Details',
            className: 'text-left',
            cellClassName: 'text-left font-weight-normal',
            sortable: true
        },
        {
            key: 'url',
            label: 'URL',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left', // items-table__td--url
            sortable: true
            // type: 'url'
        },
        {
            key: 'referer',
            label: 'Referer',
            className: 'text-left',
            cellClassName: 'font-weight-normal text-left', // items-table__td--url
            sortable: true
            // type: 'url'
        },
        {
            key: 'remoteIp',
            label: 'IP',
            className: 'text-left',
            cellClassName: 'text-left font-weight-normal',
            sortable: true
        }
    ],
    chapter_reports: [
        {
            key: 'name',
            label: 'Name',
            important: true,
            default: true,
            className: 'underline items-table__th--name',
            cellClassName: 'font-weight-normal items-table__td--name',
            sortable: true,
            disabled: true
        }
    ],
    values: [
        {
            key: 'visitsUnique',
            label: 'Unique Visits',
            className: 'underline',
            default: true,
            sortable: true,
            filter: true
        },
        {
            key: 'visits',
            label: 'Visits',
            supplement: 'visitsChange',
            className: 'underline',
            default: true,
            sortable: true,
            filter: true
        },
        {
            key: 'clicksUnique',
            label: 'Unique Clicks',
            className: 'underline',
            cellClassName: '',
            default: true,
            filter: true,
            sortable: true
        },
        {
            key: 'clicks',
            label: 'Clicks',
            supplement: 'clicksChange',
            className: 'underline',
            cellClassName: '',
            default: true,
            filter: true,
            sortable: true
        },
        {
            key: 'cr',
            label: 'CR',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'percentage'
        },
        {
            key: 'epc',
            label: 'EPC',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'conversions',
            label: 'Conversions',
            supplement: 'conversionsChange',
            filter: true,
            default: true,
            sortable: true
        },
        {
            key: 'revenue',
            label: 'Revenue',
            cellClassName: 'colored',
            supplement: 'revenueChange',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: ADDITIONAL_REVENUE_KEY,
            label: 'Additional Revenue',
            cellClassName: 'colored-simple',
            sortable: true,
            rounding: 2
        },
        {
            key: 'cost',
            label: 'Cost',
            cellClassName: 'colored',
            supplement: 'costChange',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'profit',
            label: 'Profit',
            className: 'font-weight-500',
            cellClassName: 'items-table__td--profit colored',
            supplement: 'profitChange',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'roi',
            label: 'ROI',
            className: 'font-weight-500',
            cellClassName: 'items-table__td--roi colored',
            supplement: 'roiChange',
            filter: true,
            default: true,
            sortable: true,
            rounding: 2,
            type: 'percentage'
        },
        {
            key: 'ctr',
            label: 'CTR',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'percentage'
        },
        {
            key: 'cpv',
            label: 'CPV',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'epv',
            label: 'EPV',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'cv',
            label: 'CV',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'percentage'
        },
        {
            key: 'ap',
            label: 'AP',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'ecpc',
            label: 'ECPC',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'ecpa',
            label: 'ECPA',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'impressions',
            label: 'Impressions',
            filter: true,
            sortable: true
        },
        {
            key: 'ecpm',
            label: 'ECPM',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'money'
        },
        {
            key: 'ictr',
            label: 'ICTR',
            cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2,
            type: 'percentage'
        },
        {
            key: 'rpm',
            label: 'RPM',
            // cellClassName: 'colored',
            filter: true,
            sortable: true,
            rounding: 2
            // type: 'money'
        }
    ],
    comparisonColumns: [
        {
            key: 'clicksChange',
            label: 'Clicks change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'conversionsChange',
            label: 'Conversions change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'costChange',
            label: 'Cost change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'profitChange',
            label: 'Profit change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'revenueChange',
            label: 'Revenue change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'visitsChange',
            label: 'Visits change',
            type: 'change',
            className: 'text-center',
            cellClassName: 'text-center'
        },
        {
            key: 'roiChange',
            label: 'ROI change',
            type: 'changeOnlyProfit',
            className: 'text-center',
            cellClassName: 'text-center'
        }
    ],
    integration: [
        {
            key: 'integrationImpressions',
            cellClassName: 'text-align-right',
            label: 'Impressions',
            isIntegrationCell: true,
            type: 'integration'
        },
        {
            key: 'integrationCost',
            label: 'Cost',
            isIntegrationCell: true,
            type: 'integration',
            subtype: 'money',
            rounding: 2
        },
        {
            key: 'integrationBid',
            className: 'items-table__th--name',
            label: 'Bid',
            isIntegrationCell: true,
            type: 'integration',
            subtype: 'editable'
        },
        {
            key: 'integrationStatus',
            className: 'items-table__th--name',
            cellClassName: 'text-left',
            label: 'Status',
            isIntegrationCell: true,
            isNotExported: true,
            type: 'integration'
        },
        {
            key: 'integrationAction',
            className: 'items-table__th--name',
            label: 'Action',
            isIntegrationCell: true,
            isNotExported: true,
            type: 'integration',
            subtype: 'action'
        }
    ],
    expansionCustomConversions: {
        filter: false,
        sortable: true,
        default: true,
        className: '',
        cellClassName: '',
        rounding: 2
    }
};
