import React from 'react';

import Icon from '../../Icon';
import TdEdit from './TdEdit';
import TdGetSSL from './TdGetSSL';
import { TdDelete } from './TdDelete';
import { TdUndo } from './TdUndo';
import Switcher from '../../UI/Switcher/Switcher';

import './td-actions.scss';

export const TdActions = props => {
    const { actions, row, onActionClick, disabled } = props;
    if (!actions) return false;

    return actions.map((action, index) => {
        const key = row.id + '__' + index;
        switch (action.type) {
            case 'download':
                return (
                    <Icon.Download
                        key={key}
                        className="td-actions td-actions__icon--download"
                        onClick={() => onActionClick(action.type, row)}
                        disabled={disabled}
                    />
                );
            case 'editRow':
                return (
                    <TdEdit
                        dataTest={row.id}
                        key={key}
                        disabled={disabled}
                        onClick={() => onActionClick(action.type, row)}
                    />
                );
            case 'editCell':
                return (
                    <TdEdit
                        dataTest={row.id}
                        key={key}
                        disabled={disabled}
                        onClick={() => onActionClick(action.type, row)}
                    />
                );
            case 'deleteRow':
                return (
                    <TdDelete
                        dataTest={row.id}
                        key={key}
                        onClick={() => onActionClick(action.type, row)}
                        disabled={disabled || action.isDefault}
                    />
                );
            case 'deleteLink':
                return (
                    <TdDelete
                        dataTest={row.id}
                        type="link"
                        key={key}
                        disabled={disabled}
                        onClick={() => onActionClick(action.type, row)}
                    />
                );
            case 'undo':
                return (
                    <TdUndo
                        dataTest={row.id}
                        key={key}
                        disabled={disabled}
                        onClick={() => onActionClick(action.type, row)}
                    />
                );
            case 'switcher':
                return (
                    <Switcher
                        dataTest={`td-actions_${row.id}`}
                        label={action.label || null}
                        key={key}
                        disabled={disabled}
                        isSwitchOn={row.isEnabled}
                        onChange={() => onActionClick(action.type, row)}
                    />
                );
            case 'getSSL':
                return <TdGetSSL key={key} action={action} row={row} onActionClick={onActionClick} />;
            default:
                return null;
        }
    });
};
