import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import useFocus from '../../../Functions/hooks/useFocus';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useUploadConversionsMutation } from '../../../redux/api/conversionsApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';

import './conversions-upload.scss';

const ConversionUpload = () => {
    const txt = Langs[global.lng];

    const [conversionUploadID, setConversionUploadID] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [clickIdsInputRef, setClickIdsFocus] = useFocus();

    const isAllValid = !!conversionUploadID;

    useEffect(setClickIdsFocus, []);

    const [
        uploadConversions,
        {
            isLoading: isUploadConversionsLoading,
            isSuccess: isUploadConversionsSuccess,
            isError: isUploadConversionsError,
            error: uploadConversionsLoading
        }
    ] = useUploadConversionsMutation();

    useEffect(() => {
        if (isUploadConversionsSuccess) {
            toast.success(txt.toasts.saved);
            setConversionUploadID('');
            setIsSubmit(false);
        }
    }, [isUploadConversionsSuccess]);

    useEffect(() => {
        if (isUploadConversionsError)
            toast.error(getResponseErrorMessage(uploadConversionsLoading.data, txt.toasts.notSaved));
    }, [isUploadConversionsError]);

    const onSubmit = async () => {
        setIsSubmit(true);
        if (isAllValid) uploadConversions(conversionUploadID.trim());
    };

    const onClear = () => {
        setConversionUploadID('');
        setClickIdsFocus();
    };

    const sectionHeader = <SectionHeader title={txt.labels['conversion-upload']} />;

    return (
        <Section Header={sectionHeader} className="conversions-upload">
            <JustAlert type={JUST_ALERT_TYPE.INFO}>
                <div className="dfc">
                    <span>{txt.alerts.toolsConversionUpload}</span>
                    <span>{txt.alerts.toolsConversionUpload2}</span>

                    <div className="ml10">
                        <ul>
                            <li>
                                {txt.alerts.toolsConversionUpload3}

                                <JustAlert type={JUST_ALERT_TYPE.INFO} className="bg--white pr10 mt5">
                                    {txt.alerts.toolsConversionUpload4}
                                </JustAlert>
                            </li>
                            <li>{txt.alerts.toolsConversionUpload5}</li>
                            <li>{txt.alerts.toolsConversionUpload6}</li>
                            <li>{txt.alerts.toolsConversionUpload7}</li>
                        </ul>
                    </div>
                </div>
            </JustAlert>

            <TextInput
                dataTest="conversion-upload_click-id-textarea"
                className="mt15"
                value={conversionUploadID}
                placeholder={txt.placeholders.clickIds}
                type="textarea"
                ref={clickIdsInputRef}
                onChange={({ target: { value } }) => setConversionUploadID(value)}
                require
                error={isSubmit && !conversionUploadID && txt.errors.emptyConversions}
            />

            <ModalButtonBar
                rejectButtonText={txt.buttons.clear}
                confirmButtonText={txt.buttons.upload}
                onReject={onClear}
                onConfirm={onSubmit}
                isConfirmDisabled={isSubmit && !isAllValid}
                className="j5 mt15"
            />

            {isUploadConversionsLoading && <Loader isFetching />}
        </Section>
    );
};

export default ConversionUpload;
