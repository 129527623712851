
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../media/images/ellipse.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);

___CSS_LOADER_EXPORT___.push([module.id, ".conversions-upload .just-alert ul{padding-left:0;list-style:none}.conversions-upload .just-alert ul li{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left 2px top 6px;padding-left:17px;margin-bottom:10px}", "",{"version":3,"sources":["webpack://src/JS/Modules/Tools/conversions-upload/%3Cinput%20css%20132%3E"],"names":[],"mappings":"AAAA,mCAAmC,cAAc,CAAC,eAAe,CAAC,sCAAsC,6EAAoE,CAAC,iBAAiB,CAAC,kBAAkB","sourcesContent":[".conversions-upload .just-alert ul{padding-left:0;list-style:none}.conversions-upload .just-alert ul li{background:url(/media/images/ellipse.png) no-repeat left 2px top 6px;padding-left:17px;margin-bottom:10px}"],"sourceRoot":""}]);

export default ___CSS_LOADER_EXPORT___;
