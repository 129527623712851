import React from 'react';

import './table-control-container.scss';

const TableControlContainer = ({ TopComponent, children, RightComponent }) => {
    return (
        <div className="table-control-container">
            {TopComponent && <div className="table-control-container__top-group">{TopComponent}</div>}

            <div className="table-control-container__main-group">
                <div className="table-control-container__control-group">{children}</div>

                {RightComponent && <div className="table-control-container__control-group">{RightComponent}</div>}
            </div>
        </div>
    );
};

export default TableControlContainer;
