import React, { forwardRef } from 'react';

const IntegratedError = forwardRef(() => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_20047_123634)">
                <path d="M13 3H3V13H13V3Z" fill="#FCE2E8" />
                <path
                    d="M8 5.33325V7.99992"
                    stroke="#F06D8D"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 10.6665H8.00708"
                    stroke="#F06D8D"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 3H3.5C3.22386 3 3 3.22386 3 3.5V12.5C3 12.7761 3.22386 13 3.5 13H12.5C12.7761 13 13 12.7761 13 12.5V3.5C13 3.22386 12.7761 3 12.5 3Z"
                    stroke="#F06D8D"
                    strokeWidth="1.07"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13 6.5H14.5"
                    stroke="#F06D8D"
                    strokeWidth="1.07"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13 9.5H14.5"
                    stroke="#F06D8D"
                    strokeWidth="1.07"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M1.5 6.5H3" stroke="#F06D8D" strokeWidth="1.07" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.5 9.5H3" stroke="#F06D8D" strokeWidth="1.07" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M9.5 13V14.5"
                    stroke="#F06D8D"
                    strokeWidth="1.07"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.5 13V14.5"
                    stroke="#F06D8D"
                    strokeWidth="1.07"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M9.5 1.5V3" stroke="#F06D8D" strokeWidth="1.07" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.5 1.5V3" stroke="#F06D8D" strokeWidth="1.07" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_20047_123634">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});

IntegratedError.displayName = 'IntegratedError';
export default IntegratedError;
