import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { MODAL_TYPES } from '../../../Constants/index';
import { hideModal, showModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useGetTimezonesQuery } from '../../../redux/api/systemApi';
import { useGetProjectInfoQuery } from '../../../redux/api/projectApi';
import {
    useChangeSupportAccessMutation,
    useDeleteSupportAccessScheduleMutation,
    useGetSupportAccessScheduleQuery
} from '../../../redux/api/supportAccessApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustTable from '../../../components/just-table/just-table';

import constructor from './table-constructor';

import Loader from '../../Loader';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import AddScheduleModal from '../../Modals/add-schedule-modal/add-schedule-modal';
import ArchiveModal from '../../Modals/ArchiveModal';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';

const SUPPORT_ACCESS_TYPES = [
    { value: 'always', label: 'Always allow' },
    { value: 'schedule', label: 'Use schedule' }
];

const SupportSettings = () => {
    const txt = Langs[global.lng];

    const { data: projectInfo, isSuccess: isProjectInfoLoaded } = useGetProjectInfoQuery();

    const { data: timezones, isSuccess: isTimezonesLoaded } = useGetTimezonesQuery();

    const { data: scheduleData, isSuccess: isScheduleDataLoaded } = useGetSupportAccessScheduleQuery();

    const [
        updateSupportAccess,
        {
            isLoading: isUpdateSupportAccessFetching,
            isSuccess: isUpdateSupportAccessSuccess,
            isError: isUpdateSupportAccessError,
            error: updateSupportAccessError
        }
    ] = useChangeSupportAccessMutation();

    useEffect(() => {
        if (isUpdateSupportAccessSuccess) toast.success(txt.toasts.saved);
    }, [isUpdateSupportAccessSuccess]);

    useEffect(() => {
        if (isUpdateSupportAccessError) {
            toast.error(getResponseErrorMessage(updateSupportAccessError.data, txt.toasts.changeError));
        }
    }, [isUpdateSupportAccessError]);

    const [
        deleteSchedule,
        {
            isLoading: isDeleteScheduleFetching,
            isSuccess: isDeleteScheduleSuccess,
            isError: isDeleteScheduleError,
            error: deleteScheduleError
        }
    ] = useDeleteSupportAccessScheduleMutation();

    useEffect(() => {
        if (isDeleteScheduleSuccess) toast.success(txt.toasts.saved);
    }, [isDeleteScheduleSuccess]);

    useEffect(() => {
        if (isDeleteScheduleError) {
            toast.error(getResponseErrorMessage(deleteScheduleError.data, txt.toasts.deleteError));
        }
    }, [isDeleteScheduleError]);

    const showScheduleModal = (e, values) => {
        showModal({
            title: txt.titles[values ? 'editSchedule' : 'addSchedule'],
            body: props => <AddScheduleModal {...props} timezones={timezones} values={values} />
        });
    };

    const rows = useMemo(() => {
        return (
            scheduleData?.map(row => ({
                ...row,
                actions: [{ type: 'editRow' }, { type: 'deleteRow' }]
            })) ?? []
        );
    }, [scheduleData]);

    const upcomingRows = useMemo(() => rows.filter(el => !el.isExpired), [rows]);
    const expiredRows = useMemo(() => rows.filter(el => el.isExpired).slice(0, 10), [rows]);

    const onActionClick = (actionType, row) => {
        switch (actionType) {
            case 'editRow':
                showScheduleModal(null, {
                    id: row.id,
                    start: row.dateFrom,
                    end: row.dateTo,
                    timezone: row.timezone
                });
                break;
            case 'deleteRow':
                showModal({
                    title: txt.titles.deleteSchedule,
                    body: props => (
                        <ArchiveModal
                            {...props}
                            confirmButtonText={txt.buttons.deleteSchedule}
                            type={MODAL_TYPES.DOMAIN}
                            subtitle={txt.texts.getWillBeArchivedSubtitle(txt.buttons.schedule, true)}
                            onConfirm={() => {
                                hideModal();

                                deleteSchedule({ id: row.id });
                            }}
                        />
                    )
                });
                break;
            default:
                break;
        }
    };

    const isLoaded = isProjectInfoLoaded && isTimezonesLoaded && isScheduleDataLoaded;
    const isFetching = isUpdateSupportAccessFetching || isDeleteScheduleFetching;
    const isScheduleType = projectInfo?.supportAccess && projectInfo?.supportAccess !== SUPPORT_ACCESS_TYPES[1].value;

    const sectionHeader = (
        <SectionHeader
            title={txt.labels.supportAccess}
            button={{
                label: txt.buttons.schedule,
                icon: { type: 'plus' },
                onClick: showScheduleModal,
                disabled: isScheduleType
            }}
        />
    );

    return isLoaded ? (
        <Section Header={sectionHeader} className="mb25">
            <JustAlert className="mb15" type={JUST_ALERT_TYPE.INFO}>
                {txt.texts.supportAccessInfo}
            </JustAlert>

            <span className="text-default-light">
                <RadioInput
                    dataTest="path_hide-referer"
                    type="row"
                    value={projectInfo?.supportAccess ?? SUPPORT_ACCESS_TYPES[1].value}
                    items={SUPPORT_ACCESS_TYPES}
                    onChange={({ target: { value } }) => updateSupportAccess({ data: { supportAccess: value } })}
                />
            </span>

            {isScheduleType ? null : (
                <>
                    {upcomingRows.length > 0 && (
                        <JustTable
                            className="mt5"
                            header={'Upcoming'}
                            cols={constructor}
                            rows={upcomingRows}
                            onActionClick={onActionClick}
                            timezones={timezones}
                            isControledWidth
                        />
                    )}

                    {expiredRows.length > 0 && (
                        <JustTable
                            className="just-table--hide-actions mt16"
                            header={'expired (Max Last 10 items)'}
                            cols={constructor}
                            rows={expiredRows}
                            timezones={timezones}
                            hideTableTh
                            isControledWidth
                        />
                    )}
                </>
            )}

            {isFetching && <Loader isFetching />}
        </Section>
    ) : (
        <Loader />
    );
};

export default SupportSettings;
