import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import produce from 'immer';

import Langs from '../../../Langs';
import { USER_ROLES } from '../../../Constants';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { useGetDefaultCurrencyQuery, useGetTimezonesQuery } from '../../../redux/api/systemApi';
import {
    useGetGeneralSettingsQuery,
    useChangeGeneralSettingsMutation,
    useGetUserSettingsQuery
} from '../../../redux/api/settingsApi';

import Loader from '../../Loader';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Btn from '../../Overall/UI/Btn/Btn';
import Section from '../../../components/section/section';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import HintIcon from '../../Overall/Forms/Inputs/HintIcon';

import './general-settings.scss';

const GeneralSettings = () => {
    const txt = Langs[global.lng];

    const [isTouched, setIsTouched] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [fields, setFields] = useState();

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: timezones, isSuccess: isTimezonesLoaded } = useGetTimezonesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetDefaultCurrencyQuery();

    useEffect(() => {
        if (isGeneralSettingsLoaded) {
            setFields(generalSettings);
            setIsLoading(false);
        }
    }, [generalSettings]);

    const [
        changeAppSettings,
        {
            isLoading: isSettingsUpdate,
            isError: isUpdateGeneralSettingsError,
            error: updateGeneralSettingsError,
            isSuccess: isUpdateGeneralSettingsSuccess
        }
    ] = useChangeGeneralSettingsMutation();

    useEffect(() => {
        if (isUpdateGeneralSettingsError)
            toast.error(getResponseErrorMessage(updateGeneralSettingsError.data, txt.toasts.saveError));
    }, [isUpdateGeneralSettingsError]);

    useEffect(() => {
        if (isUpdateGeneralSettingsSuccess) {
            setIsTouched(false);

            if (fields.defaultHomeScreen !== 'last_opened_report') {
                localStorage.removeItem('lastOpenedGlobalReport');
            }

            toast.success(txt.toasts.saved);
        }
    }, [isUpdateGeneralSettingsSuccess]);

    const onChange = (prop, value) => {
        setIsTouched(true);
        setFields(
            produce(fields, draft => {
                draft[prop] = value;
            })
        );
    };

    const onSaveClick = useCallback(() => changeAppSettings(fields), [fields]);

    const isDataLoaded = isGeneralSettingsLoaded && isUserSettingsLoaded && isTimezonesLoaded && isCurrenciesLoaded;

    const isAllLoaded = isDataLoaded && !isLoading;

    if (!isAllLoaded) return <Loader />;

    const isDisabled = userSettings.role === USER_ROLES.USER || userSettings.role === USER_ROLES.ADMIN;

    return (
        <Section>
            <div className="general-settings">
                <div className="general-settings__column general-settings__column--global">
                    <div className="general-settings__items-title">{txt.titles.generalSettings}</div>

                    <div className="general-settings__items">
                        <RadioInput
                            dataTest="general-settings_conversion-registration-time-reporting"
                            label={txt.labels.convRegTimeReport}
                            value={fields.conversionRegistrationTimeReporting}
                            items={[
                                { label: 'visitTimestamp', value: 'visit' },
                                { label: 'postbackTimestamp', value: 'postback' }
                            ]}
                            hint="convRegTimeReport"
                            onChange={({ target: { value } }) => onChange('conversionRegistrationTimeReporting', value)}
                            className="mb10"
                            disabled={isDisabled}
                        />
                        <SelectInput
                            dataTest="general-settings_report-time-zone-select-input"
                            label={txt.labels.reportTimeZone}
                            value={fields.reportTimeZone}
                            items={timezones}
                            onChange={({ target: { value } }) => {
                                onChange('reportTimeZone', value);
                            }}
                            className="mb15"
                            isSearchable
                            disabled={isDisabled}
                        />
                        <SelectInput
                            dataTest="general-settings_default-currency-select-input"
                            label={txt.labels.defaultCurrency}
                            value={fields.defaultCurrency}
                            items={currencies}
                            onChange={({ target: { value } }) => onChange('defaultCurrency', value)}
                            hint="defaultCurrency"
                            isSearchable
                            disabled={isDisabled}
                        />
                    </div>
                </div>

                <div className="general-settings__column general-settings__column--user">
                    <div className="general-settings__items-title">{txt.titles.userSettings}</div>

                    <div className="general-settings__items">
                        <RadioInput
                            dataTest="general-settings_default-home-screen"
                            label={txt.labels.homeScreen}
                            value={fields.defaultHomeScreen}
                            items={[
                                {
                                    value: 'dashboard',
                                    label: 'dashboard'
                                },
                                {
                                    value: 'campaign_list',
                                    label: 'campaignList'
                                },
                                {
                                    value: 'last_opened_report',
                                    label: 'lastOpenedGlobalReport'
                                }
                            ]}
                            hint="homeScreen"
                            onChange={({ target: { value } }) => onChange('defaultHomeScreen', value)}
                            className="mb10"
                        />

                        <RadioInput
                            dataTest="general-settings_dashboard-sorting"
                            label={txt.labels.dashboardSorting}
                            value={fields.dashboardSorting}
                            items={['ASC', 'DESC']}
                            hint="dashboardSorting"
                            hintType="left"
                            onChange={({ target: { value } }) => onChange('dashboardSorting', value)}
                        />

                        <RadioInput
                            dataTest="general-settings_dashboard-grouping"
                            label={txt.labels.dashboardGrouping}
                            value={fields.dashboardGrouping}
                            items={['revenue', 'cost', 'profit', 'roi', 'visits', 'clicks', 'conversions']}
                            hint="dashboardGrouping"
                            onChange={({ target: { value } }) => onChange('dashboardGrouping', value)}
                        />

                        <div>
                            <RadioInput
                                dataTest="general-settings_opening-report"
                                label={txt.labels.defaultWayToOpenReport}
                                value={fields.openingReport}
                                items={[
                                    { label: 'goToTheReport', value: 'in_focus' },
                                    { label: 'openInTheBackground', value: 'in_background' }
                                ]}
                                hint="defaultWayToOpenReport"
                                hintType="left"
                                onChange={({ target: { value } }) => onChange('openingReport', value)}
                                className="mb10"
                            />

                            <div className="form-small-label mt10">
                                <div className="j4 radio-label mb10">
                                    <div className="mr5">Other</div>
                                    <HintIcon hint="Other settings" />
                                </div>

                                <CheckBoxInput
                                    label="Show account Date & Time"
                                    value={fields.isDateTimeEnabled}
                                    onChange={({ target: { checked } }) => onChange('isDateTimeEnabled', checked)}
                                />

                                <CheckBoxInput
                                    label="Use Tr. Source cost for calculating"
                                    value={fields.isIntegrationCostUsedForCalc}
                                    className="mt15"
                                    hint="Use Tr. Source cost for calculating"
                                    hintType="left"
                                    onChange={({ target: { checked } }) =>
                                        onChange('isIntegrationCostUsedForCalc', checked)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isTouched && (
                <Btn dataTest="general-settings_save-btn" className="plr30" type="filled" onClick={onSaveClick}>
                    {txt.buttons.save}
                </Btn>
            )}

            {isSettingsUpdate && <Loader isFetching />}
        </Section>
    );
};

export default GeneralSettings;
