import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import * as Icon from 'react-feather';

import Langs from '../../../Langs';

import Bookmarks from './components/bookmarks/bookmarks';
import HeaderAvatar from './HeaderAvatar';
import HeaderLink from './components/header-link/header-link';
import HeaderTabs from './HeaderTabs';
import LogoImg from '../../../../../media/images/logo.svg';
import WorkspacesFilter from './components/workspaces-filter/workspaces-filter';
import ButtonPrev from './components/button-prev/button-prev';
import ButtonNext from './components/button-next/button-next';

const MenuHeader = ({ reports, supportModeInfo, balance, generalSettings }) => {
    const txt = Langs[global.lng];

    const [currentScroll, setCurrentScroll] = useState(0);
    const [maxOffset, setMaxOffset] = useState(0);
    const [status, setStatus] = useState('noscroll');
    const [columnsSize, setColumnsSize] = useState(5000);
    const [activeTab, setActiveTab] = useState(null);

    const tabsRef = useRef(null);
    const firstRef = useRef(null);
    const secondRef = useRef(null);

    let fullWidth, visibleWidth, currentOffset;
    const indent = 2;

    const location = useLocation();
    const isActiveHomeTab = location.pathname.split('/')[1] === 'home';

    const bookmarks = useMemo(() => reports.map(el => el.bookmark), [reports]);

    const refreshScrollData = () => {
        if (tabsRef.current) {
            const wrapper = tabsRef.current.parentElement;

            fullWidth = tabsRef.current.scrollWidth;
            visibleWidth = wrapper.clientWidth - indent;
            currentOffset = wrapper.scrollWidth;

            setMaxOffset(fullWidth - visibleWidth);
            setColumnsSize(firstRef.current.clientWidth + secondRef.current.clientWidth);
        }
    };

    useEffect(() => {
        refreshScrollData();
        if (maxOffset <= indent) {
            setStatus('noscroll');
            setCurrentScroll(0);
        } else if (currentOffset < fullWidth) setStatus('scrolling');
        else setStatus('scrollable');
    });

    useEffect(() => {
        let timer;

        window.addEventListener('resize', () => {
            clearTimeout(timer);
            timer = setTimeout(refreshScrollData, 200);
        });

        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', refreshScrollData);
        };
    }, []);

    const checkScrollBorders = () => {
        setCurrentScroll(current => (current < 0 ? 0 : current));
        setCurrentScroll(current => (current > maxOffset ? maxOffset : current));
    };

    const handlePrev = () => {
        setCurrentScroll(current => current - visibleWidth);
        checkScrollBorders();
    };

    const handleNext = () => {
        setCurrentScroll(current => current + visibleWidth);
        checkScrollBorders();
    };

    const handleActiveTab = ({ current }) => {
        if (current) {
            if (current.classList.contains('menu-header-tabs__tab--active')) {
                setActiveTab({
                    offset: current.offsetLeft,
                    width: current.clientWidth
                });
            }
        }
    };

    useEffect(() => {
        if (activeTab) {
            const { offset, width } = activeTab;

            if (offset + width > currentScroll + visibleWidth) {
                const newScroll = offset + width - visibleWidth < 0 ? 0 : offset + width - visibleWidth;
                setCurrentScroll(newScroll);
            } else if (offset < currentScroll) {
                const newScroll = offset - 1;
                setCurrentScroll(newScroll);
            }

            setActiveTab(null);
        }
    }, [activeTab]);

    const [accountDate, setAccountDate] = useState(moment().format('h:mm A MMM D, YYYY'));

    useEffect(() => {
        let timer;

        if (generalSettings?.isDateTimeEnabled) {
            timer = setInterval(() => {
                setAccountDate(moment().format('h:mm A MMM D, YYYY'));
            }, 1000);
        }

        return () => clearTimeout(timer);
    });

    return (
        <div className={cn('menu-header', { 'menu-header--support': supportModeInfo })}>
            <div className="menu-header__first" ref={firstRef}>
                <div className="header-logo">
                    <NavLink to="/">
                        <img src={LogoImg} alt="Logo" width="53" height="28" />
                    </NavLink>
                </div>

                <NavLink
                    to={isActiveHomeTab ? location.pathname : '/'}
                    className={cn('menu-header-tabs__tab menu-header-tabs__tab--icon-home', {
                        'menu-header-tabs__tab--active': isActiveHomeTab
                    })}
                >
                    <div className="mr5 menu-header-tabs__text">
                        <Icon.Home size={16} className="mr5" />
                        <span>{txt.labels.home || 'HOME'}</span>
                    </div>
                </NavLink>
            </div>

            <div className="menu-header__nav" style={{ maxWidth: `calc(100% - ${columnsSize}px)` }}>
                {status !== 'noscroll' && <ButtonPrev onClick={handlePrev} disabled={currentScroll === 0} />}

                <div className="tabs-wrapper">
                    <HeaderTabs
                        reports={reports}
                        tabs={bookmarks.filter(el => el?.isActive)}
                        style={{
                            transform: `translate3d(-${currentScroll}px, 0px, 0px)`
                        }}
                        refresh={refreshScrollData}
                        scrollTo={handleActiveTab}
                        ref={tabsRef}
                    />
                </div>

                {status !== 'noscroll' && <ButtonNext onClick={handleNext} disabled={currentScroll >= maxOffset} />}
            </div>

            <div className="menu-header__second" ref={secondRef}>
                {generalSettings?.isDateTimeEnabled && <div className="menu-header__account-time">{accountDate}</div>}

                <Bookmarks favorites={bookmarks.filter(el => el?.isFavorite)} reports={reports} />

                <WorkspacesFilter />

                <div className="menu-header-items">
                    <HeaderLink id="menu_users" path="/users" />
                    <HeaderLink id="menu_tools" path="/tools" />
                    <HeaderLink id="menu_settings" path="/settings" />
                </div>

                <HeaderAvatar supportModeInfo={supportModeInfo} credit={balance} />
            </div>
        </div>
    );
};

export default MenuHeader;
