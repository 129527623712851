import React from 'react';

import Langs from '../../../Langs';
import CheckBoxInput from './Inputs/CheckBoxInput';
import RadioInput from './Inputs/RadioInput';
import SelectInput from './Inputs/SelectInput/SelectInput';
import TagInput from './Inputs/TagInput';
import TextInput from './Inputs/TextInput';

const LabeledField = props => {
    const txt = Langs[global.lng];

    const { field, onChange, isEditing, fixed, className } = props;
    const {
        error,
        icon,
        type = 'text',
        label,
        value,
        id,
        placeholder,
        require,
        items,
        hint,
        customValue,
        // className,
        tags
    } = field;

    const change = event => {
        field.value = type === 'checkbox' ? event.target.checked : event.target.value;
        field.error = '';
        onChange(field.value);
    };

    switch (type) {
        case 'radio':
            return (
                <RadioInput
                    dataTest={`labeled-field_${id}`}
                    id={id}
                    label={txt.labels[id] || label || ''}
                    items={items}
                    value={value}
                    onChange={change}
                    disabled={!isEditing}
                    hint={hint}
                    customValue={customValue}
                    placeholder={txt.placeholders[id] || placeholder}
                    fixed={fixed}
                    className={className}
                />
            );
        case 'checkbox':
            return (
                <CheckBoxInput
                    dataTest={`labeled-field_${id}-checkbox`}
                    label={txt.labels[id] || label || ''}
                    id={id}
                    value={value}
                    onChange={change}
                    checked={value}
                    disabled={!isEditing}
                    hint={hint}
                />
            );
        case 'select':
            return (
                <SelectInput
                    dataTest={`labeled-field_${id}-select-input`}
                    label={txt.labels[id] || label || ''}
                    className={className}
                    id={id}
                    value={value}
                    placeholder={txt.placeholders[id] || placeholder}
                    require={require}
                    onChange={change}
                    disabled={!isEditing}
                    items={items}
                    error={error}
                    hint={hint}
                    fixed={fixed}
                />
            );
        case 'tagInput':
            return (
                <TagInput
                    dataTest="labeled-field"
                    label={txt.labels[id] || label || ''}
                    hint={hint}
                    value={tags}
                    onChange={() => onChange()}
                />
            );
        default:
            return (
                <TextInput
                    dataTest={`labeled-field_${id}-input`}
                    label={txt.labels[id] || label || ''}
                    className={className}
                    type={type}
                    id={id}
                    value={value}
                    placeholder={txt.placeholders[id] || placeholder}
                    require={require}
                    onChange={change}
                    disabled={!isEditing}
                    hint={hint}
                    error={error}
                    icon={icon}
                    fixed={fixed}
                />
            );
    }
};

export default LabeledField;
