import React from 'react';
import moment from 'moment-timezone';

import './header-support-schedule.scss';

const HeaderSupportSchedule = ({ schedule, timezones }) => {
    return (
        <div className="header-support-schedule">
            {schedule?.map(el => {
                const isCurrentDay = moment(el.dateTo).isSame(moment(el.dateFrom), 'days');

                const formattedDateFrom = moment(el.dateFrom).format('L LT');
                const formattedDateTo = isCurrentDay
                    ? moment(el.dateTo).format('LT')
                    : moment(el.dateTo).format('L LT');

                const activeTimezone = timezones?.find(item => item.value === el.timezone);

                return (
                    <div className="header-support-schedule__item" key={el.id}>
                        <div className="header-support-schedule__time">
                            <span className="mr5">{formattedDateFrom}</span>-
                            <span className="ml5">{formattedDateTo}</span>
                        </div>
                        <div className="header-support-schedule__timezone">
                            <span>{activeTimezone ? activeTimezone.label : el.timezone}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default HeaderSupportSchedule;
