import React from 'react';

import './td-actions.scss';

const TdGetSSL = ({ action, row, onActionClick }) => (
    <button
        className="td-get-ssl"
        onClick={() => onActionClick('getSSL', { action: action.type, domainID: row.id, domainType: row.type })}
    >
        Get SSL
    </button>
);
export default TdGetSSL;
