import React from 'react';
import cn from 'classnames';

import Langs from '../../../Langs';
import { BADGE_SIZE, BADGE_TYPE } from '../../../Constants';
import { showByValue } from '../../../Functions/utils';

import Alert from '../../Overall/UI/Alert/Alert';
import BadgeRound from '../../Overall/UI/BadgeRound/BadgeRound';
import Icon from '../../Overall/Icon';

const ActualWeightsModalOffer = ({ offer, offers, currencies, countries, type, className, onReportClick }) => {
    const {
        name: offerName,
        value: offerId,
        countryTag,
        conversions,
        clicks,
        conversionCap,
        clickCap,
        payout,
        payoutType,
        payoutCurrency,
        isCapEnabled,
        redirectOffer
    } = offer;

    const txt = Langs[global.lng];

    const isConversionsValid = Number.isFinite(conversions) && Number.isFinite(conversionCap);
    const isClicksValid = Number.isFinite(clicks) && Number.isFinite(clickCap);
    const redirectOfferItem = offers?.find(item => item.value === redirectOffer);

    return (
        <>
            <Alert
                disabled={offer.isDeleted}
                type={type}
                className={cn(className, {
                    'alert--weights-modal-offer-cap': isCapEnabled && (isConversionsValid || isClicksValid)
                })}
                LeftComponent={
                    <div className="flow-path__landers-offers">
                        <span>{`${showByValue(countries, countryTag)} - ${offerName}`}</span>

                        {offer.isArchived && (
                            <BadgeRound
                                text={txt.labels.archived}
                                type={BADGE_TYPE.DANGER}
                                className="badge-round--clear-border ml5"
                            />
                        )}
                    </div>
                }
                RightComponent={
                    <>
                        {isCapEnabled && isConversionsValid && (
                            <BadgeRound
                                type={conversionCap <= conversions && BADGE_TYPE.DANGER}
                                size={BADGE_SIZE.MEDIUM}
                                className="mr5 badge-round--clear-border"
                            >
                                <Icon.DollarCoin />
                                {`${conversions}/${conversionCap}`}
                            </BadgeRound>
                        )}
                        {isCapEnabled && isClicksValid && (
                            <BadgeRound
                                type={clickCap <= clicks && BADGE_TYPE.DANGER}
                                size={BADGE_SIZE.MEDIUM}
                                className="mr5 badge-round--clear-border"
                            >
                                <Icon.Click />
                                {`${clicks}/${clickCap}`}
                            </BadgeRound>
                        )}
                        {payoutType && (
                            <BadgeRound size={BADGE_SIZE.MEDIUM} className="mr5">
                                {payout
                                    ? showByValue(currencies, payoutCurrency, 'symbol') + ' ' + payout
                                    : txt.labels[payoutType]}
                            </BadgeRound>
                        )}

                        <div
                            className="actual-weights-modal__report-btn actual-weights-modal__report-btn--offers"
                            onClick={e => onReportClick(e, 'offers', { id: offerId, name: offerName })}
                        >
                            {txt.buttons.report}
                        </div>

                        <div className="path__alert-action">{offer.optimizedWeight}%</div>
                    </>
                }
            />
            {isCapEnabled && (isConversionsValid || isClicksValid) && (
                <Alert
                    disabled={offer.isDeleted}
                    className={cn(
                        'alert__cap',
                        `alert__cap--${
                            (isConversionsValid && conversions >= conversionCap) ||
                            (isClicksValid && clicks >= clickCap)
                                ? 'work'
                                : 'wait'
                        }`
                    )}
                    LeftComponent={
                        <div className="flow-path__landers-offers">
                            {`${showByValue(countries, countryTag)} - ${redirectOfferItem?.name}`}
                        </div>
                    }
                    RightComponent={
                        <>
                            <BadgeRound size={BADGE_SIZE.MEDIUM} className="mr5">
                                {redirectOfferItem?.payout ? (
                                    <>
                                        <span>{redirectOfferItem?.payoutCurrency}</span>
                                        <span className="path__redirect-offer-payout">{redirectOfferItem?.payout}</span>
                                    </>
                                ) : (
                                    txt.labels.auto
                                )}
                            </BadgeRound>

                            <div className="path__alert-action" />
                        </>
                    }
                />
            )}
        </>
    );
};

export default ActualWeightsModalOffer;
