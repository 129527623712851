import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';
import { transformFromCamelCase } from '../../../../Functions/utils';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import RestoreCampaignsModal from '../../../../Modules/Modals/restore-campaigns-modal/restore-campaigns-modal';

const MultiRestoreButton = ({ chapter, isDisabled, onClick, type, rowsForReport, rows }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: `Restore ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
                body: props => (
                    <RestoreCampaignsModal
                        {...props}
                        entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                        rowsForReport={rowsForReport}
                        rows={rows}
                        isMulti
                    />
                )
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__multi-restore-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {`${txt.buttons.restore} ${transformFromCamelCase(chapter, ' ')}(${rowsForReport?.length})`}
        </Btn>
    );
};

export default MultiRestoreButton;
