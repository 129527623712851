import React from 'react';
import { Share2 } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../Langs';
import { ENTITY_MODE_STATUS, USER_ROLES, MENU_TYPES } from '../../../../Constants/index';
import { showModal } from '../../../../redux/operations';
import { capitalize, omitKeys } from '../../../../Functions/utils';

import { topMenuConstructor } from '../../../../Modules/Overall/TopMenu/constructors/topMenuConstructor';
import showItemsModal from '../../../../Modules/Items/showItemsModal';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import Icon from '../../../../Modules/Overall/Icon';
import ButtonIcon from '../../../../components/table-control-services/button-icon/button-icon';
import CreateSharedReportModal from '../../../../Modules/Modals/create-shared-report-modal/create-shared-report-modal';

import DownloadFile, { ShowDownloadFileCheckbox } from '../../../../Modules/Settings/ColumnsSettings/DownloadFile';

const TableControls = ({
    isLoading,
    report,
    rows,
    activeRow,
    columns,
    configuredColumns,
    changeReport,
    onReportClick,
    onShowSettingsClick,
    userSettings,
    storage
}) => {
    const txt = Langs[global.lng];

    const dispath = useDispatch();

    const contentSwitcher = 'report-page';

    const { groupsItems, mainMenu } = topMenuConstructor;

    const showDownloadFileModal = () => {
        showModal({
            title: txt.labels.downloadFile,
            className: 'exportFileModal',
            HeaderRightItem: ShowDownloadFileCheckbox,
            body: props => (
                <DownloadFile
                    {...props}
                    chapter="reports"
                    // TODO: check conversions
                    modifiedChapter={'reports'}
                    columns={columns}
                    dataForRender={report}
                    dateRange={report.dateRange}
                    rows={rows}
                />
            )
        });
    };

    const onDrilldownClick = () => {
        const type = report?.groups?.[activeRow?.level];
        const filters = report?.filters?.map(filter => filter.type);
        const exceptions = [...filters, type];

        changeReport(null, [
            {
                prop: 'groups',
                value: [
                    groupsItems
                        .reduce((acc, el) => {
                            if (el.value) return [...acc, el.value];
                            else return [...acc, ...el.items.map(item => item.value)];
                        }, [])
                        .filter(item => !exceptions.includes(item))[0]
                ]
            },
            {
                prop: 'filters',
                value: [...report.filters, { type, value: activeRow?.value, label: activeRow?.name }]
            },
            {
                prop: 'activeRow',
                value: null
            }
        ]);
    };

    const renderEditReportButtons = menuType => {
        const type = report?.filters?.[0].type;
        const mainTypes = mainMenu.map(el => el.label);
        const mode = ENTITY_MODE_STATUS.EDIT;

        const openEntity = (entity, value) => {
            if (!['campaigns', 'flows', 'triggers'].includes(entity)) {
                showItemsModal(entity, value, { mode }, null, null, dispath);
            } else changeReport({ mode, value, type: entity }, 'status');
        };

        const getButtonTemplate = (group, idx) => (
            <Btn
                className="mr8"
                dataTest="table-control_group-edit-btn"
                key={idx}
                type={menuType === MENU_TYPES.CONTEXT_MENU ? 'link' : 'filled'}
                disabled={isLoading || !activeRow || report?.groups?.[activeRow?.level] !== group}
                onClick={() => openEntity(group, activeRow.value)}
            >
                {menuType !== MENU_TYPES.CONTEXT_MENU && <ButtonIcon type={group} />}
                {txt.buttons.edit} {txt.singulars[group].toLowerCase() || group}
            </Btn>
        );

        if (menuType === MENU_TYPES.CONTEXT_MENU) {
            return report?.groups.map((group, idx) => {
                if (!mainTypes.includes(group)) return null;
                return getButtonTemplate(group, idx);
            });
        }

        return (
            <>
                {mainTypes.includes(type) && report?.filters?.[0].value?.length === 1 && (
                    <Btn
                        dataTest="table-control_main-edit-btn"
                        type={menuType === MENU_TYPES.CONTEXT_MENU ? 'link' : 'filled'}
                        className="mr8"
                        // TODO: [0]
                        onClick={() => openEntity(type, report?.filters?.[0].value[0])}
                        disabled={isLoading}
                    >
                        <ButtonIcon type={type} />
                        {txt.buttons.edit} {txt.singulars[type].toLowerCase() || type}
                    </Btn>
                )}
                {report?.groups.map((group, idx) => {
                    if (!mainTypes.includes(group)) return null;
                    return getButtonTemplate(group, idx);
                })}
            </>
        );
    };

    const getDrillDownButton = type => (
        <Btn
            className="mr8"
            dataTest="table-control_drilldown-btn_reports"
            type={type === MENU_TYPES.CONTEXT_MENU ? 'link' : 'filled'}
            disabled={isLoading || !activeRow || !report?.groups?.[activeRow?.level]}
            onClick={onDrilldownClick}
        >
            {type !== MENU_TYPES.CONTEXT_MENU && <Icon.Filter />}
            {txt.buttons.drilldown}
        </Btn>
    );

    const getExportButton = () => {
        if (userSettings.role !== USER_ROLES.OWNER && !userSettings.isAllowSharingReport) return null;

        return (
            <Btn
                dataTest={`table-control_export-btn_${contentSwitcher}`}
                className="table-control_export"
                type={contentSwitcher === 'report-page' ? '' : 'link'}
                disabled={isLoading || !rows.length}
                onClick={showDownloadFileModal}
            >
                {contentSwitcher === 'report-page' && <Icon.Download className="color--grey-gull" />}
                {txt.buttons.export}
            </Btn>
        );
    };

    const getShareButton = () => {
        if (userSettings.role !== USER_ROLES.OWNER && !userSettings.isAllowSharingReport) return null;

        const openModal = () => {
            const reportName = `${report.bookmark?.label} - ${report?.groups?.map(el => capitalize(el))?.join(' / ')}`;

            showModal({
                title: txt.titles.createSharedReport,
                body: props => (
                    <CreateSharedReportModal
                        {...props}
                        chapter="reports"
                        systemDateRange={report.dateRange}
                        initialName={reportName}
                        storage={{ ...storage, customColumns: configuredColumns }}
                        reportGroups={report?.groups}
                        reportFilters={report?.filters}
                    />
                )
            });
        };

        return (
            <div className="rel ml8">
                <Btn
                    dataTest={`table-control_share-btn_${contentSwitcher}`}
                    className="create-shared-report-button"
                    onClick={openModal}
                    disabled={isLoading || !rows.length || report?.filters?.length > 1}
                >
                    <Share2 size={15} />
                    {txt.buttons.share}
                </Btn>

                <div className="create-shared-report-button-hint create-shared-report-button-hint--report">
                    {txt.hints.createSharedReport}
                </div>
            </div>
        );
    };

    return (
        <TableControlContainer>
            {renderEditReportButtons()}

            <Btn
                dataTest="table-control_report-btn_reports"
                className="plr30 mr8"
                type="filled"
                disabled={isLoading || !activeRow || !report?.groups?.[activeRow?.level]}
                onClick={() => onReportClick(report?.groups?.[activeRow?.level], activeRow)}
            >
                {txt.buttons.report}
            </Btn>

            {getDrillDownButton()}

            {getExportButton()}

            <Btn
                dataTest="table-control_charts-btn_reports"
                type={report.status?.mode === 'charts' && 'black-link'}
                className="ml8"
                onClick={() => {
                    changeReport(report.status?.mode === 'charts' ? { mode: 'default' } : { mode: 'charts' }, 'status');
                }}
                disabled={isLoading}
            >
                <Icon.PieChart />
                {report.status?.mode === 'charts' ? txt.buttons.closeChart : txt.buttons.chart}
            </Btn>

            {getShareButton()}

            <div className="table-control-container__pagination">
                <TablePagination
                    value={report.pagination}
                    disabled={isLoading}
                    onChange={pagination => changeReport(omitKeys(pagination, ['per-page']), 'pagination')}
                />

                <Btn
                    dataTest={`table-control_pagination-settings-btn_${contentSwitcher}`}
                    type="icon"
                    onClick={onShowSettingsClick}
                    disabled={isLoading}
                >
                    <Icon.Settings />
                </Btn>
            </div>
        </TableControlContainer>
    );
};

export default TableControls;
