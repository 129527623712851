import cn from 'classnames';
import React, { useState, useEffect } from 'react';

import CheckBoxInput from '../../../Overall/Forms/Inputs/CheckBoxInput';

export const TdCheckbox = React.memo(
    ({
        value,
        onChange,
        isStark,
        type,
        disabled,
        chapter,
        id,
        className,
        onMouseUp,
        setRowIsChecked,
        supportMultiMouseSelect
    }) => {
        const [isActive, setIsActive] = useState(value);

        useEffect(() => {
            setIsActive(value);
        }, [value]);

        const changeIsChecked = () => {
            setIsActive(!!parseInt(localStorage.getItem('activeCheckedElementStatus'), 10));
            setRowIsChecked(!!parseInt(localStorage.getItem('activeCheckedElementStatus'), 10));
        };

        const handleMouseOver = ev => {
            if (ev.buttons === 1 && localStorage.getItem('activeCheckedElementStatus') !== null) changeIsChecked();
        };

        const handleMouseDown = ev => {
            const isChecked = !ev.target.parentElement.querySelector('input').checked;
            localStorage.setItem('activeCheckedElementStatus', isChecked ? '1' : '0');

            changeIsChecked();
            onMouseUp();
        };

        return (
            // eslint-disable-next-line
            <label
                className={cn('td-checkbox', type && 'td-checkbox--' + type, className)}
                onMouseOver={supportMultiMouseSelect ? handleMouseOver : () => {}}
                onMouseDown={supportMultiMouseSelect ? handleMouseDown : () => {}}
            >
                <CheckBoxInput
                    dataTest={`td-checkbox_${chapter}_${id}-checkbox`}
                    value={isActive}
                    isStark={isStark}
                    onChange={supportMultiMouseSelect ? () => {} : onChange}
                    disabled={disabled}
                />
            </label>
        );
    }
);
