import { useState, useEffect } from 'react';

const useCopyToggle = (timeout = 500) => {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        let timerId;

        if (isCopied) {
            timerId = setTimeout(setIsCopied, timeout, false);
        }

        return () => clearTimeout(timerId);
    }, [isCopied]);

    return [isCopied, setIsCopied];
};

export default useCopyToggle;
