import React from 'react';
import cn from 'classnames';

export const TdTextArray = props => {
    const { item, columnOptions, disabled } = props;
    if (!item && item !== 0) return null;
    const value = item.split('\n');

    return (
        <div
            className={cn('td-textArray', columnOptions?.summaryClassName, {
                'td-textArray--disabled': disabled
            })}
        >
            <span>{value[0]}</span>
            {value.length > 1 ? <span>{`+${value.length - 1}`}</span> : null}
        </div>
    );
};
