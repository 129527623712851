import React from 'react';
import cn from 'classnames';

import useClickOutside from '../../../../Functions/hooks/useClickOutside';
import useToggle from '../../../../Functions/hooks/useToggle';

import './popover.scss';

const Popover = ({ children, Component, prop }) => {
    const { isToggled, toggle, toggleOff } = useToggle();
    const ref = useClickOutside(toggleOff);

    return (
        <div className="popover" ref={ref}>
            <div onClick={toggle} className={cn('popover__children', { 'popover__children--selected': isToggled })}>
                {children}
            </div>
            {isToggled && (
                <div className="popover__container">
                    <Component onClose={toggleOff} prop={prop} />
                </div>
            )}
        </div>
    );
};

export default React.memo(Popover);
