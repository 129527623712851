import React from 'react';
import { useHistory } from 'react-router-dom';

import Langs from '../../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../../Constants';
import { camelToKebabCase } from '../../../../Functions/utils';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';

const CreateButton = ({ chapter, className, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    const history = useHistory();

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            history.push(`/home/${camelToKebabCase(chapter)}/new`, {
                mode: ENTITY_MODE_STATUS.CREATE,
                id: null
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__create-btn--${chapter}`}
            className={className}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.create} {txt.singulars[chapter]?.toLowerCase() || chapter}
        </Btn>
    );
};

export default CreateButton;
