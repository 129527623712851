/* eslint-disable guard-for-in, no-unused-vars */
import jwt from 'jsonwebtoken';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';

export const CREDENTIALS_KEY = 'session';
export const IS_SUPPORT_KEY = 'isSupport';
export const LAST_USER_ID_KEY = 'lastUserId';
export const DEFAULT_CREDENTIALS = { token: null, tokenDate: null, refresh: null, isNeedRefresh: false };

export const getLocalStorageSession = () => JSON.parse(localStorage.getItem(CREDENTIALS_KEY));
export const getSessionStorageSession = () => JSON.parse(sessionStorage.getItem(CREDENTIALS_KEY));

export const getSessionStorageTabId = () => getSessionStorageSession()?.tabId;
export const getSessionStorageToken = () => getSessionStorageSession()?.tokenToSync;
export const getSessionStorageUserId = () => getSessionStorageSession()?.userId;

export const findUserIndex = userId => {
    const session = getLocalStorageSession();
    if (!session?.length) return -1;

    return session.findIndex(el => el.userId === userId);
};

export const findTabIndex = (userIndex, tabId) => {
    const session = getLocalStorageSession();
    if (!session?.length || !session[userIndex]) return -1;

    return session[userIndex].tabs.findIndex(el => el.tabId === tabId);
};

export const getLastUserId = () => {
    const lastUserId = JSON.parse(localStorage.getItem(LAST_USER_ID_KEY));
    if (lastUserId) return lastUserId;

    return null;
};

export const getLocalStorageUserInfo = (userId, param) => {
    const session = getLocalStorageSession();
    if (!session?.length) return;

    if (userId) {
        const userIndex = findUserIndex(userId);
        return session[userIndex]?.[param];
    }

    return session[session.length - 1]?.[param];
};

export const setLocalStorageSession = session => localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(session));
export const setSessionStorageSession = session => sessionStorage.setItem(CREDENTIALS_KEY, JSON.stringify(session));

export const setLastOpenedSecondMenuItem = (key, item) => {
    let menu = JSON.parse(localStorage.getItem('lastOpenedSecondMenu'));

    if (menu) menu[key] = item;
    else menu = { [key]: item };

    localStorage.setItem('lastOpenedSecondMenu', JSON.stringify(menu));
};

export const getLastOpenedSecondMenuItem = key => {
    const menu = JSON.parse(localStorage.getItem('lastOpenedSecondMenu'));

    if (menu) return menu[key];

    return null;
};

export const getCredentials = () => {
    const userId = getSessionStorageUserId();
    const userIndex = findUserIndex(userId);
    let credentials;

    if (userId && userIndex !== -1) credentials = getLocalStorageSession()[userIndex];
    else credentials = DEFAULT_CREDENTIALS;

    const token = credentials?.token || null;

    if (token) {
        let decoded;

        try {
            decoded = jwt.decode(token);
        } catch {
            decoded = null;
        }

        const currentTime = Date.now() / 1000;
        if (decoded !== null && +decoded.exp < currentTime) credentials.isNeedRefresh = true;
    }

    return { ...credentials, userId };
};

export const saveCredentials = (credentials, userEmail, isLongSession, cb, asSupport, adminCredentials) => {
    const currentCredentials = getLocalStorageSession() || [];

    // refresh token
    if (credentials?.userId) {
        const user = currentCredentials.find(el => el.userId === credentials.userId);

        if (user) {
            if (!credentials.token) {
                const userIndex = findUserIndex(credentials.userId);
                currentCredentials.splice(userIndex, 1);
            } else {
                user.token = credentials.token;
                user.refresh = credentials.refresh;
                user.tokenDate = credentials.tokenDate;

                const session = getSessionStorageSession();
                session.tokenToSync = credentials.token;
                setSessionStorageSession(session);
            }
        }

        localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(currentCredentials));
        return;
    }

    const issetUserData = currentCredentials.find(el => el.userId === userEmail);

    if (issetUserData) {
        const tabId = uuid();

        sessionStorage.setItem(
            CREDENTIALS_KEY,
            JSON.stringify({
                tabId,
                userId: issetUserData.userId,
                tokenToSync: issetUserData.token
            })
        );

        const userIndex = findUserIndex(issetUserData.userId);

        if (!issetUserData.isRemember) {
            currentCredentials[userIndex].tabs.push({ tabId });
            localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(currentCredentials));
        }

        if (issetUserData.isLastTabClosed) {
            currentCredentials[userIndex].token = credentials.token;
            currentCredentials[userIndex].refresh = credentials.refresh;
            currentCredentials[userIndex].tokenDate = credentials.tokenDate;
            currentCredentials[userIndex].adminToken = adminCredentials;
            currentCredentials[userIndex].isLastTabClosed = false;

            localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(currentCredentials));
        }

        localStorage.setItem(LAST_USER_ID_KEY, JSON.stringify(issetUserData.userId));
        if (cb) cb();
        return;
    }

    const tabId = uuid();
    const userId = userEmail;

    sessionStorage.setItem(
        CREDENTIALS_KEY,
        JSON.stringify({
            tabId,
            userId,
            tokenToSync: credentials.token
        })
    );

    currentCredentials.push({
        userId,
        ...credentials,
        isRemember: isLongSession,
        ...(!isLongSession ? { tabs: [{ tabId }] } : {}),
        ...(!isLongSession ? { isLastTabClosed: false } : {}),
        ...(asSupport
            ? {
                  adminToken: adminCredentials,
                  support: {
                      supportId: asSupport.supportId,
                      userLogin: asSupport.userLogin,
                      userRole: asSupport.userRole
                  }
              }
            : {})
    });

    localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(currentCredentials));
    localStorage.setItem(LAST_USER_ID_KEY, JSON.stringify(userId));

    if (cb) cb();
};

export const clearCredentials = () => {
    localStorage.removeItem(CREDENTIALS_KEY);
    sessionStorage.removeItem(CREDENTIALS_KEY);
    localStorage.removeItem(LAST_USER_ID_KEY);
};

export const logout = cb => {
    let session = getLocalStorageSession();
    const userId = getSessionStorageUserId();
    const userIndex = findUserIndex(userId);

    session?.splice(userIndex, 1);

    // remove not remember users
    if (session?.length) {
        session = session.filter(el => {
            if (el.isRemember === false && el.tabs.length === 0) return false;
            else return true;
        });
    }

    if (session?.length) {
        setLocalStorageSession(session);
        sessionStorage.removeItem(CREDENTIALS_KEY);
        localStorage.removeItem(LAST_USER_ID_KEY);
    } else clearCredentials();

    if (typeof cb === 'function') cb();
    else window.location.reload();
};

export const handleObjectChange =
    (updateObject, updateFunction) =>
    (data, prop = '', isNumber) => {
        let value;
        if (data?.target) {
            value = data.target.type === 'checkbox' ? data.target.checked : data.target.value;
        } else {
            value = data;
        }

        value = isNumber ? +value : value;

        const props = prop.split('.');
        const currentObject = props.reduce((res, chapter, index) => {
            if (props.length !== index + 1) res = res[chapter];
            return res;
        }, updateObject);

        currentObject[props.pop()] = value;
        updateFunction();
    };

export const omitKeys = (obj = {}, keys = []) =>
    keys.reduce((acc, key) => {
        const { [key]: omit, ...rest } = acc;
        return rest;
    }, obj);

export const compare = (a, b, keys = []) => {
    if ((Array.isArray(a), Array.isArray(b)))
        return JSON.stringify([...a]?.sort?.()) === JSON.stringify([...b]?.sort?.());
    else if (Array.isArray(a) || Array.isArray(b)) throw new Error('Different types detected!');
    return (
        JSON.stringify(Object.entries(omitKeys(a, keys)).sort?.()) ===
        JSON.stringify(Object.entries(omitKeys(b, keys)).sort?.())
    );
};

// eslint-disable-next-line no-console
export const getObjectsDifference = (obj1, obj2) =>
    // eslint-disable-next-line no-console
    console.log(Object.keys(obj1).reduce((acc, key) => (obj1[key] !== obj2[key] ? [...acc, key] : acc), []));

export const getOnlyDecimalNumbers = data => {
    const value = data.target ? data.target.value : data;
    return value.replace(/[^\d.]|\.(?=.*\.)/g, '');
};

export const clone = obj => {
    const data = obj instanceof Array ? [] : {};
    for (const i in obj) data[i] = typeof obj[i] === 'object' && obj[i] != null ? clone(obj[i]) : obj[i];
    return data;
};

export const isEmpty = obj => !!obj && !Object.keys(obj).length;

export const showByValue = (items, value, label = 'label') => {
    return items?.find(el => el.value === value)?.[label] || '';
};

export const clearObject = (obj, props, isMatched) => {
    if (isMatched) {
        Object.keys(obj).forEach(prop => {
            if (!props.includes(prop)) {
                delete obj[prop];
            }
        });
    } else {
        props.forEach(prop => delete obj[prop]);
    }
};

export const toggleNestedRows = (item, isChecked) => {
    item.isChecked = isChecked;
    item.isStark = false;
    if (item?.children?.length > 0) {
        item?.children.forEach(child => toggleNestedRows(child, isChecked));
    }
};

export const deepEvery = (childs, fn, prop = 'children') =>
    childs.reduce((acc, child) => {
        if (child?.[prop]?.length > 0) {
            return deepEvery(child[prop], fn) && acc;
        }
        return acc;
    }, childs.every(fn));

export const deepSome = (childs, fn, prop = 'children') =>
    childs.reduce((acc, child) => {
        if (child?.[prop]?.length > 0) {
            return deepSome(child[prop], fn) || acc;
        }
        return acc;
    }, childs.some(fn));

export const toggleStark = item => {
    if (item?.children?.length > 0) {
        if (deepEvery(item?.children, el => el.isChecked)) {
            item.isChecked = true;
            item.isStark = false;
        } else if (deepEvery(item?.children, el => !el.isChecked)) {
            item.isChecked = false;
            item.isStark = false;
        } else if (deepSome(item?.children, el => el.isChecked)) {
            item.isChecked = false;
            item.isStark = true;
        } else {
            item.isStark = false;
        }
    } else item.isStark = false;
    return item;
};

export const absToStr = value => (value < 0 ? value.toString().substring(1) : value?.toString());

export const swapKeyValue = obj => Object.entries(obj).reduce((acc, [a, b]) => ({ ...acc, [b]: a }), {});

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const camelize = str => {
    return str?.toLowerCase()?.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const uppercase = str => {
    if (typeof str !== 'string') return str;
    return str?.toUpperCase();
};

export const transformFromCamelCase = (str, wherewith) =>
    str?.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, `$1${wherewith}$2`).toLowerCase();

export const transformToCamelCase = (str, wherewith) =>
    str?.replace(new RegExp(`${wherewith}.`, 'g'), x => x.toUpperCase()[1]);

export const camelToKebabCase = str => transformFromCamelCase(str, '-');
export const camelToSnakeCase = str => transformFromCamelCase(str, '_');
export const kebabToCamelCase = str => transformToCamelCase(str, '-');
export const snakeToCamelCase = str => transformToCamelCase(str, '_');

export const fixRegexPattern = str => str?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const waitPromises = async (promises, callback) => {
    const waitEventLoop = data => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(data);
            }, 0);
        });
    };

    if (Array.isArray(promises) && promises.length) {
        return Promise.all(promises)
            .finally(async responses => {
                if (callback) await callback();
                return waitEventLoop(responses);
            })
            .then(data => data)
            .catch(data => data);
    }
};

export const getParamsFromArray = (name, elements) => elements.map(el => `${name}[]=${el}`).join('&');

export const getParams = (params = {}) => {
    return Array.isArray(params)
        ? getParamsFromArray('columns', params).replace(/^(?=.)/, '?')
        : Object.entries(params)
              .map(
                  ([key, value]) => value && (Array.isArray(value) ? getParamsFromArray(key, value) : `${key}=${value}`)
              )
              .filter(el => el)
              .join('&')
              .replace(/^(?=.)/, '?');
};

export const getResponseBody = response => {
    if (!response || !response.headers?.get || !response.json || !response.text) return null;
    return response.headers.get('content-type')?.includes('json') ? response.json() : response.text();
};

export const getErrorMessage = async (response, responseBody) => {
    // DEPRECATED: use it if use AboratbleFetch
    // else use getResponseErrorMessage
    let body = responseBody;

    if (response) body = await getResponseBody(response);

    if (body?.errors) {
        if (Array.isArray(body.errors)) return body.errors[0]?.message;
        else if (typeof body.errors === 'object') return body.errors?.message;
        else if (typeof body.errors === 'string') return body.errors;
    }

    return null;
};

export const getResponseErrorMessage = (body, defaultMessage) => {
    if (body?.errors) {
        if (Array.isArray(body.errors)) return body.errors[0]?.message;
        else if (typeof body.errors === 'object') return body.errors?.message;
        else if (typeof body.errors === 'string') return body.errors;
    }

    if (defaultMessage) return defaultMessage;

    return null;
};

export const getOpenedRows = data => {
    return data.map(item => ({
        value: item.value,
        isOpened: item.isOpened,
        ...(item.children ? { children: getOpenedRows(item.children) } : {})
    }));
};

export const addIsOpen = (data, layoutData) => {
    return data.map(item => {
        const layoutItem = layoutData?.find(el => el.value === item.value);
        return {
            ...item,
            isOpened: layoutItem?.isOpened,
            ...(item.children ? { children: addIsOpen(item.children, layoutItem?.children) } : {})
        };
    });
};

export const addParents = (rows, parents) => {
    if (!rows) return null;
    return rows.map(item => ({
        ...item,
        parents,
        ...(item.children ? { children: addParents(item.children, [...parents, item.value]) } : {})
    }));
};

export const checkPositivity = data => {
    if (data > 0) {
        return 'positive';
    } else if (data < 0) {
        return 'negative';
    }
    return 'default';
};

export function download(data, filename, type = 'text/csv') {
    const file = new Blob([data], { type });
    if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else {
        // Others
        const a = document.createElement('a'),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

export const createUniqueName = (name, items) => {
    const allIndexes = items.reduce(
        (acc, el) => {
            if (el.trim() === name.trim()) return [...acc, 1];
            const match = new RegExp(`${name.trim()} *(\\d+)`).exec(el.trim());
            if (match?.[1]) return [...acc, +match[1]];
            return acc;
        },
        [0]
    );
    const maxIndex = Math.max(...allIndexes);
    return `${name.trim()}${maxIndex > 0 ? ` ${maxIndex + 1}` : ''}`;
};

export const transposeItems = (items, rowLength) => {
    if (!Array.isArray(items) || typeof rowLength !== 'number') return null;

    const rowsQuantity =
        items.length % rowLength === 0 ? items.length / rowLength : Math.trunc(items.length / rowLength) + 1;

    // const overflowCorrection = items.length % rowLength === 0 ? null : items.length % rowLength;
    const overflowCorrection = items.length % rowLength;

    let twoDimensionalArray = [...Array(rowsQuantity).keys()].map(() => null);
    twoDimensionalArray = twoDimensionalArray.map(() => [...Array(rowLength).keys()].map(() => null));

    let index = 0;
    for (let i = 0; i < rowLength; i++) {
        for (let j = 0; j < rowsQuantity; j++) {
            if (overflowCorrection !== 0 && j === rowsQuantity - 1 && !(i < overflowCorrection)) {
                twoDimensionalArray[j][i] = null;
                // eslint-disable-next-line no-continue
                continue;
            }
            twoDimensionalArray[j][i] = items?.[index];
            index++;
        }
    }
    return twoDimensionalArray.reduce((ac, item) => [...ac, ...item.filter(el => el !== null && el !== undefined)], []);
};

export const getStyles = (el, prop) => {
    if (!el) return null;
    if (!prop) return window.getComputedStyle(el);
    return window.getComputedStyle(el).getPropertyValue(prop);
};

const getDimension = (offset, el, metricsToAdd, isSubtract) => {
    if (!el || !metricsToAdd || !metricsToAdd.length) return el?.[offset] || 0;
    const getMetric = metric => +getStyles(el, metric)?.replace(/px/g, '') || 0;

    return metricsToAdd.reduce(
        (acc, metric) => (isSubtract ? acc - getMetric(metric) : acc + getMetric(metric)),
        el?.[offset] || 0
    );
};

export const getWidth = (...args) => getDimension('offsetWidth', ...args);
export const getHeight = (...args) => getDimension('offsetHeight', ...args);

export const getHiddenCardNumber = num => {
    return `•••• ${num}`;
};

export const formatCardNumber = (num, isPartHidden, type) => {
    const newNum = isPartHidden ? '•'.repeat(num.length - 4) + num.slice(-4) : num;
    if (num.length === 13 && type === 'Visa') {
        return newNum.replace(/^(.{4})(.{3})(.{3})(.{3})$/g, '$1 $2 $3 $4');
    }
    if (num.length === 15 && type === 'AmericanExpress') {
        return newNum.replace(/^(.{4})(.{6})(.{5})$/g, '$1 $2 $3');
    }
    return newNum.replace(/(.{4})/g, '$1 ').trim();
};

export const tryRegExp = input => {
    try {
        return new RegExp(input);
    } catch {
        return null;
    }
};

export const throttle = (fn, delay = 500) => {
    let timeout;
    return (...args) => {
        if (!timeout) {
            timeout = setTimeout(() => {
                fn(...args);
                timeout = null;
            }, delay);
        }
    };
};

export const debounce = (fn, delay = 500) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

export const random = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getIntlFormattedValue = (value, rounding, locale = 'en-EN') => {
    if (value === undefined) return '0';

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: rounding || 0,
        maximumFractionDigits: rounding || 0
    }).format(value);
};

export const checkPositiveNumber = num => /^(?!0+$)\d+$/.test(num);
export const checkPositiveFloatNumber = num => /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(num);

// eslint-disable-next-line no-control-regex
export const checkUrlCharacters = url => !/[^\x00-\x7F]+/.test(url);

export const checkStartUrlWithHttp = url => /^https?:\/\//gi.test(url);

// eslint-disable-next-line no-control-regex
export const getOnlyASCIICodeText = text => text?.replace(/[^\x00-\x7F]+/g, '');

export const getReplacedString = (str, pattern, wherewith = '') => {
    if (typeof str !== 'string') return str;

    const re = new RegExp(pattern, 'g');
    return str?.replace(re, wherewith);
};

export const getNotDangerouslyHTML = html => getReplacedString(html, '<', '&lt;');

export const getValueOfNumber = (value, decimalPlacesCount) => {
    const replace = val => val.replace(/[^0-9.]/g, '');

    const lastSymbol = value ? value.slice(-1) : '';
    const previousValue = value ? value.slice(0, value.length - 1) : '';

    if (lastSymbol === '.' && previousValue.includes('.')) return replace(previousValue);

    if (decimalPlacesCount && previousValue.includes('.')) {
        const position = previousValue.indexOf('.');
        return replace(value.length <= position + 1 + decimalPlacesCount ? value : previousValue);
    }

    return replace(value);
};

export const trimObjValues = obj =>
    Object.keys(obj).reduce((acc, key) => {
        acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
        return acc;
    }, {});

export const getLimitedLengthNumber = (value, maxLength = Infinity) => value.replace(/\D/g, '').substring(0, maxLength);

export const sortArrayByObjectValue = (array, value, isIncrease = true) => {
    if (!Array.isArray(array)) return;

    return array.slice().sort((a, b) => {
        const valueA = a?.[value]?.toLowerCase();
        const valueB = b?.[value]?.toLowerCase();

        if (valueA > valueB) return isIncrease ? 1 : -1;
        if (valueA < valueB) return isIncrease ? -1 : 1;
        return 0;
    });
};

export const getTrialModalLastShowTime = accountEmail => {
    if (accountEmail) return JSON.parse(localStorage.getItem('trialModalLastShowTime'))?.[accountEmail] || null;
    return JSON.parse(localStorage.getItem('trialModalLastShowTime')) ?? {};
};

export const setTrialModalLastShowTime = accountEmail => {
    const list = getTrialModalLastShowTime();
    list[accountEmail] = new Date().getTime();
    localStorage.setItem('trialModalLastShowTime', JSON.stringify(list));
};

export const removeTrialModalLastShowTime = accountEmail => {
    const list = getTrialModalLastShowTime();
    if (list[accountEmail]) delete list[accountEmail];
    if (Object.keys(list).length) localStorage.setItem('trialModalLastShowTime', JSON.stringify(list));
    else localStorage.removeItem('trialModalLastShowTime');
};

export const generateString = (initialString, items, options = {}) => {
    // options === {
    //     joinSeparator: string
    //     prefix: string
    //     postfix: string
    // }

    let result = '';

    const additionalString = items
        .map(el => {
            // el === 'string'
            // el === {
            //     isInclude: true,
            //     value: 'string'
            // }

            if (typeof el === 'string') return el;

            return el.isInclude ? el.value : '';
        })
        .filter(el => el);

    if (additionalString.length) {
        result = additionalString.join(options.joinSeparator || ' ').trim();
    }

    return `${initialString} ${options.prefix && additionalString.length ? options.prefix : ''}${result}${
        options.postfix && additionalString.length ? options.postfix : ''
    }`;
};

export const getDateRangeFromQueryString = query => {
    const newReportPeriod = {};

    const params = query?.split('&');

    params?.forEach(el => {
        if (el.search(/dateFrom/) >= 1) {
            newReportPeriod.start = moment(el.split('=')[1]).format();
        }
        if (el.search(/dateTo/) >= 1) {
            newReportPeriod.end = moment(el.split('=')[1]).format();
        }
        if (el.search(/compareDateFrom/) >= 1) {
            newReportPeriod.startPrevDate = moment(el.split('=')[1]).format();
        }
        if (el.search(/compareDateTo/) >= 1) {
            newReportPeriod.endPrevDate = moment(el.split('=')[1]).format();
        }
    });

    return newReportPeriod;
};

export const lowercaseFirstLetter = str => str.charAt(0).toLowerCase() + str.slice(1);

export const sharedReportEntityTransformer = backEntity => {
    const entities = {
        Campaign: 'campaigns',
        Flow: 'flows',
        Offer: 'offers',
        Lander: 'landers',
        TrafficSource: 'trafficSources',
        AffiliateNetwork: 'affiliateNetworks',
        Report: 'reports'
    };

    const result = entities[backEntity];

    if (!result) throw new Error('Invalid chapter in sharedReportEntityTransformer');

    return result;
};

export const getSharedReportLink = id => `${window.location.origin}/sharedreport/${id}`;

export const immerUpdateFn = (draft, prop, newValue) => {
    if (Array.isArray(prop)) {
        prop.forEach(el => {
            draft[el.prop] = el.value;
        });
    } else {
        draft[prop] = newValue;
    }
};
