import { createApi } from '@reduxjs/toolkit/query/react';
import { getCustomBaseQuery, apiPath } from '../services';

// const authApiTagTypes = {};

const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: getCustomBaseQuery({
        baseUrl: apiPath
    }),
    endpoints: builder => ({
        login: builder.mutation({
            query: formData => ({
                url: `/auth/token`,
                method: 'POST',
                body: formData
            })
        }),

        register: builder.mutation({
            query: data => ({
                url: `/auth/signup`,
                method: 'POST',
                body: data
            })
        }),

        registerInvite: builder.mutation({
            query: ({ projectId, id, data }) => ({
                url: `/auth/invite/${projectId}/${id}`,
                method: 'POST',
                body: data
            })
        }),

        checkEmailCode: builder.mutation({
            query: code => ({
                url: `/auth/signup/${code}`,
                method: 'GET'
            })
        }),

        resetPassword: builder.mutation({
            query: email => ({
                url: `/auth/reset`,
                method: 'POST',
                body: { email }
            })
        }),

        changePassword: builder.mutation({
            query: ({ token, data }) => ({
                url: `/auth/reset/${token}`,
                method: 'POST',
                body: data
            })
        }),

        confirmEmail: builder.mutation({
            query: ({ code, password }) => ({
                url: `/auth/signup/${code}`,
                method: 'POST',
                body: { password }
            })
        }),

        loginAsSupport: builder.query({
            query: ({ supportId, code }) => ({
                url: `/auth/admin?code=${code}`,
                method: 'GET',
                headers: {
                    'Support-Id': supportId
                }
            })
        })
    })
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useRegisterInviteMutation,
    useCheckEmailCodeMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useConfirmEmailMutation
} = authApi;

export default authApi;
