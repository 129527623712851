import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import Langs from '../../../../../Langs';

import useClickOutside from '../../../../../Functions/hooks/useClickOutside';
import useDelayedInput from '../../../../../Functions/hooks/useDelayedInput';
import useToggle from '../../../../../Functions/hooks/useToggle';

import indexApi from '../../../../../redux/api/indexApi';

import FormActions from '../../../Forms/FormActions/FormActions';
import SelectItem from '../../../Forms/Inputs/SelectInput/SelectItem';
import { Tag } from '../../../Forms/Inputs/TagInput';

// eslint-disable-next-line no-unused-vars
import { useGetSuggestDataMutation } from '../../../../../redux/api/infiniteSelectScrollApi';

import './multi-select-input.scss';

export const getMultiSelectData = ({ value, items }) =>
    value.map(val => items?.find(item => item.value === val)?.label || val).join(', ');
export const getMultiSelectCount = value => value.length;

const MultiSelectInput = ({
    value,
    items: initItems = [],
    isMatched,
    isEnabled,
    onChange,
    label,
    className,
    placeholder,
    suggestUrl,
    isSearchable
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const [valueList, setValueList] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [items, setItems] = useState(initItems);
    const [itemsPagination, setItemsPagination] = useState({ page: 1, pages: 1 });
    const { isToggled: isOpened, toggle: toggleOpened, toggleOn: setIsOpened, toggleOff: unsetIsOpened } = useToggle();
    const optionsContainerRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const isValueExist = item => value.includes(item);

    const handleDeleteValue = itemValue => onChange([...value.filter(val => itemValue !== val)], 'value');

    const handleListChange = () =>
        setValueList(value.map(val => items.find(item => item.value === val)?.label || val).join(', '));

    const fetchItemsList = async (suggestStr, page = 1) => {
        setIsLoading(true);

        const itemsList = await dispatch(
            indexApi.endpoints.getSuggestData.initiate({
                url: suggestUrl,
                string: encodeURIComponent(suggestStr),
                params: `?per-page=20&page=${page}`
            })
        );

        setIsLoading(false);

        return itemsList.data ?? { items: [], pagination: { page: 1, pages: 1 } };
    };

    const [suggestText, onSuggestionTextChange] = useDelayedInput(async suggestStr => {
        if (suggestText) {
            const { items: fetchItems, pagination } = await fetchItemsList(suggestStr);

            setItems(fetchItems?.map(item => ({ value: item, label: item })) || []);
            setItemsPagination(pagination);
            setIsOpened();
        } else {
            setItems([]);
            setItemsPagination({ page: 1, pages: 1 });
        }
    }, '');

    const handleItemClick = item => {
        if (isValueExist(item.value)) handleDeleteValue(item.value);
        else onChange([...value, item.value], 'value');
    };

    const handleListConfirm = async () => {
        const newValuesList = valueList.split(/[,\n]/);
        if (!suggestUrl) {
            onChange(
                items.filter(item => newValuesList.some(tag => item.label === tag.trim())).map(el => el.value),
                'value'
            );
            return;
        }
        const fetchedItems = await Promise.all(
            newValuesList.map(async item => {
                // ToDo - пересмотреть (отдельные fetchData в массиве)
                const { items: fetchItems } = await fetchItemsList(item.trim());
                return fetchItems[0];
            })
        );
        const filteredFetchedItems = fetchedItems.filter(el =>
            newValuesList.some(item => item.trim()?.toLowerCase() === el?.toLowerCase())
        );
        onChange(filteredFetchedItems, 'value');
    };

    const handleClear = () => {
        onChange([], 'value');
        setValueList('');
    };

    const handleScroll = async () => {
        const { scrollTop, scrollHeight, clientHeight } = optionsContainerRef.current;

        if (scrollTop + clientHeight === scrollHeight && itemsPagination.pages > itemsPagination.page) {
            const { items: fetchItems, pagination } = await fetchItemsList(suggestText, itemsPagination.page + 1);

            setItems([...items, ...(fetchItems?.map(item => ({ value: item, label: item })) || [])]);
            setItemsPagination(pagination);
        }
    };

    const getContent = () => {
        if (suggestUrl) {
            return (
                <div className="multi-select-input__suggest-field">
                    <input
                        autoComplete="chrome-off"
                        onFocus={({ target }) => target.select()}
                        value={suggestText}
                        placeholder={txt.placeholders[placeholder]}
                        onChange={onSuggestionTextChange}
                    />
                </div>
            );
        } else if (isSearchable) {
            return (
                <input
                    className="multi-select-input__searchable-field"
                    value={searchValue}
                    placeholder={txt.placeholders[placeholder] || placeholder}
                    onChange={e => setSearchValue(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                />
            );
        } else return txt.placeholders[placeholder] || placeholder || txt.placeholders.selectValue;
    };

    return (
        <FormActions
            className={className}
            label={label}
            isEnabled={isEnabled}
            onChange={onChange}
            handleEditConfirm={handleListConfirm}
            isMatched={isMatched}
            onClear={handleClear}
            handleListChange={handleListChange}
            textFieldValue={valueList}
            onTextFieldEdit={({ target: { value: fieldValue } }) => setValueList(fieldValue)}
        >
            <div className="multi-select-input" ref={useClickOutside(unsetIsOpened)}>
                <div className="multi-select-input__container">
                    {value?.map(val => {
                        const newLabel = items.find(item => item.value === val)?.label || val;
                        return (
                            <Tag
                                key={val}
                                label={txt.labels[newLabel] || newLabel}
                                onDeleteItem={() => handleDeleteValue(val)}
                                type={isMatched ? 'confirm' : 'error'}
                            />
                        );
                    })}
                    <div
                        data-test={`multi-select-input_${label}-div`}
                        className={cn('multi-select-input__body', isOpened && 'multi-select-input__body--opened')}
                        onClick={toggleOpened}
                    >
                        {getContent()}
                    </div>
                </div>
                {(isOpened || isSearchFocused) && (
                    <>
                        <div
                            className="multi-select-input__options form-select-options form-select-options--wide"
                            onScroll={handleScroll}
                            ref={optionsContainerRef}
                        >
                            {items
                                .filter(item => item.label.toLowerCase().includes(searchValue.toLowerCase()))
                                .map(item => (
                                    <SelectItem
                                        key={item.value}
                                        label={txt.labels[item.label] || item.label}
                                        isActive={isValueExist(item.value)}
                                        onItemClick={() => handleItemClick(item)}
                                        disabled={item.disabled}
                                        highlightedText={suggestText || searchValue}
                                    />
                                ))}
                            {!items.length && !isLoading && (
                                <SelectItem
                                    label={`<span class="color--disabled">${
                                        suggestText ? txt.labels.noData : txt.labels.startType
                                    }</span>`}
                                    isDangerouslyLabel
                                />
                            )}
                            {isLoading && <div className="multi-select-input__loader" />}
                        </div>
                    </>
                )}
            </div>
        </FormActions>
    );
};

export default MultiSelectInput;
