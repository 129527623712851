import React from 'react';

import Langs from '../../../../../Langs';

import AddBillingInfoModal from '../../../add-billing-info-modal/add-billing-info-modal';

import icon from '../../../../../../../media/images/lock-red.svg';

import './update-billing-info-modal.scss';

const UpdateBillingInfoModal = ({ data, isOwner, onClose }) => {
    const txt = Langs[global.lng];

    return isOwner ? (
        <div className="update-billing-info-modal">
            <div className="chage-plan-modal__header">
                <span className="chage-plan-modal__modal-title">{txt.titles.billingInfo}</span>
            </div>

            <AddBillingInfoModal handleConfirmation={onClose} noRejectBtn />
        </div>
    ) : (
        <div className="block-modal">
            <div className="block-modal__icon">
                <img src={icon} width="24" height="24" alt="lock" />
            </div>

            <div className="block-modal__container block-modal__container--large">
                <h2 className="block-modal__title">{txt.labels.billing.accountBlocked}</h2>

                <p className="block-modal__text mt16">
                    Please contact{' '}
                    <a href={`mailto:${data.ownerEmail}`} className="block-modal__owner">
                        {data.ownerEmail}
                    </a>{' '}
                    to upgrade account billing info.
                </p>
            </div>
        </div>
    );
};

export default UpdateBillingInfoModal;
