import React from 'react';

const TdBadges = props => {
    const { items = [], onClick, row } = props;

    return (
        <div className="badge-square wrap">
            {items?.map((item, index) => (
                <span
                    data-test={`td-badges_${row?.id}`}
                    className="badge--yellow badge-square__item"
                    style={item?.style}
                    key={index}
                    onClick={onClick}
                >
                    {item?.label || item}
                </span>
            ))}
        </div>
    );
};

export default TdBadges;
