import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Gift } from 'react-feather';

import Langs from '../../../../Langs';
import { MODAL_TYPES } from '../../../../Constants';
import { compare, omitKeys, getIntlFormattedValue, getResponseErrorMessage } from '../../../../Functions/utils';

import { useChangeUseNextPaymentMethodStatusMutation } from '../../../../redux/api/projectApi';
import { useChangePaymentMethodPriorityMutation } from '../../../../redux/api/paymentApi';

import Section from '../../../../components/section/section';
import SectionHeader from '../../../../components/section-header/section-header';
import PaymentItem from './components/payment-item';
import Loader from '../../../Loader';
import Switcher from '../../../Overall/UI/Switcher/Switcher';

import './payment-method.scss';

const DragabblePaymentItem = React.forwardRef((props, ref) => (
    <div
        className={cn('payment-method__item', {
            'payment-method__item--expired': props.method?.isExpired
        })}
        ref={ref}
        {...omitKeys(props, ['method', 'setIsProcessing'])}
    >
        <PaymentItem method={props.method} setIsProcessing={props.setIsProcessing} isDragabble />
    </div>
));

const PaymentList = React.memo(({ paymentMethods, setIsProcessing }) => {
    return paymentMethods.map((method, index) => {
        return (
            <Draggable draggableId={method.id} index={index} key={method.id}>
                {provided => (
                    <DragabblePaymentItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        method={method}
                        setIsProcessing={setIsProcessing}
                    />
                )}
            </Draggable>
        );
    });
});

const PaymentMethod = ({ isFetching, balance, paymentMethodList, showCurrentModal, useNextPaymentMethodStatus }) => {
    const txt = Langs[global.lng];

    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState(paymentMethodList);

    useEffect(() => {
        if (isProcessing) setIsProcessing(false);

        if (!compare(paymentMethods, paymentMethodList)) {
            setPaymentMethods(paymentMethodList);
        }
    }, [paymentMethodList]);

    const [
        changePaymentMethodPriority,
        {
            isLoading: isChangePaymentMethodPriorityLoading,
            isSuccess: isChangePaymentMethodPrioritySuccess,
            isError: isChangePaymentMethodPriorityError,
            error: changePaymentMethodPriorityError
        }
    ] = useChangePaymentMethodPriorityMutation();

    useEffect(() => {
        if (isChangePaymentMethodPrioritySuccess) toast.success(txt.toasts.saved);
    }, [isChangePaymentMethodPrioritySuccess]);

    useEffect(() => {
        if (isChangePaymentMethodPriorityError)
            toast.error(getResponseErrorMessage(changePaymentMethodPriorityError.data, txt.toasts.saveError));
    }, [isChangePaymentMethodPriorityError]);

    const onDragEnd = ({ destination, source }) => {
        if (!destination) return;
        if (destination.index === source.index) return;

        const list = [...paymentMethods];
        const [movedCard] = list.splice(source.index, 1);
        list.splice(destination.index, 0, movedCard);

        setPaymentMethods(list);

        changePaymentMethodPriority({ list: list.map(el => ({ id: el.id })) });
    };

    const [
        changeUseNextPaymentMethodStatus,
        {
            isLoading: isChangeUseNextPaymentMethodStatusLoading,
            isSuccess: isChangeUseNextPaymentMethodStatusSuccess,
            isError: isChangeUseNextPaymentMethodStatusError,
            error: changeUseNextPaymentMethodStatusError
        }
    ] = useChangeUseNextPaymentMethodStatusMutation();

    useEffect(() => {
        if (isChangeUseNextPaymentMethodStatusSuccess) {
            toast.success(txt.toasts.saved);
        }
    }, [isChangeUseNextPaymentMethodStatusSuccess]);

    useEffect(() => {
        if (isChangeUseNextPaymentMethodStatusError)
            toast.error(getResponseErrorMessage(changeUseNextPaymentMethodStatusError.data, txt.toasts.saveError));
    }, [isChangeUseNextPaymentMethodStatusError]);

    const sectionHeader = (
        <SectionHeader
            title={txt.titles.paymentMethod}
            button={{
                label: txt.labels.addMethod,
                onClick: () => showCurrentModal(MODAL_TYPES.ADD_NEW_PAYMENT_METHOD)
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="mb25 payment-method">
            {balance > 0 && (
                <div className="payment-method__credit">
                    <div className="payment-method__credit-icon-wrapper">
                        <Gift className="payment-method__credit-icon" size={24} />
                    </div>
                    <div className="payment-method__credit-info">
                        <span className="payment-method__credit-info-title">
                            {txt.texts.credit}:&nbsp;{`$${getIntlFormattedValue(balance)}`}
                        </span>
                        <p className="payment-method__credit-info-text">{txt.texts.creditWillGoAutomatically}</p>
                    </div>
                </div>
            )}

            {paymentMethods.length >= 2 && (
                <Switcher
                    dataTest="payment-method"
                    label={txt.labels.useNextPaymentMethod}
                    className="payment-method-switcher"
                    disabled={isChangeUseNextPaymentMethodStatusLoading}
                    isSwitchOn={useNextPaymentMethodStatus}
                    onChange={value => changeUseNextPaymentMethodStatus(value)}
                />
            )}

            {paymentMethods.length ? (
                <>
                    {useNextPaymentMethodStatus && paymentMethods.length >= 2 ? (
                        <>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {provided => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <PaymentList
                                                paymentMethods={paymentMethods?.filter(el => !el.isExpired) || []}
                                                setIsProcessing={setIsProcessing}
                                            />
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            <div className="payment-method__item-wrapper">
                                {paymentMethods
                                    .filter(el => el.isExpired)
                                    .map(method => (
                                        <div
                                            className="payment-method__item payment-method__item--expired"
                                            key={method.id}
                                        >
                                            <PaymentItem
                                                method={method}
                                                setIsProcessing={setIsProcessing}
                                                isDragabble
                                            />
                                        </div>
                                    ))}
                            </div>
                        </>
                    ) : (
                        <div className="payment-method-list">
                            {paymentMethods.map(method => (
                                <PaymentItem method={method} setIsProcessing={setIsProcessing} key={method.id} />
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <div className="payment-method-list">
                    <div className="payment-method__no-payment-method">{txt.texts.noPaymentMethod}</div>
                </div>
            )}

            {(isFetching ||
                isChangeUseNextPaymentMethodStatusLoading ||
                isChangePaymentMethodPriorityLoading ||
                isProcessing) && <Loader isFetching />}
        </Section>
    );
};

export default PaymentMethod;
