import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import produce from 'immer';
import { ChevronDown } from 'react-feather';
import { toast } from 'react-toastify';

import Langs from '../../../../../Langs';
import { getResponseErrorMessage } from '../../../../../Functions/utils';

import useNewClickOutside from '../../../../../Functions/hooks/useNewClickOutside';

import {
    useGetWorkspacesAllNamesQuery,
    useGetWorkspacesStoreListQuery,
    useUpdateWorkspacesStoreListMutation
} from '../../../../../redux/api/workspacesApi';

import CheckBoxInput from '../../../Forms/Inputs/CheckBoxInput';

import './workspaces-filter.scss';

const SEPARATOR = 20;

const WorkspacesFilter = () => {
    const txt = Langs[global.lng];

    const [isOpen, setIsTabPopuOpen] = useState(false);
    const [workspaces, setWorkspaces] = useState([]);

    const { data: workspacesInStore, isSuccess: isWorkspacesInStoreLoaded } = useGetWorkspacesStoreListQuery();
    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();

    const [
        updateWorkspacesStore,
        {
            isSuccess: isUpdateWorkspacesStoreSuccess,
            isError: isUpdateWorkspacesStoreError,
            error: updateWorkspacesStoreError
        }
    ] = useUpdateWorkspacesStoreListMutation();

    const send = () => {
        const selectedWorkspaces = workspaces.slice(1, workspaces.length).filter(el => el.isChecked);

        if (selectedWorkspaces.length !== workspacesInStore.length) {
            updateWorkspacesStore({ data: selectedWorkspaces.map(el => el.value) });
        }
    };

    useEffect(() => {
        if (isUpdateWorkspacesStoreSuccess) toast.success(txt.toasts.saved);
    }, [isUpdateWorkspacesStoreSuccess]);

    useEffect(() => {
        if (isUpdateWorkspacesStoreError) {
            toast.error(getResponseErrorMessage(updateWorkspacesStoreError.data, txt.errors.saveError));
        }
    }, [isUpdateWorkspacesStoreError]);

    const onFilterButtonClick = () => {
        if (isOpen) send();
        setIsTabPopuOpen(!isOpen);
    };

    useEffect(() => {
        if (isWorkspacesListLoaded && isWorkspacesInStoreLoaded) {
            setWorkspaces([
                {
                    value: 'All',
                    label: 'All workspaces',
                    isChecked: workspacesInStore.length === workspacesList.length,
                    isStark: workspacesInStore.length !== workspacesList.length && workspacesInStore.length > 0
                },
                ...workspacesList.map(el => ({ ...el, isChecked: !!workspacesInStore.find(it => it.id === el.value) }))
            ]);
        }
    }, [workspacesList, workspacesInStore]);

    const filterButtonRef = useRef();

    const popupRef = useNewClickOutside(
        () => {
            if (isOpen) {
                send();
                setIsTabPopuOpen(false);
            }
        },
        { additionalComponent: filterButtonRef.current }
    );

    const columnsCount = Math.ceil(workspaces.length / SEPARATOR);

    const countWorkspaces = (() => {
        const countCheckedElements = workspaces.filter(el => el.isChecked).length;

        if (workspaces.length === countCheckedElements) return 'All';
        else return countCheckedElements;
    })();

    const setChecked = id => {
        if (id === 'All') {
            setWorkspaces(
                produce(draft => {
                    const status = draft.find(el => el.value === id)?.isChecked;

                    return draft.forEach(item => {
                        item.isChecked = !status;

                        if (item.isStark) item.isStark = false;
                    });
                })
            );
        } else {
            setWorkspaces(
                produce(draft => {
                    const item = draft.find(el => el.value === id);
                    item.isChecked = !item.isChecked;

                    const isNotAllChecked = draft.slice(1, draft.length).some(el => el.isChecked === false);
                    const atLeastOneElementChecked = [...draft]
                        .slice(1, draft.length)
                        .some(el => el.isChecked === true);

                    const itemAll = draft.find(el => el.value === 'All');

                    if (isNotAllChecked) {
                        if (atLeastOneElementChecked) {
                            itemAll.isStark = true;
                            itemAll.isChecked = false;
                        } else {
                            itemAll.isChecked = false;
                            itemAll.isStark = false;
                        }
                    } else {
                        itemAll.isChecked = true;
                        itemAll.isStark = false;
                    }
                })
            );
        }
    };

    const getContent = () => {
        const columns = [];

        for (let index = 1; index <= columnsCount; index++) {
            const list = workspaces.slice((index - 1) * SEPARATOR, index * SEPARATOR);

            columns.push(
                <div className="workspaces-filter__list" key={index}>
                    {list.map(el => (
                        <div className="workspaces-filter__item" key={el.value}>
                            <CheckBoxInput
                                label={el.label}
                                isStark={el.isStark}
                                value={el.isChecked}
                                onChange={() => setChecked(el.value)}
                                className="mr10"
                            />
                        </div>
                    ))}
                </div>
            );
        }

        return columns;
    };

    return (
        <div className="workspaces-filter" ref={filterButtonRef}>
            <div className="workspaces-filter__container" onClick={onFilterButtonClick}>
                {`${countWorkspaces} workspaces`}{' '}
                <ChevronDown
                    size={15}
                    className={cn('workspaces-filter__icon', { 'workspaces-filter__icon--open': isOpen })}
                />
            </div>

            {isOpen && (
                <div
                    className={cn('workspaces-filter__popup', { 'workspaces-filter__popup--right': columnsCount > 2 })}
                    ref={popupRef}
                >
                    {getContent().map(el => el)}
                </div>
            )}
        </div>
    );
};

export default WorkspacesFilter;
