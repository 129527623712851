import React from 'react';

import ItemsTableBodyConstructor from './modules/ItemsTableBodyConstructor';
import ItemsTableHeader from './modules/ItemsTableHeader';

// DEPRECATED: Этот компонент больше не поддерживается, использовать или заменить на <JustTable /> JS\components\just-table\just-table.js

const ItemsTable = props => {
    const { cols, rows, onChange, onActionClick, className, currencies } = props;

    const handleClick = (type, item) => {
        if (onActionClick) onActionClick(type, item);
    };

    return (
        <div className={`${className || 'default'}`}>
            <ItemsTableHeader cols={cols} rows={rows} onChange={onChange} />
            <ItemsTableBodyConstructor
                cols={cols}
                rows={rows}
                onChange={onChange}
                onActionClick={handleClick}
                currencies={currencies}
            />
        </div>
    );
};

export default ItemsTable;
