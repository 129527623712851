import React, { useEffect } from 'react';

import Langs from '../../../../Langs';
import { checkUrlCharacters } from '../../../../Functions/utils';
import useToken from '../../../../Functions/hooks/useToken';

import { AccordionTokens } from '../../../Overall/Accordion/Accordion';
import CheckBoxInput from '../../../Overall/Forms/Inputs/CheckBoxInput';
import Label from '../../../Overall/Forms/Inputs/Label';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import { TdBadgesSquareInline } from '../../../Overall/ItemsTable/TdTypes';

const TSPostbackUrl = ({
    postbackUrl,
    trafficSourceTokens,
    postbackUrlInputRef,
    isPostbackApproved,
    isPostbackNew,
    isPostbackDeclined,
    isPostbackChargeback,
    parameters,
    isAutoGeneratePostbackUrl,
    updateTrafficSource
}) => {
    const txt = Langs[global.lng];

    const allTokens = parameters
        ? [...trafficSourceTokens, ...parameters?.map(({ internalToken }) => `{${internalToken}}`)].filter(
              token => !/workspace/.test(token) // TODO: Temporarily filter. hide workspace PC-1446
          )
        : trafficSourceTokens;

    const { url, displayUrl, tokens, handleTokenClick, handleUrlChange, error } = useToken(
        postbackUrl || txt.labels.https,
        [...new Set(allTokens)],
        postbackUrlInputRef
    );

    useEffect(() => updateTrafficSource({ postbackUrl: url }), [url]);

    return (
        <div className="mt15">
            <div className="mb10">
                <div className="postback-text">
                    <TextInput
                        dataTest="traffic-source_postback-url-textarea"
                        value={url}
                        type="textarea"
                        className="create-ts-content__text"
                        onChange={handleUrlChange}
                        checkCorrectSymbol={checkUrlCharacters}
                        ref={postbackUrlInputRef}
                        error={error}
                        disabled={isAutoGeneratePostbackUrl}
                    />
                </div>

                {!isAutoGeneratePostbackUrl && (
                    <>
                        <div className="postback-preview">
                            {/* eslint-disable-next-line react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: displayUrl }} />
                        </div>

                        <AccordionTokens>
                            <TdBadgesSquareInline label={txt.labels.tokens} items={tokens} onClick={handleTokenClick} />
                        </AccordionTokens>
                    </>
                )}
            </div>

            <Label
                label={txt.labels.sendPostbackWithStatuses}
                hint={txt.hints.sendPostbackWithStatuses}
                className="mb10"
            />
            <div className="j4">
                <div className="mr15">
                    <CheckBoxInput
                        dataTest="traffic-source_postback-approved-checkbox"
                        label={txt.labels.approved}
                        value={isPostbackApproved}
                        onChange={({ target: { checked } }) => updateTrafficSource({ isPostbackApproved: checked })}
                    />
                </div>
                <div className="mr15">
                    <CheckBoxInput
                        dataTest="traffic-source_postback-new-checkbox"
                        label={txt.labels.new}
                        value={isPostbackNew}
                        onChange={({ target: { checked } }) => updateTrafficSource({ isPostbackNew: checked })}
                    />
                </div>
                <div className="mr15">
                    <CheckBoxInput
                        dataTest="traffic-source_postback-declined-checkbox"
                        label={txt.labels.declined}
                        value={isPostbackDeclined}
                        onChange={({ target: { checked } }) => updateTrafficSource({ isPostbackDeclined: checked })}
                    />
                </div>
                <div className="mr15">
                    <CheckBoxInput
                        dataTest="traffic-source_postback-changeback-checkbox"
                        label={txt.labels.chargeback}
                        value={isPostbackChargeback}
                        onChange={({ target: { checked } }) => updateTrafficSource({ isPostbackChargeback: checked })}
                    />
                </div>
            </div>
            {/* <div className="j4 mt20">
                <TextInput
                    dataTest="traffic-source_postback-pct-input"
                    className="mr15"
                    label={txt.labels.postbackPct}
                    hint={txt.hints.postbackPct}
                    value={postbackPct}
                    onChange={e => onChange(e, 'postbackPct')}
                />
                <TextInput
                    dataTest="traffic-source_payout-pct-input"
                    className="mr15"
                    label={txt.labels.payoutPct}
                    hint={txt.hints.payoutPct}
                    value={payoutPct}
                    onChange={e => onChange(e, 'payoutPct')}
                />
            </div> */}
        </div>
    );
};

export default TSPostbackUrl;
