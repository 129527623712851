import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { capitalize } from '../../../Functions/utils';

import Langs from '../../../Langs';
import { BADGE_TYPE } from '../../../Constants';
import CheckBoxInput from '../Forms/Inputs/CheckBoxInput';
import BadgeRound from '../UI/BadgeRound/BadgeRound';
import Icon from '../Icon';

import './select-table.scss';

export const SelectTableHeader = props => {
    const { className, columns, isAllSelected, onChange, sortBy, setSortBy, replacementId, limit } = props;

    const handleThClick = col => {
        if (!col.isSortingDisabled) {
            if (sortBy?.key === col.key) {
                setSortBy({
                    key: col.key,
                    type: sortBy?.type === 'asc' ? 'desc' : 'asc'
                });
            } else {
                setSortBy({ key: col.key, type: 'asc' });
            }
        }
    };

    return (
        <div className={cn('select-table-items-header__tr', className)}>
            {!replacementId && (
                <div
                    onClick={e => (!limit ? onChange(e, isAllSelected) : null)}
                    className="select-table-items-header__th select-table-items-header__checkbox"
                >
                    {!limit && (
                        <CheckBoxInput
                            dataTest="select-table-items_select-all-checkbox"
                            value={isAllSelected}
                            onChange={e => onChange(e, isAllSelected)}
                        />
                    )}
                </div>
            )}
            {columns?.map(col => (
                <div
                    key={col.key}
                    onClick={() => handleThClick(col)}
                    className={cn('select-table-items-header__th', col.className)}
                >
                    <div className="select-table-items-header__flex">
                        <div>{col.label}</div>
                        <div className="select-table-items-header__arrow">
                            {sortBy?.key === col.key &&
                                (sortBy?.type === 'asc' ? <Icon.ArrowDown size={16} /> : <Icon.ArrowUp size={16} />)}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const SelectTableBody = props => {
    const txt = Langs[global.lng];
    const { columns, rows, ids, limit, onClick, replacementId, currencies } = props;

    const renderValue = (val, key, row) => {
        if (Array.isArray(val)) {
            val = val.join(', ');
        }

        if (key === 'status') {
            return <span className={`${val === 'Active' ? 'color--de-york' : 'color--froly'}`}>{val}</span>;
        }

        if (key === 'name' && row.isArchived) {
            return (
                <div className="color--froly j7">
                    {val}
                    <BadgeRound
                        text={txt.labels.archived}
                        type={BADGE_TYPE.DANGER}
                        className="badge-round--clear-border ml5"
                    />
                </div>
            );
        }

        if (key === 'countryTag' && val === 'GO') val = 'Global';

        if (key === 'payout') {
            if (val) {
                const currencySymbol = currencies.find(el => el.value === row.payoutCurrency)?.symbol ?? '';

                if (row.payoutType === 'byOfferClick') {
                    val = `${currencySymbol}${val} (By click)`;
                } else {
                    val = `${currencySymbol}${val} (${capitalize(row.payoutType)})`;
                }
            } else val = 'Auto';
        }

        if (row.isArchived && key !== 'id') {
            val = <span className="color--froly">{val}</span>;
        }

        return val;
    };

    const handleRowClick = (e, value) => {
        e.preventDefault();
        if (replacementId) {
            onClick(value);
            return;
        }
        if (ids.includes(value)) {
            onClick(ids.filter(el => el !== value));
        } else if (!limit || ids.length < limit) onClick([...ids, value]);
        else if (ids.length === limit) onClick([value]);
    };

    return (
        <>
            {rows?.map((row, idx) => {
                return (
                    <div
                        key={idx}
                        className={cn(
                            'select-table-items-body__tr',
                            {
                                'select-table-items-body__tr--active': ids.includes(row.value)
                            },
                            {
                                'select-table-items-body__tr--invalid':
                                    row?.verification && !row.verification.isVerified
                            }
                        )}
                        onClick={e => handleRowClick(e, row.value)}
                    >
                        {!replacementId && (
                            <div className="select-table-items-body__td select-table-items-body__checkbox">
                                <CheckBoxInput
                                    dataTest="select-table-items_value-checkbox"
                                    value={ids.includes(row.value)}
                                    onChange={e => handleRowClick(e, row.value)}
                                />
                            </div>
                        )}
                        {columns?.map(col => (
                            <div key={col.key} className={cn('select-table-items-body__td', col.cellClassName)}>
                                {renderValue(row[col.key], col.key, row)}
                            </div>
                        ))}
                    </div>
                );
            })}
        </>
    );
};

const SelectTableItems = props => {
    const {
        className,
        columns,
        rows: initialRows,
        ids: inititalIds,
        changeIds,
        countries,
        currencies,
        replacementId,
        limit
    } = props;

    const [ids, setIds] = useState(inititalIds);
    const [sortBy, setSortBy] = useState({ key: 'id', type: 'asc' });

    const rows = useMemo(
        () =>
            sortBy
                ? initialRows.sort((a, b) => {
                      if (a[sortBy?.key] > b[sortBy?.key]) return sortBy?.type === 'asc' ? 1 : -1;
                      if (a[sortBy?.key] < b[sortBy?.key]) return sortBy?.type === 'asc' ? -1 : 1;
                      return 0;
                  })
                : initialRows,
        [initialRows, sortBy]
    );

    const isAllSelected = useMemo(
        () => rows.length > 0 && rows.every(el => ids.includes(el.value)),
        [ids, initialRows]
    );

    const handleChange = (e, isChecked) => {
        e.preventDefault();
        if (isChecked) setIds([]);
        else setIds([...rows.map(el => el.value)]);
    };

    useEffect(() => {
        changeIds(ids);
    }, [ids]);

    return (
        <div className={cn('select-table-items', className)}>
            <SelectTableHeader
                columns={columns}
                isAllSelected={isAllSelected}
                onChange={handleChange}
                sortBy={sortBy}
                setSortBy={setSortBy}
                limit={limit}
                replacementId={replacementId}
            />
            <SelectTableBody
                rows={rows}
                columns={columns}
                ids={ids}
                onClick={setIds}
                limit={limit}
                countries={countries}
                currencies={currencies}
                replacementId={replacementId}
            />
        </div>
    );
};

export default SelectTableItems;
