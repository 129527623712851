import React from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';

import HintIcon from './HintIcon';
import RadioButton from './RadioButton';

const RadioInput = React.memo(props => {
    const txt = Langs[global.lng];
    const { label, items, value, onChange, disabled, hint, hintType, className, type, dataTest, isHighlighted } = props;

    const isObjectUsed = !!(items && typeof items[0] === 'object' && items[0] !== null);

    return (
        <div className={cn('form-small-label', className)}>
            {label && (
                <div className={cn('j4 radio-label', { mb5: type !== 'row' })}>
                    <div className="mr5">{txt?.labels[label] || label}</div>
                    {hint && <HintIcon hint={txt?.hints[hint] || hint} type={hintType} />}
                </div>
            )}
            <div className={cn('form-radio-group', type === 'row' && 'form-radio-group__row')}>
                {items?.map((item, key) => (
                    <div
                        className={cn('df form-radio-item', item?.customClass)}
                        key={`${isObjectUsed ? item?.value : item}${key}`}
                    >
                        <RadioButton
                            dataTest={dataTest}
                            value={isObjectUsed ? item?.value : item}
                            selectedValue={value}
                            label={isObjectUsed ? txt?.labels[item?.label] || item?.label : txt?.labels[item] || item}
                            hint={isObjectUsed ? item?.hint : null}
                            hintType={isObjectUsed ? item?.hintType : hintType || hintType}
                            onChange={!disabled ? onChange : () => {}}
                            disabled={disabled || (isObjectUsed && item?.disabled)}
                            isHighlighted={isHighlighted}
                            onDisabledClick={item?.onDisabledClick}
                            rightElement={item?.rightElement}
                            errorText={item?.errorText}
                        />
                        {item?.component && <>{item.component}</>}
                    </div>
                ))}
            </div>
        </div>
    );
});

RadioInput.displayName = 'RadioInput';
export default RadioInput;
