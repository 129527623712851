import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { produce } from 'immer';
import jwt from 'jsonwebtoken';

import Langs from '../../Langs';
import { EMAIL_RULE, KEYBOARD_SERVICE_KEY } from '../../Constants';
import { getResponseErrorMessage, saveCredentials } from '../../Functions/utils';
import { transform } from '../../redux/transformers';

import useKeyPress from '../../Functions/hooks/useKeyPress';

import { useLoginMutation, useCheckEmailCodeMutation, useConfirmEmailMutation } from '../../redux/api/authApi';

import {
    changeAppIsAuthorized,
    changeAppIsStartProjectAfterLogIn,
    changeAppIsWithPromo,
    changeAppPromocode
} from '../../redux/app-slice';

import AuthForm, { AuthBtn } from '../../Modules/AuthForm/AuthForm';
import CheckBoxInput from '../../Modules/Overall/Forms/Inputs/CheckBoxInput';
import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';
import Loader from '../../Modules/Loader';

const PageLogin = () => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const dispatch = useDispatch();
    const { code } = useParams();

    document.title = txt.auth.logInTitlePageTitle;

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(!!code);
    const [isLoading, setIsLoading] = useState(false);

    const [userData, setUserData] = useState({
        emailToken: null,
        userEmail: null,
        email: '',
        password: '',
        confirmPassword: '',
        remember: true
    });

    const updateUserData = useCallback((prop, value) => {
        setUserData(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const [login, { data: loginData, isSuccess: isLoginSuccess, isError: isLoginError, error: loginError }] =
        useLoginMutation();

    useEffect(() => {
        if (isLoginSuccess) {
            const userEmail = jwt.decode(loginData.access_token)?.sub;
            const credentials = transform.user.import(loginData);

            saveCredentials(credentials, userEmail, userData.remember, () => {
                dispatch(changeAppIsAuthorized(!!credentials.token));
                dispatch(changeAppIsStartProjectAfterLogIn(!!credentials.token));
            });
        }
    }, [isLoginSuccess]);

    useEffect(() => {
        if (isLoginError) {
            toast.error(getResponseErrorMessage(loginError.data, txt.toasts.commonError));
            setIsLoading(false);
        }
    }, [isLoginError]);

    const initLoginRequest = () => {
        const formData = new FormData();

        formData.append('grant_type', 'password');
        formData.append('username', userData.email);
        formData.append('password', userData.password);
        formData.append('client_id', 'oauth');
        formData.append('client_secret', 'secret');
        formData.append('access_type', 'offline');

        login(formData);
    };

    const [
        checkEmailCode,
        { data: checkEmailCodeData, isSuccess: isCheckEmailCodeSuccess, isError: isCheckEmailCodeError }
    ] = useCheckEmailCodeMutation();

    useEffect(() => {
        if (isCheckEmailCodeSuccess) {
            setUserData({
                ...userData,
                emailToken: code,
                email: checkEmailCodeData.login,
                userEmail: checkEmailCodeData.login
            });

            if (checkEmailCodeData?.isWithPromo) {
                dispatch(changeAppIsWithPromo(true));
                dispatch(changeAppPromocode(checkEmailCodeData?.promo));
            }

            setIsInitialLoading(false);
        }
    }, [isCheckEmailCodeSuccess]);

    useEffect(() => {
        if (isCheckEmailCodeError) {
            toast.error(txt.toasts.invalidEmailToken);
            setIsInitialLoading(false);
        }
    }, [isCheckEmailCodeError]);

    useEffect(() => {
        if (code) checkEmailCode(code);
    }, []);

    const [confirmEmail, { isSuccess: isConfirmEmailSuccess, isError: isConfirmEmailError, error: confirmEmailError }] =
        useConfirmEmailMutation();

    useEffect(() => {
        if (isConfirmEmailSuccess) initLoginRequest();
    }, [isConfirmEmailSuccess]);

    useEffect(() => {
        if (isConfirmEmailError) {
            toast.error(getResponseErrorMessage(confirmEmailError.data, txt.errors.signingUpError));
            setIsLoading(false);
        }
    }, [isConfirmEmailError]);

    const getErrorPasswordText = () => {
        if (!userData.password) return txt.errors.required(txt.labels.password);
        if (userData.password.length < 8 && userData.emailToken) return txt.errors.min(txt.labels.password, 8);

        return '';
    };

    const errors = {
        email: !EMAIL_RULE.test(userData.email.toLowerCase()) ? txt.errors.email : '',
        password: getErrorPasswordText(),
        confirmPassword:
            userData.emailToken && userData.password !== userData.confirmPassword
                ? txt.errors.same(txt.labels.password)
                : ''
    };

    const isAllValid = Object.keys(errors).every(el => !errors[el]);

    const onSubmit = () => {
        setIsSubmitted(true);

        if (isAllValid) {
            setIsLoading(true);

            if (userData.emailToken) confirmEmail({ code: userData.emailToken, password: userData.password });
            else initLoginRequest();
        }
    };

    useKeyPress(KEYBOARD_SERVICE_KEY.ENTER, onSubmit);

    if (isInitialLoading) return <Loader />;

    return (
        <AuthForm
            title={txt.auth.logInTitle}
            questionLabel={txt.auth.dontHaveAcc}
            exitLabel={txt.auth.signUp}
            onExit={() => history.push('/register')}
        >
            <TextInput
                dataTest="login_email-input"
                value={userData.userEmail || userData.email}
                onChange={({ target: { value } }) => updateUserData('email', value)}
                icon="email"
                label={txt.labels.email}
                placeholder={txt.labels.email}
                error={isSubmitted && errors.email}
                disabled={isLoading || userData.userEmail}
                className="mb20"
            />

            <TextInput
                dataTest={userData.emailToken ? `register_password-input` : `login_password-input`}
                value={userData.password}
                onChange={({ target: { value } }) => updateUserData('password', value)}
                icon="password"
                type="password"
                label={txt.labels.password}
                placeholder={txt.labels.password}
                error={isSubmitted && errors.password}
                disabled={isLoading}
                className="mb20"
            />

            {userData.emailToken ? (
                <TextInput
                    dataTest="register_confirm-password-input"
                    value={userData.confirmPassword}
                    onChange={({ target: { value } }) => updateUserData('confirmPassword', value)}
                    icon="password"
                    type="password"
                    label={txt.labels.confirmPassword}
                    placeholder={txt.labels.confirmPassword}
                    error={isSubmitted && errors.confirmPassword}
                    disabled={isLoading}
                    className="mb25"
                />
            ) : null}

            <CheckBoxInput
                dataTest="login_remember-checkbox"
                label={txt.labels.remember}
                value={userData.remember}
                onChange={({ target: { checked } }) => updateUserData('remember', checked)}
                disabled={isLoading}
            />

            <AuthBtn disabled={isLoading || (isSubmitted && !isAllValid)} onClick={onSubmit} isLoading={isLoading}>
                {txt.auth.logIn}
            </AuthBtn>

            {!userData.emailToken ? (
                <div className="form-terms mt25">
                    <a
                        data-test="login_forgot-pass-link"
                        href="#forgot"
                        className="form-link"
                        onClick={e => {
                            e.preventDefault();
                            history.push('/forgot', { email: userData.email });
                        }}
                    >
                        {txt.auth.forgotPass}
                    </a>
                </div>
            ) : null}
        </AuthForm>
    );
};

export default PageLogin;
