import React, { useMemo } from 'react';
import { Plus } from 'react-feather';

import Btn from '../../Modules/Overall/UI/Btn/Btn';
import Switcher from '../../Modules/Overall/UI/Switcher/Switcher';

import './section-header.scss';

const SectionHeader = React.memo(({ title, subTitle, warningInfo, button, switcher, pagination, isLoading }) => {
    const buttonIcon = useMemo(() => {
        if (!button?.icon) return null;

        const { type, size = 16, stroke = '#fff' } = button.icon;

        switch (type) {
            case 'plus':
                return <Plus size={size} stroke={stroke} />;

            default:
                return null;
        }
    }, [button?.icon]);

    return (
        <header className="section-header">
            {switcher && !switcher.hidden ? (
                <div className="section-header__title j4">
                    <div>
                        <Switcher
                            dataTest={`section-header-switcher`}
                            isSwitchOn={switcher.isOn}
                            onChange={switcher.onClick}
                            label={switcher.label}
                            disabled={isLoading || switcher.disabled}
                        />
                    </div>

                    <span>{title}</span>

                    <span className={`${switcher.isOn ? 'color--de-york' : 'color--froly'} ml5`}>
                        ({switcher.isOn ? 'enabled' : 'disabled'})
                    </span>
                </div>
            ) : (
                <div className="section-header__title">{title}</div>
            )}

            {subTitle && <div className="section-header__subtitle">{subTitle}</div>}

            {warningInfo && <span className="section-header__warning-info">{warningInfo}</span>}

            {button && !button.hidden && (
                <Btn
                    dataTest="settings-header_btn"
                    type={button.type ?? 'filled'}
                    onClick={button.onClick}
                    className="plr30 j4"
                    disabled={isLoading || button.disabled}
                >
                    {button.icon && buttonIcon}
                    {button.label}
                </Btn>
            )}

            {pagination && <div className="section-header__pagination">{pagination}</div>}
        </header>
    );
});

export default SectionHeader;
