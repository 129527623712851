import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import { URL_PARAMETER } from '../../../../Constants';
import { getNotDangerouslyHTML } from '../../../../Functions/utils';

import HintIcon from '../../../Overall/Forms/Inputs/HintIcon';
import RadioInput from '../../../Overall/Forms/Inputs/RadioInput';
import CopyField from '../../../Overall/UI/CopyField';
import JustAlert, { JUST_ALERT_TYPE } from '../../../../components/just-alert/just-alert';

const HighlightedUrl = React.memo(({ values = {}, isOptional, onChange }) => {
    const txt = Langs[global.lng];
    const { clickToken, payoutToken, transactionToken, eventToken, statusToken, urlFormat, domain } = values || {};
    const [url, setUrl] = useState();

    const getUrlParameter = useCallback((parameter, value) => {
        value = getNotDangerouslyHTML(value);

        const htmlParameter = `<span class="${cn(
            value === URL_PARAMETER.OPTIONAL ? 'color--sea-buckthorn' : 'bg--sandy-beach p4',
            isOptional && !value && 'color--sea-buckthorn'
        )}">${value}</span>`;
        return value || isOptional ? `&<span class="color--primary">${parameter}</span>=${htmlParameter}` : '';
    }, []);

    const commonUrl = useMemo(
        () =>
            `<span class="color--primary">cid</span>=<span class="${
                clickToken ? 'bg--sandy-beach p4' : 'bg--wisp-pinky color--froly p4 affiliate-network--font-10'
            }">${getNotDangerouslyHTML(clickToken?.trim?.()) || URL_PARAMETER.REPLACE}</span>` +
            getUrlParameter('payout', payoutToken?.trim?.() || URL_PARAMETER.OPTIONAL) +
            getUrlParameter('txid', transactionToken?.trim?.() || URL_PARAMETER.OPTIONAL) +
            getUrlParameter('et', eventToken?.trim?.() || URL_PARAMETER.OPTIONAL) +
            getUrlParameter('status', statusToken?.trim?.() || URL_PARAMETER.OPTIONAL),
        [clickToken, payoutToken, transactionToken, eventToken, statusToken]
    );

    const domainPrefix = domain?.ssl ? 'https' : 'http';

    const urlTypes = [
        {
            label: 's2s_postback_url',
            leftUrlPart: `${domainPrefix}://${domain?.name}/postback?`,
            rightUrlPart: ''
        },
        {
            label: 'tracking_pixel',
            leftUrlPart: `<img src="${domainPrefix}://${domain?.name}/conversion.gif?`,
            rightUrlPart: ' width="1" height="1" />'
        },
        {
            label: 'tracking_pixel_url',
            leftUrlPart: `${domainPrefix}://${domain?.name}/conversion?`,
            rightUrlPart: ''
        },
        {
            label: 'tracking_script',
            leftUrlPart: `<script type="text/javascript" src="${domainPrefix}://${domain?.name}/conversion.js?$`,
            rightUrlPart: '"></script>'
        }
    ];

    const [activeUrlType, setActiveUrlType] = useState(
        urlFormat ? urlTypes[urlTypes.findIndex(el => el.label === urlFormat)] : urlTypes[0]
    );

    useEffect(() => {
        if (urlFormat) setActiveUrlType(urlTypes[urlTypes.findIndex(el => el.label === urlFormat)]);
    }, [urlFormat]);

    useEffect(() => {
        const textUrl = commonUrl
            .replace(/<\/?span[^>]*>/g, '')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/;/g, '&');
        setUrl(activeUrlType.leftUrlPart + textUrl + activeUrlType.rightUrlPart);
    }, [commonUrl, activeUrlType]);

    return (
        <>
            <div className="j4">
                <RadioInput
                    dataTest="affiliate-network"
                    type="row"
                    items={urlTypes.map(({ label }) => label)}
                    value={activeUrlType.label}
                    onChange={({ target: { value } }) => {
                        setActiveUrlType(urlTypes.find(({ label }) => label === value));
                        onChange?.(urlTypes.find(({ label }) => label === value).label);
                    }}
                />

                <HintIcon type="left" className="ml5" hint={txt.hints.conversionTrackingMethods} />
            </div>

            <CopyField dataTest="affiliate-network" copyText={url} className="copy-field--fixed-copy-btn mb8">
                <div className="affiliate-network__url-field">
                    <HintIcon iconType="alert" hint={txt.hints[activeUrlType.label] || ''} />

                    <span>
                        {/* {`${domain?.isHTTPS ? 'https' : 'http'}://${domain?.name}?`} */}
                        {activeUrlType.leftUrlPart}
                        {/* eslint-disable-next-line react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: commonUrl }} />
                        {activeUrlType.rightUrlPart}
                    </span>
                </div>
            </CopyField>

            <JustAlert type={JUST_ALERT_TYPE.INFO}>{txt.labels.copyAboveContent}</JustAlert>
        </>
    );
});

HighlightedUrl.displayName = 'HighlightedUrl';
export default HighlightedUrl;
