import * as Icon from 'react-feather';
import Traffic from './icons/Traffic';
import Switch from './icons/Switch';
import Alert from './icons/Alert';
import Spinner from './icons/Spinner';
import DollarCoin from './icons/DollarCoin';
import Click from './icons/Click';
import Compare from './icons/Compare';
import IntegratedError from './icons/integrated-error';

export default {
    ...Icon,
    Traffic,
    Switch,
    Spinner,
    Alert,
    DollarCoin,
    Click,
    Compare,
    IntegratedError
};
