import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { getColumns } from '../../components/main-table/services';
import { getParams, clone, compare } from '../../Functions/utils';
import { getActualDateRange } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';

import { useChangeStorageMutation } from '../../redux/api/storeApi';

import {
    changeMainTablePagination,
    changeMainTableSorting,
    selectReportsTableData,
    changeMainTableActiveRow,
    changeMainTableColumnFilters
} from '../../redux/main-table-slice';

import TableControls from './components/table-controls/table-controls';
import MainTableItems from '../../Modules/MainTable/MainTableItems/MainTableItems';

import './screen-reports.scss';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetChapterReportQuery } from '../../redux/api/reportsApi';
import useContextMenu from '../../Functions/hooks/useContextMenu';

const ScreenReports = ({ chapter, storage, reports, className }) => {
    const dispatch = useDispatch();

    const tableParameters = useSelector(selectReportsTableData);
    const { search, activeRow, sorting, pagination, columnFilters } = tableParameters;

    const dateRange = getActualDateRange(storage.common.dateRange);

    const parameters = {
        'filter[name]': search,
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        'filter[level1]': chapter,
        [`filter[dateFrom]`]: exportDateTransformer(dateRange.start),
        [`filter[dateTo]`]: exportDateTransformer(dateRange.end),
        ...(dateRange.compare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(dateRange.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(dateRange.endPrevDate)
              }
            : {}),
        ...columnFilters.reduce((acc, el) => {
            return {
                ...acc,
                ...(el.minValue ? { [`filter[${el.key}Min]`]: +el.minValue } : {}),
                ...(el.maxValue ? { [`filter[${el.key}Max]`]: +el.maxValue } : {})
            };
        }, {})
    };

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const {
        data: chapterReportData,
        isSuccess: isChapterReportDataLoaded,
        isFetching: isChapterReportDataFetching
    } = useGetChapterReportQuery(getParams(parameters));

    useEffect(() => {
        if (!isChapterReportDataLoaded) return;

        if (activeRow) {
            const row = chapterReportData.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter: 'reports',
                        value: row
                    })
                );
            }
        }

        if (chapterReportData?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter: 'reports',
                    value: chapterReportData.pagination
                })
            );
        }
    }, [chapterReportData]);

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting': {
                dispatch(
                    changeMainTableSorting({
                        chapter: 'reports',
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter: 'reports',
                        value: { ...pagination, page: 1 }
                    })
                );

                break;
            }

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.chapter_reports.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            case 'columnFilters': {
                dispatch(
                    changeMainTableColumnFilters({
                        chapter: 'reports',
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter: 'reports',
                        value: { ...pagination, page: 1 }
                    })
                );

                break;
            }

            default:
                break;
        }
    };

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter: 'reports',
                value: row.value === activeRow?.value ? null : row
            })
        );
    };

    const updateDateRange = newDateRange => {
        if (!compare(storage.common.dateRange, newDateRange)) {
            const newStorage = clone(storage);

            newStorage.common.dateRange = newDateRange;
            changeStorage(newStorage);

            dispatch(
                changeMainTablePagination({
                    chapter: 'reports',
                    value: { ...pagination, page: 1 }
                })
            );
        }
    };

    const [contextMenuRef, contextMenuSettings, onMainTableBodyScroll, onRowContextMenu] = useContextMenu();

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = chapterReportData?.items ?? [];

    const isLoaded =
        isChapterReportDataLoaded && !isChapterReportDataFetching && isUserSettingsLoaded && isGeneralSettingsLoaded;

    return (
        <>
            <div className={cn('table-container screen-reports', className)}>
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            pagination={pagination}
                            tableParameters={tableParameters}
                            dateRange={dateRange}
                            setDateRange={updateDateRange}
                            rows={rows}
                            columns={columns}
                            activeRow={activeRow}
                            storage={storage}
                            reports={reports}
                            generalSettings={generalSettings}
                            userSettings={userSettings}
                            contextMenuRef={contextMenuRef}
                            contextMenuSettings={contextMenuSettings}
                        />

                        <div className="main-table__visible-content" onScroll={onMainTableBodyScroll}>
                            <MainTableItems
                                chapter={chapter}
                                modifiedChapter={chapter}
                                activeRow={activeRow}
                                columns={columns}
                                columnFilters={columnFilters}
                                rows={rows}
                                total={chapterReportData?.total}
                                sorting={sorting}
                                storage={storage}
                                isLoading={!isLoaded}
                                onRowClick={onRowClick}
                                onRowContextMenu={(e, row) => onRowContextMenu(e, row, activeRow, onRowClick)}
                                handleChange={onTableActionsChange}
                                onChange={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScreenReports;
