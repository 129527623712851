const triggersTemplate = {
    status: 'enabled',
    name: '',
    group: 'dropdown',
    sleepTime: 5,
    sleepTimeCheckboxVal: true,
    notes: '',
    conditionTarget: {
        value: '',
        list: []
    },
    conditionMethod: {
        value: '',
        options: {}
    },
    actions: {
        isActions: true,
        list: []
    },
    notifications: {
        isNotifications: true,
        list: []
    }
};

export default triggersTemplate;
