const importSettingsConstructor = {
    columnSeparation: 'import-settings-modal__column-separation',
    columns: [
        {
            key: 'name',
            label: 'Name',
            className: 'select-table-items-header__th--name',
            cellClassName: 'select-table-items-body__td--normal'
        },
        {
            key: 'platName',
            label: 'Plat name',
            className: 'select-table-items-header__th--name',
            cellClassName: 'select-table-items-body__td--normal select-table-items-body__td--plat-name'
        },
        {
            key: 'importedDate',
            type: 'date',
            label: 'Imported',
            className: 'select-table-items-header__th--name',
            cellClassName: 'select-table-items-body__td--normal select-table-items-body__td--imported'
        }
    ]
};
export default importSettingsConstructor;
