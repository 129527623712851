export default [
    {
        key: 'date',
        label: 'Date',
        type: 'supportAccessDate',
        size: 50
    },
    {
        key: 'timezone',
        label: 'Time zone',
        type: 'supportAccessTimezone',
        size: 35
    },
    {
        key: 'actions',
        label: 'Actions',
        type: 'actions',
        size: 15
    }
];
