import React, { useEffect, useState } from 'react';

import Langs from '../../../../../Langs';
import { KEY_CODE } from '../../../../../Constants';

import useFocus from '../../../../../Functions/hooks/useFocus';

import TextInput from '../../../Forms/Inputs/TextInput';
import Btn from '../../../UI/Btn/Btn';
import DropdownBalloon from '../../../UI/DropdownBalloon/DropdownBalloon';

const AddBookmarkFavorite = ({ onAdded, tabLabel, setIsTabPopupOpen }) => {
    const txt = Langs[global.lng];

    const [label, setLabel] = useState(tabLabel || '');

    const [inputRef, setInputFocus] = useFocus();

    useEffect(setInputFocus, []);

    const handleKeyUp = e => {
        if (e.keyCode === KEY_CODE.ENTER) onAdded(label);
    };

    return (
        <DropdownBalloon className="dropdown-balloon--star">
            <TextInput
                dataTest="tab-item_name-input"
                type="text"
                label={txt.labels.addBookmark}
                value={label}
                onKeyUp={handleKeyUp}
                onChange={({ target: { value } }) => setLabel(value)}
                ref={inputRef}
            />

            <div className="j6 pt15">
                <Btn dataTest="tab-item_cancel-btn" className="plr15" onClick={() => setIsTabPopupOpen(false)}>
                    {txt.buttons.cancel}
                </Btn>

                <Btn dataTest="tab-item_apply-btn" className="ml5 plr30" type="filled" onClick={() => onAdded(label)}>
                    {txt.buttons.apply}
                </Btn>
            </div>
        </DropdownBalloon>
    );
};

export default AddBookmarkFavorite;
