import React, { forwardRef } from 'react';

const Traffic = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            stroke={color}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path d="M5 10L8.33333 13.3333L11.6667 10" />
            <path d="M1 2H5.66667C6.37391 2 7.05219 2.28095 7.55229 2.78105C8.05238 3.28115 8.33333 3.95942 8.33333 4.66667V12.6667" />
            <path d="M15.6666 2H11C10.2927 2 9.61444 2.28095 9.11434 2.78105C8.61424 3.28115 8.33329 3.95942 8.33329 4.66667V12.6667" />
        </svg>
    );
});

Traffic.displayName = 'Traffic';

export default Traffic;
