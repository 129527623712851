import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../../../Langs';
import { getResponseErrorMessage, removeTrialModalLastShowTime } from '../../../../../Functions/utils';
import { hideModal } from '../../../../../redux/operations';

import { useAddPaymentMethodMutation } from '../../../../../redux/api/paymentApi';

import ModalButtonBar from '../../../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Btn from '../../../../Overall/UI/Btn/Btn';
import Icon from '../../../../Overall/Icon';
import AddCard from '../../../../Overall/AddCard/AddCard';
import useCreditCardValidation from '../../../../../Functions/hooks/useCreditCardValidation';
import Loader from '../../../../Loader';

import cardIcon from '../../../../../../../media/images/paymentMethods/cardicon.svg';
import paypalIcon from '../../../../../../../media/images/paymentMethods/paypalicon.svg';

const AddPaymentPayment = ({ onClose, userEmail, isIntegrated, onAdded }) => {
    const txt = Langs[global.lng];

    const [isTouch, setIsTouch] = useState(false);
    const [responseError, setResponseError] = useState({});

    const [
        cardInfo,
        cardErrors,
        onCardNumberChange,
        onCardDateChange,
        onCardDateBlur,
        onCardCodeChange,
        onCardSaveChange
    ] = useCreditCardValidation();

    const isValid = !Object.values(cardErrors).filter(error => !!error).length;

    const [
        addPaymentMethod,
        {
            isLoading: isAddPaymentMethodLoading,
            isSuccess: isAddPaymentMethodSuccess,
            isError: isAddPaymentMethodError,
            error: addPaymentMethodError
        }
    ] = useAddPaymentMethodMutation();

    useEffect(() => {
        if (isAddPaymentMethodSuccess) {
            toast.success(txt.toasts.paymentMethodAdded);
            removeTrialModalLastShowTime(userEmail);
            hideModal();
            if (onAdded) onAdded();
        }
    }, [isAddPaymentMethodSuccess]);

    useEffect(() => {
        if (isAddPaymentMethodError) {
            setResponseError({
                responseError: getResponseErrorMessage(addPaymentMethodError.data, txt.toasts.saveError)
            });
        }
    }, [isAddPaymentMethodError]);

    const onSubmit = () => {
        setIsTouch(true);

        if (isValid) {
            addPaymentMethod({
                methodType: 'method_card',
                card: {
                    cardNumber: cardInfo.cardNumber,
                    expirationDate: `${cardInfo.cardDate.slice(0, 2)}/20${cardInfo.cardDate.slice(-2)}`,
                    cardCode: cardInfo.cardCode
                }
            });
        }
    };

    return (
        <>
            <div className="method-content">
                <div className="mode-buttons">
                    <Btn type="flexible" className="mode-button mode-button--active">
                        <img src={cardIcon} alt={txt.texts.creditCard} />
                        <div>{txt.texts.creditCard}</div>
                    </Btn>

                    <Btn type="flexible" disabled className="mode-button">
                        <img src={paypalIcon} alt={txt.texts.payPal} />
                        <div>{txt.texts.payPal}</div>
                    </Btn>
                </div>

                <AddCard
                    cardInfo={cardInfo}
                    onCardNumberChange={onCardNumberChange}
                    onCardDateChange={onCardDateChange}
                    onCardCodeChange={onCardCodeChange}
                    onCardSaveChange={onCardSaveChange}
                    onCardDateBlur={onCardDateBlur}
                    setResponseError={setResponseError}
                    errors={{ ...cardErrors, ...responseError }}
                    isTouch={isTouch}
                />

                <div className="color--de-york secure-method">
                    <Icon.Lock />
                    <div>{txt.texts.secureMethod}</div>
                </div>

                {isAddPaymentMethodLoading && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={isIntegrated ? txt.buttons.back : txt.buttons.cancel}
                confirmButtonText={isIntegrated ? txt.buttons.updatePayment : txt.buttons.save}
                onConfirm={onSubmit}
                onReject={isIntegrated ? isIntegrated?.onBtnClick : onClose}
                isConfirmDisabled={(isTouch && !isValid) || isAddPaymentMethodLoading}
            />
        </>
    );
};

export default AddPaymentPayment;
