import { useEffect } from 'react';

const useEventListener = (eventName, handler, targetNode = document, options = false) => {
    useEffect(() => {
        targetNode.addEventListener(eventName, handler, options);
        return () => {
            targetNode.removeEventListener(eventName, handler, options);
        };
    });
};

export default useEventListener;
