import classNames from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Langs from '../../../../Langs';

import HintIcon from '../../Forms/Inputs/HintIcon';

import './switcher.scss';

const Switcher = ({ label, note, isSwitchOn, disabled, onChange, hint, hintType, children, className, dataTest }) => {
    const txt = Langs[global.lng];

    const handleSwitcher = e => {
        e.preventDefault();
        if (onChange) onChange(!isSwitchOn);
    };

    return (
        <>
            <button
                className={classNames('switcher', disabled && 'switcher--disable', className)}
                onClick={disabled ? () => {} : handleSwitcher}
            >
                <div
                    data-test={`${dataTest}-switcher`}
                    className={classNames('switcher__container', isSwitchOn && 'switcher__container_on')}
                >
                    <div className={`switcher__stick ${isSwitchOn && 'switcher__stick_on'}`} />
                </div>
                {label && <span>{txt.labels[label] || label}</span>}
                {note && <span className="color--gray-gull switcher__note">{note}</span>}
                {hint && <HintIcon hint={txt.hints[hint] || hint} type={hintType} />}
            </button>
            <CSSTransition in={!!isSwitchOn} timeout={300} classNames="switcher" unmountOnExit>
                <div>{children}</div>
            </CSSTransition>
        </>
    );
};

export default React.memo(Switcher);
