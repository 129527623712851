import React from 'react';

import Langs from '../../../../Langs';

export const TdBadgesSquareInline = React.memo(props => {
    const { items, onClick, label } = props;
    if (!items) return null;
    const txt = Langs[global.lng];

    return (
        <div className="badge-square wrap">
            {label && <span className="badge-square__label">{label}</span>}
            {items.map((badge, index) => (
                <div key={index}>
                    {/* <Tag label={txt.labels.badges[badge.label] || badge.label} onClick={() => onClick(badge)} className="plr10" /> */}
                    <span
                        className={`badge-square__item
                                    ${badge.bgColor ? ' badge-square__item--' + badge.bgColor : ''}
                                    ${badge.isSelected ? ' badge-square__item--selected' : ''}
                                    ${onClick ? ' badge-square__item--action' : ''}`}
                        onClick={() => onClick && onClick(badge)}
                    >
                        {txt.labels.badges[badge.label] || badge.label}
                    </span>
                </div>
            ))}
        </div>
    );
});

TdBadgesSquareInline.displayName = 'TdBadgesSquareInline';
