import React, { useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import useClickOutside from '../../../../../Functions/hooks/useClickOutside';
import useToggle from '../../../../../Functions/hooks/useToggle';
import useSearch from '../../../../../Functions/hooks/useSearch';
import Langs from '../../../../../Langs';
import { KEY_CODE } from '../../../../../Constants';
import { fixRegexPattern } from '../../../../../Functions/utils';
import { setState } from '../../../../../redux/operations';

import OpenedPart from './OpenedPart';
import { SELECT_INPUT_OPTIONS_TYPE } from './SelectItem';
import HintIcon from '../HintIcon';
import Icon from '../../../Icon';

export const SelectInput = React.memo(props => {
    const txt = Langs[global.lng];
    const {
        // eslint-disable-next-line no-unused-vars
        absTooltip,
        id,
        label,
        className,
        value,
        onChange = () => {},
        items = [],
        error,
        hint,
        hintType,
        disabled,
        isHoverable,
        // eslint-disable-next-line no-unused-vars
        short,
        placeholder,
        displayLabel = 'label',
        dataTest,
        // eslint-disable-next-line no-unused-vars
        optionsDirection = 'bottom',
        isSearchable,
        isCleanable,
        optionsType,
        isUseAbs,
        labelRequire,
        inputSearchRef
    } = props;

    const { isToggled, toggle, toggleOn, toggleOff } = useToggle();
    const { filteredItems, handleInputChange, setItems, searchBy, setSearchBy } = useSearch(
        items,
        item => item?.label || item
    );

    const blur = () => {
        toggleOff();
        if (!isSearchable) return;
        const regexExpr = new RegExp(`^${fixRegexPattern(searchBy)}$`, 'i');
        items.forEach(item => {
            if (regexExpr.test(item?.[displayLabel] || item)) {
                if (item.value === value || item === value) return;
                onChange({ target: { value: item.value || item } });
            }
        });
    };

    const handleKeyUp = ({ keyCode, target }) => {
        if (keyCode === KEY_CODE.ENTER) {
            blur();
            target.blur();
        }
    };

    const itemRef = useRef(null);
    const parentRef = useRef(null);
    const mainContainerRef = useRef(null);
    const allRefs = useRef({ itemRef, parentRef, mainContainerRef });

    const outsideRef = !isUseAbs ? useClickOutside(blur) : useRef(null);
    const inputButtonRef = useRef(null);

    const isObjectUsed = !!(items && typeof items[0] === 'object' && items[0] !== null);

    const handleItemClick = item => {
        if (optionsType !== SELECT_INPUT_OPTIONS_TYPE.LINK) {
            onChange({ target: { value: item?.value !== undefined ? item?.value : item } });
        }
        toggleOff();
    };

    const selectedLabel = useMemo(() => {
        if (!isObjectUsed) return value !== undefined ? txt?.labels[value] || value : placeholder;
        const sourceLabel =
            items.find(el => el.value === value)?.[displayLabel] ||
            items.reduce((acc, item) => acc || item.items?.find(el => el.value === value)?.[displayLabel], null);
        return value !== undefined ? txt?.labels[sourceLabel] || sourceLabel?.toString() : placeholder;
    }, [items, value]);

    const isSelectedLabelEqualsToSearch = searchBy === selectedLabel;
    const currentItems = isSelectedLabelEqualsToSearch || !isSearchable ? items : filteredItems;

    const openedPartProps = useMemo(
        () => ({
            ...props,
            ref: allRefs,
            isObjectUsed,
            handleItemClick,
            currentItems,
            isSelectedLabelEqualsToSearch,
            searchBy
        }),
        [items, value, searchBy, filteredItems, selectedLabel, mainContainerRef.current]
    );

    const mainTopPosition = mainContainerRef.current?.getBoundingClientRect()?.top;
    const topPosition = mainTopPosition + mainContainerRef.current?.offsetHeight || 0;

    const mainLeftPosition = mainContainerRef.current?.getBoundingClientRect()?.left;
    const leftPosition = mainLeftPosition || 0;

    const absTooltipData = useMemo(
        () => ({
            body: OpenedPart,
            props: openedPartProps,
            baseContainer: inputButtonRef.current,
            clickOutsideCallback: () => toggleOff(),
            top: topPosition,
            left: leftPosition
        }),
        [mainContainerRef.current, parentRef.current, value, searchBy, mainTopPosition, mainLeftPosition]
    );

    const setAbs = (data, path) => {
        if (isUseAbs) setState(isToggled ? data || absTooltipData : null, path || 'absTooltip');
    };

    useEffect(() => {
        const parent = parentRef.current;
        const child = itemRef.current;
        if (child && parent.scrollHeight > parent.clientHeight) {
            parent.scrollTop = child.offsetTop - child.clientHeight;
        }
    }, [isToggled]);

    useEffect(() => setSearchBy(selectedLabel), [value, selectedLabel, isToggled]);
    useEffect(() => setItems(items), [items]);
    useEffect(() => setAbs(), [isToggled, absTooltipData, mainTopPosition, mainLeftPosition]);

    useEffect(() => {
        if (isToggled) setAbs(openedPartProps, 'absTooltip/props');
    }, [openedPartProps]);

    return (
        <div className={cn('form-label', className)} ref={mainContainerRef} onScroll={() => setAbs()}>
            <div className="j4">
                <div className="mr5">{label}</div>
                {labelRequire && <span className="form-label-title__label-required">*</span>}
                {hint && <HintIcon hint={txt.hints[hint] || hint} type={hintType} />}
            </div>
            <div
                className="select-form"
                ref={outsideRef}
                onMouseEnter={isHoverable && !disabled ? toggleOn : null}
                onMouseLeave={isHoverable && toggleOff}
            >
                <button
                    ref={inputButtonRef}
                    data-test={dataTest}
                    id={id}
                    className={cn(
                        'form-input form-select__input',
                        { 'form-select__input--searchable': isSearchable },
                        { 'form-select__input--disabled': disabled },
                        { 'form-select__input--placeholder': value === undefined },
                        { 'form-select__input--opened': isToggled },
                        { 'form-select__input--cleanable': isCleanable && value },
                        { 'form-input--error': error }
                    )}
                    onClick={e => {
                        if (!isSearchable) {
                            if (!disabled && !isHoverable) toggle();
                        } else if (!isToggled) toggleOn();
                        else if (!inputButtonRef.current.firstChild.contains(e.target)) toggleOff();
                    }}
                    // onBlur={toggleOff}
                    // onFocus={toggleOn}
                    tabIndex={disabled ? -1 : 0}
                >
                    {isSearchable ? (
                        <input
                            type="text"
                            value={searchBy || ''}
                            ref={inputSearchRef}
                            className="form-select__input-text--searchable"
                            onChange={handleInputChange}
                            onKeyUp={handleKeyUp}
                            onFocus={({ target }) => {
                                target.select();
                                toggleOn();
                            }}
                            disabled={disabled}
                        />
                    ) : (
                        selectedLabel
                    )}
                </button>
                {isCleanable && value && (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    <div className="form-select__clear-btn" tabIndex={0} onClick={() => handleItemClick(undefined)}>
                        <Icon.X />
                    </div>
                )}
                {isToggled && currentItems?.length > 0 && !isUseAbs && (
                    <OpenedPart
                        {...props}
                        ref={allRefs}
                        isObjectUsed={isObjectUsed}
                        handleItemClick={handleItemClick}
                        currentItems={currentItems}
                        isSelectedLabelEqualsToSearch={isSelectedLabelEqualsToSearch}
                        searchBy={searchBy}
                        isUseAbs={isUseAbs}
                    />
                )}
            </div>
            {error && <div className="form-label--error">{error}</div>}
        </div>
    );
});

const Store = ({ oldState: { absTooltip } }) => ({ absTooltip });

SelectInput.displayName = 'SelectInput';
export default connect(Store)(SelectInput);
