import React, { forwardRef } from 'react';

const Click = forwardRef(({ color = 'currentColor', size = 16, ...rest }, ref) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            stroke={color}
            ref={ref}
            {...rest}
        >
            <circle cx="6" cy="6" r="2" fill={color} />
            <path
                d="M6 6L9.46348 14.3133L10.6931 10.6931L14.3133 9.46348L6 6Z"
                strokeWidth="1.07"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8988 10.8989L13.8381 13.8382"
                strokeWidth="1.07"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.75806 7.37002C10.075 6.50057 10.0807 5.54812 9.77415 4.67495C9.4676 3.80178 8.86776 3.06191 8.07686 2.58142C7.28595 2.10093 6.35291 1.90954 5.43671 2.03986C4.52051 2.17018 3.67784 2.61415 3.05228 3.29612C2.42673 3.97809 2.05699 4.85586 2.00606 5.77988C1.95514 6.7039 2.22618 7.61699 2.773 8.36358C3.31983 9.11016 4.1086 9.64404 5.00493 9.87425C5.90126 10.1045 6.84969 10.0168 7.6886 9.6261"
                strokeWidth="1.07"
            />
        </svg>
    );
});

Click.displayName = 'Click';

export default Click;
