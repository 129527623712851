import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import Langs from '../../../Langs';
import { KEY_CODE } from '../../../Constants';
import useFocus from '../../../Functions/hooks/useFocus';
import useToggle from '../../../Functions/hooks/useToggle';

import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Btn from '../../Overall/UI/Btn/Btn';

import lessEqualIcon from '../../../../../media/images/less-equal.svg';
import greaterEqualIcon from '../../../../../media/images/greater-equal.svg';

const MainTableHeaderFilter = React.memo(
    ({ onClose, prop: { key, handleApplyFilter, handleDeleteFilter, getFilterValue, chapter } }) => {
        const [minValue, setMinValue] = useState(getFilterValue(key)?.minValue || '');
        const [maxValue, setMaxValue] = useState(getFilterValue(key)?.maxValue || '');
        const { isToggled: isLessThan, toggleOn: setLessThan, toggleOff: unsetLessThan } = useToggle(!!maxValue);
        const [inputRef, setInputFocus] = useFocus();
        const txt = Langs[global.lng];

        useEffect(() => {
            setInputFocus();
        }, [isLessThan]);

        const handleApply = () => {
            let curMinVal = minValue;
            let curMaxVal = maxValue;
            if (maxValue - minValue <= 0) {
                const filter = getFilterValue(key);
                curMinVal = filter?.minValue === minValue ? '' : minValue;
                curMaxVal = filter?.maxValue === maxValue ? '' : maxValue;
            }
            handleApplyFilter({
                key,
                minValue: curMinVal,
                maxValue: curMaxVal
            });
            onClose();
        };

        const handleReset = () => {
            handleDeleteFilter(key);
            setMinValue('');
            setMaxValue('');
        };

        const handleInputChange = ({ target: { value } }) => {
            const handleFunction = isLessThan ? setMaxValue : setMinValue;
            handleFunction(value.replace(/[^\d.]+/g, ''));
        };

        const handleKeyUp = ({ keyCode }) => {
            if (keyCode === KEY_CODE.ENTER && (minValue || maxValue)) handleApply();
        };

        return (
            <div className="header-filter">
                <div className=" mb8 header-filter__arrow-button-group">
                    <Btn
                        dataTest={`main-table-header-filter_${chapter}_${key}_chevron-left-btn`}
                        className={cn(isLessThan && 'btn--active')}
                        type="icon"
                        onClick={setLessThan}
                    >
                        <img src={lessEqualIcon} width="16" height="16" alt="Less or Equal icon" />
                    </Btn>
                    <Btn
                        dataTest={`main-table-header-filter_${chapter}_${key}_chevron-right-btn`}
                        className={cn(!isLessThan && 'btn--active')}
                        type="icon"
                        onClick={unsetLessThan}
                    >
                        <img src={greaterEqualIcon} width="16" height="16" alt="Greater or Equal icon" />
                    </Btn>
                </div>
                <TextInput
                    dataTest={`main-table-header-filter_${chapter}_${key}_input`}
                    value={isLessThan ? maxValue : minValue}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    ref={inputRef}
                />
                <Btn dataTest={`main-table-header-filter_${chapter}_${key}_reset-btn`} onClick={handleReset}>
                    {txt.buttons.reset}
                </Btn>
                <Btn
                    dataTest={`main-table-header-filter_${chapter}_${key}_apply-btn`}
                    type="filled"
                    onClick={handleApply}
                    disabled={!(minValue || maxValue)}
                >
                    {txt.buttons.apply}
                </Btn>
            </div>
        );
    }
);

MainTableHeaderFilter.displayName = 'MainTableHeaderFilter';

export default MainTableHeaderFilter;
