import React from 'react';
import { useHistory } from 'react-router-dom';

import { clone } from '../../../../../Functions/utils';

import { useChangeReportsMutation } from '../../../../../redux/api/reportsApi';

import SelectItemLabel from '../../../Forms/Inputs/SelectInput/SelectItemLabel';
import Icon from '../../../Icon';

const BookmarkItem = ({ reports, item, setIsTabPopupOpen, favorites, search }) => {
    const history = useHistory();

    const [changeReport] = useChangeReportsMutation();

    const onBookmarkClick = e => {
        e.preventDefault();

        const result = clone(reports);
        const index = result.findIndex(el => el.value === item.id);

        if (index === -1) return;

        if (!result[index].bookmark.isActive) {
            result[index].bookmark.isActive = true;

            changeReport(result);
        }

        history.push(item.path);

        setIsTabPopupOpen(false);
    };

    const onDeleteBookmark = e => {
        e.preventDefault();
        e.stopPropagation();

        const result = clone(reports);
        const index = result.findIndex(el => el.value === item.id);

        if (index === -1) return;

        if (item.isActive) result[index].bookmark.isFavorite = false;
        else result.splice(index, 1);

        changeReport(result);

        if (favorites.length < 2) setIsTabPopupOpen(false);
    };

    return (
        <a data-test="bookmark-item-link" href={item.path} onClick={onBookmarkClick} className="bookmark-item">
            <div className="bookmark-item__label">
                <SelectItemLabel label={item.label} match={search} />
            </div>

            <div data-test="bookmark-item_delete-btn" className="bookmark-item__btn" onClick={onDeleteBookmark}>
                <Icon.Trash2 />
            </div>
        </a>
    );
};

export default BookmarkItem;
