import React, { forwardRef } from 'react';

const DollarCoin = forwardRef(({ color = 'currentColor', size = 16, ...rest }, ref) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            stroke={color}
            ref={ref}
            {...rest}
        >
            <circle cx="8" cy="8" r="7.465" strokeWidth="1.07" />
            <path d="M8 3V13" strokeWidth="1.07" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M10.2727 4.81836H6.86362C6.44168 4.81836 6.03703 4.98597 5.73867 5.28433C5.44032 5.58268 5.27271 5.98734 5.27271 6.40928C5.27271 6.83121 5.44032 7.23587 5.73867 7.53422C6.03703 7.83258 6.44168 8.00019 6.86362 8.00019H9.13636C9.55829 8.00019 9.96295 8.1678 10.2613 8.46616C10.5597 8.76451 10.7273 9.16917 10.7273 9.59111C10.7273 10.013 10.5597 10.4177 10.2613 10.7161C9.96295 11.0144 9.55829 11.182 9.13636 11.182H5.27271"
                strokeWidth="1.07"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});

DollarCoin.displayName = 'DollarCoin';

export default DollarCoin;
