import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import produce from 'immer';
import { toast } from 'react-toastify';
import { ArrowRight } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { uppercase, getResponseErrorMessage } from '../../../Functions/utils';

import indexApi from '../../../redux/api/indexApi';
import { useChangeCampaignsCostFromIntegrationMutation } from '../../../redux/api/campaignsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import JustAlert from '../../../components/just-alert/just-alert';
import Loader from '../../Loader';

import './sync-campaign-cost-modal.scss';

const SyncCampaignCostModal = ({ onClose, bidAlertData }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const [isProcessing, setIsProcessing] = useState(false);

    const [alertList, setAlertList] = useState(bidAlertData);

    const [changeCost, { isSuccess: isChangeCostSuccess, isError: isChangeCostError, error: changeCostError }] =
        useChangeCampaignsCostFromIntegrationMutation();

    useEffect(() => {
        if (isChangeCostSuccess) {
            toast.success(txt.toasts.saved);
            onClose();
        }
    }, [isChangeCostSuccess]);

    useEffect(() => {
        if (isChangeCostError) {
            toast.error(getResponseErrorMessage(changeCostError.data, txt.toasts.saveError));
            setIsProcessing(false);
        }
    }, [isChangeCostError]);

    const onApplyAllCost = () => {
        setIsProcessing(true);

        const items = alertList.filter(el => !el.isEqual);

        changeCost({
            ids: items.map(el => el.id),
            campaignIds: items.map(el => el.campaignId)
        });
    };

    const onApplyCost = async (id, campaignId) => {
        setIsProcessing(true);

        await dispatch(
            indexApi.endpoints.changeCampaignsCostFromIntegration.initiate({
                ids: [id],
                campaignIds: [campaignId]
            })
        )
            .then(res => {
                if (!res.error) {
                    const notEqualCount = alertList.filter(el => !el.isEqual).length;

                    if (notEqualCount - 1 <= 0) onClose();
                    else {
                        setAlertList(
                            produce(draft => {
                                const index = draft.findIndex(el => el.id === id);
                                draft[index].isEqual = true;
                            })
                        );

                        setIsProcessing(false);
                    }

                    toast.success(txt.toasts.saved);
                } else {
                    toast.error(getResponseErrorMessage(res.error.data, txt.toasts.saveError));
                    setIsProcessing(false);
                }
            })
            .catch(() => setIsProcessing(false));
    };

    return (
        <>
            <div className="sync-campaign-cost-modal">
                <JustAlert>
                    Some campaigns have a cost (or cost model) in settings that is not equal to the TS campaign cost
                    (obtained via TS integration)
                </JustAlert>

                <div className="sync-campaign-cost-modal__head">
                    <span className="sync-campaign-cost-modal__head-first">Campaigns</span>
                    <span className="sync-campaign-cost-modal__head-second sync-campaign-cost-modal__head-second--first">
                        TS campaign cost
                    </span>
                    <span className="sync-campaign-cost-modal__head-second">Settings cost</span>
                </div>

                <div className="sync-campaign-cost-modal__list">
                    {alertList.map(el => {
                        return (
                            <div
                                key={el.id}
                                className={cn('sync-campaign-cost-modal__item', {
                                    'sync-campaign-cost-modal__item--equal': el.isEqual
                                })}
                            >
                                <div className="sync-campaign-cost-modal__item-block">
                                    <span>{el.displayName}</span>
                                </div>

                                <div className="sync-campaign-cost-modal__item-block">
                                    <div className="sync-campaign-cost-modal__cost">
                                        {uppercase(el.trafficSourceCostMode)} ${el.trafficSourcePrice}
                                    </div>

                                    <div
                                        className="sync-campaign-cost-modal__btn"
                                        onClick={() => {
                                            if (!el.isEqual) onApplyCost(el.id, el.campaignId);
                                        }}
                                    >
                                        <span>{el.isEqual ? 'Is equal' : 'Apply TS cost'}</span>
                                        {!el.isEqual && <ArrowRight size={15} />}
                                    </div>

                                    <div className="sync-campaign-cost-modal__cost">
                                        {uppercase(el.isEqual ? el.trafficSourceCostMode : el.campaignCostMode)} $
                                        {el.isEqual ? el.trafficSourcePrice : el.campaignPrice}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <p className="sync-campaign-cost-modal__descr">
                    You can update the total campaign cost for a specific period using the <span>Upgrade cost</span>{' '}
                    tool.
                </p>

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.close}
                confirmButtonText="Apply all"
                onReject={onClose}
                isConfirmDisabled={isProcessing}
                onConfirm={onApplyAllCost}
            />
        </>
    );
};

export default SyncCampaignCostModal;
