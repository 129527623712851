import React, { useMemo } from 'react';
import cn from 'classnames';
import { Cpu } from 'react-feather';

import Langs from '../../Langs';
import useSearch from '../../Functions/hooks/useNewSearch';

import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';

import './modal-templates.scss';

const ModalTemplates = React.memo(({ templates, activeTemplateId, onTemplateClick, isIntegrationStatusSplit }) => {
    const txt = Langs[global.lng];

    const { filteredItems, setSearchByForInput, searchByValue } = useSearch(
        templates,
        ({ templateName }) => templateName,
        [templates[0]]
    );

    const trafficSources = useMemo(() => {
        if (isIntegrationStatusSplit) {
            return Object.values(
                filteredItems.reduce(
                    (items, item) => {
                        const key = item.isIntegrationAvailable ? 'integrationAvailable' : 'integrationNotAvailable';
                        items[key].items.push(item);
                        return items;
                    },
                    {
                        integrationAvailable: { title: 'integrationAvailable', items: [] },
                        integrationNotAvailable: { title: 'integrationNotAvailable', items: [] }
                    }
                )
            );
        }

        return [{ items: filteredItems }];
    }, [searchByValue, isIntegrationStatusSplit]);

    return (
        <div className="template-modal__list">
            <div className="template-modal__search-box">
                <span>{txt.titles.selectTemplate}</span>

                <TextInput
                    dataTest="template-modal_select-template-input"
                    placeholder={txt.placeholders.typeToSearch}
                    value={searchByValue}
                    onChange={setSearchByForInput}
                    reset
                />
            </div>

            <div className="template-modal__items-list">
                {trafficSources.map((el, i) => {
                    if (el.items <= 0) return null;

                    return (
                        <div key={el.title ?? `template${i}`} className="template-modal__list-container">
                            {el.title && (
                                <div className="template-modal__list-title">
                                    <span>{txt.titles[el.title] || el.title}</span>
                                </div>
                            )}

                            {el.items.map(template => (
                                <div
                                    key={template.id}
                                    className={cn(
                                        'template-modal__list-item',
                                        activeTemplateId === template.id && 'template-modal__list-item--active'
                                    )}
                                    onClick={() => onTemplateClick(template)}
                                >
                                    <span>{template.templateName}</span>
                                    {template.isIntegrationAvailable && <Cpu size={15} stroke="#9AA0B9" />}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

ModalTemplates.displayName = 'ModalTemplates';
export default ModalTemplates;
