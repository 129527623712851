import React from 'react';

import Langs from '../../../Langs';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './change-workspace-warning-modal.scss';

const ChangeWorkspaceWarningModal = ({ onClose, onConfirm, target }) => {
    const txt = Langs[global.lng];

    const content = (() => {
        switch (target) {
            case 'campaigns':
                return 'tr.source, flow, offers, landers';

            case 'flows':
                return 'campaigns, offers, landers';

            case 'offers':
                return 'aff. network, redirect offer';

            default:
                break;
        }
    })();

    return (
        <>
            <div className="change-workspace-warning-modal">
                <div>
                    {`The workspace is changed, the selected entities (${content}) will be removed from settings`}
                </div>
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                confirmButtonType="danger"
                onConfirm={onConfirm}
                onReject={onClose}
            />
        </>
    );
};

export default ChangeWorkspaceWarningModal;
