import indexApi from './indexApi';
import { transform } from '../transformers';
import { camelToKebabCase } from '../../Functions/utils';

export const mainEntityApi = indexApi.injectEndpoints({
    endpoints: builder => ({
        addMainEntityItem: builder.mutation({
            query: ({ entity, link, data, transformer }) => ({
                url: `/${camelToKebabCase(entity)}${link ? `/${link}` : ''}`,
                method: 'POST',
                body: transformer ? transform[transformer].export(data) : data
            }),
            invalidatesTags: (result, error, { entity, id }) => {
                if (!error)
                    return [
                        { type: entity, id },
                        { type: entity, id: 'ALL' },
                        { type: 'integrations', id: 'LIST' }
                    ];
                else return [];
            }
        }),

        editMainEntityItem: builder.mutation({
            query: ({ entity, id, link, data, transformer }) => ({
                url: `/${camelToKebabCase(entity)}${link ? `/${link}` : ''}/${id}`,
                method: 'PUT',
                body: transformer ? transform[transformer].export(data) : data
            }),
            invalidatesTags: (result, error, { entity, id }) => {
                if (!error)
                    return [
                        { type: entity, id },
                        { type: entity, id: 'ALL' },
                        { type: 'integrations', id: 'LIST' }
                    ];
                else return [];
            }
        })
    })
});

export const { useEditMainEntityItemMutation, useAddMainEntityItemMutation } = mainEntityApi;
