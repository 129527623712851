import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { INTEGRATION_FEATURES } from '../../../Constants/index';
import { hideModal, showModal } from '../../../redux/operations';

import {
    useChangeIntegrationDataTransferMutation,
    useDeleteIntegrationMutation,
    useGetIntegrationsQuery
} from '../../../redux/api/integrationsApi';

import Loader from '../../Loader';
import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import IntegrationFeatures from '../../../components/integration-features/integration-features';
import JustTable from '../../../components/just-table/just-table';
import AddIntegrationModal from '../../Modals/add-integration-modal/add-integration-modal';
import ConfirmDeleteModal from '../../Modals/confirm-delete-modal/confirm-delete-modal';
import ArchiveResultModal from '../../Modals/ArchiveResultModal';

import './integrations.scss';

import cols from './table-constructor';

const Integrations = () => {
    const txt = Langs[global.lng];

    const {
        data: integrations,
        isSuccess: isIntegrationsLoaded,
        isFetching: isIntegrationsFetching
    } = useGetIntegrationsQuery();

    const [
        changeDataTransfer,
        {
            isLoading: isChangeDataTransferLoading,
            isError: isChangeDataTransferError,
            isSuccess: isChangeDataTransferSuccess
        }
    ] = useChangeIntegrationDataTransferMutation();

    const [
        deleteIntegration,
        {
            isLoading: isDeleteIntegrationLoading,
            isError: isDeleteIntegrationError,
            error: deleteIntegrationError,
            isSuccess: isDeleteIntegrationSuccess
        }
    ] = useDeleteIntegrationMutation();

    useEffect(() => {
        if (isChangeDataTransferSuccess) toast.success(txt.toasts.saved);
    }, [isChangeDataTransferSuccess]);

    useEffect(() => {
        if (isDeleteIntegrationSuccess) {
            toast.success(txt.toasts.deleted);
            hideModal();
        }
    }, [isDeleteIntegrationSuccess]);

    useEffect(() => {
        if (isChangeDataTransferError) toast.error(txt.toasts.saveError);

        if (isDeleteIntegrationError) {
            toast.error(deleteIntegrationError?.data?.errors?.message || txt.toasts.deletedError);

            if (deleteIntegrationError?.data?.errors?.options) {
                hideModal();

                showModal({
                    title: txt.titles.deletingResult,
                    body: props => (
                        <ArchiveResultModal
                            {...props}
                            subtitle={txt.texts.getCouldNotBeArchivedSubtitle('Integration', true)}
                            integrations={deleteIntegrationError?.data?.errors?.options}
                        />
                    )
                });
            }
        }
    }, [isChangeDataTransferError, isDeleteIntegrationError]);

    const showAddIntegrationModal = () => {
        showModal({
            title: txt.titles.addIntegration,
            body: props => <AddIntegrationModal {...props} />
        });
    };

    const sectionHeader = (
        <SectionHeader
            title={txt.titles.integrations.toUpperCase()}
            button={{ label: txt.buttons.addIntegration, icon: { type: 'plus' }, onClick: showAddIntegrationModal }}
        />
    );

    const rows = integrations?.map(row => ({
        ...row,
        actions: [{ type: 'deleteRow' }] //{ type: 'editRow' }
    }));

    // const [editedIntegration, setEditedIntegration] = useState(null);

    // const { data: templateItem, isSuccess: isTemplateItemLoaded } = useGetTemplateItemQuery(
    //     { entity: 'trafficSources', id: editedIntegration?.templateId },
    //     { skip: !editedIntegration }
    // );

    // useEffect(() => {
    //     if (isTemplateItemLoaded && templateItem?.id === editedIntegration.templateId) {
    //         const ModalBody = (() => {
    //             if (templateItem.name === 'Facebook') return FacebookIntegrationModal;
    //             if (templateItem.name === 'Google Ads - Search/Display') return GoogleIntegrationModal;

    //             return TSIntegrationModal;
    //         })();

    //         showModal({
    //             title: txt.titles.integrateAccount,
    //             body: props => (
    //                 <ModalBody
    //                     {...props}
    //                     templateName={editedIntegration.name.split(' - ')[0] ?? editedIntegration.name}
    //                     templateId={editedIntegration.templateId}
    //                     integrationId={editedIntegration.id}
    //                     mode={ENTITY_MODE_STATUS.EDIT}
    //                     fields={templateItem.integrationAuthFields}
    //                     chapter={'trafficSources'}
    //                 />
    //             )
    //         });
    //     }
    // }, [templateItem, editedIntegration]);

    const onActionClick = (actionType, row, value) => {
        switch (actionType) {
            case 'isActive':
                changeDataTransfer({
                    body: {
                        integrationId: row.id,
                        isDataTransferActive: value
                    }
                });
                break;
            // case 'editRow':
            //     setEditedIntegration(row);
            //     break;
            case 'deleteRow':
                showModal({
                    title: txt.titles.deleteIntegration,
                    body: props => (
                        <ConfirmDeleteModal
                            {...props}
                            title={txt.texts.getWillBeArchivedSubtitle(row.name, true)}
                            entity={'integration'}
                            onConfirm={() => deleteIntegration({ id: row.id })}
                        />
                    )
                });
                break;
            default:
                break;
        }
    };

    const isProcessing = isChangeDataTransferLoading || isDeleteIntegrationLoading;

    if (!isIntegrationsLoaded) return <Loader />;

    return (
        <Section Header={sectionHeader} className="integrations">
            <IntegrationFeatures title={txt.titles.integrationAllowYouTo} features={INTEGRATION_FEATURES} />

            {integrations.length ? (
                <JustTable className="mt30" gap={50} cols={cols} rows={rows} onActionClick={onActionClick} />
            ) : null}

            {(isProcessing || isIntegrationsFetching) && <Loader isFetching />}
        </Section>
    );
};

export default Integrations;
