import React from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import Langs from '../../../../Langs';
import { MODAL_TYPES } from '../../../../Constants';

import Section from '../../../../components/section/section';
import SectionHeader from '../../../../components/section-header/section-header';
import Loader from '../../../Loader';

import './current-plan.scss';

const formatNumber = num => new Intl.NumberFormat('en-EN').format(+num);

export const CurrentPlan = ({ currentPlan, isUnlimited, showModalByType, isFetching }) => {
    const txt = Langs[global.lng];

    const overdraft =
        currentPlan.eventsIncluded - currentPlan.eventsUsed < 0
            ? Math.abs(currentPlan.eventsIncluded - currentPlan.eventsUsed)
            : 0;

    const getNextPlanName = () => {
        const isDowngrade = currentPlan.monthlyPayment > currentPlan.nextPlan.monthlyPayment;

        return (
            <span className="colored">
                {currentPlan.nextPlan?.name}{' '}
                <span className={cn('text-uppercase', isDowngrade ? 'color--froly' : 'color--de-york')}>{`(${
                    isDowngrade ? txt.labels.downgrade : txt.labels.renew
                })`}</span>
            </span>
        );
    };

    const data = {
        monthlyPayment: currentPlan.monthlyPayment ? `$${currentPlan.monthlyPayment}` : 'Free',

        ...(currentPlan.nextPlan ? { nextPlan: getNextPlanName() } : {}),
        currentPeriod:
            currentPlan.dateFrom === currentPlan.dateTo
                ? 'Unlimited'
                : `${moment(currentPlan.dateFrom).format('L')} - ${moment(currentPlan.dateTo).format('L')}`,
        eventsIncluded: formatNumber(currentPlan.eventsIncluded),
        eventsUsed: [formatNumber(currentPlan.eventsUsed), formatNumber(overdraft)],
        overageCost: `$${currentPlan.overageCostFor1000} per 1000`
    };

    const rows = Object.keys(data).map(key => ({
        name: txt.labels.billing.currentPlan[key],
        value: data[key]
    }));

    const sectionHeader = (
        <SectionHeader
            title={txt.titles.currentPlan}
            subTitle={`${currentPlan.name} ${currentPlan.monthlyPayment ? `$${currentPlan.monthlyPayment}/mo` : ''}`}
            button={{
                label: txt.buttons.changePlan,
                onClick: () => showModalByType(MODAL_TYPES.CHOOSE_PLAN),
                hidden: isUnlimited
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="mb25 current-plan">
            <div className={cn('settings-table-body')}>
                {rows?.map((item, index) => {
                    return (
                        <div
                            key={item.name}
                            className={cn('j46', 'pt10', 'pb10', {
                                'border-bottom': index !== rows.length - 1
                            })}
                        >
                            <span className="text-default-light">{item.name}</span>
                            <span>
                                {Array.isArray(item.value) ? (
                                    <>
                                        <span className="color--green-haze">{item.value[0]}</span>/{item.value[1]}
                                    </>
                                ) : (
                                    item.value
                                )}
                            </span>
                        </div>
                    );
                })}
            </div>

            {isFetching && <Loader isFetching />}
        </Section>
    );
};

export default CurrentPlan;
