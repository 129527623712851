import React from 'react';
import { useHistory } from 'react-router-dom';
import { RefreshCw, Settings } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../Langs';
import { USER_ROLES } from '../../../../Constants/index';
import { showModal } from '../../../../redux/operations';

import useDelayedInput from '../../../../Functions/hooks/useDelayedInput';
import useReturnFocus from '../../../../Functions/hooks/useReturnFocus';

import indexApi from '../../../../redux/api/indexApi';
import { reportsApitagTypes, useChangeReportsMutation } from '../../../../redux/api/reportsApi';

import { changeMainTablePagination, changeMainTableSearch } from '../../../../redux/main-table-slice';

import { reportClickHandler } from '../../../../components/table-control-services/table-control-services';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import DateRangeInput from '../../../../Modules/Overall/Forms/Inputs/DateRangeInput';
import TextInput from '../../../../Modules/Overall/Forms/Inputs/TextInput';
import ColumnsSettings from '../../../../Modules/Settings/ColumnsSettings/ColumnsSettings';
import ContextMenu from '../../../../components/context-menu/context-menu';
import ShareButton from '../../../../components/table-control-services/buttons/share-button/share-button';
import ReportButton from '../../../../components/table-control-services/buttons/report-button/report-button';

const TableControls = ({
    chapter,
    isLoading,
    pagination,
    tableParameters,
    dateRange,
    setDateRange,
    rows,
    columns,
    activeRow,
    storage,
    reports,
    generalSettings,
    userSettings,
    contextMenuRef,
    contextMenuSettings
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();
    const history = useHistory();

    const contentSwitcher = chapter;

    const [searchText, setSearchText, searchInputRef] = useDelayedInput(value => {
        dispatch(
            changeMainTableSearch({
                chapter: 'reports',
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter: 'reports',
                value: { ...pagination, page: 1 }
            })
        );
    }, tableParameters.search);

    const [changeReportQuery] = useChangeReportsMutation();

    useReturnFocus(isLoading, searchInputRef);

    const onReportClick = () =>
        reportClickHandler(chapter, false, {
            generalSettings,
            dateRange,
            reports,
            rows,
            activeRows: activeRow ? [activeRow?.value] : [],
            checkedRows: [],
            activeRow,
            rowsForReport: [],
            history,
            changeReport: changeReportQuery
        });

    const onShowSettingsClick = () => {
        showModal({
            title: txt.labels.columnsSettings,
            headerType: 'primary',
            body: prop => (
                <ColumnsSettings
                    {...prop}
                    chapter={chapter}
                    columns={columns}
                    isColoredRows={storage.common.isColoredRows}
                    isVerticalBorders={storage.common.isVerticalBorders}
                    storage={storage}
                />
            )
        });
    };

    return (
        <>
            <TableControlContainer>
                <ReportButton
                    chapter={chapter}
                    className="plr30 mr8"
                    isDisabled={isLoading || !activeRow}
                    onClick={onReportClick}
                    type="filled"
                />

                {(userSettings.role === USER_ROLES.OWNER || userSettings.isAllowSharingReport) && (
                    <ShareButton
                        chapter={chapter}
                        storage={storage}
                        dateRange={dateRange}
                        isDisabled={isLoading || !rows.length}
                    />
                )}

                <TextInput
                    dataTest="table-control-header_error-log-search-input"
                    placeholder={txt.placeholders.search}
                    className="mr10"
                    ref={searchInputRef}
                    value={searchText}
                    onChange={setSearchText}
                    disabled={isLoading}
                    reset
                />

                <DateRangeInput value={dateRange} onChange={data => setDateRange(data)} disabled={isLoading} />

                <Btn
                    dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                    className="ml10 table-control_export"
                    type="filled"
                    disabled={isLoading}
                    onClick={() =>
                        dispatch(
                            indexApi.util.invalidateTags([{ type: reportsApitagTypes.reports, id: 'CHAPTER_REPORT' }])
                        )
                    }
                >
                    <RefreshCw />
                    {txt.buttons.refresh}
                </Btn>

                <div className="table-control-container__pagination">
                    <TablePagination
                        value={pagination}
                        onChange={data => {
                            dispatch(
                                changeMainTablePagination({
                                    chapter: 'reports',
                                    value: data
                                })
                            );
                        }}
                        disabled={isLoading}
                    />

                    <Btn
                        dataTest={`table-control_pagination-settings-btn_${contentSwitcher}`}
                        type="icon"
                        onClick={onShowSettingsClick}
                        disabled={isLoading}
                    >
                        <Settings />
                    </Btn>
                </div>
            </TableControlContainer>

            <ContextMenu contextMenuRef={contextMenuRef} contextMenuSettings={contextMenuSettings}>
                <ReportButton
                    chapter={chapter}
                    isDisabled={isLoading || !activeRow}
                    onClick={onReportClick}
                    type="link"
                />
            </ContextMenu>
        </>
    );
};

export default TableControls;
