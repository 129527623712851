import React from 'react';
// import { RefreshCw } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';
import { USER_ROLES, PRIVATE_ACTIONS } from '../../../../../Constants/index';
import { showModal } from '../../../../../redux/operations';

import { changeMainTablePagination } from '../../../../../redux/main-table-slice';

// import indexApi from '../../../../../redux/api/indexApi';

import showItemsModal from '../../../../Items/showItemsModal';
import TableControlContainer from '../../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Overall/UI/Btn/Btn';
import TablePagination from '../../../../Overall/UI/TablePagination/TablePagination';
import PrivateElement from '../../../../PrivateElement/PrivateElement';
import ConfirmDeleteModal from '../../../../Modals/confirm-delete-modal/confirm-delete-modal';

const TableControls = ({ chapter, isLoading, activeRow, pagination, deleteUser }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const onDeleteUserClick = () => {
        showModal({
            title: 'Delete user?',
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(activeRow.name, true)}
                    entity={'user'}
                    onConfirm={() => deleteUser({ id: activeRow.value })}
                    autoClose={true}
                />
            )
        });
    };

    return (
        <PrivateElement forbiddenRoles={USER_ROLES.USER} action={PRIVATE_ACTIONS.HIDE}>
            <TableControlContainer>
                <Btn
                    dataTest="table-control_invite-btn_users"
                    type="filled"
                    disabled={isLoading}
                    onClick={() => showItemsModal('inviteUser', null, null, null, null, dispatch)}
                >
                    {txt.buttons.inviteUser}
                </Btn>

                <div className="table-control-container__links">
                    <Btn
                        dataTest="table-control_edit-btn_users"
                        type="link"
                        disabled={isLoading || !activeRow || activeRow?.role === USER_ROLES.OWNER}
                        onClick={() => showItemsModal('editUser', activeRow.value, null, null, null, dispatch)}
                    >
                        {txt.buttons.edit}
                    </Btn>

                    <Btn
                        dataTest="table-control_delete-btn_users"
                        type="link"
                        disabled={isLoading || !activeRow || activeRow?.role === USER_ROLES.OWNER}
                        onClick={onDeleteUserClick}
                    >
                        {txt.buttons.delete}
                    </Btn>
                </div>

                {/* <Btn
                    dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                    type="filled"
                    disabled={isLoading}
                    className="ml8"
                    onClick={() =>
                        dispatch(indexApi.util.invalidateTags([{ type: workspacesApiTagTypes.workspaces, id: 'LIST' }]))
                    }
                >
                    <RefreshCw />
                    {txt.buttons.refresh}
                </Btn> */}

                <div className="table-control-container__pagination">
                    <TablePagination
                        value={pagination}
                        onChange={data => {
                            dispatch(
                                changeMainTablePagination({
                                    chapter,
                                    value: data
                                })
                            );
                        }}
                        disabled={isLoading}
                    />
                </div>
            </TableControlContainer>
        </PrivateElement>
    );
};

export default TableControls;
