import { useEffect, useRef, useCallback } from 'react';

const useClickOutside = (hideComponent, additionalComponent) => {
    const ref = useRef(null);

    const checkCondition = target =>
        additionalComponent
            ? !ref.current?.contains(target) && !additionalComponent?.contains(target)
            : !ref.current?.contains(target);

    const handleClickOutside = useCallback(
        ({ target }) => {
            if (checkCondition(target)) hideComponent();
        },
        [hideComponent]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);

    return ref;
};

export default useClickOutside;
