import indexApi from './indexApi';
import { transform } from '../transformers';

// const tagTypes = {
//     systemData: 'systemData'
// };

// .enhanceEndpoints({ addTagTypes: Object.values(tagTypes) })

export const systemApi = indexApi.injectEndpoints({
    endpoints: builder => ({
        getCountries: builder.query({
            query: () => `/countries`,
            keepUnusedDataFor: 82800 //23h
        }),

        getCurrencies: builder.query({
            query: () => `/currencies`,
            keepUnusedDataFor: 82800 //23h
        }),

        getDefaultCurrency: builder.query({
            query: () => `/currencies/default-settings`,
            keepUnusedDataFor: 82800 //23h
        }),

        getTimezones: builder.query({
            query: () => `/timezones`,
            keepUnusedDataFor: 82800, //23h
            transformResponse: response => transform.timeZoneTransformer.import(response)
        }),

        getLanguages: builder.query({
            query: () => `/languages`,
            keepUnusedDataFor: 82800 //23h
        })
    })
});

export const {
    useGetCountriesQuery,
    useGetCurrenciesQuery,
    useGetDefaultCurrencyQuery,
    useGetTimezonesQuery,
    useGetLanguagesQuery
} = systemApi;
