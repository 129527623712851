const pathTemplate = {
    name: 'New path',
    isReferHide: false,
    isEnabled: true,
    hideReferer: 'meta_refresh',
    weight: 100,
    isOptimizationWeights: false,
    weightsOptimization: 'offers',
    weightsOptimizationMethod: 'auto',
    landers: [],
    offers: []
};

export default pathTemplate;
