import indexApi from './indexApi';
import { transform } from '../transformers';

export const reportsApitagTypes = {
    reports: 'reports',
    entityAllNames: 'entityAllNames'
};

export const reportsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(reportsApitagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getReports: builder.query({
                query: () => `/store/reports`,
                transformResponse: response => response ?? [],
                providesTags: () => [
                    { type: reportsApitagTypes.reports, id: 'LIST' },
                    { type: reportsApitagTypes.reports, id: 'ALL' }
                ]
            }),

            getReport: builder.query({
                query: params => `/reports${params || ''}`,
                transformResponse: response => ({
                    items: response.items.map(el => transform.reportStatistics.import(el)),
                    total: response.total,
                    pagination: transform.pagination.import(response.pagination)
                }),

                providesTags: () => [
                    { type: reportsApitagTypes.reports, id: 'REPORT' },
                    { type: reportsApitagTypes.reports, id: 'ALL' }
                ]
            }),

            getChapterReport: builder.query({
                query: params => `/reports${params || ''}`,
                keepUnusedDataFor: 0,
                transformResponse: response => ({
                    items: response.items.map(el => transform.reportStatistics.import(el)),
                    total: response.total,
                    pagination: response.pagination
                }),

                providesTags: () => [
                    { type: reportsApitagTypes.reports, id: 'CHAPTER_REPORT' },
                    { type: reportsApitagTypes.reports, id: 'ALL' }
                ]
            }),

            getReportChart: builder.query({
                query: params => ({
                    url: `/reports/chart${params}`,
                    method: 'POST'
                }),
                providesTags: () => [
                    { type: reportsApitagTypes.reports, id: 'REPORT_CHART' },
                    { type: reportsApitagTypes.reports, id: 'ALL' }
                ]
            }),

            getEntityAllNamesForReport: builder.query({
                query: entity => `/${entity}/all/names`,
                transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
                // FIXME: удалить после добаления инвалидации ко всем сущностям
                keepUnusedDataFor: 0,
                providesTags: (result, error, entity) => [
                    { type: reportsApitagTypes.entityAllNames, id: entity },
                    { type: reportsApitagTypes.entityAllNames, id: 'ALL' }
                ]
            }),

            changeReports: builder.mutation({
                query: reports => ({
                    url: '/store/reports',
                    method: 'POST',
                    body: reports.filter(el => el.bookmark)
                }),

                async onQueryStarted(reports, { dispatch, queryFulfilled }) {
                    const patchResult = dispatch(
                        indexApi.util.updateQueryData('getReports', undefined, () => reports.filter(el => el.bookmark))
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();

                        /**
                         * Alternatively, on failure you can invalidate the corresponding cache tags
                         * to trigger a re-fetch:
                         * dispatch(api.util.invalidateTags(['Post']))
                         */
                    }
                }
            })
        })
    });

export const {
    useGetReportsQuery,
    useGetReportQuery,
    useGetChapterReportQuery,
    useGetReportChartQuery,
    useGetEntityAllNamesForReportQuery,
    useChangeReportsMutation
} = reportsApi;
