import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';
import { INTERVALS, INTERVALS_WITH_FUTURE, CUSTOM_INTERVAL_KEY } from '../../../../Constants';
import { Dispatch } from '../../../../redux/rsd';
import { getActualDateRange, setState, checkDateRangeLimitsAndRewrite } from '../../../../redux/operations';

import useClickOutside from '../../../../Functions/hooks/useClickOutside';
import useToggle from '../../../../Functions/hooks/useToggle';

import { useGetProjectCurrentPlanQuery } from '../../../../redux/api/projectApi';

import Datepicker from '../../UI/Datepicker/Datepicker';
import Btn from '../../UI/Btn/Btn';
import Icon from '../../Icon';
import Tooltip from '../../UI/Tooltip/Tooltip';

export const SelectItem = ({ label, isActive, onItemClick, onMouseEnter, disabled }) => (
    <div
        className={cn(
            'form-select-options__item',
            isActive && 'form-select-options__item--active',
            disabled && 'form-select-options__item--disabled'
        )}
        onClick={onItemClick}
        onMouseEnter={onMouseEnter}
    >
        {label}
    </div>
);

const OpenedPart = props => {
    const txt = Langs[global.lng];
    const {
        actualValues,
        onChange = () => {},
        isHoverable,
        short,
        isCompact = false,
        isIntervalsHidden = false,
        isCompareHidden = false,
        limitRange,
        isPositionReverse,
        current,
        setCurrent,
        isCompare,
        setIsCompare,
        toggleOff,
        monthDataStorage,
        isFutureSelect,
        availableInterval,
        isAllowFuture
    } = props;

    const formSelectInnerRef = useRef(null);
    const [isFormSelectInnerReverse, setIsFormSelectInnerReverse] = useState(isPositionReverse);

    const items = isCompact ? [...Object.keys(INTERVALS)] : [...Object.keys(INTERVALS), CUSTOM_INTERVAL_KEY];

    useLayoutEffect(() => {
        setIsFormSelectInnerReverse(window.innerWidth < formSelectInnerRef.current?.getBoundingClientRect()?.right);
    }, []);

    const handleItemClick = item => {
        const intervals = isAllowFuture ? INTERVALS_WITH_FUTURE : INTERVALS;

        let inputStartDate = intervals[item].start();
        let inputEndDate = intervals[item].end();

        if (availableInterval) {
            let isRewriteAvailable = false;

            if (moment(inputStartDate).isBefore(moment(availableInterval.start), 'days')) {
                inputStartDate = moment(availableInterval.start);
                isRewriteAvailable = true;
            }

            if (
                moment(inputEndDate).isAfter(moment(availableInterval.end), 'days') ||
                moment(inputEndDate).isBefore(moment(availableInterval.end), 'days')
            ) {
                if (moment(availableInterval.end).isAfter(moment(), 'days')) {
                    inputEndDate = moment().add(1, 'day').startOf('day');
                } else {
                    inputEndDate = moment(availableInterval.end);
                }

                isRewriteAvailable = true;
            }

            onChange({
                intervalKey: isRewriteAvailable ? CUSTOM_INTERVAL_KEY : item,
                start: inputStartDate.format(),
                end: inputEndDate.format(),
                compare: isCompare
            });

            setTimeout(() => toggleOff(), 0);

            return;
        }

        if (item !== CUSTOM_INTERVAL_KEY) {
            checkDateRangeLimitsAndRewrite(
                monthDataStorage,
                inputStartDate,
                inputEndDate,
                (isRewrited, startDate, endDate) => {
                    onChange({
                        intervalKey: isRewrited ? CUSTOM_INTERVAL_KEY : item,
                        start: isRewrited ? startDate.format() : inputStartDate.format(),
                        end: isRewrited ? endDate.format() : inputEndDate.format(),
                        compare: isCompare
                    });

                    if (isRewrited) {
                        setCurrent(CUSTOM_INTERVAL_KEY);
                        toast.warning(txt.toasts.dateRangeLimit);
                    } else setCurrent(item);
                }
            );

            setTimeout(() => toggleOff(), 0);
        }
    };

    const getSelectedMode = (newValues, options) => {
        const { start, end } = newValues;

        if (moment(start).get('hour') !== 0 || moment(end).get('hour') !== 0) {
            return CUSTOM_INTERVAL_KEY;
        }

        for (const [key, interval] of Object.entries(options?.isAllowFuture ? INTERVALS_WITH_FUTURE : INTERVALS)) {
            if (moment(start).isSame(interval.start()) && moment(end).isSame(interval.end())) {
                return key;
            }
        }

        return CUSTOM_INTERVAL_KEY;
    };

    return (
        <div
            className={cn('form-select-inner', {
                'form-select-inner--position-reverse': isPositionReverse || isFormSelectInnerReverse
            })}
            ref={formSelectInnerRef}
        >
            {!isIntervalsHidden && (
                <div
                    className={cn(
                        'form-select-options',
                        { 'form-select-options--short': short },
                        { 'form-select-options--hoverable': isHoverable }
                    )}
                >
                    {items.map((item, index) => (
                        <SelectItem
                            key={index}
                            label={txt.labels[item] || item}
                            isActive={current === item}
                            onItemClick={() => handleItemClick(item.value || item)}
                            disabled={item === CUSTOM_INTERVAL_KEY}
                        />
                    ))}
                </div>
            )}
            {!isCompact && (
                <Datepicker
                    values={actualValues}
                    onChange={data => {
                        onChange({
                            intervalKey: current,
                            ...data
                        });
                        toggleOff();
                    }}
                    getSelectedMode={getSelectedMode}
                    onChangeInterval={val => setCurrent(val)}
                    onChangeCompare={data => setIsCompare(data)}
                    onCancel={() => {
                        toggleOff();
                        setIsCompare(actualValues?.compare);
                    }}
                    isCompareHidden={isCompareHidden}
                    limitRange={limitRange}
                    monthDataStorage={monthDataStorage}
                    isFutureSelect={isFutureSelect}
                    availableInterval={availableInterval}
                    isAllowFuture={isAllowFuture}
                />
            )}
        </div>
    );
};

const DateRangeInput = props => {
    const txt = Langs[global.lng];
    const {
        absTooltip,
        id,
        className,
        containerClassName,
        value,
        onChange = () => {},
        error,
        disabled,
        isHoverable,
        // eslint-disable-next-line no-unused-vars
        short,
        isCompact = false,
        isFocused = false,
        isIntervalsHidden = false,
        isCompareHidden = false,
        hideArrows = false,
        // eslint-disable-next-line no-unused-vars
        limitRange,
        // eslint-disable-next-line no-unused-vars
        isPositionReverse,
        isUseAbs,
        isFullRange,
        isReadOnly,
        availableInterval,
        isAllowFuture,
        isSharedReportPage
    } = props;

    const actualValues = getActualDateRange(value ?? {}, { isAllowFuture });
    const { isToggled, toggle, toggleOn, toggleOff } = useToggle();
    const [current, setCurrent] = useState(actualValues?.intervalKey);
    const [isCompare, setIsCompare] = useState(actualValues?.compare);

    const { data: currentPlan } = useGetProjectCurrentPlanQuery(undefined, { skip: isSharedReportPage });

    const range = useMemo(() => {
        const { start, end } = actualValues;
        const startTime = moment(start).format('HH:mm');
        const endTime = moment(end).format('HH:mm');
        const firstPart = `${moment(start).format('ll')} ${startTime !== '00:00' ? `(${startTime})` : ''}`;
        const secondPart = `${(endTime !== '00:00' ? moment(end) : moment(end).subtract(1, 'days')).format('ll')} ${
            endTime !== '00:00' ? `(${endTime})` : ''
        }`;

        if (isFullRange) {
            return `${moment(start).format('ll')} (${startTime}) - ${(endTime !== '00:00'
                ? moment(end)
                : moment(end).subtract(1, 'days')
            ).format('ll')} (${endTime === '00:00' && moment(end).isAfter(moment(start), 'day') ? '24:00' : endTime})`;
        }

        return `${firstPart} ${
            endTime === '00:00' && moment(end).isSame(moment(start).add(1, 'days'), 'day') ? '' : ` - ${secondPart}`
        }`;
    }, [actualValues]);

    const slideInterval = (direction = 'forward') => {
        const { start, end } = actualValues;
        const intervalHoursCount = moment(end).diff(start, 'hours');
        let newEnd, newStart;
        const endHours = moment(end).hours();
        const startHours = moment(start).hours();
        if (direction === 'forward') {
            newStart = moment(end)
                .add(endHours === 0 ? 0 : 1, 'day')
                .hours(startHours);
            newEnd = moment(newStart).add(intervalHoursCount, 'hours');
        } else {
            newEnd = moment(start)
                .subtract(endHours === 0 ? 0 : 1, 'day')
                .hours(endHours);
            newStart = moment(newEnd).subtract(intervalHoursCount, 'hours');
        }
        const startPrevDate = moment(newStart).subtract(intervalHoursCount, 'hours').subtract(1, 'seconds');
        const endPrevDate = moment(newEnd).subtract(1, 'seconds');
        onChange({
            ...value,
            intervalKey: getActualDateRange({
                start: newStart.format(),
                end: newEnd.format()
            })?.intervalKey,
            start: newStart.format(),
            end: newEnd.format(),
            startPrevDate: startPrevDate.format(),
            endPrevDate: endPrevDate.format()
        });
    };

    const ref = !isUseAbs ? useClickOutside(toggleOff) : useRef(null);
    const internalContainerRef = useRef(null);
    const isEndDateNearFuture = moment(actualValues?.end).isSameOrAfter(moment());

    const absData = useMemo(
        () => ({
            body: OpenedPart,
            props: {
                ...props,
                key: `${actualValues.start}-${actualValues.end}-${actualValues.intervalKey}-${current}-${isCompare}`,
                actualValues,
                current,
                setCurrent,
                isCompare,
                setIsCompare,
                toggleOff,
                onChange,
                monthDataStorage: currentPlan?.monthDataStorage
            },
            clickOutsideCallback: () => toggleOff(),
            top: internalContainerRef.current?.getBoundingClientRect()?.bottom || 0,
            left: internalContainerRef.current?.getBoundingClientRect()?.left || 0
        }),
        [isToggled, value, actualValues, current, isCompare]
    );

    useEffect(() => {
        if (current && isUseAbs && absTooltip) setState(current, 'absTooltip/props/current');
    }, [current]);

    useEffect(() => {
        if (isUseAbs) setState(isToggled ? absData : null, 'absTooltip');
    }, [isToggled]);

    const getPreviousPeriodMessage = ({ startPrevDate, endPrevDate }) => {
        if (startPrevDate && endPrevDate) {
            const previousPeriodText = `${moment(startPrevDate).format('ll')} (${moment(startPrevDate).format(
                'HH:mm'
            )}) - ${moment(endPrevDate).format('ll')} (${moment(endPrevDate).format('HH:mm')})`;

            return (
                <>
                    <span className="color--grey-gull">{txt.labels.previousPeriod}: </span>
                    {previousPeriodText}
                </>
            );
        }
    };

    return (
        <div
            ref={internalContainerRef}
            className={cn('date-range-input form-label', containerClassName, {
                'date-range-input--compact': isCompact,
                'date-range-input--focused': isFocused
            })}
        >
            {!isCompact && !hideArrows && (
                <>
                    <Btn
                        icon
                        type="white"
                        className={cn({ 'btn--white-active': isToggled, 'btn--white-disabled': disabled })}
                        onClick={() => slideInterval('back')}
                    >
                        <Icon.ArrowLeft />
                    </Btn>
                    <Btn
                        icon
                        type="white"
                        className={cn({ 'btn--white-active': isToggled, 'btn--white-disabled': disabled })}
                        onClick={() => slideInterval('forward')}
                        disabled={isEndDateNearFuture}
                    >
                        <Icon.ArrowRight />
                    </Btn>
                </>
            )}
            <div
                className="select-form"
                ref={ref}
                onMouseEnter={isHoverable && toggleOn}
                onMouseLeave={isHoverable && toggleOff}
            >
                {!isCompact && !isCompareHidden && (
                    <Tooltip
                        message={getPreviousPeriodMessage(actualValues)}
                        className={cn('tooltip--light date-range-input__compare-tooltip', {
                            'date-range-input__compare-tooltip--off': !actualValues?.compare,
                            'date-range-input__compare-tooltip--on': actualValues?.compare
                        })}
                    >
                        <Icon.Compare
                            onClick={() => {
                                if (disabled) return;
                                onChange({
                                    ...actualValues,
                                    compare: !actualValues?.compare
                                });
                            }}
                        />
                    </Tooltip>
                )}
                <button
                    data-test="date-range-input_range-select-input"
                    id={id}
                    className={cn(
                        'form-input form-select__input',
                        { 'form-select__input--disabled': disabled },
                        { 'form-select__input--opened': isToggled },
                        { 'form-select__input--focused': isToggled },
                        { 'form-select__input--readonly': isReadOnly },
                        className
                    )}
                    disabled={disabled}
                    onClick={!disabled && !isReadOnly && !isHoverable ? toggle : undefined}
                >
                    {isCompact ? (
                        txt.labels[actualValues?.intervalKey] || actualValues?.intervalKey
                    ) : (
                        <>
                            {!isIntervalsHidden && (
                                <span
                                    className={cn('date-range-input__interval-label', {
                                        'date-range-input__interval-label--compare-shown': !isCompareHidden
                                    })}
                                >
                                    {txt.labels[actualValues?.intervalKey] || actualValues?.intervalKey}:
                                </span>
                            )}

                            {range}
                        </>
                    )}
                </button>
                {isToggled && !isUseAbs && (
                    <OpenedPart
                        {...props}
                        actualValues={actualValues}
                        current={current}
                        setCurrent={setCurrent}
                        isCompare={isCompare}
                        setIsCompare={setIsCompare}
                        toggleOff={toggleOff}
                        onChange={onChange}
                        availableInterval={availableInterval}
                        isAllowFuture={isAllowFuture}
                        monthDataStorage={currentPlan?.monthDataStorage}
                    />
                )}
            </div>
            {error && <div className="form-label--error">{error}</div>}
        </div>
    );
};

const Store = ({ oldState: { absTooltip } }) => ({ absTooltip });

export default connect(Store, Dispatch)(React.memo(DateRangeInput));
