import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';

import Langs from '../../../Langs';
import { getActualDateRange } from '../../../redux/operations';
import { getParams } from '../../../Functions/utils';
import { useGetDashboardEntityDataQuery } from '../../../redux/api/dashboardApi';
import usePagination from '../../../Functions/hooks/usePagination';

import Icon from '../../Overall/Icon';
import Btn from '../../Overall/UI/Btn/Btn';
import Loader from '../../Loader';

import './dashboard-table.scss';

const TextPagination = React.memo(({ value, onChange }) => {
    const txt = Langs[global.lng];
    const { page = 1, 'per-page': perPage = 10, pages = 1, total = 10 } = value || {};

    const handleChange = (key, val) => {
        onChange({ ...value, [key]: val });
    };

    const toggleDec = () => {
        if (+page > 1) handleChange('page', +page - 1);
    };

    const toggleInc = () => {
        if (+page < pages) handleChange('page', +page + 1);
    };

    return (
        <div className="text-pagination">
            <Btn
                dataTest="dashboard-table_chevron-left-btn"
                disabled={page === 1}
                icon="icon"
                type="pure"
                className="mr5"
                onClick={() => toggleDec()}
            >
                <Icon.ChevronLeft size={16} />
            </Btn>
            <span>
                {(page - 1) * perPage + 1} - {page === pages ? total : (page - 1) * perPage + perPage} {txt.labels.from}{' '}
                {total}
            </span>
            <Btn
                dataTest="dashboard-table_chevron-right-btn"
                disabled={page === pages}
                icon="icon"
                type="pure"
                className="mr5"
                onClick={() => toggleInc()}
            >
                <Icon.ChevronRight size={16} disabled />
            </Btn>
        </div>
    );
});

const DashboardTable = React.memo(
    ({
        chapter,
        dashboardSorting,
        dashboardGrouping,
        dateRange,
        isFetching,
        rounding,
        isStartRefresh,
        onRefreshed
    }) => {
        const txt = Langs[global.lng];

        const { pagination, setCustomPagination } = usePagination();

        const actualDateRange = getActualDateRange(dateRange);
        const parameters = {
            statistic: dashboardGrouping,
            'filter[dateFrom]': moment(actualDateRange.start).format('YYYY-MM-DDTHH:mm'),
            'filter[dateTo]': moment(actualDateRange.end).format('YYYY-MM-DDTHH:mm'),
            sort: dashboardSorting?.toLowerCase(),
            page: pagination.page,
            pages: pagination.pages,
            'per-page': pagination.perPage
        };

        const queryParameters = getParams(parameters);

        const {
            data,
            isLoading: isDataLoading,
            isFetching: isDataFetching,
            refetch
        } = useGetDashboardEntityDataQuery({
            entity: chapter,
            params: queryParameters
        });

        const isLoading = isDataLoading || isFetching || isDataFetching;

        useEffect(() => {
            if (isStartRefresh) {
                refetch();
                onRefreshed();
            }
        }, [isStartRefresh]);

        useEffect(() => {
            if (!isLoading) setCustomPagination({ ...data?.pagination, perPage: data?.pagination?.['per-page'] });
        }, [data?.pagination]);

        const onPaginationChange = useCallback(newPagination => setCustomPagination(newPagination));

        const { items } = data ?? {};

        const renderRows = () => {
            if (items?.length === 0)
                return (
                    <div className="dashboard-table-rows__row dashboard-table-rows__row--disabled">
                        {txt.labels.noData}
                    </div>
                );
            return items?.map((row, i) => (
                <div key={`${chapter}-${row.name}-${i}`} className="dashboard-table-rows__row">
                    <div className="dashboard-table-rows__label">{row.name}</div>
                    <div
                        className={cn('dashboard-table-rows__value', {
                            'dashboard-table-rows__value--positive':
                                ['revenue', 'profit', 'roi'].includes(dashboardGrouping) && row?.sum > 0,
                            'dashboard-table-rows__value--negative': row?.sum < 0,
                            'dashboard-table-rows__value--zero': row?.sum === 0
                        })}
                    >
                        {row.sum < 0 ? '-' : ''}
                        {row.sum && ['profit'].includes(dashboardGrouping) ? '$' : null}
                        {Math.abs(row.sum)?.toLocaleString('en-EN', {
                            minimumFractionDigits: rounding[dashboardGrouping] || 0,
                            maximumFractionDigits: rounding[dashboardGrouping] || 0
                        })}
                    </div>
                </div>
            ));
        };

        return (
            <div className="dashboard-table">
                <div className="dashboard-table__header">
                    <div>{txt.labels[chapter] || chapter}</div>
                    <div className="dashboard-table__stats-by">
                        {txt.labels[dashboardGrouping] || dashboardGrouping}
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="dashboard-table__body">{renderRows()}</div>
                        <div className="dashboard-table__footer">
                            {items?.length > 0 && <TextPagination value={pagination} onChange={onPaginationChange} />}
                        </div>
                    </>
                )}
            </div>
        );
    }
);

export default DashboardTable;
