import React, { forwardRef } from 'react';

const Switch = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            width={size}
            height={size}
            fill="none"
            stroke={color}
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        >
            <path
                d="M11.138 2.4714L13.9665 5.29983M13.9665 5.29983L11.138 8.12826M13.9665 5.29983L7.36679 5.29983"
                strokeWidth="1.07"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.86191 13.1283L2.03348 10.2998M2.03348 10.2998L4.86191 7.4714M2.03348 10.2998L8.63314 10.2998"
                strokeWidth="1.07"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});

Switch.displayName = 'Switch';

export default Switch;
