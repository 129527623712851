import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';

import Langs from '../../../../Langs';
import { KEY_CODE } from '../../../../Constants';
import { tryRegExp } from '../../../../Functions/utils';

import useClickOutside from '../../../../Functions/hooks/useClickOutside';
import useSearch from '../../../../Functions/hooks/useNewSearch';
import useToggle from '../../../../Functions/hooks/useToggle';
import useFocus from '../../../../Functions/hooks/useFocus';

import HintIcon from './HintIcon';
import SelectItem from './SelectInput/SelectItem';
import Btn from '../../UI/Btn/Btn';
import TextInput from './TextInput';

export const Tag = ({ label, value, onClick, onDeleteItem, type, className, error }) => {
    const txt = Langs[global.lng];
    if (!label && !value) return null;
    return (
        <div
            className={cn('form-tag', className, {
                'form-tag--error': error,
                [`form-tag--${type}`]: type,
                'form-tag--pointer': onClick
            })}
            onClick={onClick}
        >
            <p className={cn('form-tag__label', { 'form-tag__label--lowercase': !value })}>
                {type === 'light' ? txt.singulars[label] || txt.labels[label] || label : label}
                {type === 'light' && value && ':'}
            </p>
            {type === 'light' && <p className="form-tag__value">{value}</p>}
            {onDeleteItem && <Icon.X size={16} className="form-tag__close-icon" onClick={onDeleteItem} />}
        </div>
    );
};

const TagInput = ({
    id,
    value = [],
    onChange = () => {},
    items,
    label,
    placeholder,
    hint,
    hintType,
    className,
    isDropdownHidden,
    isListView,
    dataTest,
    error,
    validation
}) => {
    const isObjectUsed = !!(items && typeof items[0] === 'object' && items[0] !== null);
    const txt = Langs[global.lng];
    const inputRef = useRef();
    const [textareaRef, setTextareaFocus] = useFocus();
    const tags = items || [];
    const { isToggled: isOpened, toggleOn: setIsOpened, toggleOff: unsetIsOpened } = useToggle();
    const { filteredItems, setSearchByForInput, searchByValue, clearSearchBy } = useSearch(tags, el =>
        isObjectUsed ? el.label : el
    );
    const [editValue, setEditValue] = useState(value.join(', '));
    const baseDataTest = `tag-input_${dataTest}_${label?.replace(/\s+/g, '-').toLowerCase()}`;

    const getIsValid = (item, validationString) =>
        validationString && tryRegExp(validationString) ? tryRegExp(validationString).test(item) : true;

    const isItemsValid = value.reduce((ac, item) => ac && getIsValid(item, validation), true);

    useEffect(() => {
        setEditValue(value.join(', '));
    }, [value]);

    const isItemExist = useCallback(
        (item, values) => values?.some(data => data === (isObjectUsed ? item.value : item)),
        []
    );

    const handleDeleteValue = useCallback(
        item => {
            onChange(value?.filter(valueItem => valueItem !== item));
        },
        [value]
    );

    const handleItemClick = item => {
        if (!isItemExist(item, value)) {
            onChange([...value, item.value || item]);
        } else {
            handleDeleteValue(item.value || item);
        }

        unsetIsOpened();
        inputRef.current.value = '';
        clearSearchBy();
    };

    const handleAddValue = () => {
        const searchByTrimmed = searchByValue.trim();
        if (searchByTrimmed) {
            if (!isListView && !isItemExist(searchByTrimmed, tags)) {
                if (!items) {
                    onChange([...value, searchByTrimmed]);
                }
            } else if (!isItemExist(searchByTrimmed, value)) {
                onChange([...value, searchByTrimmed]);
            }

            inputRef.current.value = '';
            clearSearchBy();
        }
    };

    const onKeyUp = useCallback(
        ({ keyCode }) => {
            if (keyCode === KEY_CODE.ENTER) {
                handleAddValue();
            }
        },
        [searchByValue, tags, value]
    );

    const onBlur = () => {
        handleAddValue();
    };

    const handleListEdit = () => {
        setEditValue(value.join(', '));
        setIsOpened();
        setTimeout(setTextareaFocus, 0);
    };

    const handleListConfirm = useCallback(() => {
        const newValue = editValue?.split(/[,\n]/)?.reduce((res, item) => {
            if (item) res.push(item.trim());
            return res;
        }, []);
        const uniqueValue = [...new Set(newValue)];
        onChange(uniqueValue);
        setEditValue(uniqueValue.join(', '));
        unsetIsOpened();
    }, [editValue]);

    const clearValue = useCallback(() => {
        onChange([]);
        setEditValue('');
    }, []);

    return (
        <div className={cn('form-label', className)}>
            <div className="j4">
                <div className="mr5">{label}</div>
                {hint && <HintIcon hint={txt.hints[hint] || hint} type={hintType} />}
                {isListView && (
                    <div className="form-label__button-group">
                        {isOpened ? (
                            <>
                                <Btn dataTest={`${baseDataTest}_close-btn`} type="link" onClick={unsetIsOpened}>
                                    {txt.buttons.close}
                                </Btn>
                                <Btn dataTest={`${baseDataTest}_apply-btn`} type="filled" onClick={handleListConfirm}>
                                    {txt.buttons.apply}
                                </Btn>
                            </>
                        ) : (
                            <Btn dataTest={`${baseDataTest}_list-view-btn`} type="link" onClick={handleListEdit}>
                                {txt.buttons.listView}
                            </Btn>
                        )}
                        <Btn dataTest={`${baseDataTest}_clear-btn`} type="link" onClick={clearValue}>
                            {txt.buttons.clear}
                        </Btn>
                    </div>
                )}
            </div>
            <div
                ref={!isListView ? useClickOutside(unsetIsOpened) : null}
                className={cn('form-tag-input', {
                    'form-tag-input--error': error || !isItemsValid
                })}
            >
                {isOpened && isListView ? (
                    <TextInput
                        dataTest={`${baseDataTest}_textarea`}
                        ref={textareaRef}
                        type="textarea"
                        value={editValue}
                        onChange={e => setEditValue(e.target.value)}
                        placeholder={placeholder || 'enterValue'}
                    />
                ) : (
                    <div id={id} className="form-tag-input__container">
                        {value
                            ?.map((item, idx) => {
                                const tagLabel = isObjectUsed ? items.find(el => el.value === item)?.label : item;
                                return tagLabel ? (
                                    <Tag
                                        key={`${idx}-tagId`}
                                        label={tagLabel}
                                        onDeleteItem={() => handleDeleteValue(item)}
                                        error={!getIsValid(item, validation)}
                                    />
                                ) : null;
                            })
                            .filter(el => el)}
                        <input
                            data-test={`${baseDataTest}_input`}
                            ref={inputRef}
                            className="form-tag-input__input"
                            placeholder={
                                !placeholder && placeholder !== ''
                                    ? txt.placeholders.typeOrSelectTagsFromList
                                    : placeholder || ''
                            }
                            onChange={setSearchByForInput}
                            onBlur={onBlur}
                            value={searchByValue}
                            onClick={!isListView ? setIsOpened : null}
                            onKeyUp={onKeyUp}
                        />
                    </div>
                )}
                {isOpened && !isDropdownHidden && !isListView && !!filteredItems.length && (
                    <div className="form-select-options form-select-options--wide">
                        {filteredItems.map((item, idx) => (
                            <SelectItem
                                key={`${idx}-selectId`}
                                label={txt.labels[isObjectUsed ? item.label : item] || isObjectUsed ? item.label : item}
                                isActive={isItemExist(item, value)}
                                onItemClick={() => handleItemClick(item)}
                            />
                        ))}
                    </div>
                )}
            </div>
            {typeof error === 'string' && (!value.length || !isItemsValid) && (
                <div className={cn('form-tag-input__error', error)}>{error}</div>
            )}
        </div>
    );
};

export default TagInput;
