import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';

import Langs from '../../Langs';
import { MAIN_CHAPTERS, CHAPTER_REPORTS, DISPLAY_MODE_STATUS } from '../../Constants';
import { showModal } from '../../redux/operations';

import { useGetCurrenciesQuery } from '../../redux/api/systemApi';

import MainTableHeader from '../../Modules/MainTable/MainTableItems/MainTableHeader';
import MainTableBody from '../../Modules/MainTable/MainTableItems/MainTableBody';
import TablePreloader from '../../Modules/Overall/UI/TablePreloader/TablePreloader';
import DownloadFile, { ShowDownloadFileCheckbox } from '../../Modules/Settings/ColumnsSettings/DownloadFile';
import NoData from '../no-data/no-data';

const GeneralTable = ({
    chapter,
    tableParameters,
    dateRange,
    rows,
    activeRow,
    columns,
    columnFilters,
    columnsWidths,
    total,
    showTotalCheck,
    sorting,
    isLoading,
    isColoredRows: initialColoredRows,
    isVerticalBorders: initialVerticalBorders,
    isSharedReportPage,
    report,
    storage,
    generalSettings,
    onRowClick,
    onRowContextMenu,
    onTableActionsChange,
    updateRows
}) => {
    const txt = Langs[global.lng];
    const isVerticalBorders = initialVerticalBorders || storage?.common?.isVerticalBorders || false;
    const isColoredRows = initialColoredRows || storage?.common?.isColoredRows || false;

    // getDataForTdMoney
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery(undefined, {
        skip: isSharedReportPage
    });

    const isAllLoaded = !isLoading && (!isSharedReportPage ? isCurrenciesLoaded : true);

    const columnsWidth = (() => {
        if (columnsWidths) return columnsWidths;
        if (chapter === 'reports') return report?.columnWidths ?? [];
        if (CHAPTER_REPORTS.includes(chapter)) return storage.chapter_reports.columnWidths ?? [];
        return storage?.[chapter]?.columnWidths ?? [];
    })();

    useEffect(() => {
        if (columnsWidth?.length === 0 && isAllLoaded && rows.length > 0) {
            const resizableCells = Array.from(document.querySelectorAll('div[data-key]'));

            const allColumnsWidths = resizableCells.map(cell => ({
                key: cell.dataset.key,
                width: cell.clientWidth + (cell.dataset.key === 'important' ? 1 : 0)
            }));

            onTableActionsChange(allColumnsWidths, 'columnWidths');
        }
    }, [columnsWidth, isAllLoaded]);

    const getProfitsMinMax = (isMoreLess, isMinMax) => {
        const getAllProfits = inputRows => {
            return inputRows
                .reduce((acc, row) => {
                    const result = [];
                    result.push(row.statistic?.profit ?? row.profit);
                    return row?.children?.length
                        ? [...acc, ...result, getAllProfits(row.children)]
                        : [...acc, ...result];
                }, [])
                .flat();
        };

        const profits = getAllProfits(rows);

        const processedProfits = profits.filter(el => (isMoreLess === '>' ? el > 0 : el < 0));

        switch (isMinMax) {
            case 'max':
                return processedProfits.length > 0 ? Math.max(...processedProfits) : 0;
            case 'min':
                return processedProfits.length > 0 ? Math.min(...processedProfits) : 0;
            default:
                throw new Error('isMinMax is incorrect');
        }
    };

    const coloredConfig = {
        positiveMin: getProfitsMinMax('>', 'min'),
        positiveMax: getProfitsMinMax('>', 'max'),
        negativeMin: getProfitsMinMax('<', 'min'),
        negativeMax: getProfitsMinMax('<', 'max')
    };

    const showDownloadFile = storage?.common?.showModals?.DownloadFile && rows?.length;
    useHotkeys(
        'ctrl+c, command+c',
        () => {
            // FIXME: change logic
            const isIncludeExportBtn = document.querySelector('.table-control_export');
            const isOpenModal = document.querySelector('.modal2');

            if (showDownloadFile && isIncludeExportBtn && !isOpenModal) {
                showModal({
                    title: txt.labels.downloadFile,
                    className: 'exportFileModal',
                    HeaderRightItem: ShowDownloadFileCheckbox,
                    body: props => (
                        <DownloadFile
                            {...props}
                            chapter={chapter}
                            rows={rows}
                            columns={columns}
                            dataForRender={tableParameters}
                            dateRange={dateRange}
                        />
                    )
                });
            }
        },
        {},
        [showDownloadFile, chapter, rows, columns, tableParameters, dateRange]
    );

    // TODO: Заменить
    const onChange = useCallback(() => updateRows([...rows]), [rows]);

    return (
        <>
            <div className={cn('items-table', { 'items-table--loading': !isAllLoaded })}>
                <MainTableHeader
                    chapter={chapter}
                    cols={columns}
                    rows={rows}
                    total={total ?? {}}
                    showTotalCheck={showTotalCheck}
                    sorting={sorting}
                    setSorting={data => onTableActionsChange(data, 'sorting')}
                    columnFilters={columnFilters}
                    setColumnFilters={data => onTableActionsChange(data, 'columnFilters')}
                    columnWidths={columnsWidth}
                    setColumnWidths={data => onTableActionsChange(data, 'columnWidths')}
                    isVerticalBorders={isVerticalBorders}
                    isLoading={isLoading}
                    currencies={currencies}
                    generalSettings={generalSettings}
                    // TODO: Объединить
                    onChange={onChange}
                    setRows={updateRows}
                />
                {isAllLoaded ? (
                    <MainTableBody
                        chapter={chapter}
                        cols={columns}
                        rows={rows}
                        isVerticalBorders={isVerticalBorders}
                        activeRow={activeRow}
                        coloredConfig={isColoredRows && coloredConfig}
                        isSharedReportPage={isSharedReportPage}
                        storage={storage}
                        generalSettings={generalSettings}
                        currencies={currencies}
                        onRowClick={onRowClick}
                        onRowContextMenu={onRowContextMenu}
                        // TODO: Объединить
                        onChange={onChange}
                        setRows={updateRows}
                    />
                ) : (
                    <TablePreloader
                        columnsLength={columns.filter(el => !el.important).length + 1}
                        columnWidths={columnsWidth}
                    />
                )}
            </div>

            {!isLoading &&
                (rows?.length ? null : (
                    <NoData
                        chapter={chapter}
                        isNew={
                            MAIN_CHAPTERS.includes(chapter) &&
                            !tableParameters?.search &&
                            !tableParameters?.columnFilters.length &&
                            storage?.[chapter]?.displayMode !== DISPLAY_MODE_STATUS.ARCHIVE
                        }
                    />
                ))}
        </>
    );
};

export default GeneralTable;
