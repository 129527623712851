export default [
    { key: 'incrementId' },
    {
        key: 'eventName',
        label: 'Custom conversion event name'
    },
    {
        key: 'tags',
        label: 'Parameter values',
        type: 'badges'
    },
    {
        key: 'actions',
        type: 'actions'
    }
];
