import React, { useEffect, useCallback, useRef } from 'react';

import Langs from '../../../../Langs';
import { getUrlError } from '../../../../redux/operations';
import { checkUrlCharacters, getOnlyASCIICodeText, showByValue, clone } from '../../../../Functions/utils';

import useToken from '../../../../Functions/hooks/useToken';
import useDelayedFocus from '../../../../Functions/hooks/useDelayedFocus';

import { AccordionLink } from '../../../Overall/Accordion/Accordion';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TagInput from '../../../../components/inputs/tag-input/tag-input';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import { TdBadgesSquareInline } from '../../../Overall/ItemsTable/TdTypes';
import JustAlert, { JUST_ALERT_TYPE } from '../../../../components/just-alert/just-alert';
import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';

const LanderSettings = ({
    workspaceId,
    landerTags,
    landerName,
    landerUrl,
    countryTag,

    updateLander,
    isCreateMode,
    isNeedValidate,

    workspacesList,
    landerTokens,
    countries
}) => {
    const txt = Langs[global.lng];

    const urlInputRef = useRef();
    const [nameInputRef, setIsNameFocus] = useDelayedFocus();

    const { url, tokens, handleTokenClick, handleUrlChange } = useToken(landerUrl, clone(landerTokens), urlInputRef);

    useEffect(() => {
        if (isCreateMode && nameInputRef.current && !landerName) {
            setTimeout(() => {
                nameInputRef.current?.focus();
            }, 0);
        }
    }, []);

    useEffect(() => updateLander({ landerUrl: url }), [url]);

    const onCountryTagChange = useCallback(({ target: { value } }) => {
        updateLander({ countryTag: value });
        setIsNameFocus(true);
    }, []);

    const onLanderNameChange = useCallback(({ target: { value } }) => {
        const transformedValue = getOnlyASCIICodeText(value?.split(' - ')[1]?.trimStart());
        updateLander({ name: transformedValue || '' });
    }, []);

    const onTagsChange = useCallback(tags => {
        updateLander({ tags });
    }, []);

    const onWorkspaceChange = useCallback(({ target: { value } }) => {
        updateLander({ workspaceId: value });
    }, []);

    return (
        <ModalContentContainer>
            <div className="create-lander__fields-row create-lander__fields-row--tag mt15">
                <SelectInput
                    dataTest="create-lander_workspace-select-input"
                    label={txt.labels.workspace}
                    hint={txt.hints.workspace(txt.labels.landingPage)}
                    value={workspaceId}
                    items={workspacesList}
                    onChange={onWorkspaceChange}
                />

                <TagInput
                    label="Select or start entering tags"
                    hint="Select or start entering tags"
                    tagList={landerTags}
                    onChange={onTagsChange}
                />
            </div>

            <div className="create-lander__fields-row mt25">
                <SelectInput
                    dataTest="create-lander_country-tag-select-input"
                    label={txt.labels.countryTag}
                    hint={txt.hints.countryTag(txt.labels.landingPages)}
                    value={countryTag}
                    items={countries}
                    onChange={onCountryTagChange}
                    isSearchable
                />

                <TextInput
                    dataTest="create-lander_lander-name-input"
                    ref={nameInputRef}
                    label={txt.labels.name}
                    value={`${showByValue(countries, countryTag)} - ${landerName}`}
                    onChange={onLanderNameChange}
                    error={isNeedValidate && !landerName?.trim() && txt.errors.required(txt.labels.name)}
                />
            </div>

            <div className="create-lander__fields-row create-lander__fields-row--grow mt25">
                <TextInput
                    dataTest="create-lander_lander-url-input"
                    label={txt.labels.landerUrl}
                    hint={txt.hints.landerUrl}
                    ref={urlInputRef}
                    error={isNeedValidate && getUrlError(url, txt.labels.landerUrl)}
                    value={url}
                    onChange={handleUrlChange}
                    checkCorrectSymbol={checkUrlCharacters}
                />
            </div>

            <AccordionLink
                className="mt25"
                label={txt.labels.availableUrlTokens}
                title={<JustAlert type={JUST_ALERT_TYPE.INFO}>{txt.hints.availableUrlTokens}</JustAlert>}
            >
                <div className="mt8">
                    <TdBadgesSquareInline items={tokens} onClick={handleTokenClick} />
                </div>
            </AccordionLink>
        </ModalContentContainer>
    );
};

export default LanderSettings;
