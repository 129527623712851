import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const campaignsApiTagTypes = {
    campaigns: 'campaigns',
    campaignAdditionalRevenue: 'campaignAdditionalRevenue',
    campaignIntegrationStatistics: 'campaignIntegrationStatistics',
    campaignBidAlert: 'campaignBidAlert'
};

const campaignsApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(campaignsApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getCampaignsList: builder.query({
            query: params => `/campaigns${params}`,
            keepUnusedDataFor: 0,
            transformResponse: response => ({
                items: response.items.map(el => transform.campaigns.importList(el)),
                total: response.total,
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: campaignsApiTagTypes.campaigns, id: 'LIST' },
                { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignById: builder.query({
            query: id => `/campaigns/${id}`,
            transformResponse: response => transform.campaigns.import(response),
            providesTags: (result, error, id) => [
                { type: campaignsApiTagTypes.campaigns, id },
                { type: campaignsApiTagTypes.campaigns, id: 'ALL_ITEMS' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignsAllNames: builder.query({
            query: () => `/campaigns/all/names`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: campaignsApiTagTypes.campaigns, id: 'ALL_NAMES_LIST' },
                { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignsWithColumns: builder.query({
            query: params => `/campaigns/all/with-columns${params}`,
            providesTags: () => [
                { type: campaignsApiTagTypes.campaigns, id: 'WITH_COLUMNS_LIST' },
                { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignsAllModal: builder.query({
            query: () => `/campaigns/all/modal`,
            transformResponse: response => response.map(el => transform.campaignsAllModal.import(el)),
            providesTags: () => [
                { type: campaignsApiTagTypes.campaigns, id: 'ALL_MODAL_LIST' },
                { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignsAdditionalRevenue: builder.query({
            query: id => `/campaigns/${id}/additional-revenue/modal`,
            transformResponse: response => response.map(el => ({ ...el, id: el.month })),
            providesTags: (result, error, id) => [
                { type: campaignsApiTagTypes.campaignAdditionalRevenue, id },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignsChart: builder.query({
            query: params => `/campaigns/all/chart${params}`,
            providesTags: () => [
                { type: campaignsApiTagTypes.campaigns, id: 'CHART' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignIntegrationStatistics: builder.query({
            query: ({ id, params }) =>
                `/campaigns/traffic-source/campaign/statistics/${id}${params ? `/${params}` : ''}`,
            keepUnusedDataFor: 0,
            providesTags: (result, error, { id }) => [
                { type: campaignsApiTagTypes.campaignIntegrationStatistics, id: 'ALL_INTEGRATION_STATISTICS' },
                { type: campaignsApiTagTypes.campaignIntegrationStatistics, id },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getCampaignBidAlert: builder.query({
            query: params => `/campaigns/integration-bid-alert/modal${params ? `${params}` : ''}`,
            keepUnusedDataFor: 0,
            providesTags: [campaignsApiTagTypes.campaignBidAlert]
        }),

        addCampaign: builder.mutation({
            query: ({ data }) => ({
                url: `/campaigns`,
                method: 'POST',
                body: transform.campaigns.export(data)
            }),
            invalidatesTags: (result, error, { data }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: 'flows', id: data.flowValue },
                        { type: 'integrations', id: 'LIST' },
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                }

                return [];
            }
        }),

        editCampaign: builder.mutation({
            query: ({ id, data }) => ({
                url: `/campaigns/${id}`,
                method: 'PUT',
                body: transform.campaigns.export(data)
            }),
            invalidatesTags: (result, error, { id, data }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: 'flows', id: data.flowValue },
                        { type: 'flows', id: data.initialFlowValue },
                        { type: 'integrations', id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert,
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                }

                return [];
            }
        }),

        deleteCampaigns: builder.mutation({
            query: ({ id }) => ({
                url: `/campaigns/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: campaignsApiTagTypes.campaigns, id: 'CHART' },
                        { type: 'integrations', id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert,
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                } else return [];
            }
        }),

        multiDeleteCampaigns: builder.mutation({
            query: ({ data }) => ({
                url: `/campaigns/delete/multiple`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: campaignsApiTagTypes.campaigns, id: 'CHART' },
                        { type: 'integrations', id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert,
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                } else return [];
            }
        }),

        restoreCampaigns: builder.mutation({
            query: ({ id }) => ({
                url: `/campaigns/${id}/restore`,
                method: 'PATCH'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: campaignsApiTagTypes.campaigns, id: 'CHART' },
                        { type: 'flows', id: 'ALL_ITEMS' },
                        campaignsApiTagTypes.campaignBidAlert,
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                } else return [];
            }
        }),

        multiRestoreCampaigns: builder.mutation({
            query: ({ data }) => ({
                url: `/campaigns/restore/multiple`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'ALL' },
                        { type: campaignsApiTagTypes.campaigns, id: 'CHART' },
                        { type: 'integrations', id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert,
                        { type: 'entityAllNames', id: 'campaigns' }
                    ];
                } else return [];
            }
        }),

        clearCampaignStatistics: builder.mutation({
            query: ({ id, params }) => ({
                url: `/campaigns/${id}/clear-statistics${params}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'LIST' }
                    ];
                } else return [];
            }
        }),

        changeCampaignsAdditionalRevenue: builder.mutation({
            query: ({ campaignId, data }) => ({
                url: `/campaigns/${campaignId}/additional-revenue/modal`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: campaignsApiTagTypes.campaignAdditionalRevenue, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'LIST' }
                    ];
                else return [];
            }
        }),

        updateCampaignCost: builder.mutation({
            query: ({ id, data }) => ({
                url: `/campaigns/update-cost/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert
                    ];

                return [];
            }
        }),

        changeCampaignCost: builder.mutation({
            query: ({ id, data }) => ({
                url: `/campaigns/update-campaign-cost/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: campaignsApiTagTypes.campaigns, id },
                        { type: campaignsApiTagTypes.campaigns, id: 'LIST' },
                        campaignsApiTagTypes.campaignBidAlert
                    ];

                return [];
            }
        }),

        changeCampaignsCostFromIntegration: builder.mutation({
            query: data => ({
                url: '/campaigns/apply-traffic-source-cost',
                method: 'PATCH',
                body: { ids: data.ids }
            }),
            invalidatesTags: (result, error, data) => {
                if (!error) return data.campaignIds.map(el => ({ type: campaignsApiTagTypes.campaigns, id: el }));
                else return [];
            },
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        indexApi.util.updateQueryData('getCampaignBidAlert', undefined, draft => {
                            return draft.filter(el => !data.ids.includes(el.id));
                        })
                    );
                    // eslint-disable-next-line no-empty
                } catch {}
            }
        }),

        resetCampaignUrlAlert: builder.mutation({
            query: ({ rowId }) => ({
                url: `/campaigns/${rowId}/reset-tracking-url-changed`,
                method: 'PATCH'
            }),
            async onQueryStarted({ rowId, campaignListRequestParams }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        indexApi.util.updateQueryData('getCampaignsList', campaignListRequestParams, draft => {
                            const element = draft.items.find(it => it.value === rowId);

                            if (element) element.isTrackingUrlChanged = false;

                            return draft;
                        })
                    );

                    // eslint-disable-next-line no-empty
                } catch {}
            }
        })
    })
});

export const {
    useGetCampaignsListQuery,
    useGetCampaignByIdQuery,
    useGetCampaignsAllNamesQuery,
    useGetCampaignsWithColumnsQuery,
    useGetCampaignsAllModalQuery,
    useGetCampaignsAdditionalRevenueQuery,
    useGetCampaignsChartQuery,
    useGetCampaignIntegrationStatisticsQuery,
    useGetCampaignBidAlertQuery,
    useAddCampaignMutation,
    useEditCampaignMutation,
    useDeleteCampaignsMutation,
    useMultiDeleteCampaignsMutation,
    useRestoreCampaignsMutation,
    useMultiRestoreCampaignsMutation,
    useClearCampaignStatisticsMutation,
    useChangeCampaignsAdditionalRevenueMutation,
    useUpdateCampaignCostMutation,
    useChangeCampaignCostMutation,
    useChangeCampaignsCostFromIntegrationMutation,
    useResetCampaignUrlAlertMutation
} = campaignsApi;
