import { v4 as uuid } from 'uuid';
import pathTemplate from './path-template';

const campaignTemplate = {
    mainOptionValues: {
        workspaceId: '',
        trackingDomain: undefined,
        links: [],
        trafficSource: undefined,
        trafficSourceCampaignId: undefined,
        country: 'GO',
        name: '',
        referrerDefaultHideType: 'without',
        costModel: 'auto',
        costPrice: null,
        percentageShare: null,
        notes: '',
        tags: []
    },

    flowOptionValues: {
        flowValue: 'custom',
        trackingMethod: {
            type: null,
            value: 'redirect'
        },
        defaultRule: {
            paths: [{ ...pathTemplate, id: uuid() }]
        },
        rules: [],
        originalSettingsAt: null
    }
};

export default campaignTemplate;
