import React from 'react';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import Langs from '../../../../../../Langs';
import { BADGE_SIZE, BADGE_TYPE, OFFER_CAP_TYPE } from '../../../../../../Constants';
import { showByValue } from '../../../../../../Functions/utils';

import Alert from '../../../../../Overall/UI/Alert/Alert';
import ActionsGroup from '../../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import { Badge, PathWeight } from './common';
import BadgeRound from '../../../../../Overall/UI/BadgeRound/BadgeRound';
import Icon from '../../../../../Overall/Icon';

const getStyle = (style, snapshot) =>
    !snapshot.isDropAnimating
        ? style
        : {
              ...style,
              transitionDuration: `0.05s`
          };

const Offer = ({
    offer,
    className,
    onOfferByIdChange,
    offerIndex,
    offers,
    pathOffers,
    countries,
    currencies,
    showSelectItemsModal,
    showCreateUpdateModal,
    hasManyClickUrls,
    onPathChange,
    isSwitchable = true,
    isDraggable = false,
    isOptimizationWeights,
    weightsOptimization,
    isRuleOptimizedData,
    initialWeightOptimizationMethod,
    isPathOutside,
    dataTest,
    lastChangeType,
    isOneItemChange,
    isOneItemDelete,
    entityType
}) => {
    const txt = Langs[global.lng];

    const offerItem = offers?.find(item => offer.offerValue === item.value);

    if (!offerItem) return null;

    const {
        value,
        name: offerName,
        countryTag,
        conversions,
        clicks,
        conversionCap,
        clickCap,
        offerUrl,
        payout,
        payoutType,
        payoutCurrency,
        isCapEnabled,
        redirectOffer,
        capType
    } = offerItem;

    const onOfferChange = (data, prop) => onOfferByIdChange(data, prop, offer.offerValue);

    const isOfferOptimization = !isOptimizationWeights || weightsOptimization === 'landers';

    const isConversionsCapTypeAllow =
        capType === OFFER_CAP_TYPE.CONVERSION || capType === OFFER_CAP_TYPE.CONVERSION_OR_CLICK;
    const isClickCapTypeAllow = capType === OFFER_CAP_TYPE.CLICK || capType === OFFER_CAP_TYPE.CONVERSION_OR_CLICK;

    const isConversionsValid =
        isConversionsCapTypeAllow && Number.isFinite(conversions) && Number.isFinite(conversionCap);
    const isClicksValid = isClickCapTypeAllow && Number.isFinite(clicks) && Number.isFinite(clickCap);

    const redirectOfferItem = offers?.find(item => item.value === redirectOffer);

    const getWarningMessage = () =>
        !offer.isDeleted && offerUrl?.indexOf('{click_id') < 0 && offerUrl?.indexOf('{clickid') < 0
            ? txt.labels.clickIdNotSet
            : '';
    const getAlertMessage = () => {
        if (offerItem.isArchived) return txt.labels.archived;

        /*return !offer.isDeleted &&
            offers
                ?.filter(item =>
                    pathOffers
                        .filter(el => !el.offer.isDeleted)
                        .map(el => el.offerValue)
                        .includes(item.value)
                )
                .some(item => item.redirectOffer === value)
            ? txt.labels.offerUsedAsCap
            : '';*/
    };

    const getOfferWeight = () => {
        if (weightsOptimization === 'bothCombined') return -1;

        if (isRuleOptimizedData && initialWeightOptimizationMethod === weightsOptimization) {
            if (offer.optimizedWeight >= 0) return offer.optimizedWeight;
            else return -1;
        } else {
            if (!offer.isEnabled) return 0;
            return 100 / pathOffers.filter(el => el.isEnabled).length;
        }
    };

    const DragWrapper = ({ children }) =>
        isDraggable ? (
            <Draggable draggableId={offer.offerValue} index={offerIndex} key={value}>
                {(provided, snapshot) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getStyle(provided.draggableProps.style, snapshot)}
                        ref={provided.innerRef}
                    >
                        {children}
                    </div>
                )}
            </Draggable>
        ) : (
            <>{children}</>
        );

    return (
        <DragWrapper>
            <Alert
                disabled={offer.isDeleted}
                warningMessage={getWarningMessage()}
                alertMessage={getAlertMessage()}
                className={className}
                LeftComponent={
                    <div
                        className="flow-path__landers-offers"
                        onClick={() => !offer.isDeleted && showSelectItemsModal('offers', offer.offerValue)}
                    >
                        {hasManyClickUrls && !className.includes('alert_offer--inactive') && (
                            <Badge className="mr4" isSuccess>
                                Click {offerIndex + 1}
                            </Badge>
                        )}

                        <span>{`${showByValue(countries, countryTag)} - ${offerName}`}</span>

                        {offerItem.isArchived && (
                            <BadgeRound
                                text={txt.labels.archived}
                                type={BADGE_TYPE.DANGER}
                                className="badge-round--clear-border ml5"
                            />
                        )}
                    </div>
                }
                RightComponent={
                    // offer.isDeleted ? (
                    //     <ActionsGroup
                    //         dataTest={dataTest}
                    //         onUndo={() => {
                    //             delete offer.isDeleted;
                    //             onOfferChange();
                    //         }}
                    //     />
                    // ) : (
                    <>
                        {isCapEnabled && isConversionsValid && (
                            <BadgeRound
                                type={conversionCap <= conversions && BADGE_TYPE.DANGER}
                                size={BADGE_SIZE.MEDIUM}
                                className="mr5 badge-round--clear-border"
                            >
                                <Icon.DollarCoin />
                                {`${conversions}/${conversionCap}`}
                            </BadgeRound>
                        )}
                        {isCapEnabled && isClicksValid && (
                            <BadgeRound
                                type={clickCap <= clicks && BADGE_TYPE.DANGER}
                                size={BADGE_SIZE.MEDIUM}
                                className="mr5 badge-round--clear-border"
                            >
                                <Icon.Click />
                                {`${clicks}/${clickCap}`}
                            </BadgeRound>
                        )}
                        {payoutType && (
                            <BadgeRound size={BADGE_SIZE.MEDIUM} className="mr5">
                                {payout
                                    ? showByValue(currencies, payoutCurrency, 'symbol') + ' ' + payout
                                    : txt.labels[payoutType]}
                            </BadgeRound>
                        )}
                        {!offer.isDeleted ? (
                            <PathWeight
                                isOptimized={isOfferOptimization}
                                isShowEditableOptimizationInOffer={!hasManyClickUrls && isSwitchable}
                                entityType={entityType}
                                optimizedWeight={getOfferWeight()}
                                item={offer}
                                items={pathOffers}
                                onChange={onOfferChange}
                            />
                        ) : null}
                        <ActionsGroup
                            className={offer.isDeleted ? 'path_alert-actions-group--deleted' : null}
                            dataTest={dataTest}
                            // onDelete={() => onOfferChange(true, 'offer.isDeleted')}
                            onDelete={() => {
                                if (lastChangeType) lastChangeType.current = 'offers';
                                if (isOneItemChange) isOneItemChange.current = true;
                                if (isOneItemDelete) isOneItemDelete.current = true;

                                onPathChange(
                                    pathOffers.filter(el => offer.offerValue !== el.offerValue),
                                    'offers'
                                );
                            }}
                            isSwitchOn={offer.isEnabled}
                            onSwitch={
                                offer.isDeleted || hasManyClickUrls || !isSwitchable
                                    ? null
                                    : e => onOfferChange(e, 'isEnabled')
                            }
                            onEdit={() => showCreateUpdateModal('offers', offerItem.value)}
                        />
                    </>
                    // )
                }
            />
            {isCapEnabled && (isConversionsValid || isClicksValid) && (
                <Alert
                    disabled={offer.isDeleted}
                    className={cn(
                        'alert__cap',
                        `alert__cap--${
                            (isConversionsValid && conversions >= conversionCap) ||
                            (isClicksValid && clicks >= clickCap)
                                ? 'work'
                                : 'wait'
                        }`,
                        {
                            'alert--white-inactive': className.includes('alert_offer--inactive')
                        },
                        { 'alert__cap--locked': isPathOutside }
                    )}
                    LeftComponent={
                        <div className="flow-path__landers-offers" onClick={() => {}}>
                            {`${showByValue(countries, countryTag)} - ${redirectOfferItem?.name}`}
                        </div>
                    }
                    RightComponent={
                        <BadgeRound size={BADGE_SIZE.MEDIUM} className="mr5">
                            {redirectOfferItem?.payout ? (
                                <>
                                    <span>{redirectOfferItem?.payoutCurrency}</span>
                                    <span className="path__redirect-offer-payout">{redirectOfferItem?.payout}</span>
                                </>
                            ) : (
                                txt.labels.auto
                            )}
                        </BadgeRound>
                    }
                />
            )}
        </DragWrapper>
    );
};

export default Offer;
