import React, { useEffect } from 'react';

import Langs from '../../../../../Langs';

import useSearch from '../../../../../Functions/hooks/useNewSearch';
import useFocus from '../../../../../Functions/hooks/useFocus';

import TextInput from '../../../Forms/Inputs/TextInput';
import DropdownBalloon from '../../../UI/DropdownBalloon/DropdownBalloon';
import BookmarkItem from '../bookmark-item/bookmark-item';

const BookmarkFavorite = ({ favorites, reports, setIsTabPopupOpen }) => {
    const txt = Langs[global.lng];

    const [inputRef, setInputFocus] = useFocus();

    useEffect(setInputFocus, []);

    const { filteredItems, searchByValue, setSearchByForInput } = useSearch(
        favorites,
        favorite => favorite.label || favorite.name || favorite
    );

    return (
        <DropdownBalloon className="dropdown-balloon--bookmarks">
            <TextInput
                dataTest="bookmarks-input"
                className="p15"
                ref={inputRef}
                value={searchByValue}
                onChange={setSearchByForInput}
                placeholder={txt.placeholders.search}
            />

            {filteredItems.map(bookmark => (
                <BookmarkItem
                    key={bookmark.id}
                    reports={reports}
                    item={bookmark}
                    setIsTabPopupOpen={setIsTabPopupOpen}
                    favorites={favorites}
                    search={searchByValue}
                />
            ))}
        </DropdownBalloon>
    );
};

export default BookmarkFavorite;
