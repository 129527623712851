import { useCallback, useState } from 'react';

const useToggle = initialState => {
    const [isToggled, setToggle] = useState(!!initialState);
    const toggle = useCallback(() => setToggle(isOn => !isOn), []);
    const toggleOn = useCallback(() => setToggle(true), []);
    const toggleOff = useCallback(() => setToggle(false), []);

    return {
        isToggled,
        toggle,
        toggleOn,
        toggleOff
    };
};

export default useToggle;
