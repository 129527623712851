import React from 'react';

import { getIntlFormattedValue } from '../../../../Functions/utils';

import ArrowDown from '../../../../../../media/images/arrow-down.svg';
import ArrowUp from '../../../../../../media/images/arrow-up.svg';

import './indicator.scss';

const Indicator = React.memo(({ label, color, change, value, units, prefix, suffix, isInteger }) => {
    const getChange = () => {
        if (!change || change === 0) return null;
        return (
            <div className="indicator-header__change">
                <img src={change > 0 ? ArrowUp : ArrowDown} alt="Arrow" />
                {getIntlFormattedValue(Math.abs(change), 2)}%
            </div>
        );
    };

    return (
        <div className="indicator">
            <div className="indicator-header">
                <div className="indicator-header__label" style={{ color }}>
                    <span>{label}</span>
                </div>
                {getChange()}
            </div>

            <div className="indicator-body">
                {prefix && <div className="indicator-body__prefix">{prefix}</div>}
                <div className="indicator-body__value">
                    {Number.isFinite(value) ? getIntlFormattedValue(value, isInteger ? 0 : 2) : value}
                    <span>{units}</span>
                </div>
                {suffix && <div className="indicator-body__suffix">{suffix}</div>}
            </div>
        </div>
    );
});

export default Indicator;
