const initialState = {
    modal: [],
    absTooltip: null
};

export default function reducer(state = initialState, action) {
    let data = null;
    if (action.data || action.data === false || action.data === '' || action.data === 0) {
        if (Array.isArray(action.data)) {
            data = Array.from(action.data);
        } else if (typeof action.data === 'object') data = { ...action.data };
        else data = action.data;
    }
    switch (action.type) {
        //UNI dispatches
        case 'Branch':
            return {
                ...state,
                [action.branch]: data
            };
        case 'CD':
            return {
                ...state,
                [action.branch]: {
                    ...state[action.branch],
                    [action.chapter]: data
                }
            };
        case 'Rank1':
            return {
                ...state,
                [action.branch]: {
                    ...state[action.branch],
                    [action.chapter]: {
                        ...state[action.branch][action.chapter],
                        [action.rank1]: data
                    }
                }
            };
        case 'Rank2':
            return {
                ...state,
                [action.branch]: {
                    ...state[action.branch],
                    [action.chapter]: {
                        ...state[action.branch][action.chapter],
                        [action.rank1]: {
                            ...state[action.branch][action.chapter][action.rank1],
                            [action.rank2]: data
                        }
                    }
                }
            };
        case 'Rank3':
            return {
                ...state,
                [action.branch]: {
                    ...state[action.branch],
                    [action.chapter]: {
                        ...state[action.branch][action.chapter],
                        [action.rank1]: {
                            ...state[action.branch][action.chapter][action.rank1],
                            [action.rank2]: {
                                ...state[action.branch][action.chapter][action.rank1][action.rank2],
                                [action.rank3]: data
                            }
                        }
                    }
                }
            };
        default:
            return { ...state };
    }
}
