import React from 'react';

import Switcher from '../../../UI/Switcher/Switcher';
import SwitcherIsNot from '../../../UI/SwitcherIsNot/SwitcherIsNot';

const SwitcherIsNotPicker = ({ label, onChange, isEnabled, isMatched, className }) => {
    return (
        <Switcher
            dataTest="switcher-is-not-picker"
            label={label}
            isSwitchOn={isEnabled}
            onChange={e => onChange(e, 'isEnabled')}
            className={className}
        >
            <SwitcherIsNot
                dataTest="switcher-is-not-picker"
                className="mt10"
                item={{ value: isMatched, text: ['is', 'is not'] }}
                onChange={e => onChange(e, 'isMatched')}
            />
        </Switcher>
    );
};

export default SwitcherIsNotPicker;
