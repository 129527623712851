import React from 'react';

import Icon from '../../Icon';
import Btn from '../../UI/Btn/Btn';

import './td-actions.scss';
import './td-types.scss';

const TdStatusWithUpdating = props => {
    const { rowIndex, item, onActionClick, domainID } = props;

    const renderInternalElement = () => {
        const text = item;
        switch (item) {
            case 'active':
                return <span className="color--de-york dns-status">{text}</span>;
            case 'pending':
            case 'error':
                return (
                    <>
                        <span className="color--froly dns-status">{text}</span>
                        <Btn
                            type="icon"
                            onClick={() => {
                                onActionClick('checkStatus', { domain: domainID, status: item, rowIndex });
                            }}
                        >
                            <Icon.RefreshCw className="td-actions--refresh" />
                        </Btn>
                    </>
                );
            default:
                return (
                    <>
                        <span className="color--froly">{text}</span>
                        <Btn
                            type="icon"
                            onClick={() => {
                                onActionClick('checkStatus', { domain: domainID, status: item });
                            }}
                        >
                            <Icon.RefreshCw className="td-actions--refresh" />
                        </Btn>
                    </>
                );
        }
    };

    return renderInternalElement();
};

export default TdStatusWithUpdating;
