import React, { useState } from 'react';

import Langs from '../../../Langs';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import { handleObjectChange } from '../../../Functions/utils';
import CriteriaTabs from '../../Overall/CriteriaDialog/CriteriaTabs';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';

import './antiFraudKit.scss';

const ConditionsModal = props => {
    const { onSubmit, item, /*params,*/ onClose } = props;
    // const { templates, overall } = params;
    const txt = Langs[global.lng];
    const [isSubmitTouched, setIsSubmitTouched] = useState(false);

    // FIXME:
    const criteria = [];
    const setCriteria = () => {};
    // const [criteria, setCriteria] = useState(
    //     getCriteriaValues(item ? item.criteria : templates.offerRuleTemplate?.criteria, overall)
    // );
    const LOGICAL_RELATIONS = [
        { value: 'and', label: 'logicalAnd' },
        { value: 'or', label: 'logicalOr' }
    ];

    const conditionAdd = {
        name: '',
        logicalRelation: 'and',
        criteria
    };

    const [condition, setCondition] = useState(item || conditionAdd);
    const isAllValid = !!condition.name;
    const onChange = handleObjectChange(condition, () => {
        setCondition({ ...condition });
    });

    const handleSubmit = () => {
        onSubmit({ ...condition, criteria });
        return true;
    };

    return (
        <>
            <div className="settings-wrapper conditions-modal-box">
                <div className="conditions-modal-name">
                    <TextInput
                        dataTest="conditions-modal_name-input"
                        label={txt.labels.conditionsName}
                        value={condition.name}
                        onChange={e => onChange(e, 'name')}
                        hint={txt.hints.conditionsName}
                        require={true}
                        error={isSubmitTouched && !condition.name && txt.errors.required(txt.labels.name)}
                    />
                </div>

                <div className="conditions-modal-radios">
                    <RadioInput
                        dataTest="conditions-modal_logical-relation"
                        label={txt.labels.logicalRelation}
                        hint={txt.hints.logicalRelation}
                        items={LOGICAL_RELATIONS}
                        value={condition.logicalRelation}
                        type="row"
                        onChange={e => onChange(e, 'logicalRelation')}
                    />
                </div>

                <CriteriaTabs criteria={criteria} onChange={() => setCriteria([...criteria])} />
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                onConfirm={() => {
                    setIsSubmitTouched(true);
                    return isAllValid ? handleSubmit() : false;
                }}
                isConfirmDisabled={isSubmitTouched && !isAllValid}
                onReject={onClose}
            />
        </>
    );
};

export default ConditionsModal;
