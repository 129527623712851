import React, { useMemo, useEffect, useState, useRef } from 'react';
import cn from 'classnames';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import Langs from '../../Langs';
import { INITIAL_SELECTED_METRICS } from '../../Constants/index';
import { getColumns } from '../../components/main-table/services';
import { getParams, sharedReportEntityTransformer, getDateRangeFromQueryString } from '../../Functions/utils';
import { showModal, getActualDateRange } from '../../redux/operations';
import usePagination from '../../Functions/hooks/usePagination';
import { exportDateTransformer } from '../../redux/transformers';

import indexApi from '../../redux/api/indexApi';
import {
    useGetSharedReportStatsQuery,
    useGetSharedReportChartQuery,
    sharedReportsApiTagTypes
} from '../../redux/api/sharedReportsApi';

import GeneralTable from '../../components/main-table/general-table';
import Loader from '../../Modules/Loader';
import TableControls from './components/table-controls/table-controls';
import Charts from '../../Modules/Charts/Charts';
import ColumnsSettings from '../../Modules/Settings/ColumnsSettings/ColumnsSettings';

import LogoImg from '../../../../media/images/logo-dark.svg';

import './page-shared-report.scss';

const PageSharedReport = () => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [isShowCompare, setIsShowCompare] = useState(false);
    const [isShowChart, setIsShowChart] = useState(false);

    const { pagination, setPagination } = usePagination({ perPage: 50 });
    const [sorting, setSorting] = useState({ key: 'name', type: 'ASC' });
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnsWidths, setColumnsWidths] = useState([]);
    const [reportPeriod, setReportPeriod] = useState({});
    const [reportAvailablePeriod, setReportAvailablePeriod] = useState({});

    const isFirstRender = useRef(true);

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination.perPage,
        ...(!isFirstRender.current
            ? {
                  [`filter[dateFrom]`]: exportDateTransformer(reportPeriod.start),
                  [`filter[dateTo]`]: exportDateTransformer(reportPeriod.end)
              }
            : {}),
        ...(isShowCompare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(reportPeriod.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(reportPeriod.endPrevDate)
              }
            : {}),
        ...columnFilters.reduce((acc, el) => {
            return {
                ...acc,
                ...(el.minValue ? { [`filter[${el.key}Min]`]: +el.minValue } : {}),
                ...(el.maxValue ? { [`filter[${el.key}Max]`]: +el.maxValue } : {})
            };
        }, {})
    };

    const {
        data: sharedReport,
        isSuccess: isSharedReportLoaded,
        isError: isSharedReportError,
        isFetching: isSharedReportFetching
    } = useGetSharedReportStatsQuery({ id, params: getParams(parameters) });

    useEffect(() => {
        if (isSharedReportLoaded) {
            setPagination(sharedReport.report.pagination);
            setRows(sharedReport.report.items);

            const dateRange = getDateRangeFromQueryString(sharedReport.reportDetails.query);

            setReportAvailablePeriod(
                getActualDateRange({
                    ...dateRange
                })
            );

            if (isFirstRender.current) {
                setReportPeriod(
                    getActualDateRange({
                        ...dateRange,
                        compare: true
                    })
                );
            }

            setIsLoading(false);
            isFirstRender.current = false;
        }
    }, [sharedReport]);

    useEffect(() => {
        if (isSharedReportError) window.location.href = 'https://plat.com/report-not-found';
    }, [isSharedReportError]);

    const {
        data: sharedReportChart,
        isSuccess: isSharedReportChartLoaded,
        isFetching: isSharedReportChartFetching
    } = useGetSharedReportChartQuery(id, { skip: !isShowChart });

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                setSorting(data);
                break;

            case 'columnFilters': {
                setColumnFilters(data);
                break;
            }

            case 'columnWidths': {
                setColumnsWidths(data);
                break;
            }

            default:
                break;
        }
    };

    const onRefreshClick = () => {
        dispatch(
            indexApi.util.invalidateTags([
                { type: sharedReportsApiTagTypes.sharedReports, id: 'SHARED_REPORT_STATS' },
                { type: sharedReportsApiTagTypes.sharedReports, id: 'SHARED_REPORT_CHART' }
            ])
        );
    };

    const [metrics, setMetrics] = useState(INITIAL_SELECTED_METRICS);
    const [userColumnsSettings, setUserColumnsSettings] = useState({
        columns: [],
        isColoredRows: false,
        isVerticalBorders: false
    });

    const columns = useMemo(() => {
        if (!sharedReport) return [];
        else {
            const partialStorage = {
                common: {
                    customConversions: sharedReport.reportDetails.customColumns
                },
                customColumns: userColumnsSettings.columns
            };

            return getColumns(sharedReportEntityTransformer(sharedReport.reportDetails.entityType), partialStorage, {
                includeIntegratedCampaignList: true,
                includeAdditionalRevenue: true,
                includeCompareColumns: isShowCompare
            })
                .orderedColumns.filter(el => el.key !== 'checked')
                .filter(
                    el =>
                        sharedReport.reportDetails.columns.includes(el.key) ||
                        el.type === 'change' ||
                        el.type === 'changeOnlyProfit'
                );
        }
    }, [sharedReport, userColumnsSettings, isShowCompare]);

    const onShowSettingsClick = () => {
        showModal({
            title: txt.labels.columnsSettings,
            headerType: 'primary',
            body: prop => (
                <ColumnsSettings
                    {...prop}
                    chapter={sharedReportEntityTransformer(sharedReport.reportDetails.entityType)}
                    columns={columns}
                    customSave={data => setUserColumnsSettings(data)}
                    isColoredRows={userColumnsSettings.isColoredRows}
                    isVerticalBorders={userColumnsSettings.isVerticalBorders}
                />
            )
        });
    };

    if (isLoading) return <Loader />;

    return (
        <div className="page-shared-report">
            <div className="page-shared-report__header">
                <div className="page-shared-report__logo">
                    <Link to="/home/campaigns">
                        <img src={LogoImg} alt="Logo" width="60" height="32" />
                    </Link>
                </div>

                <h1 className="page-shared-report__name">{sharedReport?.reportDetails?.name}</h1>

                <div className="page-shared-report__period">
                    <span>
                        Report Available Until{' '}
                        {moment(sharedReport?.reportDetails?.availableDateTo).format('HH:mm DD MMM YYYY')}
                    </span>
                </div>

                <div className="page-shared-report__links">
                    <Link
                        to="/login"
                        target="_blank"
                        className="page-shared-report__link page-shared-report__link--login"
                    >
                        {txt.buttons.login}
                    </Link>

                    <Link
                        to="/register"
                        target="_blank"
                        className="page-shared-report__link page-shared-report__link--signup"
                    >
                        {txt.buttons.signUp}
                    </Link>
                </div>
            </div>

            <div className="table-container">
                <div className={cn('main-table')}>
                    <div className="main-table__wrapper">
                        <TableControls
                            report={sharedReport?.reportDetails}
                            pagination={pagination}
                            reportPeriod={reportPeriod}
                            reportAvailablePeriod={reportAvailablePeriod}
                            setPagination={setPagination}
                            isShowCompare={isShowCompare}
                            setIsShowCompare={setIsShowCompare}
                            setReportPeriod={setReportPeriod}
                            isShowChart={isShowChart}
                            setIsShowChart={setIsShowChart}
                            refresh={onRefreshClick}
                            onShowSettingsClick={onShowSettingsClick}
                        />

                        {isShowChart && (
                            <Charts
                                datasets={sharedReportChart?.datasets}
                                labels={sharedReportChart?.labels}
                                onChange={data => setMetrics(data)}
                                metrics={metrics}
                                isLoading={!isSharedReportChartLoaded || isSharedReportChartFetching}
                            />
                        )}

                        <div className="main-table__visible-content">
                            <GeneralTable
                                chapter={sharedReportEntityTransformer(sharedReport.reportDetails.entityType)}
                                rows={rows}
                                columns={columns}
                                columnFilters={columnFilters}
                                columnsWidths={columnsWidths}
                                total={sharedReport.report?.total}
                                sorting={sorting}
                                isLoading={isSharedReportFetching}
                                isColoredRows={userColumnsSettings.isColoredRows}
                                isVerticalBorders={userColumnsSettings.isVerticalBorders}
                                onRowClick={() => null}
                                onRowContextMenu={() => null}
                                onTableActionsChange={onTableActionsChange}
                                updateRows={setRows}
                                isSharedReportPage
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageSharedReport;
