import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Langs from '../../../Langs';
import useToggle from '../../../Functions/hooks/useToggle';
import useClickOutside from '../../../Functions/hooks/useClickOutside';
import { getIntlFormattedValue, logout } from '../../../Functions/utils';

import { useGetProjectInfoQuery } from '../../../redux/api/projectApi';
import { useGetSupportAccessScheduleQuery } from '../../../redux/api/supportAccessApi';
import { useGetTimezonesQuery } from '../../../redux/api/systemApi';

import DropdownBalloon from '../UI/DropdownBalloon/DropdownBalloon';
import HeaderSupportSchedule from './components/header-support-schedule/header-support-schedule';

import Icon from '../Icon';
import ProfileIcon from '../../../../../media/images/profile.svg';
import ProfileSupportIcon from '../../../../../media/images/profile-support.svg';
import SupportHeadphoneGreen from '../../../../../media/images/support-headphone-green.svg';
import SupportHeadphoneGold from '../../../../../media/images/support-headphone-gold.svg';

import './header-avatar.scss';

const HeaderAvatar = ({ isUserBlocked, supportModeInfo, credit }) => {
    const txt = Langs[global.lng];

    const { isToggled: isProfileToggled, toggle: toggleProfile, toggleOff: toggleOffProfile } = useToggle();
    const {
        isToggled: isSupportAccessToggled,
        toggle: toggleSupportAccess,
        toggleOff: toggleOffSupportAccess
    } = useToggle();

    const profileRef = useClickOutside(toggleOffProfile);
    const supportAccessRef = useClickOutside(toggleOffSupportAccess);

    const { data: projectInfo } = useGetProjectInfoQuery();
    const isScheduleType = projectInfo?.supportAccess && projectInfo?.supportAccess === 'schedule';

    const { data: timezones } = useGetTimezonesQuery();

    const { data: scheduleData } = useGetSupportAccessScheduleQuery(undefined, { skip: supportModeInfo });
    const upcomingSchedule = scheduleData?.filter(el => !el.isExpired);

    const isValidCredit = +credit > 0;

    const renderAvatarPopup = () => {
        return (
            <DropdownBalloon className="dropdown-balloon--profile">
                {!isUserBlocked && (
                    <Link
                        to="/settings/user"
                        className="bookmark-item"
                        data-test="header-avatar_profile-link"
                        onClick={toggleOffProfile}
                    >
                        <div className="bookmark-item__label">{txt.auth.profile}</div>
                    </Link>
                )}
                <a data-test="header-avatar_logout-link" href="#logout" onClick={logout} className="bookmark-item">
                    <div className="bookmark-item__label">{txt.auth.logOut}</div>
                </a>
            </DropdownBalloon>
        );
    };

    const renderCreditPopup = () => {
        return (
            <DropdownBalloon className="dropdown-balloon--credit">
                <>
                    <div className="header-avatar__popup-credit-title">
                        {txt.texts.credit}:&nbsp;{`$${getIntlFormattedValue(credit)}`}
                    </div>
                    <div className="header-avatar__popup-credit-text">{txt.texts.creditWillGoAutomatically}</div>
                </>
            </DropdownBalloon>
        );
    };

    const renderSupportAccessPopup = () => {
        return (
            <div className="header-avatar__support-info">
                <div className="header-avatar__support-info-container">
                    {isScheduleType ? (
                        <>
                            <div className="header-avatar__popup-container">
                                <b>Support access:</b> Scheduled
                            </div>

                            <HeaderSupportSchedule schedule={upcomingSchedule} timezones={timezones} />
                        </>
                    ) : (
                        <div className="header-avatar__popup-container mb8">
                            <b>Support access:</b> Always allow
                        </div>
                    )}
                    <div className="header-avatar__popup-footer">
                        Go to{' '}
                        <Link to={'/settings/support'} onClick={toggleOffSupportAccess}>
                            Settings / Support
                        </Link>{' '}
                        to manage Support access
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={cn('header-avatar__wrapper', {
                'header-avatar__wrapper--user-blocked': isUserBlocked,
                'header-avatar__wrapper--user-blocked-offset':
                    projectInfo?.supportAccess === 'always' || upcomingSchedule?.length,
                'header-avatar__wrapper--support':
                    (projectInfo?.supportAccess === 'always' || upcomingSchedule?.length) && !supportModeInfo
            })}
        >
            <div
                className={cn(
                    'header-avatar',
                    { 'header-avatar--user-blocked': isUserBlocked },
                    { 'header-avatar--admin': supportModeInfo },
                    { 'header-avatar--credit': isValidCredit && !supportModeInfo }
                )}
            >
                {supportModeInfo && !isUserBlocked && (
                    <span className="header-avatar__info-panel">{`${supportModeInfo?.userRole} (${supportModeInfo?.userLogin})`}</span>
                )}

                {isValidCredit && !supportModeInfo && (
                    <>
                        <Icon.Gift className="header-avatar__info-panel-icon" size={16} />
                        <span className="header-avatar__info-panel">{`$${getIntlFormattedValue(credit)}`}</span>
                    </>
                )}

                <div className="header-avatar__image-wrapper" ref={profileRef}>
                    <img
                        src={supportModeInfo ? ProfileSupportIcon : ProfileIcon}
                        alt={txt.auth.profile}
                        className="header-avatar__image"
                        onClick={toggleProfile}
                    />
                    {isValidCredit && !supportModeInfo && renderCreditPopup()}
                    {isProfileToggled && renderAvatarPopup()}
                </div>
            </div>

            {(projectInfo?.supportAccess === 'always' || upcomingSchedule?.length) && !supportModeInfo ? (
                <div ref={supportAccessRef}>
                    <div
                        onClick={toggleSupportAccess}
                        className={cn('header-avatar__support', { 'header-avatar__support--schedule': isScheduleType })}
                    >
                        <img
                            src={isScheduleType ? SupportHeadphoneGold : SupportHeadphoneGreen}
                            alt={txt.auth.profile}
                            width="14"
                        />
                    </div>

                    <div className="header-avatar__support-info-tooltip">Click to view access settings</div>

                    {isSupportAccessToggled && renderSupportAccessPopup()}
                </div>
            ) : null}
        </div>
    );
};

export default HeaderAvatar;
