import React from 'react';
import moment from 'moment-timezone';

import Langs from '../../../Langs';

import './createdUpdatedUserInfo.scss';

const getDate = date => moment(date).format('hh:mm DD MMM YYYY');

const CreatedUpdatedUserInfo = ({ createdUserName, updatedUserName, createdAt, updatedAt }) => {
    const txt = Langs[global.lng];

    const isTheSameUser = createdUserName === updatedUserName;
    const isTheSameDate = createdAt === updatedAt;

    return (
        <>
            <span className="user-info">
                <span className="user-info__title">{txt.texts.createdBy}</span>
                <span className="user-info__body">{`${createdUserName} ${getDate(createdAt)}`}</span>
            </span>
            {(!isTheSameUser || !isTheSameDate) && updatedUserName && (
                <span className="user-info user-info--updated">
                    <span className="user-info__title">{txt.texts.updatedBy}</span>
                    <span className="user-info__body">{`${updatedUserName} ${getDate(updatedAt)}`}</span>
                </span>
            )}
        </>
    );
};

export default CreatedUpdatedUserInfo;
