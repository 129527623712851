import indexApi from './indexApi';

const invoiceDetailsApiTagTypes = {
    invoiceDetails: 'invoiceDetails'
};

const invoiceDetailsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(invoiceDetailsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getInvoiceDetails: builder.query({
                query: () => `/invoice-details`,
                providesTags: () => [invoiceDetailsApiTagTypes.invoiceDetails]
            }),

            addInvoiceDetails: builder.mutation({
                query: ({ data }) => ({
                    url: `/invoice-details`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [invoiceDetailsApiTagTypes.invoiceDetails, { type: 'invoices', id: 'LIST' }];
                }
            }),

            editInvoiceDetails: builder.mutation({
                query: ({ data }) => ({
                    url: `/invoice-details`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [invoiceDetailsApiTagTypes.invoiceDetails];
                }
            })
        })
    });

export const { useGetInvoiceDetailsQuery, useAddInvoiceDetailsMutation, useEditInvoiceDetailsMutation } =
    invoiceDetailsApi;
