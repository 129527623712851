import React from 'react';

import Langs from '../../../../Langs';
import { showModal, hideModal } from '../../../../redux/operations';
import { capitalize, omitKeys } from '../../../../Functions/utils';

import Section from '../../../../components/section/section';
import SectionHeader from '../../../../components/section-header/section-header';
import AddBillingInfoModal from '../../../Modals/add-billing-info-modal/add-billing-info-modal';
import Loader from '../../../Loader';

import './invoice-details.scss';

export const InvoiceDetails = ({ invoiceDetails, isFetching }) => {
    const txt = Langs[global.lng];

    const invoiceDetailsKeys = Object.keys(
        omitKeys(invoiceDetails ?? {}, [
            'id',
            'projectId',
            'createdAt',
            'updatedAt',
            invoiceDetails?.type === 'personal' ? 'ssn' : ''
        ])
    );

    const showInvoiceDetailsModal = () => {
        showModal({
            title: txt.titles.billingInfo,
            onClose: hideModal,
            body: props => <AddBillingInfoModal {...props} />
        });
    };

    const sectionHeader = (
        <SectionHeader
            title={txt.titles.invoiceDetails}
            button={{
                label: invoiceDetailsKeys?.length ? txt.buttons.change : txt.buttons.addDetails,
                onClick: showInvoiceDetailsModal
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="mb25 invoice-details">
            <div className="invoice-details__list">
                {invoiceDetailsKeys.map(key => (
                    <div key={key} className="invoice-details__row">
                        <span className="text-default-light">{txt.labels.billing.invoiceDetails[key] || key}</span>
                        <span>{key === 'type' ? capitalize(invoiceDetails[key]) : invoiceDetails[key]}</span>
                    </div>
                ))}

                {invoiceDetailsKeys.length === 0 && <span className="invoice-details__empty">No invoice details</span>}
            </div>

            {isFetching && <Loader isFetching />}
        </Section>
    );
};

export default InvoiceDetails;
