// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noData__2Z3mI{width:100%;display:flex;align-items:center;justify-content:center;padding:18px;position:sticky;left:0;font-weight:300;font-size:16px;line-height:20px}.noData__inner__23jI_{width:100%;border:1px dashed #e2e5ec;height:80px;display:flex;align-items:center;justify-content:center;color:#9aa0b9;pointer-events:none;user-select:none}.noData__button__10nwP{cursor:pointer;width:100%;display:flex;align-items:center;justify-content:center;color:var(--color-primary);background:var(--color-bg);border:1px dashed #e2e5ec;height:80px}", "",{"version":3,"sources":["webpack://src/JS/Modules/MainTable/MainTableItems/no-data.module.scss"],"names":[],"mappings":"AAAA,eACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,eAAA,CACA,MAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,sBACE,UAAA,CACA,yBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CACA,mBAAA,CACA,gBAAA,CAGF,uBACE,cAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,0BAAA,CACA,0BAAA,CACA,yBAAA,CACA,WAAA","sourcesContent":[".noData {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 18px;\n  position: sticky;\n  left: 0;\n  font-weight: 300;\n  font-size: 16px;\n  line-height: 20px;\n\n  &__inner {\n    width: 100%;\n    border: 1px dashed #e2e5ec;\n    height: 80px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #9AA0B9;\n    pointer-events: none;\n    user-select: none;\n  }\n\n  &__button {\n    cursor: pointer;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--color-primary);\n    background: var(--color-bg);\n    border: 1px dashed #E2E5EC;\n    height: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": "noData__2Z3mI",
	"noData__inner": "noData__inner__23jI_",
	"noData__button": "noData__button__10nwP"
};
export default ___CSS_LOADER_EXPORT___;
