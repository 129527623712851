import React from 'react';
import cn from 'classnames';

import CheckBoxInput from '../../../Forms/Inputs/CheckBoxInput';
import Icon from '../../../Icon';
import Btn from '../../../UI/Btn/Btn';
import Switcher from '../../../UI/Switcher/Switcher';

import './actions-group.scss';

const ActionsGroup = ({
    onEdit,
    onCopy,
    onDelete,
    onSwitch,
    onCheck,
    onUndo,
    isSwitchOn,
    isChecked,
    dataTest,
    className
}) => (
    <div className={cn('actions-group', className)}>
        {onCheck && (
            <CheckBoxInput
                dataTest="actions-group_check-checkbox"
                value={isChecked}
                className="mr8"
                onChange={onCheck}
            />
        )}
        {onSwitch && <Switcher dataTest="actions-group-switch" isSwitchOn={isSwitchOn} onChange={onSwitch} />}
        {onCopy && (
            <Btn dataTest={`actions-group_${dataTest}_copy-btn`} type="pure" onClick={onCopy}>
                <Icon.Copy />
            </Btn>
        )}
        {onEdit && (
            <Btn
                dataTest={`actions-group_${dataTest}_edit-btn`}
                type="pure"
                onClick={onEdit}
                className="path__action-edit"
            >
                <Icon.Edit3 />
            </Btn>
        )}
        {onDelete && (
            <Btn
                className="actions-group--delete-btn"
                dataTest={`actions-group_${dataTest}_delete-btn`}
                type="pure"
                onClick={onDelete}
            >
                <Icon.Trash2 />
            </Btn>
        )}
        {onUndo && (
            <Btn dataTest={`actions-group_${dataTest}_undo-btn`} type="pure" onClick={onUndo}>
                <Icon.RotateCcw className="actions-group__icon--undo" />
            </Btn>
        )}
    </div>
);

export default ActionsGroup;
