import React from 'react';

import Langs from '../../../../Langs';

// DEPRECATED: Этот компонент больше не поддерживается, использовать или заменить на <JustTable /> JS\components\just-table\just-table.js

const TableThItem = props => {
    const txt = Langs[global.lng];
    const { item } = props;

    return (
        <div className={`th-item ${item.className || ''}`}>{item.label ? item.label || txt.labels[item.key] : ''}</div>
    );
};

export default TableThItem;
