import React, { useEffect, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../../../Constants';
import { GOOGLE_OAUTH_KEY } from '../../../../../Functions/checkOAuthStatus';
import { hideModal } from '../../../../../redux/operations';
import useFocus from '../../../../../Functions/hooks/useFocus';
import { getResponseErrorMessage } from '../../../../../Functions/utils';

import indexApi from '../../../../../redux/api/indexApi';
import {
    useGetTSIntegrationByIdQuery,
    useEditTSIntegrationMutation,
    useGetIntegrationAccountsQuery
} from '../../../../../redux/api/integrationsApi';

import { getIntegrationIcon } from '../../../../Tools/integrations/get-integration-icon';

import ModalButtonBar from '../../../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../../../Overall/Forms/Inputs/TextInput';
import Alert from '../../../../Overall/UI/Alert/Alert';
import Loader from '../../../../Loader';
import FakeAutocomplete from '../../../../../components/fake-autocomlete/fake-autocomlete';
import RadioInput from '../../../../Overall/Forms/Inputs/RadioInput';

const GoogleIntegrationModal = ({ templateName, templateId, integrationId, mode, onClose, onChange }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const [nameInputRef, setNameFocus] = useFocus();
    const [isTouched, setIsTouched] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isAuthClick, setIsAuthClick] = useState(false);
    const [isIntegratedConfirm, setIsIntegratedConfirm] = useState(templateName !== 'Google Ads - Search/Display');
    const [additionalRadioFields, setAdditionalRadioFields] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const savedIntegrationId = useRef(null);

    let OAuthPopup;

    const isCreateMode = mode === ENTITY_MODE_STATUS.CREATE;
    const isEditMode = mode === ENTITY_MODE_STATUS.EDIT;

    const modifiedTemplateName = 'Google Ads';
    const [integrationName, setIntegrationName] = useState(modifiedTemplateName);

    const { data: integration, isSuccess: isIntegrationLoaded } = useGetTSIntegrationByIdQuery(integrationId, {
        skip: !integrationId || mode === ENTITY_MODE_STATUS.CREATE
    });

    useEffect(() => {
        if (isIntegrationLoaded) {
            setIntegrationName(integration?.name);
            setSelectedAccount(integration.authCredentials?.accountId);
        }
    }, [integration]);

    const {
        data: integrationAccounts,
        isSuccess: isIntegrationAccountsLoaded,
        isError: isIntegrationAccountsError
    } = useGetIntegrationAccountsQuery(integrationId, {
        skip: mode === ENTITY_MODE_STATUS.CREATE
    });

    useEffect(() => {
        if (isIntegrationAccountsLoaded) {
            setAdditionalRadioFields(integrationAccounts);
        }
    }, [integrationAccounts]);

    const [
        editTrafficSourceIntegration,
        {
            isLoading: isEditTrafficSourceIntegrationLoading,
            isError: isEditTrafficSourceIntegrationError,
            error: editTrafficSourceIntegrationError,
            isSuccess: isEditTrafficSourceIntegrationSuccess
        }
    ] = useEditTSIntegrationMutation();

    useEffect(() => {
        if (isEditTrafficSourceIntegrationSuccess) {
            toast.success(txt.toasts.saved);

            if (onChange) {
                onChange({
                    integrationId: savedIntegrationId.current || integrationId,
                    isIntegration: true
                });
            }

            hideModal();
        }
    }, [isEditTrafficSourceIntegrationSuccess]);

    useEffect(() => {
        if (mode === ENTITY_MODE_STATUS.CREATE) setTimeout(setNameFocus, 0);
    }, []);

    const onConfirm = () => {
        setIsTouched(true);

        const queryData = {
            id: savedIntegrationId.current || integrationId,
            data: {
                isActive: true,
                name: integrationName,
                templateId,
                authCredentials: { accountId: selectedAccount },
                isDraft: false
            }
        };

        editTrafficSourceIntegration(queryData);
    };

    const Img = useMemo(() => getIntegrationIcon(templateName.split(' - ')[0]), [templateName]);

    useEffect(() => {
        const closeOAuthPopup = async event => {
            if (event.key === GOOGLE_OAUTH_KEY) OAuthPopup?.close();
            else return;

            setIsProcessing(true);

            const data = JSON.parse(localStorage.getItem(GOOGLE_OAUTH_KEY));
            savedIntegrationId.current = data.integrationId;
            localStorage.removeItem(GOOGLE_OAUTH_KEY);

            if (!data?.isOk) {
                setIsProcessing(false);
                setIsAuthClick(false);
                return toast.error(data.error ?? txt.toasts.commonError);
            }

            const codeCheckStatus = await dispatch(
                indexApi.endpoints.checkIntegrationOAuthCode.initiate({
                    id: data.integrationId,
                    code: data.code
                })
            );

            if (codeCheckStatus.isError) {
                setIsProcessing(false);
                setIsAuthClick(false);
                return toast.error(codeCheckStatus?.error?.data.errors?.message ?? txt.toasts.commonError);
            }

            const integrationAccountsData = await dispatch(
                indexApi.endpoints.getIntegrationAccounts.initiate(data.integrationId)
            );

            if (integrationAccountsData.isError) {
                return toast.error(integrationAccountsData.error.data.errors?.message ?? txt.toasts.commonError);
            }

            setAdditionalRadioFields(integrationAccountsData.data);
            setSelectedAccount(integrationAccountsData.data[0]?.value);
            setIsIntegratedConfirm(true);
            setIsProcessing(false);
        };

        if (isAuthClick) {
            (async () => {
                let createdIntegration = { data: { id: integrationId } };

                if (isCreateMode) {
                    createdIntegration = await dispatch(
                        indexApi.endpoints.addTSIntegration.initiate({
                            data: {
                                isActive: false,
                                name: integrationName,
                                authCredentials: {},
                                templateId,
                                isDraft: true
                            }
                        })
                    );

                    if (!createdIntegration.data?.id) {
                        toast.error('Invalid draft integration id');
                        throw new Error('Invalid draft integration id');
                    }
                }

                const appId =
                    process.env.GO_CLIENT_ID ??
                    '114958966250-vehn5fbbb2rvpos7hkpjbsk0kjigeq4j.apps.googleusercontent.com';
                const url = 'https://accounts.google.com/o/oauth2/auth';
                const redirectUrl = `${window.location.origin}/google-auth-status`;
                const scope = 'https://www.googleapis.com/auth/adwords';

                OAuthPopup = window.open(
                    `${url}?response_type=code&access_type=offline&client_id=${appId}&redirect_uri=${redirectUrl}&state=${createdIntegration.data.id}&scope=${scope}&include_granted_scopes=true&prompt=consent`,
                    '',
                    'popup=true,left=100,top=100,width=565,height=700'
                );

                window.addEventListener('storage', closeOAuthPopup);
            })();
        }

        return () => {
            window.removeEventListener('storage', closeOAuthPopup);
        };
    }, [isAuthClick]);

    return (
        <>
            <div className="traffic-source-integration-modal">
                {Img && <img src={Img} alt="Integration logo" width="144" />}

                <FakeAutocomplete />

                <TextInput
                    dataTest={`traffic-source-integration-modal_field_integration-name`}
                    label={txt.titles.integrationName}
                    value={integrationName}
                    ref={nameInputRef}
                    onFocus={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            setIntegrationName(`${modifiedTemplateName} - `);
                        }
                    }}
                    onBlur={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) setIntegrationName(modifiedTemplateName);
                    }}
                    onChange={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            setIntegrationName(`${modifiedTemplateName} - `);
                        } else setIntegrationName(value);
                    }}
                    className="mb20"
                    labelRequire
                />

                {additionalRadioFields && (
                    <RadioInput
                        label={'Select account'}
                        className="mb15"
                        value={selectedAccount}
                        items={additionalRadioFields}
                        onChange={({ target: { value } }) => setSelectedAccount(value)}
                    />
                )}

                {!isIntegratedConfirm && (isCreateMode || (isEditMode && isIntegrationAccountsError)) && (
                    <div
                        className="traffic-source-integration-modal__auth-btn traffic-source-integration-modal__auth-btn--google"
                        onClick={() => {
                            setIsAuthClick(true);

                            if (OAuthPopup && OAuthPopup.closed) {
                                setIsAuthClick(false);
                                setTimeout(() => {
                                    setIsAuthClick(true);
                                }, 100);
                            }
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 32 32"
                            width="30"
                            height="30"
                        >
                            <defs>
                                <path
                                    id="A"
                                    d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                                />
                            </defs>
                            <clipPath id="B">
                                <use xlinkHref="#A" />
                            </clipPath>
                            <g transform="matrix(.727273 0 0 .727273 -.954545 -1.45455)">
                                <path d="M0 37V11l17 13z" clipPath="url(#B)" fill="#fbbc05" />
                                <path d="M0 11l17 13 7-6.1L48 14V0H0z" clipPath="url(#B)" fill="#ea4335" />
                                <path d="M0 37l30-23 7.9 1L48 0v48H0z" clipPath="url(#B)" fill="#34a853" />
                                <path d="M48 48L17 24l-4-3 35-10z" clipPath="url(#B)" fill="#4285f4" />
                            </g>
                        </svg>
                        Continue with Google
                    </div>
                )}

                {isEditTrafficSourceIntegrationError && (
                    <Alert type="danger" message={getResponseErrorMessage(editTrafficSourceIntegrationError.data)} />
                )}

                {(isEditTrafficSourceIntegrationLoading || isProcessing) && <Loader isFetching />}

                {!isCreateMode && !isIntegrationLoaded && !isIntegrationAccountsLoaded && <Loader isLoad />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={isTouched || (!isIntegratedConfirm && isCreateMode)}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default GoogleIntegrationModal;
