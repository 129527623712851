import React from 'react';
import { ChevronRight } from 'react-feather';

const ButtonNext = ({ onClick, disabled }) => (
    <div
        className={`menu-header-tabs__tab scroll-wrapper__next
            ${disabled ? 'scroll-wrapper__next--disabled' : ''}`}
        onClick={disabled ? null : onClick}
    >
        <span className="icon">
            <ChevronRight size={16} color="white" />
        </span>
    </div>
);

export default ButtonNext;
