import React from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import HintIcon from './HintIcon';

const RadioButton = props => {
    const {
        value,
        selectedValue,
        isChecked,
        label,
        onChange,
        disabled,
        hint,
        hintType,
        isHighlighted,
        dataTest,
        onDisabledClick,
        rightElement,
        errorText
    } = props;
    const txt = Langs[global.lng];

    const handleClick = () => {
        if (!disabled) {
            onChange({ target: { value } });
        } else if (onDisabledClick) {
            onDisabledClick({ target: { value } });
        }
    };

    return (
        <div
            className={cn(
                'radio-container',
                { 'radio-container--highlighted': isHighlighted },
                { 'radio-container--highlighted-active': isHighlighted && selectedValue === value }
            )}
            onClick={handleClick}
        >
            <div className="radio-container__mark-container">
                <input
                    data-test={`${dataTest}_${value}-radio`}
                    type="radio"
                    value={value}
                    onChange={onChange}
                    checked={isChecked ?? selectedValue === value}
                    disabled={disabled}
                />
                <span className="radio-container__mark" />
            </div>
            <div className="j4">
                {value && (
                    <div
                        className={cn('radio-container__label', {
                            'radio-container__label--disabled': disabled && !onDisabledClick,
                            'radio-container__label--shadow': disabled
                        })}
                    >
                        {txt?.labels[label] || label || ''}
                    </div>
                )}
                {hint && <HintIcon className="ml2" hint={txt?.hints[hint] || hint} type={hintType} />}
            </div>
            {rightElement && (
                <div
                    className={cn('radio-container__right-element', {
                        'radio-container__right-element--error': disabled
                    })}
                >
                    {rightElement}
                </div>
            )}
            {errorText && <p className="radio-container__error-text">{errorText}</p>}
        </div>
    );
};

export default RadioButton;
