import React from 'react';
import { NavLink } from 'react-router-dom';

import Langs from '../../../../../Langs';

const HeaderLink = ({ id, label, path }) => {
    const txt = Langs[global.lng];

    return (
        <NavLink to={path} className="menu-header-items__item" activeClassName="menu-header-items__item--active">
            {txt.labels[id] || label}
        </NavLink>
    );
};

export default HeaderLink;
