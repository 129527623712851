import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { changeMainTableActiveRow } from '../../../redux/main-table-slice';

import { useRestoreCampaignsMutation, useMultiRestoreCampaignsMutation } from '../../../redux/api/campaignsApi';

import Loader from '../../Loader';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './restore-campaigns-modal.scss';

const RestoreCampaignsModal = ({
    onClose,
    confirmButtonText,
    title,
    body,
    entity,
    isMulti,
    entityName,
    entityId,
    rowsForReport,
    rows
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const [
        restoreCampaigns,
        {
            isLoading: isRestoreCampaignsLoading,
            isSuccess: isRestoreCampaignsSuccess,
            isError: isRestoreCampaignsError,
            error: restoreCampaignsError
        }
    ] = useRestoreCampaignsMutation();

    useEffect(() => {
        if (isRestoreCampaignsSuccess) {
            hideModal(); // close archive modal
            toast.success(txt.toasts.restored);

            dispatch(
                changeMainTableActiveRow({
                    chapter: 'campaigns',
                    value: null
                })
            );
        }
    }, [isRestoreCampaignsSuccess]);

    useEffect(() => {
        if (isRestoreCampaignsError) {
            hideModal(); // close archive modal
            toast.error(getResponseErrorMessage(restoreCampaignsError.data, txt.errors.restoreError));
        }
    }, [isRestoreCampaignsError]);

    const [
        multiRestoreCampaigns,
        {
            isLoading: isMultiRestoreCampaignsLoading,
            isSuccess: isMultiRestoreCampaignsSuccess,
            isError: isMultiRestoreCampaignsError,
            error: multiRestoreCampaignsError
        }
    ] = useMultiRestoreCampaignsMutation();

    useEffect(() => {
        if (isMultiRestoreCampaignsSuccess) {
            hideModal(); // close archive modal
            toast.success(txt.toasts.restored);

            dispatch(
                changeMainTableActiveRow({
                    chapter: 'campaigns',
                    value: null
                })
            );
        }
    }, [isMultiRestoreCampaignsSuccess]);

    useEffect(() => {
        if (isMultiRestoreCampaignsError) {
            hideModal(); // close archive modal
            toast.error(getResponseErrorMessage(multiRestoreCampaignsError.data, txt.errors.archiveError));
        }
    }, [isMultiRestoreCampaignsError]);

    const onConfirmClick = () => {
        if (isMulti) multiRestoreCampaigns({ data: rowsForReport });
        else restoreCampaigns({ id: entityId });
    };

    return (
        <>
            <div className="archive-campaigns-modal">
                <div className="archive-campaigns-modal__content">
                    {title && <div className="archive-campaigns-modal__title">{title}</div>}

                    {isMulti
                        ? rowsForReport.map(el => (
                              <div key={el}>
                                  {txt.texts.getWillBeRestoredSubtitle(rows.find(it => it.value === el)?.name)}
                              </div>
                          ))
                        : txt.texts.getWillBeRestoredSubtitle(entityName)}
                </div>

                {(isRestoreCampaignsLoading || isMultiRestoreCampaignsLoading) && (
                    <Loader isFetching type={!body ? 'cell' : ''} />
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={confirmButtonText || `Yes, restore ${entity ?? 'element'}`}
                confirmButtonType="danger"
                onConfirm={onConfirmClick}
                onReject={onClose}
                isConfirmDisabled={isRestoreCampaignsLoading || isMultiRestoreCampaignsLoading}
            />
        </>
    );
};

export default RestoreCampaignsModal;
