import cn from 'classnames';
import React from 'react';

import Langs from '../../../../Langs';
import HintIcon from './HintIcon';

const CheckBoxInput = React.memo(props => {
    const txt = Langs[global.lng];

    const {
        label,
        id,
        value: initValue,
        onChange,
        disabled,
        hint,
        hintType,
        isStark,
        className,
        boxStyle,
        isStriped,
        dataTest
    } = props;

    const value = typeof initValue !== 'object' ? initValue : initValue.value;

    const classNames = cn('check-container', { 'check-container--disabled': disabled }, className);

    return (
        <label className={classNames} htmlFor={id}>
            <input
                data-test={dataTest}
                id={id}
                type="checkbox"
                className="check-box-input"
                ref={elem => {
                    if (elem) elem.indeterminate = isStark;
                }}
                value={value}
                onChange={onChange}
                checked={value}
                disabled={disabled}
            />
            <span
                className={cn('check-container__mark', { 'check-container__mark--striped': isStriped })}
                style={boxStyle}
            />
            {label && (
                <>
                    <div className="check-container__label mr5">{txt.checkboxes[label] || label}</div>
                    {hint && <HintIcon hint={txt.hints[hint] || hint} type={hintType} />}
                </>
            )}
        </label>
    );
});

CheckBoxInput.displayName = 'CheckBoxInput';
export default CheckBoxInput;
