import indexApi from './indexApi';

const tagsApiTagTypes = {
    tags: 'tags'
};

const tagsApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(tagsApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getTags: builder.query({
            query: () => `/tags`,
            providesTags: () => [tagsApiTagTypes.tags],
            keepUnusedDataFor: 86400 //24h
        }),

        addTag: builder.mutation({
            query: ({ data }) => ({
                url: `/tags`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [tagsApiTagTypes.tags];
            }
        }),

        editTag: builder.mutation({
            query: ({ id, data }) => ({
                url: `/tags/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [tagsApiTagTypes.tags];
            }
        }),

        deleteTag: builder.mutation({
            query: ({ id }) => ({
                url: `/tags/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [tagsApiTagTypes.tags];
            }
        })
    })
});

export const { useGetTagsQuery, useAddTagMutation, useEditTagMutation, useDeleteTagMutation } = tagsApi;
