import React from 'react';

import Langs from '../../../Langs';
import { getFlowActionRadiosItems } from '../../../redux/operations';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

import '../modals.scss';

const CommonActions = props => {
    const txt = Langs[global.lng];
    const {
        radioValue,
        setRadio,
        flowValue,
        setFlowValue,
        originalSettingsQuantity,
        checkboxValue,
        setCheckboxValue,
        flowsList,
        globalCampaignCheckbox,
        flowCampaignsQuantity
    } = props;

    const isNoCampaignsWereSelected = !globalCampaignCheckbox?.value && !globalCampaignCheckbox?.isStark;
    const isAllCampaignsHaveOriginalSettings = !(flowCampaignsQuantity > originalSettingsQuantity);

    let radioButtons = getFlowActionRadiosItems(
        null,
        isAllCampaignsHaveOriginalSettings ? originalSettingsQuantity : 0
    );
    if (!flowsList.length) radioButtons = radioButtons.filter(item => item.value !== 'change');

    return (
        <div className="archive-flow-modal__common-actions-block">
            <RadioInput
                dataTest="archive-flow-common-block_radio"
                className="archive-flow-modal__common-radio"
                label={txt.labels.archiveCommonActions}
                type="column"
                items={radioButtons}
                value={radioValue}
                onChange={({ target: { value } }) => {
                    if (value === 'original') setCheckboxValue(true);
                    else if (isAllCampaignsHaveOriginalSettings) setCheckboxValue(false);

                    setRadio(value);
                }}
                disabled={isNoCampaignsWereSelected}
            />
            {!!flowsList.length && (
                <SelectInput
                    dataTest="archive-flow-common-block_dropdown"
                    className="archive-flow-modal__common-dropdown"
                    label={null}
                    placeholder={null}
                    value={flowValue}
                    onChange={({ target: { value } }) => setFlowValue(value)}
                    items={flowsList}
                    optionsDirection="bottom"
                    disabled={radioValue !== 'change' || isNoCampaignsWereSelected}
                    isSearchable
                />
            )}

            {originalSettingsQuantity && !isAllCampaignsHaveOriginalSettings ? (
                <CheckBoxInput
                    dataTest="archive-flow-common-block_checkbox"
                    className="archive-flow-modal__common-checkbox"
                    label={txt.jsx.loadOriginalSettings(originalSettingsQuantity)}
                    value={checkboxValue}
                    onChange={({ target: { checked } }) => setCheckboxValue(checked)}
                    disabled={isNoCampaignsWereSelected}
                />
            ) : null}
        </div>
    );
};

export default CommonActions;
