import React from 'react';

import Langs from '../../../Langs';

import { useGetIntegrationPostbackUrlQuery } from '../../../redux/api/integrationsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';
import CopyFieldLabeled from '../../Overall/UI/CopyField/CopyFieldLabeled';

import './facebook-postback-url-modal.scss';

const FacebookPostbackUrkModal = ({ onClose, integrationId }) => {
    const txt = Langs[global.lng];

    const { data: postbackUrl, isSuccess: isPostbackUrlLoaded } = useGetIntegrationPostbackUrlQuery(integrationId);

    const isLoaded = isPostbackUrlLoaded;

    return (
        <>
            <div className="facebook-postback-url-modal">
                {!isLoaded ? (
                    <Loader />
                ) : (
                    <div>
                        <JustAlert type={JUST_ALERT_TYPE.INFO} className="mb20">
                            Here&apos;s your Facebook Postback URL. You can now use this as a &apos;Traffic source
                            postback URL&apos; in the traffic source that you use to track Facebook.
                        </JustAlert>

                        <CopyFieldLabeled field={{ text: postbackUrl?.[0] }} />
                    </div>
                )}
            </div>

            <ModalButtonBar rejectButtonText={txt.buttons.cancel} onReject={onClose} />
        </>
    );
};

export default FacebookPostbackUrkModal;
