import indexApi from './indexApi';

const plansApiTagTypes = {
    plans: 'plans'
};

const plansApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(plansApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getPlans: builder.query({
            query: () => `/plans`,
            providesTags: () => [{ type: plansApiTagTypes.plans, id: 'LIST' }]
        })
    })
});

export const { useGetPlansQuery } = plansApi;
