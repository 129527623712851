import React, { forwardRef } from 'react';

const Alert = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            // stroke={color}
            // strokeWidth="1"
            // strokeLinecap="round"
            // strokeLinejoin="round"
            {...rest}
        >
            <path
                className="st0"
                d="M13.9,11.9L7.8,1.2C7.6,0.9,7.3,0.8,7,0.8c-0.3,0-0.6,0.2-0.8,0.4L0.1,11.9c-0.2,0.3-0.2,0.6,0,0.9
		c0.2,0.3,0.5,0.4,0.8,0.4h12.2c0.3,0,0.6-0.2,0.8-0.4C14,12.5,14,12.2,13.9,11.9L13.9,11.9z M7.9,11.5H6.1V9.7h1.8V11.5L7.9,11.5z
		 M7.9,8.8H6.1V5.2h1.8V8.8L7.9,8.8z"
            />
        </svg>
    );
});

Alert.displayName = 'Alert';

export default Alert;
