export const apiPath = process.env.API_PATH || 'https://qa-pc.plat-clicks.com/api';
// export const apiPath = process.env.API_PATH || 'https://qa-pc-visit.plat-clicks.com/api';

const Store = state => {
    return { store: state };
};

const Dispatch = dispatch => {
    return {
        onUpdateBranch: (data, branch) => dispatch({ type: 'Branch', branch, data }),
        onUpdateCD: (data, branch, chapter) => dispatch({ type: 'CD', branch, chapter, data }),
        onUpdateRank1: (data, branch, chapter, rank1) => dispatch({ type: 'Rank1', branch, chapter, rank1, data }),
        onUpdateRank2: (data, branch, chapter, rank1, rank2) =>
            dispatch({
                type: 'Rank2',
                branch,
                chapter,
                rank1,
                rank2,
                data
            }),
        onUpdateRank3: (data, branch, chapter, rank1, rank2, rank3) =>
            dispatch({
                type: 'Rank3',
                branch,
                chapter,
                rank1,
                rank2,
                rank3,
                data
            })
    };
};

export { Store, Dispatch };
