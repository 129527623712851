import { useState, useEffect, useRef, useCallback } from 'react';

const contextMenuDefaultSettings = {
    isOpen: false,
    position: {
        top: 'auto',
        left: 'auto'
    }
};

const useContextMenu = () => {
    const contextMenuRef = useRef(null);

    const [contextMenuSettings, setContextMenuSettings] = useState(contextMenuDefaultSettings);

    const resetToDefaultContextMenuSettings = () => setContextMenuSettings({ ...contextMenuDefaultSettings });

    useEffect(() => {
        if (contextMenuSettings.isOpen) document.addEventListener('click', resetToDefaultContextMenuSettings);

        return () => document.removeEventListener('click', resetToDefaultContextMenuSettings);
    }, [contextMenuSettings]);

    const onMainTableBodyScroll = () => {
        if (contextMenuSettings.isOpen) resetToDefaultContextMenuSettings();
    };

    const onRowContextMenu = useCallback(
        (e, row, activeRow, onRowClick) => {
            if (e.ctrlKey || !contextMenuRef.current) return;
            e.preventDefault();

            if (row.value !== activeRow?.value) onRowClick(row);

            setTimeout(() => {
                const { offsetWidth: menuWidth, offsetHeight: menuHeight } = contextMenuRef.current;
                const { innerWidth: windowInnerWidth, innerHeight: windowInnerHeight } = window;

                const topPosition = windowInnerHeight - e.clientY < menuHeight ? e.clientY - menuHeight : e.clientY;
                const leftPosition = windowInnerWidth - e.clientX < menuWidth ? e.clientX - menuWidth : e.clientX;

                setContextMenuSettings({
                    isOpen: true,
                    position: {
                        top: topPosition,
                        left: leftPosition
                    }
                });
            }, 0);
        },
        [contextMenuRef.current]
    );

    return [contextMenuRef, contextMenuSettings, onMainTableBodyScroll, onRowContextMenu];
};

export default useContextMenu;
