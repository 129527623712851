export const selectTableConstructor = {
    landers: {
        width: '950px',
        height: '600px',
        filters: ['tags', 'countryTag'],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: '',
                cellClassName: 'select-table-items-body__td--normal'
            },
            {
                key: 'numberOfOffers',
                label: 'Number of offers',
                className: 'select-table-items-header__th--offers-number select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--right'
            },
            /*{
                key: 'tags',
                label: 'Tag',
                className: 'select-table-items-header__th--tag select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--tag',
                isSortingDisabled: true
            },*/
            {
                key: 'countryTag',
                label: 'Country tag',
                className: 'select-table-items-header__th--country select-table-items-header__th--right',
                cellClassName: ''
            }
        ]
    },
    trafficSources: {
        width: '950px',
        height: '600px',
        filters: ['countryTag'],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: '',
                cellClassName: 'select-table-items-body__td--normal'
            },
            {
                key: 'lang',
                label: 'Language',
                className: 'select-table-items-header__th--country select-table-items-header__th--right',
                cellClassName: ''
            }
        ]
    },
    flows: {
        width: '425px',
        height: '600px',
        filters: [],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            }
        ]
    },
    domains: {
        width: '425px',
        height: '600px',
        filters: [],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            },
            {
                key: 'status',
                label: 'DNS',
                className: 'select-table-items-header__th--right select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--tag'
            }
        ]
    },
    affiliateNetworks: {
        width: '425px',
        height: '600px',
        filters: [],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            }
        ]
    },
    offers: {
        width: '950px',
        height: '600px',
        filters: ['tags', 'countryTag'],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--name',
                cellClassName: 'select-table-items-body__td--normal'
            },
            /*{
                key: 'tags',
                label: 'Tag',
                className: 'select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--tag',
                isSortingDisabled: true
            },*/
            {
                key: 'payout',
                label: 'Payout',
                className: 'select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--payout'
            },
            {
                key: 'affiliateNetworkName',
                label: 'Aff. network',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            },
            {
                key: 'countryTag',
                label: 'Country tag',
                className: 'select-table-items-header__th--right',
                cellClassName: ''
            }
        ]
    },
    campaigns: {
        width: '950px',
        height: '600px',
        filters: ['tags', 'countryTag'],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--name',
                cellClassName: 'select-table-items-body__td--normal'
            },
            {
                key: 'group',
                label: 'Group',
                className: 'select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--tag',
                isSortingDisabled: true
            },
            {
                key: 'sources',
                label: 'Traffic Source',
                className: 'select-table-items-header__th--right',
                cellClassName: 'select-table-items-body__td--payout'
            }
        ]
    },
    campaignsForFlow: {
        width: '950px',
        height: '600px',
        // filters: ['possibleValues:trafficSourceId:trafficSourceName', 'possibleValues:rotationId:rotationName'],
        filters: ['possibleValues:trafficSourceId:trafficSourceName', 'rotationValue'],
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--name',
                cellClassName: 'select-table-items-body__td--normal select-table-items-body__td--name'
            },
            {
                key: 'trafficSourceName',
                label: 'Traffic Source',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            },
            {
                key: 'rotationName',
                label: 'Rotation',
                className: 'select-table-items-header__th--left',
                cellClassName: 'select-table-items-body__td--left'
            }
            // {
            //     key: 'rotation',
            //     label: 'Rotation'
            // }
        ]
    },
    notifications: {
        width: '500px',
        height: '450px',
        columns: [
            {
                key: 'id',
                label: 'ID',
                className: 'select-table-items-header__th--id',
                cellClassName: 'select-table-items-body__td--id'
            },
            {
                key: 'name',
                label: 'Name',
                className: 'select-table-items-header__th--name',
                cellClassName: 'select-table-items-body__td--normal'
            },
            {
                key: 'template',
                label: 'Template',
                className: 'select-table-items-header__th--name',
                cellClassName: 'select-table-items-body__td--normal'
            }
        ]
    }
};
