import React from 'react';
import { Redirect } from 'react-router-dom';

import { PRIVATE_ACTIONS, SESSION_INFO_TYPES } from '../../Constants';
import { getSessionStorageUserId, getLocalStorageUserInfo } from '../../Functions/utils';

import { useGetUserSettingsQuery } from '../../redux/api/settingsApi';

const PrivateElement = ({ children, action, forbiddenRoles, to, checkSupport }) => {
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    if (!isUserSettingsLoaded) return null;

    const isForbidden = Array.isArray(forbiddenRoles)
        ? forbiddenRoles.includes(userSettings.role)
        : forbiddenRoles === userSettings.role;

    let isSupport = false;

    if (checkSupport) {
        const userId = getSessionStorageUserId();
        isSupport = !!getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.SUPPORT) || null;
    }

    if (isForbidden || isSupport) {
        switch (action) {
            case PRIVATE_ACTIONS.HIDE:
                return null;

            case PRIVATE_ACTIONS.DISABLE:
                return <div className="not-interactive">{children}</div>;

            case PRIVATE_ACTIONS.REDIRECT:
                return <Redirect to={'/'} />; // TODO: go to 403 page

            case PRIVATE_ACTIONS.REDIRECT_TO:
                if (!to) throw new Error(`Props "to" are required in the action ${PRIVATE_ACTIONS.REDIRECT_TO}`);
                return <Redirect to={to} />;

            default:
                return null;
        }
    } else {
        return <>{children}</>;
    }
};

export default PrivateElement;
