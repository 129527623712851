import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import { produce } from 'immer';

import Langs from '../../Langs';
import { PASSWORD_MIN_LENGTH } from '../../Constants/index';
import { getResponseErrorMessage, saveCredentials } from '../../Functions/utils';
import { transform } from '../../redux/transformers';

import { useLoginMutation, useRegisterInviteMutation } from '../../redux/api/authApi';

import { changeAppIsAuthorized, changeAppIsStartProjectAfterLogIn } from '../../redux/app-slice';

import AuthForm, { AuthBtn } from '../../Modules/AuthForm/AuthForm';
import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';
import CheckBoxInput from '../../Modules/Overall/Forms/Inputs/CheckBoxInput';
import FakeAutocomplete from '../../components/fake-autocomlete/fake-autocomlete';

const PageRegisterInvite = () => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();
    const history = useHistory();
    const { projectId, id } = useParams();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [userData, setUserData] = useState({
        firstname: '',
        lastname: '',
        password: '',
        confirmPassword: '',
        remember: true
    });

    const errors = {
        firstname: !userData.firstname ? txt.errors.required(txt.labels.firstname) : '',
        lastname: !userData.lastname ? txt.errors.required(txt.labels.lastname) : '',
        password:
            userData.password.length < PASSWORD_MIN_LENGTH
                ? txt.errors.min(txt.labels.password, PASSWORD_MIN_LENGTH)
                : '',
        confirmPassword: userData.password !== userData.confirmPassword ? txt.errors.same(txt.labels.password) : ''
    };

    const isAllValid = Object.keys(errors).every(el => !errors[el]);

    const updateUserData = useCallback((prop, value) => {
        setUserData(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const [login, { data: loginData, isSuccess: isLoginSuccess, isError: isLoginError, error: loginError }] =
        useLoginMutation();

    useEffect(() => {
        if (isLoginSuccess) {
            const userEmail = jwt.decode(loginData.access_token)?.sub;
            const credentials = transform.user.import(loginData);

            saveCredentials(credentials, userEmail, userData.remember, () => {
                dispatch(changeAppIsAuthorized(!!credentials.token));
                dispatch(changeAppIsStartProjectAfterLogIn(!!credentials.token));
            });
        }
    }, [isLoginSuccess]);

    useEffect(() => {
        if (isLoginError) {
            toast.error(getResponseErrorMessage(loginError.data, txt.errors.wrongEmailPassword));
            setIsLoading(false);
        }
    }, [isLoginError]);

    const [
        registerInvite,
        {
            data: registerInviteData,
            isSuccess: isRegisterInviteSuccess,
            isError: isRegisterInviteError,
            error: registerInviteError
        }
    ] = useRegisterInviteMutation();

    useEffect(() => {
        if (isRegisterInviteSuccess) {
            const formData = new FormData();

            formData.append('grant_type', 'password');
            formData.append('username', registerInviteData.login);
            formData.append('password', userData.password);
            formData.append('client_id', 'oauth');
            formData.append('client_secret', 'secret');
            formData.append('access_type', 'offline');

            login(formData);
        }
    }, [isRegisterInviteSuccess]);

    useEffect(() => {
        if (isRegisterInviteError) {
            toast.error(getResponseErrorMessage(registerInviteError.data, txt.errors.error));
            setIsLoading(false);
        }
    }, [isRegisterInviteError]);

    const onSubmit = () => {
        setIsSubmitted(true);

        if (isAllValid) {
            setIsLoading(true);
            registerInvite({
                projectId,
                id,
                data: { firstName: userData.firstname, lastName: userData.lastname, password: userData.password }
            });
        }
    };

    return (
        <AuthForm
            title={txt.auth.activateYourInvite}
            questionLabel={txt.auth.alreadyHaveAcc}
            exitLabel={txt.auth.logIn}
            onExit={() => history.push('/login')}
        >
            <FakeAutocomplete />

            <TextInput
                dataTest="register_first-name-input"
                value={userData.firstname}
                onChange={({ target: { value } }) => updateUserData('firstname', value)}
                icon="login"
                label={txt.labels.firstname}
                placeholder={txt.placeholders.firstname}
                error={isSubmitted && errors.firstname}
                disabled={isLoading}
                className="mb20"
            />

            <TextInput
                dataTest="register_last-name-input"
                value={userData.lastname}
                onChange={({ target: { value } }) => updateUserData('lastname', value)}
                icon="login"
                label={txt.labels.lastname}
                placeholder={txt.placeholders.lastname}
                error={isSubmitted && errors.lastname}
                disabled={isLoading}
                className="mb20"
            />

            <TextInput
                dataTest="register_password-input"
                value={userData.password}
                onChange={({ target: { value } }) => updateUserData('password', value)}
                icon="password"
                type="password"
                label={txt.labels.password}
                placeholder={txt.labels.password}
                error={isSubmitted && errors.password}
                disabled={isLoading}
                className="mb20"
            />

            <TextInput
                dataTest="register_confirm-password-input"
                value={userData.confirmPassword}
                onChange={({ target: { value } }) => updateUserData('confirmPassword', value)}
                icon="password"
                type="password"
                label={txt.labels.confirmPassword}
                placeholder={txt.labels.confirmPassword}
                error={isSubmitted && errors.confirmPassword}
                disabled={isLoading}
                className="mb20"
            />

            <CheckBoxInput
                dataTest="register_remember-checkbox"
                label={txt.labels.remember}
                value={userData.remember}
                onChange={({ target: { checked } }) => updateUserData('remember', checked)}
                disabled={isLoading}
            />

            <AuthBtn disabled={isLoading || (isSubmitted && !isAllValid)} onClick={onSubmit} isLoading={isLoading}>
                {txt.auth.createAcc}
            </AuthBtn>
        </AuthForm>
    );
};

export default PageRegisterInvite;
