import React, { useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import { clone, compare, capitalize, camelToKebabCase } from '../Functions/utils';

import { useGetStorageQuery } from '../redux/api/storeApi';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../redux/api/settingsApi';
import { useGetCampaignByIdQuery } from '../redux/api/campaignsApi';
import { useGetTrafficSourceByIdQuery } from '../redux/api/trafficSourcesApi';
import {
    useChangeReportsMutation,
    useGetReportsQuery,
    useGetEntityAllNamesForReportQuery
} from '../redux/api/reportsApi';

import { topMenuConstructor } from '../Modules/Overall/TopMenu/constructors/topMenuConstructor';
import reportTemplate from './page-report/report-template';

import TopMenu from '../Modules/Overall/TopMenu/TopMenu';
import PageReport from './page-report/page-report';
import ScreenConversions from '../Screens/screen-conversions/screen-conversions';

const Report = () => {
    const history = useHistory();
    const { reportValue } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const { groupsItems } = topMenuConstructor;

    const { data: reports } = useGetReportsQuery();
    const { data: storage } = useGetStorageQuery();
    const { data: generalSettings } = useGetGeneralSettingsQuery();
    const { data: userSettings } = useGetUserSettingsQuery();

    const report = reports.find(el => el.value === reportValue);

    if (!report) return <Redirect to={'/'} />;

    const isNeedVariablesLoad = report.filters[0]?.type === 'campaigns' && report.filters[0]?.value?.length === 1;
    const isNeedListLoad = report.filters[0]?.type !== 'reports' && report.filters[0]?.value?.length > 1;

    const { data: campaignData, isSuccess: isCampaignDataLoaded } = useGetCampaignByIdQuery(report.filters[0].value, {
        skip: !isNeedVariablesLoad
    });

    const { data: trafficSourceData, isSuccess: isTrafficSourceDataLoaded } = useGetTrafficSourceByIdQuery(
        campaignData?.trafficSource,
        { skip: !campaignData }
    );

    const { data: entityList, isSuccess: isEntityListLoaded } = useGetEntityAllNamesForReportQuery(
        camelToKebabCase(report.filters[0]?.type),
        {
            skip: !isNeedListLoad
        }
    );

    const parameters = [...groupsItems];

    if (trafficSourceData?.variables && trafficSourceData?.variables.length > 2) {
        const variables = [];

        for (let i = 2; i < trafficSourceData?.variables.length; i++) {
            const parameterId = trafficSourceData?.variables[i].internalToken.replace(/[{}]/gi, '');
            variables.push({ value: parameterId, label: `{${parameterId}}: ${trafficSourceData?.variables[i].name}` });
        }

        parameters.push({ label: 'Variables', items: variables });
    }

    const [changeReportQuery] = useChangeReportsMutation();

    const changeReport = (data, prop) => {
        const result = clone(reports);
        const index = result.findIndex(el => el.value === reportValue);
        if (index === -1) return;

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                result[index][el.prop] = el.value;
            });
        } else {
            result[index][prop] = data;
        }

        changeReportQuery(result);
    };

    const onReportClick = (type, activeRow) => {
        const isTabOpened = generalSettings.openingReport === 'in_focus';

        // TODO: временный фильтр, пока не решится проблема с репортами
        const similarReport = reports
            .filter(el => el.bookmark)
            .find(
                el =>
                    el.filters[0].type === type &&
                    compare(el.filters[0].value, [activeRow.value]) &&
                    moment(el.dateRange.start).diff(report.dateRange.start, 'minutes') === 0 &&
                    moment(el.dateRange.end).diff(report.dateRange.end, 'minutes') === 0
            );

        if (similarReport) {
            if (!similarReport.bookmark.isActive) {
                const result = clone(reports);
                const index = result.findIndex(el => el.value === similarReport.value);
                if (index === -1) return;

                result[index].bookmark.isActive = true;

                changeReport(result);
            }

            history.push(similarReport.bookmark.path);
            return;
        }

        const value = uuid();
        const path = `/reports/${value}`;

        changeReportQuery([
            ...reports,
            {
                ...reportTemplate,
                value,
                dateRange: report.dateRange,
                filters: [{ type, value: [activeRow.value] }],
                groups: [type === 'campaigns' ? 'offers' : 'campaigns'],
                bookmark: {
                    id: value,
                    label: capitalize(activeRow?.name),
                    name: capitalize(activeRow?.name),
                    path,
                    isFavorite: false,
                    isActive: true,
                    icon: type
                }
            }
        ]);

        if (isTabOpened) history.push(path);
    };

    const isMenuLoaded =
        (isNeedVariablesLoad ? isCampaignDataLoaded && isTrafficSourceDataLoaded : true) &&
        (isNeedListLoad ? isEntityListLoaded : true);

    return (
        <>
            <TopMenu
                type="reports"
                reportValue={reportValue}
                isLoading={isLoading || !isMenuLoaded}
                groupsItems={parameters}
                entityList={entityList}
            />

            {report.groups[0] === 'conversions' ? (
                <ScreenConversions
                    chapter="conversions"
                    storage={storage}
                    className="screen-conversions--in-report"
                    setExternalLoading={setIsLoading}
                    reportFilters={report.filters}
                />
            ) : (
                <PageReport
                    report={report}
                    storage={storage}
                    userSettings={userSettings}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    changeReport={changeReport}
                    onReportClick={onReportClick}
                />
            )}
        </>
    );
};

export default Report;
