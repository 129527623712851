import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, USER_ROLES, WORKSPACE_TYPE } from '../../../Constants';
import { compare } from '../../../Functions/utils';
import { getCreatedUpdatedUserNames } from '../../../redux/operations';

import { useGetAffiliateNetworksTemplateListQuery } from '../../../redux/api/templatesApi';
import { useGetDefaultDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useGetAffiliateNetworkByIdQuery } from '../../../redux/api/affiliateNetworksApi';
import { useGetWorkspacesAllNamesQuery } from '../../../redux/api/workspacesApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import ModalTemplates from '../../../components/modal-templates/modal-templates';
import AffiliateNetworkConversionTracking from './components/affiliate-network-conversion-tracking';
import AffiliateNetworkSettings from './components/affiliate-network-settings';
import AffiliateNetworkOptions from './components/affiliate-network-options';
import CreatedUpdatedUserInfo from '../../Overall/CreatedUpdatedUserInfo/CreatedUpdatedUserInfo';
import Loader from '../../Loader';

import affiliateNetworkTemplate from './affiliate-network-template';

import './create-affiliate-network.scss';

const affiliateNetworkInitialTemplate = {
    id: 'custom-affiliate-network',
    templateName: 'Custom Affiliate Network',
    isCustomTemplate: true,
    conversionTracking: {
        clickToken: '',
        clickParameter: '',
        payoutToken: '',
        transactionToken: '',
        eventToken: ''
    }
};

const CreateAffiliateNetwork = ({ id, mode, onConfirm, onTouch, onClose }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const [activeTemplateId, setActiveTemplateId] = useState('custom-affiliate-network');
    const [isLoading, setIsLoading] = useState(!!id);
    const [isFetching, setIsFetching] = useState(false);
    const [isNeedValidate, setIsNeedValidate] = useState(false);

    const [affiliateNetwork, setAffiliateNetwork] = useState({
        ...affiliateNetworkTemplate,
        ...affiliateNetworkInitialTemplate
    });

    const originalEditableAffiliateNetwork = useRef(affiliateNetwork);
    const customAffiliateNetwork = useRef(affiliateNetwork);
    const savedCustomAffiliateNetwork = useRef(affiliateNetwork);

    const updateAffiliateNetwork = useCallback(updatedFields => {
        setAffiliateNetwork(prevAffiliateNetwork => {
            const updatedAffiliateNetwork = { ...prevAffiliateNetwork, ...updatedFields };

            if (prevAffiliateNetwork.isCustomTemplate) {
                customAffiliateNetwork.current = updatedAffiliateNetwork;
            }

            return updatedAffiliateNetwork;
        });
    }, []);

    const { data: templates, isSuccess: isTemplatesLoaded } = useGetAffiliateNetworksTemplateListQuery(undefined, {
        skip: id
    });

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const { data: defaultTrackingDomain, isSuccess: isDefaultTrackingDomainLoaded } =
        useGetDefaultDomainsByTypeQuery('tracking');

    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();

    const { data: affiliateNetworkData, isSuccess: isAffiliateNetworkDataLoaded } = useGetAffiliateNetworkByIdQuery(
        id,
        { skip: !id }
    );

    useEffect(() => {
        (async () => {
            if (isAffiliateNetworkDataLoaded) {
                const createdUpdatedUserNames = await getCreatedUpdatedUserNames(affiliateNetworkData, dispatch);

                const isNeedReplaceWorkspaceId =
                    mode === ENTITY_MODE_STATUS.DUPLICATE &&
                    userSettings.role !== USER_ROLES.OWNER &&
                    affiliateNetworkData.workspaceId === userSettings.publicWorkspaceId;

                const resultAffiliateNetwork = {
                    ...affiliateNetworkData,
                    value: mode === ENTITY_MODE_STATUS.DUPLICATE ? null : affiliateNetworkData.value,
                    workspaceId: isNeedReplaceWorkspaceId
                        ? userSettings.initialWorkspaceId
                        : affiliateNetworkData.workspaceId,
                    ...createdUpdatedUserNames,
                    ...(mode === ENTITY_MODE_STATUS.DUPLICATE ? { name: `${affiliateNetworkData.name} Copy` } : {}),
                    ...(mode === ENTITY_MODE_STATUS.EDIT && affiliateNetworkData.isArchived ? { isRestore: true } : {})
                };

                setAffiliateNetwork(resultAffiliateNetwork);
                originalEditableAffiliateNetwork.current = resultAffiliateNetwork;
                setIsLoading(false);
            }
        })();
    }, [affiliateNetworkData]);

    useEffect(() => {
        if (isUserSettingsLoaded && isWorkspacesListLoaded && !affiliateNetwork.workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            updateAffiliateNetwork({ workspaceId: initialWorkspaceId });

            originalEditableAffiliateNetwork.current = {
                ...originalEditableAffiliateNetwork.current,
                workspaceId: initialWorkspaceId
            };

            savedCustomAffiliateNetwork.current = {
                ...savedCustomAffiliateNetwork.current,
                workspaceId: initialWorkspaceId
            };
        }
    }, [userSettings, workspacesList]);

    const onTemplateClick = template => {
        const resultTemplate = template.isCustomTemplate ? customAffiliateNetwork.current : template;

        setAffiliateNetwork({
            ...affiliateNetworkTemplate,
            ...resultTemplate,
            workspaceId: affiliateNetwork.workspaceId
        });

        setActiveTemplateId(template.id);

        originalEditableAffiliateNetwork.current = {
            ...affiliateNetworkTemplate,
            ...template,
            workspaceId: affiliateNetwork.workspaceId
        };
    };

    const getConfirmButtonText = () => {
        const isHideSaveButtons =
            mode === ENTITY_MODE_STATUS.EDIT &&
            userSettings.role === USER_ROLES.USER &&
            affiliateNetworkData?.workspaceId === userSettings.publicWorkspaceId;

        if (isHideSaveButtons) return null;
        if (mode === ENTITY_MODE_STATUS.EDIT && affiliateNetworkData?.isArchived) return txt.buttons.saveAndRestore;
        if (id) return txt.buttons.save;
        return txt.buttons.apply;
    };

    const isAffiliateNetworkValid = useMemo(() => !!affiliateNetwork.name?.trim(), [affiliateNetwork]);

    useEffect(() => {
        const isCompare =
            compare(affiliateNetwork, originalEditableAffiliateNetwork.current) &&
            compare(savedCustomAffiliateNetwork.current, customAffiliateNetwork.current);

        onTouch(!isCompare);
    }, [affiliateNetwork]);

    const onModalConfirm = async () => {
        setIsNeedValidate(true);

        if (isAffiliateNetworkValid) {
            setIsFetching(true);

            const ok = await onConfirm(affiliateNetwork);
            if (!ok) setIsFetching(false);

            return ok;
        }
    };

    const filteredWorkspaceList = useMemo(() => {
        if (workspacesList && userSettings?.role === USER_ROLES.USER) {
            return workspacesList.map(el => {
                if (el.workspaceType === WORKSPACE_TYPE.PUBLIC) return { ...el, disabled: true };
                return el;
            });
        }

        return workspacesList;
    }, [workspacesList, userSettings]);

    const isLoaded =
        (!id ? isTemplatesLoaded : true) &&
        isDefaultTrackingDomainLoaded &&
        isWorkspacesListLoaded &&
        isUserSettingsLoaded;

    return (
        <>
            <div className="template-modal">
                {isLoading || !isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        {mode === ENTITY_MODE_STATUS.CREATE && (
                            <ModalTemplates
                                templates={[affiliateNetworkInitialTemplate, ...templates]}
                                activeTemplateId={activeTemplateId}
                                onTemplateClick={onTemplateClick}
                            />
                        )}

                        <div className="template-modal__content">
                            <div className="affiliate-network">
                                <AffiliateNetworkSettings
                                    workspaceId={affiliateNetwork.workspaceId}
                                    tags={affiliateNetwork.tags}
                                    name={affiliateNetwork.name}
                                    isNeedValidate={isNeedValidate}
                                    isCustomTemplate={affiliateNetwork.isCustomTemplate}
                                    updateAffiliateNetwork={updateAffiliateNetwork}
                                    workspacesList={filteredWorkspaceList}
                                />

                                <AffiliateNetworkConversionTracking
                                    defaultTrackingDomain={defaultTrackingDomain}
                                    conversionTracking={affiliateNetwork.conversionTracking}
                                    updateAffiliateNetwork={updateAffiliateNetwork}
                                />

                                <AffiliateNetworkOptions
                                    isDoublePostbackAccepted={affiliateNetwork.isDoublePostbackAccepted}
                                    usePostbackStatuses={affiliateNetwork.usePostbackStatuses}
                                    isClickIdAppendToOffer={affiliateNetwork.isClickIdAppendToOffer}
                                    isPostbackIpWhiteListEnabled={affiliateNetwork.isPostbackIpWhiteListEnabled}
                                    ipList={affiliateNetwork.ipList}
                                    conversionTracking={affiliateNetwork.conversionTracking}
                                    postbackStatuses={affiliateNetwork.postbackStatuses}
                                    updateAffiliateNetwork={updateAffiliateNetwork}
                                />
                            </div>
                        </div>
                    </>
                )}

                {isFetching && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={getConfirmButtonText()}
                isConfirmDisabled={isLoading || isFetching}
                leftSettings={
                    id && affiliateNetwork?.createdAtUserId ? (
                        <CreatedUpdatedUserInfo
                            createdUserName={affiliateNetwork.createdUserName}
                            updatedUserName={affiliateNetwork.updatedUserName}
                            createdAt={affiliateNetwork.createdAt}
                            updatedAt={affiliateNetwork.updatedAt}
                        />
                    ) : null
                }
                onConfirm={onModalConfirm}
                onReject={onClose}
            />
        </>
    );
};

CreateAffiliateNetwork.displayName = 'CreateAffiliateNetwork';
export default CreateAffiliateNetwork;
