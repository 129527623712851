import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import Langs from '../../Langs';
import {
    setLastOpenedSecondMenuItem,
    getLastOpenedSecondMenuItem,
    getSessionStorageUserId,
    getLocalStorageUserInfo
} from '../../Functions/utils';
import { PRIVATE_ACTIONS, SESSION_INFO_TYPES, USER_ROLES } from '../../Constants';

import { useGetUserSettingsQuery } from '../../redux/api/settingsApi';

import CustomConversions from '../../Modules/Settings/custom-conversions/custom-conversions';
import DomainsSettings from '../../Modules/Settings/domains-settings/domains-settings';
import GeneralSettings from '../../Modules/Settings/general-settings/general-settings';
import UserSettings from '../../Modules/Settings/UserSettings/user-settings';
// import StatisticsSettings from '../../Modules/Settings/StatisticsSettings/StatisticsSettings';
import TrackingURLs from '../../Modules/Settings/tracking-urls/tracking-urls';
import SecuritySettings from '../../Modules/Settings/security-settings/security-settings';
// import ReferralProgramSettings from '../../Modules/Settings/ReferralProgram/ReferralProgramSettings';
import BillingAndSubscription from '../../Modules/Settings/BillingAndSubscription/BillingAndSubscription';
import PrivateElement from '../../Modules/PrivateElement/PrivateElement';
import SupportSettings from '../../Modules/Settings/support-settings/support-settings';
import Loader from '../../Modules/Loader';
import PageContainer from '../../components/page-container/page-container';
import PageHeader from '../../components/page-header/page-header';
import PageContent from '../../components/page-content/page-content';

const SETTINGS_COMPONENTS = {
    general: GeneralSettings,
    user: ({ isSupportMode }) => <UserSettings isSupportMode={isSupportMode} />,
    // 'referral-program': ReferralProgramSettings,
    security: SecuritySettings,
    // statistics: () => (
    //     <PrivateElement forbiddenRoles={[USER_ROLES.USER]} action={PRIVATE_ACTIONS.REDIRECT}>
    //         <StatisticsSettings />
    //     </PrivateElement>
    // ),
    'tracking-urls': TrackingURLs,
    'billing-and-subscription': () => (
        <PrivateElement forbiddenRoles={[USER_ROLES.USER, USER_ROLES.ADMIN]} action={PRIVATE_ACTIONS.REDIRECT}>
            <BillingAndSubscription />
        </PrivateElement>
    ),
    'custom-conversions': CustomConversions,
    domains: () => (
        <PrivateElement forbiddenRoles={[USER_ROLES.USER]} action={PRIVATE_ACTIONS.REDIRECT}>
            <DomainsSettings />
        </PrivateElement>
    ),
    support: () => (
        <PrivateElement action={PRIVATE_ACTIONS.REDIRECT} checkSupport>
            <SupportSettings />
        </PrivateElement>
    )
};

const SETTINGS_CHAPTERS = [
    'general',
    'user',
    // 'referral-program',
    'security',
    // 'statistics',
    'tracking-urls',
    'billing-and-subscription',
    'custom-conversions',
    'domains',
    'support'
];

const settingsTabConstructor = {
    [USER_ROLES.USER]: ['billing-and-subscription', 'statistics', 'domains'],
    [USER_ROLES.ADMIN]: ['billing-and-subscription'],
    SUPPORT: ['support', 'security']
};

const PageSettings = () => {
    const txt = Langs[global.lng];
    const { chapter } = useParams();
    const history = useHistory();

    const userId = getSessionStorageUserId();
    const supportModeInfo = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.SUPPORT) || null;

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    let settingsChapters = SETTINGS_CHAPTERS.filter(el => !settingsTabConstructor[userSettings?.role]?.includes(el));

    const isCorrectChapter = settingsChapters.includes(chapter);

    if (supportModeInfo) {
        settingsChapters = settingsChapters.filter(el => !settingsTabConstructor.SUPPORT?.includes(el));
    }

    useEffect(() => {
        if (chapter && userSettings && isCorrectChapter) {
            const filteredChapters = settingsChapters.filter(
                el => !settingsTabConstructor[userSettings?.role]?.includes(el)
            );

            if (filteredChapters.includes(chapter)) setLastOpenedSecondMenuItem('settings', chapter);
            else {
                setLastOpenedSecondMenuItem('settings', settingsChapters[0]);
                history.push(`/settings/${settingsChapters[0]}`);
            }
        }
    }, [chapter, userSettings]);

    const SettingsComponent = useMemo(() => SETTINGS_COMPONENTS[chapter], [chapter]) || GeneralSettings;

    if (!isUserSettingsLoaded) return <Loader />;

    if (!chapter || !isCorrectChapter) {
        let lastOpenedItem = getLastOpenedSecondMenuItem('settings');

        if (settingsTabConstructor[userSettings?.role]?.includes(lastOpenedItem)) lastOpenedItem = null;

        return <Redirect to={`/settings/${lastOpenedItem || settingsChapters[0] || '/'}`} />;
    }

    return (
        <PageContainer>
            <PageHeader title={txt.labels.settings} link="settings" items={settingsChapters} />

            <PageContent className={chapter}>
                <SettingsComponent isSupportMode={!!supportModeInfo} />
            </PageContent>
        </PageContainer>
    );
};

export default PageSettings;
