import MultiSelectInput, {
    getMultiSelectData,
    getMultiSelectCount
} from '../../Overall/CriteriaDialog/components/MultiSelectInput/MultiSelectInput';
import SwitcherIsNotPicker from '../../Overall/CriteriaDialog/components/SwitcherIsNotPicker/SwitcherIsNotPicker';
import TimeZone, { getTimeZoneData } from '../../Overall/CriteriaDialog/components/TimeZone/TimeZone';
import TimePicker, { getTimePickerData } from '../../Overall/CriteriaDialog/components/TimePicker/TimePicker';
import DaysOfWeekPicker, {
    getDaysOfWeekData,
    getDaysOfWeekCount
} from '../../Overall/CriteriaDialog/components/DaysOfWeekPicker/DaysOfWeekPicker';
import StepByStepForm, {
    getStepByStepFormData,
    getStepByStepFormCount
} from '../../Overall/CriteriaDialog/components/StepByStepForm/StepByStepForm';
import TextPicker, { getTextData, getTextCount } from '../../Overall/CriteriaDialog/components/TextPicker/TextPicker';

const elementProps = {
    multiSelect: {
        Component: MultiSelectInput,
        getFlatValue: getMultiSelectData,
        getCount: getMultiSelectCount
    },
    stepByStep: {
        getFlatValue: getStepByStepFormData,
        Component: StepByStepForm,
        getCount: getStepByStepFormCount
    },
    weekDays: {
        Component: DaysOfWeekPicker,
        getFlatValue: getDaysOfWeekData,
        getCount: getDaysOfWeekCount
    },
    time: { Component: TimePicker, getFlatValue: getTimePickerData },
    timeZone: { Component: TimeZone, getFlatValue: getTimeZoneData, getCount: 0 },
    switch: { Component: SwitcherIsNotPicker },
    text: {
        Component: TextPicker,
        getFlatValue: getTextData,
        getCount: getTextCount
    }
};

const getCriteriaConstructor = items => [
    {
        key: 'geoCountry',
        label: 'country',
        placeholder: 'selectCountry',
        tabId: 'geo',
        items: items.countries?.filter(country => country.value !== 'GO'),
        isSearchable: true,
        ...elementProps.multiSelect
    },
    {
        key: 'geoStateOrRegion',
        label: 'stateRegion',
        placeholder: 'selectRegion',
        tabId: 'geo',
        suggestUrl: 'dictionaries/cities',
        ...elementProps.multiSelect
    },
    {
        key: 'geoCity',
        label: 'city',
        placeholder: 'selectCity',
        tabId: 'geo',
        suggestUrl: 'dictionaries/cities',
        ...elementProps.multiSelect
    },
    {
        key: 'geoLanguage',
        label: 'language',
        placeholder: 'selectLanguage',
        tabId: 'geo',
        isSearchable: true,
        items: items.languages,
        ...elementProps.multiSelect
    },
    {
        key: 'timeDayOfWeek',
        label: 'dayOfWeek',
        tabId: 'time',
        ...elementProps.weekDays
    },
    {
        key: 'timeTime',
        label: 'time',
        tabId: 'time',
        ...elementProps.time
    },
    {
        key: 'timeTimezone',
        label: 'timeZone',
        tabId: 'time',
        isHidden: true,
        items: items.timezones,
        ...elementProps.timeZone
    },
    {
        key: 'connectionType',
        label: 'connectionType',
        placeholder: 'selectConnectionType',
        tabId: 'connection',
        items: items.connectionTypes,
        ...elementProps.multiSelect
    },
    {
        key: 'connectionCarrier',
        label: 'mobileCarrier',
        placeholder: 'selectMobileCarrier',
        tabId: 'connection',
        suggestUrl: 'dictionaries/mobile-carrier',
        ...elementProps.multiSelect
    },
    {
        key: 'connectionIsp',
        label: 'isp',
        placeholder: 'selectIsp',
        tabId: 'connection',
        suggestUrl: 'dictionaries/isp',
        ...elementProps.multiSelect
    },
    {
        key: 'connectionProxy',
        label: 'proxy',
        placeholder: 'selectProxy',
        tabId: 'connection',
        items: items.connectionProxy,
        ...elementProps.multiSelect
    },
    {
        key: 'deviceType',
        label: 'deviceTypes',
        tabId: 'device',
        placeholder: 'selectDeviceType',
        items: items.deviceTypes,
        ...elementProps.multiSelect
    },
    {
        key: 'deviceBrandAndModel',
        label: 'brandAndModel',
        tabId: 'device',
        items: items.brands,
        ...elementProps.stepByStep
    },
    {
        key: 'deviceOsNameAndVersion',
        label: 'operatingSystemAndVersion',
        tabId: 'device',
        items: items.os || [],
        ...elementProps.stepByStep
    },
    {
        key: 'deviceBrowserNameAndVersion',
        label: 'browserAndVersion',
        tabId: 'device',
        items: items.browsers || [],
        ...elementProps.stepByStep
    },
    {
        key: 'refererReferer',
        label: 'referrer',
        tabId: 'referrer',
        placeholder: 'typeDomain',
        isNotText: ['must contain', 'must not contain'],
        inputLabel: 'includeEmptyReferrer',
        ...elementProps.text
    },
    ...new Array(20).fill(true).map((el, i) => ({
        key: `variableVar${i + 1}`,
        label: `customVariable${i + 1}`,
        tabId: 'variables',
        inputLabel: 'includeEmptyVariable',
        ...elementProps.text
    })),
    // {
    //     key: 'uniqueVisit', // not use
    //     label: 'uniqueVisit',
    //     tabId: 'other',
    //     ...elementProps.switch
    // },
    {
        key: 'otherIp',
        label: 'ipRange',
        placeholder: 'typeIpRange',
        tabId: 'other',
        ...elementProps.text
    }
];

export default getCriteriaConstructor;
