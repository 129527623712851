import React from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import Btn from '../Btn/Btn';

import './buttons-group.scss';

const ButtonsGroup = React.memo(({ value, onChange, className, items }) => {
    const txt = Langs[global.lng];

    return (
        <div className={cn('buttons-group', className)}>
            {items?.map((item, idx) => (
                <Btn
                    dataTest={`buttons-group_${item}`}
                    key={idx}
                    onClick={() => onChange(item)}
                    type={value === item && 'filled'}
                >
                    {txt.labels[item] || item}
                </Btn>
            ))}
        </div>
    );
});

ButtonsGroup.displayName = 'ButtonsGroup';

export default ButtonsGroup;
