import cn from 'classnames';
import React, { useRef, useEffect } from 'react';
import Icon from '../../Icon';

import Tooltip from '../Tooltip/Tooltip';

const Btn = React.forwardRef(
    ({ dataTest, onClick, className, icon, type, children, disabled, hint, isLoading }, ref) => {
        const btnRef = ref || useRef();
        const widthRef = useRef();

        useEffect(() => {
            widthRef.current = btnRef.current?.offsetWidth + 'px';
        }, []);

        const BtnWrapper = React.useMemo(() => (hint ? Tooltip : React.Fragment), []);

        return (
            <BtnWrapper {...(hint ? { message: hint, type: 'bottom-left' } : {})}>
                <button
                    data-test={dataTest}
                    ref={btnRef}
                    onClick={onClick}
                    className={cn('btn', type && 'btn--' + type, { 'btn--icon': icon }, className)}
                    disabled={disabled || isLoading}
                    style={isLoading ? { width: widthRef.current } : {}}
                >
                    {isLoading ? <Icon.Spinner style={{ margin: 'auto' }} size={24} className="mr5" /> : children}
                </button>
            </BtnWrapper>
        );
    }
);

export const AddBtn = ({ isCreateType, children, ...props }) => (
    <Btn {...props}>
        {isCreateType ? <Icon.PlusSquare strokeWidth={1.1} /> : <Icon.Plus strokeWidth={2} />}
        {children}
    </Btn>
);

export const CloseBtn = props => (
    <Btn {...props} type="pure">
        <Icon.X size={16} />
    </Btn>
);

export const EditBtn = props => (
    <Btn {...props} type="icon">
        <Icon.Edit3 size={16} />
    </Btn>
);

Btn.displayName = 'Btn';

export default Btn;
