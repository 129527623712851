import cn from 'classnames';
import React from 'react';

import Langs from '../../../../Langs';
import HintIcon from './HintIcon';

const Label = ({ label, className, style, hint, hintType }) => {
    const txt = Langs[global.lng];

    return (
        <div className={cn('form-label', className)} style={style}>
            <div className="j4 form-label-title">
                <div className="mr5">{txt?.labels[label] || label}</div>
                {hint && <HintIcon hint={txt?.hints[hint] || hint} type={hintType} />}
            </div>
        </div>
    );
};

export default Label;
