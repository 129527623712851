import React, { useState, useEffect } from 'react';

import Langs from '../../../Langs';
import { getIntlFormattedValue } from '../../../Functions/utils';
import useFocus from '../../../Functions/hooks/useFocus';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Alert from '../../Overall/UI/Alert/Alert';

import './weights-optimization-settings-modal.scss';

const WeightsOptimizationSettingsModal = ({
    onClose,
    onConfirm,
    optimizationSettings,
    resultCalculatedValue,
    VISITS_OPTIMIZATION_TYPE,
    VISITS_OPTIMIZATION_ALGORITHM
}) => {
    const txt = Langs[global.lng];
    const [isTouched, setIsTouched] = useState(false);

    const [activeVisitsOptimizationType, setActiveVisitsOptimizationType] = useState(
        optimizationSettings?.visitsOptimizationType ?? VISITS_OPTIMIZATION_TYPE.calculated
    );

    const [activeVisitsOptimizationAlgorithm, setActiveVisitsOptimizationAlgorithm] = useState(
        optimizationSettings?.visitsOptimizationAlgorithm ?? VISITS_OPTIMIZATION_ALGORITHM.last
    );

    const isManual = activeVisitsOptimizationType === VISITS_OPTIMIZATION_TYPE.manual;
    const isLast = activeVisitsOptimizationAlgorithm === VISITS_OPTIMIZATION_ALGORITHM.last;

    const [manualInputRef, setFocus] = useFocus();

    useEffect(() => {
        if (isManual) setFocus();
    }, [isManual]);

    const [manualValue, setManualValue] = useState(optimizationSettings?.visitsOptimizationValue);

    const countVisits = getIntlFormattedValue(isManual ? manualValue ?? '' : resultCalculatedValue);
    const isManualValid = manualValue && manualValue > 0;

    const getManualErrorText = () => {
        if (!manualValue) return txt.errors.required();
        if (manualValue && manualValue <= 0) return txt.errors.incorrectValue;
        return null;
    };

    return (
        <>
            <div className="weights-optimization-settings-modal">
                <div className="j4 mb25">
                    <RadioInput
                        dataTest="weights-optimization-settings-modal_number-of-visits"
                        type="row"
                        label={txt.texts.numberVisitsForOptimization}
                        value={activeVisitsOptimizationType}
                        items={[
                            {
                                label: `Calculated (${getIntlFormattedValue(resultCalculatedValue)} recommended)`,
                                value: VISITS_OPTIMIZATION_TYPE.calculated
                            },
                            { label: 'Manual', value: VISITS_OPTIMIZATION_TYPE.manual }
                        ]}
                        hint={txt.texts.numberVisitsForOptimization}
                        onChange={({ target: { value } }) => setActiveVisitsOptimizationType(value)}
                    />
                    {activeVisitsOptimizationType === VISITS_OPTIMIZATION_TYPE.manual && (
                        <TextInput
                            className="weights-optimization-settings-modal__manual-input"
                            dataTest="weights-optimization-settings-modal_manual-input"
                            value={manualValue}
                            ref={manualInputRef}
                            onChange={({ target: { value } }) => {
                                setManualValue(value.replace(/[^\d]/g, ''));
                                if (isTouched) setIsTouched(false);
                            }}
                            error={isTouched && isManual && getManualErrorText()}
                        />
                    )}
                </div>

                <RadioInput
                    dataTest="weights-optimization-settings-modal_algorithm"
                    type="row"
                    label={txt.texts.algorithm}
                    value={activeVisitsOptimizationAlgorithm}
                    items={[
                        {
                            label: `Last ${countVisits} visits (recommended)`,
                            value: VISITS_OPTIMIZATION_ALGORITHM.last
                        },
                        {
                            label: `Every ${countVisits} visits`,
                            value: VISITS_OPTIMIZATION_ALGORITHM.every
                        }
                    ]}
                    hint={txt.texts.algorithm}
                    onChange={({ target: { value } }) => setActiveVisitsOptimizationAlgorithm(value)}
                    className="mb10"
                />

                <Alert
                    message={
                        isLast
                            ? txt.alerts.weightsOptmizationAlgorithmLast
                            : txt.alerts.weightsOptmizationAlgorithmEvery
                    }
                />
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.close}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={isTouched}
                onConfirm={() => {
                    setIsTouched(true);

                    if (isManual && !isManualValid) return;

                    onConfirm({
                        visitsOptimizationType: activeVisitsOptimizationType,
                        visitsOptimizationAlgorithm: activeVisitsOptimizationAlgorithm,
                        isManual,
                        visitsOptimizationValue: +manualValue > 0 ? +manualValue : null
                    });
                }}
            />
        </>
    );
};

export default WeightsOptimizationSettingsModal;
