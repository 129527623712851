import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getParams, getResponseErrorMessage } from '../../../Functions/utils';
import { getActualDateRange, hideModal } from '../../../redux/operations';
import { exportDateTransformer } from '../../../redux/transformers';

import { useClearCampaignStatisticsMutation } from '../../../redux/api/campaignsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import DateRangeInput from '../../Overall/Forms/Inputs/DateRangeInput';

import './clear-statistics-modal.scss';

const ClearModal = ({ onClose, dateRange: initialDateRange, activeRow }) => {
    const txt = Langs[global.lng];

    const [dateRange, setDateRange] = useState(initialDateRange);

    const [statisticsValue, setStatisticsValue] = useState('period');

    const [
        clearCampaignStatistics,
        {
            isLoading: isClearCampaignStatisticsLoading,
            isSuccess: isClearCampaignStatisticsSuccess,
            isError: isClearCampaignStatisticsError,
            error: clearCampaignStatisticsError
        }
    ] = useClearCampaignStatisticsMutation();

    useEffect(() => {
        if (isClearCampaignStatisticsSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isClearCampaignStatisticsSuccess]);

    useEffect(() => {
        if (isClearCampaignStatisticsError)
            toast.error(getResponseErrorMessage(clearCampaignStatisticsError.data, txt.toasts.saveError));
    }, [isClearCampaignStatisticsError]);

    const onConfirm = () => {
        const { start, end } = getActualDateRange(
            statisticsValue === 'allData' ? { start: activeRow?.createdAt, end: moment().format() } : dateRange
        );

        const parameters = {
            'filter[dateFrom]': exportDateTransformer(start),
            'filter[dateTo]': exportDateTransformer(end)
        };

        clearCampaignStatistics({ id: activeRow.value, params: getParams(parameters) });
    };

    const clearTypes = [
        { value: 'allData', label: txt.labels.allData },
        {
            value: 'period',
            label: txt.labels.period,
            component: (
                <DateRangeInput
                    containerClassName="ml15"
                    value={dateRange}
                    onChange={setDateRange}
                    hideArrows
                    isCompareHidden
                    isUseAbs
                    disabled={statisticsValue === 'allData' || isClearCampaignStatisticsLoading}
                />
            )
        }
    ];

    return (
        <>
            <div className="clear-statistics-modal">
                <div className="clear-statistics-modal__content">
                    <RadioInput
                        items={clearTypes}
                        value={statisticsValue}
                        disabled={isClearCampaignStatisticsLoading}
                        onChange={({ target: { value } }) => setStatisticsValue(value)}
                    />
                </div>
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.clearStat}
                onConfirm={onConfirm}
                confirmButtonType="danger"
                onReject={onClose}
                isLoading={isClearCampaignStatisticsLoading}
            />
        </>
    );
};

export default ClearModal;
