import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const notShowErrorEndpoints = [
    'getSharedReportStats',
    'getTrafficSourceIntegrationCampaignList',
    'loginAsSupport',
    'confirmUserSettingsEmail',
    'checkEmailCode',
    'checkPromocode'
];

const rtkQueryErrorLogger = () => next => action => {
    if (isRejectedWithValue(action) && action?.meta?.baseQueryMeta?.request?.method === 'GET') {
        if (notShowErrorEndpoints.includes(action?.meta?.arg?.endpointName)) return next(action);

        toast.error('An error occurred while loading data');
    }

    return next(action);
};

export default rtkQueryErrorLogger;
