import React, { useEffect, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../../../Constants';
import { FACEBOOK_OAUTH_KEY } from '../../../../../Functions/checkOAuthStatus';
import { hideModal } from '../../../../../redux/operations';
import useFocus from '../../../../../Functions/hooks/useFocus';
import { getResponseErrorMessage } from '../../../../../Functions/utils';

import indexApi from '../../../../../redux/api/indexApi';
import {
    useGetTSIntegrationByIdQuery,
    useEditTSIntegrationMutation,
    useGetIntegrationAccountsQuery
} from '../../../../../redux/api/integrationsApi';

import { getIntegrationIcon } from '../../../../Tools/integrations/get-integration-icon';

import ModalButtonBar from '../../../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../../../Overall/Forms/Inputs/TextInput';
import Alert from '../../../../Overall/UI/Alert/Alert';
import Loader from '../../../../Loader';
import FakeAutocomplete from '../../../../../components/fake-autocomlete/fake-autocomlete';
import RadioInput from '../../../../Overall/Forms/Inputs/RadioInput';

const FacebookIntegrationModal = ({ templateName, templateId, integrationId, mode, onClose, onTouch, onChange }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const [nameInputRef, setNameFocus] = useFocus();
    const [isTouched, setIsTouched] = useState(false);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isFacebookAuthClick, setIsFacebookAuthClick] = useState(false);
    const [isIntegratedConfirm, setIsIntegratedConfirm] = useState(templateName !== 'Facebook');
    const [additionalRadioFields, setAdditionalRadioFields] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [pixelId, setPixelId] = useState('');
    const [eventSourceUrl, setEventSourceUrl] = useState('');
    const [conversionApiKey, setConversionApiKey] = useState('');

    const savedIntegrationId = useRef(null);

    let OAuthPopup;

    const isCreateMode = mode === ENTITY_MODE_STATUS.CREATE;
    const isEditMode = mode === ENTITY_MODE_STATUS.EDIT;

    const [integrationName, setIntegrationName] = useState(templateName);

    const { data: integration, isSuccess: isIntegrationLoaded } = useGetTSIntegrationByIdQuery(integrationId, {
        skip: !integrationId || mode === ENTITY_MODE_STATUS.CREATE
    });

    useEffect(() => {
        if (isIntegrationLoaded) {
            setIntegrationName(integration?.name);
            setSelectedAccount(integration.authCredentials?.accountId);
        }
    }, [integration]);

    const {
        data: integrationAccounts,
        isSuccess: isIntegrationAccountsLoaded,
        isError: isIntegrationAccountsError
    } = useGetIntegrationAccountsQuery(integrationId, {
        skip: mode === ENTITY_MODE_STATUS.CREATE
    });

    useEffect(() => {
        if (isIntegrationAccountsLoaded) {
            setAdditionalRadioFields(integrationAccounts);
        }
    }, [integrationAccounts]);

    const [
        editTrafficSourceIntegration,
        {
            isLoading: isEditTrafficSourceIntegrationLoading,
            isError: isEditTrafficSourceIntegrationError,
            error: editTrafficSourceIntegrationError,
            isSuccess: isEditTrafficSourceIntegrationSuccess
        }
    ] = useEditTSIntegrationMutation();

    useEffect(() => {
        if (isEditTrafficSourceIntegrationSuccess) {
            toast.success(txt.toasts.saved);

            if (onChange) {
                onChange({
                    integrationId: savedIntegrationId.current || integrationId,
                    isIntegration: true
                });
            }

            hideModal();
        }
    }, [isEditTrafficSourceIntegrationSuccess]);

    useEffect(() => {
        if (mode === ENTITY_MODE_STATUS.CREATE) setTimeout(setNameFocus, 0);
    }, []);

    const onConfirm = () => {
        setIsTouched(true);

        const queryData = {
            id: savedIntegrationId.current || integrationId,
            data: {
                isActive: true,
                name: integrationName,
                templateId,
                authCredentials: {
                    accountId: selectedAccount,
                    ...(pixelId ? { pixelId } : {}),
                    ...(eventSourceUrl ? { eventSourceUrl } : {}),
                    ...(conversionApiKey ? { conversionApiKey } : {})
                },
                isDraft: false
            }
        };

        editTrafficSourceIntegration(queryData);
    };

    const Img = useMemo(() => getIntegrationIcon(templateName), [templateName]);

    useEffect(() => {
        const closeOAuthPopup = async event => {
            if (event.key === FACEBOOK_OAUTH_KEY) OAuthPopup?.close();
            else return;

            setIsProcessing(true);

            const data = JSON.parse(localStorage.getItem(FACEBOOK_OAUTH_KEY));
            savedIntegrationId.current = data.integrationId;
            localStorage.removeItem(FACEBOOK_OAUTH_KEY);

            if (!data.isOk) {
                setIsProcessing(false);
                setIsFacebookAuthClick(false);
                return toast.error(data.error ? `${data.error} (${data.reason})` : txt.toasts.commonError);
            }

            const codeCheckStatus = await dispatch(
                indexApi.endpoints.checkIntegrationOAuthCode.initiate({
                    id: data.integrationId,
                    code: data.code
                })
            );

            if (codeCheckStatus.isError) {
                return toast.error(codeCheckStatus.error.data.errors?.message ?? txt.toasts.commonError);
            }

            const integrationAccountsData = await dispatch(
                indexApi.endpoints.getIntegrationAccounts.initiate(data.integrationId)
            );

            if (integrationAccountsData.isError) {
                return toast.error(integrationAccountsData.error.data.errors?.message ?? txt.toasts.commonError);
            }

            setAdditionalRadioFields(integrationAccountsData.data);
            setSelectedAccount(integrationAccountsData.data[0]?.value);
            setIsIntegratedConfirm(true);
            setIsProcessing(false);
        };

        if (isFacebookAuthClick) {
            (async () => {
                let createdIntegration = { data: { id: integrationId } };

                if (isCreateMode) {
                    createdIntegration = await dispatch(
                        indexApi.endpoints.addTSIntegration.initiate({
                            data: {
                                isActive: false,
                                name: integrationName,
                                authCredentials: {},
                                templateId,
                                isDraft: true
                            }
                        })
                    );

                    if (!createdIntegration.data?.id) {
                        toast.error('Invalid draft integration id');
                        throw new Error('Invalid draft integration id');
                    }

                    onTouch(true);
                }

                const appId = process.env.FB_CLIENT_ID ?? 1285205519000788;
                const url = 'https://www.facebook.com/v15.0/dialog/oauth';
                const redirectUrl = process.env.FB_REDIRECT_URI ?? window.location.origin + '/facebook-auth-status';
                const scope = 'ads_management';

                OAuthPopup = window.open(
                    `${url}?client_id=${appId}&redirect_uri=${redirectUrl}&state=${createdIntegration.data.id}&scope=${scope}&display=popup`,
                    '',
                    'popup=true,left=100,top=100,width=565,height=700'
                );

                window.addEventListener('storage', closeOAuthPopup);
            })();
        }

        return () => {
            window.removeEventListener('storage', closeOAuthPopup);
        };
    }, [isFacebookAuthClick]);

    return (
        <>
            <div className="traffic-source-integration-modal">
                {Img && <img src={Img} alt="Integration logo" width="144" />}

                <FakeAutocomplete />

                <TextInput
                    dataTest={`traffic-source-integration-modal_field_integration-name`}
                    label={txt.titles.integrationName}
                    value={integrationName}
                    ref={nameInputRef}
                    onFocus={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            setIntegrationName(`${templateName} - `);
                        }
                    }}
                    onBlur={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) setIntegrationName(templateName);
                    }}
                    onChange={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            setIntegrationName(`${templateName} - `);
                        } else setIntegrationName(value);
                    }}
                    className="mb20"
                    labelRequire
                />

                {additionalRadioFields && (
                    <>
                        <RadioInput
                            label={'Select account'}
                            className="mb15"
                            value={selectedAccount}
                            items={additionalRadioFields}
                            onChange={({ target: { value } }) => setSelectedAccount(value)}
                        />

                        <div className="mb15">Send s2s postback to Facebook with Convesion API</div>

                        <TextInput
                            dataTest={`traffic-source-integration-modal_field_pixel-id`}
                            label={'Pixel ID'}
                            value={pixelId}
                            onChange={({ target: { value } }) => setPixelId(value)}
                            className="mb10"
                        />

                        <TextInput
                            dataTest={`traffic-source-integration-modal_field_event-source-url`}
                            label={'Event source URL'}
                            value={eventSourceUrl}
                            onChange={({ target: { value } }) => setEventSourceUrl(value)}
                            className="mb10"
                        />

                        <TextInput
                            dataTest={`traffic-source-integration-modal_field_conversion-api-key`}
                            label={'Conversion API key'}
                            value={conversionApiKey}
                            onChange={({ target: { value } }) => setConversionApiKey(value)}
                            className="mb15"
                        />
                    </>
                )}

                {!isIntegratedConfirm && (isCreateMode || (isEditMode && isIntegrationAccountsError)) && (
                    <div
                        className="traffic-source-integration-modal__auth-btn traffic-source-integration-modal__auth-btn--facebook"
                        onClick={() => {
                            setIsFacebookAuthClick(true);

                            if (OAuthPopup && OAuthPopup.closed) {
                                setIsFacebookAuthClick(false);
                                setTimeout(() => {
                                    setIsFacebookAuthClick(true);
                                }, 100);
                            }
                        }}
                    >
                        <svg width="30" height="30" fill="none" aria-labelledby="fb-logo">
                            <path
                                d="M30 15.091C30 6.756 23.285 0 15 0S0 6.756 0 15.091C0 22.625 5.484 28.868 12.656 30V19.454H8.848V15.09h3.808v-3.324c0-3.782 2.239-5.872 5.666-5.872 1.64 0 3.358.295 3.358.295v3.714h-1.893c-1.863 0-2.443 1.164-2.443 2.358v2.83h4.16l-.665 4.362h-3.495V30C24.516 28.868 30 22.625 30 15.091z"
                                fill="currentColor"
                            />
                        </svg>
                        Continue with Facebook
                    </div>
                )}

                {isEditTrafficSourceIntegrationError && (
                    <Alert type="danger" message={getResponseErrorMessage(editTrafficSourceIntegrationError.data)} />
                )}

                {(isEditTrafficSourceIntegrationLoading || isProcessing) && <Loader isFetching />}

                {!isCreateMode && !isIntegrationLoaded && !isIntegrationAccountsLoaded && <Loader isLoad />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={isTouched || (!isIntegratedConfirm && isCreateMode)}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default FacebookIntegrationModal;
