import React from 'react';
import * as Icon from 'react-feather';

import SelectInput from '../../Forms/Inputs/SelectInput/SelectInput';
import Btn from '../Btn/Btn';

import './table-pagination.scss';

const ITEMS_PER_PAGE = [50, 100, 200];

const TablePagination = props => {
    const { value = {}, onChange = () => null, disabled } = props;
    const { page = 1, pages = 150 } = value;

    const perPage = value.perPage ?? value['per-page'];

    const items = Array.from(Array(pages).keys()).map(x => x + 1);

    const handleChange = (key, val) => {
        onChange({ ...value, [key]: val });
    };

    const toggleDec = () => {
        if (+page > items[0]) handleChange('page', +page - 1);
    };

    const toggleInc = () => {
        if (+page < pages) handleChange('page', +page + 1);
    };

    return (
        <div className="table-pagination">
            <Btn
                dataTest="table-pagination_chevron-left-btn"
                icon="icon"
                className="table-pagination__btn mr5"
                onClick={toggleDec}
                disabled={disabled || page === 1}
            >
                <Icon.ChevronLeft className="table-pagination__icon" />
            </Btn>
            <Btn
                dataTest="table-pagination_chevron-right-btn"
                icon="icon"
                className="table-pagination__btn mr5"
                onClick={toggleInc}
                disabled={disabled || page === pages}
            >
                <Icon.ChevronRight className="table-pagination__icon" />
            </Btn>
            <SelectInput
                dataTest="table-pagination_page-select-input"
                className="table-pagination__pages"
                onChange={({ target: { value: val } }) => handleChange('page', val)}
                value={page}
                items={items}
                disabled={disabled}
            />
            <div className="table-pagination__total">/ {pages}</div>
            <SelectInput
                dataTest="table-pagination_per-page-select-input"
                className="table-pagination__in-page"
                onChange={({ target: { value: val } }) => {
                    onChange({ ...value, 'per-page': val, perPage: val, page: 1 });
                }}
                value={perPage}
                items={ITEMS_PER_PAGE}
                disabled={disabled}
            />
        </div>
    );
};

export default TablePagination;
