import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { getParams } from '../../Functions/utils';
import { getActualDateRange } from '../../redux/operations';
import { HIDDEN_PROFIT_FILTER_METRICS } from '../../Constants';
import { useGetChartDataQuery } from '../../redux/api/dashboardApi';

import Charts from '../Charts/Charts';
import Loader from '../Loader';

const DashboardChart = React.memo(({ dateRange, isHideProfit, isFetching, isStartRefresh, onRefreshed }) => {
    const [metrics, setMetrics] = useState(['clicks', 'conversions']);

    const actualDateRange = getActualDateRange(dateRange);
    const { start, end } = actualDateRange;
    const parameters = {
        'filter[dateFrom]': moment(start).format('YYYY-MM-DDTHH:mm'),
        'filter[dateTo]': moment(end).format('YYYY-MM-DDTHH:mm')
    };
    const queryParameters = getParams(parameters);

    const onChangeChart = useCallback(data => setMetrics(data), []);

    const {
        data: chartData,
        isLoading,
        isFetching: isFetchingChartData,
        refetch
    } = useGetChartDataQuery(queryParameters);

    useEffect(() => {
        if (isStartRefresh) {
            refetch();
            onRefreshed();
        }
    }, [isStartRefresh]);

    if (isLoading) return <Loader />;

    const { datasets, labels } = chartData || {};

    const currentDatasets = !isHideProfit
        ? datasets
        : datasets?.filter(dataset => !HIDDEN_PROFIT_FILTER_METRICS.includes(dataset?.label.toLowerCase()));

    const isComponentFetching = isFetching || isFetchingChartData;

    return (
        <>
            <Charts
                datasets={currentDatasets}
                labels={labels}
                onChange={onChangeChart}
                type="dashboard"
                metrics={metrics}
            />

            {isComponentFetching && <Loader isFetching={isComponentFetching} />}
        </>
    );
});

export default DashboardChart;
