import React, { useEffect } from 'react';

import Langs from '../../Langs';
import { METRIC_COLOR } from '../../Constants';
import { getActualDateRange } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';
import { getIntlFormattedValue, getParams } from '../../Functions/utils';
import { useGetIndicatorsQuery } from '../../redux/api/dashboardApi';

import Indicator from '../Overall/UI/Indicator/Indicator';
import Loader from '../Loader';

const Indicators = React.memo(({ dateRange, isHideProfit, isFetching, isStartRefresh, onRefreshed }) => {
    const txt = Langs[global.lng];

    const actualDateRange = getActualDateRange({
        start: dateRange.start,
        end: dateRange.end,
        intervalKey: dateRange.intervalKey,
        compare: true
    });

    const parameters = {
        'filter[dateFrom]': exportDateTransformer(actualDateRange.start),
        'filter[dateTo]': exportDateTransformer(actualDateRange.end),
        'filter[compareDateFrom]': exportDateTransformer(actualDateRange.startPrevDate),
        'filter[compareDateTo]': exportDateTransformer(actualDateRange.endPrevDate)
    };

    const queryParameters = getParams(parameters);

    const { data: indicators, isFetching: isFetchingIdicators, refetch } = useGetIndicatorsQuery(queryParameters);

    const isComponentFetching = isFetching || isFetchingIdicators;

    useEffect(() => {
        if (isStartRefresh) {
            refetch();
            onRefreshed();
        }
    }, [isStartRefresh]);

    const { visits, clicks, conversions, revenue, cost, roi, profit } = indicators?.indicators ?? 0;

    return (
        <div className="dashboard-charts__indicators-wrapper">
            <div className="dashboard-charts__indicators dashboard-charts__indicators--top">
                <div className="dashboard-wrapper">
                    <Indicator
                        label={txt.labels.visits}
                        change={visits?.change}
                        value={visits?.value}
                        isInteger
                        color={METRIC_COLOR.visits}
                    />
                    <Indicator
                        label={txt.labels.clicks}
                        change={clicks?.change}
                        value={clicks?.value}
                        isInteger
                        color={METRIC_COLOR.clicks}
                    />
                    <Indicator
                        label={txt.labels.conversions}
                        change={conversions?.change}
                        value={conversions?.value}
                        isInteger
                        color={METRIC_COLOR.conversions}
                        suffix={`${getIntlFormattedValue(conversions?.percent ?? 0, 2)}%`}
                    />
                </div>
            </div>

            <div className="dashboard-charts__indicators dashboard-charts__indicators--bottom">
                {!isHideProfit && (
                    <div className="dashboard-wrapper">
                        <Indicator
                            label={txt.labels.revenue}
                            change={revenue?.change}
                            value={revenue?.value}
                            prefix="$"
                            color={METRIC_COLOR.revenue}
                        />
                    </div>
                )}
                <div className="dashboard-wrapper">
                    <Indicator
                        label={txt.labels.cost}
                        change={cost?.change}
                        value={cost?.value}
                        prefix="$"
                        color={METRIC_COLOR.cost}
                    />
                </div>
                <div className="dashboard-wrapper">
                    <Indicator
                        label={txt.labels.roi}
                        change={roi?.change}
                        value={roi?.value}
                        units="%"
                        color={METRIC_COLOR.roi}
                    />
                </div>
                {!isHideProfit && (
                    <div className="dashboard-wrapper">
                        <Indicator
                            label={txt.labels.profit}
                            change={profit?.change}
                            value={profit?.value}
                            prefix="$"
                            color={METRIC_COLOR.profit}
                        />
                    </div>
                )}
            </div>

            {isComponentFetching && <Loader isFetching={isComponentFetching} />}
        </div>
    );
});

export default Indicators;
