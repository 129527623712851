/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import { USER_ROLES } from '../../Constants';
import { setLastOpenedSecondMenuItem, getLastOpenedSecondMenuItem } from '../../Functions/utils';

import { useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetStorageQuery } from '../../redux/api/storeApi';

import Workspaces from '../../Modules/Users/workspaces/workspaces';
import Users from '../../Modules/Users/users/users';
import Invitations from '../../Modules/Users/invitations/invitations';
import PageContainer from '../../components/page-container/page-container';
import PageContent from '../../components/page-content/page-content';
import PageHeaderLinks from '../../components/page-header-links/page-header-links';
import Loader from '../../Modules/Loader';

import './page-users.scss';

const USERS_COMPONENTS = {
    users: Users,
    invitations: Invitations, // simple-main-table compact-main-table--important-single-cell
    workspaces: Workspaces
};

const USERS_CHAPTERS = ['users', 'invitations', 'workspaces'];

const usersTabConstructor = {
    [USER_ROLES.USER]: ['invitations', 'workspaces']
};

const PageUsers = () => {
    const { chapter } = useParams();
    const history = useHistory();

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();

    const usersChapters = USERS_CHAPTERS.filter(el => {
        return !usersTabConstructor[userSettings?.role]?.includes(el);
    });

    const isCorrectChapter = usersChapters.includes(chapter);

    useEffect(() => {
        if (chapter && userSettings && isCorrectChapter) {
            const filteredChapters = usersChapters.filter(el => !usersTabConstructor[userSettings?.role]?.includes(el));

            if (filteredChapters.includes(chapter)) setLastOpenedSecondMenuItem('users', chapter);
            else {
                setLastOpenedSecondMenuItem('users', usersChapters[0]);
                history.push(`/users/${usersChapters[0]}`);
            }
        }
    }, [chapter, userSettings]);

    const UsersComponent = useMemo(() => USERS_COMPONENTS[chapter], [chapter]);

    if (!isUserSettingsLoaded || !isStorageLoaded) return <Loader />;

    if (!chapter || !isCorrectChapter) {
        let lastOpenedItem = getLastOpenedSecondMenuItem('users');

        if (usersTabConstructor[userSettings?.role]?.includes(lastOpenedItem)) lastOpenedItem = null;

        return <Redirect to={`/users/${lastOpenedItem || usersChapters[0] || '/'}`} />;
    }

    return (
        <PageContainer className="page-users">
            <div className="page-users__header">
                <PageHeaderLinks link="users" items={usersChapters} />
            </div>

            <PageContent>
                <UsersComponent chapter={chapter} storage={storage} />
            </PageContent>
        </PageContainer>
    );
};

export default PageUsers;
