import React from 'react';
import { LANDER_AND_OFFER_OPTIMIZATION_LIMIT } from './Constants/index';
import { capitalize } from './Functions/utils';

const Langs = {
    en: {
        auth: {
            platClicks: 'Plat Clicks',
            createYourAcc: 'Create your account',
            createYourAccPageTitle: 'Create Your Account | Plat.com',
            logInTitle: 'Log in to your account',
            logInTitlePageTitle: 'Log in to Your Account | Plat.com',
            activateYourInvite: 'Activate your invite',
            createAcc: 'Create account',
            passwordReset: 'Password Reset',
            sendPwdResetLink: 'Send password reset link',
            alreadyHaveAcc: 'Already have an account?',
            dontHaveAcc: "Don't have an account?",
            signIn: 'Sign In',
            logIn: 'Log In',
            logOut: 'Log Out',
            profile: 'Profile',
            signUp: 'Sign Up',
            login: 'Login',
            trackNameHelpsText:
                "Sign up now for a 14-day free trial! Track, enhance, and automate all your traffic sources and networks through Plat's performance-driven ad tracking software.",
            passwordResetSuccess: 'Email with reset password instructions has been sent to',
            forgotPass: 'Forgot password?',
            changePass: 'Change password',
            typeNewPass: 'Type your new password',
            termsText: 'By signing up, I agree with',
            termsLink: 'terms and conditions'
        },
        settings: {
            profileInfo: 'Profile information',
            email: 'Email',
            password: 'Password',
            confirmWithPassword: 'Confirm with Password',
            disabled: 'DISABLED',
            examples: 'Examples',
            example: 'Example',
            forExample: 'for example',
            note: 'Note',
            s2sPostbackUrl: (
                <>
                    Create a new affiliate network element in Plat. Provide the specific click ID token used by your
                    affiliate network. The &quot;replace&quot; field will automatically update with the provided token.
                    For instance, if the click ID used by your affiliate network is <b>&quot;sub 1&quot;</b>, your
                    postback URL will be:
                </>
            ),
            s2sPostbackUrlMultipleParameters: (
                <>
                    You can also track your payout, transaction ID, and event type. Fill in the fields with the
                    corresponding tokens provided by your affiliate network. The &quot;optional&quot; field will
                    automatically update with the provided tokens. Your postback URL will be:
                </>
            ),
            learnMore: 'Learn more',
            registration: 'registration',
            event: 'event',
            conversionTrackingPixelInfo: (
                <>
                    If you need to track <b>Payout</b>, <b>Transaction ID</b>, or <b>Event</b> type parameters, fill in
                    those fields with the corresponding tokens provided by your affiliate network. The&nbsp;
                    <b className="settings-section--accent">“OPTIONAL”</b>&nbsp;fields in the conversion tracking pixel
                    will be replaced with the specified tokens.
                    <br />
                    The <b>Click ID</b> value is stored in a cookie, so conversion tracking will still work even without
                    specifying this parameter.
                </>
            ),
            landingPageProtectionInfo: (
                <>
                    You can use Lander Protection Script to block all the visits to your landers, that did not came
                    through a campaign URL. This option will also allow you to block spying tools, that try to access
                    your landers.
                    <br />
                    Enable “Protect Landing page” option, when creating a Lander element in Plat to use this feature.
                    <div className="mt8">
                        <b>Note: </b>
                        <span className="settings-section--accent">
                            Lander Protection Script only works with Redirect tracking method.
                        </span>
                    </div>
                </>
            ),
            customConversionsAlert: (
                <>
                    <b>Note: </b>After copying the postback URL / tracking pixel, you need to replace the{' '}
                    <b>OPTIONAL</b> placeholder with a conversion type-specific token supported by your affiliate
                    network.
                </>
            ),
            customConversionEvent: (
                <>
                    Set a name for the custom conversion event and corresponding value(s) of the <b>et</b> postback
                    parameter. The custom conversion event name will be displayed in reports. Each parameter’s value
                    should be added in a separate line.
                </>
            ),
            customConversionEventExample: (
                <>
                    <b>Example:</b>
                    <br />
                    For the <b>Sign up</b> custom conversion event with the parameter’s values such as <b>signup</b> and{' '}
                    <b>registration</b>, every postback sent with
                    <b className="color--froly"> &et=</b>
                    <b>signup</b> or <b className="color--froly">&et=</b>
                    <b>registration</b> will be counted as a new conversion. Those conversions will be categorized as
                    the <b>Sign up</b> event.
                </>
            )
        },
        labels: {
            exportOnlySelectedRowsLabel: quantity => <span>Only checked rows ({quantity})</span>,
            getShowForCtrlC: () => (
                <span>
                    Show for <b>Ctrl(Command)+C</b> hotkey
                </span>
            ),
            required: 'Required',
            searchByPartOfValue: 'Search by part of value',
            searchByFullValue: 'Search by full value',
            users: 'Users',
            inviteUser: 'Invite user',
            editUser: 'Edit user',
            collapseAll: 'Collapse all',
            uncollapseAll: 'Uncollapse all',
            hideProfit: 'Hide profit',
            role: 'Role',
            assignPrivateWorkspace: 'Assign private workspace',
            name: 'Name',
            yourName: 'Your name',
            preview: 'Preview',
            notes: 'Notes',
            tracking: 'Tracking',
            trackingDomain: 'Tracking domain',
            trackingMethod: 'Tracking method',
            costModel: 'Cost model',
            parameters: 'Parameters',
            parameter: 'Parameter',
            queryParameter: 'Query parameter',
            tsToken: 'TS token',
            tokens: 'Tokens:',
            track: 'Track',
            readOnly: 'Read only',
            choose: 'Choose',
            choosePlan: 'Choose Plan',
            upgradePlan: 'Upgrade Plan',
            downgradePlan: 'Downgrade Plan',
            renewPlan: 'Renew Plan',
            downgrade: 'Downgrade',
            renew: 'Renew',
            addParameter: 'Add parameter',
            addMethod: 'Add method',
            lastVerification: 'Last verification',
            firstname: 'First name',
            lastname: 'Last name',
            accountName: 'Account name',
            trackingSubdomainName: 'Tracking subdomain name',
            company: 'Company name',
            flow: 'Flow',
            campaignSettings: 'Campaign settings',
            email: 'Email',
            password: 'Password',
            newPassword: 'New password',
            confirmPassword: 'Confirm Password',
            promo: 'Promo code',
            iHavePromoCode: 'I have a promo code',
            remember: 'Remember me',
            clickForLanding: 'Click URLs For Landing Pages',
            clickUrl: 'Click URL',
            clickUrlPage: 'Click URL page',
            clickUrlScript: 'Click URL script',
            protectScript: 'Protect script',
            multiOffer: 'Multi-offer click URL',
            customConversions: 'Custom conversions',
            'custom-conversions': 'Custom conversions',
            domains: 'Domains',
            trackingUrls: 'Tracking URLs',
            'tracking-urls': 'Tracking URLs',
            statistics: 'Statistics',
            billingAndSubscription: 'Billing & Subscription',
            'billing-and-subscription': 'Billing & Subscription',
            security: 'Security',
            user: 'User',
            administrator: 'Administrator',
            integrations: 'Integrations',
            'conversion-upload': 'Conversion upload',
            'shared-reports': 'Shared Reports',
            import: 'Import',
            'event-log': 'Event log',
            'ip-ua-filtering': 'IP/UA filtering',
            'anti-fraud-kit': 'Anti-fraud kit',
            support: 'Support',
            notifications: 'Notifications',
            general: 'General',
            settings: 'Settings',
            tools: 'Tools',
            custom: 'Custom',
            columns: 'Columns',
            postbackUrl: 'Postback URL',
            postbackUrlShouldLook: 'Your postback URL should look like this:',
            securePostbackUrl: 'Secure Postback URL',
            hasPostbackUrl: 'Traffic source postback URL',
            tracking_pixel_url: 'Tracking pixel URL',
            tracking_pixel: 'Tracking pixel',
            tracking_script: 'Tracking script',
            conversionUpload: 'Conversion upload',
            defaultPaths: 'Default paths',
            rotationFlow: 'Rotation (Flow):',
            trafficDistributionAiPaths: 'Traffic distribution AI (paths)',
            calculationMethod: 'Calculation method',
            ruleBasedPaths: 'Rule based paths',
            importFrom: 'Import from',
            columnsVisibility: 'Columns visibility',
            columnsOrder: 'Columns order',
            columnsSettings: 'Columns settings',
            selectExportedColumns: 'Select the columns that will be visible in the exported CSV',
            createTrafficSource: 'Create traffic source',
            editTrafficSource: 'Edit traffic source',
            metrics: 'Metrics',
            logicalRelation: 'Logical Relation',
            ruleName: 'Rule name',
            swapGroups: 'Swap groups',
            // createLander: 'Create lander',
            createAffiliateNetwork: 'Create an Affiliate Network',
            // createOffer: 'Create offer',
            all: 'All',
            allData: 'All data',
            selectAll: 'Select all',
            unselectAll: 'Unselect all',
            approved: 'Approved',
            ignore: 'Ignore',
            new: 'New',
            edit: 'Edit',
            declined: 'Declined',
            chargeback: 'Chargeback',
            postbackPct: 'Postback %',
            payoutPct: 'Payout %',
            trafficLossPct: 'Traffic loss, %',
            readGuide: 'Read guide',
            homeScreen: 'Default HOME screen',
            dashboardGrouping: 'Dashboard Grouping',
            dashboardSorting: 'Dashboard Sorting',
            convRegTimeReport: 'Conversion registration time reporting',
            reportTimeZone: 'Report time zone',
            timeZone: 'Time zone',
            between: 'Between',
            and: 'and',
            redirectOffer: 'Redirect offer',
            offerInPath: 'Offers in path',
            redirectOfferOffersInPath: 'Redirect offer, Offers in path',
            offerInPathRedirectOffer: 'Offers in path, Redirect offer',
            selectTimeZone: 'Select time zone',
            selectRedirectOffer: 'Select redirect offer',
            defaultWayToOpenReport: 'Default way to open a report',
            defaultCurrency: 'Default currency',
            dashboard: 'Dashboard',
            statisticsBy: 'Statistics by',
            campaignList: 'Campaign list',
            lastOpenedGlobalReport: 'Last opened global report',
            sendPostbackWithStatuses: 'Send postback with statuses',
            profit: 'Profit',
            compareProfit: 'Previous Profit',
            revenue: 'Revenue',
            compareRevenue: 'Previous Revenue',
            cost: 'Cost',
            compareCost: 'Previous Cost',
            visits: 'Visits',
            compareVisits: 'Previous Visits',
            clicks: 'Clicks',
            compareClicks: 'Previous Clicks',
            conversions: 'Conversions',
            compareConversions: 'Previous Conversions',
            impressions: 'Impressions',
            compareImpressions: 'Previous Impressions',
            compareRoi: 'Previous ROI',
            cpv: 'CPV',
            ctr: 'CTR',
            epc: 'EPC',
            ap: 'AP',
            cr: 'CR',
            noData: 'No data',
            startType: 'Start typing to suggest',
            from: 'from',
            connection: 'Connection',
            connections: 'Connections',
            countries: 'Countries',
            country: 'Country',
            countryMenu: 'Country',
            brand: 'Brand',
            brands: 'Brands',
            model: 'Model',
            resolution: 'Resolution',
            models: 'Models',
            osVersion: 'OS version',
            osVersions: 'OS versions',
            browserName: 'Browser name',
            browser: 'Browser',
            browsers: 'Browsers',
            browserVersion: 'Browser version',
            browserVersions: 'Browsers versions',
            period: 'Period',
            ascending: 'Ascending',
            descending: 'Descending',
            asc: 'Ascending',
            desc: 'Descending',
            ASC: 'Ascending',
            DESC: 'Descending',
            visitTimestamp: 'Visit timestamp',
            postbackTimestamp: 'Postback timestamp',
            tzMinus5: '(UTC-05:00) Eastern Time (US & Canada)',
            tzPlus3: '(UTC+03.00) Moscow Time Zone (Moscow, Minsk)',
            goToTheReport: 'Go to the report',
            openInTheBackground: 'Open in the background',
            currency: 'Currency',
            criteria: 'Criteria',
            usd: 'USD',
            byn: 'BYN',
            rur: 'RUR',
            workspace: 'Workspace',
            workspacePublic: 'Public',
            workspaceSecond: 'Second',
            workspaceThird: 'Third',
            public: 'Public',
            private: 'Private',
            byLink: 'By Link',
            'referral-program': 'Referral program',
            invitations: 'Pending invitations',
            workspaces: 'Workspaces',
            invitedUsers: 'Invited Users',
            homepageURL: 'Homepage URL',
            registerURL: 'Register URL',
            persRefPercent: 'Personal referral percent',
            commissionLast30: 'Commission from last 30 days',
            total: 'Total',
            totalCommission: 'Total commissions',
            eventName: 'Custom conversion event name',
            parameterValues: 'Parameter values',
            home: 'HOME',
            campaignNameReport: 'Campaign name report',
            offerNameReport: 'Offer name report',
            menu_users: 'Users',
            menu_tools: 'Tools',
            menu_settings: 'Settings',
            editColumn: 'Edit column',
            newColumn: 'New column',
            time: 'Time',
            none: 'None',
            sum: 'Sum',
            average: 'Average',
            campaigns: 'Campaigns',
            addedNow: 'Added now',
            flows: 'Flows',
            actionAfterDelete: 'Select action after delete',
            actionAfterArchive: 'Select action after archive',
            offers: 'Offers',
            landers: 'Landers',
            tags: 'Tags',
            trafficSource: 'Traffic source',
            trafficSources: 'Traffic sources',
            trafficSourceIntegrated: 'Tr. source campaign',
            affiliateNetworks: 'Aff. networks',
            affiliateNetwork: 'Aff. network',
            hideReferer: 'Hide referrer',
            hideReferrerDefault: 'Hide referrer default',
            triggers: 'Triggers',
            uniqueVisit: 'Unique visit',
            device: 'Device',
            deviceType: 'Device type',
            devices: 'Devices',
            os: 'OS',
            osName: 'OS name',
            stateProvince: 'State / Province',
            remoteIp: 'IP',
            ispCarrier: 'ISP / Carrier',
            day: 'Day',
            dayOfMonth: 'Day of month',
            dayOfWeek: 'Day of week',
            hourOfDay: 'Hour of day',
            month: 'Month',
            referer: 'Referer',
            refererDomain: 'Referer domain',
            refererUrl: 'Referer URL',
            errors: 'Errors',
            dayParting: 'Day parting',
            errorLog: 'Error log',
            selectOneItem: 'Select at least one item',
            compare: 'Compare',
            previousPeriod: 'Previous period',
            today: 'Today',
            yesterday: 'Yesterday',
            thisWeek: 'This Week',
            lastWeek: 'Last Week',
            last3Days: 'Last 3 Days',
            last7Days: 'Last 7 Days',
            last14Days: 'Last 14 Days',
            thisMonth: 'This Month',
            lastMonth: 'Last Month',
            last6Months: '6 Months',
            thisYear: 'This Year',
            lastYear: 'Last Year',
            allTime: 'All Time',
            customDate: 'Custom Date',
            passingConversion: 'Passing conversion info to traffic source',
            moreTrackingOptions: 'More tracking options',
            pixelRedirectUrl: 'Pixel redirect URL',
            conversionTracking: 'Conversion tracking',
            impressionTracking: 'Impression tracking',
            directTracking: 'Direct tracking',
            clickId: 'Click ID',
            payout: 'Payout',
            transactionId: 'Transaction ID',
            eventType: 'Event type',
            copyAboveContent:
                'Copy the above content and paste it into the affiliate network global conversion tracking settings.',
            additionalOptions: 'Additional options',
            acceptDoublePostback: 'Accept double postback',
            usePostbackStatuses: 'Use postback statuses',
            defaultPostbackStatus: 'Default Postback Status',
            appendClickIdUrl: 'Append click ID to offer URLs',
            postbackIpWhiteList: 'Postback IP white list',
            anTokens: 'AN tokens',
            anParameter: 'AN parameter',
            s2s_postback_url: 'S2S Postback URL',
            enterIpPerLine: 'Enter one IP or IP block (CIDR notation) per line',
            selectStartEnteringTags: 'Select or start entering tags',
            countryTag: 'Country tag',
            offerUrl: 'Offer URL',
            landerUrl: 'Lander URL',
            pageDoesNotMatchLanderURL: "The page with the Click URL does not match the Lander's URL",
            availableUrlTokens: 'Available URL tokens',
            landingPageSetup: 'Landing page setup',
            willUseLandingWith: 'I will use this landing in campaigns with:',
            oneDomain: 'one Tracking domain',
            differentDomains: 'different Tracking domains',
            verify: 'Verify',
            notVerified: 'Not verified',
            notVerifiedYet: 'Not verified yet',
            verified: 'Verified',
            missing: 'Missing',
            numberOfOffers: 'Number of Offers',
            clickUrlForOffer: 'Click URL for Offer',
            clickUrlClassic: 'Click URL for campaigns with different domains.',
            protectLandingPage: 'Protect Landing page',
            redirectlessScript: 'Redirectless script',
            landerScript: '(Lander script)',
            landingVerification: 'Landing page setup verification',
            offerCap: 'Offer CAP',
            conversionCap: 'Conversion CAP',
            clickCap: 'Click CAP',
            conversionOrClickCap: 'Conversion CAP or Click CAP',
            dailyCap: 'Daily cap',
            selected: 'selected',
            colorInTheRow: 'Color in the row',
            verticalBorders: 'Vertical borders',
            // closeTsForm: 'Close traffic source\'s form without saving?',
            // closeTsFormNow: 'You have entered changes to the traffic source\'s form. If you close the form now, you will lose all input.',
            // closeAffiliateForm: 'Close affiliate network\'s form without saving?',
            // closeAffiliateFormNow: 'You have entered changes to the affiliate network\'s form. If you close the form now, you will lose all input.',
            // closeLanderForm: 'Close lander\'s form without saving?',
            // closeLanderFormNow: 'You have entered changes to the lander\'s form. If you close the form now, you will lose all input.',
            manual: 'Manual',
            auto: 'Auto',
            byOfferClick: 'By offer click',
            cpc: 'CPC',
            cpm: 'CPM',
            cpa: 'CPA',
            cpcCost: 'CPC cost',
            cpvCost: 'CPV cost',
            cpmCost: 'CPM cost',
            cpaCost: 'CPA cost',
            revShare: 'RevShare',
            percentageShare: 'Percentage share, %',
            notTracked: 'Not tracked',
            roi: 'ROI',
            epv: 'EPV',
            cv: 'CV',
            priority: 'Priority',
            paths: 'Paths',
            weight: 'Weight',
            actions: 'Actions',
            archiveCommonActions: 'Action for selected campaigns:',
            metaRefresh: 'MetaRefresh',
            doubleMetaRefresh: 'Double MetaRefresh',
            bothSeparately: 'Both separately',
            bothCombined: 'Both combined',
            without: 'Without',
            brandModel: 'Brand and model',
            logicalAnd: 'Logical AND',
            logicalOr: 'Logical OR',
            or: 'OR',
            ampersand: '&',
            stateRegion: 'State / Region',
            city: 'City',
            language: 'Language',
            brandAndModel: 'Brand and model',
            operatingSystemAndVersion: 'Operating system and version',
            browserAndVersion: 'Browser and version',
            deviceTypes: 'Device types',
            connectionType: 'Connection type',
            mobileCarrier: 'Mobile carrier',
            isp: 'ISP',
            proxy: 'Proxy',
            referrer: 'Referrer',
            customVariable1: 'Custom variable 1',
            customVariable2: 'Custom variable 2',
            customVariable3: 'Custom variable 3',
            customVariable4: 'Custom variable 4',
            customVariable5: 'Custom variable 5',
            customVariable6: 'Custom variable 6',
            customVariable7: 'Custom variable 7',
            customVariable8: 'Custom variable 8',
            customVariable9: 'Custom variable 9',
            customVariable10: 'Custom variable 10',
            customVariable11: 'Custom variable 11',
            customVariable12: 'Custom variable 12',
            customVariable13: 'Custom variable 13',
            customVariable14: 'Custom variable 14',
            customVariable15: 'Custom variable 15',
            customVariable16: 'Custom variable 16',
            customVariable17: 'Custom variable 17',
            customVariable18: 'Custom variable 18',
            customVariable19: 'Custom variable 19',
            customVariable20: 'Custom variable 20',
            ipRange: 'IP range',
            newRule: 'New rule',
            is: 'is',
            isNot: 'is not',
            offer: 'Offer',
            newOffer: 'New Offer',
            lander: 'Lander',
            newLander: 'New Lander',
            clickIdNotSet: 'ClickId not set',
            offerUsedAsCap: 'This offer is already used as a CAP-offer',
            rule: 'Rule',
            rules: 'Rules',
            pathsOptimization: 'Paths optimization',
            weightsOptimization: 'Weights optimization',
            optimized: 'Optimized',
            setsPathsOptimized: 'sets of paths optimized',
            no: 'No',
            group: 'Group',
            mainOptions: 'Main options',
            addCampaigns: 'Add campaigns',
            ROLE_USER: 'User',
            ROLE_ADMIN: 'Administrator',
            ROLE_OWNER: 'Owner',
            badges: {
                waiting: 'waiting',
                install: 'install',
                sale: 'sale',
                minus: '-',
                plus: '+',
                multiplier: '*',
                divider: '/',
                startBracket: '(',
                endBracket: ')',
                clicks: 'Clicks',
                uClicks: 'Uniq. Clicks',
                leads: 'Leads',
                lpClicks: 'LP Clicks',
                revenue: 'Revenue',
                cost: 'Cost',
                event1: 'Event1',
                event2: 'Event2',
                event3: 'Event3',
                event4: 'Event4',
                event5: 'Event5',
                event6: 'Event6',
                event7: 'Event7',
                event8: 'Event8',
                event9: 'Event9',
                event10: 'Event10',
                lpCrt: 'LP CTR',
                cpc: 'CPC',
                cr: 'CR',
                epc: 'EPC',
                profit: 'Profit',
                roi: 'ROI'
            },
            columnsSettingsDesc: {
                id: 'ID is a unique number that is used for identifying elements created in Plat.',
                displayName: 'Name is a unique string used for organizational purposes.',
                rotationName:
                    'Rotation indicates whether the campaign uses flow or whether the path has been edited inside the campaign.',
                impressions: 'Impressions show how many times the advertisement has been shown to a visitor.',
                ecpm:
                    'Effective cost per mille (thousand) ($) is a traffic source cost model that indicates how much' +
                    ' you pay to your traffic source for a thousand ad impressions. You need to set up impression tracking and define the CPM value, while creating a campaign. ' +
                    'Effective cost per mille = Total cost / Number of impressions * 1000',
                ictr: 'Impression click-through rate (%) indicates the number of users that clicked on the ad url after viewing it on a website. ICTR uses the following formula: ICTR (%) = Number of visits / Number of impressions * 100 %.',
                rpm: 'Revenue per mille (thousand) ($) indicates the amount of money earned for a thousand impressions. RPM uses the following formula: RPM ($) = Total revenue ($) / Number of impressions * 1000.',
                cpa: 'Cost per action ($) is a traffic source cost model that indicates how much you pay to your traffic source for a single user action. You need to define this value while creating a campaign.',
                cpc: 'Cost per click ($) is a traffic source cost model that indicates how much you pay to your traffic source for every click on an ad. You need to define this value while creating a campaign.',
                cpm: 'Cost per mille (thousand) ($) is a traffic source cost model that indicates how much you pay to your traffic source for a thousand ad impressions. You need to set up impression tracking and define the CPM value while creating a campaign.',
                visitsUnique:
                    'Indicates the number of unique visits for the stated time period. A visit is considered unique when there is no cookie associated with the user before the visit. Cookies are kept for 24 hours. Visits from the same users will be considered unique after 24 hours.',
                visits: 'Indicates the number of all visits for the stated time period.',
                clicksUnique: 'Indicates the number of unique clicks for the stated time period.',
                clicks: 'Indicates the number of all clicks for the stated time period.',
                cr:
                    'Conversion Rate (%) lets you measure profitability of your offer and indicates how well your campaign performs. It calculates (in percentage) how many users converted after clicking on your offer URL.\n' +
                    'CR uses the following formula: CR (%) = Number of conversions / Number of clicks * 100%',
                epc:
                    'Earning per click ($) calculates your revenue for a single click on your ad. ' +
                    'ER uses the following formula: EPC ($) = Total revenue ($) / Number of clicks.',
                conversions: 'Indicates the number of conversions from users that clicked on your offer URL.',
                revenue: 'Revenue ($) indicates the total amount of money earned from all conversions.',
                cost: 'Cost ($) indicates the total amount of money paid to run your campaign.',
                profit:
                    'Indicates how much you have earned after your cost is deducted. ' +
                    'Profit ($) = Revenue ($) - Cost ($)',
                roi:
                    "The main indicator of your campaign's profitability. Compares earned money to invested money in percent. " +
                    'Return on investment (%) = (Total revenue ($) - Total cost ($) ) / Total cost ($) * 100%',
                ctr:
                    'The Click-through rate (CTR) indicator shows how many users clicked on your offer on a landing' +
                    ' page. Thus, it shows how well your offer works for your traffic source and audience. ' +
                    'Click-through rate (%) = Number of clicks / Number of visits * 100%',
                cpv:
                    'The cost per view (CPV) shows how much you pay for a single ad on a website. ' +
                    'Cost per view ($) = Total Cost ($) / Number of views',
                epv:
                    'The EPV (earning per visit) indicator determines how much money you earn per visit to the' +
                    ' offer website. ' +
                    'Earnings per visit ($) = Total revenue ($) / Number of visits',
                cv:
                    'Conversion visits (CV) indicate how many users converted after clicking on the ad. It is one of' +
                    ' the most important indicators that determines the profitability of your campaign and offer. ' +
                    'Conversion rate (%) (visits) = Number of conversions / Number of visits * 100%',
                ap:
                    'Average payout (AP) indicates the average amount you make for each conversion ' +
                    'Average payout ($) = Total revenue ($) / Number of conversions',
                ecpc:
                    'Effective cost per click (ECPC) indicates how much a single click costs. This is calculated' +
                    ' post-campaign. If you use a CPC model as your traffic payment, the ECPC calculates how much you actually paid for the traffic. If you used another cost model, the ECPC shows the average price for a single click. ' +
                    'Average payout ($) = Total revenue ($) / Number of conversions',
                ecpa:
                    'Effective cost per action (ECPA) indicates the cost for a single user action, such as a' +
                    ' subscription or app installation. This is calculated post-campaign. If you pay for traffic using a CPA cost model, then ECPA equals CPA. If you pay for traffic using another cost model, then the ECPA will be calculated using the following formula: ' +
                    'Effective cost per action or acquisition rate ($) - Total cost / Number of acquisitions (conversions)',
                affiliateNetworkName: 'Indicates which affiliate network your offer belongs to.',
                url: 'Website URL that needs to be advertised. Provided by offer-owner or affiliate network.',
                payoutPrice: 'Payout shows how much revenue you get for each conversion.',
                conversionCap:
                    'Limits the number of conversions an offer can get. If the CAP is reached, users will be redirected to another offer that was predefined in CAP menu.',
                clickCap: '',
                numberOfOffers: 'This indicates how many offers you have on your landing page.',
                name: 'The offer name is based on country tag and unique custom name. Name preferences for offer name in report should be set up in Settings -> General -> Reporting.',
                country:
                    'Country - new conversion appears each time the postback URL/pixel passes the corresponding' +
                    ' value specified in settings of Affiliate Network.',
                campaignsCount: 'Indicates the number of campaigns added to the flow.',
                clickId: 'A unique identifier for each visitor.',
                status: 'Indicates the conversion status.',
                externalId: 'Determines the Click ID sent by the traffic source and recorded in Plat.',
                transactionId:
                    'Indicates the Transaction ID, which is unique to the Click ID. If the Transaction ID differs, you can record more than one conversion using the same Click ID',
                postback: 'Determines the date and time when the postback URL was activated.',
                visit: 'Indicates the date and time of a visit.',
                timeSinceClick: 'Indicates the time (in seconds) between the offer URL click and conversion.',
                campaign: 'Indicates the name of your campaign.',
                trafficSource: 'Indicates the name of the Traffic Source used to run your campaign.',
                offerName: 'Indicates the name of your offer.',
                payout: 'Payout shows how much you get paid for each conversion.',
                geo: 'Indicates the geolocation of a visitor.',
                clientIp: 'Indicates the IP of a visitor.'
            },
            billing: {
                accountOverdue: 'Your account is currently overdue',
                accountBlocked: 'Your account is currently blocked',
                blockAccount: (dateBlock, daysLeft) => (
                    <>
                        Your account is currently blocked. Сampaigns were stopped on{' '}
                        <span className="block-modal__date">{dateBlock}</span> ({daysLeft} days after account is
                        overdue). Please complete payment or downgrade Subscription to Free.
                    </>
                ),
                contactOwner: (email, dateFrom, dateTo) => (
                    <>
                        Please contact{' '}
                        <a href={`mailto:${email}`} className="block-modal__owner">
                            {email}
                        </a>{' '}
                        to Renew or Downgrade account subscription for current billing period{' '}
                        <span className="block-modal__date">{`${dateFrom} - ${dateTo}`}</span>.
                    </>
                ),
                blockDate: (dateBlock, daysLeft) => (
                    <>
                        Your campaigns will continue work and will be stop after{' '}
                        <span className="block-modal__date">{dateBlock}</span>{' '}
                        {`(${daysLeft} days after account is overdue)`}
                    </>
                ),
                stopDate: (dateBlock, daysLeft) => (
                    <>
                        Your campaigns were stopped on <span className="block-modal__date">{dateBlock}</span>{' '}
                        {`(${daysLeft} days after account is overdue)`}
                    </>
                ),
                currentPlan: {
                    monthlyPayment: 'Monthly payment',
                    nextPlan: 'Next Plan',
                    currentPeriod: 'Current Period',
                    eventsIncluded: 'Events Included',
                    eventsUsed: 'Events used/Overage events',
                    overageCost: 'Overage cost'
                },
                invoiceDetails: {
                    type: 'Type',
                    company: 'Company',
                    name: 'Name',
                    address: 'Address',
                    city: 'City',
                    zip: 'ZIP / Postal code',
                    country: 'Country',
                    ssn: 'SSN / TAX / VAT ID',
                    personal: 'Personal',
                    business: 'Business'
                }
            },
            accessKey: 'Access Key',
            secretKey: 'Secret Key',
            s2sPostback: 'S2S Postback URLs',
            conversionTrackingPixel: 'Conversion tracking pixel',
            conversionTrackingPixelUrl: 'Conversion tracking pixel URL',
            conversionTrackingScript: 'Conversion tracking script',
            landingPageProtection: 'Landing Page Protection',
            phpRecommended: 'PHP (Recommended)',
            javaScript: 'JavaScript',
            chooseScript: '1) Choose the type of lander protection script:',
            copyScript:
                "2) Copy the following script and paste it right after the <head> tag in your lander's HTML file:",
            invoices: 'Invoices',
            personal: 'Personal',
            business: 'Business',
            address: 'Street Address',
            zip: 'ZIP / Postal code',
            tax: 'SSN / TAX / VAT ID',
            trackingDomains: 'Tracking domains',
            redirectDomain: 'Redirect domain',
            registrar: 'Your registrar / DNS hosting provider',
            yourDomain: 'Your domain',
            domain: 'Domain',
            readInstruction: 'Read instruction',
            destination: 'Destination',
            newWorkspace: 'New workspace',
            editWorkspace: 'Edit workspace',
            newNotification: 'New Notification',
            editNotification: 'Edit Notification',
            template: 'Template',
            systemMessage: 'System message',
            https: 'https://',
            httpApiToken: 'HTTP API token',
            chatID: 'Chat ID',
            fromTwilio: 'From (twilio tel. #)',
            toYour: 'To (your tel. #)',
            apiId: 'API ID',
            apiToken: 'API token',
            webhookUrl: 'URL (specified link will be triggered)',
            postParams: 'Post params',
            value: 'Value',
            ipuaFiltering: 'IP/UA filtering',
            filterName: 'Filter name',
            ipOrIpRange: 'IP or IP range',
            filterType: 'Filter type',
            userAgent: 'User agent',
            botFilters: 'Bot filters',
            markAsBot: 'Mark as bot',
            redirectBot: 'Redirect bot',
            closeConnection: 'Close connection',
            conditionsName: 'Conditions name',
            redirect: 'Redirect',
            direct: 'Direct',
            landerOffers: 'Lander & Offers',
            valuesOfTheEtParameter: 'Values of the et parameter',
            includeConversions: 'Include in "Conversions" column',
            includeRevenue: 'Include in "Revenue" column',
            sendPostbackToTrafficSource: 'Send postback to traffic source',
            includeInCostColumn: 'Include in "Cost" column',
            type: 'Type',
            cardNumber: 'Card number',
            expMonth: "Expiration date's month",
            expYear: "Expiration date's year",
            cvc: 'CVV / CVC',
            expirationDate: 'Expiration Date',
            year: 'Year',
            code: 'Code',
            securelySaveCard: 'Securely save this card',
            pricingPlan: 'Pricing plan',
            confirmation: 'Confirmation',
            status: 'Status',
            objectSleepTime: 'Object Sleep Time (hours)',
            condition: 'Condition',
            object: 'Object',
            timeframe: 'Timeframe',
            actionName: 'Action name',
            sendSystemMessage: 'Send system message',
            action: 'Action',
            rotation: 'Rotation',
            applyTo: 'Apply to',
            landingPage: 'Landing page',
            landingPages: 'Landing pages',
            manualCostUpdate: 'Manual cost update',
            timeRange: 'Time range',
            downloadFile: 'Select the columns that will be visible in the exported file',
            customNotification: 'Custom notification',
            entity: 'Entity',
            campaignUrl: 'Campaign URL',
            campaignTestUrl: 'Campaign Test URL',
            impressionUrl: 'Impression URL',
            landingPixel: 'Landing pixel',
            postbackPixel: 'Postback Pixel',
            postbackScript: 'Postback Script',
            pixelGenerator: 'Pixel generator',
            oldPassword: 'Old password',
            confirmNewPassword: 'Confirm new password',
            pasteRule: 'Paste rule',
            clearBuffer: 'Clear buffer',
            newURL: 'New URL',
            addBookmark: 'Add a Bookmark',
            autoPaymentError: 'Auto payment error',
            useNextPaymentMethod: 'Use next payment method if default method failed',
            blockModals: {
                trial: {
                    youHaveDays: days => `You have ${days} days after registration to update a payment method.`,
                    contactOwnerToUpdate: email => (
                        <>
                            Please contact{' '}
                            <a href={`mailto:${email}`} className="block-modal__owner">
                                {email}
                            </a>{' '}
                            to update payment method.
                        </>
                    ),
                    contactOwnerToUpdateForce: email => (
                        <>
                            Please contact{' '}
                            <a href={`mailto:${email}`} className="block-modal__owner">
                                {email}
                            </a>{' '}
                            to update payment method to access the panel.
                        </>
                    ),
                    paymentMethodRequired:
                        'The payment method is required to avoid any blocks after events limit is exceeded.',
                    updateToAccess: 'Please update payment method to access the panel.'
                },
                eventsLimit: {
                    text: events => (
                        <>
                            Specify billing info and payment method to access the panel. The payment method is required
                            to avoid any blocks after <b>{events}</b> events limit is exceeded.
                        </>
                    )
                }
            },
            bySupport: 'by Support',
            dragToChangePriority: type => `Drag and drop ${type} to change priority`,
            maxLandersAndOffers: (landers, offers, invert) =>
                `You can add max ${invert ? `${offers} Offers` : `${landers} Landers`} for ${
                    !invert ? `${offers} Offers below` : `${landers} Landers above`
                }`,
            unlim: 'Unlim',
            archived: 'Archived',
            integration: 'Integration',
            integrated: 'Integrated',
            active: 'Active',
            inactive: 'Inactive',
            supportAccess: 'Support access',
            nameOfReport: 'Name of Report'
        },
        singulars: {
            affiliateNetworks: 'Aff. network',
            campaigns: 'Campaign',
            flows: 'Flow',
            landers: 'Lander',
            offers: 'Offer',
            trafficSources: 'Traffic source',
            triggers: 'Trigger',
            domains: 'Domain',
            users: 'User'
        },
        columnsLabels: {
            checked: 'Checkbox',
            id: 'ID',
            name: 'Name',
            visits: 'Visits',
            clicks: 'Clicks',
            suspicious: 'Suspicious visits',
            cr: 'CR',
            epc: 'EPC',
            revenue: 'Revenue',
            cost: 'Cost',
            profit: 'Profit',
            roi: 'ROI',
            workspace: 'Workspace',
            costCurrency: 'Cost currency'
        },
        titles: {
            selectFlowsActions: 'Please select action after archive Flow',
            chooseLanders: 'Choose landers',
            chooseOffers: 'Choose offers',
            chooseCampaign: 'Choose campaign',
            createLander: 'Create lander',
            editUser: 'Edit user',
            editTrafficSource: 'Edit traffic source',
            createTrafficSource: 'Create traffic source',
            createAffiliateNetwork: 'Create affiliate network',
            editAffiliateNetwork: 'Edit affiliate network',
            createOffer: 'Create offer',
            editLander: 'Edit lander',
            editOffer: 'Edit offer',
            edit: 'Edit',
            googleAuth: 'Google Authenticator',
            newCampaign: 'New Campaign',
            newFlow: 'New Flow',
            closeWithoutSave: type => `Close ${type ? `${type}'s` : ''} form without saving?`,
            currentPlanSubTitle: 'Basic $99/mo',
            customConversionEvent: 'Custom Conversion Event',
            addCustomDomain: type => `Add custom ${type} domain`,
            editCustomDomain: 'Edit custom domain',
            addFilteringRule: 'Add filtering rule',
            editFilteringRule: 'Edit filtering rule',
            addConditions: 'Add conditions',
            editConditions: 'Edit conditions',
            invoiceDetails: 'Invoice Details',
            currentPlan: 'Current Plan',
            paymentMethod: 'Payment Method',
            changePassword: 'Change password',
            twoFactorAuth: 'Two-Factor Authentication',
            apiAccessKeys: 'API Access Keys',
            billingInfo: 'Billing Info',
            updPaymentMethod: 'Update Payment Method',
            addPaymentMethod: 'Add New Payment Method',
            newPaymentMethod: 'New Payment Method',
            newWebhook: 'new webhook',
            editWebhook: 'edit webhook',
            switchRotation: 'switch rotation',
            pauseAnywhere: 'pause anywhere',
            activateAnywhere: 'activate anywhere',
            changeRouting: 'change routing',
            notificationsMethods: 'Notifications Methods',
            switchDomain: 'switch domain',
            campaignName: 'Campaign name',
            selectCampaignName: 'Select campaign name',
            selectCampaign: 'Select campaign',
            notifyMe: 'Notify me when',
            notificationsTypes: 'Notifications types',
            campaignLinks: 'Campaign links',
            updateConversions: 'Update conversions',
            conversionsLogs: 'Conversions logs',
            deleteDomain: 'Delete domain?',
            deleteIntegration: 'Delete integration?',
            deleteSchedule: 'Delete schedule?',
            deleteConversion: 'Delete custom conversion?',
            deleteElement: isMulti => `Delete selected element${isMulti ? 's' : ''}?`,
            restoreElement: isMulti => `Restore selected element${isMulti ? 's' : ''}?`,
            archiveElement: isMulti => `Archive selected element${isMulti ? 's' : ''}?`,
            archivingResult: 'Archiving result',
            savingResult: 'Saving result',
            deletingResult: 'Deleting result',
            clearElement: 'Clear statistics for the selected element?',
            newVersion: 'New Version!',
            error: 'Error',
            generalSettings: 'Global settings',
            userSettings: 'User settings',
            overageEvents: quantity => `Overage events (${quantity})`,
            unlimitedBillingAlertTitle: 'This is an Unlimited account:',
            congratulations: 'Congratulations',
            asRedirectOffer: 'AS Redirect offer',
            trackingMethodChanged: 'Tracking method was changed',
            optimizationSettings: 'Optimization settings',
            integrateAccount: 'Integrate account',
            integrationName: 'Integration name',
            integrations: 'Integrations',
            integrationAvailable: 'Integration available',
            integrationNotAvailable: 'Integration NOT available',
            integrationAllowYouTo: 'Integration will allow you to:',
            addIntegration: 'Add integration',
            additionalRevenue: 'Additional Revenue',
            selectTemplate: 'Select template',
            addSchedule: 'Add schedule',
            editSchedule: 'Edit schedule',
            createSharedReport: 'Create a Shared Report',
            editSharedReport: 'Edit Shared Report',
            sharedReportLink: 'Shared Report Link',
            confirmation: 'Confirmation'
        },
        placeholders: {
            name: 'Name',
            firstname: 'First name',
            lastname: 'Last name',
            accountName: 'Account name',
            trackingSubdomainName: 'Tracking subdomain name',
            yourName: 'Your name',
            email: 'E-mail',
            password: 'password',
            confirmPassword: 'password',
            promo: 'If you have',
            clickIds: 'Type one click ID per line',
            chooseGrouping: 'Choose grouping',
            searchForColumn: 'Search for column',
            selectConnectionType: 'Select connection type',
            selectCountry: 'Select country',
            selectRegion: 'Select state/region',
            selectLanguage: 'Select language',
            selectMobileCarrier: 'Select mobile carrier',
            selectIsp: 'Select ISP',
            selectProxy: 'Select proxy type',
            selectCity: 'Select city',
            selectDeviceType: 'Select device type',
            selectValue: 'Select value',
            selectTrafficSource: 'Select traffic source',
            custom: 'Custom',
            search: 'Search',
            typeToken: 'Type token',
            typeParameter: 'Type parameter',
            typeToSearch: 'Type to search',
            commaSeparatedValues: 'Comma-separated values',
            payout: 'Payout',
            payoutCurrency: 'usd',
            longValueHere: 'Long value here',
            typeDomain: 'Type one domain name per line',
            enterValue: 'Enter a value',
            typeIpRange: 'Type one ip range per line, eg. 192.168.0.0 - 192.168.1.32',
            typeOrSelectTagsFromList: 'Type or select tags from list',
            newPassword: 'New password',
            repeatNewPassword: 'Repeat new password',
            enterTargetUrl: 'Enter target URL',
            typeValue: 'Type value',
            typeName: 'Type name',
            startEnteringName: 'Start entering name',
            typeFlowName: 'Type flow name',
            exampleCom: 'E.g. https://www.example.com'
        },
        hints: {
            readMore: 'Read more',
            costModels: {
                auto: 'Auto should be selected if you have defined the cost parameter in your traffic source settings.',
                cpm: 'Only applicable with ‘Track Impressions’ option',
                cpa: 'Conversion cost could be passed to traffic source postback via {campaignCpa} token',
                revShare: 'RevShare indicates the percent of revenue that you will pay to your traffic source.'
            },
            costModel:
                'Select a cost model. This should correspond to the ad pricing model on your Traffic Source account.',
            trackingDomain: 'Select domain for your campaign.',
            weightsOptimization:
                'When enabled, weights of Offers and Landers (separately or combined) will be optimized using AI to get the best results.',
            weightsOptimizationOption: option => `When selected, only weights of ${option} will be optimized.`,
            weightsOptimizationBothSeparately: 'This feature optimizes Landers and Offers separately.',
            weightsOptimizationBothCombined:
                'Enable this option if you need to calculate all the possible combinations of landers and offers in the path. When selected, the most optimized weights will automatically be set.\n' +
                'You can see optimized weights for every combination below.',
            hideReferer:
                'Select redirection method for campaign’s endpoint. If you want to avoid passing referrer data choose Meta Refresh or Double MetaRefresh.',
            hideReferrerDefault:
                'Select redirection method for campaign’s endpoint. If you want to avoid passing referrer data choose Meta Refresh or Double MetaRefresh.',
            landingVerification:
                'By clicking the button below you will be able to check if your landing page has been properly set up.',
            availableUrlTokens:
                'Adding tokens to your Lander URL is optional. You can use this option e.g. if you want to perform dynamic call-out on your landing page.',
            differentDomains:
                'In this step you need to do modify the landing page, so that all the data is automatically taken and used to build a click URL on a landing page. For this, copy the following script and paste it within the <head> section of your landing page.',
            denyVerifyMissingUrl: 'You are missing a Lander URL, so we are enable to scan your landing page.',
            denyVerifyProtection:
                'If you use the Lander Protection Script, then the Lander Scanning Tool will not be able to reach your site.',
            homeScreen: 'Determines default Home screen.',
            dashboardGrouping: 'Determines default dashboard grouping.',
            dashboardSorting:
                'If you want your dashboard statistics to be shown from low values to high values, select' +
                ' “Ascending.” ' +
                'If you want your dashboard statistics to be shown from high values to low values, select “Descending.”',
            convRegTimeReport: 'Select on what event timestamps you want to get your overall conversion reports.',
            reportTimeZone: 'Report time zone Hint',
            defaultWayToOpenReport:
                'Gives you the option to automatically redirect to the reports page or open the page in a new tab.',
            defaultCurrency: 'Determines your default currency.',
            customColumnFormula: 'Custom Column Formula Hint',
            parameters: (parameters = 20) =>
                `These parameters let you specify what data to pass to Plat from your traffic source. Besides External ID and Cost tokens, you can add up to ${parameters} variables. Check documentation of your traffic source for supported parameters and tokens. You can use Plat’s tokens to send traffic source data to an offer, a landing page, or back to the traffic source when sending conversion data.`,
            name: 'Type in names for the parameters of your traffic source. These names will be displayed in your reports.',
            sendPostbackWithStatuses:
                'This option allows to pass postbacks to your traffic source only with selected statuses',
            queryParameter:
                'Query parameters are static values and will be used in your campaign URL. Plat uses these parameters to identify what data the tokens contain when your traffic source replaces dynamic tokens. Check your traffic source’s documentation for these parameters.',
            tsToken: `Your traffic source will dynamically replace these tokens during the redirection from the ad to the offer URL.
                Check your traffic source’s documentation for these tokens.
                Examples: {conversion}, %cost%, [[country]].`,
            compare: 'Compare Description',
            workspace: (entity = 'Campaign', isHidePublic) =>
                `Select a specific workspace for your ${entity}. Only members of the selected workspace will have access to the created ${entity}. ${
                    !isHidePublic
                        ? 'Choose the “Public“ option if you want users from all workspaces to use this element in their campaigns.'
                        : ''
                }`,
            trafficSource:
                'Select your traffic source (ad network, social media, organic traffic). These users will be redirected to the offer website.',
            campaignName:
                'Enter campaign name. The name is composed of the traffic source, country tag, and unique name. This is the campaign name that will be shown in your reports.',
            redirect: "Option to redirect visitors through Plat's servers using rule-based paths.",
            direct:
                "A faster tracking method that doesn't have a redirect. This method is accepted by all traffic" +
                ' source requirements. However, you cannot rotate landers, offers, or rule-based paths.',
            redirectlessScript:
                "Put this script before your HTML file's </body> tag in order if you want to use direct tracking between" +
                ' traffic source and landing page or between landing page and offer page',
            costCurrency:
                'The currency you use to buy traffic. Select this option if the currency you use to buy traffic is different from the currency you get paid in.',
            passingConversion:
                'Allows you to pass conversion information recorded in Plat to your traffic source. You may be required to select this option if you use CPA traffic sources.',
            hasPostbackUrl:
                'Server-to-server (S2S) postback URLs are the most reliable method to send conversion information back to your traffic source. These postback URLs allow your traffic source to recognize converted users if a unique click ID generated by the traffic source is appended to the postback URL.',
            conversionTracking:
                'These are parameters and tokens used by your affiliate network, which are mandatory if you use S2S postbacks for conversion tracking, and optional for other conversion tracking types. Check your affiliate network’s documentation for these values.',
            conversionTrackingMethods:
                'Method that will be used to pass conversion to PLAT. We recommend to use S2S postback URL as the' +
                ' most secure option.',
            s2s_postback_url:
                'Server-to-server (S2S) postbacks are not cookie based and allow servers to communicate with each other directly. The "replace" and "optional" fields will be adjusted with the corresponding tokens provided by your affiliate network after you fill in the "AN tokens" fields.',
            tracking_pixel:
                'The "replace" and "optional" fields will be adjusted with corresponding tokens provided by your affiliate network after you fill in the "AN tokens" fields.',
            postbackPct:
                'The amount (percentage) of conversions that will be sent to traffic source. By default, it set to 100%',
            payoutPct: 'Percentage of payout that’ll be send to your traffic source',
            pixelRedirectUrl:
                'This option only works while using conversion tracking pixel to register conversions in Plat. Plat tokens do not work in the pixel redirect URL.',
            '{external_id}':
                'A unique identifier for every click that is generated by your traffic source. External IDs are usually used in postback URLs to send the conversion information back to your traffic source.',
            '{cost}':
                'If your traffic source supports cost tokens, you can automatically send ad cost information to Plat. This feature is useful for campaigns with a CPC (Cost-Per-Click) cost model. If your source doesn’t support this option, you can upload campaign costs by clicking “Update Cost” in the “Campaigns List”',
            trafficLossPct:
                'Settings traffic loss percent affects traffic cost in campaigns, that using cost models CPV, CPM,' +
                ' or Auto. When value is greater than zero total traffic cost is calculated according to the formula: (100% + traffic loss %)*cost value in campaign',
            impressionTracking:
                'If your traffic source lets you track ad impressions in third party tools, you can use this option. After enabling this option and creating a new campaign with this traffic source, Plat will generate an additional tracking link.',
            directTracking:
                'Important for the sources that check if the destination URL set in the campaign matches the final' +
                ' URL displayed for a user. After enabling this option, new campaigns with this traffic source will show a warning message. It will notify you to set the tracking method to “direct“.',
            conversionParameter:
                'Equivalent tokens used by Plat. Apart from click ID, other tokens can be used if you need to send the conversion data to the traffic source using the traffic source postback URL.',
            conversionANToken:
                'Affiliate network-specific tokens. Your affiliate network will dynamically replace these tokens when sending the postback URL.',
            conversionANParameter:
                'A static value used by your network to record Plat data. You can add this parameter with a Plat token in the offer URL. Upon redirection, we dynamically pass the data to the affiliate network.',
            acceptDoublePostback:
                'Enabling this option lets you record duplicate postback requests with the same click ID, even without a txid token. This will affect the conversion tracking results.',
            usePostbackStatuses: 'This option allows to pass conversion statuses from your affiliate network.',
            newPostbackStatus:
                'Conversions with this status will not be marked as confirmed and conversion revenue will not be added to the reports.',
            approvedPostbackStatus:
                'Conversions with this status will be marked as confirmed and conversion revenue will be added to the reports.',
            declinedPostbackStatus: 'Conversions that were declined.',
            chargebackPostbackStatus:
                'Conversions with this status will be marked as charged and conversion revenue will be subtracted from the reports.',
            clickId:
                'Click ID is a unique identifier for each visitor. It is a required token when used in S2S postback URLs for tracking conversions in Plat.',
            payout: 'An affiliate network token that passes payout information to Plat. This includes information about earned money after successful conversions.',
            transactionId:
                'The transaction ID must be unique to the click ID. However, you can record more than one conversion using the same click ID if the transaction ID differs.',
            eventType:
                'Event type tokens let you to track several conversion types within one campaign. The custom conversion types are displayed in reports to measure the campaign’s performance more accurately. In order to track event types, specify custom conversions in settings.',
            status:
                'Use this token if you receive other conversion statuses instead of the regular ones (Approved,' +
                ' Declined, Chargeback).',
            appendClickIdUrl:
                'Offers created with the same affiliate network will have the same click ID automatically added at the end of offer URLs.',
            postbackIpWhiteList:
                'You can define an IP filter for postbacks from your affiliate network. Only conversion data from the white-listed IPs will be accepted. You can define a single IP or IP range.',
            selectStartEnteringTags:
                'Add personalized tags to easily search for landers afterward. Keep in mind that tags can only contain letters, numbers, and underscores.',
            countryTag: (entity = 'campaigns') =>
                `Entering country tag will help you organize your ${entity}. This option will NOT target users from a certain location or country.`,
            addPersonalizedOfferTags:
                'Add personalized tags to easily search for offers afterward. Keep in mind that tags can only contain letters, numbers, and underscores.',
            addCampaignTags:
                'Add personalized tags to easily search for campaigns afterward. Keep in mind that tags can only contain letters,  numbers, and underscores.',
            offerUrl: 'Website URL that needs to be advertised. Provided by offer-owner or Affiliate network.',
            offerConversionTracking:
                'Monitors all conversion methods. You can select "Offer Click," which will count every click as a' +
                ' conversion. If your affiliate network doesn\'t provide conversion data, use "Upload Conversion"' +
                ' in "Tools."',
            offerPayout:
                "Select either Manual to enter the fixed payment amount or the Auto option if you've added the payout parameter to your postback or pixel URL.",
            offerCurrency:
                'The default display currency is USD. If your payout currency are different, your data in reports will be calculated using exchange rates accurate for the selected time range.',
            offerCap:
                'This feature allows you to limit the amount of traffic for a specific offer. After choosing the method which will be used to calculate the traffic, whenever the limit is reached traffic will be redirected to another offer which you have selected.',
            offerCapOption: cap =>
                `Set a ${cap} limit for the offer. You can redirect traffic to different offer after the ${cap} CAP is reached.`,
            offerCapBothOptions:
                'This method will count both conversions and clicks. Whenever the selected limit of Conversions or Clicks will be reached, traffic will be redirected to other offer.',
            offerDefineCap: cap => `Define the ${cap} CAP.`,
            offerDailyCap: 'Restricts the number of conversions within a 24-hour period',
            offerTimeZone:
                "Select time zone which will be used to reset CAP limit. The counter will reset at 12 a.m. daily based on the affiliate network's time zone.",
            offerPriority: 'Redirect sequence in case if daily cap is reached.',
            redirectOffer: 'If the CAP is reached, users will be redirected to the offer specified in this field.',
            defaultPostbackStatus:
                'If the conversion cannot be specified, you can determine what the default status will be.',
            defaultPaths: 'If rules are not applied, all visitors will be routed to default paths.',
            pathsOptimization:
                'Enabling this feature will optimize weights inside this path using AI Auto Optimization.',
            landingPages:
                'This is the webpage the visitor will be directed to after clicking on the ad. The visitor will then be redirected to the offer website.',
            offers: 'Offer is a Website URL provided by Aff.network or offer-owner that needs to be advertised.',
            ruleBasedPaths:
                'Helps you target your traffic to a specific path by choosing the criteria or defining a value.',
            ruleName: 'Used for organizing and labeling rules',
            rotationFlow:
                'The set of campaign destination paths. The flow is predefined, but can be edited in the "Flows" tab on the Main Menu.',
            trafficDistributionAiPaths:
                'Enables Auto Optimization for traffic paths.This feature helps you increase your profit using AI, which optimizes the offers and landers.This feature works correctly only when you track conversions.',
            calculationMethod:
                'Select an optimization metric. If there is no data for the selected metric, calculations will be based on an available data sample. Leave Auto, if you want to optimize weights according to the approach suggested by Tracker',
            yourDomain: 'Enter the tracking domain that will be used to redirect users to PLAT.',
            customDomainDestination:
                'Select where visitors will be redirected if they go to your domain URL without a campaign ID.',
            newNotificationTemplate: 'Some hint here',
            filterType: 'Anti-fraud kit Filter type hint',
            markAsBot: 'Anti-fraud kit Mark as bot hint',
            redirectBot: 'Anti-fraud kit Redirect bot hint',
            closeConnection: 'Anti-fraud kit Close connection hint',
            conditionsName: 'Anti-fraud kit Conditions name hint',
            logicalRelation: 'Anti-fraud kit Logical Relation hint',
            logicalAnd:
                'When selected, all of your criteria should match in order to direct your visitor to a rule-based path.',
            logicalOr:
                'When selected, at least one of your criteria is enough for your visitor to be redirected to a rule-based path.',
            customConversionEventName: 'Set a name for custom conversion event.',
            valuesOfTheEtParameter: 'You can define more than one value. Each value must be typed in a new line.',
            includeConversions:
                'After enabling this option, the number of custom conversion events will be added to the total number displayed in the “Conversions” column.',
            includeRevenue:
                'After enabling this option, the revenue of custom conversion events will be added to the amount in the “Revenue” column.',
            sendPostbackToTrafficSource:
                'Enable this option if you want to notify your traffic source about the custom conversion event.',
            includeInCostColumn:
                'After enabling this option, the cost of custom conversion events will be added to the amount in the “Cost” column.',
            group: 'Group hint',
            notes: 'Notes hint',
            changeRoutingModal: 'Change routing hint',
            cost: 'You can define the cost of your traffic source and the type of action taken. You can choose between restore or update.',
            timeRange: 'Time range hint',
            timeZone: 'To accurately update the cost of your campaign, set the same time zone as your traffic source',
            notificationsTypes: 'Notifications types hint',
            campaignUrl: 'Campaign URL hint',
            campaignTestUrl: 'Campaign test URL hint',
            impressionUrl: 'Impression URL hint',
            landingPixel: 'Landing pixel hint',
            clickUrl: 'Click URL hint',
            multiOffer: 'Multi-Offer click URL hint',
            postbackUrl: 'Postback URL hint',
            postbackPixel: 'Postback pixel hint',
            postbackScript: 'Postback Script hint',
            campaignsNotes: 'Note of some description',
            verifyURLs: 'After setting up the Landing Page, you can verify to make sure it has been set up correctly.',
            landerUrl:
                "Enter your Lander URL in this field. If you use multiple landers in your setup, enter the first Lander URL here, which must redirect users to other Landers. After that, enable the ‘The page with the Click URL does not match the Lander's URL’ option below and pass there the Lander URL, that redirects users to the offer.",
            pageDoesNotMatchLanderURL:
                'Enable this option, if you use multiple landing pages in your setup. Enter the lander url, which' +
                ' will be used to redirected users to your offer.',
            clickUrlClassic:
                'This feature is used when you are using same Landing page inside multiple campaigns with different' +
                ' domains.Use the script below inside your Landing page by copying it before </body> tag.',
            protectLandingPage:
                "Enable this option, if you want to use Lander Protection Script to block all the visits to your lander page, that did not came through a campaign URL. This option will also allow you to block spying tools, that try to access your lander. Choose the default Lander Protection script type (PHP or Javascript) from the Settings – Tracking URLs menu. Paste the script right after the <head> tag in your lander's HTML file.",
            createSharedReport: 'Create shared report',
            offerTrackingMethod:
                "Offer Script is required to be inside your offer's HTML <body></body> tag when using Direct Tracking Method without Landing Page in order to get accurate tracking."
        },
        buttons: {
            showAll: 'Show all',
            less: 'Less',
            back: 'Back',
            cancel: 'Cancel',
            yesCloseForm: 'Yes, close form',
            apply: 'Apply',
            create: 'Create',
            downloadCsv: 'Download CSV',
            downloadXLSX: 'Download XLSX',
            save: 'Save',
            upload: 'Upload',
            edit: 'Edit',
            copy: 'Copy',
            copied: 'Copied!',
            expand: 'Expand',
            collapse: 'Collapse',
            add: 'Add',
            addDetails: 'Add details',
            addCustom: 'Add custom',
            addDomain: 'Add domain',
            addIntegration: 'Add integration',
            addNotifications: 'Add notification',
            importSettings: 'Import settings',
            resetToDefaults: 'Reset to defaults',
            resetDefaultOrder: 'Reset default order',
            listView: 'List view',
            clear: 'Clear',
            drilldown: 'Drilldown',
            report: 'Report',
            multipleReport: 'Multiple Report',
            export: 'Export',
            editCampaign: 'Edit Campaign',
            editOffer: 'Edit Offer',
            chart: 'Chart',
            closeChart: 'Close chart',
            charts: 'Charts',
            refresh: 'Refresh',
            archive: 'Archive',
            restore: 'Restore',
            duplicate: 'Duplicate',
            delete: 'Delete',
            invite: 'Invite',
            inviteUser: 'Invite user',
            editUser: 'Edit user',
            enable2FA: 'Enable 2FA',
            nextStep: 'Next step',
            createApiKey: 'Create API key',
            phpRecommended: 'PHP (Recommended)',
            javascript: 'Javascript',
            changePlan: 'Change plan',
            choosePlan: 'Choose Plan',
            currentPlan: 'Current Plan',
            backToPlans: 'Back to Plans',
            change: 'Change',
            checkDns: 'Check DNS',
            addWorkspace: 'Add workspace',
            close: 'Close',
            saveAndClose: 'Save & close',
            saveAndRestore: 'Save & Restore',
            saveAsNew: (item = '') => `Save as New ${item}`,
            next: 'Next',
            startImport: 'Start import',
            addConditions: 'Add conditions',
            ok: 'OK',
            done: 'Done',
            accept: 'Accept',
            start: 'Start',
            run: 'Run',
            pause: 'Pause',
            logs: 'Logs',
            webhook: 'Webhook',
            domainSwitch: 'Domain switch',
            changeRouting: 'Change Routing',
            switchRotation: 'Switch Rotation',
            pauseAnywhere: 'Pause anywhere',
            activateAnywhere: 'Activate anywhere',
            notification: 'Notification',
            newNotification: 'New notification',
            updateCost: 'Update cost',
            additionalRevenue: 'Additional Revenue',
            notifyMe: 'Notify me',
            update: 'Update',
            reset: 'Reset',
            remove: 'Remove',
            archiveElement: isMulti => `Yes, archive element${isMulti ? 's' : ''}`,
            restoreElement: isMulti => `Yes, restore element${isMulti ? 's' : ''}`,
            deleteElement: isMulti => `Yes, delete element${isMulti ? 's' : ''}`,
            deleteDomain: 'Yes, delete domain',
            deleteIntegration: 'Yes, delete integration',
            deleteConversion: 'Yes, delete conversion',
            deleteIt: 'Yes, delete it',
            clearStat: 'Yes, clear statistics',
            loadOriginalSettings: 'Load original settings',
            deleteFlag: 'Delete flag',
            later: 'Later',
            loadNewVersion: 'Load new version',
            scheduleDowngrade: 'Schedule downgrade',
            editBillingInfo: 'Edit Billing info',
            payAndSubscribe: moneyToPay => (moneyToPay ? `Pay ${moneyToPay} and ` : '') + 'Subscribe',
            canChangeSubscription: 'You can change Subscription',
            updatePayment: 'Update Payment',
            verifyURLsAndScripts: 'Verify URLs and scripts',
            showActualWeights: 'Show actual weights',
            newIntegration: 'New integration',
            addMonth: 'Add month',
            schedule: 'Schedule',
            deleteSchedule: 'Yes, delete schedule',
            createLink: 'Create Link',
            share: 'Share',
            editReport: 'Edit Report',
            stopAccess: 'Stop access',
            activateAccess: 'Activate access',
            login: 'Log In',
            signUp: 'Sign Up',
            copyReportLink: 'Copy Report Link',
            linkCopied: 'Link copied!',
            clickToCopyLink: 'Click to copy Link',
            continue: 'Continue'
        },
        radioButtons: {
            redirectOffer: 'Redirect offer, Offers in path',
            offerInPath: 'Offers in path, Redirect offer',
            destination404: 'Show 404 on the root page',
            destinationRedirect: 'Redirect root to a URL',
            campaign: 'Campaign',
            enabled: 'Enabled',
            paused: 'Paused',
            deleted: 'Deleted',
            tracker: 'Tracker',
            campaigns: 'Campaigns',
            landings: 'Landings',
            offers: 'Offers',
            metrics: 'Metrics',
            domainsBanCheck: "Domain's ban check",
            unavailability: 'Unavailability',
            payoutIncrease: 'Payout Increase',
            payoutDecrease: 'Payout Decrease',
            capOverload: 'CAP Overload',
            landers: 'Landers',
            disable: 'Disable',
            setWeight: 'Set weight to 0',
            setWeight100: 'Set weight to 100',
            pause: 'Pause',
            changeWeightTo: 'Change weight to',
            flowActionAccept: "Accept Flow's Path and Rules",
            getFlowActionOriginal: date => (
                <span>
                    Load <b>Original settings</b> saved on <b>{date}</b> before setting Flow
                </span>
            ),
            flowActionChange: 'Change Flow',
            getExportActionRows: quantity => <span>Selected rows ({quantity})</span>,
            exportActionPeriod: 'Period'
        },
        weekDays: {
            su: 'Su',
            mo: 'Mo',
            tu: 'Tu',
            we: 'We',
            th: 'Th',
            fr: 'Fr',
            sa: 'Sa'
        },
        alerts: {
            creditCardAdded: 'Credit card added successfully',
            selectedOffersHasConversion: 'The selected offer has conversion CAP',
            checkSubdomainAvailability: 'Enter a subdomain to check availability',
            conflictOfHideProfit: (
                <>
                    Conflict of <b>Hide profit</b> options for selected workspaces. Please configure for this user or
                    select one workspace.
                </>
            ),
            campaignHasOriginalSettings: date => (
                <span>
                    Campaign has the <b>Original settings</b> saved on <b>{date}</b> before settings Flow
                </span>
            ),
            newPath: 'New path',
            landingPageProtectionWarnFirst:
                'Use PHP version of the script, only if the server, that hosts your landing page, supports PHP.',
            landingPageProtectionWarnSecond:
                'Javascript version of the script is not the most secure option, as the script is evaluated in the browser, it is possible to remove it and access content of your lander page.',
            landingPageCampaignsCountWarn: count => (
                <>
                    You cannot change the <b>Number of Offers</b> bacause this Lander is used in{' '}
                    <b>{count} Campaigns</b>
                </>
            ),
            addCustomDomainInfo: (
                <>
                    <span>Please note that updating DNS records may take up to 24 hours.</span> <br />
                    <span>Usually it takes 15-20 minutes</span>
                </>
            ),
            addCustomDomainDanger: 'mydomain.com → No CNAME record for this domain',
            getCheckDNSText: result => (result ? 'CNAME added on this domain' : 'No CNAME record for this domain'),
            newNotificationEmail: 'Email notifications will be sent to specified email',
            newNotificationTelegram: 'Telegram notifications will be sent to specified bot',
            newNotificationSMS: 'SMS notifications will be sent to specified number',
            newNotificationWebhook: (
                <>
                    Telegram notifications will be sent to specified bot. For more information, check{' '}
                    <a data-test="new-notification-webhook-link" href="/tools/notifications">
                        this manual.
                    </a>
                </>
            ),
            toolsConversionUpload: 'Enter one clickid per line. Only clickid is required, other values are optional.',
            toolsConversionUpload2: 'If you type more than one value in a line, separate each of them with a comma.',
            toolsConversionUpload3: (
                <>
                    In order to increase the number of conversions by one, type one clickid value; for example:{' '}
                    <b>d495RGB7KJWPFTM82686PL7L</b>
                </>
            ),
            toolsConversionUpload4:
                'You cannot add more than one line with the same clickid, unless the “Accept duplicate postbacks” option is enabled in the affiliate network element.',
            toolsConversionUpload5: (
                <>
                    You can update the number of conversions, payout, and transaction ID. For that, type these comma
                    separated values in the following order: 1. clickid, 2. payout amount, 3. transaction ID; for
                    example: <b>d495RGB7KJWPFTM82686PL7L,15.5,txid457</b>
                </>
            ),
            toolsConversionUpload6: (
                <>
                    You can update all the following values at once: 1. clickid, 2. payout amount, 3. transaction ID, 4.
                    conversion type; for example: <b>d495RGB7KJWPFTM82686PL7L,15.5,txid457,install</b>
                </>
            ),
            toolsConversionUpload7: (
                <>
                    You can leave one of the properties blank, if you do not need to update it; for example, you want to
                    update the conversion type, but there is no transaction ID, leave the field of the transaction ID
                    value blank:
                    <b>d495RGB7KJWPFTM82686PL7L,15.5,,install</b>
                </>
            ),
            toolsImportSuccess: 'Success. Import of 97 items completed',
            newFilteringRule: (
                <>
                    Define a filter name and add at least one IP / IP range or user agent value. <br />
                    <b>You can add up to 50 values within a filtering rule.</b> <br />
                    One value means one line that is populated with an IP / IP range or user agent string.
                </>
            ),
            domainsBanCheck: (
                <>
                    <a data-test="domains-ban-check-link" href="#s">
                        Google API key
                    </a>{' '}
                    not configured
                </>
            ),
            triggersSwitchRotation: 'Enables selected rotation in campaign.',
            triggersActionsPause: (
                <>
                    Disables landing or offer in <b>all campaigns</b>. By enabling <b>Set weight to 0</b>, you will
                    disable elements by changing its Weight instead of switching it off completely. This setting will
                    help in case you have only one landing/offer in your path and it can’t be shut down completely.
                </>
            ),
            triggersActionsActivate: (
                <>
                    Enables landing or offer in <b>all campaigns</b>. By enabling Set Weight, you set weight equal to
                    100 for activated elements. This setting will help in case you disabled these elements via{' '}
                    <b>Pause Everywhere</b> action with <b>Set weight to 100</b> enabled.
                </>
            ),
            triggersConversionRecord: (
                <>
                    Each Offer&#8217;s click triggers a conversion record.&#32;
                    <b>Conversion=Click, Revenue=&#123;Clicks&#125;*&#123;Payout&#125;</b>
                </>
            ),
            changeRoutingModal:
                'Disables or changes weight of selected routing element (path, landing, offer) in selected campaign',
            switchDomainModal: 'Replaces landing’s domain to a randomly selected from the list',
            manualCostUpdate:
                "You can manually modify the campaign's cost in a selected time range (< 32 days). Manual cost update is designed to make updates a maximum of 60 times per one time frame. The time frame is 1 h. If you try to update the cost more frequently for the same hour, all updates will be rejected",
            emptyCriteria: 'Criteria cannot be empty',
            selectOffer: 'Select an offer in a path',
            selectLander: 'Select or create a Lander',
            changeTrackingMethod: 'Remove all campaigns before changing the Tracking method type',
            urlChangeOutside: 'This campaign was flagged because the URL was changed outside of the settings',
            saveCampaignToGenerateURLs: 'Save the Campaign to generate URLs',
            planWillBeApplied:
                "Downgrade plan doesn't apply instantly. It will be applied from the next billing month.",
            renewPlanAlert:
                'Your account is currently overdue. Please complete payment or downgrade Subscription to Free.',
            planWasChanged: 'Plan was changed',
            unlimitedBillingAlertMessages: (
                <ul>
                    <li>It doesn’t have a Trial Period</li>
                    <li>You can’t change the Plan and Plan’s limit doesn’t work</li>
                    <li>No payment method required</li>
                    <li>All invoices will be paid automatically</li>
                </ul>
            ),
            isOptimizationWeightsText: (alreadyHave, addMax, max) => (
                <>
                    <b>Max Landers*Offers={max}</b> for an optomized path. You already have <b>{alreadyHave}</b> and can
                    max <b>{addMax}</b>.
                </>
            ),
            maxItemsInPath: (value, type) =>
                `Maximum ${value} ${type}. Remove ${capitalize(type)} from path to add more.`,
            clickUrlPageExample: (
                <>
                    For instance, if you have three lander pages in your setup:{' '}
                    <b>
                        “https://lander.com/<span className="color--froly">number1</span>”
                    </b>
                    ,{' '}
                    <b>
                        “https://lander.com/<span className="color--froly">number2</span>”
                    </b>{' '}
                    and{' '}
                    <b>
                        “https://lander.com/<span className="color--froly">number3_offer</span>”
                    </b>
                    , you will need to enter the first lander page in the ‘Lander URL’ field above. The third Lander
                    page, where your offer is placed must be entered here, in the ‘Click URL page’ field.
                    <div className="mtb8">Check this option and enter the URL to verify Click URL setup.</div>
                    <b>Note: </b>
                    <span className="color--froly">
                        Only visits to the first lander page and clicks on the Click URL will be tracked, when using
                        this setup.
                    </span>
                </>
            ),
            capOfferDoesntWorkWithDirect: "CAP offer doesn't work with the Direct tracking method",
            weightsOptmizationAlgorithmLast:
                'For instance, your rule based path gets 100 visits every 10 minutes. In this case, the first optimization will be performed in 50 minutes for the first 500 visits. After that, optimization will be performed again in 10 minutes, for the last 500 visits (for the visits from 100 to 600) and so on.',
            weightsOptmizationAlgorithmEvery:
                'For instance, your rule based path gets 100 visits every 10 minutes. In this case, the first optimization will be performed in 50 minutes for the first 500 visits. After that, the algorithm will wait until you get another 500 new visits from the moment of the last optimization. In 50 minutes, when you get another 500 visits and your total visits will be 1000, the algorithm will perform next optimization (for the visits from 500 to 1000) and so on.'
        },
        checkboxes: {
            includeEmptyReferrer: 'Include empty referrer',
            includeEmptyVariable: 'Include empty variable',
            objectSleepTime: 'None (first execution only)',
            push: 'Push (mobile & desktop)',
            email: 'Email'
        },
        errors: {
            accountActivationError: 'Account activation error!',
            passwordResetError: 'Password reset error!',
            passwordChangingError: 'Password changing error!',
            signingUpError: 'Signing up error!',
            wrongEmailPassword: 'Wrong E-mail or Password!',
            deleteError: 'Delete error!',
            archiveError: 'Archive error!',
            restoreError: 'Restore error!',
            changeError: 'Change error!',
            notFound: 'Item not found!',
            error: 'Error!',
            saveError: 'Save error!',
            email: 'Please enter a valid email address',
            typeNameForCampaign: 'Type a name for a campaign',
            typeNameForFlow: 'Type a name for a flow',
            same: otherField => `Please enter the same value as ${otherField}`,
            required: (field = 'This field') => `${field} is required`,
            requiredShort: 'Required',
            charactersNumberLimit: value => `Must have ${value} characters`,
            charactersNumberLimitShort: value => `Must have ${value} chars`,
            min: (field = 'This field', val) => `${field} must contain at least ${val} chars`,
            max: (field = 'This field', val) => `${field} must contain at most ${val} chars`,
            reCaptcha: 'reCAPTCHA confirmation is required',
            anToken: 'Please fill out the AN tokens for Click ID above ',
            startUrlWithHttp: 'Mind that the URL must start with http:// or https://',
            allGood: 'All good.',
            missingTrackingLinks: 'Missing tracking links.',
            addRedirectlessTrackingScript: 'add redirectless tracking script',
            addClickUrls: 'add click URL(s)',
            incorrectLanderStatusCode: 'Incorrect lander status code.',
            incorrect: 'Incorrect',
            incorrectYear: 'Incorrect year',
            incorrectMonth: 'Incorrect month',
            incorrectDate: 'Incorrect date',
            enableToReachTheHost: 'enable to reach the host',
            payoutValueIsRequired: 'Payout value is required',
            invalidPayoutValue: 'Invalid payout value',
            emptyConversions: 'Conversions cannot be empty.',
            ipRangeRequired: 'An IP / IP range value is required.\n',
            invalidPassword: 'Invalid password',
            invalidToken: 'Invalid token',
            badCredentials: 'Bad credentials',
            badRequest: 'Bad Request',
            noCustomConversionsList: "Couldn't get a list of additional columns",
            domainAlreadyExists: 'Domain name already exists',
            maximumLengthDomainName: size => `Maximum length is ${size} characters`,
            timePickerFormat: 'HH:MM format',
            incorrectValue: 'Incorrect value',
            badCardCredentials: 'The card credentials is incorrect',
            expirationCardPast: "Your card's expiration date is in the past",
            expirationCardFuture: "Your card's expiration date is more than 5 years",
            logOutFirst: 'Please, log out first',
            maxCharactersLength: length => `Max length is ${length} characters`,
            loadDataError: 'An error occurred while loading data',
            promoCodeCheckError: 'Promo code check error'
        },
        texts: {
            getTextForArchiveFlowOriginAction: date => `Load Original settings saved on ${date} before setting Flow`,
            getTextForArchiveFlowChangeAction: name => `Change Flow to ${name}`,
            getTextForArchiveFlowAcceptAction: () => 'Accept Path and Rules of this Flow as Original Settings',
            getTextEmailResetPasswordHasBeenSent: email => (
                <>
                    Email with reset password instructions has been sent to <b>{email}</b>
                </>
            ),
            youHaveBeenRegistered: 'You have been registered',
            checkYourEmailForFurtherInstructions: 'Check your email for further instructions.',
            clicksAreUsed: (
                <>
                    Click URL is the URL that user clicks on Landing page to get to an offer website.
                    <br />
                    You need to copy and paste Click URL inside your&nbsp;
                    <b>&lt;button href=&apos;Click URL here&apos;&gt;</b> tag.
                    <br />
                    For Multi-offer click URL just do the same and add <b>/1,/2,/3</b> etc. at the end of your Tracker
                    domain URL.
                </>
            ),
            toTrackConversion:
                'In order to track conversion types, define a custom conversion event. It allows you to track more than one conversion type within a campaign. The custom conversion types are displayed in reports to measure the campaign’s performance more accurately.',
            'referral-program':
                'Involve users and receive a regular income from all their payments. Use the links below to attract your referrals. All registered users will be permanently added to your referrals. To all visitors, who came from your link, we will set the cookie for 30 days - registration in that period will be counted as your referral.',
            columnsNumber: (num, total) => `${num} from ${total} columns`,
            affNetworkNameConfiguredTemplate: name => `For ${name} configured Offer URL template`,
            warningModal: type =>
                `You have entered changes to the ${
                    type ? `${type}'s` : ''
                } form. If you close the form now, you will lose all input.`,
            securityEnterPassword: 'For security please enter your password for ',
            scanQR: '1. Open Google Authenticator and scan QR-code',
            enterCode: '2. Enter the code from Google Authenticator',
            apiAccessKeys:
                "Please save the secret key in a safe place. You won't be able to see it after closing the window.",
            serverToServerPostback:
                'Server-to-server (S2S) postback URLs are not cookie-based and allow servers to communicate with each other directly. As a result, this is the most secure option to track conversions.',
            billingAddPlanFooterText: [
                'An event should be defined as every visit/click/conversion',
                'Overage charge occurs when the plan’s included events limit is exceeded.',
                'Plans should be paid upfront. Overage should be paid at the end of each month of using.'
            ],
            conversionTrackingPixel:
                'Use this option if your offer only supports cookie-based conversion tracking pixels.',
            conversionTrackingPixelNote: (
                <span>
                    <b>Note: </b>
                    Always verify that the domain used in the pixel is the same as the domain used for your campaign
                    URL.
                </span>
            ),
            trackingUrlsChanged: item => `You cannot revert to the previous settings after saving the ${item}.
                    Please be careful, tracking urls have changed.`,
            secureMethod: (
                <span>
                    Powered by{' '}
                    <a href="https://www.authorize.net/" target="_blank" rel="noreferrer">
                        Authorize.net
                    </a>
                </span>
            ),
            settingsApiAccessKeys:
                'Use access keys to make secure REST or HTTP Query protocol requests to service APIs. For your protection, you should never share your secret keys with anyone',
            customConversions:
                'You can track conversion types by defining a custom conversion event. This enables you to track several conversion types within one campaign. Added custom conversion types will be visible in reports. This lets us measure the performance of your campaign more accurately.',
            trackingDomains:
                'Here you can add your custom tracking domains. We highly recommend adding a custom domain. The default tracker.com is meant for testing purposes only.',
            redirectDomain:
                'If you redirect visitors using the Double meta refresh option in the campaign configuration, the redirect domain name will show up in their browser’s address bar. We highly recommend adding a custom redirect domain and set it up the same way as a custom domain.',
            twoFactorAuthAlertText:
                'Two-step authentication adds an extra layer of protection to your account. Whenever you sign in to our website, you will need to enter both your password and also a security code from mobile phone app.',
            s2sPostbackSteps: 'Follow these steps to make sure your postback URL works correctly',
            clickUrlText:
                'Copy and paste it on your landing page, if the page has a single offer or rotates many in one placement.',
            multiOfferText:
                'If there is more than one offer placement on the page, copy the URL, change the value appended to the end of the click URL with a corresponding offer number, and paste all URLs on the landing page. For three offer placements on the landing page, there should be three URLs.',
            noteText:
                'Double-check if the domain used in the click URL/multi-offer click URL matches exactly to the domain used for the campaign URL.',
            copyPostbackUrl: "Copy the postback URL to the affiliate network's global conversion tracking settings.",
            // addCustomDomainItem1: "1. Read the instructions to create a CNAME record in your registrar's panel",
            // addCustomDomainItem2: '2. Create CNAME Record For Your Domain That Points To:',
            // addCustomDomainItem3: '3. Your CNAME record should look like this:',
            // addCustomDomainItem4: '4. Insert your domain and check DNS',
            // addCustomDomainItem5: '5. Domain settings',
            addCustomDomainItem2: 'Create CNAME Record For Your Domain That Points To:',
            addCustomDomainItem3: 'Your CNAME record should look like this:',
            addCustomDomainItem4: 'Insert your domain and check DNS',
            addCustomDomainItem5: 'Domain settings',
            importProcess: 'Import process is ready to start',
            importProcess2: 'items to import',
            ipuaFiltering:
                'To exclude certain traffic events from your campaign reporting, add filtering rules based on IP / IP range and user agent values.',
            ipRangeInputTextRight: 'Enter one IP / IP range per line.',
            userAgentInputTextRight: 'Enter one user agent per line.',
            antiFraudKit: (
                <>
                    Set up filters to manage the bot traffic in your campaigns according to the conditions selected in{' '}
                    <b>Bot Detection Rules section.</b>
                </>
            ),
            getWillBeArchivedSubtitle: (entity = 'Entity', isDelete) => (
                <>
                    <b>{entity}</b> will be <>{isDelete ? 'deleted' : 'archived'}</>
                </>
            ),
            getWillBeRestoredSubtitle: (entity = 'Entity') => (
                <>
                    <b>{entity}</b> will be <>restored</>
                </>
            ),
            getCouldNotBeArchivedSubtitle: (entity = 'Entity', isDelete) => (
                <>
                    <span>{entity}</span> could not be <>{isDelete ? 'deleted' : 'archived'}</>, because it is used in:
                </>
            ),
            getCouldNotBeSavedByWorkspaseSubtitle: (entity = 'Entity') => (
                <>
                    <span>{entity}</span> could not be saved, because the entity below has a different workspace:
                </>
            ),
            archiveElement: isMulti =>
                `Campaign${
                    isMulti ? 's' : ''
                } with archived elements will still receive traffic and can be restored at any moment.`,
            waitPlease: 'Wait, please...',
            restoreElement: isMulti => `Do you want to restore the selected element${isMulti ? 's' : ''}?`,
            deleteElement: isMulti => `Do you want to delete the selected element${isMulti ? 's' : ''}?`,
            areYouSure: 'Are you sure?',
            youWontBeAbleRevert: "You won't be able to revert this!",
            campaignSettings: 'Campaign settings',
            needToLogOut: 'You need to Log out of your account to complete this action',
            newVersion:
                'New version is available. You have to reload the app to get new version. Do you want to do this' +
                ' right now?',
            unlimited: 'Unlimited',
            inSettings: 'In settings',
            free: 'Free',
            recommended: 'Recommended',
            forProfessionals: 'For professionals',
            forUsers: 'For users who want to do more',
            youWillPay: 'The amount you will pay monthly starting from',
            paymentAmountToday: 'Payment amount today',
            youHaveAlreadyPaid: (money, planName) => `You have already paid ${money} for the ${planName} Plan`,
            noPaymentMethod: 'No payment method provided',
            billingPeriod: (dateFrom, dateTo, cost) =>
                `Billing Period— ${dateFrom} to ${dateTo}` + (cost ? ` / ${cost} per 1,000` : ''),
            getDomainsLimitText: (plan = 'Free', limit = 5) => `${limit} domains limit exceeded for ${plan} plan`,
            events: 'Events',
            daysLeft: 'Days left',
            cardInformation: 'Card information',
            creditCard: 'Credit Card',
            payPal: 'PayPal',
            visa: 'Visa',
            masterCard: 'MasterCard',
            americanExpress: 'AmericanExpress',
            discover: 'Discover',
            pathOverLimit: type => `These ${type} are over the limit and will be removed after saving`,
            bothCombinedLimit: `Both combined: max ${LANDER_AND_OFFER_OPTIMIZATION_LIMIT} combinations of landers and offers`,
            bothCombinedActualWeights: `Both combined - actual weights`,
            credit: 'Credit',
            creditWillGoAutomatically:
                'The credit will go toward your invoices automatically. We will charge your primary payment method after you spend the credit.',
            balanceWithPromocodeTitle: balance => `${balance} was successfully added to your account.`,
            balanceWithPromocodeText: `This credit will go toward your invoices automatically. We will charge your primary payment method after you spend the promotional credit.`,
            copyAndPasteScriptIntoLanderPage: (
                <>
                    If you are using Multi-Step Landing Page write the script inside the last Landing Page, which is
                    leading to an offer.
                </>
            ),
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            lastOptimization: 'Last optimization',
            algorithm: 'Algorithm',
            visits: 'visits',
            numberVisitsForOptimization: 'Number of visits for optimization',
            editSettings: 'Edit settings',
            waiting: 'waiting',
            promoCodeRemoved: 'Promo code removed',
            promoCodeApplied: code => (
                <>
                    <span>{code}</span> applied!
                </>
            ),
            promoCodeCredit: credit => `Credit $${credit} to your account`,
            supportAccessInfo: (
                <>
                    If you want to give support access to your account to help with checks and solve some issues, you
                    need to enable <b>Always allow or Create Schedule</b>.
                </>
            ),
            integrationLoadError: "Can't get campaign data from traffic source"
        },
        jsx: {
            ConversionUpload: () => (
                <div className="settings-section__info">
                    <div>Enter one clickid per line. Only clickid is required, other values are optional.</div>
                    <div>If you type more than one value in a line, separate each of them with a comma.</div>
                    <div>
                        <ul>
                            <li>
                                Enter only a clickid value, if you want to increase the number of conversions by one;
                                for example: <b>d495RGB7KJWPFTM82686PL7L</b>
                                <div className="settings-section__info settings-section__info--white">
                                    You cannot add more than one line with the same clickid unless the Accept duplicate
                                    postbacks option is enabled in the affiliate network entity.
                                </div>
                            </li>
                            <li>
                                If you want to update a number of conversions, payout, and transaction ID, enter a
                                clickid value, payout amount,and transaction ID; <br />
                                for example: <b>d495RGB7KJWPFTM82686PL7L,10.0,txid123</b>
                            </li>
                            <li>
                                If you want to update all properties, enter a clickid value, payout amount, transaction
                                ID, and conversion type; <br />
                                for example: <b>d495RGB7KJWPFTM82686PL7L,10.0,txid123,install</b>
                            </li>
                            <li>
                                If you do not need to update one of the values, leave it blank; <br />
                                for example, if there is a conversion type, but no transaction ID, leave the transaction
                                ID value blank: <br />
                                <b>d495RGB7KJWPFTM82686PL7L,10.0,,install</b>
                            </li>
                        </ul>
                    </div>
                </div>
            ),
            SectionInfo: () => (
                <div className="settings-section__info">
                    <div>
                        <b>Click URL</b>: Copy and paste it on your landing page, if the page has a single offer or
                        rotates many in one placement.{' '}
                    </div>
                    <div>
                        <b>Multi-offer click URL</b>:If there is more than one offer placement on the page, copy the
                        URL, change the value appended to the end of the click URL with a corresponding offer number,
                        and paste all URLs on the landing page. For three offer placements on the landing page, there
                        should be three URLs.{' '}
                    </div>
                    <div>
                        <b>Examples:</b>
                    </div>
                    <div>
                        http://
                        <span className="settings-section--accent">your_tracker_domain.com</span>
                        /click/1;
                    </div>
                    <div>
                        http://
                        <span className="settings-section--accent">your_tracker_domain.com</span>
                        /click/2;
                    </div>
                    <div>
                        http://
                        <span className="settings-section--accent">your_tracker_domain.com</span>
                        /click/3;
                    </div>
                    <div className="mt15">
                        <b>Note:</b>{' '}
                        <span className="settings-section--danger">
                            Double-check if the domain used in the click URL/multi-offer click URL matches exactly to
                            the domain used for the campaign URL.
                        </span>
                    </div>
                </div>
            ),
            SectionInfo2: () => (
                <div className="settings-section__info settings-section__info--yellow">
                    <b>Note:</b> After copying the postback URL / tracking pixel, you need to replace the{' '}
                    <b>OPTIONAL</b> placeholder with a conversion type-specific token supported by your affiliate
                    network.
                </div>
            ),
            addCampaignsInfo: () => (
                <div>
                    <p className="campaign-main-option__add-campaigns-info">
                        Add campaigns here to set the rotation by this Flow.
                    </p>
                    <span className="campaign-main-option__add-campaigns-info-small">
                        {/* <b>The Original campaign settings will be saved if they exist.</b> */}
                        The Original campaign settings will be saved if they exist.
                    </span>
                </div>
            ),
            loadOriginalSettings: (campaignsQuantity = 0) => (
                <span>
                    Load <b>Original settings</b> ({campaignsQuantity} campaign)
                </span>
            ),
            manualCostUpdate: timeRange => (
                <span>{`You can change the campaign’s cost in a selected time range (< ${timeRange} days). You can manually update the cost a maximum of 60 times per one time frame, which is one hour.  All cost updates will be rejected if you try to update the cost more frequently for the same hour.`}</span>
            ),
            errorOfMismatchOfDirectTracking: trafficSource => (
                <span className="flow-rules__mismatch-direct-tracking-error">
                    Selected Tr. Source{' '}
                    <b>
                        {
                            // eslint-disable-next-line react/destructuring-assignment
                            trafficSource?.name
                        }
                    </b>{' '}
                    has <b>Direct tracking</b> type
                </span>
            )
        },
        toasts: {
            invitationSent: 'Invitation sent',
            saved: 'Saved',
            saveError: 'Save error!',
            loadingError: 'An error occurred during the download',
            savedAsFlow: 'Rotation settings saved as Flow',
            wasSaved: ' was saved',
            notSaved: 'Not saved',
            deleted: 'Deleted',
            archived: 'Archived',
            deletedError: 'Delete error!',
            changeError: 'Change error!',
            restored: 'Restored',
            created: 'Created',
            changed: 'Changed',
            accepted: 'Accepted',
            registered: 'Registered',
            defaultDomainChanged: 'Default domain changed',
            apiKeyAdded: 'API access key added successfully',
            passwordChanged: 'Password changed successfully',
            updateCostStarted: 'Update cost in progress',
            notificationWasSwitched: (type, values) =>
                type === 'isEnabled'
                    ? `Notification was ${values?.isEnabled ? 'enabled' : 'disabled'}`
                    : `System message was ${values?.isSystemMessage ? 'enabled' : 'disabled'}`,
            notificationWasDeleted: 'Notification was deleted',
            notificationWasRestored: 'Notification was restored',
            ipFilteringToggled: enabled => `IP/UA filtering was ${enabled ? 'enabled' : 'disabled'}`,
            modifiedChapterDeletedSuccessful: (modifiedChapter = 'Modified chapter') =>
                `${modifiedChapter} deleted successfully`,
            invalidEmailToken: 'Expired, already confirmed or invalid token',
            getEntityWasSuccessfullyArchived: (entity = 'Entity') => `${entity} was successfully archived`,
            getEntityWasSuccessfullyRestored: (entity = 'Entity') => `${entity} was successfully restored`,
            commonError: 'Something is wrong, please try again',
            noNetworkConnection: 'Please check your network connection and reload page',
            paymentMethodAdded: 'Payment method successfully added',
            planUpgrade: 'Plan successfully upgraded',
            planDowngrade: 'Plan downgrade successfully scheduled',
            dateRangeLimit:
                'The selected time range exceeds the data retention included in your subscription plan. You can upgrade to extend your data retention and access older data.'
        }
    }
};

export default Langs;
