import React, { useState } from 'react';

import FormActions from '../../../Forms/FormActions/FormActions';

export const getTextData = ({ value }) => value.join(', ');
export const getTextCount = value => value.length;

const TextPicker = ({
    label,
    onChange,
    isEnabled,
    isMatched,
    value,
    placeholder,
    isIncludedEmpty,
    isNotText,
    inputLabel
}) => {
    const [editValue, setEditValue] = useState(value.join('\n'));

    const handleListConfirm = () => {
        const newValue = editValue?.split(/[,\n]/)?.reduce((res, item) => {
            if (item) res.push(item.trim());
            return res;
        }, []);

        onChange(newValue, 'value');

        setEditValue(newValue.join('\n'));
    };

    const clearValue = () => {
        onChange([], 'value');
        setEditValue('');
    };

    return (
        <FormActions
            label={label}
            isEnabled={isEnabled}
            onChange={onChange}
            isMatched={isMatched}
            onClear={clearValue}
            textFieldValue={editValue}
            onTextFieldEdit={e => setEditValue(e.target.value)}
            handleEditConfirm={handleListConfirm}
            placeholder={placeholder}
            isIncludedEmpty={isIncludedEmpty}
            isNotText={isNotText}
            inputLabel={inputLabel}
        />
    );
};

export default TextPicker;
