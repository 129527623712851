import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';
import { getResponseErrorMessage } from '../../../../Functions/utils';

import { useDownloadFileMutation } from '../../../../redux/api/downloadApi';

import { billingAndSubscriptionCols } from '../constructor';

import ItemsTable from '../../../Overall/ItemsTable/ItemsTable';
import TablePagination from '../../../Overall/UI/TablePagination/TablePagination';
import Section from '../../../../components/section/section';
import SectionHeader from '../../../../components/section-header/section-header';
import Loader from '../../../Loader';

import './invoices.scss';

export const Invoices = ({ invoices, pagination, setPagination, isFetching, currencies }) => {
    const txt = Langs[global.lng];

    const [isProcessing, setIsProcessing] = useState(false);

    const rows =
        invoices?.map(row => ({
            ...row,
            actions: [{ type: 'download' }]
        })) || [];

    const [loadPdf, { isSuccess: isLoadPdfSuccess, isError: isLoadPdfError, error: loadPdfError }] =
        useDownloadFileMutation();

    useEffect(() => {
        if (isLoadPdfSuccess) setIsProcessing(false);
    }, [isLoadPdfSuccess]);

    useEffect(() => {
        if (isLoadPdfError) {
            toast.error(getResponseErrorMessage(loadPdfError.data, txt.toasts.loadingError));
            setIsProcessing(false);
        }
    }, [isLoadPdfError]);

    const load = (type, item) => {
        setIsProcessing(true);

        loadPdf({
            url: `invoices/${item.id}/pdf`,
            fileName: `invoice-${moment(item.createdAt).format('YYYY-MM-DD')}.pdf`
        });
    };

    const sectionHeader = (
        <SectionHeader
            title={txt.labels.invoices}
            pagination={
                <TablePagination
                    value={pagination}
                    onChange={data => setPagination(data)}
                    disabled={isFetching || !rows.length}
                />
            }
        />
    );

    return (
        <Section Header={sectionHeader} className="mb10 invoices">
            {invoices?.length && (
                <ItemsTable
                    cols={billingAndSubscriptionCols.invoices}
                    rows={rows}
                    onActionClick={load}
                    className="thead-decorate"
                    currencies={currencies}
                />
            )}

            {(isFetching || isProcessing) && <Loader isFetching />}
        </Section>
    );
};

export default Invoices;
