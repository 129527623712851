import React, { useState } from 'react';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';

import './confirm-with-password-modal.scss';

const ConfirmWithPasswordModal = ({ onClose, onChange, onConfirm }) => {
    const txt = Langs[global.lng];

    const [userPassword, setUserPassword] = useState('');
    const [isConfirmClick, setIsConfirmClick] = useState(false);

    return (
        <>
            <div className="confirm-with-password-modal">
                <p className="confirm-with-password-modal__title">To confirm this change, please enter your password</p>

                <TextInput
                    label={txt.labels.password}
                    placeholder={txt.placeholders.password}
                    type="password"
                    icon="password"
                    value={userPassword}
                    error={isConfirmClick && !userPassword && txt.errors.required(txt.labels.password)}
                    onChange={({ target: { value } }) => {
                        setUserPassword(value);
                        onChange('userPassword', value);
                    }}
                />
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.continue}
                confirmButtonType="danger"
                onConfirm={() => {
                    setIsConfirmClick(true);

                    if (userPassword) {
                        hideModal();
                        onConfirm();
                    }
                }}
                onReject={onClose}
            />
        </>
    );
};

export default ConfirmWithPasswordModal;
