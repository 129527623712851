import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';

import Langs from '../../../Langs';
import { showModal } from '../../../redux/operations';
import { MAIN_CHAPTERS, CHAPTER_REPORTS, DISPLAY_MODE_STATUS } from '../../../Constants';
import { camelToKebabCase } from '../../../Functions/utils';

import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetGeneralSettingsQuery } from '../../../redux/api/settingsApi';

import { mainTableConstructor } from '../constructors/mainTableConstructor';
import MainTableBody from './MainTableBody';
import MainTableHeader from './MainTableHeader';
import DownloadFile, { ShowDownloadFileCheckbox } from '../../Settings/ColumnsSettings/DownloadFile';
import NoData from './NoData';
import TablePreloader from '../../Overall/UI/TablePreloader/TablePreloader';

const getAllProfits = rows =>
    rows
        .reduce((acc, row) => {
            const result = [];
            result.push(row.statistic?.profit ?? row.profit);
            return row?.children?.length ? [...acc, ...result, getAllProfits(row.children)] : [...acc, ...result];
        }, [])
        .flat();

const MainTableItems = ({
    chapter,
    report,
    dataForRender,
    dateRange,
    columns,
    isCompareDateRanges,
    rows,
    onChange,
    setRows,
    handleChange,
    activeRow,
    onRowClick,
    onRowContextMenu,
    isColoredRows,
    isVerticalBorders: initialVerticalBorders,
    showTotalCheck,
    isLoading,
    isSharedReportPage,

    sorting,
    columnFilters,
    columnsWidths,
    total,

    storage
}) => {
    const txt = Langs[global.lng];
    const isVerticalBorders = initialVerticalBorders || storage?.common?.isVerticalBorders || false;

    // getDataForTdMoney
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery(undefined, {
        skip: isSharedReportPage
    });
    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery(undefined, {
        skip: isSharedReportPage
    });

    const isAllLoaded = !isLoading && (!isSharedReportPage ? isCurrenciesLoaded && isGeneralSettingsLoaded : true);

    const columnsWidth = (() => {
        if (columnsWidths) return columnsWidths;
        if (chapter === 'reports') return report?.columnWidths ?? [];
        if (CHAPTER_REPORTS.includes(chapter)) return storage.chapter_reports.columnWidths ?? [];
        return storage?.[chapter]?.columnWidths ?? [];
    })();

    useEffect(() => {
        if (columnsWidth?.length === 0 && isAllLoaded && rows.length > 0) {
            const resizableCells = Array.from(document.querySelectorAll('div[data-key]'));

            const allColumnsWidths = resizableCells.map(cell => ({
                key: cell.dataset.key,
                width: cell.clientWidth + (cell.dataset.key === 'important' ? 1 : 0)
            }));

            handleChange(allColumnsWidths, 'columnWidths');
        }
    }, [columnsWidth, isAllLoaded]);

    const history = useHistory();

    const renderNoData = () =>
        MAIN_CHAPTERS.includes(chapter) &&
        !dataForRender?.search &&
        !dataForRender?.columnFilters.length &&
        storage?.[chapter]?.displayMode !== DISPLAY_MODE_STATUS.ARCHIVE ? (
            <NoData
                onClick={() => history.push(`/home/${camelToKebabCase(chapter)}/new`)}
                label={txt.singulars[chapter] || ''}
            />
        ) : (
            <NoData />
        );

    const showDownloadFile = storage?.common?.showModals?.DownloadFile && rows?.length;
    useHotkeys(
        'ctrl+c, command+c',
        () => {
            // FIXME: change logic
            const isIncludeExportBtn = document.querySelector('.table-control_export');
            const isOpenModal = document.querySelector('.modal2');

            if (showDownloadFile && isIncludeExportBtn && !isOpenModal) {
                showModal({
                    title: txt.labels.downloadFile,
                    className: 'exportFileModal',
                    HeaderRightItem: ShowDownloadFileCheckbox,
                    body: props => (
                        <DownloadFile
                            {...props}
                            chapter={chapter}
                            rows={rows}
                            columns={columns}
                            dataForRender={dataForRender}
                            dateRange={dateRange}
                        />
                    )
                });
            }
        },
        {},
        [showDownloadFile, chapter, rows, columns, dataForRender, dateRange]
    );

    const getExtendedCollumns = mainColumns => {
        if (!isCompareDateRanges) return mainColumns;
        if (CHAPTER_REPORTS.includes(chapter)) return mainColumns;

        return mainColumns.reduce((ac, item) => {
            if (item.supplement) {
                const additionalItem = mainTableConstructor.comparisonColumns.find(el => el.key === item.supplement);
                additionalItem.rounding = item.rounding;
                return [...ac, item, additionalItem];
            }
            return [...ac, item];
        }, []);
    };

    const sortedColumns = useMemo(
        () => getExtendedCollumns(columns.filter(item => !item.isHide).sort((a, b) => a.order - b.order)),
        [columns, isCompareDateRanges]
    );

    const getProfitsMinMax = (isMoreLess, isMinMax) => {
        const profits = getAllProfits(rows);

        const processedProfits = profits.filter(el => (isMoreLess === '>' ? el > 0 : el < 0));
        switch (isMinMax) {
            case 'max':
                return processedProfits.length > 0 ? Math.max(...processedProfits) : 0;
            case 'min':
                return processedProfits.length > 0 ? Math.min(...processedProfits) : 0;
            default:
                throw new Error('isMinMax is incorrect');
        }
    };

    const coloredConfig = {
        positiveMin: getProfitsMinMax('>', 'min'),
        positiveMax: getProfitsMinMax('>', 'max'),
        negativeMin: getProfitsMinMax('<', 'min'),
        negativeMax: getProfitsMinMax('<', 'max')
    };

    return (
        <>
            <div className={cn('items-table', { 'items-table--loading': !isAllLoaded })}>
                <MainTableHeader
                    chapter={chapter}
                    cols={sortedColumns}
                    rows={rows}
                    total={total || (chapter === 'reports' ? report?.total : {})}
                    onChange={onChange}
                    sorting={sorting || (chapter === 'reports' ? report?.sorting : {})}
                    setSorting={data => {
                        handleChange(data, 'sorting');
                        handleChange({ page: 1, 'per-page': 50, pages: 1 }, 'pagination');
                    }}
                    columnFilters={columnFilters || (chapter === 'reports' ? report?.columnFilters : [])}
                    setColumnFilters={data => {
                        handleChange(data, 'columnFilters');
                        handleChange({ page: 1, 'per-page': 50, pages: 1 }, 'pagination');
                    }}
                    columnWidths={columnsWidth}
                    setColumnWidths={data => handleChange(data, 'columnWidths')}
                    showTotalCheck={showTotalCheck}
                    isVerticalBorders={isVerticalBorders}
                    isLoading={isLoading}
                    currencies={currencies}
                    generalSettings={generalSettings}
                    setRows={setRows}
                />
                {isAllLoaded ? (
                    <MainTableBody
                        cols={sortedColumns}
                        rows={rows}
                        chapter={chapter}
                        activeRow={activeRow}
                        coloredConfig={isColoredRows && coloredConfig}
                        isVerticalBorders={isVerticalBorders}
                        isSharedReportPage={isSharedReportPage}
                        currencies={currencies}
                        generalSettings={generalSettings}
                        storage={storage}
                        onChange={onChange}
                        onRowClick={onRowClick}
                        onRowContextMenu={onRowContextMenu}
                        setRows={setRows}
                    />
                ) : (
                    <TablePreloader
                        columnsLength={sortedColumns.filter(el => !el.important).length + 1}
                        columnWidths={columnsWidth}
                    />
                )}
            </div>
            {!isLoading && (rows?.length ? null : renderNoData())}
        </>
    );
};

export default MainTableItems;
