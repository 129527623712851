export default [
    {
        key: 'isEnabled',
        label: 'Enable',
        type: 'switcher',
        size: 1
    },
    {
        key: 'name',
        label: 'Name',
        size: 5
    },
    {
        key: 'template',
        label: 'Template',
        size: 2
    },
    {
        key: 'templateValuesComponents',
        label: 'Values',
        type: 'hidingContainer',
        counterClassName: 'notifications__values-field-counter',
        size: 5
    },
    {
        key: 'isSystemMessage',
        label: 'System message',
        type: 'switcher',
        cellClassName: 'notifications__system-message',
        size: 1
    },
    {
        key: 'actions',
        label: 'Actions',
        type: 'actions',
        size: 1
    }
];
