import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { hideModal } from '../../../redux/operations';
import { exportDateTransformer } from '../../../redux/transformers';
import { useGetGeneralSettingsQuery } from '../../../redux/api/settingsApi';
import {
    useAddSupportAccessScheduleMutation,
    useEditSupportAccessScheduleMutation
} from '../../../redux/api/supportAccessApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import Datepicker from '../../Overall/UI/Datepicker/Datepicker';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';

import './add-schedule-modal.scss';

const AddScheduleModal = ({ onClose, timezones, values }) => {
    const txt = Langs[global.lng];

    const [modalState, setModalState] = useState({
        timezone: values ? values.timezone : null,
        startDate: values ? moment(values.start) : moment().startOf('hours').format(),
        endDate: values ? moment(values.end) : moment().startOf('day').add(1, 'day').format()
    });

    const updateModalState = updatedFields => setModalState(prevState => ({ ...prevState, ...updatedFields }));

    const [isConfirmPressed, setIsConfirmPressed] = useState(false);

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();

    useEffect(() => {
        if (isGeneralSettingsLoaded && !modalState.timezone) {
            updateModalState({ timezone: generalSettings?.reportTimeZone });
        }
    }, [generalSettings]);

    const [addSchedule, { isSuccess: isAddScheduleSuccess, isError: isAddScheduleError, error: addScheduleError }] =
        useAddSupportAccessScheduleMutation();

    useEffect(() => {
        if (isAddScheduleSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isAddScheduleSuccess]);

    useEffect(() => {
        if (isAddScheduleError) {
            setIsConfirmPressed(false);
            toast.error(getResponseErrorMessage(addScheduleError.data, txt.toasts.saveError));
        }
    }, [isAddScheduleError]);

    const [editSchedule, { isSuccess: isEditScheduleSuccess, isError: isEditScheduleError, error: editScheduleError }] =
        useEditSupportAccessScheduleMutation();

    useEffect(() => {
        if (isEditScheduleSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isEditScheduleSuccess]);

    useEffect(() => {
        if (isEditScheduleError) {
            setIsConfirmPressed(false);
            toast.error(getResponseErrorMessage(editScheduleError.data, txt.toasts.saveError));
        }
    }, [isEditScheduleError]);

    const onModalConfirm = result => {
        setIsConfirmPressed(true);

        const isEdit = !!values;

        const request = isEdit ? editSchedule : addSchedule;

        request({
            ...(isEdit ? { id: values.id } : {}),
            data: result
        });
    };

    const isLoaded = isGeneralSettingsLoaded;

    return (
        <>
            <div className="add-schedule-modal">
                {isLoaded ? (
                    <>
                        <Datepicker
                            values={{
                                start: modalState.startDate,
                                end: modalState.endDate
                            }}
                            getSelectedMode={data => ({ start: data.start.format(), end: data.end.format() })}
                            onChangeInterval={val => {
                                if (val.start !== modalState.startDate || val.end !== modalState.endDate) {
                                    updateModalState({ startDate: val.start, endDate: val.end });
                                }
                            }}
                            isHideFooter
                            isFutureSelect
                        />

                        <SelectInput
                            dataTest="schedule_time-zone-select-input"
                            label={txt.labels.timeZone}
                            items={timezones}
                            value={modalState.timezone}
                            onChange={({ target: { value } }) => updateModalState({ timezone: value })}
                            optionsDirection="top"
                            isSearchable
                        />
                    </>
                ) : (
                    <Loader />
                )}

                {isConfirmPressed && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={isConfirmPressed}
                onConfirm={() => {
                    onModalConfirm({
                        dateFrom: exportDateTransformer(modalState.startDate),
                        dateTo: exportDateTransformer(modalState.endDate),
                        timezone: modalState.timezone
                    });
                }}
            />
        </>
    );
};

export default AddScheduleModal;
