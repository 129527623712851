import React from 'react';

import Langs from '../../Langs';
import ModalButtonBar from '../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Alert from '../Overall/UI/Alert/Alert';

import './modals.scss';

const ArchiveResultModal = ({ onClose, subtitle, archiveResultData = {}, integrations }) => {
    const txt = Langs[global.lng];

    const { affiliateNetworks, campaigns, flows, landers, offers, redirectOffers, trafficSources } = archiveResultData;

    return (
        <>
            <div className="archive-modal archive-modal--result">
                <div className="archive-modal__content">
                    {subtitle && <span className="archive-modal__subtitle">{subtitle}</span>}

                    <>
                        {campaigns?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.campaigns}</div>
                                {campaigns.map(({ name }, idx) => (
                                    <Alert key={idx} type="warning" LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {flows?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.flows}</div>
                                {flows.map(({ name }, idx) => (
                                    <Alert key={idx} type="warning" LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {offers?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.offers}</div>
                                {offers.map(({ name }, idx) => (
                                    <Alert key={idx} LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {redirectOffers?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.titles.asRedirectOffer}</div>
                                {redirectOffers.map(({ name }, idx) => (
                                    <Alert key={idx} LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {landers?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.landers}</div>
                                {landers.map(({ name }, idx) => (
                                    <Alert key={idx} type="success" LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {trafficSources?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.trafficSources}</div>
                                {trafficSources.map(({ name }, idx) => (
                                    <Alert key={idx} LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {affiliateNetworks?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">
                                    {txt.singulars.affiliateNetworks}
                                </div>
                                {affiliateNetworks.map(({ name }, idx) => (
                                    <Alert key={idx} LeftComponent={name} />
                                ))}
                            </>
                        ) : null}

                        {integrations?.length ? (
                            <>
                                <div className="archive-modal__alert-group-title">{txt.singulars.trafficSources}</div>
                                {integrations.map(({ name }, idx) => (
                                    <Alert key={idx} LeftComponent={name} />
                                ))}
                            </>
                        ) : null}
                    </>
                </div>
            </div>

            <ModalButtonBar rejectButtonText={txt.buttons.cancel} onReject={onClose} />
        </>
    );
};

export default ArchiveResultModal;
