import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

import {
    logout,
    getCredentials,
    saveCredentials,
    DEFAULT_CREDENTIALS,
    getLocalStorageSession,
    findUserIndex,
    setLocalStorageSession
} from '../Functions/utils';
import { transform } from './transformers';

export const apiPath = process.env.API_PATH || 'https://qa-pc.plat-clicks.com/api';
export const apiVersion = '/v1';
export const apiFullPath = apiPath + apiVersion;
export const mutex = new Mutex();

export const refreshCredentials = async (token, refresh) => {
    const headers = new Headers();
    const body = new FormData();

    headers.append('Authorization', `Bearer ${token}`);
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', refresh);
    body.append('client_id', 'oauth');
    body.append('client_secret', 'secret');
    body.append('access_type', 'offline');

    const refreshResult = await fetch(`${apiPath}/auth/token`, {
        mode: 'cors',
        method: 'POST',
        headers,
        body,
        redirect: 'follow'
    })
        .then(res => {
            if (res.ok) return res.json();
            else return DEFAULT_CREDENTIALS;
        })
        .then(cred => transform.user.import(cred))
        .catch(() => DEFAULT_CREDENTIALS);

    return refreshResult;
};

export const checkCredentials = async cred => {
    if (cred.isNeedRefresh) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            const newCredentials = await refreshCredentials(cred.token, cred.refresh);

            saveCredentials({ ...newCredentials, userId: cred.userId }, null, cred.isRemember);

            release();

            return { ...cred, ...newCredentials, isNeedRefresh: false };
        } else {
            await mutex.waitForUnlock();

            return getCredentials();
        }
    }

    return cred;
};

export const getCustomBaseQuery = options => {
    return async (args, api, extraOptions) => {
        const { token, refresh, isRemember, userId, support } = await checkCredentials(getCredentials());

        options.prepareHeaders = headers => {
            const { token: actualToken, adminToken: actualAdminToken, support: actualSupport } = getCredentials();

            if (actualToken) headers.set('authorization', `Bearer ${actualToken}`);

            if (actualSupport) {
                headers.set('authorization2', `Bearer ${actualAdminToken?.token}`);
                headers.set('Support-Id', actualSupport.supportId);
            }

            return headers;
        };

        const baseQuery = fetchBaseQuery(options);

        if (support) {
            if (typeof args === 'string') {
                args = `${args}${args.indexOf('?') !== -1 ? '&' : '?'}_switch_user=${encodeURIComponent(
                    support.userLogin
                )}`;
            }

            if (typeof args === 'object' && args?.url) {
                args.url = `${args.url}${args.url.indexOf('?') !== -1 ? '&' : '?'}_switch_user=${encodeURIComponent(
                    support.userLogin
                )}`;
            }
        }

        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();

        let result = await baseQuery(args, api, extraOptions);

        if (result.error && (result.error?.status === 401 || result.error?.originalStatus === 401)) {
            // checking whether the mutex is locked

            if (!mutex.isLocked()) {
                const release = await mutex.acquire();

                const refreshResult = await refreshCredentials(token, refresh);

                if (refreshResult.token) {
                    saveCredentials({ ...refreshResult, userId }, null, isRemember);
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);

                    if (result.error && (result.error?.status === 401 || result.error?.originalStatus === 401)) {
                        // release must be called once the mutex should be released again.
                        logout();
                        release();
                    }
                } else {
                    // release must be called once the mutex should be released again.
                    logout();
                    release();
                }
            } else {
                // wait until the mutex is available without locking it
                await mutex.waitForUnlock();
                result = await baseQuery(args, api, extraOptions);
            }
        }

        if (result.error && (result.error?.status === 403 || result.error?.originalStatus === 403)) {
            if (support) logout();
        }

        // update lastActivityTime time
        const session = getLocalStorageSession();

        if (session?.length && isRemember === false) {
            const userIndex = findUserIndex(userId);

            session[userIndex].lastActivityTime = new Date().getTime();
            setLocalStorageSession(session);
        }

        return result;
    };
};

export const providesList = (items, tagType) => {
    if (!items)
        return [
            { type: tagType, id: 'LIST' },
            { type: tagType, id: 'ALL' }
        ];

    return [
        ...items.map(el => {
            const id = el.id || el.value;

            const isValidId = new RegExp(/^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/i).test(id);

            if (!isValidId) {
                // Если это простой список и у элементов нет id
                // или не будут использоваться элементы списка по отдельности (отдельными запросами getMainEntityItem)
                // тогда используй skipProvideItemsTags: true
                throw new Error(`You provide incorrect uuid (${id})`);
            }

            return { type: tagType, id };
        }),
        { type: tagType, id: 'LIST' },
        { type: tagType, id: 'ALL' }
    ];
};
