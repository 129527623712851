import React from 'react';
import './td-types.scss';

import Langs from '../../../../Langs';

const TdCriteria = props => {
    const { row, items } = props;
    const txt = Langs[global.lng];

    return (
        <div className="j4 wrap">
            {items
                ?.filter(item => item.isEnabled === true)
                .map((item, index) => {
                    return (
                        <div className="tdListWithCount_box" key={index}>
                            <span className="color--froly">{txt.labels[item.label || item.key] || item.label} </span>
                            {item.value?.length > 0 ? (
                                <span className="color--froly">({item.value.length}) </span>
                            ) : null}
                            {items?.length > 0 ? (
                                <span className="logical-relation">
                                    {' '}
                                    {row.logicalRelation === txt.labels.and ? txt.labels.ampersand : txt.labels.or}
                                </span>
                            ) : null}
                        </div>
                    );
                })}
        </div>
    );
};

export default TdCriteria;
