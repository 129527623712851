import React from 'react';
import * as Icon from 'react-feather';

import { capitalize } from '../../../../Functions/utils';

import trash from '../../../../../../media/images/trash.svg';
import play from '../../../../../../media/images/play.svg';
import pause from '../../../../../../media/images/pause.svg';

export const TdStatus = ({ value }) => {
    const renderStatus = () => {
        switch (value) {
            case 'play':
                return <img src={play} alt="" />;
            case 'pause':
                return <img src={pause} alt="" />;
            case 'delete':
                return <img className="small-icon" src={trash} alt="" />;
            case 'pending':
                return (
                    <div className="df">
                        <Icon.Clock size={16} className="color--sea-buckthorn mr5" />
                        {capitalize(value)}
                    </div>
                );
            default:
                return null;
        }
    };
    return <div>{renderStatus()}</div>;
};
