import React, { forwardRef } from 'react';

const Spinner = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
        <svg
            ref={ref}
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            stroke={color}
            viewBox="0 0 50 50"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                fill={color}
                d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
            >
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
});

Spinner.displayName = 'Spinner';

export default Spinner;
