import React from 'react';

import Langs from '../../../Langs';
import { showModal } from '../../../redux/operations';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import ChangePasswordModal from '../../Modals/change-password-modal/change-password-modal';

import './security-settings.scss';

const SecuritySettings = () => {
    const txt = Langs[global.lng];
    //const { accessKey, secretKey } = settings.security.apiAccessKeys;
    //const params = { accessKey, secretKey };
    // const rows = settings.security?.apiAccessKeysTable.rows.map(row => ({
    //     ...row,
    //     actions: row.isDeleted ? [{ type: 'undo' }] : [{ type: 'deleteRow' }]
    // }));

    // const onSubmit = domain => {
    //     onUpdateRank2([...rows, domain], 'settings', 'security', 'apiAccessKeysTable', 'rows');
    // };

    // const onActionClick = (actionType, item) => {
    //     const selectedIndex = rows.findIndex(el => el.value === item.value);
    //     switch (actionType) {
    //         case 'deleteRow':
    //             onUpdateRank2(
    //                 [...rows.slice(0, selectedIndex), { ...item, isDeleted: true }, ...rows.slice(selectedIndex + 1)],
    //                 'settings',
    //                 'security',
    //                 'apiAccessKeysTable',
    //                 'rows'
    //             );
    //             toast.warning(txt.toasts.deleted);
    //             break;
    //         case 'undo':
    //             onUpdateRank2(
    //                 [...rows.slice(0, selectedIndex), { ...item, isDeleted: false }, ...rows.slice(selectedIndex + 1)],
    //                 'settings',
    //                 'security',
    //                 'apiAccessKeysTable',
    //                 'rows'
    //             );
    //             toast.warning(txt.toasts.restored);
    //             break;
    //         default:
    //             break;
    //     }
    // };

    // const twoFactorAuthModal = () => {
    //     showModal({
    //         title,
    //         headerType: 'primary',
    //         body: props => {
    //             return <TwoFactorAuth {...props} />;
    //         }
    //     });
    // };

    // const createApiKey = () => {
    //     showModal({
    //         title,
    //         headerType: 'primary',
    //         body: props => {
    //             return <ApiAccessKeysAdd {...props} onSubmit={onSubmit} />;
    //         }
    //     });
    // };

    // const twoFactorAuth = (
    //     <div className="p5">
    //         <span className="color--error">{txt.settings.disabled} </span>
    //         {txt.texts.twoFactorAuthAlertText}
    //     </div>
    // );

    // const renderField = () => {
    //     switch (type) {
    //         case 'changePassword':
    //             return (
    //                 <div className="settings-wrapper settings-wrapper--wide">
    //                     <SettingsHeader title={title} btnLabel={txt.buttons.edit} onClick={changePasswordModal} />
    //                 </div>
    //             );
    //         case 'twoFactorAuth':
    //             return (
    //                 <div className="settings-wrapper settings-wrapper--wide">
    //                     <SettingsHeader title={title} btnLabel={txt.buttons.enable2FA} onClick={twoFactorAuthModal} />
    //                     <SettingsSection className="wide">
    //                         <Alert message={twoFactorAuth} type="warning" />
    //                     </SettingsSection>
    //                 </div>
    //             );
    //         case 'apiAccessKeys':
    //             return (
    //                 <div className="settings-wrapper settings-wrapper--wide">
    //                     <SettingsHeader
    //                         title={title}
    //                         btnLabel={txt.buttons.createApiKey}
    //                         icon="+"
    //                         onClick={createApiKey}
    //                     />

    //                     <SettingsSection>
    //                         <span>{txt.texts.settingsApiAccessKeys}</span>
    //                     </SettingsSection>

    //                     <SettingsSection className="wide">
    //                         <div>
    //                             <ItemsTable cols={apiAccessKeysColumns} rows={rows} onActionClick={onActionClick} />
    //                         </div>
    //                     </SettingsSection>
    //                 </div>
    //             );
    //         default:
    //             return null;
    //     }
    // };

    //  txt.titles.twoFactorAuth
    //  txt.titles.apiAccessKeys

    const warningModalConfig = {
        title: txt.titles.closeWithoutSave(),
        message: txt.texts.warningModal(),
        confirmButtonText: 'yesCloseForm',
        confirmButtonType: 'danger'
    };

    const changePasswordModal = () => {
        showModal({
            title: txt.titles.changePassword,
            warningModalConfig,
            body: props => <ChangePasswordModal {...props} />
        });
    };

    const changePasswordHeader = (
        <SectionHeader
            title={txt.titles.changePassword}
            button={{
                label: txt.buttons.edit,
                onClick: changePasswordModal
            }}
        />
    );

    return <Section Header={changePasswordHeader} className="mb25 security__change-password" />;
};

export default SecuritySettings;
