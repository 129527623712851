import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import Langs from '../../Langs';
import { random } from '../../Functions/utils';

import indexApi from '../../redux/api/indexApi';
import { useGetPreparationProjectInfoQuery } from '../../redux/api/projectApi';

import AuthForm from '../../Modules/AuthForm/AuthForm';

import './page-preparation.scss';

const CHECK_STATUS_DELAY = 5000; // ms

const Box = ({ index, title, status = 'preparing' }) => (
    <>
        <div className={cn('step', { [`step--${status}`]: status })}>
            step {index}
            <hr />
        </div>
        <div className={cn('box', { [`box--${status}`]: status })}>{title}</div>
    </>
);

const PagePreparation = () => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const isInitialization = JSON.parse(localStorage.getItem('isStartInitialization'));

    if (!isInitialization) {
        localStorage.removeItem('accountInitialisation');
        return <Redirect to={'/'} />;
    }

    const [isCreated, setIsCreated] = useState(false);

    const { data: projectInfo, isSuccess: isProjectInfoLoaded } = useGetPreparationProjectInfoQuery(undefined, {
        pollingInterval: isCreated ? undefined : CHECK_STATUS_DELAY,
        skip: isCreated
    });

    const initialStep = localStorage.getItem('accountInitialisation');
    const [step, setStep] = useState(+initialStep || 1);
    const statuses = ['Creating project', 'Creating database', 'Registration domains', 'Preparing account'];

    useEffect(() => {
        let timer;
        const CHANGE_STEP_DELAY = random(2, 3);
        if (step < statuses.length) {
            timer = setTimeout(() => {
                if (step === statuses.length) {
                    clearTimeout(timer);
                    return;
                }
                setStep(prevStep => prevStep + 1);
                localStorage.setItem('accountInitialisation', step + 1);
            }, CHANGE_STEP_DELAY * 1000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [step]);

    useEffect(() => {
        if (isProjectInfoLoaded && projectInfo.status !== 'waiting') setIsCreated(true);
    }, [projectInfo]);

    useEffect(() => {
        if (isCreated) {
            dispatch(indexApi.util.invalidateTags(['projectInfo']));
            localStorage.removeItem('isStartInitialization');
        }
    }, [isCreated]);

    const getStatus = num => {
        if (step < num) return 'preparing';
        else if (step > num) return 'completed';
        return 'in-progress';
    };

    return (
        <AuthForm className="form__wrapper--compact" childrenClassName="form__col--gray">
            <div className="preparation">
                <div className="preparation__title">{txt.texts.waitPlease}</div>
                <div className="preparation__body">
                    {statuses.map((status, idx) => (
                        <Box key={idx} index={idx + 1} title={status} status={getStatus(idx + 1)} />
                    ))}
                </div>
            </div>
        </AuthForm>
    );
};

export default PagePreparation;
