import React from 'react';

const SectionHeader = props => {
    const { title, label, onSubmit, header, isLoading } = props;
    if (!title) return null;

    const renderSubmitButton = () => (
        <div className="pr25">
            <button
                data-test="section-header_submit-btn"
                className="standard-button standard-button--primary standard-button--wide"
                onClick={() => onSubmit()}
                disabled={isLoading}
            >
                {label}
            </button>
        </div>
    );

    return (
        <div className="settings-header j46">
            <div className="settings-header__title pl25">{title}</div>
            {header}
            {onSubmit && renderSubmitButton()}
        </div>
    );
};

export default SectionHeader;
