import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { showModal, hideModal } from '../../../redux/operations';
import { BILLING_PLAN, MODAL_TYPES } from '../../../Constants';
import {
    useDeleteDomainMutation,
    useCheckDomainStatusMutation,
    useChangeDefaultDomainMutation,
    domainsApi
} from '../../../redux/api/domainsApi';

import AddDomainModal from '../../Modals/add-domain-modal/add-domain-modal';
import Loader from '../../Loader';
import ArchiveModal from '../../Modals/ArchiveModal';
import ArchiveResultModal from '../../Modals/ArchiveResultModal';
import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustTable from '../../../components/just-table/just-table';

import { trackingDomains, redirectDomains } from './table-constructor';

const DomainTable = ({ domains, campaigns, type, isFetching, currentPlanName, customDomains, isUnlimited }) => {
    const txt = Langs[global.lng];
    const dispath = useDispatch();

    const [isProcessing, setIsProcessing] = useState(false);

    const rows = domains?.map(row => ({
        ...row,
        campaignName: row.destination.campaignId
            ? campaigns?.find(el => el.value === row.destination.campaignId)?.label
            : null,
        actions: [
            //   ...(row?.domain?.ssl === false ? [{ type: 'getSSL' }] : []),
            { type: 'editRow' },
            {
                type: 'deleteRow',
                isDefault: row.isDefault || row.isSystem || (row.dnsStatus === 'active' && row.domain.ssl === false)
            }
        ]
    }));

    const [
        deleteDomain,
        {
            isSuccess: isDeleteDomainSucces,
            isError: isDeleteDomainError,
            error: deleteDomainError,
            originalArgs: deleteDomainCallArgs
        }
    ] = useDeleteDomainMutation();

    useEffect(() => {
        if (isDeleteDomainSucces) {
            toast.success(txt.toasts.deleted);
            setIsProcessing(false);
        }
    }, [isDeleteDomainSucces]);

    useEffect(() => {
        if (isDeleteDomainError) {
            toast.error(deleteDomainError.data?.errors?.message);
            setIsProcessing(false);

            if (deleteDomainError.data?.errors?.options?.isBinded) {
                showModal({
                    title: txt.titles.error,
                    body: props => (
                        <ArchiveResultModal
                            {...props}
                            subtitle={txt.texts.getCouldNotBeArchivedSubtitle(deleteDomainCallArgs.name, true)}
                            archiveResultData={deleteDomainError.data?.errors?.options}
                        />
                    )
                });
            }
        }
    }, [isDeleteDomainError]);

    const [checkStatus, { data: checkStatusData, isSuccess: isCheckStatusSucces, isError: isCheckStatusError }] =
        useCheckDomainStatusMutation();

    useEffect(() => {
        if (isCheckStatusSucces) {
            dispath(
                domainsApi.util.updateQueryData('getDomainsByType', type, draft => {
                    const index = draft.findIndex(el => el.id === checkStatusData.id);
                    if (index >= 0) draft[index].dnsStatus = checkStatusData.dnsStatus;
                })
            );

            setIsProcessing(false);
        }
    }, [isCheckStatusSucces]);

    const [
        changeDefault,
        { isSuccess: isChangeDefaultSucces, originalArgs: changeDefaultCallArgs, isError: isChangeDefaultError }
    ] = useChangeDefaultDomainMutation();

    useEffect(() => {
        if (isChangeDefaultSucces) {
            toast.success(txt.toasts.defaultDomainChanged);

            dispath(
                domainsApi.util.updateQueryData('getDomainsByType', type, draft => {
                    const index = draft.findIndex(el => el.id === changeDefaultCallArgs.id);
                    if (index >= 0) {
                        draft.forEach(el => {
                            el.isDefault = false;
                        });
                        draft[index].isDefault = true;
                    }
                })
            );

            setIsProcessing(false);
        }
    }, [isChangeDefaultSucces]);

    useEffect(() => {
        if (isCheckStatusError || isChangeDefaultError) toast.error(txt.toasts.commonError);
    }, [isCheckStatusError, isChangeDefaultError]);

    const onActionClick = (actionType, item) => {
        switch (actionType) {
            case 'isDefault':
                setIsProcessing(true);
                changeDefault({ id: item.id, type });
                break;
            case 'editRow':
                showModal({
                    title: txt.titles.editCustomDomain,
                    body: props => <AddDomainModal {...props} item={item} type={type} />
                });
                break;
            case 'deleteRow':
                showModal({
                    title: txt.titles.deleteDomain,
                    body: props => (
                        <ArchiveModal
                            {...props}
                            type={MODAL_TYPES.DOMAIN}
                            subtitle={txt.texts.getWillBeArchivedSubtitle(item.domain.name, true)}
                            onConfirm={() => {
                                hideModal();
                                setIsProcessing(true);
                                deleteDomain({ id: item.id, type, name: item.domain?.name });
                            }}
                        />
                    )
                });
                break;
            case 'checkStatus':
                setIsProcessing(true);
                checkStatus({ id: item.domain });
                break;
            default:
                break;
        }
    };

    const openAddDomainModal = () => {
        showModal({
            title: txt.titles.addCustomDomain(type),
            body: props => <AddDomainModal {...props} type={type} />
        });
    };

    const isDomainsLimited = !isUnlimited && Number.isFinite(customDomains) && customDomains <= domains?.length;

    const getWarningInfo = () => {
        return isDomainsLimited
            ? txt.texts.getDomainsLimitText(BILLING_PLAN[currentPlanName.toUpperCase()], customDomains)
            : null;
    };

    const sectionHeader = (
        <SectionHeader
            title={txt.labels[type === 'tracking' ? 'trackingDomains' : 'redirectDomain']}
            warningInfo={getWarningInfo()}
            button={{
                label: txt.buttons.addDomain,
                icon: { type: 'plus' },
                onClick: openAddDomainModal,
                disabled: isDomainsLimited
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="mb25">
            <span className="text-default-light">
                {txt.texts[type === 'tracking' ? 'trackingDomains' : 'redirectDomain']}
            </span>

            <JustTable
                className="mt16"
                cols={type === 'tracking' ? trackingDomains : redirectDomains}
                rows={rows}
                onActionClick={onActionClick}
            />

            {(isFetching || isProcessing) && <Loader isFetching />}
        </Section>
    );
};

export default DomainTable;
