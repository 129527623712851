import React from 'react';

export const TdDoubleValue = ({ items = {}, className }) => {
    const { valueLeft, valueRight } = items;
    return (
        <div className={className}>
            <span className="color--de-york strong">{valueLeft}</span>
            {valueLeft || valueRight ? <span className="color--pause ml5 mr5">|</span> : null}
            <span className={`${valueRight !== '0' ? 'color--froly' : ''}`}>{valueRight}</span>
        </div>
    );
};
