import React, { useState } from 'react';
import * as Icon from 'react-feather';

import Langs from '../../../Langs';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Btn from '../../Overall/UI/Btn/Btn';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';

const PostParams = props => {
    const txt = Langs[global.lng];
    const { className, items, onChange, title, type, validateFields, atLeastOneValue } = props;
    const [row, setRow] = useState(items);
    const timeFrameItems = {
        criteria: ['visits', 'column'],
        condition: ['highter', 'below']
    };

    const handleAddParameter = () => {
        const newRow = [
            ...row,
            {
                name: '',
                value: '',
                count: ''
            }
        ];
        setRow(newRow);
        onChange(newRow);
    };

    const onDelete = index => {
        onChange([...row.splice(index, 1)]);
        onChange(row);
    };

    const handleArrayChange = (e, index, prop) => {
        row[index][prop] = e.target.value;
        onChange(row);
    };

    const renderRow = (item, index) => {
        switch (type) {
            case 'timeframe':
                return (
                    <div className="j4 mb5" key={index}>
                        <div className="postParams-item w50 mr10">
                            <SelectInput
                                dataTest="post-params_timeframe_name-select-input"
                                value={item.name}
                                items={timeFrameItems.criteria}
                                onChange={e => handleArrayChange(e, index, 'name')}
                            />
                        </div>
                        <div className="postParams-item w50 mr10">
                            <SelectInput
                                dataTest="post-params_timeframe_value-select-input"
                                value={item.value}
                                items={timeFrameItems.condition}
                                onChange={e => handleArrayChange(e, index, 'value')}
                            />
                        </div>
                        <div className="postParams-item w50 mr10 j4">
                            <TextInput
                                dataTest="post-params_timeframe_count-input"
                                onChange={e => handleArrayChange(e, index, 'count')}
                                value={item.count}
                                type="number"
                                placeholder={txt.placeholders.typeValue}
                            />
                            <div className={`${row.length > 1 ? 'ml10 j4 pointer' : 'hidden'}`}>
                                <Icon.Trash2 size={16} className="color--froly" onClick={() => onDelete(index)} />
                            </div>
                        </div>
                    </div>
                );
            case 'customNotification':
                return (
                    <div className="customNotification_row j4 mb5" key={index}>
                        <div className="postParams-item w50 mr10">
                            <SelectInput
                                dataTest="post-params_custom-notification_name-select-input"
                                value={item.name}
                                items={timeFrameItems.criteria}
                                onChange={e => handleArrayChange(e, index, 'name')}
                            />
                        </div>
                        <div className="postParams-item w50 mr10">
                            <SelectInput
                                dataTest="post-params_custom-notification_value-select-input"
                                value={item.value}
                                items={timeFrameItems.condition}
                                onChange={e => handleArrayChange(e, index, 'value')}
                            />
                        </div>
                        <span className="mr10">than</span>
                        <div className="postParams-item w50 mr10 j4">
                            <TextInput
                                dataTest="post-params_custom-notification_count-input"
                                onChange={e => handleArrayChange(e, index, 'count')}
                                value={item.count}
                                type="number"
                                placeholder={txt.placeholders.typeValue}
                            />
                            <div className={`${row.length > 1 ? 'ml10 j4 pointer' : 'hidden'}`}>
                                <Icon.Trash2 size={16} className="color--froly" onClick={() => onDelete(index)} />
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="j4 mb5" key={index}>
                        <div className="postParams-item w50 mr10">
                            <TextInput
                                dataTest={`post-params_${index}-name-input`}
                                onChange={e => handleArrayChange(e, index, 'name')}
                                value={item.name}
                                placeholder={txt.labels.name}
                                error={validateFields ? validateFields(item.name) : null}
                            />
                        </div>
                        <div className="postParams-item w50 j4">
                            <div className="postParams_input mr10">
                                <TextInput
                                    dataTest={`post-params_${index}-value-input`}
                                    onChange={e => handleArrayChange(e, index, 'value')}
                                    value={item.value}
                                    placeholder={txt.labels.value}
                                    error={validateFields ? validateFields(item.value) : null}
                                />
                            </div>
                            <div className="j4 pointer">
                                <Icon.Trash2
                                    size={16}
                                    className="color--froly"
                                    onClick={() => {
                                        if (atLeastOneValue && items.length <= 1) return;
                                        onDelete(index);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className={className}>
            {title ? <div className="form-label-title mb5">{title}</div> : null}
            {row.map((item, index) => renderRow(item, index))}

            {row.length < 12 && (
                <Btn dataTest="post-params_add-btn" onClick={handleAddParameter} type="filled" className="mt5">
                    <Icon.Plus size={16} className="mr5" />
                    {txt.buttons.add}
                </Btn>
            )}
        </div>
    );
};

export default PostParams;
