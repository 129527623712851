import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

const usersApiTagTypes = {
    users: 'users'
};

export const usersApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(usersApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getUsersList: builder.query({
            query: params => `/users${params || ''}`,
            transformResponse: response => ({
                items: response.items.map(el => transform.users.import(el)),
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: usersApiTagTypes.users, id: 'LIST' },
                { type: usersApiTagTypes.users, id: 'ALL' }
            ]
        }),

        getUserById: builder.query({
            query: id => `/users/${id}`,
            transformResponse: res => transform.users.import(res),
            providesTags: (result, error, id) => [
                { type: usersApiTagTypes.users, id },
                { type: usersApiTagTypes.users, id: 'ALL_ITEMS' }
            ]
        }),

        getUsersAllNames: builder.query({
            query: () => `/users/all/names`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: usersApiTagTypes.users, id: 'ALL_NAMES_LIST' },
                { type: usersApiTagTypes.users, id: 'ALL' }
            ]
        }),

        getUsersAllNamesOnlyRoleUsers: builder.query({
            query: () => `/users/all/names/role-user`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: usersApiTagTypes.users, id: 'ALL_NAMES_LIST' },
                { type: usersApiTagTypes.users, id: 'ALL' }
            ]
        }),

        editUser: builder.mutation({
            query: ({ id, data }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                body: transform.users.export(data)
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: usersApiTagTypes.users, id },
                        { type: usersApiTagTypes.users, id: 'ALL' }
                    ];
                } else return [];
            }
        }),

        deleteUser: builder.mutation({
            query: ({ id }) => ({
                url: `/users/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error) {
                    return [
                        { type: usersApiTagTypes.users, id },
                        { type: usersApiTagTypes.users, id: 'ALL' },
                        { type: 'workspaces', id: 'ALL' },
                        { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT }
                    ];
                } else return [];
            }
        })
    })
});

export const {
    useGetUsersListQuery,
    useGetUsersAllNamesQuery,
    useGetUsersAllNamesOnlyRoleUsersQuery,
    useGetUserByIdQuery,
    useEditUserMutation,
    useDeleteUserMutation
} = usersApi;
