import indexApi from './indexApi';
import { transform } from '../transformers';

export const eventLogApiTagTypes = {
    eventLog: 'eventLog'
};

const eventLogApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(eventLogApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getEventLog: builder.query({
            query: params => `/event-log${params || ''}`,
            transformResponse: response => ({
                items: response.items.map(el => transform.eventLog.import(el)),
                pagination: response.pagination
            }),
            providesTags: () => [{ type: eventLogApiTagTypes.eventLog, id: 'LIST' }]
        })
    })
});

export const { useGetEventLogQuery } = eventLogApi;
