import React from 'react';

export const TdUrl = React.memo(({ value }) => {
    return (
        <span data-text={value} onClick={() => window.open(value, '_blank')}>
            <span className="items-table__td-text">{value}</span>
        </span>
    );
});

TdUrl.displayName = 'TdUrl';
