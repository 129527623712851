import React from 'react';

import { capitalize, getIntlFormattedValue } from '../../../../../Functions/utils';

import TdMoney from '../td-money/TdMoney';
import TdIntegrationAction from './components/TdIntegrationAction';
import TdIntegrationBid from './components/TdIntegrationBid';

import './td-integration.scss';

const TdIntegration = React.memo(
    ({
        trafficSourceId,
        trafficSourceCampaignId,
        integration,
        integrationData,
        isIntegrationActive,
        isIntegrationSimilarCampaigns,
        isIntegrationCostUsed,
        type,
        cellKey,
        rounding,
        currencies,
        rowId,
        chapter
    }) => {
        if (!trafficSourceCampaignId || !isIntegrationActive || (integrationData && !integrationData?.isSuccessful))
            return null;

        const key = cellKey.replace('integration', '').toLowerCase();

        const value = (() => {
            if (cellKey === 'integrationStatus') return capitalize(integrationData?.status);
            if (cellKey === 'integrationCost') return integrationData?.costAmount;
            if (cellKey === 'integrationImpressions') return getIntlFormattedValue(integrationData?.impressions);

            return integrationData?.[key]?.toString();
        })();

        if (trafficSourceCampaignId && !integrationData && isIntegrationActive) {
            // return (
            //     <div className="td-integration">
            //         <Loader type="cell" />
            //     </div>
            // );

            return null;
        }

        const getContent = () => {
            switch (type) {
                case 'money':
                    return (
                        <TdMoney
                            value={value}
                            rounding={rounding}
                            currencies={currencies}
                            defaultCurrency={integrationData?.currency}
                            isIntegrationCostUsed={isIntegrationCostUsed}
                            cellKey={cellKey}
                            chapter={chapter}
                            integration={integration}
                            integrationData={integrationData}
                        />
                    );

                case 'action':
                    return (
                        <TdIntegrationAction
                            trafficSourceId={trafficSourceId}
                            trafficSourceCampaignId={trafficSourceCampaignId}
                            integrationStatus={integrationData?.isActive}
                            disabled={integrationData?.isActionDisable}
                            rowId={rowId}
                            similarCampaigns={isIntegrationSimilarCampaigns}
                            campaignListRequestParams={integration.campaignListRequestParams}
                        />
                    );

                case 'editable':
                    return (
                        <TdIntegrationBid
                            trafficSourceId={trafficSourceId}
                            trafficSourceCampaignId={trafficSourceCampaignId}
                            bid={integrationData?.bidAmount}
                            bidMode={integrationData?.bidMode}
                            currencies={currencies}
                            defaultCurrency={integrationData?.currency}
                            disabled={integrationData?.isBidDisable}
                            rowId={rowId}
                            similarCampaigns={isIntegrationSimilarCampaigns}
                            campaignListRequestParams={integration.campaignListRequestParams}
                        />
                    );

                default:
                    return (
                        <div className="td-integration rel">
                            <span className="items-table__td-text">{value}</span>
                        </div>
                    );
            }
        };

        return (
            <div className="rel">
                {getContent()}
                {/* {isIntegrationFetching && <Loader type="cell" isFetching />} */}
            </div>
        );
    }
);

TdIntegration.displayName = 'TdIntegration';
export default TdIntegration;
