import React, { useEffect, useState } from 'react';
import Langs from '../../../../../Langs';

import { hideModal } from '../../../../../redux/operations';
import { useAddNewPaymentMethodMutation } from '../../../../../redux/api/paymentApi';
import { getResponseErrorMessage } from '../../../../../Functions/utils';
import useCreditCardValidation from '../../../../../Functions/hooks/useCreditCardValidation';

import AddCard from '../../../../Overall/AddCard/AddCard';
import Btn from '../../../../Overall/UI/Btn/Btn';

import iconInfo from '../../../../../../../media/images/alert-triangle.svg';
import cardIcon from '../../../../../../../media/images/paymentMethods/cardicon.svg';
import paypalIcon from '../../../../../../../media/images/paymentMethods/paypalicon.svg';

import Visa from '../../../../../../../media/images/paymentMethods/visa.svg';
import MasterCard from '../../../../../../../media/images/paymentMethods/mastercard.svg';
import AmericanExpress from '../../../../../../../media/images/paymentMethods/americanexpress.svg';
import Discover from '../../../../../../../media/images/paymentMethods/discover.svg';
import PayPal from '../../../../../../../media/images/paymentMethods/paypal-light-large.svg';

import './update-payment-modal.scss';

const icons = {
    Visa,
    MasterCard,
    AmericanExpress,
    Discover,
    PayPal
};

const UpdatePaymentModal = ({ data, onClose }) => {
    const txt = Langs[global.lng];

    const [isTouch, setIsTouch] = useState(false);
    const [responseError, setResponseError] = useState({});

    const [
        addPaymentMethod,
        {
            isLoading: isAddPaymentMethodLoading,
            isSuccess: isAddPaymentMethodSuccess,
            isError: isAddPaymentMethodError,
            error: addPaymentMethodError
        }
    ] = useAddNewPaymentMethodMutation();

    useEffect(() => {
        if (isAddPaymentMethodSuccess) {
            hideModal();
            onClose();
        }
    }, [isAddPaymentMethodSuccess]);

    useEffect(() => {
        if (isAddPaymentMethodError) {
            setResponseError({
                responseError: getResponseErrorMessage(addPaymentMethodError.data, txt.toasts.saveError)
            });
        }
    }, [isAddPaymentMethodError]);

    const [
        cardInfo,
        cardErrors,
        onCardNumberChange,
        onCardDateChange,
        onCardDateBlur,
        onCardCodeChange,
        onCardSaveChange
    ] = useCreditCardValidation();

    const onSubmit = () => {
        setIsTouch(true);

        const isValid = !Object.values(cardErrors).filter(error => !!error).length;

        if (isValid) {
            addPaymentMethod({
                methodType: 'method_card',
                card: {
                    cardNumber: cardInfo.cardNumber,
                    expirationDate: `${cardInfo.cardDate.slice(0, 2)}/20${cardInfo.cardDate.slice(-2)}`,
                    cardCode: cardInfo.cardCode
                }
            });
        }
    };

    return (
        <div className="update-payment-modal">
            <div className="update-payment-modal__icon">
                <img src={iconInfo} width="24" height="24" alt="lock" />
            </div>

            <div className="update-payment-modal__container">
                <h2 className="update-payment-modal__title">Update Payment Method</h2>

                <p className="update-payment-modal__text mt16">
                    We need to inform you that the card ending in <b>{data.cardNumber}</b> has been suspended. Please
                    add this card again or provide another one to prevent your account from being blocked.
                </p>

                <div className="update-payment-modal__card">
                    <img src={icons[data.cardType] || cardIcon} width="36" height="24" alt={'Card'} />

                    <span className="update-payment-modal__card-number">•••• •••• •••• {data.cardNumber}</span>

                    <span className="update-payment-modal__card-expired">{data.expirationDate}</span>
                </div>

                <div className="mode-buttons">
                    <Btn type="flexible" className="mode-button mode-button--active">
                        <img src={cardIcon} alt={txt.texts.creditCard} />
                        <div>{txt.texts.creditCard}</div>
                    </Btn>

                    <Btn type="flexible" disabled className="mode-button">
                        <img src={paypalIcon} alt={txt.texts.payPal} />
                        <div>{txt.texts.payPal}</div>
                    </Btn>
                </div>

                <AddCard
                    cardInfo={cardInfo}
                    onCardNumberChange={onCardNumberChange}
                    onCardDateChange={onCardDateChange}
                    onCardCodeChange={onCardCodeChange}
                    onCardSaveChange={onCardSaveChange}
                    onCardDateBlur={onCardDateBlur}
                    setResponseError={setResponseError}
                    errors={{ ...cardErrors, ...responseError }}
                    isTouch={isTouch}
                />

                <div className="update-payment-modal__btn">
                    <Btn onClick={onSubmit} type="filled" disabled={isAddPaymentMethodLoading}>
                        Update Payment
                    </Btn>
                </div>
            </div>
        </div>
    );
};

export default UpdatePaymentModal;
