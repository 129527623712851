import React from 'react';

import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import Loader from '../../Loader';

const SettingsSection = ({ title, children, label, onSubmit, buttons, header, className, isLoadind }) => {
    return (
        <div className={`${className ? 'settings-wrapper settings-wrapper--' + className : 'settings-wrapper'}`}>
            <SectionHeader title={title} label={label} onSubmit={onSubmit} header={header} />

            {children ? <div className="settings-wrapper-body">{children}</div> : null}

            {buttons && <SectionFooter buttons={buttons} />}

            {isLoadind && (
                <div className="general-settings__loader">
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default SettingsSection;
