import React from 'react';

import Label from '../Forms/Inputs/Label';

const ModalContentContainer = React.memo(({ title, hint, children }) => (
    <div className="modal-component-container">
        {title && <Label className="modal-component-container__title" label={title} hint={hint} />}
        <div className="modal-component-container__content">{children}</div>
    </div>
));

ModalContentContainer.displayName = 'ModalContentContainer';
export default ModalContentContainer;
