import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import moment from 'moment-timezone';

import Langs from '../../../../../Langs';
import { clone } from '../../../../../Functions/utils';

import useDelayedInput from '../../../../../Functions/hooks/useDelayedInput';

import indexApi from '../../../../../redux/api/indexApi';
import { reportsApitagTypes, useChangeReportsMutation } from '../../../../../redux/api/reportsApi';

import MainMenu from '../main-menu/main-menu';
import AdditionalMenu from '../additional-menu/additional-menu';
import HiddenMenu from '../hidden-menu/hidden-menu';
import DateRangeInput from '../../../Forms/Inputs/DateRangeInput';
import SelectInput from '../../../Forms/Inputs/SelectInput/SelectInput';
import { Tag } from '../../../Forms/Inputs/TagInput';
import TextInput from '../../../Forms/Inputs/TextInput';
import Icon from '../../../Icon';
import Btn from '../../../UI/Btn/Btn';
import Tooltip from '../../../UI/Tooltip/Tooltip';

const INITIAL_DATE_RANGE = {
    start: moment().startOf('day'),
    end: moment().add(1, 'day').startOf('day'),
    compare: false
};

const ReportTopMenu = ({
    reportValue,
    reports,
    report,
    isLoading,
    topMenuWrapperRef,
    lastMatchingElementIndex,
    mainMenuForRender,
    additionalMenuForRender,
    hiddenMenuForRender,
    isMenuButton,
    isSeparator,
    isHiddenMenuOpened,
    setIsHiddenMenuOpened,
    groupsItems,
    entityList
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    // const { groupsItems: initialGroups } = topMenuConstructor;

    const [changeReportQuery] = useChangeReportsMutation();

    const { value: filterValues } = report?.filters[0] || [];
    if (reportValue && !report) return null;
    // const [groupsItems, setGroupsItems] = useState([...initialGroups]);

    // useEffect(async () => {
    //     setGroupsItems([...initialGroups]);

    //     if (filterChapter === 'campaigns' && filterValues?.length === 1) {
    //         const campaign = await fetchData('campaigns/' + filterValues[0], null, transform.campaigns.import);
    //         const trafficSourceId = campaign.trafficSource;

    //         const trafficSource = await fetchData(
    //             'traffic-sources/' + trafficSourceId,
    //             null,
    //             transform.trafficSources.import
    //         );

    //         const parameters = trafficSource?.variables;

    //         if (parameters && parameters.length > 2) {
    //             const variables = [];

    //             for (let i = 2; i < parameters.length; i++) {
    //                 const parameterId = parameters[i].internalToken.replace(/[{}]/gi, '');
    //                 variables.push({ value: parameterId, label: `{${parameterId}}: ${parameters[i].name}` });
    //             }

    //             setGroupsItems(prev => [...prev, { label: 'Variables', items: variables }]);
    //         }
    //     }

    //     // if (filterChapter !== 'reports' && filterValues?.length > 1) {
    //     //     await fetchData(
    //     //         `${camelToKebabCase(filterChapter)}/all/names`,
    //     //         `overall/${filterChapter}`,
    //     //         itemsListTransformer
    //     //     );
    //     // }
    // }, [reportValue]);

    const changeReport = (id, data, prop) => {
        const result = clone(reports);
        const index = result.findIndex(el => el.value === reportValue);
        if (index === -1) return;

        result[index][prop] = data;

        changeReportQuery(result);
    };

    if (!report) return null;
    const groups = report?.groups;
    const groupsFirstItem = groups[0];
    const filters = report?.filters.map(filter => filter.type);
    const exceptions = [...groups, ...filters];
    const { dateRange, search } = report;
    const topFilters = report?.filters.filter((el, idx) => idx > 0);

    const [searchText, setSearchText] = useDelayedInput(val => changeReport(reportValue, val, 'search'), search || '');

    useEffect(() => {
        if (reportValue) setSearchText({ target: { value: search } });
    }, [reportValue]);

    if (!['campaigns', 'rules'].includes(filters[0])) exceptions.push('paths');

    const renderGroups = () => {
        const MIN_GROUPS_COUNT = 1;
        const MAX_GROUPS_COUNT = 3;

        const groupsCount =
            topFilters.length >= MAX_GROUPS_COUNT ? MIN_GROUPS_COUNT : MAX_GROUPS_COUNT - topFilters.length;
        return [...Array(groupsCount).keys()].map(idx => {
            const group = report?.groups?.[idx];

            const items = groupsItems
                .map(item => {
                    if (!(item.value === group || !exceptions.includes(item.value))) return null;
                    if (Object.prototype.hasOwnProperty.call(item, 'items')) {
                        return {
                            ...item,
                            items: item.items.filter(el => el.value === group || !exceptions.includes(el.value))
                        };
                    }
                    return item;
                })
                .filter(el => (el?.items ? el.items?.length > 0 && el : el))
                .filter(el => el);

            return (
                <React.Fragment key={`group_${idx}`}>
                    <SelectInput
                        dataTest="top-menu_groups-select-input"
                        className="menu-select"
                        value={group}
                        disabled={isLoading}
                        onChange={({ target: { value: val } }) => {
                            const isHasValue = val !== -1 && val;

                            const newGroups = isHasValue
                                ? [...groups.slice(0, idx), val, ...groups.slice(idx + 1)]
                                : [...groups.slice(0, idx), ...groups.slice(idx + 1)];

                            changeReport(reportValue, newGroups, 'groups');
                        }}
                        items={[
                            group && idx > 0 ? { value: -1, label: txt.placeholders.chooseGrouping } : {},
                            ...items
                        ]}
                        placeholder={txt.placeholders.chooseGrouping}
                        isCleanable={idx > 0}
                    />
                    {idx < groupsCount - 1 && (
                        <Tooltip message={idx < groups.length - 1 ? txt.labels.swapGroups : null}>
                            <Btn
                                icon
                                type="borderless"
                                onClick={() => {
                                    const newGroups = clone(groups);
                                    [newGroups[idx], newGroups[idx + 1]] = [newGroups[idx + 1], newGroups[idx]];
                                    changeReport(reportValue, newGroups, 'groups');
                                }}
                                disabled={idx >= groups.length - 1}
                            >
                                <Icon.Switch />
                            </Btn>
                        </Tooltip>
                    )}
                </React.Fragment>
            );
        });
    };

    const handleRemoveFilter = type => {
        const result = clone(reports);
        const index = result.findIndex(el => el.value === reportValue);
        if (index === -1) return;

        result[index].groups = [type];
        result[index].filters = report.filters.filter(el => el.type !== type);

        changeReportQuery(result);
    };

    const renderTopMultipleItems = () => {
        return (
            filterValues?.length > 1 &&
            filterValues.map((filter, idx) => (
                <Tag key={idx} type="light" label={entityList?.find(el => el.value === filter)?.label} />
            ))
        );
    };

    const renderTopFilters = () => {
        return (
            topFilters?.length > 0 &&
            topFilters.map((filter, idx) => (
                <Tag
                    key={idx}
                    type="light"
                    label={filter.type}
                    value={filter.label || filter.id}
                    onDeleteItem={() => handleRemoveFilter(filter.type)}
                />
            ))
        );
    };

    return (
        <div className="top-menu__main-wrapper page-report__menu">
            {(filterValues?.length > 1 || topFilters?.length > 0) && (
                <div className="j7 mb8 wrap">
                    {renderTopMultipleItems()}
                    {renderTopFilters()}
                </div>
            )}

            <div className="top-menu">
                <div
                    ref={topMenuWrapperRef}
                    className={cn('top-menu__wrapper', {
                        'top-menu__wrapper--resize': lastMatchingElementIndex === -1
                    })}
                >
                    <MainMenu
                        menu={mainMenuForRender}
                        activeItem={report?.groups?.[0]}
                        // exceptions={filters}
                        onChange={data => changeReport(reportValue, [data], 'groups')}
                        isLoading={isLoading}
                        isReport={true}
                    />

                    {isSeparator && <div className="top-menu__separator" />}

                    <AdditionalMenu
                        menu={additionalMenuForRender}
                        activeItem={report?.groups?.[0]}
                        // exceptions={filters}
                        onChange={data => changeReport(reportValue, [data], 'groups')}
                        isLoading={isLoading}
                        isReport={true}
                    />
                </div>

                {isMenuButton && (
                    <div
                        className={cn('top-menu__button', { 'top-menu__button--active': isHiddenMenuOpened })}
                        onClick={() => setIsHiddenMenuOpened(!isHiddenMenuOpened)}
                    />
                )}

                {isHiddenMenuOpened && (
                    <HiddenMenu
                        menu={hiddenMenuForRender}
                        isReport
                        onChange={data => changeReport(reportValue, [data], 'groups')}
                    />
                )}
            </div>

            {groupsFirstItem !== 'conversions' && (
                <div className="reports-top-menu j7">
                    {renderGroups()}

                    <TextInput
                        dataTest="top-menu_search-input"
                        placeholder={txt.placeholders.search}
                        value={searchText}
                        onChange={setSearchText}
                        disabled={isLoading}
                        reset
                    />

                    <DateRangeInput
                        value={dateRange || INITIAL_DATE_RANGE}
                        onChange={data => changeReport(reportValue, data, 'dateRange')}
                        disabled={isLoading}
                    />

                    <Btn
                        dataTest="top-menu_refresh-btn"
                        type="success"
                        onClick={() => {
                            dispatch(
                                indexApi.util.invalidateTags([
                                    { type: reportsApitagTypes.reports, id: 'REPORT' },
                                    { type: reportsApitagTypes.reports, id: 'REPORT_CHART' }
                                ])
                            );
                        }}
                        disabled={isLoading}
                    >
                        <Icon.RefreshCw />
                        {txt.buttons.refresh}
                    </Btn>
                </div>
            )}
        </div>
    );
};

export default ReportTopMenu;
