import moment from 'moment-timezone';
import { mainTableConstructor } from '../../Modules/MainTable/constructors/mainTableConstructor';
import { INTERVALS, DISPLAY_MODE_STATUS } from '../../Constants';

const start = moment().startOf('day').format('YYYY-MM-DDTHH:mm');
const end = moment().startOf('day').add(1, 'day').format('YYYY-MM-DDTHH:mm');
const intervalsKeys = Object.keys(INTERVALS);

const dateRange = {
    intervalKey: intervalsKeys[0],
    start,
    end,
    compare: false,
    startPrevDate: null,
    endPrevDate: null
};

const rounding = mainTableConstructor.values.reduce((ac, item) => ({ ...ac, [item.key]: item.rounding }), {});

// Attention, changing this parameter will reset the settings of all users after the release
export const STORAGE_VERSION = 1;

export const storage = {
    storageControledVersion: STORAGE_VERSION,
    common: {
        dateRange,
        isColoredRows: false,
        isVerticalBorders: false,
        rounding,
        showModals: { DownloadFile: true },
        positionPickerValue: 0,
        customConversions: []
    },
    campaigns: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'displayName', isHide: false, order: 3 },

            { key: 'integrationImpressions', isHide: false, order: 4 },
            { key: 'integrationCost', isHide: false, order: 5 },
            { key: 'integrationBid', isHide: false, order: 6 },
            { key: 'integrationStatus', isHide: false, order: 7 },
            { key: 'integrationAction', isHide: false, order: 8 },

            { key: 'rotationName', isHide: false, order: 9 },

            { key: 'visitsUnique', isHide: false, order: 10 },
            { key: 'visits', isHide: false, order: 11 },
            { key: 'clicksUnique', isHide: false, order: 12 },
            { key: 'clicks', isHide: false, order: 13 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 14 },
            { key: 'revenue', isHide: false, order: 15 },
            { key: 'additionalRevenue', isHide: false, order: 16 },
            { key: 'cost', isHide: false, order: 17 },
            { key: 'profit', isHide: false, order: 18 },
            { key: 'roi', isHide: false, order: 19 },

            { key: 'cr', isHide: false, order: 20 },
            { key: 'ctr', isHide: false, order: 21 },
            { key: 'cv', isHide: false, order: 22 },
            { key: 'cpv', isHide: false, order: 23 },
            { key: 'cpa', isHide: false, order: 24 },
            { key: 'cpc', isHide: false, order: 25 },
            { key: 'cpm', isHide: false, order: 26 },

            { key: 'epc', isHide: false, order: 27 },
            { key: 'epv', isHide: false, order: 28 },
            { key: 'ap', isHide: false, order: 29 },

            { key: 'ecpc', isHide: false, order: 30 },
            { key: 'ecpa', isHide: false, order: 31 },

            { key: 'impressions', isHide: false, order: 32 },
            { key: 'ecpm', isHide: false, order: 33 },
            { key: 'ictr', isHide: false, order: 34 },
            { key: 'rpm', isHide: false, order: 35 },

            { key: 'domainName', isHide: false, order: 36 },
            { key: 'trafficSourceName', isHide: false, order: 37 }
        ]
    },
    flows: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },
            { key: 'campaignsCount', isHide: false, order: 4 },

            { key: 'visitsUnique', isHide: false, order: 5 },
            { key: 'visits', isHide: false, order: 6 },
            { key: 'clicksUnique', isHide: false, order: 7 },
            { key: 'clicks', isHide: false, order: 8 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 9 },
            { key: 'revenue', isHide: false, order: 10 },
            { key: 'cost', isHide: false, order: 11 },
            { key: 'profit', isHide: false, order: 12 },
            { key: 'roi', isHide: false, order: 13 },

            { key: 'cr', isHide: false, order: 14 },
            { key: 'ctr', isHide: false, order: 15 },
            { key: 'cv', isHide: false, order: 16 },
            { key: 'cpv', isHide: false, order: 17 },

            { key: 'epc', isHide: false, order: 18 },
            { key: 'epv', isHide: false, order: 19 },
            { key: 'ap', isHide: false, order: 20 },

            { key: 'ecpc', isHide: false, order: 21 },
            { key: 'ecpa', isHide: false, order: 22 },

            { key: 'impressions', isHide: false, order: 23 },
            { key: 'ecpm', isHide: false, order: 24 },
            { key: 'ictr', isHide: false, order: 25 },
            { key: 'rpm', isHide: false, order: 26 }
        ]
    },
    offers: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },

            { key: 'affiliateNetworkName', isHide: false, order: 4 },
            { key: 'url', isHide: false, order: 5 },
            { key: 'payoutPrice', isHide: false, order: 6 },
            { key: 'conversionCap', isHide: false, order: 7 },
            { key: 'clickCap', isHide: false, order: 8 },

            { key: 'visitsUnique', isHide: false, order: 9 },
            { key: 'visits', isHide: false, order: 10 },
            { key: 'clicksUnique', isHide: false, order: 11 },
            { key: 'clicks', isHide: false, order: 12 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 13 },
            { key: 'revenue', isHide: false, order: 14 },
            { key: 'cost', isHide: false, order: 15 },
            { key: 'profit', isHide: false, order: 16 },
            { key: 'roi', isHide: false, order: 17 },

            { key: 'cr', isHide: false, order: 18 },
            { key: 'ctr', isHide: false, order: 19 },
            { key: 'cv', isHide: false, order: 20 },
            { key: 'cpv', isHide: false, order: 21 },

            { key: 'epc', isHide: false, order: 22 },
            { key: 'epv', isHide: false, order: 23 },
            { key: 'ap', isHide: false, order: 24 },

            { key: 'ecpc', isHide: false, order: 25 },
            { key: 'ecpa', isHide: false, order: 26 },

            { key: 'impressions', isHide: false, order: 27 },
            { key: 'ecpm', isHide: false, order: 28 },
            { key: 'ictr', isHide: false, order: 29 },
            { key: 'rpm', isHide: false, order: 30 }
        ]
    },
    landers: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },
            { key: 'numberOfOffers', isHide: false, order: 4 },

            { key: 'visitsUnique', isHide: false, order: 5 },
            { key: 'visits', isHide: false, order: 6 },
            { key: 'clicksUnique', isHide: false, order: 7 },
            { key: 'clicks', isHide: false, order: 8 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 9 },
            { key: 'revenue', isHide: false, order: 10 },
            { key: 'cost', isHide: false, order: 11 },
            { key: 'profit', isHide: false, order: 12 },
            { key: 'roi', isHide: false, order: 13 },

            { key: 'cr', isHide: false, order: 14 },
            { key: 'ctr', isHide: false, order: 15 },
            { key: 'cv', isHide: false, order: 16 },
            { key: 'cpv', isHide: false, order: 17 },

            { key: 'epc', isHide: false, order: 18 },
            { key: 'epv', isHide: false, order: 19 },
            { key: 'ap', isHide: false, order: 20 },

            { key: 'ecpc', isHide: false, order: 21 },
            { key: 'ecpa', isHide: false, order: 22 },

            { key: 'impressions', isHide: false, order: 23 },
            { key: 'ecpm', isHide: false, order: 24 },
            { key: 'ictr', isHide: false, order: 25 },
            { key: 'rpm', isHide: false, order: 26 }
        ]
    },
    trafficSources: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },

            { key: 'visitsUnique', isHide: false, order: 4 },
            { key: 'visits', isHide: false, order: 5 },
            { key: 'clicksUnique', isHide: false, order: 6 },
            { key: 'clicks', isHide: false, order: 7 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 8 },
            { key: 'revenue', isHide: false, order: 9 },
            { key: 'cost', isHide: false, order: 10 },
            { key: 'profit', isHide: false, order: 11 },
            { key: 'roi', isHide: false, order: 12 },

            { key: 'cr', isHide: false, order: 13 },
            { key: 'ctr', isHide: false, order: 14 },
            { key: 'cv', isHide: false, order: 15 },
            { key: 'cpv', isHide: false, order: 16 },

            { key: 'epc', isHide: false, order: 17 },
            { key: 'epv', isHide: false, order: 18 },
            { key: 'ap', isHide: false, order: 19 },

            { key: 'ecpc', isHide: false, order: 20 },
            { key: 'ecpa', isHide: false, order: 21 },

            { key: 'impressions', isHide: false, order: 22 },
            { key: 'ecpm', isHide: false, order: 23 },
            { key: 'ictr', isHide: false, order: 24 },
            { key: 'rpm', isHide: false, order: 25 }
        ]
    },
    affiliateNetworks: {
        displayMode: DISPLAY_MODE_STATUS.ACTIVE,
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },

            { key: 'visitsUnique', isHide: false, order: 4 },
            { key: 'visits', isHide: false, order: 5 },
            { key: 'clicksUnique', isHide: false, order: 6 },
            { key: 'clicks', isHide: false, order: 7 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 8 },
            { key: 'revenue', isHide: false, order: 9 },
            { key: 'cost', isHide: false, order: 10 },
            { key: 'profit', isHide: false, order: 11 },
            { key: 'roi', isHide: false, order: 12 },

            { key: 'cr', isHide: false, order: 13 },
            { key: 'ctr', isHide: false, order: 14 },
            { key: 'cv', isHide: false, order: 15 },
            { key: 'cpv', isHide: false, order: 16 },

            { key: 'epc', isHide: false, order: 17 },
            { key: 'epv', isHide: false, order: 18 },
            { key: 'ap', isHide: false, order: 19 },

            { key: 'ecpc', isHide: false, order: 20 },
            { key: 'ecpa', isHide: false, order: 21 },

            { key: 'impressions', isHide: false, order: 22 },
            { key: 'ecpm', isHide: false, order: 23 },
            { key: 'ictr', isHide: false, order: 24 },
            { key: 'rpm', isHide: false, order: 25 }
        ]
    },
    conversions: {
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'clickId', isHide: false, order: 2 },
            { key: 'status', isHide: false, order: 3 },
            { key: 'externalId', isHide: false, order: 4 },
            { key: 'transactionId', isHide: false, order: 5 },
            { key: 'postback', isHide: false, order: 6 },
            { key: 'visit', isHide: false, order: 7 },
            { key: 'timeSinceClick', isHide: false, order: 8 },
            { key: 'campaign', isHide: false, order: 9 },
            { key: 'trafficSource', isHide: false, order: 10 },
            { key: 'offerName', isHide: false, order: 11 },
            { key: 'payout', isHide: false, order: 12 },
            { key: 'geo', isHide: false, order: 13 },
            { key: 'clientIp', isHide: false, order: 14 }
        ]
    },
    chapter_reports: {
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },

            { key: 'visitsUnique', isHide: false, order: 4 },
            { key: 'visits', isHide: false, order: 5 },
            { key: 'clicksUnique', isHide: false, order: 6 },
            { key: 'clicks', isHide: false, order: 7 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 8 },
            { key: 'revenue', isHide: false, order: 9 },
            { key: 'cost', isHide: false, order: 10 },
            { key: 'profit', isHide: false, order: 11 },
            { key: 'roi', isHide: false, order: 12 },

            { key: 'cr', isHide: false, order: 13 },
            { key: 'ctr', isHide: false, order: 14 },
            { key: 'cv', isHide: false, order: 15 },
            { key: 'cpv', isHide: false, order: 16 },

            { key: 'epc', isHide: false, order: 17 },
            { key: 'epv', isHide: false, order: 18 },
            { key: 'ap', isHide: false, order: 19 },

            { key: 'ecpc', isHide: false, order: 20 },
            { key: 'ecpa', isHide: false, order: 21 },

            { key: 'impressions', isHide: false, order: 22 },
            { key: 'ecpm', isHide: false, order: 23 },
            { key: 'ictr', isHide: false, order: 24 },
            { key: 'rpm', isHide: false, order: 25 }
        ]
    },
    errorLog: {
        columns: [
            { key: 'createdAt', isHide: false, order: 1 },
            { key: 'campaignName', isHide: false, order: 2 },
            { key: 'category', isHide: false, order: 3 },
            { key: 'details', isHide: false, order: 4 },
            { key: 'url', isHide: false, order: 5 },
            { key: 'referer', isHide: false, order: 6 },
            { key: 'remoteIp', isHide: false, order: 7 }
        ]
    },
    reports: {
        columns: [
            { key: 'checked', isHide: false, order: 1 },
            { key: 'id', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },

            { key: 'visitsUnique', isHide: false, order: 4 },
            { key: 'visits', isHide: false, order: 5 },
            { key: 'clicksUnique', isHide: false, order: 6 },
            { key: 'clicks', isHide: false, order: 7 },
            // { key: 'suspicious', isHide: false, order: 0 },

            { key: 'conversions', isHide: false, order: 8 },
            { key: 'revenue', isHide: false, order: 9 },
            { key: 'cost', isHide: false, order: 10 },
            { key: 'profit', isHide: false, order: 11 },
            { key: 'roi', isHide: false, order: 12 },

            { key: 'cr', isHide: false, order: 13 },
            { key: 'ctr', isHide: false, order: 14 },
            { key: 'cv', isHide: false, order: 15 },
            { key: 'cpv', isHide: false, order: 16 },

            { key: 'epc', isHide: false, order: 17 },
            { key: 'epv', isHide: false, order: 18 },
            { key: 'ap', isHide: false, order: 19 },

            { key: 'ecpc', isHide: false, order: 20 },
            { key: 'ecpa', isHide: false, order: 21 },

            { key: 'impressions', isHide: false, order: 22 },
            { key: 'ecpm', isHide: false, order: 23 },
            { key: 'ictr', isHide: false, order: 24 },
            { key: 'rpm', isHide: false, order: 25 }
        ]
    },
    users: {
        columns: [
            { key: 'id', isHide: false, order: 1 },
            { key: 'name', isHide: false, order: 2 },
            { key: 'email', isHide: false, order: 3 },
            { key: 'role', isHide: false, order: 4 },
            // { key: 'workspaces', isHide: false, order: 0 },
            { key: 'clicks', isHide: false, order: 5 },
            { key: 'cr', isHide: false, order: 6 },
            { key: 'epc', isHide: false, order: 7 },
            { key: 'revenue', isHide: false, order: 8 },
            { key: 'cost', isHide: false, order: 9 },
            { key: 'profit', isHide: false, order: 10 },
            { key: 'roi', isHide: false, order: 11 }
        ]
    },
    invitations: {
        columns: [
            { key: 'status', isHide: false, order: 1 },
            { key: 'email', isHide: false, order: 2 },
            { key: 'userRole', isHide: false, order: 3 },
            // { key: 'workspaces', isHide: false, order: 1 },
            { key: 'createdAt', isHide: false, order: 4 }
        ]
    },
    workspaces: {
        columns: [
            { key: 'name', isHide: false, order: 1 },
            { key: 'workers', isHide: false, order: 2 },
            { key: 'domains', isHide: false, order: 3 },
            { key: 'campaignsCount', isHide: false, order: 4 },
            { key: 'landersCount', isHide: false, order: 5 },
            { key: 'offersCount', isHide: false, order: 6 },
            { key: 'affiliateNetworksCount', isHide: false, order: 7 },
            { key: 'trafficSourcesCount', isHide: false, order: 8 }
        ]
    },
    eventLog: {
        columns: [
            { key: 'userName', isHide: false, order: 1 },
            { key: 'entityType', isHide: false, order: 2 },
            { key: 'actionType', isHide: false, order: 3 },
            { key: 'entityName', isHide: false, order: 4 },
            { key: 'entityIncrementId', isHide: false, order: 5 },
            { key: 'changedAt', isHide: false, order: 6 },
            { key: 'details', isHide: false, order: 7 }
        ]
    },
    sharedReports: {
        columns: [
            { key: 'name', isHide: false, order: 1 },
            { key: 'userName', isHide: false, order: 2 },
            { key: 'createdAt', isHide: false, order: 3 },
            { key: 'availableDateTo', isHide: false, order: 4 },
            { key: 'status', isHide: false, order: 5 },
            { key: 'counterViews', isHide: false, order: 6 }
        ]
    },
    triggers: {
        columns: [
            { key: 'id', isHide: false, order: 1 },
            { key: 'status', isHide: false, order: 2 },
            { key: 'name', isHide: false, order: 3 },
            { key: 'group', isHide: false, order: 4 },
            { key: 'lastAction', isHide: false, order: 5 },
            { key: 'object', isHide: false, order: 6 },
            { key: 'objectState', isHide: false, order: 7 },
            { key: 'condition', isHide: false, order: 8 },
            { key: 'action', isHide: false, order: 9 }
        ]
    }
};
