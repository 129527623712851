import React from 'react';
import Btn from '../../Overall/UI/Btn/Btn';
import Switcher from '../../Overall/UI/Switcher/Switcher';

const SettingsHeader = props => {
    const {
        title,
        subTitle,
        btnLabel,
        onClick,
        isButtonHidden,
        isButtonDisabled,
        icon,
        isSwitcherShown,
        onChange,
        isSwitchOn,
        switcherLabel,
        dataTest,
        isLoading,
        warningInfo
    } = props;

    // DEPRECATED: Этот компонент больше не поддерживается, использовать или заменить на <Section Header={sectionHeader} />

    const renderButton = () => (
        <div className="pr25">
            <Btn
                dataTest="settings-header_btn"
                type="filled"
                onClick={() => onClick(true)}
                className="plr30"
                disabled={isLoading || isButtonDisabled}
            >
                {icon && <span className="mr5">{icon}</span>}
                {btnLabel}
            </Btn>
        </div>
    );

    return (
        <div className="settings-header j46">
            {isSwitcherShown ? (
                <div className="settings-header__title j4 pl25">
                    <div>
                        <Switcher
                            dataTest={`settings-header_${dataTest}`}
                            isSwitchOn={isSwitchOn}
                            onChange={onChange}
                            label={switcherLabel}
                        />
                    </div>
                    <span>{title}</span>
                    {isSwitchOn === true ? (
                        <span className="color--de-york ml5">(enabled)</span>
                    ) : (
                        <span className="color--froly ml5">(disabled)</span>
                    )}
                </div>
            ) : (
                <div className="settings-header__title pl25">{title}</div>
            )}

            {subTitle ? <div className="settings-header__subTitle">{subTitle}</div> : null}
            {warningInfo && <span className="settings-header__warning-info">{warningInfo}</span>}
            {!isButtonHidden && renderButton()}
        </div>
    );
};

export default SettingsHeader;
