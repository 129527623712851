import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import Langs from '../../../Langs';
import { EMAIL_RULE, USER_ROLES } from '../../../Constants';
import { hideModal, showModal } from '../../../redux/operations';
import { trimObjValues, getResponseErrorMessage, omitKeys } from '../../../Functions/utils';
import { useEditUserMutation, useGetUserByIdQuery } from '../../../redux/api/usersApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import Label from '../../Overall/Forms/Inputs/Label';
import Btn from '../../Overall/UI/Btn/Btn';
import Loader from '../../Loader';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import ConfirmWithPasswordModal from '../confirm-with-password-modal/confirm-with-password-modal';
// import TagInput from '../../Overall/Forms/Inputs/TagInput';
// import Alert from '../../Overall/UI/Alert/Alert';

import './edit-user-modal.scss';

const roles = Object.values(omitKeys(USER_ROLES, ['OWNER']));

const EditUserModal = ({ id, onTouch, onClose }) => {
    const txt = Langs[global.lng];

    const [user, setUser] = useState({});
    const [isSubmitTouched, setIsSubmitTouched] = useState(false);
    const [isPasswordEditing, setIsPasswordEditing] = useState(false);
    const [isSend, setIsSend] = useState(false);

    const { data: userData, isLoading: isUserDataLoading, isSuccess: isUserDataSuccess } = useGetUserByIdQuery(id);

    useEffect(() => {
        if (isUserDataSuccess) setUser({ ...userData, password: '', confirmPassword: '', userPassword: '' });
    }, [userData]);

    const isRoleOrPasswordChange = userData?.role !== user.role || user.password !== '';

    const isAllValid =
        EMAIL_RULE.test(user?.email) &&
        user?.firstName?.trim() &&
        user?.lastName?.trim() &&
        (!isPasswordEditing || (user?.password && user?.confirmPassword === user?.password));

    const onChange = useCallback((prop, value) => {
        onTouch();

        setUser(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const [
        editUser,
        { isLoading: isEditUserLoading, isSuccess: isEditUserSuccess, isError: isEditUserError, error: editUserError }
    ] = useEditUserMutation();

    useEffect(() => {
        if (isEditUserError) toast.error(getResponseErrorMessage(editUserError.data, txt.toasts.saveError));
    }, [isEditUserError]);

    useEffect(() => {
        if (isEditUserSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isEditUserSuccess]);

    const onSubmit = () => {
        setIsSubmitTouched(true);

        if (isAllValid) {
            if (isRoleOrPasswordChange) {
                return showModal({
                    title: txt.titles.confirmation,
                    body: props => (
                        <ConfirmWithPasswordModal {...props} onChange={onChange} onConfirm={() => setIsSend(true)} />
                    )
                });
            }

            editUser({ id, data: trimObjValues(user) });
        }
    };

    useEffect(() => {
        if (isSend) {
            editUser({ id, data: trimObjValues(user) });
            setIsSend(false);
        }
    }, [isSend]);

    return (
        <>
            <div className="edit-user-modal">
                {isUserDataLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="edit-user-modal__row">
                            <div className="edit-user-modal__col">
                                <TextInput
                                    dataTest="edit-user_first-name-input"
                                    label={txt.labels.firstname}
                                    className="invite-user__first-name"
                                    value={user?.firstName}
                                    onChange={({ target: { value } }) => onChange('firstName', value)}
                                    error={
                                        isSubmitTouched &&
                                        !user?.firstName?.trim() &&
                                        txt.errors.required(txt.labels.firstname)
                                    }
                                />
                            </div>

                            <div className="edit-user-modal__col">
                                <TextInput
                                    dataTest="edit-user_last-name-input"
                                    label={txt.labels.lastname}
                                    className="invite-user__first-name"
                                    value={user?.lastName}
                                    onChange={({ target: { value } }) => onChange('lastName', value)}
                                    error={
                                        isSubmitTouched &&
                                        !user?.lastName?.trim() &&
                                        txt.errors.required(txt.labels.lastname)
                                    }
                                />
                            </div>
                        </div>

                        <div className="edit-user-modal__row">
                            <div className="edit-user-modal__col">
                                <TextInput
                                    dataTest="edit-user_email-input"
                                    label={txt.labels.email}
                                    className="invite-user__email"
                                    value={user?.email}
                                    onChange={({ target: { value } }) => onChange('email', value)}
                                    error={isSubmitTouched && !EMAIL_RULE.test(user?.email) && txt.errors.email}
                                />
                            </div>

                            <div className="edit-user-modal__col">
                                <Label label="Password" />
                                <Btn
                                    dataTest="edit-user_change-btn"
                                    className="plr30"
                                    onClick={() => setIsPasswordEditing(true)}
                                    disabled={isPasswordEditing}
                                >
                                    {txt.buttons.change}
                                </Btn>
                            </div>
                        </div>

                        <div className="edit-user-modal__row">
                            <div className="edit-user-modal__col">
                                <RadioInput
                                    dataTest="edit-user_role"
                                    className="mb10"
                                    label={txt.labels.role}
                                    hint={txt.labels.role}
                                    items={roles}
                                    type="row"
                                    value={user?.role}
                                    onChange={({ target: { value } }) => onChange('role', value)}
                                />

                                <CheckBoxInput
                                    label="Allow sharing reports"
                                    value={user.isAllowSharingReport ?? false}
                                    onChange={({ target: { checked } }) => onChange('isAllowSharingReport', checked)}
                                />
                            </div>

                            <div className="edit-user-modal__col">
                                {isPasswordEditing && (
                                    <>
                                        <TextInput
                                            dataTest="edit-user_new-password-input"
                                            type="password"
                                            icon="password"
                                            label={txt.labels.password}
                                            placeholder={txt.placeholders.newPassword}
                                            onChange={({ target: { value } }) => onChange('password', value)}
                                            className="mb20"
                                            value={user?.password}
                                            error={
                                                isSubmitTouched &&
                                                isPasswordEditing &&
                                                !user?.password &&
                                                txt.errors.required(txt.labels.password)
                                            }
                                        />

                                        <TextInput
                                            dataTest="edit-user_confirm-password-input"
                                            placeholder={txt.placeholders.repeatNewPassword}
                                            type="password"
                                            icon="password"
                                            value={user?.confirmPassword}
                                            onChange={({ target: { value } }) => onChange('confirmPassword', value)}
                                            className="mb20"
                                            error={
                                                isSubmitTouched &&
                                                isPasswordEditing &&
                                                user?.confirmPassword !== user?.password &&
                                                txt.errors.same(txt.labels.password)
                                            }
                                        />

                                        <Btn
                                            dataTest="edit-user_cancel-btn"
                                            className="plr30"
                                            onClick={() => {
                                                onChange('password', '');
                                                onChange('confirmPassword', '');
                                                setIsPasswordEditing(false);
                                            }}
                                        >
                                            {txt.buttons.cancel}
                                        </Btn>
                                    </>
                                )}
                            </div>
                        </div>

                        {/*<TagInput
                            dataTest="edit-user"
                            label={txt.labels.assignPrivateWorkspace}
                            items={workspaces}
                            value={user?.workspaces}
                            onChange={value => handleChange('workspaces', value)}
                        />*/}
                        {/*<Alert message={txt.alerts.conflictOfHideProfit} type="warning" />*/}
                        {/*<CheckBoxInput
                            dataTest="edit-user_hide-profit-checkbox"
                            className="mr25"
                            label={txt.labels.hideProfit}
                            value={user?.isHideProfit}
                            onChange={({ target: { checked } }) => handleChange('isHideProfit', checked)}
                        />*/}

                        {isEditUserLoading && <Loader isFetching />}
                    </>
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                isLoading={isUserDataLoading}
                onConfirm={onSubmit}
                isConfirmDisabled={(isSubmitTouched && !isAllValid) || isEditUserLoading}
                onReject={onClose}
            />
        </>
    );
};

export default EditUserModal;
