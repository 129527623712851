import TrafficStarsImg from '../../../../../media/images/integrations/traffic-source/traffic-stars.svg';
import TrafficStarsImgSmall from '../../../../../media/images/integrations/traffic-source/traffic-stars-small.svg';

import TaboolaImg from '../../../../../media/images/integrations/traffic-source/taboola.svg';
import TaboolaImgSmall from '../../../../../media/images/integrations/traffic-source/taboola-small.svg';

import ExoClickImg from '../../../../../media/images/integrations/traffic-source/exoclick.svg';
import ExoClickImgSmall from '../../../../../media/images/integrations/traffic-source/exoclick-small.svg';

import PropellerAdsImg from '../../../../../media/images/integrations/traffic-source/propellerads.svg';
import PropellerAdsImgSmall from '../../../../../media/images/integrations/traffic-source/propellerads-small.svg';

import AdsterraImg from '../../../../../media/images/integrations/traffic-source/adsterra.svg';
import AdsterraImgSmall from '../../../../../media/images/integrations/traffic-source/adsterra-small.svg';

import MgidImg from '../../../../../media/images/integrations/traffic-source/mgid.svg';
import MgidImgSmall from '../../../../../media/images/integrations/traffic-source/mgid-small.svg';

import RevcontentImg from '../../../../../media/images/integrations/traffic-source/revcontent.svg';
import RevcontentImgSmall from '../../../../../media/images/integrations/traffic-source/revcontent-small.svg';

import FacebookImg from '../../../../../media/images/integrations/traffic-source/facebook.svg';
import FacebookImgSmall from '../../../../../media/images/integrations/traffic-source/facebook-small.svg';

import OutbrainImg from '../../../../../media/images/integrations/traffic-source/outbrain.svg';
import OutbrainImgSmall from '../../../../../media/images/integrations/traffic-source/outbrain-small.svg';

import GoogleImg from '../../../../../media/images/integrations/traffic-source/google.svg';
import GoogleImgSmall from '../../../../../media/images/integrations/traffic-source/google-small.svg';

export const getIntegrationIcon = (integrationName, isSmall) => {
    switch (integrationName) {
        case 'TrafficStars':
            return isSmall ? TrafficStarsImgSmall : TrafficStarsImg;

        case 'Taboola':
            return isSmall ? TaboolaImgSmall : TaboolaImg;

        case 'ExoClick':
            return isSmall ? ExoClickImgSmall : ExoClickImg;

        case 'PropellerAds Pop':
            return isSmall ? PropellerAdsImgSmall : PropellerAdsImg;

        case 'Adsterra':
            return isSmall ? AdsterraImgSmall : AdsterraImg;

        case 'MGID':
            return isSmall ? MgidImgSmall : MgidImg;

        case 'Revcontent':
            return isSmall ? RevcontentImgSmall : RevcontentImg;

        case 'Facebook':
            return isSmall ? FacebookImgSmall : FacebookImg;

        case 'Outbrain':
            return isSmall ? OutbrainImgSmall : OutbrainImg;

        case 'Google Ads':
            return isSmall ? GoogleImgSmall : GoogleImg;

        default:
            return null;
    }
};
