import { useState } from 'react';

import Langs from '../../Langs';
import { getCardErrors } from '../../redux/operations';
import {
    CARD_SYMBOLS_MAX_QUANTITY,
    CREDIT_CARD_RULE,
    CVV_SYMBOLS_QUANTITY,
    DATE_SYMBOLS_QUANTITY
} from '../../Constants/index';
import { getLimitedLengthNumber } from '../utils';

const findDiffIndex = (strA, strB) => [...strA].findIndex((char, idx) => char !== strB[idx]);

const useCreditCardValidation = () => {
    const txt = Langs[global.lng];
    const [isInputDateLastSlash, setIsInputDateLastSlash] = useState(false);
    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        cardDate: '',
        cardCode: '',
        isSave: true
    });

    const creditCardsMatchCommonCVCSymbols = [txt.texts.visa, txt.texts.masterCard, txt.texts.discover];
    const creditCardsMatchDefaultCVCSymbols = [txt.texts.americanExpress];
    const isMatchCVV = cardList => !!cardList.filter(card => CREDIT_CARD_RULE[card].test(cardInfo.cardNumber)).length;

    const getCurrentCVVSymbolsQuantity = () => {
        if (isMatchCVV(creditCardsMatchCommonCVCSymbols)) return CVV_SYMBOLS_QUANTITY.COMMON;
        if (isMatchCVV(creditCardsMatchDefaultCVCSymbols)) return CVV_SYMBOLS_QUANTITY.DEFAULT;
    };

    const currentCVVSymbolsQuantity = getCurrentCVVSymbolsQuantity() || CVV_SYMBOLS_QUANTITY.DEFAULT;

    if (cardInfo.cardCode.length > currentCVVSymbolsQuantity) {
        setCardInfo({ ...cardInfo, cardCode: cardInfo.cardCode.slice(0, currentCVVSymbolsQuantity) });
    }

    const cardErrors = getCardErrors(cardInfo, getCurrentCVVSymbolsQuantity());

    const setCardFieldValue = (field, value, maxNumber) => {
        setCardInfo({ ...cardInfo, [field]: getLimitedLengthNumber(value, maxNumber) });
    };

    const onCardNumberChange = ({ target: { value } }) => {
        setCardFieldValue('cardNumber', value, CARD_SYMBOLS_MAX_QUANTITY);
    };

    const onCardDateChange = ({ target: { value } }) => {
        if (!/^\d{0,2}\/?\d{0,2}$/.test(value)) {
            return;
        }

        const month = [];
        const year = [];

        const newValue = Array.from(value.replace('/', ''));

        newValue.forEach((el, i) => {
            if (i < 2) {
                if (i === 0 && /[2-9]/.test(el)) {
                    month.push('0', el);
                } else {
                    month.push(el);
                }
            } else {
                year.push(el);
            }
        });

        const cardDate =
            month.join('') +
            `${(month.length === 2 && !isInputDateLastSlash) || (month.length === 2 && year.length) ? '/' : ''}` +
            year.join('');

        setIsInputDateLastSlash(month.length === 2 && !year.length);

        const diffIndex = findDiffIndex(cardInfo.cardDate, cardDate);

        setCardInfo({ ...cardInfo, cardDate: diffIndex === -1 ? cardDate : cardDate.slice(0, diffIndex) });
    };

    const onCardCodeChange = ({ target: { value } }) => {
        setCardFieldValue('cardCode', value, currentCVVSymbolsQuantity);
    };

    const onCardSaveChange = ({ target: { value } }) => {
        setCardInfo({ ...cardInfo, isSave: !JSON.parse(value) });
    };

    const onCardDateBlur = () => {
        if (cardInfo.cardDate.length < DATE_SYMBOLS_QUANTITY) {
            setCardInfo({ ...cardInfo, cardDate: '' });
        }
    };

    return [
        cardInfo,
        cardErrors,
        onCardNumberChange,
        onCardDateChange,
        onCardDateBlur,
        onCardCodeChange,
        onCardSaveChange
    ];
};

export default useCreditCardValidation;
