import { useEffect, useState } from 'react';

const useReturnFocus = (isLoading, elementRef, trigger) => {
    const [isReturnFocusToElement, setIsReturnFocusToElement] = useState(false);

    const handleBlurSearchInput = () => {
        if (isLoading) setIsReturnFocusToElement(true);
    };

    useEffect(() => {
        elementRef?.current?.addEventListener('blur', handleBlurSearchInput);
        return () => elementRef?.current?.removeEventListener('blur', handleBlurSearchInput);
    }, [elementRef?.current]);

    useEffect(() => {
        if (!isLoading && isReturnFocusToElement && elementRef?.current?.value) {
            elementRef.current.focus();
            setIsReturnFocusToElement(false);
        }
    }, [isLoading, trigger]);
};

export default useReturnFocus;
