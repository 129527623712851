import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { produce } from 'immer';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { omitKeys, getResponseErrorMessage } from '../../../Functions/utils';

import { useGetCountriesQuery } from '../../../redux/api/systemApi';
import {
    useEditInvoiceDetailsMutation,
    useAddInvoiceDetailsMutation,
    useGetInvoiceDetailsQuery
} from '../../../redux/api/invoiceDetailsApi';

import Btn from '../../Overall/UI/Btn/Btn';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Loader from '../../Loader';

import './add-billing-info-modal.scss';

const KEYS_ORDER_TEMPLATE = ['type', 'name', 'address', 'city', 'country', 'zip', 'ssn'];
const MAX_ADDRESS_LENGTH = 60;

const AddBillingInfoModal = ({ className, handleConfirmation, isIntegrated, onClose, noRejectBtn }) => {
    const txt = Langs[global.lng];

    const radios = [
        { value: 'personal', label: txt.labels.billing.invoiceDetails.personal },
        { value: 'business', label: txt.labels.billing.invoiceDetails.business }
    ];

    const [isTouch, setIsTouch] = useState(false);

    const getFields = data => {
        return KEYS_ORDER_TEMPLATE.reduce(
            (ac, key) => ({
                ...ac,
                [key]: data?.[key] || (key === 'type' ? 'personal' : '')
            }),
            {}
        );
    };

    const [state, setState] = useState(getFields());

    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: invoiceDetails, isSuccess: isInvoiceDetailsLoading } = useGetInvoiceDetailsQuery();

    useEffect(() => {
        if (isInvoiceDetailsLoading) setState(getFields(invoiceDetails));
    }, [invoiceDetails]);

    const countriesForRender = useMemo(() => {
        if (!countries) return [];

        const result = [...countries];
        const globalCountryIndex = countries.findIndex(el => el.label === 'Global');

        if (globalCountryIndex !== -1) result.splice(globalCountryIndex, 1);

        return result.map(el => ({ value: el.label, label: el.label }));
    }, [countries]);

    const onChange = useCallback((newData, prop) => {
        setState(
            produce(draft => {
                draft[prop] = newData;
            })
        );
    }, []);

    const isValid = Object.keys(omitKeys(state, [state.type === 'personal' ? 'ssn' : ''])).every(key => !!state[key]);

    const [
        addInvoiceDetails,
        {
            isLoading: isAddInvoiceDetailsLoading,
            isSuccess: isAddInvoiceDetailsSucces,
            isError: isAddInvoiceDetailsError,
            error: addInvoiceDetailsError
        }
    ] = useAddInvoiceDetailsMutation();

    useEffect(() => {
        if (isAddInvoiceDetailsSucces) {
            toast.success(txt.toasts.saved);
            if (!handleConfirmation) hideModal();
            if (handleConfirmation) handleConfirmation(true);
        }
    }, [isAddInvoiceDetailsSucces]);

    useEffect(() => {
        if (isAddInvoiceDetailsError) {
            toast.error(getResponseErrorMessage(addInvoiceDetailsError.data, txt.toasts.saveError));
            if (handleConfirmation) handleConfirmation(false);
        }
    }, [isAddInvoiceDetailsError]);

    const [
        editInvoiceDetails,
        {
            isLoading: isEditInvoiceDetailsLoading,
            isSuccess: isEditInvoiceDetailsSucces,
            isError: isEditInvoiceDetailsError,
            error: editInvoiceDetailsError
        }
    ] = useEditInvoiceDetailsMutation();

    useEffect(() => {
        if (isEditInvoiceDetailsSucces) {
            toast.success(txt.toasts.saved);
            if (!handleConfirmation) hideModal();
            if (handleConfirmation) handleConfirmation(true);
        }
    }, [isEditInvoiceDetailsSucces]);

    useEffect(() => {
        if (isEditInvoiceDetailsError) {
            toast.error(getResponseErrorMessage(editInvoiceDetailsError.data, txt.toasts.saveError));
            if (handleConfirmation) handleConfirmation(false);
        }
    }, [isEditInvoiceDetailsError]);

    const handleSubmit = async () => {
        setIsTouch(true);

        if (isValid) {
            const request = !Object.keys(invoiceDetails ?? {})?.length ? addInvoiceDetails : editInvoiceDetails;
            request({ data: state });
        }
    };

    const getFirstPropName = () => (state.type === 'personal' ? 'name' : 'company');

    const getAddressFieldError = () => {
        if (isTouch && !state.address) return txt.errors.required();
        if (state.address.length >= MAX_ADDRESS_LENGTH) return txt.errors.maxCharactersLength(MAX_ADDRESS_LENGTH);
        return null;
    };

    const isLoaded = isCountriesLoaded && isInvoiceDetailsLoading;

    return (
        <>
            <div className={cn('settings-wrapper', 'billing-info-modal-content', className)}>
                {isLoaded ? (
                    <>
                        <div>
                            <RadioInput
                                dataTest="billing-info-modal_type"
                                items={radios}
                                type="row"
                                onChange={({ target: { value } }) => onChange(value, 'type')}
                                value={state.type}
                            />
                        </div>

                        <div>
                            <TextInput
                                type="textInput"
                                value={state.name}
                                label={txt.labels.billing.invoiceDetails[getFirstPropName()]}
                                onChange={({ target: { value } }) => onChange(value, 'name')}
                                dataTest={`billing-info-modal_${getFirstPropName()}-input`}
                                error={isTouch && !state.name && txt.errors.required()}
                                labelRequire
                            />
                        </div>

                        <div>
                            <SelectInput
                                value={state.country}
                                label={txt.labels.billing.invoiceDetails.country}
                                onChange={({ target: { value } }) => onChange(value, 'country')}
                                dataTest={`billing-info-modal_country-input`}
                                error={isTouch && !state.country && txt.errors.required()}
                                labelRequire
                                items={countriesForRender}
                                isSearchable
                            />
                        </div>

                        <div className="billing-info-modal-content__item_one-string">
                            <div>
                                <TextInput
                                    type="textInput"
                                    value={state.city}
                                    label={txt.labels.billing.invoiceDetails.city}
                                    onChange={({ target: { value } }) => onChange(value, 'city')}
                                    dataTest={`billing-info-modal_city-input`}
                                    error={isTouch && !state.city && txt.errors.required()}
                                    labelRequire
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="textInput"
                                    value={state.zip}
                                    label={txt.labels.billing.invoiceDetails.zip}
                                    onChange={({ target: { value } }) => onChange(value, 'zip')}
                                    dataTest={`billing-info-modal_zip-input`}
                                    error={isTouch && !state.zip && txt.errors.required()}
                                    labelRequire
                                />
                            </div>
                        </div>

                        <div>
                            <TextInput
                                type="textInput"
                                value={state.address}
                                label={txt.labels.billing.invoiceDetails.address}
                                onChange={({ target: { value } }) => {
                                    if (!(value.length > MAX_ADDRESS_LENGTH)) onChange(value, 'address');
                                }}
                                dataTest={`billing-info-modal_address-input`}
                                error={getAddressFieldError()}
                                labelRequire
                            />
                        </div>

                        {state.type === 'business' && (
                            <div>
                                <TextInput
                                    type="textInput"
                                    value={state.ssn}
                                    label={txt.labels.billing.invoiceDetails.ssn}
                                    onChange={({ target: { value } }) => onChange(value, 'ssn')}
                                    dataTest={`billing-info-modal_ssn-input`}
                                    error={isTouch && state.type === 'business' && !state.ssn && txt.errors.required()}
                                    labelRequire
                                />
                            </div>
                        )}

                        {(isEditInvoiceDetailsLoading || isAddInvoiceDetailsLoading) && <Loader isFetching />}
                    </>
                ) : (
                    <Loader />
                )}
            </div>

            {!isIntegrated ? (
                <ModalButtonBar
                    rejectButtonText={!noRejectBtn && txt.buttons.cancel}
                    confirmButtonText={txt.buttons.save}
                    isConfirmDisabled={isEditInvoiceDetailsLoading || isAddInvoiceDetailsLoading}
                    onConfirm={handleSubmit}
                    onReject={onClose}
                />
            ) : (
                <div className="chage-plan-modal__additional-buttons">
                    {isIntegrated?.addBackBtn && (
                        <Btn onClick={isIntegrated?.onBackBtnClick} className="chage-plan-modal__back-button">
                            {isIntegrated?.btnText || txt.buttons.back}
                        </Btn>
                    )}

                    <Btn onClick={handleSubmit} type="filled" className="chage-plan-modal__next-button">
                        {txt.buttons.next}
                    </Btn>
                </div>
            )}

            {/* {isLocalLoading && isIntegrated && <Loader />} */}
        </>
    );
};

export default AddBillingInfoModal;
