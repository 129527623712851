import React from 'react';

import { useGetDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useGetCampaignsAllNamesQuery } from '../../../redux/api/campaignsApi';
import { useGetProjectCurrentPlanQuery, useGetProjectInfoQuery } from '../../../redux/api/projectApi';

import DomainTable from './domain-table';
import Loader from '../../Loader';

const DomainsSettings = () => {
    const {
        data: trackingDomains,
        isSuccess: isTrackingDomainsLoaded,
        isFetching: isTrackingDomainsFetching
    } = useGetDomainsByTypeQuery('tracking');

    const {
        data: redirectDomains,
        isSuccess: isRedirectDomainsLoaded,
        isFetching: isRedirectDomainsFetching
    } = useGetDomainsByTypeQuery('redirect');

    const { data: campaigns, isSuccess: isCampaignsLoaded } = useGetCampaignsAllNamesQuery();

    const { data: currentPlan, isSuccess: isCurrentPlanLoaded } = useGetProjectCurrentPlanQuery();
    const { data: projectInfo, isSuccess: isProjectInfoLoaded } = useGetProjectInfoQuery();

    if (
        !isTrackingDomainsLoaded ||
        !isRedirectDomainsLoaded ||
        !isCurrentPlanLoaded ||
        !isProjectInfoLoaded ||
        !isCampaignsLoaded
    ) {
        return <Loader />;
    }

    return (
        <>
            <DomainTable
                domains={trackingDomains}
                campaigns={campaigns}
                currentPlanName={currentPlan.name}
                customDomains={currentPlan.customDomains}
                isUnlimited={projectInfo.isUnlimited}
                isFetching={isTrackingDomainsFetching}
                type="tracking"
            />

            <DomainTable
                domains={redirectDomains}
                currentPlanName={currentPlan.name}
                customDomains={currentPlan.customDomains}
                isUnlimited={projectInfo.isUnlimited}
                isFetching={isRedirectDomainsFetching}
                type="redirect"
            />
        </>
    );
};

export default DomainsSettings;
