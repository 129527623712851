import React, { useEffect, useState, useRef, useMemo } from 'react';
import cn from 'classnames';

import { useGetReportsQuery } from '../../../redux/api/reportsApi';

import { topMenuConstructor } from './constructors/topMenuConstructor';

import './top-menu.scss';

import MainMenu from './components/main-menu/main-menu';
import AdditionalMenu from './components/additional-menu/additional-menu';
import HiddenMenu from './components/hidden-menu/hidden-menu';
import ReportTopMenu from './components/report-top-menu/report-top-menu';

const HomeTopMenu = ({
    chapter,
    topMenuWrapperRef,
    lastMatchingElementIndex,
    mainMenuForRender,
    additionalMenuForRender,
    hiddenMenuForRender,
    isMenuButton,
    isSeparator,
    isHiddenMenuOpened,
    setIsHiddenMenuOpened
}) => {
    return (
        <div className="top-menu__main-wrapper">
            <div className="top-menu">
                <div
                    className={cn('top-menu__wrapper', {
                        'top-menu__wrapper--resize': lastMatchingElementIndex === -1
                    })}
                    ref={topMenuWrapperRef}
                >
                    {/* // TODO: delete isLoading*/}
                    <MainMenu menu={mainMenuForRender} activeItem={chapter} isLoading={false} />
                    {isSeparator && <div className="top-menu__separator" />}
                    {/* // TODO: delete isLoading*/}
                    <AdditionalMenu menu={additionalMenuForRender} activeItem={chapter} isLoading={false} />
                </div>
                {isMenuButton && (
                    <div
                        className={cn('top-menu__button', { 'top-menu__button--active': isHiddenMenuOpened })}
                        onClick={() => setIsHiddenMenuOpened(!isHiddenMenuOpened)}
                    />
                )}
                {isHiddenMenuOpened && <HiddenMenu menu={hiddenMenuForRender} />}
            </div>
        </div>
    );
};

const TopMenu = ({ type, chapter, reportValue, ...rest }) => {
    const [lastMatchingElementIndex, setLastMatchingElementIndex] = useState(-1);
    const [isHiddenMenuOpened, setIsHiddenMenuOpened] = useState(false);

    const { data: reports } = useGetReportsQuery();

    const topMenuWrapperRef = useRef(null);
    const stopRebuild = useRef(false);

    const { mainMenu, additionalMenu } = topMenuConstructor;
    const hiddenReportsMainMenuItems = ['dashboard', 'triggers'];
    const hiddenReportsAdditionalMenuItems = ['errorLog'];

    const report = reports?.find(el => el.value.toString() === reportValue);
    const filters = report?.filters.map(filter => filter.type) || [];
    const exceptions = [...filters, ...hiddenReportsMainMenuItems, ...hiddenReportsAdditionalMenuItems];

    if (!['campaigns', 'rules'].includes(filters[0])) exceptions.push('paths');

    const filteredMainMenu = useMemo(() => {
        return type === 'reports'
            ? mainMenu.filter(el => !exceptions.includes(el.label))
            : mainMenu.filter(el => el.label !== 'paths');
    }, [type, exceptions, reportValue]);

    const filteredAdditionalMenu = useMemo(() => {
        return type === 'reports'
            ? additionalMenu.filter(el => !exceptions.includes(el.label))
            : additionalMenu
                  .filter(el => el.label !== 'paths')
                  .map(el => ({
                      ...el,
                      ...(el.items ? { items: el.items?.filter(item => item.label !== 'remoteIp') } : {})
                  }));
    }, [type, exceptions, reportValue]);

    const rebuildMenu = isNeedResetIndex => {
        if (!topMenuWrapperRef.current) return;
        if (isNeedResetIndex) setLastMatchingElementIndex(-1);

        const wrapperWidth = topMenuWrapperRef.current.offsetWidth;
        const childs = Array.from(topMenuWrapperRef.current.children);

        let elementIndex;

        childs.reduce((acc, el, i) => {
            const style = getComputedStyle(el);
            const marginLeft = parseInt(style.marginLeft, 10);
            const marginRight = parseInt(style.marginRight, 10);
            const width = parseInt(style.width, 10);
            const resultWidth = marginLeft + width + marginRight;
            const result = acc + resultWidth;

            if (wrapperWidth > result) elementIndex = i;

            return result;
        }, 0);

        setLastMatchingElementIndex(elementIndex);
    };

    useEffect(() => {
        if (lastMatchingElementIndex >= 0) setLastMatchingElementIndex(-1);
        setTimeout(rebuildMenu, 0);
        setIsHiddenMenuOpened(false);
    }, [chapter, report?.groups?.[0], reportValue]);

    useEffect(() => {
        if (lastMatchingElementIndex >= 0 && !stopRebuild.current) setTimeout(rebuildMenu, 0);
        stopRebuild.current = false;
    }, [lastMatchingElementIndex]);

    useEffect(() => {
        let timeoutId = null;

        const resizeListener = () => {
            clearTimeout(timeoutId);
            setIsHiddenMenuOpened(false);
            setLastMatchingElementIndex(-1);
            timeoutId = setTimeout(rebuildMenu, 150);
        };

        window.addEventListener('resize', resizeListener);

        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    useEffect(() => {
        const clickListener = () => setIsHiddenMenuOpened(false);

        if (isHiddenMenuOpened) {
            setTimeout(() => document.addEventListener('click', clickListener), 0);
        }

        return () => document.removeEventListener('click', clickListener);
    }, [isHiddenMenuOpened]);

    const getMenu = menuType => {
        const allMenuItems = [...filteredMainMenu, ...filteredAdditionalMenu];

        const compareMode = type === 'reports' ? report?.groups?.[0] : chapter;

        const menuItemIndex = [...filteredMainMenu, ...filteredAdditionalMenu].findIndex(el => {
            if (el.items) {
                const result = el.items.find(it => it.label === compareMode);

                if (result) return result;
            }

            return el.label === compareMode;
        });

        switch (menuType) {
            case 'main':
                if (lastMatchingElementIndex >= 0 && lastMatchingElementIndex < filteredMainMenu.length) {
                    if (lastMatchingElementIndex <= menuItemIndex) {
                        if (menuItemIndex >= filteredMainMenu.length) {
                            if (menuItemIndex === filteredMainMenu.length) stopRebuild.current = true;
                            return filteredMainMenu.slice(0, lastMatchingElementIndex);
                        }

                        return [
                            ...filteredMainMenu.slice(0, lastMatchingElementIndex),
                            filteredMainMenu[menuItemIndex]
                        ];
                    }

                    return filteredMainMenu.slice(0, lastMatchingElementIndex + 1);
                }

                return filteredMainMenu;

            case 'additional':
                if (lastMatchingElementIndex >= 0 && lastMatchingElementIndex >= filteredMainMenu.length) {
                    if (lastMatchingElementIndex <= menuItemIndex) {
                        if (lastMatchingElementIndex === filteredMainMenu.length) {
                            return [filteredAdditionalMenu[menuItemIndex - filteredMainMenu.length]];
                        }

                        return [
                            ...filteredAdditionalMenu.slice(0, lastMatchingElementIndex - 1 - filteredMainMenu.length),
                            filteredAdditionalMenu[menuItemIndex - filteredMainMenu.length]
                        ];
                    }

                    return filteredAdditionalMenu.slice(0, lastMatchingElementIndex - filteredMainMenu.length);
                }

                if (lastMatchingElementIndex >= 0 && lastMatchingElementIndex < filteredMainMenu.length) {
                    if (lastMatchingElementIndex <= menuItemIndex && menuItemIndex >= filteredMainMenu.length) {
                        return [filteredAdditionalMenu[menuItemIndex - filteredMainMenu.length]];
                    }

                    return [];
                }

                return filteredAdditionalMenu;

            case 'hidden':
                if (lastMatchingElementIndex >= 0 && lastMatchingElementIndex < filteredMainMenu.length) {
                    if (menuItemIndex > lastMatchingElementIndex) {
                        const total = allMenuItems.slice(lastMatchingElementIndex, allMenuItems.length);
                        total.splice(menuItemIndex - lastMatchingElementIndex, 1);

                        return total;
                    }

                    return allMenuItems.slice(lastMatchingElementIndex + 1, allMenuItems.length);
                }

                if (menuItemIndex >= lastMatchingElementIndex) {
                    let total;

                    if (lastMatchingElementIndex === filteredMainMenu.length) {
                        total = allMenuItems.slice(lastMatchingElementIndex, allMenuItems.length);
                        total.splice(menuItemIndex - lastMatchingElementIndex, 1);
                    } else {
                        total = allMenuItems.slice(lastMatchingElementIndex - 1, allMenuItems.length);
                        total.splice(menuItemIndex + 1 - lastMatchingElementIndex, 1);
                    }

                    return total;
                }

                return allMenuItems.slice(lastMatchingElementIndex, allMenuItems.length);

            default:
                throw new Error('Menu type is required');
        }
    };

    const isMenuButton =
        lastMatchingElementIndex >= 0 &&
        lastMatchingElementIndex < filteredMainMenu.length + filteredAdditionalMenu.length;

    const isSeparator = lastMatchingElementIndex === -1 || lastMatchingElementIndex > filteredMainMenu.length;

    const mainMenuForRender = useMemo(() => getMenu('main'), [lastMatchingElementIndex]);
    const additionalMenuForRender = useMemo(() => getMenu('additional'), [lastMatchingElementIndex]);
    const hiddenMenuForRender = useMemo(() => getMenu('hidden'), [lastMatchingElementIndex]);

    return (
        <>
            {type === 'reports' ? (
                <ReportTopMenu
                    {...rest}
                    reports={reports}
                    report={report}
                    reportValue={reportValue}
                    topMenuWrapperRef={topMenuWrapperRef}
                    lastMatchingElementIndex={lastMatchingElementIndex}
                    mainMenuForRender={mainMenuForRender}
                    additionalMenuForRender={additionalMenuForRender}
                    hiddenMenuForRender={hiddenMenuForRender}
                    isSeparator={isSeparator}
                    isMenuButton={isMenuButton}
                    isHiddenMenuOpened={isHiddenMenuOpened}
                    setIsHiddenMenuOpened={setIsHiddenMenuOpened}
                />
            ) : (
                <HomeTopMenu
                    {...rest}
                    chapter={chapter}
                    topMenuWrapperRef={topMenuWrapperRef}
                    lastMatchingElementIndex={lastMatchingElementIndex}
                    mainMenuForRender={mainMenuForRender}
                    additionalMenuForRender={additionalMenuForRender}
                    hiddenMenuForRender={hiddenMenuForRender}
                    isSeparator={isSeparator}
                    isMenuButton={isMenuButton}
                    isHiddenMenuOpened={isHiddenMenuOpened}
                    setIsHiddenMenuOpened={setIsHiddenMenuOpened}
                />
            )}
        </>
    );
};

export default TopMenu;
