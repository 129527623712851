import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { clone, handleObjectChange } from '../../../Functions/utils';
import Langs from '../../../Langs';
import Label from '../../Overall/Forms/Inputs/Label';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import { TdBadgesSquareInline } from '../../Overall/ItemsTable/TdTypes/TdBadgesSquareInline';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import { KEY_CODE } from '../../../Constants';
import { hideModal } from '../../../redux/operations';

const customColumnTemplate = {
    rows: {
        label: '',
        rounding: 2,
        campaigns: true,
        flows: true,
        landers: true,
        offers: true,
        affiliateNetworks: true,
        trafficSources: true,
        custom: {
            formula: 'lp_clicks*event_6',
            format: 'clean',
            custom: '',
            totalScheme: 'none'
        }
    },
    tokens: [
        {
            label: 'minus',
            value: '-',
            bgColor: 'pink'
        },
        {
            label: 'plus',
            value: '+',
            bgColor: 'pink'
        },
        {
            label: 'multiplier',
            value: '*',
            bgColor: 'pink'
        },
        {
            label: 'divider',
            value: '/',
            bgColor: 'pink'
        },
        {
            label: 'startBracket',
            value: '(',
            bgColor: 'pink'
        },
        {
            label: 'endBracket',
            value: ')',
            bgColor: 'pink'
        },
        {
            label: 'clicks',
            value: 'clicks'
        },
        {
            label: 'uClicks',
            value: 'uniq_clicks'
        },
        {
            label: 'leads',
            value: 'leads'
        },
        {
            label: 'lpClicks',
            value: 'lp_clicks'
        },
        {
            label: 'revenue',
            value: 'revenue'
        },
        {
            label: 'cost',
            value: 'cost'
        },
        {
            label: 'event1',
            value: 'event_1'
        },
        {
            label: 'event2',
            value: 'event_2'
        },
        {
            label: 'event3',
            value: 'event_3'
        },
        {
            label: 'event4',
            value: 'event_4'
        },
        {
            label: 'event5',
            value: 'event_5'
        },
        {
            label: 'event6',
            value: 'event_6'
        },
        {
            label: 'event7',
            value: 'event_7'
        },
        {
            label: 'event8',
            value: 'event_8'
        },
        {
            label: 'event9',
            value: 'event_9'
        },
        {
            label: 'event10',
            value: 'event_10'
        },
        {
            label: 'lpCrt',
            value: 'lp_crt',
            bgColor: 'yellow'
        },
        {
            label: 'cpc',
            value: 'cpc',
            bgColor: 'yellow'
        },
        {
            label: 'cr',
            value: 'cr',
            bgColor: 'yellow'
        },
        {
            label: 'epc',
            value: 'epc',
            bgColor: 'yellow'
        },
        {
            label: 'profit',
            value: 'profit',
            bgColor: 'yellow'
        },
        {
            label: 'roi',
            value: 'roi',
            bgColor: 'yellow'
        }
    ]
};

const AddCustomColumn = ({ item, onSubmit, onClose }) => {
    const txt = Langs[global.lng];

    const { rows: initialRows, tokens } = customColumnTemplate;

    const [rows, setRows] = useState(item ? clone(item) : clone(initialRows));
    const [isSubmitTouched, setIsSubmitTouched] = useState(false);

    const isAllValid = rows.label;

    useEffect(() => {
        setRows(item ? clone(item) : clone(initialRows));
    }, []);

    const handleTokenClick = ({ value }) => {
        rows.custom.formula += value;
        setRows({ ...rows });
    };

    const handleChange = handleObjectChange(rows, () => setRows({ ...rows }));

    const handleSubmit = () => {
        const result = item ? { ...rows, key: item.key } : { ...rows, key: uuid() };
        onSubmit(result);
        return true;
    };

    const handleConfirmation = () => {
        setIsSubmitTouched(true);
        return isAllValid ? handleSubmit() : false;
    };

    const handleKeyUp = ({ keyCode }) => {
        if (keyCode === KEY_CODE.ENTER) {
            if (handleConfirmation()) hideModal();
        }
    };

    return (
        <>
            <div className="mx-auto">
                <div className="dfc plr16 w100 mt10 add-custom-column">
                    <TextInput
                        dataTest="add-custom-column_name-input"
                        label="Column name"
                        placeholder={txt.labels.name}
                        value={rows.label}
                        hint="hint"
                        onChange={e => handleChange(e, 'label')}
                        onKeyUp={handleKeyUp}
                        error={isSubmitTouched && !rows.label && txt.errors.required(txt.labels.name)}
                    />
                    <TextInput
                        dataTest="add-custom-column_formula-textarea"
                        type="textarea"
                        label="Formula"
                        value={rows.custom.formula}
                        hint="Formula hint"
                        onChange={e => handleChange(e, 'custom.formula')}
                    />
                    <div className="add-custom-column__badges">
                        <TdBadgesSquareInline items={tokens} onClick={handleTokenClick} />
                    </div>
                    <div className="add-custom-column__choice-fields">
                        <SelectInput
                            dataTest="add-custom-column_routing-select-input"
                            label="Decimal"
                            value={rows.rounding}
                            items={[1, 2, 3]}
                            hint="Decimal"
                            onChange={e => handleChange(e, 'rounding')}
                            className="pb10"
                        />
                        <div className="j4 pb10">
                            <Label label="Format" hint="Format" className="pr15" />
                            <RadioInput
                                dataTest="add-custom-column_format"
                                type="row"
                                value={rows.custom.format}
                                items={[
                                    { label: '99', value: 'clean' },
                                    { label: '$99', value: 'prefix' },
                                    { label: '99%', value: 'suffix' },
                                    { label: 'Time', value: 'time' },
                                    { label: '99', value: 'custom' }
                                ]}
                                onChange={e => handleChange(e, 'custom.format')}
                                className="pr10"
                            />
                            <TextInput
                                dataTest="add-custom-column_custom-input"
                                value={rows.custom.custom}
                                hint="Hint"
                                placeholder="Custom"
                                disabled={rows.custom.format !== 'custom'}
                                onChange={e => handleChange(e, 'custom.custom')}
                            />
                        </div>
                        <RadioInput
                            dataTest="add-custom-column_total-scheme"
                            type="row"
                            label="Total scheme"
                            value={rows.custom.totalScheme}
                            items={['none', 'sum', 'average']}
                            hint="hint"
                            onChange={e => handleChange(e, 'custom.totalScheme')}
                            className="pb10"
                        />
                    </div>
                </div>
            </div>
            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                onConfirm={handleConfirmation}
                isConfirmDisabled={isSubmitTouched && !isAllValid}
                onReject={onClose}
            />
        </>
    );
};

export default AddCustomColumn;
