import React from 'react';

import PageHeaderLinks from '../page-header-links/page-header-links';

import './page-header.scss';

const PageHeader = ({ title, link, items }) => {
    return (
        <div className="page-header">
            <div className="page-header__title">{title}</div>

            <PageHeaderLinks link={link} items={items} />
        </div>
    );
};

export default PageHeader;
