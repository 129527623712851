import React from 'react';

import { fixRegexPattern, getNotDangerouslyHTML } from '../../../../../Functions/utils';

const SelectItemLabel = ({ label, match, isDangerously }) => {
    if (!isDangerously) label = getNotDangerouslyHTML(label);

    if (match) {
        label = label?.replace(
            new RegExp(fixRegexPattern(match), 'i'),
            highlight => `<span class="bg--yellow">${highlight}</span>`
        );
    }

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: label }} />;
};

export default SelectItemLabel;
