import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Cpu } from 'react-feather';

import { getIntlFormattedValue } from '../../../../../Functions/utils';

import './td-money.scss';

const zeroCurrency = {
    value: 'USD',
    label: 'US Dollar',
    symbol: '$'
};

const TdMoney = React.memo(
    ({
        value,
        rounding,
        isPercentage,
        currencies,
        defaultCurrency,
        isIntegrationCostUsed,
        cellKey,
        chapter,
        integration,
        integrationData
    }) => {
        const initialCurrency = (value?.currency || defaultCurrency) ?? 'USD';
        const currency = currencies?.find(el => el.value === initialCurrency) ?? zeroCurrency;
        const currencySymbol = currency && currency.symbol !== currency.value ? currency.symbol : '';

        let initialValue = 0;
        if (typeof value === 'number') initialValue = value;
        if (typeof value === 'string') initialValue = parseInt(value, 10);
        if (typeof value === 'object') initialValue = value?.value || 0;

        const formattedValue = getIntlFormattedValue(Math.abs(initialValue), rounding);
        const isPositive = initialValue > 0;
        const isNegative = initialValue < 0;
        const percentageValue = `${formattedValue}%`;
        const currencyValue = `${currencySymbol}${formattedValue}${!currencySymbol ? currency?.value ?? '' : ''}`;

        const getValueWithCurrency = val => `${currencySymbol}${val}${!currencySymbol ? currency?.value ?? '' : ''}`;

        if (
            (cellKey === 'cost' || cellKey === 'integrationCost') &&
            chapter === 'campaigns' &&
            integration &&
            integration.isActive &&
            integrationData?.isSuccessful
        ) {
            if (cellKey === 'cost' && isIntegrationCostUsed) {
                return (
                    <div className="td-money__cost">
                        <div className="td-money__cost-value">{currencyValue}</div>
                        <div className="td-money__cost-tooltip">
                            <div className="td-money__cost-tooltip-header">
                                This cost <span>{currencyValue}</span> doesn’t use for calculating columns and Total
                                cost because we has got a cost{' '}
                                <span>
                                    {getValueWithCurrency(
                                        getIntlFormattedValue(Math.abs(integrationData.costAmount), rounding)
                                    )}
                                </span>{' '}
                                from Tr. Source through integration (see{' '}
                                <div className="td-money__cost-tooltip-cost-icon">
                                    <Cpu size={15} stroke="#9AA0B9" /> Cost
                                </div>{' '}
                                column).
                            </div>

                            <div className="td-money__cost-tooltip-footer">
                                Go to <Link to={'/settings/general'}>General settings</Link> and turn off{' '}
                                <b>“Use Tr. Source cost for calculating”</b> if you need.
                            </div>
                        </div>
                    </div>
                );
            }

            if (cellKey === 'integrationCost' && !isIntegrationCostUsed) {
                return (
                    <div className="td-money__cost">
                        <div className="td-money__cost-value">{currencyValue}</div>
                        <div className="td-money__cost-tooltip">
                            <div className="td-money__cost-tooltip-header">
                                This cost{' '}
                                <span>
                                    {getValueWithCurrency(
                                        getIntlFormattedValue(Math.abs(integrationData.costAmount), rounding)
                                    )}
                                </span>{' '}
                                doesn’t use for calculating columns and Total cost.
                            </div>

                            <div className="td-money__cost-tooltip-footer">
                                Go to <Link to={'/settings/general'}>General settings</Link> and turn on{' '}
                                <b>“Use Tr. Source cost for calculating”</b> if you need.
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={cn({ 'text--positive': isPositive }, { 'text--negative': isNegative })}>
                {isPercentage && percentageValue}

                {!isPercentage && (isNegative ? `(${currencyValue})` : currencyValue)}
            </div>
        );
    }
);

TdMoney.displayName = 'TdMoney';
export default TdMoney;
