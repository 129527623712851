import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { APP_ROUTES } from '../../../../../Constants';
import { camelToKebabCase } from '../../../../../Functions/utils';

import SelectItemLabel from './SelectItemLabel';

export const SELECT_INPUT_OPTIONS_TYPE = {
    LINK: 'link'
};

const SelectItem = React.forwardRef((props, ref) => {
    const {
        label = '',
        isActive,
        onItemClick,
        onMouseEnter,
        disabled,
        dataTest,
        highlightedText,
        icon,
        item,
        optionsType,
        isDangerouslyLabel
    } = props;

    if (optionsType === SELECT_INPUT_OPTIONS_TYPE.LINK) {
        return (
            <NavLink
                to={`${APP_ROUTES.HOME}/${camelToKebabCase(item)}`}
                data-test={`select-item_${dataTest}-div`}
                className={cn(
                    'form-select-options__item',
                    isActive && 'form-select-options__item--active',
                    disabled && 'form-select-options__item--disabled'
                )}
                onClick={onItemClick}
            >
                {label}
            </NavLink>
        );
    }

    return (
        <div
            data-test={`select-item_${dataTest}-div`}
            className={cn(
                'form-select-options__item',
                isActive && 'form-select-options__item--active',
                disabled && 'form-select-options__item--disabled'
            )}
            onMouseDown={onItemClick}
            onMouseEnter={onMouseEnter}
            ref={ref}
        >
            <SelectItemLabel label={label} match={highlightedText} isDangerously={isDangerouslyLabel} />
            {icon}
        </div>
    );
});

export default SelectItem;
