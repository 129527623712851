import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import produce from 'immer';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, NEW_ITEM_WITH_COLUMNS, TRACKING_METHOD, USER_ROLES } from '../../../Constants';
import { camelToKebabCase, clone, getParams, immerUpdateFn } from '../../../Functions/utils';
import { getTrackingMethodName } from '../../../redux/operations';

import { useGetStorageQuery } from '../../../redux/api/storeApi';
import { useGetDefaultDomainsByTypeQuery, useGetDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useGetFlowByIdQuery, useGetFlowsWithColumnsQuery } from '../../../redux/api/flowsApi';
import { useGetLandersWithColumnsQuery } from '../../../redux/api/landersApi';
import { useGetOffersWithColumnsQuery } from '../../../redux/api/offersApi';
import { useGetAffiliateNetworksAllNamesQuery } from '../../../redux/api/affiliateNetworksApi';
import { useGetWorkspacesAllNamesQuery } from '../../../redux/api/workspacesApi';
import { useGetCampaignByIdQuery, useGetCampaignsAllModalQuery } from '../../../redux/api/campaignsApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetUserByIdQuery } from '../../../redux/api/usersApi';
import {
    useGetCountriesQuery,
    useGetCurrenciesQuery,
    useGetLanguagesQuery,
    useGetTimezonesQuery
} from '../../../redux/api/systemApi';
import {
    useGetTrafficSourceByIdQuery,
    useGetTrafficSourcesWithColumnsQuery
} from '../../../redux/api/trafficSourcesApi';
import {
    useGetBrandsQuery,
    useGetBrowsersQuery,
    useGetConnectionProxyQuery,
    useGetConnectionTypesQuery,
    useGetDeviceTypesQuery,
    useGetOsQuery
} from '../../../redux/api/dictionariesApi';

import campaignTemplate from './templates/campaign-template';
import flowTemplate from './templates/flow-template';
import triggersTemplate from './templates/triggers-template';

import NewTrigger from './NewTrigger';
import NewFlowCampaign from './NewFlowCampaign';
import Icon from '../../Overall/Icon';
import Btn from '../../Overall/UI/Btn/Btn';
import CreatedUpdatedUserInfo from '../../Overall/CreatedUpdatedUserInfo/CreatedUpdatedUserInfo';
import Loader from '../../Loader';

import './new-item.scss';

const NewItem = ({ chapter, status, setStatus }) => {
    const txt = Langs[global.lng];

    const history = useHistory();

    const entity = status.type;
    const actualDataForEntity = useRef(null);

    const template = useMemo(() => {
        switch (entity) {
            case 'flows':
                return clone(flowTemplate);
            case 'triggers':
                return clone(triggersTemplate);
            default:
                return clone(campaignTemplate);
        }
    }, [entity]);

    const [loadingStatuses, setLoadingStatuses] = useState({
        trackingDomain: !status.value && entity === 'campaigns',
        trafficSourceData: status.value && entity === 'campaigns',
        mainEntity: !!status.value,
        createUser: false,
        updateUser: false
    });

    const updateLoadingStatuses = useCallback((prop, newLoadingStatus) => {
        setLoadingStatuses(
            produce(draft => {
                draft[prop] = newLoadingStatus;
            })
        );
    }, []);

    const [isProcessing, setIsProcessing] = useState(false);
    const [actionState, setActionState] = useState({ isPress: false, isSave: false, isClose: false, isRestore: false });
    const [trackingMethod, setTrackingMethod] = useState({ value: TRACKING_METHOD.REDIRECT });

    const updateActionState = useCallback((prop, newValue) => {
        setActionState(produce(draft => immerUpdateFn(draft, prop, newValue)));
    }, []);

    const [commonValues, setCommonValues] = useState({
        redirect: template.flowOptionValues,
        offer: template.flowOptionValues,
        lander_offers: template.flowOptionValues
    });
    const originalValues = useRef(null);

    const [mainOptionValues, setMainOptionValues] = useState(template.mainOptionValues);
    const updateMainOptionValues = useCallback((prop, newValue) => {
        setMainOptionValues(produce(draft => immerUpdateFn(draft, prop, newValue)));
    }, []);

    const [headerValues, setHeaderValues] = useState(null);

    const currentValues = commonValues[getTrackingMethodName(trackingMethod)];

    const updateFlowValues = useCallback(
        (prop, newValue, targetTrackingMethod) => {
            const target = getTrackingMethodName(targetTrackingMethod ?? trackingMethod);

            setCommonValues(
                produce(draft => {
                    if (Array.isArray(prop)) {
                        prop.forEach(el => {
                            draft[target][el.prop] = el.value;
                        });
                    } else {
                        draft[target][prop] = newValue;
                    }
                })
            );
        },
        [trackingMethod]
    );

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const { data: trackingDomains, isSuccess: isTrackingDomainsLoaded } = useGetDomainsByTypeQuery('tracking', {
        skip: entity !== 'campaigns'
    });

    const { data: defaultTrackingDomain, isSuccess: isDefaultTrackingDomainLoaded } = useGetDefaultDomainsByTypeQuery(
        'tracking',
        { skip: entity !== 'campaigns' }
    );

    useEffect(() => {
        if (isDefaultTrackingDomainLoaded && !status.value) {
            updateMainOptionValues('trackingDomain', defaultTrackingDomain.id);
            updateLoadingStatuses('trackingDomain', false);
        }
    }, [defaultTrackingDomain]);

    const { data: flowsList, isSuccess: isFlowsListLoaded } = useGetFlowsWithColumnsQuery(
        getParams(NEW_ITEM_WITH_COLUMNS.flows)
    );

    const { data: landersList, isSuccess: isLandersListLoaded } = useGetLandersWithColumnsQuery(
        getParams(NEW_ITEM_WITH_COLUMNS.landers)
    );

    const { data: offersList, isSuccess: isOffersListLoaded } = useGetOffersWithColumnsQuery(
        getParams(NEW_ITEM_WITH_COLUMNS.offers)
    );

    const { data: trafficSourcesList, isSuccess: isTrafficSourcesListLoaded } = useGetTrafficSourcesWithColumnsQuery(
        getParams(NEW_ITEM_WITH_COLUMNS.trafficSources),
        { skip: entity !== 'campaigns' }
    );

    const { data: affiliateNetworksList, isSuccess: isAffiliateNetworksListLoaded } =
        useGetAffiliateNetworksAllNamesQuery();

    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();

    const { data: campaignData, isSuccess: isCampaignDataLoaded } = useGetCampaignByIdQuery(status.value, {
        skip: !status.value || entity !== 'campaigns'
    });

    const { data: flowData, isSuccess: isFlowDataLoaded } = useGetFlowByIdQuery(status.value, {
        skip: !status.value || entity !== 'flows'
    });

    const allEntityData = entity === 'campaigns' ? campaignData : flowData;

    const { data: trafficSourceData, isSuccess: trafficSourceDataLoaded } = useGetTrafficSourceByIdQuery(
        mainOptionValues?.trafficSource,
        {
            skip: !mainOptionValues?.trafficSource
        }
    );

    useEffect(() => {
        if (trafficSourceDataLoaded) updateLoadingStatuses('trafficSourceData', false);
    }, [trafficSourceData, campaignData, flowData, status]);

    useEffect(() => {
        if (status.mode === ENTITY_MODE_STATUS.EDIT || (status.mode === ENTITY_MODE_STATUS.DUPLICATE && status.value)) {
            if (isCampaignDataLoaded || isFlowDataLoaded) {
                const response = entity === 'campaigns' ? campaignData : flowData;

                setHeaderValues({
                    createdAtUserId: response.createdAtUserId,
                    updatedAtUserId: response.updatedAtUserId,
                    createdAt: response.createdAt,
                    updatedAt: response.updatedAt,
                    name: response.displayName ?? response.name
                });

                setLoadingStatuses(
                    produce(draft => {
                        draft.createUser = !!response.createdAtUserId;
                        draft.updateUser = !!response.updatedAtUserId;
                    })
                );

                const isNeedReplaceWorkspaceId =
                    status.mode === ENTITY_MODE_STATUS.DUPLICATE &&
                    status.type === 'flows' &&
                    userSettings.role !== USER_ROLES.OWNER &&
                    response.workspaceId === userSettings.publicWorkspaceId;

                const mainOptionData = {
                    workspaceId: isNeedReplaceWorkspaceId ? userSettings.initialWorkspaceId : response.workspaceId,
                    tags: response.tags,
                    trackingDomain: response.trackingDomain,
                    links: response.links,
                    trafficSource: response.trafficSource,
                    trafficSourceCampaignId: response.trafficSourceCampaignId,
                    isIntegrationActive: response.integration?.isActive,
                    country: response.country,
                    name: status.mode === ENTITY_MODE_STATUS.DUPLICATE ? `${response.name} Copy` : response.name,
                    referrerDefaultHideType: response.referrerDefaultHideType,
                    costModel: response.costModel,
                    initialCostModel: response.costModel,
                    costPrice: (response.costPrice ?? '') + '',
                    initialCostPrice: (response.costPrice ?? '') + '',
                    percentageShare: response.percentageShare,
                    notes: response.notes,
                    ...(entity === 'flows' ? { campaignsToAdd: [] } : {}),
                    ...(entity === 'flows' ? { campaigns: response.campaigns } : {})
                };

                setMainOptionValues(mainOptionData);

                const isRotate = response.flowValue && response.flowValue !== 'custom';

                const flowOptionData = {
                    flowValue: response.flowValue,
                    initialFlowValue: response.flowValue,
                    trackingMethod: isRotate ? response.flow.trackingMethod : response.trackingMethod,
                    initialTrackingMethod: isRotate ? response.flow.trackingMethod : response.trackingMethod,
                    defaultRule: isRotate ? response.flow.defaultRule : response.defaultRule,
                    rules: isRotate ? response.flow.rules : response.rules,
                    originalSettingsAt: response.originalSettingsAt
                };

                if (status.mode === ENTITY_MODE_STATUS.DUPLICATE) setStatus({ ...status, value: null });
                originalValues.current = response;

                setCommonValues({
                    ...commonValues,
                    [getTrackingMethodName(flowOptionData?.trackingMethod)]: flowOptionData
                });

                setTrackingMethod(flowOptionData.trackingMethod);

                updateLoadingStatuses('mainEntity', false);
                actualDataForEntity.current = entity;
                return;
            }
        }

        if (
            status.mode === ENTITY_MODE_STATUS.CREATE ||
            (status.mode === ENTITY_MODE_STATUS.DUPLICATE && !status.value)
        ) {
            if (actualDataForEntity.current) {
                setCommonValues({
                    redirect: template.flowOptionValues,
                    offer: template.flowOptionValues,
                    lander_offers: template.flowOptionValues
                });

                setTrackingMethod({ value: TRACKING_METHOD.REDIRECT });

                setMainOptionValues(template.mainOptionValues);

                actualDataForEntity.current = entity;
            } else {
                setTrackingMethod({ value: TRACKING_METHOD.REDIRECT });
                actualDataForEntity.current = entity;
            }
        }
    }, [campaignData, flowData, entity]);

    const { data: campaignsInFlow, isSuccess: isCampaignsInFlowLoaded } = useGetCampaignsAllModalQuery(undefined, {
        skip: entity !== 'flows'
    });

    const { data: createdUserInfo, isSuccess: createdUserInfoLoaded } = useGetUserByIdQuery(
        headerValues?.createdAtUserId,
        {
            skip: !headerValues?.createdAtUserId
        }
    );

    const { data: updatedUserInfo, isSuccess: updatedUserInfoLoaded } = useGetUserByIdQuery(
        headerValues?.updatedAtUserId,
        {
            skip: !headerValues?.updatedAtUserId
        }
    );

    useEffect(() => {
        if (createdUserInfoLoaded) updateLoadingStatuses('createUser', false);
        if (updatedUserInfoLoaded) updateLoadingStatuses('updateUser', false);
    }, [createdUserInfo, updatedUserInfo, campaignData, flowData, status]);

    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();
    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: timezones, isSuccess: isTimezonesLoaded } = useGetTimezonesQuery();
    const { data: languages, isSuccess: isLanguagesLoaded } = useGetLanguagesQuery();

    const { data: browsers, isSuccess: isBrowsersLoaded } = useGetBrowsersQuery();
    const { data: brands, isSuccess: isBrandsLoaded } = useGetBrandsQuery();
    const { data: os, isSuccess: isOsLoaded } = useGetOsQuery();
    const { data: connectionTypes, isSuccess: isConnectionTypesLoaded } = useGetConnectionTypesQuery();
    const { data: connectionProxy, isSuccess: isConnectionProxyLoaded } = useGetConnectionProxyQuery();
    const { data: deviceTypes, isSuccess: isDeviceTypesLoaded } = useGetDeviceTypesQuery();

    const isArchiveEditStatus = status.mode === ENTITY_MODE_STATUS.EDIT && allEntityData?.isArchived;
    const isHideSaveButtons =
        status.mode === ENTITY_MODE_STATUS.EDIT &&
        userSettings.role === USER_ROLES.USER &&
        originalValues.current?.workspaceId === userSettings.publicWorkspaceId;

    const closeNewItemComponent = () => {
        if (chapter === 'reports') setStatus({ mode: ENTITY_MODE_STATUS.DEFAULT, value: null, type: chapter });
        else {
            history.push(`/home/${camelToKebabCase(chapter)}`, {
                mode: ENTITY_MODE_STATUS.DEFAULT,
                id: null
            });
        }
    };

    const newItemProps = {
        status,
        setStatus,
        chapter,
        isProcessing,
        setIsProcessing,
        actionState,
        updateActionState,
        originalValues,
        flowValues: currentValues,
        campaignsInFlow,
        updateFlowValues,
        mainOptionValues,
        updateMainOptionValues,
        trafficSourceData,
        trackingMethod,
        setTrackingMethod,
        closeNewItemComponent,
        publicWorkspaceId: userSettings.publicWorkspaceId,

        trackingDomains,
        flowsList,
        landersList,
        offersList,
        affiliateNetworksList,
        trafficSourcesList,
        workspacesList,
        storage,
        countries,
        currencies,
        timezones,
        languages,
        browsers,
        brands,
        os,
        connectionTypes,
        connectionProxy,
        deviceTypes
    };

    const ContentComponent = entity === 'triggers' ? NewTrigger : NewFlowCampaign;

    const isMainDataLoaded = Object.values(loadingStatuses).every(el => !el);

    const isLoaded =
        isFlowsListLoaded &&
        isLandersListLoaded &&
        isOffersListLoaded &&
        isAffiliateNetworksListLoaded &&
        isWorkspacesListLoaded &&
        (entity === 'campaigns' ? isTrackingDomainsLoaded : true) &&
        (entity === 'campaigns' ? isTrafficSourcesListLoaded : true) &&
        (entity === 'flows' ? isCampaignsInFlowLoaded : true) &&
        isUserSettingsLoaded &&
        isStorageLoaded &&
        isCountriesLoaded &&
        isTimezonesLoaded &&
        isLanguagesLoaded &&
        isBrowsersLoaded &&
        isBrandsLoaded &&
        isOsLoaded &&
        isConnectionTypesLoaded &&
        isConnectionProxyLoaded &&
        isDeviceTypesLoaded &&
        isCurrenciesLoaded;

    const isAllLoaded = isLoaded && isMainDataLoaded && actualDataForEntity.current === entity;

    return (
        <div className="main-wrapper">
            <div className="new-item">
                <div className="new-item__header">
                    <div className="new-item__header-col">
                        <div className="new-item__title">
                            <>
                                <span>
                                    {status?.mode === ENTITY_MODE_STATUS.EDIT && status?.value
                                        ? txt.labels.edit
                                        : txt.labels.new}
                                </span>
                                {status?.mode === ENTITY_MODE_STATUS.EDIT && status?.value ? (
                                    <span> {headerValues?.name} </span>
                                ) : null}
                            </>
                            {/* {status?.value && <span> {getItemName().trafficSource} - </span>}
                                {status?.value && <span> {getItemName().country} - </span>}
                                {status?.value && <span> {values?.name} </span>}
                                {!status?.value && <span> {txt.singulars[entity]}</span>} */}
                        </div>
                        {headerValues?.createdAtUserId && (
                            <div className="new-item__user-info">
                                <CreatedUpdatedUserInfo
                                    createdUserName={createdUserInfo?.name}
                                    updatedUserName={updatedUserInfo?.name}
                                    createdAt={headerValues.createdAt}
                                    updatedAt={headerValues.updatedAt}
                                    isTheSameUser={headerValues.createdAtUserId === headerValues.updatedAtUserId}
                                    isTheSameDate={headerValues.createdAt === headerValues.updatedAt}
                                />
                            </div>
                        )}
                    </div>
                    <div className="new-item__buttons">
                        {!isArchiveEditStatus && !isHideSaveButtons && (
                            <>
                                <Btn
                                    dataTest="new-item_save-btn"
                                    type="filled"
                                    disabled={isProcessing || !isAllLoaded}
                                    onClick={() =>
                                        updateActionState([
                                            { prop: 'isPress', value: true },
                                            { prop: 'isSave', value: true }
                                        ])
                                    }
                                >
                                    <Icon.Save />
                                    &nbsp;{txt.buttons.save}
                                </Btn>

                                <Btn
                                    dataTest="new-item_save-and-close-btn"
                                    type="outline"
                                    disabled={isProcessing || !isAllLoaded}
                                    onClick={() =>
                                        updateActionState([
                                            { prop: 'isPress', value: true },
                                            { prop: 'isSave', value: true },
                                            { prop: 'isClose', value: true }
                                        ])
                                    }
                                >
                                    <Icon.Save />
                                    &nbsp;{txt.buttons.saveAndClose}
                                </Btn>
                            </>
                        )}

                        {isArchiveEditStatus && (
                            <Btn
                                dataTest="new-item_save-and-restore-btn"
                                type="filled"
                                disabled={isProcessing || !isAllLoaded}
                                onClick={() =>
                                    updateActionState([
                                        { prop: 'isPress', value: true },
                                        { prop: 'isSave', value: true },
                                        { prop: 'isClose', value: true },
                                        { prop: 'isRestore', value: true }
                                    ])
                                }
                            >
                                <Icon.RotateCcw />
                                &nbsp;{txt.buttons.saveAndRestore}
                            </Btn>
                        )}

                        <Btn
                            dataTest="new-item_close-btn"
                            type="outline"
                            onClick={() => {
                                if (!isAllLoaded) closeNewItemComponent();
                                else {
                                    updateActionState([
                                        { prop: 'isPress', value: true },
                                        { prop: 'isClose', value: true }
                                    ]);
                                }
                            }}
                            disabled={isProcessing}
                        >
                            <Icon.X />
                            &nbsp;{txt.buttons.close}
                        </Btn>
                    </div>
                </div>

                <div className="new-item__content">
                    {isAllLoaded ? <ContentComponent {...newItemProps} /> : <Loader />}

                    {isProcessing && <Loader isFetching />}
                </div>
            </div>
        </div>
    );
};

export default NewItem;
