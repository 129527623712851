import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { compare, getResponseErrorMessage } from '../../../Functions/utils';
import useFocus from '../../../Functions/hooks/useFocus';

import { useAddIpFilterMutation, useEditIpFilterMutation } from '../../../redux/api/ipFilteringApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import JustAlert from '../../../components/just-alert/just-alert';
import Loader from '../../Loader';

import './add-ip-ua-filter-modal.scss';

const filterTemplate = {
    name: '',
    ip: '',
    userAgent: ''
};

const AddIpUaFilterModal = ({ item, onTouch, onClose }) => {
    const txt = Langs[global.lng];

    const [nameInputRef, setNameFocus] = useFocus();

    const [isSubmit, setIsSubmit] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const [filter, setFilter] = useState(item ?? filterTemplate);
    const savedFilter = useRef(filter);

    const updateFilter = updatedFields => setFilter(prevData => ({ ...prevData, ...updatedFields }));

    const isAllValid = !!filter.ip && !!filter.name;

    useEffect(() => {
        if (!filter.name) setTimeout(setNameFocus, 0);
    }, []);

    useEffect(() => {
        onTouch(!compare(filter, savedFilter.current));
    }, [filter]);

    const [addIpFilter, { isSuccess: isAddIpFilterSuccess, isError: isAddIpFilterError, error: addIpFilterError }] =
        useAddIpFilterMutation();

    const [editIpFilter, { isSuccess: isEditIpFilterSuccess, isError: isEditIpFilterError, error: editIpFilterError }] =
        useEditIpFilterMutation();

    useEffect(() => {
        if (isAddIpFilterError) toast.error(getResponseErrorMessage(addIpFilterError.data, txt.toasts.saveError));

        if (isEditIpFilterError) toast.error(getResponseErrorMessage(editIpFilterError.data, txt.toasts.saveError));

        if (isAddIpFilterError || isEditIpFilterError) setIsProcessing(false);
    }, [isAddIpFilterError, isEditIpFilterError]);

    useEffect(() => {
        if (isAddIpFilterSuccess || isEditIpFilterSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isAddIpFilterSuccess, isEditIpFilterSuccess]);

    const onModalConfirm = () => {
        setIsSubmit(true);

        if (isAllValid) {
            setIsProcessing(true);

            const request = item ? editIpFilter : addIpFilter;

            request({
                ...(item ? { id: filter.id } : {}),
                data: filter
            });
        }
    };

    return (
        <>
            <div className="add-ip-ua-filter-modal">
                <JustAlert className="mb20" type="info">
                    {txt.alerts.newFilteringRule}
                </JustAlert>

                <TextInput
                    dataTest="filtering-rule-modal_filter-name-input"
                    className="mb20"
                    ref={nameInputRef}
                    label={txt.labels.filterName}
                    value={filter.name}
                    onChange={({ target: { value } }) => updateFilter({ name: value })}
                    error={isSubmit && !filter.name && txt.errors.required()}
                />

                <TextInput
                    dataTest="filtering-rule-modal_ip-range-textarea"
                    className="mb20"
                    type="textarea"
                    label={txt.labels.ipOrIpRange}
                    value={filter.ip}
                    onChange={({ target: { value } }) => updateFilter({ ip: value })}
                    require={true}
                    textRight={txt.texts.ipRangeInputTextRight}
                    error={isSubmit && !filter.ip && txt.errors.ipRangeRequired}
                />

                <TextInput
                    dataTest="filtering-rule-modal_user-agent-textarea"
                    type="textarea"
                    label={txt.labels.userAgent}
                    value={filter.userAgent}
                    onChange={({ target: { value } }) => updateFilter({ userAgent: value })}
                    textRight={txt.texts.userAgentInputTextRight}
                />

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                onConfirm={onModalConfirm}
                isConfirmDisabled={(isSubmit && !isAllValid) || isProcessing}
                onReject={onClose}
            />
        </>
    );
};

export default AddIpUaFilterModal;
