import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Langs from '../../../../../Langs';

import FormActions from '../../../Forms/FormActions/FormActions';
import Label from '../../../Forms/Inputs/Label';
import TextInput from '../../../Forms/Inputs/TextInput';

import './time-picker.scss';

export const getTimePickerData = ({ from, to }) => `${from} - ${to}`;
export const getTimePickerNumber = time => Number(time.replace(/\D/g, ''));

const TimePicker = ({ label, onChange, isEnabled, isMatched, from, to }) => {
    const txt = Langs[global.lng];

    const [startTime, setStartTime] = useState(from);
    const [endTime, setEndTime] = useState(to);

    const validateField = (isStartInput, iputValue) => {
        const value = iputValue ?? (isStartInput ? startTime : endTime);
        const start = isStartInput ? value : startTime;
        const end = !isStartInput ? value : endTime;
        let isValid = true;
        let errorText;

        if (!value) {
            isValid = false;
            errorText = txt.errors.timePickerFormat;
        } else if (!moment(value, 'HH:mm', true).isValid()) {
            isValid = false;
            errorText = txt.errors.incorrectValue;
        } else if (!isStartInput && getTimePickerNumber(start) > getTimePickerNumber(end) && end !== '00:00') {
            isValid = false;
            errorText = txt.errors.incorrectValue;
        } else errorText = '';
        return { isValid, errorText };
    };

    const [startTimeValidation, setStartTimeValidation] = useState(validateField(true));
    const [endTimeValidation, setEndTimeValidation] = useState(validateField());
    const MASK = '99:99';

    const blurTimeHandler = (time, isStartInput) => {
        const setFunc = isStartInput ? setStartTime : setEndTime;
        const setValidationFunc = isStartInput ? setStartTimeValidation : setEndTimeValidation;

        const formattedTime = moment(time, 'HH:mm').format('HH:mm');
        const output = formattedTime === 'Invalid date' ? '' : formattedTime;

        setFunc(output);
        setValidationFunc(validateField(isStartInput, output));
    };

    useEffect(() => setEndTimeValidation(validateField()), [startTimeValidation]);

    useEffect(() => onChange(startTime, 'from'), [startTimeValidation]);
    useEffect(() => onChange(endTime, 'to'), [endTimeValidation]);

    return (
        <FormActions
            label={label}
            isEnabled={isEnabled}
            onChange={onChange}
            isMatched={isMatched}
            className="time-picker"
        >
            <div className="time-picker__range">
                <Label label="between" />
                <TextInput
                    dataTest="time-picker_start-time-input"
                    className="time-picker__input"
                    value={startTime}
                    onChange={e => setStartTime(e.target.value)}
                    placeholder="00:00"
                    onFocus={e => e.target.select()}
                    onBlur={() => blurTimeHandler(startTime, true)}
                    error={startTimeValidation.errorText}
                    mask={MASK}
                />
                <Label label="and" />
                <TextInput
                    dataTest="time-picker_end-time-input"
                    className="time-picker__input"
                    value={endTime}
                    onChange={e => setEndTime(e.target.value)}
                    placeholder="00:00"
                    onFocus={e => e.target.select()}
                    onBlur={() => blurTimeHandler(endTime)}
                    error={endTimeValidation.errorText}
                    mask={MASK}
                />
            </div>
        </FormActions>
    );
};

export default TimePicker;
