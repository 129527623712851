import { useState, useEffect, useCallback } from 'react';

import { URL_PARAMETER } from '../../Constants';
import { getOnlyASCIICodeText } from '../utils';

const useToken = (inputUrl, initialTokens, urlInputRef) => {
    const [url, setUrl] = useState(getOnlyASCIICodeText(inputUrl));
    const [error, setError] = useState(false);

    const getTokens = incomingTokens =>
        typeof incomingTokens[0] === 'string' ? incomingTokens.map(label => ({ label, value: label })) : incomingTokens;

    // const [tokens, setTokens] = useState(getTokens(initialTokens));

    const tokens = getTokens(initialTokens);
    const tokensInUrl = url?.match(/\{.*?\}/g) || [];

    tokens.forEach(token => {
        token.isSelected = tokensInUrl?.some(urlToken => urlToken === token.value);
    });

    // const updateTokens = (items = tokens) => setTokens([...items]);

    // const updateUrl = items => {
    //     const tokensInUrls = url?.match(/\{.*?\}/g) || [];
    //     items.forEach(token => {
    //         token.isSelected = tokensInUrls?.some(urlToken => urlToken === token.value);
    //     });
    //     updateTokens(items);
    // };

    // const changeTokens = newTokens => updateUrl([...getTokens(newTokens)]);

    const handleTokenClick = useCallback(
        token => {
            const parameter = token.parameter ? `${token.parameter}` : URL_PARAMETER.REPLACE;

            if (token.isSelected) {
                setUrl(
                    url
                        ?.replace(`${parameter}=${token.value}`, '')
                        .replace(token.value, '')
                        .replace(/\?&/g, '?')
                        .replace(/&{2,}/g, '&')
                        .replace(/&$|\?$/, '')
                );
                token.isSelected = false;
            } else {
                const posStart = urlInputRef.current?.selectionStart || url?.length;
                const posEnd = urlInputRef.current?.selectionEnd || url?.length;
                const prevChar = url?.[posStart - 1];
                const nextChar = url?.[posStart];

                const isInsertInMiddle = ['}', '?', '&'].some(char => char === prevChar);
                const isInsertTokenInMiddle = url?.length > posStart && prevChar === '=' && nextChar === '&';
                const isSelectText = posStart !== posEnd;

                let leftUrlSide, rightUrlSide;

                if (isInsertInMiddle || isInsertTokenInMiddle || isSelectText) {
                    leftUrlSide = url?.substring(0, posStart);
                    rightUrlSide = url?.substring(posEnd);
                } else {
                    leftUrlSide = url;
                    rightUrlSide = '';
                }

                let value;

                // каретка в конце строки
                if (posStart === url?.length) {
                    if (prevChar === '=') {
                        value = token.value;
                    } else if (prevChar === '?' || prevChar === '&') {
                        value = `${parameter}=${token.value}`;
                    } else {
                        const delimiter = url?.includes('?') ? '&' : '?';
                        value = `${delimiter}${parameter}=${token.value}`;
                    }
                    // каретка в центре строки
                } else if (posStart < url?.length && (isInsertInMiddle || isInsertTokenInMiddle)) {
                    // каретка в центре строки после "=" и перед "&"
                    if (isInsertTokenInMiddle) {
                        value = token.value;
                    } else {
                        const additionalPrevChar = prevChar === '}' || leftUrlSide === url ? '&' : '';
                        const additionalNextChar = url[posEnd] !== '&' && leftUrlSide !== url ? '&' : '';

                        value =
                            prevChar === '='
                                ? token.value
                                : additionalPrevChar + parameter + '=' + token.value + additionalNextChar;
                    }
                    // выделен текст
                } else if (isSelectText) {
                    value = token.value;
                } else {
                    const delimiter = url?.includes('?') ? '&' : '?';
                    value = `${delimiter}${parameter}=${token.value}`;
                }

                setUrl(leftUrlSide + value + rightUrlSide);
                token.isSelected = true;
            }
            // updateTokens();
        },
        [url, urlInputRef]
    );

    const createDisplayUrl = urlToCreate => {
        let newDisplayUrl = urlToCreate
            ?.replace(/=&/g, '=<span class="tokens-url--warning"><span>uu</span></span>&')
            .replace(/[=]$/, '=<span class="tokens-url--warning"><span>uu</span></span>')
            .replace(/([&?])=/g, '$1<span class="tokens-url--warning"><span>uu</span></span>=')
            .replace(/([&?])([^<]+?)([=$])/g, '$1<span class="color--primary">$2</span>$3')
            .replace(/REPLACE/g, '<span class="tokens-url--rename">REPLACE</span>')
            .replace(/\{.+?\}/g, match => `<span class="bg--sandy-beach p4">${match}</span>`);

        if (!/^https?:\/\//.test(urlToCreate)) {
            newDisplayUrl = '<span class="bg--wisp-pinky color--froly p4">http:// or https://</span>' + newDisplayUrl;
        }

        return `<span class="tokens-url"><span class="color--grey-gull">Preview: </span>${newDisplayUrl}</span>`;
    };

    const getCorrectUrl = urlToCorrect => {
        return urlToCorrect
            ?.replace(/&REPLACE=$|REPLACE=&|warning|&REPLACE=warning|\?REPLACE=$/, '')
            .replace(/{{2,}/g, '{')
            .replace(/}{2,}/g, '}')
            .replace(/[<>]/g, '')
            .trim();
    };

    const [displayUrl, setDisplayUrl] = useState(createDisplayUrl(inputUrl));

    useEffect(() => {
        let err = false;

        const newUrl = getCorrectUrl(inputUrl);

        const newDisplayUrl = createDisplayUrl(newUrl);

        if (!/^https?:\/\//.test(newUrl)) err = true;

        if (newUrl?.includes(URL_PARAMETER.REPLACE)) err = true;

        setUrl(newUrl);
        setError(err);
        setDisplayUrl(newDisplayUrl);
        // updateUrl(tokens);
    }, [inputUrl]);

    const handleUrlChange = ({ target: { value } }) => {
        setUrl(getOnlyASCIICodeText(value));
    };

    return {
        url,
        tokens,
        displayUrl,
        handleTokenClick,
        handleUrlChange,
        error
    };
};

export default useToken;
