import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getColumns } from '../../../components/main-table/services';
import { compare, getParams, omitKeys, getResponseErrorMessage, clone } from '../../../Functions/utils';

import { useGetUsersAllNamesQuery } from '../../../redux/api/usersApi';
import { useChangeStorageMutation } from '../../../redux/api/storeApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import {
    useGetSharedReportsQuery,
    useStopAccessSharedReportMutation,
    useActivateAccessSharedReportMutation,
    useDeleteSharedReportMutation
} from '../../../redux/api/sharedReportsApi';

import TableControls from './components/table-controls/table-controls';
import {
    changeMainTableActiveRow,
    changeMainTablePagination,
    changeMainTableSorting,
    selectSharedReportsTableData
} from '../../../redux/main-table-slice';
import MainTableItems from '../../MainTable/MainTableItems/MainTableItems';
import Loader from '../../Loader';

const SharedReports = ({ chapter, storage }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: users, isSuccess: isUsersLoaded } = useGetUsersAllNamesQuery();

    const { activeRow, search, filters, sorting, pagination } = useSelector(selectSharedReportsTableData);

    const parameters = {
        'filter[name]': search,
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page']
    };

    Object.entries(filters).forEach(([key, value]) => {
        if (value !== 'all') parameters[`filter[${key}]`] = value;
    });

    const {
        data: sharedReports,
        isSuccess: isSharedReportsLoaded,
        isFetching: isSharedReportsFetching
    } = useGetSharedReportsQuery(getParams(parameters));

    useEffect(() => {
        if (activeRow) {
            const row = sharedReports?.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (sharedReports?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: sharedReports.pagination
                })
            );
        }
    }, [sharedReports]);

    const [
        stopAccess,
        {
            isLoading: isStopAccessLoading,
            isSuccess: isStopAccessSuccess,
            isError: isStopAccessError,
            error: stopAccessError
        }
    ] = useStopAccessSharedReportMutation();

    useEffect(() => {
        if (isStopAccessSuccess) toast.success(txt.toasts.saved);
    }, [isStopAccessSuccess]);

    useEffect(() => {
        if (isStopAccessError) toast.error(getResponseErrorMessage(stopAccessError.data, txt.toasts.saveError));
    }, [isStopAccessError]);

    const [
        activateAccess,
        {
            isLoading: isActivateAccessLoading,
            isSuccess: isActivateAccessSuccess,
            isError: isActivateAccessError,
            error: activateAccessError
        }
    ] = useActivateAccessSharedReportMutation();

    useEffect(() => {
        if (isActivateAccessSuccess) toast.success(txt.toasts.saved);
    }, [isActivateAccessSuccess]);

    useEffect(() => {
        if (isActivateAccessError) toast.error(getResponseErrorMessage(activateAccessError.data, txt.toasts.saveError));
    }, [isActivateAccessError]);

    const [
        deleteSharedReport,
        {
            isLoading: isDeleteSharedReportLoading,
            isSuccess: isDeleteSharedReportSuccess,
            isError: isDeleteSharedReportError,
            error: deleteSharedReportError
        }
    ] = useDeleteSharedReportMutation();

    useEffect(() => {
        if (isDeleteSharedReportSuccess) {
            toast.success(txt.toasts.deleted);

            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: null
                })
            );
        }
    }, [isDeleteSharedReportSuccess]);

    useEffect(() => {
        if (isDeleteSharedReportError)
            toast.error(getResponseErrorMessage(deleteSharedReportError.data, txt.toasts.saveError));
    }, [isDeleteSharedReportError]);

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter,
                value:
                    row.value === activeRow?.value && compare(activeRow?.parents, row.parents)
                        ? null
                        : omitKeys(row, ['level'])
                // TODO: remove omitKeys after refactor
            })
        );
    };

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.sharedReports.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = sharedReports?.items || [];

    // const usersList = useMemo(() => {
    //     const uniqueUsers = new Set(sharedReports?.items?.map(el => el.userId));

    //     return Array.from(uniqueUsers).map(userId => users?.find(el => el.value === userId));
    // }, [sharedReports, users]);

    const isLoaded =
        isUsersLoaded &&
        isSharedReportsLoaded &&
        isUserSettingsLoaded &&
        !isSharedReportsFetching &&
        !isStopAccessLoading &&
        !isActivateAccessLoading &&
        !isDeleteSharedReportLoading;

    return (
        <>
            <div className={cn('table-container simple-main-table')}>
                <div className={cn('main-table')}>
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            activeRow={activeRow}
                            search={search}
                            filters={filters}
                            pagination={pagination}
                            usersList={users ?? []}
                            storage={storage}
                            userSettings={userSettings}
                            stopAccess={stopAccess}
                            activateAccess={activateAccess}
                            deleteSharedReport={deleteSharedReport}
                        />

                        <div className="main-table__visible-content">
                            {columns?.length > 0 ? (
                                <MainTableItems
                                    chapter={chapter}
                                    modifiedChapter={chapter}
                                    columns={columns}
                                    rows={rows}
                                    sorting={sorting}
                                    storage={storage}
                                    isLoading={!isLoaded}
                                    activeRow={activeRow}
                                    onRowClick={onRowClick}
                                    onRowContextMenu={() => null}
                                    handleChange={onTableActionsChange}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SharedReports;
