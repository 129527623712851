import React, { useState, useRef, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import Langs from '../../../Langs';
import { setState } from '../../../redux/operations';

import importSettingsConstructor from './importSettingsTableConstructor';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import ImportSettingsTableItem from './ImportSettingsTableItem';

const ImportSettingsModal = ({ tracker, onClose }) => {
    // onConfirm: onConfirmAll,
    const txt = Langs[global.lng];

    const entities = [
        {
            name: 'campaigns',
            items: {
                selected: [],
                unselected: []
            }
        },
        {
            name: 'flows',
            items: {
                selected: [],
                unselected: []
            }
        },
        {
            name: 'trafficSources',
            items: {
                selected: [],
                unselected: []
            }
        },
        {
            name: 'offers',
            items: {
                selected: [],
                unselected: []
            }
        },
        {
            name: 'affiliateNetworks',
            items: {
                selected: [],
                unselected: []
            }
        },
        {
            name: 'landers',
            items: {
                selected: [],
                unselected: []
            }
        }
    ];

    const [isLocalLoading, setIsLocalLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [isAllItemsChecked, setIsAllItemsChecked] = useState({ checked: false, isStark: false });
    const previousTabIndex = useRef(-1);

    const entity = entities[tabIndex];
    const entityItems = entity.items;

    const [rows, setRows] = useState([]);
    const checkedRowsQuantity = rows.filter(item => item.isChecked).length;

    const checkedRowsQuantityRef = useRef(0);
    if (tabIndex !== previousTabIndex.current)
        checkedRowsQuantityRef.current =
            entityItems.selected.filter(item => item.isChecked).length +
            entityItems.unselected.filter(item => item.isChecked).length;

    const getImportCountComponent = useMemo(
        () => () =>
            (
                <span className="import-settings-modal__import-count">
                    {txt.texts.importProcess} (
                    {entities.reduce(
                        (ac, item, index) =>
                            index !== entities.length - 1
                                ? ac +
                                  item.items.selected.length +
                                  item.items.unselected.filter(el => el.isChecked).length
                                : ac,
                        0
                    ) + rows.filter(item => item.isChecked).length}{' '}
                    {txt.texts.importProcess2})
                </span>
            ),
        [entities, rows]
    );

    const setEntities = newEntities =>
        setState(
            entities.map(item => newEntities.find(el => el.name === item.name) || item),
            `tools/import/entities`
        );

    const getItemsFromRows = () => ({
        selected: rows.filter(row => row.important),
        unselected: rows.filter(row => !row.important)
    });

    // const getEntityName = () => {
    //     return camelToKebabCase(entity.name.slice(0, entity.name.length - 1));
    // };

    const getTabItemsCount = index => {
        const count =
            [
                ...entities[index]?.items?.selected.filter(row => row?.isChecked),
                ...entities[index]?.items?.unselected.filter(row => row?.isChecked)
            ].length || 0;

        if (tabIndex !== previousTabIndex.current) return index === tabIndex ? checkedRowsQuantityRef.current : count;
        else return index === tabIndex ? checkedRowsQuantityRef.current : count;

        // if (index === tabIndex) return checkedRowsQuantityRef.current || 0;
        // return tabIndex !== previousTabIndex.current && index + 1 !== tabIndex
        //     ? rows.filter(item => item.isChecked).length
        //     : count;
    };

    const getRows = () => [...entityItems.selected, ...entityItems.unselected].sort((a, b) => a.name > b.name);

    const toggleSelectAll = ev => {
        setRows(
            [
                ...rows.filter(item => item.important),
                ...rows.filter(item => !item.important).map(item => ({ ...item, isChecked: ev.target.checked }))
            ].sort((a, b) => a.name > b.name)
        );
    };

    const handleRowClick = (ev, item) => {
        ev.preventDefault();
        setRows(rows.map(el => (el.id === item.id ? { ...el, isChecked: !item.isChecked } : el)));
    };

    const sendEntity = data => {
        return data;
        // doMonoRequest({
        //     path: `import/${tracker}/${getEntityName()}`,
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: data.filter(item => item.isChecked).map(item => ({ id: item.id, name: item.name }))
        // }).then(response => response.ok);
    };

    const processError = () => {
        toast.error(txt.toasts.saveError);
        setIsLocalLoading(false);
        return false;
    };

    const onConfirm = useMemo(
        () => () => {
            setIsLocalLoading(true);
            let request = sendEntity(rows);

            if (tabIndex === entities.length - 1) {
                request = request
                    .then(isOK => {
                        if (isOK) return '';
                        //fetchData(`import/${tracker}/final`);
                        else processError();
                    })
                    .then(response => {
                        if (response) return ''; //fetchData(`import/${tracker}/clear`).finally(() => onConfirmAll());
                    });
            } else request = request.then(isOK => (isOK ? setTabIndex(tabIndex + 1) : processError()));
            return request;
        },
        [tracker, entities, tabIndex, rows]
    );

    const onReject = useMemo(
        () => (tabIndex === 0 ? onClose : () => setTabIndex(tabIndex - 1)),
        [tracker, entities, tabIndex, rows]
    );

    useEffect(() => {
        return () => {
            setState(
                entities.map(item => ({
                    name: item.name,
                    items: {
                        selected: [],
                        unselected: []
                    }
                })),
                `tools/import/entities`
            );
        };
    }, []);

    useEffect(() => {
        const updatedEntities = [];

        if (tabIndex > previousTabIndex.current) {
            setIsLocalLoading(true);
            // await doMonoRequest(
            //     {
            //         path: `import/${tracker}/${getEntityName()}`,
            //         method: 'GET',
            //         callback: response => ({
            //             selected:
            //                 response?.selected?.map(item => ({
            //                     ...item,
            //                     isChecked: true,
            //                     important: true
            //                 })) || [],
            //             unselected:
            //                 response?.unselected?.map(item => ({
            //                     ...item,
            //                     isChecked: false
            //                 })) || []
            //         })
            //     },
            //     true
            // ).then(response => {
            //     const oldUnselected = entityItems?.unselected;
            //     const newUnselected =
            //         !oldUnselected || !oldUnselected.length
            //             ? response.unselected
            //             : response.unselected.map(item => ({
            //                   ...item,
            //                   isChecked: oldUnselected.find(el => el.id === item.id)?.isChecked
            //               }));

            //     updatedEntities.push({
            //         name: entity.name,
            //         items: { selected: response.selected, unselected: newUnselected }
            //     });
            // });
        }

        if (previousTabIndex.current >= 0)
            updatedEntities.push({
                name: entities[previousTabIndex.current].name,
                items: getItemsFromRows()
            });

        setEntities(updatedEntities);
        previousTabIndex.current = tabIndex;
    }, [tabIndex]);

    useEffect(() => {
        setRows(getRows());
        setIsLocalLoading(false);
    }, [entities]);

    useEffect(() => {
        if (rows.length && checkedRowsQuantity === rows.length) setIsAllItemsChecked({ checked: true, isStark: false });
        else if (checkedRowsQuantity === 0) setIsAllItemsChecked({ checked: false, isStark: false });
        else setIsAllItemsChecked({ checked: false, isStark: true });
        checkedRowsQuantityRef.current = checkedRowsQuantity;
    }, [rows]);

    return (
        <>
            {isLocalLoading || !rows || !rows?.length ? <Loader /> : null}

            <div className="settings-wrapper--wide">
                <div className="import-settings-modal__subheader">
                    <div className="import-settings-modal__tabs">
                        {entities.map((item, index) => (
                            <div
                                className={cn('import-settings-modal__tabs-item', {
                                    'import-settings-modal__tabs-item_active': index === tabIndex
                                })}
                                key={item?.name}
                            >
                                <span>{item?.name}</span>
                                <span>{getTabItemsCount(index)}</span>
                            </div>
                        ))}
                    </div>

                    <div className={importSettingsConstructor.columnSeparation}>
                        <ImportSettingsTableItem
                            type="header"
                            constructor={importSettingsConstructor}
                            active={isAllItemsChecked.checked}
                            isStark={isAllItemsChecked.isStark}
                            onChange={toggleSelectAll}
                        />
                    </div>
                </div>

                <div className={cn('import-settings-modal__table', importSettingsConstructor.columnSeparation)}>
                    {rows.map(row => (
                        <ImportSettingsTableItem
                            key={row.id}
                            type="row"
                            item={row}
                            active={row.isChecked}
                            disabled={row.important || false}
                            constructor={importSettingsConstructor}
                            onChange={handleRowClick}
                        />
                    ))}
                </div>
            </div>

            <ModalButtonBar
                className="import-settings-modal__button-bar"
                rejectButtonText={tabIndex === 0 ? txt.buttons.cancel : txt.buttons.back}
                confirmButtonText={tabIndex === entities.length - 1 ? txt.buttons.startImport : txt.buttons.next}
                onConfirm={onConfirm}
                leftSettings={tabIndex === entities.length - 1 ? getImportCountComponent() : null}
                onReject={onReject}
                isLoading={isLocalLoading}
                isConfirmDisabled={tabIndex === 0 && checkedRowsQuantity === 0}
            />
        </>
    );
};

export default ImportSettingsModal;
