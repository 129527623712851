import React, { useMemo } from 'react';
import { RefreshCw, Download } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import useDelayedInput from '../../../../Functions/hooks/useDelayedInput';
import useReturnFocus from '../../../../Functions/hooks/useReturnFocus';

import indexApi from '../../../../redux/api/indexApi';
import { errorLogApiTagTypes } from '../../../../redux/api/errorLogApi';

import {
    changeMainTableFilters,
    changeMainTablePagination,
    changeMainTableSearch
} from '../../../../redux/main-table-slice';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import SelectInput from '../../../../Modules/Overall/Forms/Inputs/SelectInput/SelectInput';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import DateRangeInput from '../../../../Modules/Overall/Forms/Inputs/DateRangeInput';
import DownloadFile, { ShowDownloadFileCheckbox } from '../../../../Modules/Settings/ColumnsSettings/DownloadFile';
import TextInput from '../../../../Modules/Overall/Forms/Inputs/TextInput';

const TableControls = ({
    chapter,
    isLoading,
    pagination,
    errorLogCategories,
    tableParameters,
    filters,
    dateRange,
    setDateRange,
    rows,
    columns
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const contentSwitcher = chapter;

    const onFilterChange = (value, key) => {
        dispatch(
            changeMainTableFilters({
                chapter,
                filterKey: key,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    };

    const [searchText, setSearchText, searchInputRef] = useDelayedInput(value => {
        dispatch(
            changeMainTableSearch({
                chapter,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    }, filters.search);

    useReturnFocus(isLoading, searchInputRef);

    const showDownloadFileModal = () => {
        showModal({
            title: txt.labels.downloadFile,
            className: 'exportFileModal',
            HeaderRightItem: ShowDownloadFileCheckbox,
            body: props => (
                <DownloadFile
                    {...props}
                    chapter={chapter}
                    columns={columns}
                    dataForRender={tableParameters}
                    dateRange={dateRange}
                    rows={rows}
                    isHideOnlySelectedRows
                />
            )
        });
    };

    const errorLogCategoriesList = useMemo(() => {
        const result = [{ value: 'all', label: 'All categories' }];

        if (errorLogCategories) {
            for (const [key, value] of Object.entries(errorLogCategories)) {
                result.push({ value: key, label: value });
            }
        }

        return result;
    }, [errorLogCategories]);

    return (
        <>
            <TableControlContainer>
                <SelectInput
                    className="table-control-header__category-type-dropdown"
                    dataTest="table-control-header_error-log-category-type-dropdown"
                    value={filters.category ?? 'all'}
                    items={errorLogCategoriesList}
                    disabled={isLoading}
                    onChange={({ target: { value } }) => onFilterChange(value, 'category')}
                />

                <TextInput
                    dataTest="table-control-header_error-log-search-input"
                    placeholder={txt.placeholders.search}
                    ref={searchInputRef}
                    value={searchText}
                    onChange={setSearchText}
                    disabled={isLoading}
                    reset
                />

                <Btn
                    dataTest="table-control_export-btn_error-log"
                    className="mr10 ml10 table-control_export"
                    disabled={isLoading || !rows.length}
                    onClick={showDownloadFileModal}
                >
                    <Download className="color--grey-gull" />
                    {txt.buttons.export}
                </Btn>

                <DateRangeInput
                    value={dateRange}
                    onChange={data => setDateRange(data)}
                    disabled={isLoading}
                    hideArrows
                    isCompareHidden
                />

                <Btn
                    dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                    className="ml10 table-control_export"
                    type="success"
                    disabled={isLoading}
                    onClick={() =>
                        dispatch(indexApi.util.invalidateTags([{ type: errorLogApiTagTypes.errorLog, id: 'LIST' }]))
                    }
                >
                    <RefreshCw />
                    {txt.buttons.refresh}
                </Btn>

                <div className="table-control-container__pagination">
                    <TablePagination
                        value={pagination}
                        onChange={data => {
                            dispatch(
                                changeMainTablePagination({
                                    chapter,
                                    value: data
                                })
                            );
                        }}
                        disabled={isLoading}
                    />
                </div>
            </TableControlContainer>
        </>
    );
};

export default TableControls;
