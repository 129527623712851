import { CHAPTER_REPORTS, ADD_VALUES_CHAPTERS, HIDDEN_PROFIT_FILTER_METRICS } from '../../Constants/index';
import {
    mainTableConstructor,
    ADDITIONAL_REVENUE_KEY
} from '../../Modules/MainTable/constructors/mainTableConstructor';

export const getColumns = (
    chapter,
    storage,
    options = {
        isHideProfit: false,
        includeIntegratedCampaignList: false,
        includeAdditionalRevenue: false,
        includeCompareColumns: false
    }
) => {
    // get main colums of entity
    let results = CHAPTER_REPORTS.includes(chapter)
        ? mainTableConstructor.chapter_reports
        : mainTableConstructor[chapter];

    // adding statistics columns
    if (ADD_VALUES_CHAPTERS.includes(chapter) || CHAPTER_REPORTS.includes(chapter)) {
        const columnsKeysOfConstructor = results.map(item => item.key);

        results = results.map(item => {
            const extensionElement = mainTableConstructor.values.find(el => el.key === item.key);
            return { ...item, ...(extensionElement || {}) };
        });

        results = [...results, ...mainTableConstructor.values.filter(el => !columnsKeysOfConstructor.includes(el.key))];

        // add custom conversions columns
        if (storage.common.customConversions && storage.common.customConversions.length) {
            results = [
                ...results,
                ...storage.common.customConversions.map(item => ({
                    ...item,
                    ...mainTableConstructor.expansionCustomConversions
                }))
            ];
        }

        // add integrations columns
        if (chapter === 'campaigns' && options.includeIntegratedCampaignList) {
            results = [...results, ...mainTableConstructor.integration];
        }
    }

    // remove the column of the entity for which the conversion list is compiled
    // if (chapter !== modifiedChapter)
    //     results = results.filter(item => item.key + 's' !== dataForRender?.filters?.[0]?.type);

    // add roundings
    const roundingKeys = Object.keys(storage?.common?.rounding ?? {});

    if (options.isHideProfit) {
        results = results.filter(item => !HIDDEN_PROFIT_FILTER_METRICS.includes(item.key));
    }

    if (!options.includeAdditionalRevenue || chapter !== 'campaigns') {
        results = results.filter(item => item.key !== ADDITIONAL_REVENUE_KEY);
    }

    const initialColumns = results?.map(item =>
        roundingKeys.includes(item.key) ? { ...item, rounding: storage.common.rounding[item.key] } : item
    );

    const key = CHAPTER_REPORTS.includes(chapter) ? 'chapter_reports' : chapter;

    const userStorageColumns = storage?.customColumns ?? storage[key]?.columns ?? [];

    let notIncludedColumnsInitialOrder = userStorageColumns.length;

    const orderedColumns = initialColumns
        .map(el => {
            const resultItem = { ...el };
            const column = userStorageColumns.find(item => item.key === el.key);

            if (column) {
                resultItem.order = column.order;
                resultItem.isHide = column.isHide;
            } else {
                notIncludedColumnsInitialOrder += 1;

                resultItem.order = notIncludedColumnsInitialOrder;
                resultItem.isHide = false;
            }

            return resultItem;
        })
        .sort((a, b) => a.order - b.order);

    const addCompareColumns = input => {
        if (CHAPTER_REPORTS.includes(chapter)) return input;

        const columns = [...input].sort((a, b) => a.order - b.order);

        return columns
            .reduce((ac, item) => {
                if (item.supplement) {
                    const additionalItem = mainTableConstructor.comparisonColumns.find(
                        el => el.key === item.supplement
                    );
                    additionalItem.rounding = item.rounding;
                    return [...ac, item, additionalItem];
                }
                return [...ac, item];
            }, [])
            .map((el, i) => ({ ...el, order: i }));
    };

    return {
        initialColumns: options.includeCompareColumns ? addCompareColumns(initialColumns) : initialColumns,
        orderedColumns: options.includeCompareColumns ? addCompareColumns(orderedColumns) : orderedColumns
    };
};
