import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { capitalize, omitKeys, tryRegExp, getResponseErrorMessage } from '../../../Functions/utils';
import useFocus from '../../../Functions/hooks/useFocus';

import { useAddNotificationMutation, useEditNotificationMutation } from '../../../redux/api/notificationsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import TagInput from '../../Overall/Forms/Inputs/TagInput';
import PostParams from './PostParams';
import Loader from '../../Loader';

import './add-notification-modal.scss';

const defaultTemplateDataValues = {
    emails: [],
    apiToken: '',
    apiId: '',
    chatIds: [],
    fromTel: '',
    toTel: [],
    url: '',
    postParams: [{ name: 'Status', value: 'Notification' }],
    validations: {
        emails: "^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$",
        apiToken: `^\\S*$`,
        apiId: `^\\S*$`,
        chatIds: `^\\S*$`,
        fromTel: `^\\S*$`,
        toTel: `^\\S*$`,
        url: `^\\S*$`,
        postParams: null
    }
};

const notificationTemplate = {
    isEnabled: true,
    name: '',
    template: 'email',
    isSystemMessage: true,
    ...defaultTemplateDataValues
};

const AddNotificationModal = ({ item, onClose }) => {
    const txt = Langs[global.lng];
    const templatesList = [
        { value: 'email', label: 'Email' },
        { value: 'telegram', label: 'Telegram bot' },
        { value: 'sms', label: 'SMS (by Twillio)' }
        // { value: 'webhook', label: 'Webhook' }
    ];

    const modalFields = {
        email: [
            {
                type: 'alert',
                message: txt.alerts.newNotificationEmail
            },
            {
                type: 'textInput',
                key: 'name',
                label: txt.labels.name,
                placeholder: ''
            },
            {
                type: 'checkbox',
                key: 'isSystemMessage',
                label: txt.labels.systemMessage
            },
            {
                type: 'tagInput',
                key: 'emails',
                label: txt.labels.email,
                placeholder: ''
            }
        ],
        telegram: [
            {
                type: 'alert',
                message: txt.alerts.newNotificationTelegram
            },
            {
                type: 'textInput',
                key: 'name',
                label: txt.labels.name,
                placeholder: ''
            },
            {
                type: 'checkbox',
                key: 'isSystemMessage',
                label: txt.labels.systemMessage
            },
            {
                type: 'textInput',
                key: 'apiToken',
                label: txt.labels.httpApiToken,
                placeholder: ''
            },
            {
                type: 'tagInput',
                key: 'chatIds',
                label: txt.labels.chatID,
                placeholder: ''
            }
        ],
        sms: [
            {
                type: 'alert',
                message: txt.alerts.newNotificationSMS
            },
            {
                type: 'textInput',
                key: 'name',
                label: txt.labels.name,
                placeholder: ''
            },
            {
                type: 'checkbox',
                key: 'isSystemMessage',
                label: txt.labels.systemMessage
            },
            {
                type: 'textInput',
                key: 'apiId',
                label: txt.labels.apiId,
                placeholder: ''
            },
            {
                type: 'textInput',
                key: 'apiToken',
                label: txt.labels.apiToken,
                placeholder: ''
            },
            {
                type: 'textInput',
                key: 'fromTel',
                label: txt.labels.fromTwilio,
                placeholder: ''
            },
            {
                type: 'tagInput',
                key: 'toTel',
                label: txt.labels.toYour,
                placeholder: ''
            }
        ],
        webhook: [
            {
                type: 'alert',
                message: txt.alerts.newNotificationWebhook
            },
            {
                type: 'textInput',
                key: 'name',
                label: txt.labels.name,
                placeholder: ''
            },
            {
                type: 'checkbox',
                key: 'isSystemMessage',
                label: txt.labels.systemMessage
            },
            {
                type: 'textInput',
                key: 'url',
                label: txt.labels.webhookUrl,
                placeholder: ''
            },
            {
                type: 'postParams',
                key: 'postParams',
                title: txt.labels.postParams
            }
        ]
        // Push: [
        //     {
        //         type: 'alert',
        //         message: txt.alerts.newNotificationWebhook
        //     },
        //     {
        //         type: 'textInput',
        //         key: 'name',
        //         label: txt.labels.name
        //     },
        //     {
        //         type: 'checkbox',
        //         key: 'isSystemMessage',
        //         label: txt.labels.systemMessage
        //     },
        //     {
        //         type: 'textInput',
        //         key: 'url',
        //         label: txt.labels.webhookUrl
        //     },
        //     {
        //         type: 'postParams',
        //         key: 'postParams',
        //         title: txt.labels.postParams
        //     }
        // ]
    };

    const [nameInputRef, setNameFocus] = useFocus();

    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const [notification, setNotification] = useState({
        ...notificationTemplate,
        ...(item
            ? omitKeys(
                  {
                      ...item,
                      ...item.templateData
                  },
                  ['templateData']
              )
            : {})
    });

    const updateNotification = updatedFields => {
        setNotification(prevNotification => ({ ...prevNotification, ...updatedFields }));
    };

    useEffect(() => {
        if (!notification.name) setTimeout(setNameFocus, 0);
    }, [notification.template]);

    const getArrayValidation = (data, key) =>
        !!data[key]?.length &&
        (notification?.validations?.[key] && tryRegExp(notification.validations[key])
            ? data[key].reduce((ac, el) => ac && tryRegExp(notification.validations[key]).test(el.trim()), true)
            : true);

    const getIsValid = key => {
        const validator = Array.isArray(notification[key])
            ? () => getArrayValidation(notification, key)
            : () => tryRegExp(notification.validations[key]).test(notification[key]);

        return (
            notification?.[key]?.length &&
            (notification?.validations?.[key] && tryRegExp(notification?.validations?.[key]) ? validator(key) : true)
        );
    };

    const isValid = useMemo(() => {
        const validationFields = modalFields[notification.template]
            .filter(el => el.key && el.key !== 'isSystemMessage')
            .map(el => el.key);

        return validationFields.reduce((ac, key) => ac && getIsValid(key), true);
    }, [notification]);

    const getErrorText = key => {
        switch (key) {
            case 'emails':
                return txt.errors.email;
            default:
                return txt.errors.required(capitalize(key));
        }
    };

    const onTemplateChange = ({ target: { value } }) => {
        if (isSubmit) setIsSubmit(false);
        updateNotification({ template: value });
    };

    const onTagChange = value => {
        const tagInputKey = modalFields[notification.template].find(el => el.type === 'tagInput').key;
        updateNotification({ [tagInputKey]: value });
    };

    const getTemplateDataProps = () => {
        const templateDataKeys = modalFields[notification.template]
            .filter(el => el.key && el.key !== 'name' && el.key !== 'isSystemMessage')
            .map(el => el.key);

        return templateDataKeys.reduce(
            (ac, el) => ({
                ...ac,
                [el]: notification[el]
            }),
            {}
        );
    };

    const [
        addNotification,
        { isSuccess: isAddNotificationSuccess, isError: isAddNotificationError, error: addNotificationError }
    ] = useAddNotificationMutation();

    const [
        editNotification,
        { isSuccess: isEditNotificationSuccess, isError: isEditNotificationError, error: editNotificationError }
    ] = useEditNotificationMutation();

    useEffect(() => {
        if (isAddNotificationError)
            toast.error(getResponseErrorMessage(addNotificationError.data, txt.toasts.saveError));

        if (isEditNotificationError)
            toast.error(getResponseErrorMessage(editNotificationError.data, txt.toasts.saveError));

        if (isAddNotificationError || isEditNotificationError) setIsProcessing(false);
    }, [isAddNotificationError, isEditNotificationError]);

    useEffect(() => {
        if (isAddNotificationSuccess || isEditNotificationSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isAddNotificationSuccess, isEditNotificationSuccess]);

    const onModalConfirm = () => {
        setIsSubmit(true);

        if (isValid) {
            setIsProcessing(true);

            const request = item ? editNotification : addNotification;

            request({
                ...(item ? { id: notification.value } : {}),
                data: {
                    ...notification,
                    [`${notification.template}TemplateData`]: getTemplateDataProps()
                }
            });
        }
    };

    const renderFields = () => {
        return modalFields[notification.template]?.map((obj, index) => {
            const { type, key, label, placeholder, className, title, message } = obj;

            const data = {
                className,
                label,
                placeholder,
                title,
                message,
                value: notification?.[key],
                ...(notification?.[key] && notification?.validations?.[key]
                    ? { validation: notification?.validations?.[key] }
                    : {})
            };

            switch (type) {
                case 'alert':
                    return (
                        <div key={index} className="mb15">
                            <JustAlert type={JUST_ALERT_TYPE.INFO}>{message}</JustAlert>
                        </div>
                    );
                case 'textInput':
                    return (
                        <div key={index} className="mb15">
                            <TextInput
                                {...data}
                                dataTest={`add-notification-modal_${key}-input`}
                                ref={key === 'name' ? nameInputRef : null}
                                error={isSubmit && !getIsValid(key) ? getErrorText(key) : ''}
                                onChange={({ target: { value } }) => updateNotification({ [key]: value })}
                            />
                        </div>
                    );
                case 'checkbox':
                    return (
                        <div key={index} className="pt5 mb15">
                            <CheckBoxInput
                                {...data}
                                dataTest={`add-notification-modal_${key}-checkbox`}
                                onChange={({ target: { checked } }) => updateNotification({ [key]: checked })}
                            />
                        </div>
                    );
                case 'tagInput':
                    return (
                        <div key={index} className="mb15">
                            <TagInput
                                {...data}
                                dataTest="notification-param"
                                isListView
                                error={(!!data.value?.length || isSubmit) && !getIsValid(key) ? getErrorText(key) : ''}
                                onChange={onTagChange}
                            />
                        </div>
                    );
                case 'postParams':
                    return (
                        <div key={index} className="mb15">
                            <PostParams
                                {...data}
                                items={data?.value}
                                validateFields={value => isSubmit && !value}
                                atLeastOneValue
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <>
            <div className="add-notification-modal">
                {!notification?.value ? (
                    <RadioInput
                        dataTest="add-notification-modal_custom-domain-destination"
                        type="row"
                        className="mb15"
                        label={txt.labels.template}
                        hint={txt.hints.customDomainDestination}
                        items={templatesList}
                        value={notification.template}
                        onChange={onTemplateChange}
                    />
                ) : null}

                {renderFields()}

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={item ? txt.buttons.save : txt.buttons.create}
                onConfirm={onModalConfirm}
                onReject={onClose}
                isConfirmDisabled={(isSubmit && !isValid) || isProcessing}
            />
        </>
    );
};

export default AddNotificationModal;
