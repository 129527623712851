import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';
import { setState } from '../../../../redux/operations';
import { getResponseErrorMessage } from '../../../../Functions/utils';

import { useResetCampaignUrlAlertMutation } from '../../../../redux/api/campaignsApi';

import Icon from '../../../Overall/Icon';
import Alert from '../../../Overall/UI/Alert/Alert';
import ModalButtonBar from '../../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TooltipContainer from '../../../Overall/UI/TooltipContainer/TooltipContainer';

export const WarningBlock = ({ row, className }) => {
    const txt = Langs[global.lng];
    const [isCopied, setIsCopied] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let timerId;

        if (isCopied) {
            timerId = setTimeout(setIsCopied, 500, false);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [isCopied]);

    const onCopyClick = () => {
        const link = row?.links[0]?.link;

        navigator.clipboard.writeText(link).then(() => {
            setIsCopied(true);
        });
    };

    const [resetAlert, { isSuccess: isResetAlertSuccess, isError: isResetAlertError, error: resetAlertError }] =
        useResetCampaignUrlAlertMutation();

    useEffect(() => {
        if (isResetAlertSuccess) {
            setIsProcessing(false);
            setIsOpen(false);
            setState(null, 'absTooltip');
        }
    }, [isResetAlertSuccess]);

    useEffect(() => {
        if (isResetAlertError) {
            toast.error(getResponseErrorMessage(resetAlertError.data, txt.toasts.commonError));
            setIsProcessing(false);
        }
    }, [isResetAlertError]);

    const onClose = e => {
        const POINTER_WRAPPER_WIDTH = 320;
        const POINTER_WRAPPER_HEIGHT = 150;
        const SVG_OFFSET = 1;

        const { width, height, top, left } = e.currentTarget.getBoundingClientRect();
        const isElementWillFit = window.innerHeight - (top + height) < POINTER_WRAPPER_HEIGHT;

        setIsOpen(true);

        const close = () => {
            setIsOpen(false);
            setState(null, 'absTooltip');
        };

        const absTooltipData = {
            body: () => (
                <TooltipContainer style={{ width: POINTER_WRAPPER_WIDTH }} isOpenTop={isElementWillFit}>
                    <Alert type="warning" message={txt.alerts.urlChangeOutside} />
                    <ModalButtonBar
                        rejectButtonText={txt.buttons.cancel}
                        confirmButtonText={txt.buttons.deleteFlag}
                        onConfirm={() => {
                            setIsProcessing(true);

                            resetAlert({
                                rowId: row?.value,
                                campaignListRequestParams: row.integration.campaignListRequestParams
                            });
                        }}
                        onReject={close}
                    />
                </TooltipContainer>
            ),
            clickOutsideCallback: close,
            top: isElementWillFit ? top - POINTER_WRAPPER_HEIGHT : top + height,
            left: left + width / 2 - SVG_OFFSET - POINTER_WRAPPER_WIDTH / 2
        };

        setState(absTooltipData, 'absTooltip');
    };

    return row?.isTrackingUrlChanged ? (
        <div className={cn('campaign-row__warning', className)}>
            <span className={cn('campaign-row__warning-icon', { 'not-interactive': isProcessing })}>
                <Icon.AlertTriangle size={16} className="alert__icon--triangle" />
            </span>

            <div className={cn('campaign-row__warning-content', { 'campaign-row__warning-content--open': isOpen })}>
                <span className={cn('campaign-row__warning-text', { 'not-interactive': isProcessing })}>
                    {isCopied ? `${txt.buttons.copied}` : `${txt.labels.newURL}`}
                </span>

                <span
                    data-test="campaign-row__warning-icon--copy"
                    className={cn('campaign-row__warning-icon', { 'not-interactive': isProcessing })}
                    onClick={onCopyClick}
                >
                    <Icon.Copy size={16} />
                </span>

                <span
                    className={cn('campaign-row__warning-icon', {
                        'not-interactive': isProcessing,
                        'campaign-row__warning-icon--active': isOpen
                    })}
                    onClick={onClose}
                >
                    <Icon.X size={16} />
                </span>
            </div>
        </div>
    ) : null;
};

const Store = ({ oldState: { absTooltip } }) => ({ absTooltip });

export default connect(Store)(WarningBlock);
