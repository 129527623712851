import React, { useMemo, useCallback, useEffect } from 'react';

import Langs from '../../../../Langs';
import { URL_PARAMETER } from '../../../../Constants';
import { getUrlError, showModal, hideModal } from '../../../../redux/operations';
import { getOnlyASCIICodeText, checkUrlCharacters, showByValue } from '../../../../Functions/utils';

import useDelayedFocus from '../../../../Functions/hooks/useDelayedFocus';
import useToken from '../../../../Functions/hooks/useToken';

import { AccordionTokens } from '../../../Overall/Accordion/Accordion';
import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Icon from '../../../Overall/Icon';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import { TdBadgesSquareInline } from '../../../Overall/ItemsTable/TdTypes';
import TagInput from '../../../../components/inputs/tag-input/tag-input';
import ChangeWorkspaceWarningModal from '../../../Modals/change-workspace-warning-modal/change-workspace-warning-modal';

const OfferSettings = React.memo(
    ({
        workspaceId,
        publicWorkspaceId,
        offerTags,
        offerName,
        offerUrl,
        countryTag,
        affiliateNetworkId,
        redirectOffer,

        selectedAffNetwork,
        isCreateMode,
        updateOffer,
        isNeedValidate,

        workspacesList,
        offerTokens,
        countries,
        affiliateNetworks
    }) => {
        const txt = Langs[global.lng];

        const [nameInputRef, setIsNameFocus] = useDelayedFocus();
        const [urlInputRef, setIsUrlFocus] = useDelayedFocus();

        const { url, tokens, displayUrl, handleTokenClick, handleUrlChange } = useToken(
            offerUrl,
            offerTokens,
            urlInputRef
        );

        useEffect(() => {
            if (isCreateMode && nameInputRef.current && !offerName) {
                setTimeout(() => {
                    if (nameInputRef?.current) nameInputRef.current.focus();
                }, 0);
            }
        }, []);

        useEffect(() => updateOffer({ offerUrl: url }), [url]);

        const isAppendClickId = selectedAffNetwork?.isClickIdAppendToOffer;

        const affiliateNetworksForRender = useMemo(
            () => [
                { value: null, label: txt.labels.none },
                ...affiliateNetworks
                    .filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId)
                    .map(el => ({ value: el.id, label: el.name }))
            ],
            [affiliateNetworks, workspaceId, publicWorkspaceId]
        );

        const currentAffNetworkValue = useMemo(
            () => affiliateNetworks.find(item => item.value === affiliateNetworkId),
            [affiliateNetworks, affiliateNetworkId]
        );

        const onCountryTagChange = useCallback(({ target: { value } }) => {
            updateOffer({ countryTag: value });
            setIsNameFocus(true);
        }, []);

        const onAffNetworkChange = useCallback(({ target: { value } }) => {
            updateOffer({ affiliateNetworkId: value });
            setIsUrlFocus(true);
        }, []);

        const onOfferNameChange = useCallback(({ target: { value } }) => {
            const transformedValue = getOnlyASCIICodeText(value?.split(' - ')[1]?.trimStart());
            updateOffer({ name: transformedValue || '' });
        }, []);

        const onTagsChange = useCallback(tags => {
            updateOffer({ tags });
        }, []);

        const onWorkspaceChange = useCallback(
            ({ target: { value } }) => {
                if (affiliateNetworkId || redirectOffer) {
                    const onConfirm = () => {
                        updateOffer({ workspaceId: value, affiliateNetworkId: null, redirectOffer: null });
                        hideModal();
                    };

                    showModal({
                        title: 'Warning',
                        body: props => (
                            <ChangeWorkspaceWarningModal {...props} onConfirm={onConfirm} target={'offers'} />
                        )
                    });
                } else updateOffer({ workspaceId: value });
            },
            [affiliateNetworkId, redirectOffer]
        );

        return (
            <ModalContentContainer>
                <div className="create-offer__fields-row create-offer__fields-row--tag mt15">
                    <SelectInput
                        dataTest="create-offer_workspace-select-input"
                        label={txt.labels.workspace}
                        items={workspacesList}
                        value={workspaceId}
                        hint={txt.hints.workspace(txt.singulars.offers.toLowerCase())}
                        onChange={onWorkspaceChange}
                    />

                    <TagInput
                        label="Select or start entering tags"
                        hint="Select or start entering tags"
                        tagList={offerTags}
                        onChange={onTagsChange}
                    />
                </div>

                <div className="create-offer__fields-row mt25">
                    <SelectInput
                        dataTest="create-offer_country-tag-select-input"
                        label={txt.labels.countryTag}
                        items={countries}
                        value={countryTag}
                        hint={txt.hints.countryTag(txt.labels.offers.toLowerCase())}
                        onChange={onCountryTagChange}
                        isSearchable
                    />

                    <TextInput
                        dataTest="create-offer_offer-name-input"
                        label={txt.labels.name}
                        ref={nameInputRef}
                        value={`${showByValue(countries, countryTag)} - ${offerName}`}
                        onChange={onOfferNameChange}
                        error={isNeedValidate && !offerName?.trim() && txt.errors.required(txt.labels.name)}
                    />
                </div>

                <div className={`create-offer__fields-row mt25${!isAppendClickId ? ' mb15' : ''}`}>
                    <SelectInput
                        dataTest="create-offer_affiliate-network-select-input"
                        label={txt.labels.affiliateNetwork}
                        items={affiliateNetworksForRender}
                        value={affiliateNetworkId || null}
                        onChange={onAffNetworkChange}
                    />
                    <TextInput
                        dataTest="create-offer_offer-url-input"
                        ref={urlInputRef}
                        label={txt.labels.offerUrl}
                        hint={txt.hints.offerUrl}
                        value={url}
                        onChange={handleUrlChange}
                        checkCorrectSymbol={checkUrlCharacters}
                        error={isNeedValidate && getUrlError(url, txt.labels.offerUrl)}
                    />
                </div>

                {affiliateNetworkId && isAppendClickId && (
                    <div className="create-offer__alert-message">
                        <Icon.AlertCircle size={16} className="mr5" />
                        <span>{txt.texts.affNetworkNameConfiguredTemplate(currentAffNetworkValue?.label)}</span>
                        <span className="color--text">{`\u00A0{${'offer_url'}}?${
                            selectedAffNetwork?.conversionTracking.clickParameter || URL_PARAMETER.OPTIONAL
                        }={${'click_id'}}`}</span>
                    </div>
                )}
                {/* eslint-disable-next-line react/no-danger */}
                <div className="mb15" dangerouslySetInnerHTML={{ __html: displayUrl }} />

                <AccordionTokens>
                    <TdBadgesSquareInline label={txt.labels.tokens} items={tokens} onClick={handleTokenClick} />
                </AccordionTokens>
            </ModalContentContainer>
        );
    }
);

OfferSettings.displayName = 'OfferSettings';
export default OfferSettings;
