import React from 'react';
import { Download } from 'react-feather';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import DownloadFile, { ShowDownloadFileCheckbox } from '../../../../Modules/Settings/ColumnsSettings/DownloadFile';

const ExportButton = ({ chapter, className, isDisabled, onClick, type, columns, rows, dateRange, tableParameters }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: txt.labels.downloadFile,
                className: 'exportFileModal',
                HeaderRightItem: ShowDownloadFileCheckbox,
                body: props => (
                    <DownloadFile
                        {...props}
                        chapter={chapter}
                        columns={columns}
                        dataForRender={tableParameters}
                        dateRange={dateRange}
                        rows={rows}
                        isHideOnlySelectedRows={chapter === 'errorLog'}
                    />
                )
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__export-btn--${chapter}`}
            className={className}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {chapter === 'reports' && <Download className="color--grey-gull" />}
            {txt.buttons.export}
        </Btn>
    );
};

export default ExportButton;
