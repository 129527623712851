import React from 'react';

const FakeAutocomplete = () => {
    return (
        <div
            style={{
                opacity: 0,
                height: 0,
                width: 0,
                overflow: 'hidden',
                pointerEvents: 'none',
                zIndex: -999
            }}
        >
            <input type="text" name="emailPlaceholder" placeholder="email" />
            <input type="password" name="passwordPlaceholder" />
        </div>
    );
};

export default FakeAutocomplete;
