import { transform } from '../transformers';
import indexApi from './indexApi';

const tagTypes = {
    generalSettings: 'generalSettings',
    userSettings: 'userSettings'
};

const settingsApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(tagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getGeneralSettings: builder.query({
            query: () => `/settings/general`,
            providesTags: [tagTypes.generalSettings],
            keepUnusedDataFor: 3600
        }),

        getUserSettings: builder.query({
            query: () => '/settings',
            transformResponse: res => transform.userSettings.import(res),
            providesTags: [tagTypes.userSettings]
        }),

        changeGeneralSettings: builder.mutation({
            query: data => ({
                url: '/settings/general',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [tagTypes.generalSettings];
            }
        }),

        changeUserSettingsProfile: builder.mutation({
            query: data => ({
                url: '/settings/user/profile',
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [tagTypes.userSettings];
            }
        }),

        changeUserSettingsEmail: builder.mutation({
            query: data => ({
                url: '/settings/user/email/request',
                method: 'POST',
                body: data
            })
        }),

        confirmUserSettingsEmail: builder.mutation({
            query: token => ({
                url: `/settings/user/email/confirm/${token}`,
                method: 'POST'
            })
        }),

        changeUserPassword: builder.mutation({
            query: data => ({
                url: '/settings/security/change-password',
                method: 'PATCH',
                body: data
            })
        })
    })
});

export const {
    useGetGeneralSettingsQuery,
    useGetUserSettingsQuery,
    useChangeGeneralSettingsMutation,
    useChangeUserSettingsProfileMutation,
    useChangeUserSettingsEmailMutation,
    useChangeUserPasswordMutation
} = settingsApi;
