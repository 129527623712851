import React from 'react';

import Langs from '../../../../Langs';

import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import HighlightedUrl from './highlighted-url';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import Label from '../../../Overall/Forms/Inputs/Label';
import HintIcon from '../../../Overall/Forms/Inputs/HintIcon';

const AffiliateNetworkConversionTracking = React.memo(
    ({ defaultTrackingDomain, conversionTracking, updateAffiliateNetwork }) => {
        const txt = Langs[global.lng];

        const onChange = (prop, value) => {
            updateAffiliateNetwork({ conversionTracking: { ...conversionTracking, [prop]: value } });
        };

        const conversionItems = [
            {
                name: 'clickId',
                parameter: '{cid}',
                anToken: conversionTracking.clickToken,
                anParameter: conversionTracking.clickParameter,
                hint: 'clickId',
                onTokenChange: ({ target: { value } }) => onChange('clickToken', value),
                onParameterChange: ({ target: { value } }) => onChange('clickParameter', value)
            },
            {
                name: 'payout',
                parameter: '{payout}',
                anToken: conversionTracking.payoutToken,
                hint: 'payout',
                onTokenChange: ({ target: { value } }) => onChange('payoutToken', value)
            },
            {
                name: 'transactionId',
                parameter: '{txid}',
                anToken: conversionTracking.transactionToken,
                hint: 'transactionId',
                onTokenChange: ({ target: { value } }) => onChange('transactionToken', value)
            },
            {
                name: 'eventType',
                parameter: '{et}',
                anToken: conversionTracking.eventToken,
                hint: 'eventType',
                onTokenChange: ({ target: { value } }) => onChange('eventToken', value)
            },
            {
                name: 'status',
                parameter: '{status}',
                anToken: conversionTracking.statusToken,
                hint: 'status',
                onTokenChange: ({ target: { value } }) => onChange('statusToken', value)
            }
        ];

        return (
            <ModalContentContainer title={txt.labels.conversionTracking} hint="conversionTracking">
                <div className="affiliate-network__conversion-table">
                    <div>
                        <Label label={txt.labels.name} />
                        <Label label={txt.labels.parameter} hint="conversionParameter" />
                        <Label label={txt.labels.anTokens} hint="conversionANToken" />
                        <Label label={txt.labels.anParameter} hint="conversionANParameter" hintType="left" />
                    </div>

                    {conversionItems.map(el => (
                        <div key={el.name}>
                            <Label label={txt.labels[el.name]} />

                            <div>
                                <div>
                                    <HintIcon hint={txt.hints[el.hint]} />
                                    <span className="ml5">{el.parameter}</span>
                                </div>
                            </div>

                            <TextInput
                                dataTest={`affiliate-network_an-token-${el.name}-input`}
                                value={el.anToken}
                                onChange={el.onTokenChange}
                                placeholder={txt.placeholders.typeToken}
                            />

                            {el.anParameter !== undefined && (
                                <TextInput
                                    dataTest={`affiliate-network_an-parameter-${el.name}-input`}
                                    value={el.anParameter}
                                    onChange={el.onParameterChange}
                                    placeholder={txt.placeholders.typeParameter}
                                />
                            )}
                        </div>
                    ))}
                </div>

                <HighlightedUrl
                    values={{ ...conversionTracking, ...defaultTrackingDomain }}
                    onChange={data => onChange('urlFormat', data)}
                />
            </ModalContentContainer>
        );
    }
);

AffiliateNetworkConversionTracking.displayName = 'AffiliateNetworkConversionTracking';
export default AffiliateNetworkConversionTracking;
