import React from 'react';
import './td-types.scss';

const TdDomain = props => {
    const { item, dnsStatus } = props;
    return (
        <div className="j4">
            {dnsStatus === 'active' && item.ssl ? <div className="domain_https">HTTPS</div> : null}
            {dnsStatus === 'active' && !item.ssl ? <div className="domain_waiting-ssl">Waiting SSL</div> : null}
            <span className="domain__name">{item.name}</span>
        </div>
    );
};

export default TdDomain;
