import React from 'react';
import { getNotDangerouslyHTML } from '../../../../../../Functions/utils';
import Langs from '../../../../../../Langs';
import ActionsGroup from '../../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import { getHtmlTitle } from '../../../../../Overall/CriteriaDialog/CriteriaDialog';
import Label from '../../../../../Overall/Forms/Inputs/Label';
import Icon from '../../../../../Overall/Icon';
import Alert from '../../../../../Overall/UI/Alert/Alert';
import Btn, { AddBtn } from '../../../../../Overall/UI/Btn/Btn';
import FlowPath from '../../FlowPath/FlowPath';

import './rule.scss';

const Rule = ({
    rule,
    isActive,
    onRemoveRule,
    onMoveRule,
    onChange,
    onCopyRule,
    referrerDefaultHideType,
    onRuleClick,
    index,
    trackingMethod,
    workspaceId,
    publicWorkspaceId,
    defaultPaths,
    entityType,
    landersList,
    offersList,
    affiliateNetworksList,
    countries,
    currencies,
    ruleForwardedRef
}) => {
    const txt = Langs[global.lng];

    const onRuleChange = (data, prop) => onChange(data, prop, rule.value);

    const { title } = getHtmlTitle(rule.criteria, rule.logicalRelation);

    const onDeleteEmptyRule = () => {
        if (
            rule.criteria.filter(item => item.isEnabled).length > 0 ||
            rule.paths.filter(item => item.offers.length > 0).length > 0
        )
            onRuleChange(true, 'isDeleted');
        else onRemoveRule();
    };

    const onCriteriaByKeyChange = (data, prop, criteriaKey) => {
        const idx = rule.criteria.findIndex(el => el.key === criteriaKey);
        const newValues = [...rule.criteria];

        let formattedCriteria = { ...rule.criteria[idx] };

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                formattedCriteria = { ...formattedCriteria, [el.prop]: el.value };
            });
        } else {
            formattedCriteria = { ...formattedCriteria, [prop]: data };
        }

        newValues.splice(idx, 1, formattedCriteria);
        onRuleChange(newValues, 'criteria');
    };

    return (
        <Alert
            disabled={rule.isDeleted}
            type="warning"
            ref={ruleForwardedRef}
            isActive={isActive}
            onChange={e => onRuleChange(e, 'isOpened')}
            className="stickable"
            LeftComponent={
                <div
                    className="criteria-dialog__title flow-rules__left-component"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: getNotDangerouslyHTML(rule.name) || title || txt.labels.newRule
                    }}
                />
            }
            RightComponent={
                rule.isDeleted ? (
                    <ActionsGroup
                        dataTest={`${rule.name?.replace(/[\s,]/g, e => (e === ',' ? '' : '-')).toLowerCase()}_${index}`}
                        onUndo={() => onRuleChange(false, 'isDeleted')}
                    />
                ) : (
                    <>
                        <div className="rule__alert-action--arrows">
                            <Btn dataTest="rule_arrowDown-btn" type="pure" onClick={() => onMoveRule(rule)}>
                                <Icon.ArrowDown />
                            </Btn>
                            <Btn dataTest="rule_arrowUp-btn" type="pure" onClick={() => onMoveRule(rule, true)}>
                                <Icon.ArrowUp />
                            </Btn>
                        </div>
                        <ActionsGroup
                            isSwitchOn={rule.isRuleEnabled}
                            onSwitch={() => onRuleChange(!rule.isRuleEnabled, 'isRuleEnabled')}
                            onCopy={onCopyRule}
                            onDelete={onDeleteEmptyRule}
                        />
                    </>
                )
            }
            opened={rule.isOpened}
        >
            <div className="rule__content">
                <Label label={txt.labels.criteria} className="rule__label" />

                {rule.criteria
                    .filter(({ isHidden }) => !isHidden)
                    .filter(
                        ({ value, from, to, isEnabled }) =>
                            !!(Array.isArray(value) ? value.length : value) || (isEnabled && from && to)
                    )
                    .map(item => (
                        <Alert
                            key={item.key}
                            type={item.isMatched ? 'success' : 'danger'}
                            LeftComponent={
                                <div
                                    className="rule__criterion test"
                                    onClick={() => {
                                        onRuleClick({
                                            rule,
                                            activeTabId: item.tabId
                                        });
                                    }}
                                >
                                    <span className="rule__criteria-title">{txt.labels[item.label] || item.label}</span>
                                    <div className={`is-is-not is-is-not--${item.isMatched ? 'success' : 'danger'}`}>
                                        {txt.labels[item.isMatched ? 'is' : 'isNot']}
                                    </div>
                                    <span className="rule__rules-list">{item.getFlatValue?.(item) || ''}</span>
                                </div>
                            }
                            RightComponent={
                                <ActionsGroup
                                    onDelete={() => {
                                        onCriteriaByKeyChange(
                                            null,
                                            [
                                                {
                                                    prop: 'isEnabled',
                                                    value: false
                                                },
                                                {
                                                    prop: 'value',
                                                    value: Array.isArray(item.value) ? [] : ''
                                                }
                                            ],
                                            item.key
                                        );
                                    }}
                                    onSwitch={() => onCriteriaByKeyChange(!item.isEnabled, 'isEnabled', item.key)}
                                    isSwitchOn={item.isEnabled}
                                    onEdit={() => {
                                        onRuleClick({
                                            rule,
                                            activeTabId: item.tabId
                                        });
                                    }}
                                />
                            }
                        />
                    ))}

                <div className="j4 rule__criteria">
                    <AddBtn dataTest="rule_add-criteria-btn" type="faded" onClick={() => onRuleClick({ rule })}>
                        Criteria
                    </AddBtn>

                    {rule.criteria
                        .filter(({ isHidden }) => !isHidden)
                        .filter(
                            ({ value, from, to }) => !!(Array.isArray(value) ? value.length : value) || (from && to)
                        )
                        .filter(item => item.isEnabled).length < 1 && (
                        <Alert className="rule__criteria-alert" message={txt.alerts.emptyCriteria} type="danger" />
                    )}
                </div>

                <FlowPath
                    defaultPaths={defaultPaths}
                    isPathInsideRule={true}
                    paths={rule.paths}
                    calculationMethod={rule.calculationMethod}
                    referrerDefaultHideType={referrerDefaultHideType}
                    workspaceId={workspaceId}
                    publicWorkspaceId={publicWorkspaceId}
                    onChange={onRuleChange}
                    isPathOptimization={rule.isPathOptimization}
                    isRuleOptimizedData={rule.isRuleOptimizedData}
                    trackingMethod={trackingMethod}
                    lastOptimizationAt={rule.lastOptimizationAt}
                    optimizationSettings={rule.optimizationSettings}
                    entityType={entityType}
                    landersList={landersList}
                    offersList={offersList}
                    affiliateNetworksList={affiliateNetworksList}
                    countries={countries}
                    currencies={currencies}
                />
            </div>
        </Alert>
    );
};

export default React.forwardRef((props, ref) => <Rule {...props} ruleForwardedRef={ref} />);
