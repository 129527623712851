import React from 'react';
import cn from 'classnames';
import { getIntlFormattedValue } from '../../../../Functions/utils';

export const TdChange = ({ value: initialValue, rounding, onlyProfit }) => {
    const { value, percent } = initialValue || {};
    const formatValue = getIntlFormattedValue(value, rounding);
    const formatPercent = getIntlFormattedValue(percent, 2);
    const isPositive = Number(value).toFixed(rounding) > 0;
    const isNegative = Number(value).toFixed(rounding) < 0;

    // for roiChange
    if (onlyProfit) {
        return (
            <div
                className={cn(
                    { 'td-change__value--positive': isPositive },
                    { 'td-change__value--negative': isNegative }
                )}
            >
                {isPositive && '+'}
                {getIntlFormattedValue(value, rounding ?? 2)}%
            </div>
        );
    }

    return (
        <div className="td-change">
            <div
                className={cn(
                    'td-change__percent',
                    { 'td-change__percent--positive': isPositive },
                    { 'td-change__percent--negative': isNegative }
                )}
            >
                {isPositive && '+'}
                {formatPercent}%
            </div>
            {/* <div className="td-change__delimeter">|</div> */}
            <div
                className={cn(
                    'td-change__value',
                    { 'td-change__value--positive': isPositive },
                    { 'td-change__value--negative': isNegative }
                )}
            >
                {isPositive && '+'}
                {formatValue}
            </div>
        </div>
    );
};
