import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../Constants';
import { omitKeys } from '../../../Functions/utils';
import { hideModal } from '../../../redux/operations';
import useFocus from '../../../Functions/hooks/useFocus';

import {
    useGetTSIntegrationByIdQuery,
    useAddTSIntegrationMutation,
    useEditTSIntegrationMutation
} from '../../../redux/api/integrationsApi';

import { getIntegrationIcon } from '../../Tools/integrations/get-integration-icon';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Alert from '../../Overall/UI/Alert/Alert';
import Loader from '../../Loader';
import FakeAutocomplete from '../../../components/fake-autocomlete/fake-autocomlete';

import './ts-integration-modal.scss';

const TSIntegrationModal = ({ templateName, templateId, integrationId, mode, fields, onClose, onChange }) => {
    const txt = Langs[global.lng];

    const [nameInputRef, setNameFocus] = useFocus();
    const [isTouched, setIsTouched] = useState(false);

    const isCreateMode = mode === ENTITY_MODE_STATUS.CREATE;

    const { data: integration, isSuccess: isIntegrationLoaded } = useGetTSIntegrationByIdQuery(integrationId, {
        skip: !integrationId || mode === ENTITY_MODE_STATUS.CREATE
    });

    const createInitialState = () => {
        const result = { integrationName: templateName };

        fields.forEach(field => {
            result[field.requestKey] = !isCreateMode
                ? integration?.authCredentials?.[field.requestKey] || ''
                : field.value;
        });

        return result;
    };

    const [fieldsState, setFieldsState] = useState(createInitialState());

    const updateFieldsState = useCallback((key, value) => {
        setFieldsState(prevState => ({ ...prevState, [key]: value }));
    }, []);

    useEffect(() => {
        if (isIntegrationLoaded) setFieldsState({ ...createInitialState(), integrationName: integration?.name });
    }, [isIntegrationLoaded]);

    let isAllValid = true;
    fields.forEach(field => {
        if (field.isRequired && !fieldsState[field.requestKey]) isAllValid = false;
    });

    const [
        addTrafficSourceIntegration,
        {
            data: newTrafficSourceIntegration,
            isLoading: isAddTrafficSourceIntegrationLoading,
            isError: isAddTrafficSourceIntegrationError,
            error: addTrafficSourceIntegrationError,
            isSuccess: isAddTrafficSourceIntegrationSuccess
        }
    ] = useAddTSIntegrationMutation();

    const [
        editTrafficSourceIntegration,
        {
            isLoading: isEditTrafficSourceIntegrationLoading,
            isError: isEditTrafficSourceIntegrationError,
            error: editTrafficSourceIntegrationError,
            isSuccess: isEditTrafficSourceIntegrationSuccess
        }
    ] = useEditTSIntegrationMutation();

    useEffect(() => {
        if (isAddTrafficSourceIntegrationSuccess) {
            toast.success(txt.toasts.saved);

            if (onChange) {
                onChange({
                    integrationId: newTrafficSourceIntegration.id,
                    isIntegration: true
                });
            }

            hideModal();
            return;
        }

        if (isEditTrafficSourceIntegrationSuccess) {
            toast.success(txt.toasts.saved);

            hideModal();
        }
    }, [isAddTrafficSourceIntegrationSuccess, isEditTrafficSourceIntegrationSuccess]);

    useEffect(() => {
        if (mode === ENTITY_MODE_STATUS.CREATE) setTimeout(setNameFocus, 0);
    }, []);

    const onConfirm = () => {
        setIsTouched(true);

        if (isAllValid) {
            const queryData = {
                ...(!isCreateMode ? { id: integrationId } : {}),
                data: {
                    // FIXME: remove it after back delete /api/v1/integrations/traffic-source { "isActive": true }
                    isActive: true,

                    name: fieldsState.integrationName,
                    templateId,
                    authCredentials: omitKeys(fieldsState, ['integrationName']),
                    isDraft: false
                }
            };

            const query = isCreateMode ? addTrafficSourceIntegration : editTrafficSourceIntegration;

            query(queryData);
        }
    };

    const Img = useMemo(() => getIntegrationIcon(templateName), [templateName]);

    return (
        <>
            <div className="traffic-source-integration-modal">
                {Img && <img src={Img} alt="Integration logo" width="144" />}

                <FakeAutocomplete />

                <TextInput
                    dataTest={`traffic-source-integration-modal_field_integration-name`}
                    label={txt.titles.integrationName}
                    value={fieldsState.integrationName}
                    ref={nameInputRef}
                    onFocus={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            updateFieldsState('integrationName', `${templateName} - `);
                        }
                    }}
                    onBlur={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) updateFieldsState('integrationName', templateName);
                    }}
                    onChange={({ target: { value } }) => {
                        if (!new RegExp(` - .`).test(value.trim())) {
                            updateFieldsState('integrationName', `${templateName} - `);
                        } else updateFieldsState('integrationName', value);
                    }}
                    className="mb20"
                    labelRequire
                />

                {fields.map(field =>
                    field.label ? (
                        <TextInput
                            key={field.requestKey}
                            dataTest={`traffic-source-integration-modal_field_${field.requestKey}`}
                            label={field.label}
                            icon={field.labelType === 'password' ? 'password' : null}
                            type={field.labelType ?? 'text'}
                            placeholder={field.placeholder}
                            value={fieldsState[field.requestKey]}
                            onChange={({ target: { value } }) => updateFieldsState(field.requestKey, value)}
                            className="mb20"
                            labelRequire={field.isRequired}
                            error={
                                field.isRequired &&
                                isTouched &&
                                !(field.validation
                                    ? new RegExp(field.validation).test(fieldsState[field.requestKey])
                                    : fieldsState[field.requestKey])
                            }
                        />
                    ) : null
                )}

                {(isAddTrafficSourceIntegrationError || isEditTrafficSourceIntegrationError) && (
                    <Alert
                        type="danger"
                        message={
                            isCreateMode
                                ? addTrafficSourceIntegrationError.data?.errors?.message
                                : editTrafficSourceIntegrationError.data?.errors?.message
                        }
                    />
                )}

                {(isAddTrafficSourceIntegrationLoading || isEditTrafficSourceIntegrationLoading) && (
                    <Loader isFetching />
                )}

                {!isCreateMode && !isIntegrationLoaded && <Loader isLoad />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={(isTouched && !isAllValid) || isAddTrafficSourceIntegrationLoading}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default TSIntegrationModal;
