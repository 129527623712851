import React /*{ useEffect, useMemo, useState }*/ from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';

import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

import '../../Overall/SelectTable/select-table.scss';

const ImportSettingsTableItem = props => {
    const { type, constructor, className, item, active, isStark, disabled, onChange } = props;
    const { columns } = constructor;

    const getDate = date => (date ? moment(date).format('L') : null);
    const getTime = date => (date ? moment(date.split('+')?.[0]).format('LT') : null);

    return (
        <div
            className={cn(
                className,
                type === 'header' ? 'select-table-items-header__tr' : 'select-table-items-body__tr',
                {
                    'select-table-items-body__tr--active': type === 'row' && active,
                    'select-table-items-body__tr--disabled': type === 'row' && disabled
                }
            )}
            onClick={type === 'row' ? e => onChange(e, item) : () => {}}
        >
            <div
                className={cn(
                    type === 'header' ? 'select-table-items-header__th' : 'select-table-items-body__td',
                    'select-table-items-header__checkbox'
                )}
            >
                <CheckBoxInput
                    dataTest="select-table-items_select-all-checkbox"
                    value={active || false}
                    isStark={type === 'header' && isStark !== undefined ? isStark : false}
                    onChange={type === 'row' ? () => {} : e => onChange(e, item)}
                />
            </div>

            {columns?.map(col => (
                <div
                    key={col.key}
                    className={cn(
                        type === 'header' ? 'select-table-items-header__th' : 'select-table-items-body__td',
                        type === 'header' ? col.className : col.cellClassName
                    )}
                >
                    <div data-text={type === 'header' ? col.label : item?.[col.key]}>
                        {type === 'header' ? col.label : null}
                        {type !== 'header' && col.type === 'date' ? (
                            <>
                                <span className="mr5">{getDate(item?.[col.key])}</span>
                                <span className="color--pause">{getTime(item?.[col.key])}</span>
                            </>
                        ) : (
                            item?.[col.key]
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImportSettingsTableItem;
