import React, { useMemo, useCallback } from 'react';

import Langs from '../../../../Langs';
import { getOnlyDecimalNumbers } from '../../../../Functions/utils';
import useDelayedFocus from '../../../../Functions/hooks/useDelayedFocus';

import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Alert from '../../../Overall/UI/Alert/Alert';
import HighlightedUrl from '../../create-affiliate-network/components/highlighted-url';
import RadioInput from '../../../Overall/Forms/Inputs/RadioInput';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';

const payoutTypes = ['byOfferClick', 'auto', 'manual'];

const OfferConversionTracking = React.memo(
    ({
        payout,
        payoutType,
        payoutCurrency,
        conversionUrlFormat,
        selectedAffNetwork,
        updateOffer,
        isNeedValidate,
        currencies,
        defaultTrackingDomain
    }) => {
        const txt = Langs[global.lng];

        const [offerPayoutInputRef, setIsOfferPayoutFocus] = useDelayedFocus();

        const onPayoutCurrencyChange = useCallback(
            ({ target: { value } }) => updateOffer({ payoutCurrency: value }),
            []
        );

        const onPayoutChange = useCallback(({ target: { value } }) => {
            updateOffer({ payout: getOnlyDecimalNumbers(value) });
        }, []);

        const onPayoutTypeChange = useCallback(({ target: { value } }) => {
            updateOffer({ payoutType: value });
            if (value !== 'auto') setIsOfferPayoutFocus(true);
        }, []);

        const onConversionUrlFormatChange = useCallback(value => {
            updateOffer({ conversionUrlFormat: value });
        }, []);

        const getPayoutErrorText = () => {
            if (!payout) return txt.errors.payoutValueIsRequired;
            if (payout === '0') return txt.errors.invalidPayoutValue;
            return null;
        };

        const highlightedUrls = useMemo(
            () => ({
                ...defaultTrackingDomain,
                clickToken: selectedAffNetwork?.conversionTracking?.clickToken,
                clickParameter: selectedAffNetwork?.conversionTracking?.clickParameter,
                payoutToken: selectedAffNetwork?.conversionTracking?.payoutToken,
                transactionToken: selectedAffNetwork?.conversionTracking?.transactionToken,
                eventToken: selectedAffNetwork?.conversionTracking?.eventToken,
                urlFormat: selectedAffNetwork?.conversionTracking?.urlFormat || conversionUrlFormat
            }),
            [defaultTrackingDomain, selectedAffNetwork]
        );

        return (
            <ModalContentContainer title={txt.labels.conversionTracking} hint="offerConversionTracking">
                <div className="create-offer__payout">
                    <RadioInput
                        dataTest="create-offer_payout"
                        label={txt.labels.type}
                        type="row"
                        items={payoutTypes}
                        value={payoutType}
                        onChange={onPayoutTypeChange}
                    />
                    {payoutType !== 'auto' && (
                        <TextInput
                            dataTest="create-offer_payout-input"
                            value={payout || ''}
                            label={txt.labels.payout}
                            hint={txt.hints.offerPayout}
                            ref={offerPayoutInputRef}
                            className="create-offer__payout--field"
                            placeholder={txt.placeholders.payout}
                            onChange={onPayoutChange}
                            error={isNeedValidate && getPayoutErrorText()}
                        />
                    )}
                    <SelectInput
                        dataTest="create-offer_currency-select-input"
                        label={txt.labels.currency}
                        hint={txt.hints.offerCurrency}
                        items={currencies}
                        value={payoutCurrency}
                        onChange={onPayoutCurrencyChange}
                        optionsDirection="top"
                        isSearchable
                    />
                </div>

                {payoutType === 'byOfferClick' ? (
                    <Alert className="create-offer__payout-alert" message={txt.alerts.triggersConversionRecord} />
                ) : (
                    <HighlightedUrl values={highlightedUrls} isOptional onChange={onConversionUrlFormatChange} />
                )}
            </ModalContentContainer>
        );
    }
);

OfferConversionTracking.displayName = 'OfferConversionTracking';
export default OfferConversionTracking;
