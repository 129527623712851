import React from 'react';
import cn from 'classnames';

import './just-td-timezone.scss';

const JustTdTimezone = ({ timezones, timezone, className }) => {
    const activeTimezone = timezones?.find(el => el.value === timezone);

    return (
        <div className={cn(className)}>
            <span>{activeTimezone ? activeTimezone.label : timezone}</span>
        </div>
    );
};

export default JustTdTimezone;
