import indexApi from './indexApi';
import { transform } from '../transformers';

export const errorLogApiTagTypes = {
    errorLog: 'errorLog'
};

const errorLogApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(errorLogApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getErrorLog: builder.query({
            query: params => `/error-log${params || ''}`,
            transformResponse: response => ({
                items: response.items.map(el => transform.errorLog.import(el)),
                pagination: response.pagination
            }),
            providesTags: () => [{ type: errorLogApiTagTypes.errorLog, id: 'LIST' }]
        }),

        getErrorLogCategories: builder.query({
            query: () => `/error-log/categories`,
            providesTags: () => [{ type: errorLogApiTagTypes.errorLog, id: 'CATEGORIES' }]
        })
    })
});

export const { useGetErrorLogQuery, useGetErrorLogCategoriesQuery } = errorLogApi;
