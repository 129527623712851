import React, { useEffect, useState } from 'react';

import Langs from '../../../../Langs';
import { URL_PARAMETER } from '../../../../Constants';
import useFocus from '../../../../Functions/hooks/useFocus';

import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import Switcher from '../../../Overall/UI/Switcher/Switcher';
import Label from '../../../Overall/Forms/Inputs/Label';
import RadioInput from '../../../Overall/Forms/Inputs/RadioInput';

const AffiliateNetworkOptions = React.memo(
    ({
        isDoublePostbackAccepted,
        usePostbackStatuses,
        isClickIdAppendToOffer,
        conversionTracking,
        isPostbackIpWhiteListEnabled,
        postbackStatuses,
        ipList,
        updateAffiliateNetwork
    }) => {
        const txt = Langs[global.lng];

        const [listOfIp, setListOfIp] = useState(ipList?.join(', ') || '');
        const [ipListInputRef, setIpListInputRefFocus] = useFocus();

        const updatePostbackStatuses = (prop, target, value) => {
            updateAffiliateNetwork({
                postbackStatuses: {
                    ...postbackStatuses,
                    [prop]: { ...postbackStatuses[prop], [target]: value }
                }
            });
        };

        const handleClearStatus = value => value.trim().replace(/[ ,]+/g, ', ');

        const postbackStatusesList = [
            {
                label: 'new',
                value: postbackStatuses.newStatus.value,
                key: postbackStatuses.newStatus.key,
                hint: txt.hints.newPostbackStatus,
                handleChange: ({ target: { value } }) => updatePostbackStatuses('newStatus', 'value', value),
                handleBlur: ({ target: { value } }) =>
                    updatePostbackStatuses('newStatus', 'value', handleClearStatus(value))
            },
            {
                label: 'approved',
                value: postbackStatuses.approvedStatus.value,
                key: postbackStatuses.approvedStatus.key,
                hint: txt.hints.approvedPostbackStatus,
                handleChange: ({ target: { value } }) => updatePostbackStatuses('approvedStatus', 'value', value),
                handleBlur: ({ target: { value } }) =>
                    updatePostbackStatuses('approvedStatus', 'value', handleClearStatus(value))
            },
            {
                label: 'declined',
                value: postbackStatuses.declinedStatus.value,
                key: postbackStatuses.declinedStatus.key,
                hint: txt.hints.declinedPostbackStatus,
                handleChange: ({ target: { value } }) => updatePostbackStatuses('declinedStatus', 'value', value),
                handleBlur: ({ target: { value } }) =>
                    updatePostbackStatuses('declinedStatus', 'value', handleClearStatus(value))
            },
            {
                label: 'chargeback',
                value: postbackStatuses.chargeBackStatus.value,
                key: postbackStatuses.chargeBackStatus.key,
                hint: txt.hints.chargebackPostbackStatus,
                handleChange: ({ target: { value } }) => updatePostbackStatuses('chargeBackStatus', 'value', value),
                handleBlur: ({ target: { value } }) =>
                    updatePostbackStatuses('chargeBackStatus', 'value', handleClearStatus(value))
            },
            { label: 'ignore', key: 'ignore' }
        ];

        useEffect(() => {
            if (listOfIp && isPostbackIpWhiteListEnabled) {
                updateAffiliateNetwork({ ipList: listOfIp.split(/[,\s]+/).flat() });
            }
        }, [listOfIp]);

        useEffect(() => {
            if (isPostbackIpWhiteListEnabled && !listOfIp?.length) setIpListInputRefFocus();
        }, [isPostbackIpWhiteListEnabled]);

        const clickIdUrl = encodeURI(conversionTracking.clickParameter?.trim?.()) || URL_PARAMETER.REPLACE;

        // const onOfferUrlChange = value => {
        //     const newClickParameter = value.match(/([=?])(.*?)(?==)/)[2].replace(URL_PARAMETER.REPLACE, '');
        //     onValuesChange(newClickParameter, 'conversion.clickParameter');
        // };

        return (
            <ModalContentContainer title={txt.labels.additionalOptions}>
                <Switcher
                    dataTest="affiliate-network_accept-double-postback"
                    className="mt16"
                    label={txt.labels.acceptDoublePostback}
                    isSwitchOn={isDoublePostbackAccepted}
                    hint={txt.hints.acceptDoublePostback}
                    onChange={value => updateAffiliateNetwork({ isDoublePostbackAccepted: value })}
                />

                <Switcher
                    dataTest="affiliate-network_use-postback-statuses"
                    className="mt16"
                    label={txt.labels.usePostbackStatuses}
                    hint={txt.hints.usePostbackStatuses}
                    isSwitchOn={usePostbackStatuses}
                    onChange={value => updateAffiliateNetwork({ usePostbackStatuses: value })}
                >
                    <div className="affiliate-network__postback-statuses mt10">
                        {postbackStatusesList?.map(el => {
                            if (!el.handleChange) return null;

                            return (
                                <div key={el.key}>
                                    <Label label={txt.labels[el.label]} hint={el.hint} />

                                    <TextInput
                                        dataTest={`affiliate-network_additional-options-${el.label}-input`}
                                        value={el.value}
                                        placeholder={txt.placeholders.commaSeparatedValues}
                                        onChange={el.handleChange}
                                        onBlur={el.handleBlur}
                                    />
                                </div>
                            );
                        })}

                        <div>
                            <Label label={txt.labels.defaultPostbackStatus} hint={txt.hints.defaultPostbackStatus} />

                            <RadioInput
                                dataTest="affiliate-network_postback-status"
                                type="row"
                                items={postbackStatusesList?.map(({ label }) => label)}
                                value={
                                    postbackStatusesList?.find(({ key }) => key === postbackStatuses.defaultStatus)
                                        ?.label
                                }
                                onChange={({ target: { value } }) =>
                                    updateAffiliateNetwork({
                                        postbackStatuses: {
                                            ...postbackStatuses,
                                            defaultStatus: postbackStatusesList?.find(({ label }) => value === label)
                                                .key
                                        }
                                    })
                                }
                            />
                        </div>
                    </div>
                </Switcher>

                <Switcher
                    dataTest="affiliate-network_append-click-id-url"
                    className="mt16"
                    label={txt.labels.appendClickIdUrl}
                    hint={txt.hints.appendClickIdUrl}
                    hintType="bottom"
                    isSwitchOn={isClickIdAppendToOffer}
                    onChange={value => updateAffiliateNetwork({ isClickIdAppendToOffer: value })}
                >
                    <TextInput
                        className="mt10 mb25"
                        dataTest="affiliate-network_append-click-id-url-input"
                        value={`{offer_url}?${clickIdUrl}={click_id}`}
                        error={conversionTracking.clickParameter ? '' : txt.errors.anToken}
                        disabled
                    />
                </Switcher>

                <div className="j46 mt16">
                    <Switcher
                        dataTest="affiliate-network_postback-ip-white-list"
                        label={txt.labels.postbackIpWhiteList}
                        hint={txt.hints.postbackIpWhiteList}
                        hintType="bottom"
                        isSwitchOn={isPostbackIpWhiteListEnabled}
                        onChange={value => updateAffiliateNetwork({ isPostbackIpWhiteListEnabled: value })}
                    />

                    {isPostbackIpWhiteListEnabled && (
                        <span className="affiliate-network__prompt">{txt.labels.enterIpPerLine}</span>
                    )}
                </div>

                {isPostbackIpWhiteListEnabled && (
                    <TextInput
                        dataTest="affiliate-network_postback-ip-white-list-textarea"
                        type="textarea"
                        className="affiliate-network__postback-ip mt10"
                        value={listOfIp}
                        ref={ipListInputRef}
                        onChange={({ target: { value } }) => setListOfIp(value)}
                    />
                )}
            </ModalContentContainer>
        );
    }
);

AffiliateNetworkOptions.displayName = 'AffiliateNetworkOptions';
export default AffiliateNetworkOptions;
