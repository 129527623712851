import React, { useEffect, useState } from 'react';

const MainTableResizer = ({ onChange }) => {
    const [currentColumnElement, setCurrentColumnElement] = useState(null);

    useEffect(() => {
        if (currentColumnElement) {
            const itemTable = document.querySelector('.items-table');
            // const { offsetWidth: tableWrapperWidth, offsetHeight: tableWrapperHeight } =
            //     document.querySelector('.main-table__visible-content');
            const { offsetHeight: tableWrapperHeight } = document.querySelector('.main-table__visible-content');
            const resizeColumn = document.querySelector('.items-table__resize-td');
            const importantCells = Array.from(document.querySelectorAll('.td-important:not(.td-important--header)'));
            const tableTh = Array.from(
                document.querySelectorAll('.items-table__tr > .items-table__th:not(.items-table__resize-td)')
            );
            const columnsWidth = tableTh.map(({ offsetWidth }) => offsetWidth);
            const resizeMarkerStyle = document.querySelector('.items-table__resize-marker').style;

            tableTh.forEach(({ style }, index) => {
                style.minWidth = columnsWidth[index] + 'px';
                style.width = columnsWidth[index] + 'px';
            });
            importantCells.forEach(column => {
                column.style.minWidth = 'unset';
            });
            resizeColumn.style.width = '100%';

            let currentColumnElementWidth;

            const moveFunction = ({ pageX }) => {
                itemTable.style.cursor = 'col-resize';
                resizeMarkerStyle.height = tableWrapperHeight + 'px';
                resizeMarkerStyle.left = pageX + 'px';
                currentColumnElementWidth =
                    currentColumnElement.offsetWidth + pageX - currentColumnElement.getBoundingClientRect().right;
            };

            itemTable.addEventListener('mousemove', moveFunction);
            document.addEventListener('mouseup', function widthUpdate() {
                // const resizeWidth = currentColumnElementWidth - currentColumnElement.offsetWidth;
                const child = currentColumnElement.querySelector('.items-table__th-content');

                const parentStyle = getComputedStyle(currentColumnElement);
                const childtStyle = getComputedStyle(child);

                const paddings = parseInt(parentStyle.paddingLeft, 10) + parseInt(parentStyle.paddingRight, 10);
                const childWidth = Math.round(parseFloat(childtStyle.width));

                const minimalWidth = childWidth + paddings;

                // currentColumnElement.style.width = currentColumnElementWidth + 'px';
                // currentColumnElement.style.minWidth = currentColumnElementWidth + 'px';
                resizeMarkerStyle.height = 0;
                onChange(currentColumnElementWidth > minimalWidth ? currentColumnElementWidth : minimalWidth);

                // if (resizeWidth > 0) {
                //     if (resizeColumn.offsetWidth > 0 && resizeColumn.offsetWidth < resizeWidth) {
                //         itemTable.style.width = itemTable.offsetWidth + resizeWidth - resizeColumn.offsetWidth + 'px';
                //     }
                // } else if (itemTable.offsetWidth + resizeWidth > tableWrapperWidth) {
                //     itemTable.style.width = itemTable.offsetWidth + resizeWidth + 'px';
                // } else {
                //     itemTable.style.width = tableWrapperWidth + 'px';
                // }

                itemTable.style.cursor = 'unset';
                setCurrentColumnElement(null);
                itemTable.removeEventListener('mousemove', moveFunction);
                document.removeEventListener('mouseup', widthUpdate);
            });
        }
    }, [currentColumnElement]);

    return (
        <div
            className="items-table__drag-handler"
            onMouseDown={event => {
                event.preventDefault();
                setCurrentColumnElement(event.target.parentElement);
            }}
        >
            <div />
        </div>
    );
};

export default MainTableResizer;
