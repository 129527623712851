import React, { useState } from 'react';
import { connect } from 'react-redux';
import Langs from '../../../../Langs';

import { Dispatch } from '../../../../redux/rsd';
import AddCustomColumn from '../../../Settings/StatisticsSettings/AddCustomColumn';
import SelectInput from '../../Forms/Inputs/SelectInput/SelectInput';
import TdEdit from './TdEdit';
import { TdText } from './TdText';
import { showModal } from '../../../../redux/operations';

const TdRounding = ({ item, rows, index, params, columnOptions, onChange }) => {
    if (!item && item !== 0) return null;
    const txt = Langs[global.lng];
    const row = rows[index];

    const [isEditable, setIsEditable] = useState(false);

    const onSubmit = data => {
        rows[index] = data;
        onChange();
    };

    const handleClick = () => {
        if (row.custom) {
            showModal({
                title: txt.labels.editColumn,
                body: props => <AddCustomColumn {...props} item={row} onSubmit={onSubmit} />
            });
        } else setIsEditable(!isEditable);
    };

    const handleChange = e => {
        rows[index].rounding = e.target.value;
        setIsEditable(false);
        const data = { rows, index };
        onChange(data);
    };

    return (
        <>
            <TdEdit type="pure" onClick={handleClick} />
            {isEditable ? (
                <SelectInput
                    dataTest="td-rounding_select-input"
                    value={item}
                    items={[1, 2, 3, 4, 5]}
                    onChange={handleChange}
                />
            ) : (
                <TdText item={1 / 10 ** item} type={params.type} columnOptions={columnOptions} />
            )}
        </>
    );
};

export default connect(null, Dispatch)(TdRounding);
