import useEventListener from './useEventListener';

const useKeyPress = (targetKey, callback, targetNode = document) => {
    const handleKeyPress = event => {
        if (event.key === targetKey) {
            callback(event);
        }
    };

    useEventListener('keyup', handleKeyPress, targetNode);
};

export default useKeyPress;
