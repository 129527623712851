import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetInvoiceDetailsQuery } from '../../../redux/api/invoiceDetailsApi';

import ChoosePlanModal from '../choose-plan-modal/choose-plan-modal';
import UpgradePlanModal from '../upgrade-plan-modal/upgrade-plan-modal';
import DowngradePlanModal from '../downgrade-plan-modal/downgrade-plan-modal';
import RenewPlanModal from '../renew-plan-modal/renew-plan-modal';

const PLAN_MODAL_TYPES = {
    CHOOSE: 'CHOOSE',
    UPGRADE: 'UPGRADE',
    DOWNGRADE: 'DOWNGRADE',
    RENEW: 'RENEW'
};

const ChangePlanModal = ({
    isBlocking,
    isBlockedByEvents,
    blockedByEventsData,
    modalType,
    invoice,
    onPlanChanged,
    customClose
}) => {
    const [openedModal, setOpenedModal] = useState(null);

    const { data: invoiceDetails, isSuccess: isInvoiceDetailsLoaded } = useGetInvoiceDetailsQuery();

    const history = useHistory();

    const onClose = (e, isSucces) => {
        if (isSucces && onPlanChanged) onPlanChanged();

        if (customClose) customClose(isSucces);
        else history.push('/settings/billing-and-subscription');
    };

    const onChoosePlan = (currentPlanIndex, activePlanIndex, plan) => {
        if (currentPlanIndex > activePlanIndex) {
            setOpenedModal({ type: PLAN_MODAL_TYPES[isBlocking ? 'RENEW' : 'UPGRADE'], options: { planId: plan.id } });
        } else if (currentPlanIndex < activePlanIndex) {
            setOpenedModal({
                type: PLAN_MODAL_TYPES[
                    isBlocking && (invoice?.overAmount > 0 || plan.monthlyPayment > 0) ? 'RENEW' : 'DOWNGRADE'
                ],
                options: { planId: plan.id }
            });
        } else {
            setOpenedModal({ type: PLAN_MODAL_TYPES.RENEW, options: { planId: plan.id } });
        }
    };

    const ChoosePlanModalComponent = (
        <ChoosePlanModal
            isLoading={!isInvoiceDetailsLoaded}
            onClose={onClose}
            onChoosePlan={onChoosePlan}
            isBlocking={isBlocking || isBlockedByEvents}
            isBlockedByEvents={isBlockedByEvents}
            blockedByEventsData={blockedByEventsData}
            modalType={modalType}
            invoice={invoice}
        />
    );

    const RenewPlanModalComponent = (
        <RenewPlanModal
            modalType={modalType}
            invoice={invoice}
            onClose={onClose}
            onBackToPlans={() => setOpenedModal({ type: PLAN_MODAL_TYPES.CHOOSE })}
            planId={openedModal?.options?.planId}
            isEmptyInvoiceDetails={!invoiceDetails?.name}
            isLoading={!isInvoiceDetailsLoaded}
        />
    );

    switch (openedModal?.type) {
        case PLAN_MODAL_TYPES.UPGRADE:
            return (
                <UpgradePlanModal
                    planId={openedModal.options.planId}
                    onClose={onClose}
                    onBackToPlans={() => setOpenedModal({ type: PLAN_MODAL_TYPES.CHOOSE })}
                    isEmptyInvoiceDetails={!invoiceDetails?.name}
                />
            );

        case PLAN_MODAL_TYPES.DOWNGRADE:
            return (
                <DowngradePlanModal
                    planId={openedModal.options.planId}
                    onClose={onClose}
                    onBackToPlans={() => setOpenedModal({ type: PLAN_MODAL_TYPES.CHOOSE })}
                    isEmptyInvoiceDetails={!invoiceDetails?.name}
                />
            );

        case PLAN_MODAL_TYPES.RENEW:
            return RenewPlanModalComponent;

        case PLAN_MODAL_TYPES.CHOOSE:
            return ChoosePlanModalComponent;

        default:
            return isBlocking ? RenewPlanModalComponent : ChoosePlanModalComponent;
    }
};

export default ChangePlanModal;
