import React from 'react';

import Switcher from '../../../UI/Switcher/Switcher';

const TdSwitcherWithStatus = ({ isSwitchOn, text, isColored, color, onChange }) => {
    return (
        <div className="j4">
            <Switcher isSwitchOn={isSwitchOn} onChange={onChange} />
            <span style={{ ...(isColored ? { color } : {}) }}>{text}</span>
        </div>
    );
};

export default TdSwitcherWithStatus;
