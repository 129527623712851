import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';
import { KEYBOARD_SERVICE_KEY } from '../../../../Constants';
import { checkDateRangeLimitsAndRewrite } from '../../../../redux/operations';

import SelectInput from '../../Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../Forms/Inputs/TextInput';
import Btn from '../Btn/Btn';
import Switcher from '../Switcher/Switcher';
import Calendar from './Calendar';

const padTime = time => {
    return `${time.toString().padStart(2, '0')}:00`;
};

const Datepicker = props => {
    const {
        values = {},
        onChange,
        onChangeCompare,
        onCancel,
        getSelectedMode,
        onChangeInterval,
        isCompareHidden,
        isHideFooter,
        isFutureSelect,
        limitRange,
        monthDataStorage,
        availableInterval,
        isAllowFuture
    } = props;
    const { start = null, end = null, compare = false } = values;
    const txt = Langs[global.lng];
    const startDateInputRef = useRef(null);
    const endDateInputRef = useRef(null);

    const [startDate, setStartDate] = useState(moment(start));
    const [endDate, setEndDate] = useState(moment(end));
    const dateInterval = getSelectedMode({ start: startDate, end: endDate }, { isAllowFuture });

    const [startDateInput, setStartDateInput] = useState(start);
    const [endDateInput, setEndDateInput] = useState(end);

    const [startHour, setStartHour] = useState(start ? moment(start).hour() : 0);
    const [endHour, setEndHour] = useState(end ? moment(end).hour() : 0);
    const [isCompare, setIsCompare] = useState(compare);

    const [date1, setDate1] = useState(start ? moment(start) : moment().subtract(1, 'month'));

    const date2Value = end ? moment(end) : moment();
    const [date2, setDate2] = useState(isFutureSelect ? moment(date2Value).add(1, 'month') : date2Value);

    const [isStartFocused, setIsStartFocused] = useState(false);
    const [isEndFocused, setIsEndFocused] = useState(false);
    const [hoverStatus, setHoverStatus] = useState(null);

    const isNearby = useMemo(() => moment(date2).subtract(1, 'month').isSame(moment(date1), 'month'), [date1, date2]);

    const isPreviousPeriodShowed = useMemo(
        () => isCompare && !isCompareHidden && startDate && endDate,
        [startDate, endDate, isCompare]
    );

    const prevEndHour = useRef(endHour);

    const getStartHourItems = () =>
        [...Array(24).keys()].map(hour => ({
            label: padTime(hour),
            value: hour,
            disabled: (() => {
                if (isFutureSelect) return moment().hour() > hour && moment().isSame(moment(startDate), 'days');

                if (availableInterval)
                    return (
                        moment(availableInterval.start).hour() > hour &&
                        moment(availableInterval.start).isSame(moment(startDate), 'days')
                    );

                return moment(startDate).isSame(endDate, 'day') && endHour <= hour;
            })()
        }));

    const getEndHourItems = () =>
        [...Array(24).keys()].map(hour => ({
            label: padTime(hour + 1),
            value: hour === 23 ? 0 : hour + 1,
            disabled: (() => {
                if (isFutureSelect) {
                    return hour < startHour && moment().isSameOrAfter(moment(endDate).subtract(1, 'days'), 'days');
                }

                if (availableInterval) {
                    return (
                        moment(availableInterval.end).hour() < hour + 1 &&
                        (moment(availableInterval.end).isSame(moment(endDate), 'days') ||
                            (moment(availableInterval.end).isSame(moment(endDate).subtract(1, 'days'), 'days') &&
                                endHour !== 0))
                    );
                }

                return (
                    (moment(startDate).isSame(endDate, 'day') ||
                        (moment(startDate).isSame(moment(endDate).subtract(1, 'days'), 'day') && endHour === 0)) &&
                    hour < startHour
                );
            })()
        }));

    const countSelectedDays = Math.ceil(moment(endDate).diff(startDate, 'days', true));
    const isStartDay = moment(startDate).hour() === 0 && moment(endDate).hour() === 0;

    const daysInMoths = moment(startDate).daysInMonth();
    const isFullMonth = daysInMoths === countSelectedDays;
    const isSameMonth = startDate.month() === moment(endDate).subtract(1, 'days').month();

    const startYear = startDate.year();
    const endYear = moment(startDate).add(1, 'year').year();

    const daysInYear = Math.abs(Math.ceil(moment(startYear, 'YYYY').diff(moment(endYear, 'YYYY'), 'days', true)));
    const isFullYear = daysInYear === countSelectedDays;
    const isSameYear = startDate.year() === moment(endDate).subtract(1, 'days').year();

    const startPrevDate = useMemo(() => {
        if (isPreviousPeriodShowed) {
            if (isFullMonth && isSameMonth && isStartDay) return moment(startDate).subtract(1, 'months');

            if (isFullYear && isSameYear && isStartDay) return moment(startDate).subtract(1, 'year');

            const intervalHoursCount = moment(endDate).diff(startDate, 'hours');
            return moment(startDate).subtract(intervalHoursCount, 'hours');
        } else return null;
    }, [startDate, endDate, isCompare]);

    const endPrevDate = useMemo(() => {
        if (isPreviousPeriodShowed) {
            return startDate;
        } else return null;
    }, [startDate, endDate, isCompare]);

    const title = useMemo(() => {
        if (isCompare && !isCompareHidden && startDate && endDate) {
            return `${moment(startPrevDate).format('ll')} (${moment(startPrevDate).format('HH:mm')}) - ${moment(
                endPrevDate
            ).format('ll')} (${moment(endPrevDate).format('HH:mm')})`;
        } else return '';
    }, [startDate, endDate, isCompare]);

    const subtractDay = date => (endHour === 0 ? moment(date).subtract(1, 'days') : date);
    const addDay = date => (endHour === 0 ? moment(date).add(1, 'days') : date);

    useEffect(() => {
        if (moment(startDate).isSameOrAfter(endDate)) {
            if (isFutureSelect) {
                setEndDate(moment(startDate).add(1, 'd'));
            } else {
                setStartDate(moment(endDate).subtract(1, 'd'));
                setDate1(moment(endDate).subtract(1, 'd'));
            }
        }
    }, [startDate]);

    useEffect(() => {
        if (moment(endDate).isSameOrBefore(startDate)) {
            setEndDate(moment(startDate).add(1, 'd'));

            if (!isFutureSelect) setDate2(moment(startDate).add(1, 'd'));
        }
    }, [endDate]);

    useEffect(() => {
        setStartDateInput(startDate.format('L'));
        setEndDateInput(endDate.format('L'));
        if (moment(startDate).isBefore(moment(endDate), 'month')) {
            setDate1(moment(startDate));
            setDate2(moment(endDate));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (!isFutureSelect && moment(date1).isSameOrAfter(moment(date2), 'month')) {
            setDate1(moment(date2).subtract(1, 'month'));
        }
    }, [date1, date2]);

    useEffect(() => {
        onChangeInterval(dateInterval);
    }, [dateInterval]);

    useEffect(() => {
        setIsCompare(compare);
    }, [compare]);

    const handleClick = date => {
        prevEndHour.current = 0;
        const isSameDay = moment().isSame(moment(date), 'day');

        if (
            !startDate ||
            (startDate && endDate && !(moment(startDate).add(1, 'd').isSame(endDate, 'day') && endHour === 0))
        ) {
            if (isFutureSelect) {
                setStartDate(moment(date).set('hour', parseInt(startHour, 10)));
            } else setStartDate(moment(date).startOf('day'));

            if (availableInterval && moment(availableInterval.end).isSame(moment(date), 'days')) {
                if (moment(availableInterval.start).hour() === 24) {
                    setEndDate(moment(date).add(1, 'd').startOf('day'));
                } else {
                    setEndDate(moment(date).set('hour', moment(availableInterval.end).hour()));
                }
            } else setEndDate(moment(date).add(1, 'd').startOf('day'));

            if (isFutureSelect && isSameDay) setStartHour(moment().hour());
            else if (availableInterval) {
                if (moment(availableInterval.start).isSame(moment(date), 'days')) {
                    setStartDate(moment(date).set('hour', moment(availableInterval.start).hour()));
                    setStartHour(moment(availableInterval.start).hour());
                } else setStartHour(0);
            } else setStartHour(0);

            if (availableInterval && moment(availableInterval.end).isSame(moment(date), 'days')) {
                setEndHour(moment(availableInterval.end).hour());
            } else setEndHour(0);
        } else if (moment(date).isBefore(moment(startDate), 'day')) {
            if (isFutureSelect && isSameDay) {
                setStartDate(moment(date).set('hour', moment().hour()));
                setStartHour(moment().hour());
            } else if (availableInterval && moment(availableInterval.start).isSame(moment(date), 'day')) {
                setStartDate(moment(date).set('hour', moment(availableInterval.start).hour()));
                setStartHour(moment(availableInterval.start).hour());
            } else {
                setStartDate(moment(date).set('hour', parseInt(startHour, 10)));
            }
            setEndDate(moment(startDate).add(1, 'd').startOf('day'));
        } else if (moment(date).isAfter(moment(startDate), 'day')) {
            if (availableInterval && moment(availableInterval.end).isSame(moment(date), 'days')) {
                setEndDate(moment(date).set('hour', moment(availableInterval.end).hour()));
                setEndHour(moment(availableInterval.end).hour());
            } else setEndDate(moment(date).add(1, 'd').startOf('day'));
        }
        setHoverStatus(null);
    };

    let timerId;

    const handleHover = date => {
        if (!date) {
            timerId = setTimeout(() => {
                setHoverStatus(null);
            }, 400);
            return;
        }
        if (timerId) clearTimeout(timerId);
        if (moment(startDate).add(1, 'd').isSame(endDate, 'day') && endHour === 0) {
            if (moment(date).isAfter(moment(startDate), 'day')) setHoverStatus('end');
            else if (moment(date).isBefore(moment(startDate), 'day')) setHoverStatus('start');
            else setHoverStatus(null);
        } else {
            setHoverStatus('start');
        }
    };

    const handleChangeStartHour = ({ target: { value: val } }) => {
        setStartHour(+val);
        setStartDate(moment(startDate).set('hour', +val));
    };

    const handleChangeEndHour = ({ target: { value: val } }) => {
        const newHour = +val;
        setEndHour(newHour);
        let newEndDate;
        if (prevEndHour.current === 0 && newHour !== 0) {
            newEndDate = moment(endDate).subtract(1, 'days');
        } else if (prevEndHour.current !== 0 && newHour === 0) {
            newEndDate = moment(endDate).add(1, 'days');
        } else {
            newEndDate = endDate;
        }
        prevEndHour.current = newHour;
        setEndDate(moment(newEndDate).set('hour', newHour));
    };

    const renderButtons = () => (
        <>
            <Btn className="plr15 mr5" onClick={() => onCancel()}>
                {txt.buttons.cancel}
            </Btn>
            <Btn
                className="plr20"
                type="filled"
                disabled={!startDate || !endDate}
                onClick={() => {
                    checkDateRangeLimitsAndRewrite(
                        monthDataStorage,
                        startDate,
                        endDate,
                        (isRewrited, totalStartDate, totalEndDate) => {
                            if (isRewrited) {
                                setEndDate(moment(totalStartDate).add(1, 'd').startOf('day'));
                                setStartDate(totalStartDate);
                                setStartHour(0);

                                toast.warning(txt.toasts.dateRangeLimit);
                            }

                            onChange({
                                start: totalStartDate?.format(),
                                end: totalEndDate?.format(),
                                startPrevDate: startPrevDate?.format(),
                                endPrevDate: endPrevDate?.format(),
                                compare: isCompare
                            });
                        }
                    );
                }}
            >
                {txt.buttons.apply}
            </Btn>
        </>
    );

    const renderPreviousPeriod = () => (
        <>
            {txt.labels.previousPeriod}: <span className="date-picker__previous-period-interval">{title}</span>
        </>
    );

    const handleStartDateFocus = () => {
        setIsStartFocused(true);
        setStartDateInput(moment(startDate).format('L'));
    };

    const handleEndDateFocus = () => {
        setIsEndFocused(true);
        setEndDateInput(moment(subtractDay(moment(endDateInput, 'L'))).format('L'));
    };

    const handleStartDateBlur = () => {
        let newDate;

        if (moment(startDateInput, 'L').isValid()) {
            if (availableInterval && moment(startDateInput, 'L').isBefore(moment(availableInterval.start), 'day')) {
                setStartDateInput(startDate.format('L'));
                return;
            }

            const check1 = isFutureSelect && moment(startDateInput, 'L').isSameOrAfter(moment(), 'days');
            const check2 = !isFutureSelect && moment(startDateInput, 'L').isSameOrBefore(moment(), 'days');

            if (check1 || check2) {
                newDate = moment(startDateInput, 'L').set('hour', parseInt(startHour, 10));
            } else if (isAllowFuture) {
                newDate = moment(startDateInput, 'L').set('hour', parseInt(startHour, 10));
            } else newDate = startDate;

            if (moment(newDate).isSameOrAfter(endDate, 'day')) {
                setEndDate(addDay(moment(newDate)).set('hour', parseInt(endHour, 10)));
            }

            setStartDate(newDate);
        } else {
            setStartDateInput(startDate.format('L'));
        }

        setIsStartFocused(false);
        startDateInputRef.current.blur();
    };

    const handleEndDateBlur = () => {
        let newDate;

        if (moment(endDateInput, 'L').isValid()) {
            const isAfter = moment(endDateInput, 'L').isAfter(moment(startDate).startOf('day'), 'day');

            const check1 = isFutureSelect;
            const check2 = !isFutureSelect && moment(endDateInput, 'L').isSameOrBefore(moment(), 'day');

            if (isAfter) {
                if (
                    availableInterval &&
                    moment(endDateInput, 'L').isAfter(moment(availableInterval.end).subtract(1, 'hour'), 'hour')
                ) {
                    setEndDateInput(moment(subtractDay(endDate)).format('L'));
                    return;
                }

                if (check1 || check2) {
                    newDate = moment(endDateInput, 'L').set('hour', parseInt(endHour, 10));
                } else if (isAllowFuture) {
                    newDate = moment(endDateInput, 'L').set('hour', parseInt(endHour, 10));
                } else {
                    newDate = moment(endDate).subtract(1, 'day');
                }
            } else newDate = moment(startDateInput, 'L').set('hour', parseInt(endHour, 10));

            setEndDate(addDay(newDate));
        } else {
            setEndDateInput(endDate.format('L'));
        }

        setIsEndFocused(false);
        endDateInputRef.current.blur();
    };

    const handleKeyPressed = (e, handleDateBlur) => {
        if (e.key === KEYBOARD_SERVICE_KEY.ENTER) handleDateBlur();
    };

    return (
        <div className="date-picker">
            <div className="date-picker__header">
                <TextInput
                    dataTest="datepicker_start-date-input"
                    className={cn('mr5', { 'date-picker-text-input--active': hoverStatus === 'start' })}
                    value={isStartFocused ? startDateInput : moment(startDate).format('ll')}
                    disabled={!startDate}
                    onChange={e => setStartDateInput(e.target.value)}
                    onFocus={handleStartDateFocus}
                    onBlur={handleStartDateBlur}
                    onKeyPress={e => handleKeyPressed(e, handleStartDateBlur)}
                    ref={startDateInputRef}
                />
                <SelectInput
                    dataTest="datepicker_start-hour-select-input"
                    onChange={handleChangeStartHour}
                    value={startHour}
                    items={getStartHourItems()}
                    disabled={!startDate}
                    short
                />
                <div className="pl5 pr5 date-picker__header--gray">—</div>
                <TextInput
                    dataTest="datepicker_end-date-input"
                    className={cn('mr5', { 'date-picker-text-input--active': hoverStatus === 'end' })}
                    value={isEndFocused ? endDateInput : moment(subtractDay(endDate)).format('ll')}
                    disabled={!endDate}
                    onChange={e => setEndDateInput(e.target.value)}
                    onFocus={handleEndDateFocus}
                    onBlur={handleEndDateBlur}
                    onKeyPress={e => handleKeyPressed(e, handleEndDateBlur)}
                    ref={endDateInputRef}
                />
                <SelectInput
                    dataTest="datepicker_end-hour-select-input"
                    onChange={handleChangeEndHour}
                    value={endHour}
                    items={getEndHourItems()}
                    disabled={!endDate}
                    short
                />
            </div>
            <div className="date-picker__previous-period">
                {isCompare && !isCompareHidden && startDate && endDate && renderPreviousPeriod()}
            </div>

            <div className="date-picker__calendars">
                <Calendar
                    date={date1}
                    setDate={setDate1}
                    allowNext={!isNearby}
                    allowPrev={isFutureSelect ? moment(date1).isAfter(moment(), 'months') : true}
                    startDate={startDate}
                    endDate={endDate}
                    startPrevDate={startPrevDate}
                    endPrevDate={endPrevDate}
                    onClick={handleClick}
                    onHover={handleHover}
                    limitRange={limitRange}
                    isFutureSelect={isFutureSelect}
                    availableInterval={availableInterval}
                    isAllowFuture={isAllowFuture}
                />
                <Calendar
                    date={date2}
                    setDate={setDate2}
                    allowPrev={!isNearby}
                    startDate={startDate}
                    endDate={endDate}
                    startPrevDate={startPrevDate}
                    endPrevDate={endPrevDate}
                    onClick={handleClick}
                    onHover={handleHover}
                    isFutureSelect={isFutureSelect}
                    availableInterval={availableInterval}
                    isAllowFuture={isAllowFuture}
                />
            </div>

            {!isHideFooter ? (
                <div className="date-picker__footer j46">
                    {!isCompareHidden ? (
                        <div className="j4">
                            <div className="mr5">
                                <Switcher
                                    dataTest="datepicker_compare"
                                    label={txt.labels.compare}
                                    isSwitchOn={isCompare}
                                    onChange={() => {
                                        onChangeCompare(!isCompare);
                                        setIsCompare(state => !state);
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="ml-auto">{renderButtons()}</div>
                </div>
            ) : null}
        </div>
    );
};

export default Datepicker;
