import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import Langs from '../../Langs';
import { setLastOpenedSecondMenuItem, getLastOpenedSecondMenuItem, kebabToCamelCase } from '../../Functions/utils';
import { PRIVATE_ACTIONS, USER_ROLES } from '../../Constants';

import { useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetStorageQuery } from '../../redux/api/storeApi';

import Notifications from '../../Modules/Tools/notifications/notifications';
import ConversionUpload from '../../Modules/Tools/conversions-upload/conversions-upload';
import ToolsImport from '../../Modules/Tools/Import/tools-import';
import EventLog from '../../Modules/Tools/event-log/event-log';
import IPUAFiltering from '../../Modules/Tools/ip-ua-filtering/ip-ua-filtering';
import AntiFraudKit from '../../Modules/Tools/AntiFraudKit/AntiFraudKit';
import PrivateElement from '../../Modules/PrivateElement/PrivateElement';
import Integrations from '../../Modules/Tools/integrations/integrations';
import SharedReports from '../../Modules/Tools/shared-reports/shared-reports';
import PageContainer from '../../components/page-container/page-container';
import PageHeader from '../../components/page-header/page-header';
import PageContent from '../../components/page-content/page-content';
import Loader from '../../Modules/Loader';

const TOOLS_COMPONENTS = {
    integrations: () => (
        <PrivateElement forbiddenRoles={[USER_ROLES.USER]} action={PRIVATE_ACTIONS.REDIRECT}>
            <Integrations />
        </PrivateElement>
    ),
    'conversion-upload': ConversionUpload,
    'shared-reports': SharedReports,
    import: () => (
        <PrivateElement forbiddenRoles={[USER_ROLES.USER]} action={PRIVATE_ACTIONS.REDIRECT}>
            <ToolsImport />
        </PrivateElement>
    ),
    'event-log': EventLog,
    'ip-ua-filtering': IPUAFiltering,
    notifications: Notifications,
    'anti-fraud-kit': AntiFraudKit
};

const TOOLS_CHAPTERS = [
    'integrations',
    'conversion-upload',
    'shared-reports',
    'import',
    'event-log',
    'ip-ua-filtering',
    'notifications'
    // 'anti-fraud-kit'
];

const toolsTabConstructor = {
    [USER_ROLES.USER]: ['import', 'integrations', 'event-log']
};

const PageTools = () => {
    const txt = Langs[global.lng];
    const { chapter } = useParams();
    const history = useHistory();

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();

    const toolsChapters = TOOLS_CHAPTERS.filter(el => {
        return !toolsTabConstructor[userSettings?.role]?.includes(el);
    });

    const isCorrectChapter = toolsChapters.includes(chapter);

    useEffect(() => {
        if (chapter && userSettings && isCorrectChapter) {
            const filteredChapters = toolsChapters.filter(el => !toolsTabConstructor[userSettings?.role]?.includes(el));

            if (filteredChapters.includes(chapter)) setLastOpenedSecondMenuItem('tools', chapter);
            else {
                setLastOpenedSecondMenuItem('tools', toolsChapters[0]);
                history.push(`/tools/${toolsChapters[0]}`);
            }
        }
    }, [chapter, userSettings]);

    const ToolsComponent = useMemo(() => TOOLS_COMPONENTS[chapter], [chapter]);

    if (!isUserSettingsLoaded || !isStorageLoaded) return <Loader />;

    if (!chapter || !isCorrectChapter) {
        let lastOpenedItem = getLastOpenedSecondMenuItem('tools');

        if (toolsTabConstructor[userSettings?.role]?.includes(lastOpenedItem)) lastOpenedItem = null;

        return <Redirect to={`/tools/${lastOpenedItem || toolsChapters[0] || '/'}`} />;
    }

    return (
        <PageContainer>
            <PageHeader title={txt.labels.tools} link="tools" items={toolsChapters} />

            <PageContent>
                <ToolsComponent chapter={kebabToCamelCase(chapter)} storage={storage} />
            </PageContent>
        </PageContainer>
    );
};

export default PageTools;
