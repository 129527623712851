import React, { useMemo, useEffect, useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../Langs';
import { INITIAL_SELECTED_METRICS } from '../../Constants/index';
import { getColumns } from '../../components/main-table/services';
import { getParams, getResponseErrorMessage, compare, omitKeys } from '../../Functions/utils';
import { showModal } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';

import { useGetReportQuery, useGetReportChartQuery } from '../../redux/api/reportsApi';

import NewItem from '../../Modules/Items/NewItem/NewItem';
import MainTableItems from '../../Modules/MainTable/MainTableItems/MainTableItems';
import Loader from '../../Modules/Loader';
import TableControls from './components/table-controls/table-controls';
import Charts from '../../Modules/Charts/Charts';
import ColumnsSettings from '../../Modules/Settings/ColumnsSettings/ColumnsSettings';

import './page-report.scss';

const PageReport = ({ report, storage, userSettings, isLoading, setIsLoading, changeReport, onReportClick }) => {
    const txt = Langs[global.lng];

    const [rows, setRows] = useState([]);

    const parameters = {
        [`order-by[${report.sorting.key}]`]: report.sorting.type,
        page: report.pagination.page,
        'per-page': report.pagination.perPage,
        [`filter[dateFrom]`]: exportDateTransformer(report.dateRange.start),
        [`filter[dateTo]`]: exportDateTransformer(report.dateRange.end),
        [`filter[name]`]: report.search,
        ...(report.dateRange.compare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(report.dateRange.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(report.dateRange.endPrevDate)
              }
            : {}),
        ...report.columnFilters.reduce((acc, el) => {
            return {
                ...acc,
                ...(el.minValue ? { [`filter[${el.key}Min]`]: +el.minValue } : {}),
                ...(el.maxValue ? { [`filter[${el.key}Max]`]: +el.maxValue } : {})
            };
        }, {})
    };

    const filtersParams = [
        ...report.filters.map(el => `filter[dtype][]=${el.type}`),
        ...report.filters.map((el, idx) => `filter[did][]=${idx > 0 ? el.value : el?.value?.join(',')}`)
    ].join('&');

    const filterGroups = ['level1', 'level2', 'level3'];

    report.groups.forEach((el, idx) => {
        parameters[`filter[${filterGroups[idx]}]`] = el;
    });

    const {
        data: reportData,
        isSuccess: isReportDataLoaded,
        isError: isReportDataError,
        error: reportDataError,
        isFetching: isReportDataFetching
    } = useGetReportQuery(getParams(parameters).concat('&', filtersParams));

    useEffect(() => {
        if (isReportDataLoaded) {
            if (!compare(report.pagination, reportData.pagination)) {
                changeReport(reportData.pagination, 'pagination');
            }

            if (report.activeRow) {
                const row = reportData.items?.find(el => el.value === report.activeRow.value);

                if (row && !compare(omitKeys(report.activeRow, ['level']), row)) {
                    changeReport(null, 'activeRow');
                }
            }

            setRows(reportData.items);

            setIsLoading(false);
        }
    }, [reportData]);

    useEffect(() => {
        // if (isReportDataError) window.location.href = 'https://plat.com/report-not-found';
        if (isReportDataError) toast.error(getResponseErrorMessage(reportDataError.data, txt.toasts.saveError));
    }, [isReportDataError]);

    const {
        data: reportChart,
        isSuccess: isReportChartLoaded,
        isFetching: isReportChartFetching
    } = useGetReportChartQuery(getParams(parameters).concat('&', filtersParams), {
        skip: report.status?.mode !== 'charts'
    });

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                changeReport(null, [
                    { prop: 'sorting', value: data },
                    { prop: 'pagination', value: { page: 1, perPage: 50, pages: 1 } }
                ]);
                break;

            case 'columnFilters': {
                changeReport(null, [
                    { prop: 'columnFilters', value: data },
                    { prop: 'pagination', value: { page: 1, perPage: 50, pages: 1 } }
                ]);
                break;
            }

            case 'columnWidths': {
                changeReport({ ...report.columnsConfig, columnsWidths: data }, 'columnsConfig');
                break;
            }

            default:
                break;
        }
    };

    const [metrics, setMetrics] = useState(INITIAL_SELECTED_METRICS);

    const columns = useMemo(
        () =>
            getColumns(
                'reports',
                { ...storage, customColumns: report.columnsConfig.columns },
                {
                    includeIntegratedCampaignList: true,
                    includeAdditionalRevenue: true
                }
            ).orderedColumns.filter(el => el.key !== 'checked'),
        [report]
    );

    const onRowClick = row => {
        if (report.activeRow) {
            if (report.activeRow.value !== row.value) changeReport(row, 'activeRow');
            else changeReport(null, 'activeRow');
        } else changeReport(row, 'activeRow');
    };

    const onShowSettingsClick = () => {
        showModal({
            title: txt.labels.columnsSettings,
            headerType: 'primary',
            body: prop => (
                <ColumnsSettings
                    {...prop}
                    chapter={'reports'}
                    columns={columns}
                    customSave={data => changeReport({ ...report.columnsConfig, ...data }, 'columnsConfig')}
                    isColoredRows={report.columnsConfig?.isColoredRows}
                    isVerticalBorders={report.columnsConfig?.isVerticalBorders}
                />
            )
        });
    };

    if (isLoading) return <Loader />;

    const setStatus = status => changeReport(status, 'status');

    const isNewItemMode =
        ['create', 'edit', 'duplicate'].includes(report.status?.mode) &&
        ['campaigns', 'flows', 'triggers'].includes(report.status?.type);

    return (
        <div className="page-report">
            {isNewItemMode ? (
                <NewItem chapter={'reports'} status={report.status} setStatus={setStatus} />
            ) : (
                <div className="table-container">
                    <div className={cn('main-table')}>
                        <div className="main-table__wrapper">
                            <TableControls
                                isLoading={isLoading}
                                report={report}
                                rows={rows}
                                activeRow={report.activeRow}
                                columns={columns}
                                configuredColumns={report.columnsConfig.columns}
                                changeReport={changeReport}
                                onReportClick={onReportClick}
                                // reportAvailablePeriod={reportAvailablePeriod}
                                // setPagination={setPagination}
                                // isShowCompare={isShowCompare}
                                // setIsShowCompare={setIsShowCompare}
                                // setReportPeriod={setReportPeriod}
                                // isShowChart={isShowChart}
                                // setIsShowChart={setIsShowChart}
                                onShowSettingsClick={onShowSettingsClick}
                                userSettings={userSettings}
                                storage={storage}
                            />

                            {report.status?.mode === 'charts' && (
                                <Charts
                                    datasets={reportChart?.datasets}
                                    labels={reportChart?.labels}
                                    onChange={data => setMetrics(data)}
                                    metrics={metrics}
                                    isLoading={!isReportChartLoaded || isReportChartFetching}
                                />
                            )}

                            <div className="main-table__visible-content">
                                <MainTableItems
                                    chapter={'reports'}
                                    modifiedChapter={'reports'}
                                    columns={columns}
                                    rows={rows}
                                    activeRow={report.activeRow}
                                    sorting={report.sorting}
                                    total={reportData?.total}
                                    isLoading={isReportDataFetching}
                                    onRowClick={onRowClick}
                                    handleChange={onTableActionsChange}
                                    columnFilters={report.columnFilters}
                                    columnsWidths={report.columnsConfig.columnsWidths}
                                    isColoredRows={report.columnsConfig.isColoredRows}
                                    isVerticalBorders={report.columnsConfig.isVerticalBorders}
                                    setRows={setRows}
                                    onRowContextMenu={() => null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PageReport;
