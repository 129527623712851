import React from 'react';

import { getNotDangerouslyHTML } from '../../../Functions/utils';
import Langs from '../../../Langs';
import Label from '../Forms/Inputs/Label';
import RadioInput from '../Forms/Inputs/RadioInput';
import TextInput from '../Forms/Inputs/TextInput';
import Alert from '../UI/Alert/Alert';
import Btn from '../UI/Btn/Btn';
import CriteriaTabs from './CriteriaTabs';

import './criteria-dialog.scss';

const LOGICAL_RELATIONS = [
    { value: 'and', label: 'logicalAnd', hint: 'logicalAnd' },
    { value: 'or', label: 'logicalOr', hint: 'logicalOr', hintType: 'left' }
];

export const getHtmlTitle = (criteria, logicalRelation) => {
    const txt = Langs[global.lng];
    const result = [];

    criteria
        .filter(({ isHidden }) => !isHidden)
        .filter(({ value }) => !!(Array.isArray(value) ? value.length : value))
        .forEach(({ label, getCount, value, items, isMatched }) => {
            const count = getCount && getCount(value, items);
            result.push(
                ` <span class="criteria-dialog__title--${isMatched ? 'success' : 'danger'}">${
                    txt.labels[label] || label
                } ${count ? `(${count})` : ''}</span> `
            );
        });

    const title = result.join(
        `<span class="criteria-dialog__title--html">${
            txt.labels[logicalRelation === 'and' ? 'ampersand' : 'or']
        }</span>`
    );

    const placeholder = title.replace(/<[^>]*>?/gm, '').replace(/\s+/gm, ' ');

    return { title, placeholder };
};

const CriteriaDialog = ({ value: { rule, activeTabId = 'geo' }, onChange, onClose, onApply, criteriaDialogRef }) => {
    const txt = Langs[global.lng];

    const { criteria, name, logicalRelation } = rule;
    const { title, placeholder } = getHtmlTitle(criteria, logicalRelation);

    const onItemsChange = (data, prop) => onChange(data, prop, rule.value);

    const onConfirm = () => {
        const getCondition = item => {
            if (item?.key === 'timeTime') return item?.from && item?.to;
            if (item?.key === 'timeTimezone') return typeof item?.value === 'string' && item?.value?.length;
            return Array.isArray(item?.value) && item?.value?.length;
        };

        const newValues = criteria?.map(item => (getCondition(item) ? item : { ...item, isEnabled: false }));

        onItemsChange(newValues, 'criteria');
        onApply();
    };

    return (
        <div className="criteria-dialog" ref={criteriaDialogRef}>
            <div className="criteria-dialog__wrapper">
                <Alert
                    className="criteria-dialog__header"
                    type="warning"
                    LeftComponent={
                        <div
                            className="criteria-dialog__title"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: getNotDangerouslyHTML(name) || title || txt.labels.newRule
                            }}
                        />
                    }
                />

                <div className="criteria-dialog__body">
                    <TextInput
                        dataTest="criteria-dialog_rule-name-input"
                        label={txt.labels.ruleName}
                        value={name}
                        placeholder={placeholder || 'newRule'}
                        onChange={({ target: { value } }) => onItemsChange(value, 'name')}
                        hint={txt.hints.ruleName}
                        className="mb15"
                    />

                    <div className="j4">
                        <Label className="mr20" label={txt.labels.logicalRelation} />
                        <RadioInput
                            dataTest="criteria-dialog_logical-relation"
                            type="row"
                            value={logicalRelation}
                            onChange={({ target: { value } }) => onItemsChange(value, 'logicalRelation')}
                            items={LOGICAL_RELATIONS}
                        />
                    </div>

                    <CriteriaTabs criteria={criteria} onChange={onItemsChange} activeTabId={activeTabId} />
                </div>

                <div className="criteria-dialog__footer">
                    <Btn dataTest="criteria-dialog_cancel-btn" onClick={onClose}>
                        {txt.buttons.cancel}
                    </Btn>

                    <Btn dataTest="criteria-dialog_apply-btn" type="filled" onClick={onConfirm}>
                        {txt.buttons.apply}
                    </Btn>
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef((props, ref) => <CriteriaDialog {...props} criteriaDialogRef={ref} />);
