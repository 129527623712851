import React from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import useToggle from '../../../../Functions/hooks/useToggle';

import Icon from '../../Icon';
import Btn from '../Btn/Btn';
import CopyBtn from '../CopyBtn/CopyBtn';

import './copy-field.scss';

const CopyField = ({ type, children, copyText, className, isExpanded, short, dataTest }) => {
    const txt = Langs[global.lng];
    const { isToggled: isOpened, toggle: toggleIsOpen } = useToggle();

    return (
        <div
            className={cn('copy-field', className, {
                'copy-field--short-copy': short?.copy,
                'copy-field--short-expand': short?.expand,
                'copy-field--open': isOpened
            })}
        >
            {(!type || type !== 'children') && isExpanded ? (
                <textarea
                    data-test={`copy-field_${dataTest}_textarea`}
                    ref={elem => {
                        if (elem) elem.style.height = isOpened ? elem.scrollHeight + 'px' : '32px';
                    }}
                    value={copyText}
                    onChange={() => ({})}
                    spellCheck="false"
                />
            ) : (
                children
            )}
            <div className="copy-field__button-group">
                {isExpanded && (
                    <Btn
                        dataTest={`copy-field_${dataTest}_btn-expand`}
                        className="copy-field__button-expand"
                        type="link"
                        onClick={toggleIsOpen}
                    >
                        {!short?.expand ? txt.buttons[isOpened ? 'collapse' : 'expand'] : ''}
                        {isOpened ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                    </Btn>
                )}
                <CopyBtn dataTest={`copy-field_${dataTest}_btn-copy`} copyText={copyText} isLabelHidden={short?.copy} />
            </div>
        </div>
    );
};

export default CopyField;
