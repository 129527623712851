import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import ClearModal from '../../../../Modules/Modals/clear-statistics-modal/clear-statistics-modal';

const ClearButton = ({ chapter, activeRow, dateRange, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: txt.titles.clearElement,
                body: props => <ClearModal {...props} dateRange={dateRange} activeRow={activeRow} />
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__clear-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.clear}
        </Btn>
    );
};

export default ClearButton;
