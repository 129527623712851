import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { PASSWORD_MIN_LENGTH } from '../../../Constants';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { hideModal } from '../../../redux/operations';
import useFocus from '../../../Functions/hooks/useFocus';

import { useChangeUserPasswordMutation } from '../../../redux/api/settingsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Loader from '../../Loader';

import './change-password-modal.scss';

const ChangePasswordModal = ({ onClose, onTouch }) => {
    const txt = Langs[global.lng];

    const [isNeedValidate, setIsNeedValidate] = useState(false);

    const [fields, setFields] = useState({
        oldPassword: '',
        password: '',
        confirmPassword: ''
    });

    const [oldPasswordInputRef, setOldPasswordFocus] = useFocus();

    useEffect(() => {
        if (!fields.oldPassword) setTimeout(setOldPasswordFocus, 0);
    }, []);

    const errors = {
        oldPassword: fields.oldPassword.trim().length ? '' : txt.errors.invalidPassword,
        password:
            fields.password.trim().length && fields.password?.length >= PASSWORD_MIN_LENGTH
                ? ''
                : txt.errors.min(txt.labels.password, PASSWORD_MIN_LENGTH),
        confirmPassword:
            fields.confirmPassword.trim().length && fields.confirmPassword === fields.password
                ? ''
                : txt.errors.same(txt.labels.newPassword)
    };

    const isAllValid = Object.values(errors).every(el => !el);

    const onChange = (prop, value) => {
        setFields(state => ({ ...state, [prop]: value }));
    };

    const [
        changePassword,
        {
            isLoading: isChangePasswordLoading,
            isSuccess: isChangePasswordSuccess,
            isError: isChangePasswordError,
            error: changePasswordError
        }
    ] = useChangeUserPasswordMutation();

    useEffect(() => {
        if (isChangePasswordSuccess) {
            toast.success(txt.toasts.passwordChanged);
            hideModal();
        }
    }, [isChangePasswordSuccess]);

    useEffect(() => {
        if (isChangePasswordError) toast.error(getResponseErrorMessage(changePasswordError.data, txt.toasts.saveError));
    }, [isChangePasswordError]);

    const onModalConfirm = () => {
        setIsNeedValidate(true);
        if (isAllValid) changePassword(fields);
    };

    useEffect(() => {
        onTouch(Object.values(fields).some(el => !!el));
    }, [fields]);

    return (
        <>
            <div className="change-password-modal">
                <TextInput
                    dataTest="change-password-edit_old-password-input"
                    ref={oldPasswordInputRef}
                    label={txt.labels.oldPassword}
                    type="password"
                    icon="password"
                    value={fields.oldPassword}
                    className="mb15"
                    required
                    onChange={({ target: { value } }) => onChange('oldPassword', value)}
                    error={isNeedValidate && errors.oldPassword}
                />

                <TextInput
                    dataTest="change-password-edit_new-password-input"
                    label={txt.labels.newPassword}
                    type="password"
                    icon="password"
                    value={fields.password}
                    className="mb15"
                    required
                    onChange={({ target: { value } }) => onChange('password', value)}
                    error={isNeedValidate && errors.password}
                />

                <TextInput
                    dataTest="change-password-edit_confirm-new-password-input"
                    label={txt.labels.confirmNewPassword}
                    type="password"
                    icon="password"
                    value={fields.confirmPassword}
                    className="mb15"
                    required
                    onChange={({ target: { value } }) => onChange('confirmPassword', value)}
                    error={isNeedValidate && errors.confirmPassword}
                />

                {isChangePasswordLoading && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                isConfirmDisabled={isNeedValidate && !isAllValid}
                onConfirm={onModalConfirm}
                onReject={onClose}
            />
        </>
    );
};

export default ChangePasswordModal;
