import React from 'react';

export const TdProgressBar = ({ positive, negative }) => (
    <div className="td-progress-bar">
        {positive + negative}
        <div className="td-progress-bar__progress">
            <div
                className="td-progress-bar__progress--negative"
                style={{
                    width: `${(negative / (positive + negative)) * 100}%`
                }}
            />
        </div>
    </div>
);
