import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import AdditionalRevenueModal from '../../../../Modules/Modals/additional-revenue-modal/additional-revenue-modal';

const AdditionalRevenueButton = ({ chapter, activeRow, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: txt.titles.additionalRevenue,
                body: props => <AdditionalRevenueModal {...props} item={activeRow} />
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__additional-revenue-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.additionalRevenue}
        </Btn>
    );
};

export default AdditionalRevenueButton;
