import React from 'react';

import Langs from '../../../../Langs';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';

const ReportButton = ({ chapter, className, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    return (
        <Btn
            dataTest={`table-control__report-btn--${chapter}`}
            className={className}
            type={type}
            disabled={isDisabled}
            onClick={onClick}
        >
            {txt.buttons.report}
        </Btn>
    );
};

export default ReportButton;
