import React from 'react';

import Langs from '../../../../Langs';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';

const MultiReportButton = ({ chapter, isDisabled, onClick, type, countRowsForReport }) => {
    const txt = Langs[global.lng];

    return (
        <Btn
            dataTest={`table-control__multi-report-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onClick}
        >
            {txt.buttons.multipleReport}({countRowsForReport})
        </Btn>
    );
};

export default MultiReportButton;
