import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import produce from 'immer';
import { toast } from 'react-toastify';
import { Trash2, Plus } from 'react-feather';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import {
    useChangeCampaignsAdditionalRevenueMutation,
    useGetCampaignsAdditionalRevenueQuery
} from '../../../redux/api/campaignsApi';
import { getResponseErrorMessage } from '../../../Functions/utils';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';

import './additional-revenue-modal.scss';

const AdditionalRevenueModal = ({ item, onClose }) => {
    const txt = Langs[global.lng];

    const [revenueItems, setRevenueItems] = useState([{ id: 'initial', month: null, additionalRevenue: null }]);
    const [isTouch, setIsTouch] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const { data: revenueData, isSuccess: isRevenueDataLoaded } = useGetCampaignsAdditionalRevenueQuery(item.value);

    useEffect(() => {
        if (isRevenueDataLoaded && revenueData?.length) setRevenueItems(revenueData);
    }, [revenueData]);

    const [
        changeRevenue,
        {
            isError: isChangeAdditionalRevenueError,
            error: changeAdditionalRevenueError,
            isSuccess: isChangeAdditionalRevenueSuccess
        }
    ] = useChangeCampaignsAdditionalRevenueMutation();

    useEffect(() => {
        if (isChangeAdditionalRevenueSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isChangeAdditionalRevenueSuccess]);

    useEffect(() => {
        if (isChangeAdditionalRevenueError) {
            toast.error(getResponseErrorMessage(changeAdditionalRevenueError.data, txt.errors.saveError));
            setIsProcessing(false);
        }
    }, [isChangeAdditionalRevenueError]);

    const montsList = useMemo(() => {
        const result = [];

        for (let i = 1; i <= 12; i++) {
            const startValue = moment().subtract(i, 'month');
            const currentYear = startValue.year();

            const isContains = revenueItems.some(el => el.month === startValue.format('YYYY-MM'));

            if (i === 1) {
                result.push({
                    label: currentYear,
                    items: [
                        {
                            label: startValue.format('MMMM, YYYY'),
                            value: startValue.format('YYYY-MM'),
                            disabled: isContains
                        }
                    ]
                });

                // eslint-disable-next-line no-continue
                continue;
            }

            const index = result.findIndex(el => el.label === currentYear);

            if (index === -1) {
                result.push({
                    label: currentYear,
                    items: [
                        {
                            label: startValue.format('MMMM, YYYY'),
                            value: startValue.format('YYYY-MM'),
                            disabled: isContains
                        }
                    ]
                });
            } else {
                result[index].items.push({
                    label: startValue.format('MMMM, YYYY'),
                    value: startValue.format('YYYY-MM'),
                    disabled: isContains
                });
            }
        }

        return result;
    }, [revenueItems]);

    const isAllValid = !revenueItems.some(el => !el.additionalRevenue || !el.month);
    const isNotRevenue = revenueData?.length === 0;

    const onAddRevenueClick = () => {
        setRevenueItems(
            produce(draft => {
                draft.push({ id: `initial${revenueItems.length + 1}`, month: null, additionalRevenue: null });
            })
        );
    };

    const onRemoveRevenueClick = id => {
        setRevenueItems(
            produce(draft => {
                if (draft.length === 1) draft.splice(0, 1, { id: 'initial', month: null, additionalRevenue: null });
                else {
                    const index = draft.findIndex(el => el.id === id);
                    if (index !== -1) draft.splice(index, 1);
                }
            })
        );
    };

    const onSelectMonth = (id, value) => {
        setRevenueItems(
            produce(draft => {
                const index = draft.findIndex(el => el.id === id);
                draft[index].month = value;
            })
        );
    };

    const onRevenueValueChange = (id, value) => {
        setRevenueItems(
            produce(draft => {
                const isValid = new RegExp('^[0-9]*[.,]?[0-9]*$').test(value);
                if (!isValid || value?.length > 10) return;

                const index = draft.findIndex(el => el.id === id);
                draft[index].additionalRevenue = value;
            })
        );
    };

    const onConfirm = () => {
        setIsTouch(true);

        if (!isAllValid && isNotRevenue) return;

        setIsProcessing(true);

        changeRevenue({
            campaignId: item.value,
            data: {
                additionalRevenues: revenueItems
                    .filter(el => el.month)
                    .map(el => ({
                        ...el,
                        additionalRevenue: parseFloat(el.additionalRevenue)
                    }))
            }
        });
    };

    const sortedRevenueItems = useMemo(() => {
        return [...revenueItems].sort((a, b) => {
            const valueA = a.month ? moment(a.month, 'YYYY-MM').format('X') : 0;
            const valueB = b.month ? moment(b.month, 'YYYY-MM').format('X') : 0;

            if (valueA < valueB) return 1;
            if (valueA > valueB) return -1;
            return 0;
        });
    }, [revenueItems]);

    return (
        <>
            <div className="additional-revenue-modal">
                {isRevenueDataLoaded ? (
                    <>
                        <div className="additional-revenue-modal__title">
                            <span>{txt.singulars.campaigns}:</span>
                            {item.displayName}
                        </div>

                        <div className="additional-revenue-modal__container additional-revenue-modal__container--title">
                            <div className="additional-revenue-modal__container-block">Month</div>
                            <div className="additional-revenue-modal__container-block">
                                {txt.titles.additionalRevenue}
                            </div>
                            <div className="additional-revenue-modal__container-block" />
                        </div>

                        {sortedRevenueItems.map(el => (
                            <div className="additional-revenue-modal__container" key={el.id}>
                                <div className="additional-revenue-modal__container-block">
                                    <SelectInput
                                        items={montsList}
                                        value={el.month}
                                        onChange={({ target: { value } }) => onSelectMonth(el.id, value)}
                                        error={isTouch && isNotRevenue && !el.month}
                                    />
                                </div>
                                <div className="additional-revenue-modal__container-block">
                                    <TextInput
                                        value={el.additionalRevenue}
                                        onChange={({ target: { value } }) => onRevenueValueChange(el.id, value)}
                                        error={isTouch && isNotRevenue && !el.additionalRevenue}
                                        placeholder={'e.g. 500'}
                                    />
                                </div>
                                <div className="additional-revenue-modal__container-block">
                                    <div
                                        className="additional-revenue-modal__icon"
                                        onClick={() => onRemoveRevenueClick(el.id)}
                                    >
                                        <Trash2 size={16} stroke="#F06D8D" />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="j4 additional-revenue-modal__add-btn" onClick={onAddRevenueClick}>
                            <Plus size={16} className="mr4" />
                            {txt.buttons.addMonth}
                        </div>
                    </>
                ) : (
                    <Loader />
                )}

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onReject={onClose}
                isConfirmDisabled={isProcessing}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default AdditionalRevenueModal;
