export const billingAndSubscriptionCols = {
    invoices: [
        {
            key: 'createdAt',
            type: 'dateOnly',
            label: 'Date'
        },
        // {
        //     key: 'baseAmount',
        //     label: 'Amount',
        //     type: 'price',
        //     summaryClassName: 'text-default-light'
        // },
        // {
        //     key: 'overAmount',
        //     label: 'Tax',
        //     type: 'price',
        //     summaryClassName: 'text-default-light'
        // },
        {
            key: 'totalAmount',
            label: 'Total',
            type: 'price'
        },
        {
            key: 'status',
            label: 'Status',
            type: 'status'
        },
        {
            key: 'actions',
            type: 'actions',
            label: ''
        }
    ],
    invoiceDetails: [{ key: 'label' }, { key: 'value' }]
};
