import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getLandingProtectScript } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import {
    useChangeLandingProtectionScriptTypeMutation,
    useGetLandingProtectionTypeQuery,
    useGetTrackingUrlsQuery
} from '../../../redux/api/projectApi';

import ClipboardField from '../../Overall/Forms/ClipboardField';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Loader from '../../Loader';
import CopyBtn from '../../Overall/UI/CopyBtn/CopyBtn';
import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';

import './tracking-urls.scss';

const radioData = {
    id: 'chooseScriptType',
    items: [
        {
            label: 'PHP (Recommended)',
            value: 'php'
        },
        {
            label: 'Javascript',
            value: 'javascript'
        }
    ]
};

const textareaData = {
    id: 'clickIds',
    placeholder: 'Placeholder'
};

const TrackingURLs = () => {
    const txt = Langs[global.lng];

    const { data: landingProtection, isSuccess: isLandingProtectionLoaded } = useGetLandingProtectionTypeQuery();
    const { data: trackingUrls, isSuccess: isTrackingUrlsLoaded } = useGetTrackingUrlsQuery();

    const [
        changeScriptType,
        {
            isLoading: isChangeScriptTypeLoading,
            isSuccess: isChangeScriptTypeSuccess,
            isError: isChangeScriptTypeError,
            error: changeScriptTypeError
        }
    ] = useChangeLandingProtectionScriptTypeMutation();

    useEffect(() => {
        if (isChangeScriptTypeSuccess) toast.success(txt.toasts.changed);
    }, [isChangeScriptTypeSuccess]);

    useEffect(() => {
        if (isChangeScriptTypeError) {
            toast.error(getResponseErrorMessage(changeScriptTypeError.data, txt.errors.changeError));
        }
    }, [isChangeScriptTypeError]);

    const landingProtectionScript = getLandingProtectScript(
        landingProtection?.landingProtectionScriptType === 'javascript',
        landingProtection?.landingProtectionKey
    );

    const sectionClickHeader = <SectionHeader title={txt.labels.clickForLanding} />;
    const sectionS2SHeader = <SectionHeader title={txt.labels.s2sPostback} />;
    const sectionLandingHeader = <SectionHeader title={txt.labels.landingPageProtection} />;

    const isLoaded = isLandingProtectionLoaded && isTrackingUrlsLoaded;

    return !isLoaded ? (
        <Loader />
    ) : (
        <>
            <Section Header={sectionClickHeader} className="mb25">
                <div className="tracking-urls__text">{txt.texts.clicksAreUsed}</div>

                <div className="mtb8">
                    <JustAlert type={JUST_ALERT_TYPE.INFO}>
                        <div>
                            <div>
                                <b>{txt.settings.examples}:</b>
                            </div>

                            <div>
                                &lt;button href=&apos;http://
                                <span className="settings-section--accent">your_tracker_domain.com</span>
                                /click/1&apos;&gt;
                            </div>

                            <div>
                                &lt;button href=&apos;http://
                                <span className="settings-section--accent">your_tracker_domain.com</span>
                                /click/2&apos;&gt;
                            </div>

                            <div>
                                &lt;button href=&apos;http://
                                <span className="settings-section--accent">your_tracker_domain.com</span>
                                /click/3&apos;&gt;
                            </div>

                            <div className="mt8">
                                <b>Important: </b>
                                <span className="settings-section--accent">
                                    Always check that the domain you are using matches the domain in your Campaign.ss
                                </span>
                            </div>
                        </div>
                    </JustAlert>
                </div>

                <ClipboardField
                    dataTest="tracking-urls_click-url"
                    label={txt.labels.clickUrl}
                    value={trackingUrls.clickUrl && decodeURI(trackingUrls.clickUrl)}
                />

                <ClipboardField
                    dataTest="tracking-urls_multi-offer"
                    className="mt10"
                    label={txt.labels.multiOffer}
                    value={trackingUrls.multiOfferClickUrl && decodeURI(trackingUrls.multiOfferClickUrl)}
                />
            </Section>

            <Section Header={sectionS2SHeader} className="mb25">
                <div className="tracking-urls__text">{txt.texts.serverToServerPostback}</div>

                <div className="mtb8">
                    <JustAlert type={JUST_ALERT_TYPE.INFO}>
                        <div>
                            <div>
                                <b>{txt.texts.s2sPostbackSteps}:</b>
                            </div>

                            <div>
                                <ul className="tracking-urls__list">
                                    <li>
                                        {txt.settings.s2sPostbackUrl}
                                        <br />
                                        http://<span className="settings-section--accent">your_tracker_domain.com</span>
                                        /postback?cid=
                                        <b>sub1</b>
                                    </li>

                                    <li>
                                        {txt.settings.s2sPostbackUrlMultipleParameters}
                                        <br />
                                        http://<span className="settings-section--accent">your_tracker_domain.com</span>
                                        /postback?cid=
                                        <b>sub1</b>&payout=<b>price</b>&txid=<b>tx_id</b>&et=<b>event_name</b>
                                    </li>

                                    <li>{txt.texts.copyPostbackUrl}</li>
                                </ul>
                            </div>

                            <div className="mt8">
                                <b>Note: </b>
                                <span className="settings-section--accent">
                                    Always verify that you have appended the click ID token to your offer URL:
                                    http://my_offer_URL.com/?sub1=<b>&#123;click_id&#125;</b>
                                    <br />
                                    If there is no click ID, your conversions will not register.
                                </span>
                            </div>
                        </div>
                    </JustAlert>
                </div>

                <ClipboardField
                    dataTest="tracking-urls_postback-url"
                    label={txt.labels.postbackUrlShouldLook}
                    value={trackingUrls.postbackUrl && decodeURI(trackingUrls.postbackUrl)}
                />

                {/* <ClipboardField
                    dataTest="tracking-urls_secure-postback-url"
                    className="mt10"
                    label={txt.labels.securePostbackUrl}
                    value={securePostbackUrl}
                /> */}
            </Section>

            {/* <div className="section--shadowed mb25">
                <SettingsSection className="wide" title={txt.labels.conversionTrackingPixel}>
                    <div>{txt.texts.conversionTrackingPixel}</div>
                    <div className="mtb8">
                        <Alert message={txt.settings.conversionTrackingPixelInfo} type={ALERT_TYPE.INFO} />
                    </div>
                    <div className="mb10">{txt.texts.conversionTrackingPixelNote}</div>
                    <ClipboardField
                        dataTest="tracking-urls_conversion-tracking-pixel-url"
                        label={txt.labels.conversionTrackingPixelUrl}
                        value={conversionTrackingPixelUrl}
                    />
                    <ClipboardField
                        dataTest="tracking-urls_conversion-tracking-pixel"
                        label={txt.labels.conversionTrackingPixel}
                        value={conversionTrackingPixel}
                    />
                    <ClipboardField
                        dataTest="tracking-urls_conversion-tracking-script"
                        label={txt.labels.conversionTrackingScript}
                        value={conversionTrackingScript}
                    />
                </SettingsSection>
            </div> */}

            <Section Header={sectionLandingHeader} className="mb25">
                <div className="mtb8">
                    <JustAlert type={JUST_ALERT_TYPE.INFO}>{txt.settings.landingPageProtectionInfo}</JustAlert>
                </div>

                <div className="col mb15 mt10">
                    <RadioInput
                        dataTest="tracking-urls_choose-script"
                        items={radioData.items}
                        type="row"
                        onChange={({ target: { value } }) => changeScriptType(value)}
                        id={radioData.id}
                        label={txt.labels.chooseScript}
                        value={landingProtection.landingProtectionScriptType}
                        disabled={isChangeScriptTypeLoading}
                    />
                </div>

                <div className="mb15 mt10">
                    <TextInput
                        dataTest="tracking-url_landing-page-protection-textarea"
                        label={txt.labels.copyScript}
                        id={textareaData.id}
                        value={landingProtectionScript}
                        placeholder={textareaData.placeholder}
                        type="textarea"
                        textRight={
                            <CopyBtn
                                dataTest="tracking-url_landing-page-protection_btn-copy"
                                copyText={landingProtectionScript}
                            />
                        }
                        readOnly
                    />
                </div>

                <div className="mt10">
                    <JustAlert>{txt.alerts.landingPageProtectionWarnFirst}</JustAlert>
                </div>

                <div className="mt10">
                    <JustAlert>{txt.alerts.landingPageProtectionWarnSecond}</JustAlert>
                </div>
            </Section>
        </>
    );
};

export default TrackingURLs;
