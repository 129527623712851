import React from 'react';
import cn from 'classnames';

import Icon from '../../Icon';
import Btn from '../../UI/Btn/Btn';

export const TdDelete = ({ onClick, type = 'button', dataTest, disabled }) => {
    if (type === 'button') {
        return (
            <Btn
                className={cn({ 'btn--disabled': disabled })}
                dataTest={`td-actions_${dataTest}_delete-btn`}
                type="icon"
                onClick={onClick}
                disabled={disabled}
            >
                <Icon.Trash2 className="td-actions--delete" />
            </Btn>
        );
    }
    return (
        <span data-test="tdDelete_delete-icon" className="td-actions td-actions--delete" onClick={onClick}>
            <Icon.Trash2 />
        </span>
    );
};
