import React from 'react';
import Langs from '../../../../../Langs';
import SelectInput from '../../../Forms/Inputs/SelectInput/SelectInput';

import './time-zone.scss';

export const getTimeZoneData = ({ value }) => value;

const TimeZone = ({ onChange, value, items }) => {
    const txt = Langs[global.lng];

    return (
        <div className="time-zone">
            <SelectInput
                dataTest="timezone_select-input"
                label={txt.labels.timeZone}
                value={value}
                items={items}
                onChange={({ target: { value: timezone } }) => onChange(timezone, 'value')}
                placeholder={txt.labels.selectTimeZone}
                className="mt8"
                isSearchable
            />
        </div>
    );
};

export default TimeZone;
