import React, { useState } from 'react';

import AddPaymentBillingInfo from './modals/add-payment-billing-info/add-payment-billing-info';
import AddPaymentPayment from './modals/add-payment-payment/add-payment-payment';

import './add-payment-method-modal.scss';

const AddPaymentMethodModal = ({ onClose, checkInvoiceDetails, invoiceDetails, userEmail, isIntegrated, onAdded }) => {
    const [isBillingStep, setisBillingStep] = useState(checkInvoiceDetails && !invoiceDetails);

    return isBillingStep ? (
        <AddPaymentBillingInfo
            onClose={onClose}
            onConfirm={isOk => {
                if (isOk) setisBillingStep(false);
            }}
        />
    ) : (
        <AddPaymentPayment onClose={onClose} userEmail={userEmail} isIntegrated={isIntegrated} onAdded={onAdded} />
    );
};

export default AddPaymentMethodModal;
