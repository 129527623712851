import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetGeneralSettingsQuery } from '../redux/api/settingsApi';
import { camelToKebabCase } from '../Functions/utils';

import Loader from '../Modules/Loader';

const StartRedirect = () => {
    const history = useHistory();

    const getHomePagePath = homeScreen => {
        const DEFAULT_HOME_PAGES = {
            dashboard: '/home/dashboard',
            campaign_list: '/home/campaigns',
            last_opened_report: (() => {
                const lastOpenedGlobalReport = localStorage.getItem('lastOpenedGlobalReport');
                return lastOpenedGlobalReport ? '/home/' + camelToKebabCase(lastOpenedGlobalReport) : '/home/campaigns';
            })()
        };

        return homeScreen && Object.keys(DEFAULT_HOME_PAGES).includes(homeScreen)
            ? DEFAULT_HOME_PAGES[homeScreen]
            : '/home/campaigns';
    };

    const { data: generalSettings, isSuccess } = useGetGeneralSettingsQuery();

    useEffect(() => {
        if (isSuccess) history.push(getHomePagePath(generalSettings.defaultHomeScreen));
    }, [isSuccess]);

    return <Loader />;
};

export default StartRedirect;
