import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, VERIFY_STATUS, USER_ROLES, WORKSPACE_TYPE } from '../../../Constants';
import { getCreatedUpdatedUserNames, getUrlError } from '../../../redux/operations';
import { compare, getResponseErrorMessage } from '../../../Functions/utils';
import useToggle from '../../../Functions/hooks/useToggle';
import useScrollTo from '../../../Functions/hooks/useScrollTo';

import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import {
    useCheckLanderVerifyMutation,
    useGetLanderByIdQuery,
    useGetLandersUrlParamsQuery
} from '../../../redux/api/landersApi';
import { useGetCountriesQuery } from '../../../redux/api/systemApi';
import { useGetDefaultDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useGetLandingProtectionTypeQuery } from '../../../redux/api/projectApi';
import { useGetWorkspacesAllNamesQuery } from '../../../redux/api/workspacesApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import Btn from '../../Overall/UI/Btn/Btn';
import CreatedUpdatedUserInfo from '../../Overall/CreatedUpdatedUserInfo/CreatedUpdatedUserInfo';
import LanderSettings from './components/lander-settings';
import LanderSetup from './components/lander-setup';

import landerTemplate from './lander-template';

import './create-lander.scss';

const CreateLander = ({ id, onConfirm, onTouch, onClose, mode }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const contentRef = useRef();
    const scrollTo = useScrollTo(contentRef);

    const [lander, setLander] = useState({ ...landerTemplate });
    const [isLoading, setIsLoading] = useState(!!id);
    const [isFetching, setIsFetching] = useState(false);
    const originalEditableLander = useRef({ ...landerTemplate });
    const { isToggled: isNeedValidate, toggleOn: setIsNeedValidate } = useToggle();

    const updateLander = useCallback(updatedFields => {
        setLander(prevOffer => ({ ...prevOffer, ...updatedFields }));
    }, []);

    const [isVerifying, setIsVerifying] = useState(false);

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: landerTokens, isSuccess: isLanderTokensLoaded } = useGetLandersUrlParamsQuery();
    const { data: landingProtection, isSuccess: isLandingProtectionLoaded } = useGetLandingProtectionTypeQuery();
    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();
    const { data: landerData, isSuccess: isLanderDataLoaded } = useGetLanderByIdQuery(id, { skip: !id });

    useEffect(() => {
        (async () => {
            if (isLanderDataLoaded) {
                const createdUpdatedUserNames = await getCreatedUpdatedUserNames(landerData, dispatch);

                const isNeedReplaceWorkspaceId =
                    mode === ENTITY_MODE_STATUS.DUPLICATE &&
                    userSettings.role !== USER_ROLES.OWNER &&
                    landerData.workspaceId === userSettings.publicWorkspaceId;

                const resultLander = {
                    ...landerData,
                    value: mode === ENTITY_MODE_STATUS.DUPLICATE ? null : landerData.value,
                    workspaceId: isNeedReplaceWorkspaceId ? userSettings.initialWorkspaceId : landerData.workspaceId,
                    ...createdUpdatedUserNames,
                    ...(mode === ENTITY_MODE_STATUS.DUPLICATE ? { name: `${landerData.name} Copy` } : {}),
                    ...(mode === ENTITY_MODE_STATUS.EDIT && landerData.isArchived ? { isRestore: true } : {})
                };

                setLander(resultLander);
                originalEditableLander.current = resultLander;
                setIsLoading(false);
            }
        })();
    }, [landerData]);

    const { data: defaultTrackingDomain, isSuccess: isDefaultTrackingDomainLoaded } =
        useGetDefaultDomainsByTypeQuery('tracking');

    useEffect(() => {
        if (isUserSettingsLoaded && isWorkspacesListLoaded && !lander.workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            updateLander({ workspaceId: initialWorkspaceId });
            originalEditableLander.current = { ...originalEditableLander.current, workspaceId: initialWorkspaceId };
        }
    }, [userSettings, workspacesList]);

    const isCreateMode = mode === ENTITY_MODE_STATUS.CREATE;

    const isAllValid = useMemo(() => {
        let isValid = lander.name?.trim() && !getUrlError(lander.landerUrl);
        if (lander.isMatchLanderUrl) isValid = isValid && !getUrlError(lander.clickUrlPage);
        return isValid;
    }, [lander.name, lander.isMatchLanderUrl, lander.clickUrlPage, lander.landerUrl]);

    const [verify, { data: verifyData, isSuccess: isVerifySuccess, isError: isVerifyError, error: verifyError }] =
        useCheckLanderVerifyMutation();

    useEffect(() => {
        if (isVerifySuccess) {
            setIsVerifying(false);
            setLander({ ...lander, verify: verifyData });
        }
    }, [isVerifySuccess]);

    useEffect(() => {
        if (isVerifyError) {
            toast.error(getResponseErrorMessage(verifyError.data, verifyData?.error || txt.errors.error));
            setIsVerifying(false);
        }
    }, [isVerifyError]);

    const refreshVerification = () => {
        setIsVerifying(true);

        verify({
            data: {
                ...(id ? { id } : {}),
                landerUrl: lander.landerUrl,
                numberOfOffers: lander.offersNum,
                isClickUrlNotMatchLanderUrl: lander.isMatchLanderUrl,
                clickUrl: lander.clickUrlPage,
                isClickUrlClassic: lander.isClickUrlClassic,
                isRedirectlessScript: lander.hasRedirectlessScript,
                isLandingPageProtected: lander.hasProtectLandingPage
            }
        });
    };

    const hasVerificatedUrls = !!lander.verify?.statuses?.some(el => el.status !== VERIFY_STATUS.NOT_VERIFIED);

    const getConfirmButtonText = () => {
        const isHideSaveButtons =
            mode === ENTITY_MODE_STATUS.EDIT &&
            userSettings.role === USER_ROLES.USER &&
            landerData?.workspaceId === userSettings.publicWorkspaceId;

        if (isHideSaveButtons) return null;
        if (mode === ENTITY_MODE_STATUS.EDIT && lander?.isArchived) return txt.buttons.saveAndRestore;
        if (id) return txt.buttons.save;
        return txt.buttons.apply;
    };

    useEffect(() => {
        onTouch(!compare(lander, originalEditableLander.current));
    }, [lander]);

    const onModalConfirm = async () => {
        setIsNeedValidate();
        scrollTo();

        if (isAllValid) {
            setIsFetching(true);

            const ok = await onConfirm(lander);
            if (!ok) setIsFetching(false);

            return ok;
        }
    };

    const filteredWorkspaceList = useMemo(() => {
        if (workspacesList && userSettings?.role === USER_ROLES.USER) {
            return workspacesList.map(el => {
                if (el.workspaceType === WORKSPACE_TYPE.PUBLIC) return { ...el, disabled: true };
                return el;
            });
        }

        return workspacesList;
    }, [workspacesList, userSettings]);

    const isLoaded =
        isUserSettingsLoaded &&
        isCountriesLoaded &&
        isDefaultTrackingDomainLoaded &&
        isLanderTokensLoaded &&
        isLandingProtectionLoaded &&
        isWorkspacesListLoaded &&
        (id ? isLanderDataLoaded : true);

    return (
        <>
            <div className="create-lander" ref={contentRef}>
                {isLoading || !isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <LanderSettings
                            workspaceId={lander.workspaceId}
                            landerTags={lander.tags}
                            landerName={lander.name}
                            landerUrl={lander.landerUrl}
                            countryTag={lander.countryTag}
                            updateLander={updateLander}
                            isCreateMode={isCreateMode}
                            isNeedValidate={isNeedValidate}
                            workspacesList={filteredWorkspaceList}
                            landerTokens={landerTokens}
                            countries={countries}
                        />

                        <LanderSetup
                            offersNum={lander.offersNum}
                            verifyStatusess={lander.verify}
                            hasProtectLandingPage={lander.hasProtectLandingPage}
                            hasRedirectlessScript={lander.hasRedirectlessScript}
                            isMatchLanderUrl={lander.isMatchLanderUrl}
                            clickUrlPage={lander.clickUrlPage}
                            campaignsCount={lander.campaignsCount}
                            defaultTrackingDomain={defaultTrackingDomain}
                            landingProtection={landingProtection}
                            updateLander={updateLander}
                            isNeedValidate={isNeedValidate}
                        />
                    </>
                )}

                {isFetching && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={getConfirmButtonText()}
                isConfirmDisabled={isNeedValidate && !isAllValid}
                isLoading={isLoading}
                leftSettings={
                    <>
                        <Btn
                            className="btn--verification-status"
                            onClick={refreshVerification}
                            isLoading={isVerifying}
                            disabled={isLoading}
                        >
                            {txt.buttons.verifyURLsAndScripts}
                        </Btn>
                        <div className="click-url-verification-status">
                            <>
                                <div className="click-url-verification-status__title">
                                    {txt.labels.lastVerification}
                                </div>
                                <div
                                    className={cn('click-url-verification-status__body', {
                                        'color--froly': !hasVerificatedUrls
                                    })}
                                >
                                    {hasVerificatedUrls
                                        ? moment(lander.verify.date?.split('+')?.[0]).format('L LT')
                                        : txt.labels.notVerifiedYet}
                                </div>
                            </>
                        </div>
                        {id && lander?.createdAtUserId ? (
                            <CreatedUpdatedUserInfo
                                createdUserName={lander.createdUserName}
                                updatedUserName={lander.updatedUserName}
                                createdAt={lander.createdAt}
                                updatedAt={lander.updatedAt}
                                isTheSameUser={lander.createdAtUserId === lander.updatedAtUserId}
                                isTheSameDate={lander.createdAt === lander.updatedAt}
                            />
                        ) : null}
                    </>
                }
                onConfirm={onModalConfirm}
                onReject={onClose}
            />
        </>
    );
};

export default CreateLander;
