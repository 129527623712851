import React, { useCallback, useRef, useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import Langs from '../../../Langs';
import { Dispatch } from '../../../redux/rsd';
import { showModal, hideModal } from '../../../redux/operations';
import { KEYBOARD_SERVICE_KEY, MODAL_TYPES } from '../../../Constants';
import useKeyPress from '../../../Functions/hooks/useKeyPress';

import { CloseBtn } from '../UI/Btn/Btn';
import ModalButtonBar from './ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';

import './modal.scss';

export const modalForcedStyles = {
    position: 'fixed',
    'z-index': '1000',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'background-color': 'rgba(30, 30, 45, 0.55)'
};

const Modal = ({ modal, modalIndex, modalLength }) => {
    const {
        title,
        className,
        body: ModalBody,
        modalBodyProps,
        headerType,
        message,
        type,
        id = type || 'modal',
        warningModalConfig,
        confirmButtonText,
        confirmButtonType,
        noHeader,
        HeaderLeftItem,
        HeaderRightItem,
        onReject,
        onClose: customOnClose,
        force,
        noClose
    } = modal;

    const txt = Langs[global.lng];

    const isTouched = useRef(false);
    const innerRef = useRef();

    useEffect(() => {
        if (modalIndex === modalLength - 1) {
            innerRef.current?.focus();
        }
    }, [modalLength]);

    useEffect(() => {
        if (force) {
            const forcedStyles = Object.entries(modalForcedStyles);
            forcedStyles.forEach(([key, value]) => {
                innerRef.current?.style.setProperty(key, value, 'important');
            });
        }
    }, [force]);

    const onClose = useCallback(() => {
        if (noClose) return;

        if (warningModalConfig && isTouched.current) {
            showModal(warningModalConfig);
        } else {
            if (onReject) onReject();
            hideModal();
        }
    }, [warningModalConfig, isTouched.current, onReject]);

    const setIsTouched = useCallback(
        (status = true) => {
            isTouched.current = status;
        },
        [isTouched.current]
    );

    useKeyPress(
        KEYBOARD_SERVICE_KEY.ESCAPE,
        () => {
            setTimeout(customOnClose || onClose, 0);
        },
        innerRef.current
    );

    return (
        <>
            <div
                className="modal2"
                ref={innerRef}
                onMouseDown={type !== 'loading' ? customOnClose || onClose : () => {}}
                role="button"
                data-id={id}
                tabIndex={0}
            >
                {type === MODAL_TYPES.LOADING ? (
                    <Loader className={'loader-box_white'} />
                ) : (
                    <div className={cn('modal2__content', className)} onMouseDown={e => e.stopPropagation()}>
                        {noHeader ? null : (
                            <div className={cn('modal2__header', headerType && 'modal2__header--' + headerType)}>
                                {HeaderLeftItem ?? <span className="modal2__title">{txt.titles[title] || title}</span>}
                                <div className="modal__header--right-item">
                                    {HeaderRightItem ? <HeaderRightItem /> : null}
                                    {!noClose ? (
                                        <CloseBtn
                                            dataTest="modal_close-btn"
                                            className="modal2__btn-close"
                                            onClick={customOnClose || onClose}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        )}

                        <div className="modal2__body" style={noHeader ? { border: 0 } : {}}>
                            {ModalBody ? (
                                <ModalBody {...modalBodyProps} onClose={onClose} onTouch={setIsTouched} />
                            ) : (
                                <>
                                    <p className="modal2__message">
                                        {message && typeof message === 'function' ? message() : message}
                                    </p>
                                    <ModalButtonBar
                                        rejectButtonText="cancel"
                                        confirmButtonText={confirmButtonText}
                                        confirmButtonType={confirmButtonType}
                                        onConfirm={() => {
                                            onReject?.();
                                            hideModal();
                                            hideModal();
                                        }}
                                        onReject={() => hideModal()}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default connect(null, Dispatch)(Modal);
