import React from 'react';

import Langs from '../../../Langs';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

const ColumnsField = ({ field, onChange, mode, isLoading }) => {
    const txt = Langs[global.lng];

    const { label, isHide, key, important, disabled, description } = field;

    const change = ({ target: { checked } }) => {
        if (!important && !disabled) onChange(checked, field);
    };

    return (
        <div className={`columns-settings__row ${mode === 'small' && 'columns-settings__row--small'}`}>
            <CheckBoxInput
                dataTest={`columns-field_${key}-checkbox`}
                label={
                    txt.columnsLabels[key] || label + (field.isIntegrationCell ? ` (${txt.labels.integration})` : '')
                }
                onChange={change}
                value={!isHide}
                disabled={important || disabled || isLoading}
            />

            {mode !== 'small' && (
                <label
                    htmlFor={key}
                    className="columns-description pl15"
                    onClick={() => change({ target: { checked: isHide } })}
                >
                    {txt.labels.columnsSettingsDesc[key] || description}
                </label>
            )}
        </div>
    );
};

export default ColumnsField;
