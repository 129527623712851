import React from 'react';
import cn from 'classnames';

import Langs from '../../../../../Langs';
import { getSharedReportLink } from '../../../../../Functions/utils';
import useCopyToggle from '../../../../../Functions/hooks/useCopyToggle';

import Icon from '../../../../Overall/Icon';

import './copy-block.scss';

const CopyBlock = ({ row, className }) => {
    const txt = Langs[global.lng];
    const [isCopied, setIsCopied] = useCopyToggle();

    const onCopyClick = e => {
        e.stopPropagation();

        navigator.clipboard.writeText(getSharedReportLink(row.value)).then(() => {
            setIsCopied(true);
        });
    };

    return (
        <div className={cn('copy-block', className)}>
            <span className="copy-block__icon">
                <Icon.Link size={16} color={'#2680C1'} />
            </span>

            <div className="copy-block__content">
                <span className="copy-block__text">
                    {isCopied ? `${txt.buttons.copied}` : `${txt.buttons.clickToCopyLink}`}
                </span>

                <div className="copy-block__copy-icon" onClick={onCopyClick}>
                    <Icon.Link size={16} />
                </div>
            </div>
        </div>
    );
};

export default CopyBlock;
