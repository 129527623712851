import React from 'react';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';

import Langs from '../../../Langs';
import { ALERT_TYPE, BADGE_TYPE } from '../../../Constants/index';
import { showByValue, compare, capitalize, clone } from '../../../Functions/utils';
import { getActualDateRange, hideModal } from '../../../redux/operations';

import { useChangeReportsMutation } from '../../../redux/api/reportsApi';

import reportTemplate from '../../../Pages/page-report/report-template';

import Alert from '../../Overall/UI/Alert/Alert';
import BadgeRound from '../../Overall/UI/BadgeRound/BadgeRound';
import ActualWeightsModalOffer from './ActualWeightsModalOffer';
import Label from '../../Overall/Forms/Inputs/Label';

const ActualWeightsModalLander = ({
    lander,
    offers,
    forwardedRef,
    className,
    setIsOpened,
    currentCampaignInfo,
    pathInfo,
    storage,
    countries,
    currencies,
    reports
}) => {
    const txt = Langs[global.lng];
    const history = useHistory();
    const location = useLocation();

    const { name: landerName, optimizedOffers, countryTag, value: landerId } = lander || {};

    const { dateRange } = storage?.common || {};

    const [changeReport] = useChangeReportsMutation();

    const onReportClick = (e, itemType, itemInfo) => {
        e.stopPropagation();

        const actualDateRange = getActualDateRange(dateRange);

        const similarReport = reports.find(el => {
            return (
                el.filters.length === 3 &&
                el.filters[0].type === 'campaigns' &&
                compare(el.filters[0].value, [currentCampaignInfo.id]) &&
                el.filters[1].type === 'paths' &&
                el.filters[1].value === pathInfo.id &&
                el.filters[2].type === itemType &&
                el.filters[2].value === itemInfo.id &&
                moment(getActualDateRange(el.dateRange).start).diff(actualDateRange.start, 'minutes') === 0 &&
                moment(getActualDateRange(el.dateRange).end).diff(actualDateRange.end, 'minutes') === 0
            );
        });

        const isReport = location.pathname.split('/')[1] === 'reports';
        const reportValue = location.pathname.split('/')[2];

        if (isReport && similarReport?.value === reportValue) {
            changeReport(reportValue, { mode: 'default', value: null, type: null }, 'status');
            hideModal();
            return;
        }

        if (similarReport) {
            if (!similarReport.bookmark.isActive) {
                const result = clone(reports);
                const index = result.findIndex(el => el.value === similarReport.value);
                if (index === -1) return;

                result[index].bookmark.isActive = true;

                changeReport(result);
            }

            hideModal();
            history.push(similarReport.bookmark.path);
            return;
        }

        const value = uuid();
        const path = `/reports/${value}`;

        changeReport([
            ...reports,
            {
                ...reportTemplate,
                value,
                dateRange,
                filters: [
                    { type: 'campaigns', value: [currentCampaignInfo.id] },
                    { type: 'paths', value: pathInfo.id, label: pathInfo.name },
                    { type: itemType, value: itemInfo.id, label: itemInfo.name }
                ],
                groups: [itemType === 'offers' ? 'landers' : 'offers'],
                bookmark: {
                    id: value,
                    label: capitalize(currentCampaignInfo.name),
                    name: capitalize(currentCampaignInfo.name),
                    path,
                    isFavorite: false,
                    isActive: true,
                    icon: 'campaigns'
                }
            }
        ]);

        hideModal();
        history.push(path);
    };

    return (
        <Alert
            type={ALERT_TYPE.SUCCESS}
            className={cn(className)}
            ref={forwardedRef}
            onChange={() => setIsOpened(isOpened => !isOpened)}
            LeftComponent={
                <div className="flow-path__landers-offers">
                    <span>{`${showByValue(countries, countryTag)} - ${landerName}`}</span>

                    {lander.isArchived && (
                        <BadgeRound
                            text={txt.labels.archived}
                            type={BADGE_TYPE.DANGER}
                            className="badge-round--clear-border ml5"
                        />
                    )}

                    <div
                        className="actual-weights-modal__report-btn"
                        onClick={e => onReportClick(e, 'landers', { id: landerId, name: landerName })}
                    >
                        {txt.buttons.report}
                    </div>
                </div>
            }
            RightComponent={<div className="path__alert-action">{lander.optimizedWeight}%</div>}
        >
            <div className="actual-weights-modal__offer-container">
                {optimizedOffers ? (
                    <>
                        <div className="j7">
                            <Label label={txt.labels.offers} className="path__subtitle" />
                        </div>

                        {optimizedOffers.map(offer => {
                            return (
                                <ActualWeightsModalOffer
                                    currencies={currencies}
                                    offers={offers}
                                    offer={offer}
                                    type="offer"
                                    className={cn('alert--weights-modal-offer')}
                                    key={offer.value}
                                    countries={countries}
                                    onReportClick={onReportClick}
                                />
                            );
                        })}
                    </>
                ) : null}
            </div>
        </Alert>
    );
};

export default React.forwardRef((props, ref) => <ActualWeightsModalLander {...props} forwardedRef={ref} />);
