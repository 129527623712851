import React from 'react';

import { random } from '../../../../Functions/utils';

const TablePreloader = ({ columnsLength, columnWidths = [], rowLength = 10 }) => {
    const preloaderColumnWidths = columnWidths.length
        ? [...columnWidths, { width: '100%', key: columnWidths.length }]
        : Array(columnsLength + 1)
              .fill(0)
              .map((el, idx) => ({ width: random(80, 350), key: idx }));

    return new Array(rowLength).fill(null).map((_, idx) => (
        <div key={idx} className="items-table__tr">
            {preloaderColumnWidths.map(({ width, key }) => (
                <div key={key} style={{ width }} className="items-table__td items-table__td--loading" />
            ))}
        </div>
    ));
};

export default TablePreloader;
