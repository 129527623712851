import React from 'react';
import cn from 'classnames';

import TableTdItem from './TableTdItem';
import TableThItem from './TableThItem';

// DEPRECATED: Этот компонент больше не поддерживается, использовать или заменить на <JustTable /> JS\components\just-table\just-table.js

const JustTable = props => {
    const { cols, rows, onChange, onActionClick, overflow, currencies } = props;

    const renderBody = () => {
        if (rows) {
            return (
                <>
                    {rows.map((row, index) => {
                        return (
                            <div
                                key={row.id || index}
                                className={cn(
                                    'table__row listItems-table__line',
                                    row.disabled ? 'table__row--disabled' : ''
                                )}
                            >
                                {cols.map((item, subindex) => (
                                    <div
                                        key={row.id + subindex || `${index} - ${subindex}`}
                                        className={cn(
                                            'table__cell listItems-table__cell',
                                            item.hidden ? 'visibility-hidden' : 'visibility-visible',
                                            item.cellClassName
                                        )}
                                    >
                                        <TableTdItem
                                            params={{
                                                index,
                                                key: item.key,
                                                type: item.type,
                                                columnOptions: cols[subindex]
                                            }}
                                            rows={rows}
                                            onChange={onChange}
                                            onActionClick={onActionClick}
                                            overflow={overflow}
                                            currencies={currencies}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </>
            );
        }
        return null;
    };

    if (!cols) return false;
    return (
        <div className="table table--default">
            <div className="table__row">
                {cols.map((item, index) => (
                    <div
                        className={cn('table__cell table__cell--th', {
                            hidden: item.hidden
                        })}
                        key={index}
                    >
                        <TableThItem item={item} rows={rows} onChange={onChange} />
                    </div>
                ))}
            </div>
            {renderBody()}
        </div>
    );
};

export default JustTable;
