import { transform } from '../transformers';
import indexApi from './indexApi';

export const conversionsApiTagTypes = {
    conversions: 'conversions',
    conversionsLog: 'conversionsLog'
};

export const conversionsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(conversionsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getConversionList: builder.query({
                query: params => `/conversions${params || ''}`,
                transformResponse: response => ({
                    items: response.items.map(el => transform.conversionList.import(el)),
                    pagination: response.pagination
                }),

                providesTags: () => [
                    { type: conversionsApiTagTypes.conversions, id: 'LIST' },
                    { type: conversionsApiTagTypes.conversions, id: 'ALL' }
                ]
            }),

            getConversionLog: builder.query({
                query: ({ id, params }) => `/conversions/log/${id}${params || ''}`,

                providesTags: (result, error, { id }) => [
                    { type: conversionsApiTagTypes.conversionsLog, id },
                    { type: conversionsApiTagTypes.conversionsLog, id: 'ALL' }
                ]
            }),

            deleteConversions: builder.mutation({
                query: data => ({
                    url: `/conversions/delete`,
                    method: 'PUT',
                    body: { conversions: data }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: conversionsApiTagTypes.conversions, id: 'LIST' }];
                    else return [];
                }
            }),

            uploadConversions: builder.mutation({
                query: data => ({
                    url: `/conversions/upload`,
                    method: 'POST',
                    body: { conversions: data }
                })
            })
        })
    });

export const {
    useGetConversionListQuery,
    useGetConversionLogQuery,
    useDeleteConversionsMutation,
    useUploadConversionsMutation
} = conversionsApi;
