import React, { useEffect, useMemo } from 'react';

import Langs from '../../../../../Langs';
import { WORKSPACE_TYPE, USER_ROLES } from '../../../../../Constants/index';
import { showModal } from '../../../../../redux/operations';
import { getOnlyASCIICodeText, omitKeys, clone } from '../../../../../Functions/utils';

import useFocus from '../../../../../Functions/hooks/useFocus';

import { useGetUserSettingsQuery } from '../../../../../redux/api/settingsApi';

import ActionsGroup from '../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import Label from '../../../../Overall/Forms/Inputs/Label';
import SelectInput from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../Overall/Forms/Inputs/TextInput';
import SelectTable from '../../../../Overall/SelectTable/SelectTable';
import Alert from '../../../../Overall/UI/Alert/Alert';
import FlowCampaignsListItem from './FlowCampaignsListItem/FlowCampaignsListItem';
import { AddBtn } from '../../../../Overall/UI/Btn/Btn';

import './flow-main-option.scss';

const FlowMainOption = ({
    values: { workspaceId, /*group,*/ name, campaigns, campaignsToAdd },
    campaignsInFlow,
    onChange,
    isNeedValidate,
    mode,
    trackingMethod,
    flowsList,
    workspacesList,
    onWorkspaceChange,
    children,
    entityId
}) => {
    const txt = Langs[global.lng];

    const { data: userSettings } = useGetUserSettingsQuery();

    const transformedCampaings = campaigns.map(item => ({
        ...item,
        ...campaignsInFlow.find(el => el.value === item.value)
    }));

    const [flowNameRef, setFlowNameFocus] = useFocus();

    useEffect(() => {
        if (mode === 'create' && !name) setFlowNameFocus();
    }, []);

    const showSelectItemsModal = () => {
        const handleConfirm = data => {
            onChange(
                [
                    ...campaignsToAdd,
                    ...campaignsInFlow
                        .filter(item => data.includes(item.value))
                        .map(item => ({
                            ...item,
                            action: 'add',
                            actionFlowId: null,
                            isDeleted: false
                        }))
                ],
                'campaignsToAdd'
            );
            return true;
        };

        const rowsForModal = campaignsInFlow
            .filter(el => el.workspaceId === workspaceId)
            .filter(
                item =>
                    !campaigns.find(el => el.value === item.value) &&
                    !campaignsToAdd.find(el => el.value === item.value)
            )
            .map(item => {
                const { rotation } = item;
                return {
                    ...omitKeys(item, ['rotation']),
                    rotationName: rotation.flowName
                        ? `${txt.labels.flow}: ${rotation.flowName}`
                        : txt.texts.campaignSettings,
                    rotationId: rotation.flowId
                };
            });

        // const fullNamesCampaigns = campaigns.map(campaign => ({
        //     ...campaign,
        //     name: `${trafficSources.find(el => el.value === campaign.trafficSource)?.name} - ${
        //         countries.find(el => el.value === campaign.country)?.label
        //     } - ${campaign.name}`
        // }));

        showModal({
            title: 'chooseCampaign',
            className: 'flow-main-option__add-campaigns-modal relative',
            body: props => (
                <SelectTable
                    {...props}
                    type="campaignsForFlow"
                    rows={rowsForModal}
                    selectedIds={[]}
                    onConfirm={handleConfirm}
                    activeTrackingMethod={trackingMethod.value}
                />
            )
        });
    };

    const changeFlowCampaign = campaign => {
        const index = campaigns.findIndex(item => item.value === campaign.value);

        const newValues = [...campaigns];
        newValues.splice(index, 1, clone(campaign));

        onChange(newValues, 'campaigns');
    };

    const handleNameChange = ({ target: { value } }) => {
        const transformedValue = getOnlyASCIICodeText(value?.trimStart());
        onChange(transformedValue || '', 'name');
    };

    useEffect(() => {
        if (userSettings && workspacesList && !workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            onChange(initialWorkspaceId, 'workspaceId');
        }
    }, [userSettings, workspacesList]);

    const filteredWorkspaceList = useMemo(() => {
        if (workspacesList && userSettings?.role === USER_ROLES.USER) {
            return workspacesList.map(el => {
                if (el.workspaceType === WORKSPACE_TYPE.PUBLIC) return { ...el, disabled: true };
                return el;
            });
        }

        return workspacesList;
    }, [workspacesList, userSettings]);

    return (
        <div className="new-item__content-main campaign-main-option">
            <div className="campaign-main-option__content">
                <Label label={txt.labels.mainOptions} className="campaign-main-option__title" />

                <div className="campaign-main-option__line">
                    <Label label={txt.labels.name} />

                    <TextInput
                        dataTest="flow-main-option_name-input"
                        value={name}
                        ref={flowNameRef}
                        onChange={handleNameChange}
                        placeholder={txt.placeholders.typeName}
                        error={isNeedValidate && !name?.trim() ? txt.errors.typeNameForFlow : ''}
                    />
                </div>

                <div className="campaign-main-option__line">
                    <Label label={txt.labels.workspace} hint={txt.hints.workspace(txt.singulars.flows)} />

                    <SelectInput
                        dataTest="flow-main-option_workspace-select-input"
                        value={workspaceId}
                        items={filteredWorkspaceList}
                        onChange={({ target: { value } }) => onWorkspaceChange('flows', value)}
                    />
                </div>

                {/* <div className="campaign-main-option__line">
                    <Label label={txt.labels.group} hint={txt.hints.countryTag} />
                    <SelectInput
                        dataTest="flow-main-option_group-select-input"
                        value={group}
                        items={groups}
                        onChange={e => onChange(e, 'group')}
                    />
                </div> */}

                <Label label={txt.labels.campaigns} className="campaign-main-option__title" />

                {transformedCampaings.map(campaign => (
                    <FlowCampaignsListItem
                        key={campaign.value}
                        currentFlow={entityId}
                        campaign={campaign}
                        onChange={changeFlowCampaign}
                        flows={flowsList}
                        useLocalActionsSate
                    />
                ))}

                {campaignsToAdd?.length ? (
                    <>
                        <Label
                            label={txt.labels.addedNow}
                            className="campaign-main-option__title campaign-main-option__title-added-now mt8"
                        />
                        {campaignsToAdd?.map(campaign => (
                            <Alert
                                key={campaign.value}
                                type="success"
                                LeftComponent={<span>{campaign.displayName}</span>}
                                RightComponent={
                                    <ActionsGroup
                                        onDelete={() =>
                                            onChange(
                                                [...campaignsToAdd?.filter(item => item.value !== campaign.value)],
                                                'campaignsToAdd'
                                            )
                                        }
                                    />
                                }
                            />
                        ))}
                    </>
                ) : (
                    <Alert className="mt10" message={txt.jsx.addCampaignsInfo()} />
                )}

                <div>
                    <AddBtn
                        dataTest="flow-main-option_add-campaign-btn"
                        type="filled"
                        className="mt20"
                        onClick={showSelectItemsModal}
                    >
                        {txt.labels.addCampaigns}
                    </AddBtn>
                </div>
            </div>
            {children}
        </div>
    );
};

export default FlowMainOption;
