import React from 'react';
import cn from 'classnames';
import { Share2 } from 'react-feather';

import Langs from '../../../../Langs';
import { CHAPTER_REPORTS } from '../../../../Constants/index';
import { showModal } from '../../../../redux/operations';
import { capitalize } from '../../../../Functions/utils';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import CreateSharedReportModal from '../../../../Modules/Modals/create-shared-report-modal/create-shared-report-modal';

const ShareButton = ({ chapter, storage, dateRange, isDisabled, isReports, report, reports }) => {
    const txt = Langs[global.lng];

    const name = (() => {
        if (isReports) {
            const bookmarks = reports.map(el => el.bookmark);
            const bookmark = bookmarks.find(el => el.path === `/reports/${report?.value}`);
            return `${bookmark?.label} - ${report?.groups?.map(el => capitalize(el))?.join(' / ')}`;
        } else return txt.labels[chapter];
    })();

    const openModal = () => {
        showModal({
            title: txt.titles.createSharedReport,
            body: props => (
                <CreateSharedReportModal
                    {...props}
                    chapter={chapter}
                    systemDateRange={isReports ? report.dateRange : dateRange}
                    initialName={name}
                    storage={storage}
                    reportGroups={report?.groups}
                    reportFilters={report?.filters}
                />
            )
        });
    };

    return (
        <div className={cn('rel', { ml8: !CHAPTER_REPORTS.includes(chapter) })}>
            <Btn
                dataTest={`table-control__share-btn--${chapter}`}
                className="create-shared-report-button"
                type={isReports ? '' : 'link'}
                onClick={openModal}
                disabled={isDisabled} // isLoading || !rows.length || report?.filters?.length > 1
            >
                <Share2 size={15} />
                {isReports ? txt.buttons.share : null}
            </Btn>

            <div
                className={cn('create-shared-report-button-hint', {
                    'create-shared-report-button-hint--report': isReports
                })}
            >
                {txt.hints.createSharedReport}
            </div>
        </div>
    );
};

export default ShareButton;
