import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getColumns } from '../../../components/main-table/services';
import { showModal } from '../../../redux/operations';
import { getParams, clone, compare, getResponseErrorMessage } from '../../../Functions/utils';

import { useChangeStorageMutation } from '../../../redux/api/storeApi';
import { useDeleteWorkspaceMutation, useGetWorkspacesListQuery } from '../../../redux/api/workspacesApi';

import TableControls from './components/table-controls/table-controls';
import {
    changeMainTablePagination,
    changeMainTableSorting,
    changeMainTableActiveRow,
    selectWorkspacesTableData
} from '../../../redux/main-table-slice';
import GeneralTable from '../../../components/main-table/general-table';
import ArchiveResultModal from '../../Modals/ArchiveResultModal';
import Loader from '../../Loader';

import './workspaces.scss';

const Workspaces = ({ chapter, storage }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const { activeRow, sorting, pagination } = useSelector(selectWorkspacesTableData);

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page']
    };

    const {
        data: workspaces,
        isSuccess: isEventLogsLoaded,
        isFetching: isEventLogsFetching
    } = useGetWorkspacesListQuery(getParams(parameters));

    useEffect(() => {
        if (activeRow) {
            const row = workspaces?.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (workspaces?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: workspaces.pagination
                })
            );
        }
    }, [workspaces]);

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.workspaces.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter,
                value: row.value === activeRow?.value && compare(activeRow?.parents, row.parents) ? null : row
            })
        );
    };

    const [
        deleteWorkspace,
        {
            isLoading: isDeleteWorkspaceLoading,
            isSuccess: isDeleteWorkspaceSuccess,
            isError: isDeleteWorkspaceError,
            error: deleteWorkspaceError
        }
    ] = useDeleteWorkspaceMutation();

    useEffect(() => {
        if (isDeleteWorkspaceSuccess) {
            toast.success(txt.toasts.deleted);

            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: null
                })
            );
        }
    }, [isDeleteWorkspaceSuccess]);

    useEffect(() => {
        if (isDeleteWorkspaceError) {
            toast.error(getResponseErrorMessage(deleteWorkspaceError.data, txt.errors.deleteError));

            if (deleteWorkspaceError.data.errors.options?.isBinded) {
                showModal({
                    title: txt.titles.archivingResult,
                    body: props => (
                        <ArchiveResultModal
                            {...props}
                            subtitle={txt.texts.getCouldNotBeArchivedSubtitle(activeRow?.name)}
                            archiveResultData={deleteWorkspaceError.data.errors?.options}
                        />
                    )
                });
            }
        }
    }, [isDeleteWorkspaceError]);

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = workspaces?.items || [];

    const isLoaded = isEventLogsLoaded && !isEventLogsFetching && !isDeleteWorkspaceLoading;

    return (
        <>
            <div className="table-container simple-main-table workspaces">
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            pagination={pagination}
                            activeRow={activeRow}
                            deleteWorkspace={deleteWorkspace}
                        />

                        <div className="main-table__visible-content">
                            {columns?.length > 0 ? (
                                <GeneralTable
                                    chapter={chapter}
                                    rows={rows}
                                    activeRow={activeRow}
                                    columns={columns}
                                    sorting={sorting}
                                    isLoading={!isLoaded}
                                    storage={storage}
                                    onRowClick={onRowClick}
                                    onRowContextMenu={() => null}
                                    onTableActionsChange={onTableActionsChange}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Workspaces;
