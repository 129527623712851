import React from 'react';
import { useHistory } from 'react-router-dom';
import { RefreshCw, Settings } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../Langs';
import { USER_ROLES, DISPLAY_MODE_STATUS } from '../../../../Constants/index';
import { showModal } from '../../../../redux/operations';
import { capitalize } from '../../../../Functions/utils';

import useDelayedInput from '../../../../Functions/hooks/useDelayedInput';
import useReturnFocus from '../../../../Functions/hooks/useReturnFocus';

import indexApi from '../../../../redux/api/indexApi';
import { useChangeReportsMutation } from '../../../../redux/api/reportsApi';
import { campaignsApiTagTypes } from '../../../../redux/api/campaignsApi';

import {
    changeMainTablePagination,
    changeMainTableSearch,
    changeMainTableChartStatus
} from '../../../../redux/main-table-slice';

import { reportClickHandler } from '../../../../components/table-control-services/table-control-services';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import DateRangeInput from '../../../../Modules/Overall/Forms/Inputs/DateRangeInput';
import TextInput from '../../../../Modules/Overall/Forms/Inputs/TextInput';
import ColumnsSettings from '../../../../Modules/Settings/ColumnsSettings/ColumnsSettings';
import ContextMenu from '../../../../components/context-menu/context-menu';
import SelectInput from '../../../../Modules/Overall/Forms/Inputs/SelectInput/SelectInput';
import CreateButton from '../../../../components/table-control-services/buttons/create-button/create-button';
import EditButton from '../../../../components/table-control-services/buttons/edit-button/edit-button';
import ReportGroupButton from '../../../../components/table-control-services/buttons/report-group-button/report-group-button';
import DuplicateButton from '../../../../components/table-control-services/buttons/duplicate-button/duplicate-button';
import ArchiveButton from '../../../../components/table-control-services/buttons/archive-button/archive-button';
import RestoreButton from '../../../../components/table-control-services/buttons/restore-button/restore-button';
import ChartButton from '../../../../components/table-control-services/buttons/chart-button/chart-button';
import ExportButton from '../../../../components/table-control-services/buttons/export-button/export-button';
import ShareButton from '../../../../components/table-control-services/buttons/share-button/share-button';
import ReportButton from '../../../../components/table-control-services/buttons/report-button/report-button';
import MultiReportButton from '../../../../components/table-control-services/buttons/multi-report-button/multi-report-button';
import ClearButton from '../../../../components/table-control-services/buttons/clear-button/clear-button';
import ArchiveGroupButton from '../../../../components/table-control-services/buttons/archive-group-button/archive-group-button';
import MultiArchiveButton from '../../../../components/table-control-services/buttons/multi-archive-button/multi-archive-button';
import RestoreGroupButton from '../../../../components/table-control-services/buttons/restore-group-button/restore-group-button';
import MultiRestoreButton from '../../../../components/table-control-services/buttons/multi-restore-button/multi-restore-button';
import UpdateCostButton from '../../../../components/table-control-services/buttons/update-cost-button/update-cost-button';
import AdditionalRevenueButton from '../../../../components/table-control-services/buttons/additional-revenue-button/additional-revenue-button';

const TableControls = ({
    chapter,
    isLoading,
    tableParameters,
    dateRange,
    setDateRange,
    displayMode,
    changeDisplayMode,
    rows,
    columns,
    storage,
    reports,
    isAdditionalRevenue,
    generalSettings,
    userSettings,
    contextMenuRef,
    contextMenuSettings
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();
    const history = useHistory();

    const { activeRow } = tableParameters;
    const checkedRows = rows.filter(({ isChecked }) => isChecked).map(({ value }) => value);
    const activeRows = activeRow ? [activeRow?.value] : [];
    const rowsForReport = checkedRows.some(el => el === activeRow?.value)
        ? [...checkedRows]
        : [...checkedRows, ...activeRows];
    const isInPublicWorkspace = rowsForReport.some(
        el => rows.find(it => it.value === el)?.workspaceId === userSettings.publicWorkspaceId
    );

    const [searchText, setSearchText, searchInputRef] = useDelayedInput(value => {
        dispatch(
            changeMainTableSearch({
                chapter,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...tableParameters.pagination, page: 1 }
            })
        );
    }, tableParameters.search);

    const [changeReportQuery] = useChangeReportsMutation();

    useReturnFocus(isLoading, searchInputRef);

    const onShowSettingsClick = () => {
        showModal({
            title: txt.labels.columnsSettings,
            headerType: 'primary',
            body: prop => (
                <ColumnsSettings
                    {...prop}
                    chapter={chapter}
                    columns={columns}
                    isColoredRows={storage.common.isColoredRows}
                    isVerticalBorders={storage.common.isVerticalBorders}
                    storage={storage}
                />
            )
        });
    };

    const onJustButtonReportClick = () => {
        reportClickHandler(chapter, false, {
            generalSettings,
            dateRange,
            reports,
            rows,
            activeRows,
            checkedRows,
            activeRow,
            rowsForReport,
            history,
            changeReport: changeReportQuery
        });
    };

    const onMultiButtonReportClick = () => {
        reportClickHandler(chapter, true, {
            generalSettings,
            dateRange,
            reports,
            rows,
            activeRows,
            checkedRows,
            activeRow,
            rowsForReport,
            history,
            changeReport: changeReportQuery
        });
    };

    return (
        <>
            <TableControlContainer>
                <CreateButton chapter={chapter} className="btn--bold" isDisabled={isLoading} type="filled" />

                <div className="table-control-container__links">
                    <ReportGroupButton
                        chapter={chapter}
                        isDisabled={
                            isLoading ||
                            rowsForReport.length <= 1 ||
                            (rowsForReport.length === 1 && rowsForReport[0] === activeRow?.value)
                        }
                        isHideDisabled={rowsForReport.length >= 2 && !activeRow}
                        isActive={rowsForReport.length}
                        isJustButtonDisabled={isLoading || !rowsForReport.length}
                        isMultiButtonDisabled={isLoading || rowsForReport.length < 2}
                        onJustButtonClick={onJustButtonReportClick}
                        onMultiButtonClick={onMultiButtonReportClick}
                        countRowsForReport={rowsForReport.length}
                        type="link"
                    />

                    <EditButton
                        chapter={chapter}
                        entityId={activeRow?.value}
                        isDisabled={isLoading || !activeRow}
                        type="link"
                    />

                    <DuplicateButton
                        chapter={chapter}
                        entityId={activeRow?.value}
                        isDisabled={isLoading || !activeRow}
                        type="link"
                    />

                    <ClearButton
                        chapter={chapter}
                        activeRow={activeRow}
                        dateRange={dateRange}
                        isDisabled={isLoading || !activeRow}
                        type="link"
                    />

                    {displayMode === DISPLAY_MODE_STATUS.ACTIVE && (
                        <ArchiveGroupButton
                            chapter={chapter}
                            isDisabled={
                                isLoading ||
                                rowsForReport.length <= 1 ||
                                (rowsForReport.length === 1 && rowsForReport[0] === activeRow?.value) ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            isHideDisabled={rowsForReport.length >= 2 && !activeRow}
                            isActive={rowsForReport.length}
                            isJustButtonDisabled={
                                isLoading ||
                                !rowsForReport.length ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            isMultiButtonDisabled={
                                isLoading ||
                                rowsForReport.length < 2 ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            rows={rows}
                            rowsForReport={rowsForReport}
                            entityName={
                                activeRow?.name ??
                                rows.find(el => el.value === rowsForReport[0])?.name ??
                                rowsForReport[0]
                            }
                            entityId={activeRow?.value ?? rowsForReport[0]}
                            type="link"
                            justButtonSelectedRows={
                                activeRows.length ? activeRow : rows.find(it => it.value === checkedRows[0])
                            }
                        />
                    )}

                    {displayMode === DISPLAY_MODE_STATUS.ARCHIVE && (
                        <RestoreGroupButton
                            chapter={chapter}
                            isDisabled={
                                isLoading ||
                                rowsForReport.length <= 1 ||
                                (rowsForReport.length === 1 && rowsForReport[0] === activeRow?.value) ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            isHideDisabled={rowsForReport.length >= 2 && !activeRow}
                            isActive={rowsForReport.length}
                            isJustButtonDisabled={
                                isLoading ||
                                !rowsForReport.length ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            isMultiButtonDisabled={
                                isLoading ||
                                rowsForReport.length < 2 ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            rowsForReport={rowsForReport}
                            rows={rows}
                            entityName={
                                activeRow?.name ??
                                rows.find(el => el.value === rowsForReport[0])?.name ??
                                rowsForReport[0]
                            }
                            entityId={activeRow?.value ?? rowsForReport[0]}
                            type="link"
                            justButtonSelectedRows={
                                activeRows.length ? activeRow : rows.find(it => it.value === checkedRows[0])
                            }
                        />
                    )}

                    <UpdateCostButton
                        chapter={chapter}
                        activeRow={activeRow}
                        isDisabled={isLoading || !activeRow}
                        type="link"
                    />

                    {(userSettings.role === USER_ROLES.OWNER || userSettings.isAllowSharingReport) && (
                        <ExportButton
                            chapter={chapter}
                            className={'table-control_export'}
                            isDisabled={isLoading || !rows.length}
                            type="link"
                            columns={columns}
                            rows={rows}
                            dateRange={dateRange}
                            tableParameters={tableParameters}
                        />
                    )}

                    {isAdditionalRevenue && (
                        <AdditionalRevenueButton
                            chapter={chapter}
                            activeRow={activeRow}
                            isDisabled={isLoading || !activeRow}
                            type="link"
                        />
                    )}

                    <ChartButton
                        chapter={chapter}
                        isDisabled={isLoading || !rows.length}
                        isChartOpen={tableParameters.chartStatus.isOpen}
                        type={tableParameters.chartStatus.isOpen ? 'black-link' : 'link'}
                        onClick={() => {
                            dispatch(
                                changeMainTableChartStatus({
                                    chapter,
                                    value: { isOpen: !tableParameters.chartStatus.isOpen }
                                })
                            );
                        }}
                    />

                    {(userSettings.role === USER_ROLES.OWNER || userSettings.isAllowSharingReport) && (
                        <ShareButton
                            chapter={chapter}
                            storage={storage}
                            dateRange={dateRange}
                            isDisabled={isLoading || !rows.length}
                        />
                    )}
                </div>

                <TextInput
                    dataTest={`table-control-header_${chapter}-search-input`}
                    placeholder={txt.placeholders.search}
                    className="mr10"
                    ref={searchInputRef}
                    value={searchText}
                    onChange={setSearchText}
                    disabled={isLoading}
                    reset
                />

                <SelectInput
                    dataTest={`table-control-header_${chapter}-status-input`}
                    className="status-select-input mr10"
                    value={displayMode}
                    items={Object.values(DISPLAY_MODE_STATUS).map(el => ({
                        value: el,
                        label: capitalize(el)
                    }))}
                    disabled={isLoading}
                    onChange={({ target: { value } }) => changeDisplayMode(value)}
                />

                <DateRangeInput
                    value={dateRange}
                    onChange={data => setDateRange(data)}
                    disabled={isLoading}
                    containerClassName="mr10"
                />

                <Btn
                    dataTest={`table-control_refresh-btn_${chapter}`}
                    className="table-control_export"
                    type="filled"
                    disabled={isLoading}
                    onClick={() =>
                        dispatch(
                            indexApi.util.invalidateTags([
                                { type: campaignsApiTagTypes.campaigns, id: 'LIST' },
                                { type: campaignsApiTagTypes.campaigns, id: 'CHART' }
                            ])
                        )
                    }
                >
                    <RefreshCw />
                    {txt.buttons.refresh}
                </Btn>

                <div className="table-control-container__pagination">
                    <TablePagination
                        value={tableParameters.pagination}
                        disabled={isLoading}
                        onChange={data => {
                            dispatch(
                                changeMainTablePagination({
                                    chapter,
                                    value: data
                                })
                            );
                        }}
                    />

                    <Btn
                        dataTest={`table-control_pagination-settings-btn_${chapter}`}
                        type="icon"
                        onClick={onShowSettingsClick}
                        disabled={isLoading}
                    >
                        <Settings />
                    </Btn>
                </div>
            </TableControlContainer>

            <ContextMenu contextMenuRef={contextMenuRef} contextMenuSettings={contextMenuSettings}>
                <ReportButton
                    chapter={chapter}
                    isDisabled={isLoading || !activeRow}
                    onClick={onJustButtonReportClick}
                    type="link"
                />

                {rowsForReport.length >= 2 && (
                    <MultiReportButton
                        chapter={chapter}
                        isDisabled={isLoading || rowsForReport.length < 2}
                        onClick={onMultiButtonReportClick}
                        countRowsForReport={rowsForReport.length}
                        type="link"
                    />
                )}

                <hr />

                <EditButton
                    chapter={chapter}
                    entityId={activeRow?.value}
                    isDisabled={isLoading || !activeRow}
                    type="link"
                />

                <DuplicateButton
                    chapter={chapter}
                    entityId={activeRow?.value}
                    isDisabled={isLoading || !activeRow}
                    type="link"
                />

                <ClearButton
                    chapter={chapter}
                    activeRow={activeRow}
                    dateRange={dateRange}
                    isDisabled={isLoading || !activeRow}
                    type="link"
                />

                <hr />

                {displayMode === DISPLAY_MODE_STATUS.ACTIVE && (
                    <>
                        <ArchiveButton
                            chapter={chapter}
                            entityId={activeRow?.value ?? rowsForReport[0]}
                            entityName={
                                activeRow?.name ??
                                rows.find(el => el.value === rowsForReport[0])?.name ??
                                rowsForReport[0]
                            }
                            isDisabled={
                                isLoading ||
                                !rowsForReport.length ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            type="link"
                            rows={rows}
                            selectedRows={activeRows.length ? activeRow : rows.find(it => it.value === checkedRows[0])}
                        />

                        {rowsForReport.length >= 2 && (
                            <MultiArchiveButton
                                chapter={chapter}
                                isDisabled={isLoading || rowsForReport.length < 2}
                                countRowsForReport={rowsForReport.length}
                                type="link"
                                rowsForReport={rowsForReport}
                                rows={rows}
                            />
                        )}
                    </>
                )}

                {displayMode === DISPLAY_MODE_STATUS.ARCHIVE && (
                    <>
                        <RestoreButton
                            chapter={chapter}
                            entityId={activeRow?.value ?? rowsForReport[0]}
                            entityName={
                                activeRow?.name ??
                                rows.find(el => el.value === rowsForReport[0])?.name ??
                                rowsForReport[0]
                            }
                            isDisabled={
                                isLoading ||
                                !rowsForReport.length ||
                                (userSettings.role === USER_ROLES.USER && isInPublicWorkspace)
                            }
                            type="link"
                        />

                        {rowsForReport.length >= 2 && (
                            <MultiRestoreButton
                                chapter={chapter}
                                isDisabled={isLoading || rowsForReport.length < 2}
                                type="link"
                                rowsForReport={rowsForReport}
                                rows={rows}
                            />
                        )}
                    </>
                )}

                <UpdateCostButton
                    chapter={chapter}
                    activeRow={activeRow}
                    isDisabled={isLoading || !activeRow}
                    type="link"
                />

                {isAdditionalRevenue && (
                    <AdditionalRevenueButton
                        chapter={chapter}
                        activeRow={activeRow}
                        isDisabled={isLoading || !activeRow}
                        type="link"
                    />
                )}
            </ContextMenu>
        </>
    );
};

export default TableControls;
