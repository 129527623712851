import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import useClickOutside from '../../../Functions/hooks/useClickOutside';
import { setState } from '../../../redux/operations';

import './abs-tooltip.scss';

const AbsTooltip = ({ absTooltip }) => {
    const { body: TooltipBody, props, top = 0, left = 0, clickOutsideCallback, baseContainer } = absTooltip || {};

    const doOutsideEffect = async () => {
        if (clickOutsideCallback) await clickOutsideCallback();
        else setState(null, 'absTooltip');
    };

    const outsideRef = useClickOutside(doOutsideEffect, baseContainer);

    const handleScroll = e => {
        if (outsideRef?.current && !outsideRef.current.contains(e.target)) doOutsideEffect();
        return e;
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, true);
        return () => document.removeEventListener('scroll', handleScroll, true);
    }, []);

    return absTooltip ? (
        <div ref={outsideRef} className="abs-tooltip" style={{ top: `${top}px`, left: `${left}px` }}>
            {<TooltipBody {...props} />}
        </div>
    ) : null;
};

const Store = ({ oldState: { absTooltip } }) => ({ absTooltip });

export default connect(Store)(AbsTooltip);
