import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import CopyField from '.';

const CopyFieldLabeled = ({ field, className, dataTest }) => {
    const containerRef = useRef(null);
    const textRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
        const containerWidth = containerRef?.current?.offsetWidth || 0;
        const textWidth = textRef?.current?.offsetWidth || 0;

        if (textWidth <= containerWidth) setIsExpanded(false);
        else setIsExpanded(true);
    }, []);

    return (
        <CopyField
            dataTest={dataTest}
            copyText={field?.text}
            className={cn('copy-field--labeled', className)}
            isExpanded={isExpanded}
            short={{
                expand: true,
                copy: false
            }}
            type="children"
        >
            <div className="copy-field__inner">
                <div className="copy-field__label">{field?.label}</div>
                <div className="copy-field__text-wrapper" ref={containerRef}>
                    <span ref={textRef}>{field?.text}</span>
                </div>
            </div>
        </CopyField>
    );
};

export default CopyFieldLabeled;
