/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import * as Icon from 'react-feather';

import Langs from '../../../Langs';
import { KEY_CODE } from '../../../Constants';
import { showModal, hideModal } from '../../../redux/operations';
import { compare, getResponseErrorMessage } from '../../../Functions/utils';
import useNewClickOutside from '../../../Functions/hooks/useNewClickOutside';
import useSearch from '../../../Functions/hooks/useNewSearch';

import {
    useAddTagMutation,
    useDeleteTagMutation,
    useEditTagMutation,
    useGetTagsQuery
} from '../../../redux/api/tagsApi';

import HintIcon from '../../../Modules/Overall/Forms/Inputs/HintIcon';
import TextInput from '../../../Modules/Overall/Forms/Inputs/TextInput';
import Loader from '../../../Modules/Loader';
import ConfirmDeleteModal from '../../../Modules/Modals/confirm-delete-modal/confirm-delete-modal';

import './tag-input.scss';

const TAG_COLORS = [
    '#f1f0f0',
    '#e3e2e0',
    '#eedfda',
    '#f9dec9',
    '#fdecc8',
    '#dbecdb',
    '#d3e4ef',
    '#e8deee',
    '#f4e0e9',
    '#ffe2dd',
    '#d3efed',
    '#eceede'
];

// TODO: remove = [] after set default aff.network tags field
const TagInput = ({ tagList = [], onChange, label, hint, hintType, className }) => {
    const txt = Langs[global.lng];
    const componentRef = useRef();
    const inputRef = useRef();

    const [isOpened, setIsOpened] = useState(false);
    const [editableTag, setEditableTag] = useState(null);
    const savedEditableTag = useRef(null);

    const { data: allTags, isFetching: isAllTagsFetching } = useGetTagsQuery();

    const { filteredItems, searchByValue, setSearchByForInput, clearSearchBy } = useSearch(
        allTags ?? [],
        el => el.name
    );

    const tagsToRender = useMemo(() => allTags?.filter(el => tagList.includes(el.id)) ?? [], [tagList, allTags]);

    const [
        addTag,
        {
            data: addedTag,
            isLoading: isAddTagLoading,
            isSuccess: isAddTagSuccess,
            isError: isAddTagError,
            error: addTagError
        }
    ] = useAddTagMutation();

    useEffect(() => {
        if (isAddTagSuccess) {
            toast.success('Tag successfully added');
            onChange([...tagList, addedTag.id]);
            clearSearchBy();
        }
    }, [isAddTagSuccess]);

    useEffect(() => {
        if (isAddTagError) toast.error(getResponseErrorMessage(addTagError.data, 'Tag add error'));
    }, [isAddTagError]);

    const [
        editTag,
        { isLoading: isEditTagLoading, isSuccess: isEditTagSuccess, isError: isEditTagError, error: editTagError }
    ] = useEditTagMutation();

    useEffect(() => {
        if (isEditTagSuccess) toast.success('Tag successfully changed');
    }, [isEditTagSuccess]);

    useEffect(() => {
        if (isEditTagError) toast.error(getResponseErrorMessage(editTagError.data, 'Tag change error'));
    }, [isEditTagError]);

    const [
        deleteTag,
        {
            isLoading: isDeleteTagLoading,
            isSuccess: isDeleteTagSuccess,
            isError: isDeleteTagError,
            error: deleteTagError,
            originalArgs: deleteTagArgs
        }
    ] = useDeleteTagMutation();

    useEffect(() => {
        if (isDeleteTagSuccess) {
            toast.success('Tag successfully deleted');

            const tagIndex = tagList.findIndex(el => el === deleteTagArgs.id);

            if (tagIndex >= 0) {
                const result = [...tagList];
                result.splice(tagIndex, 1);

                onChange(result);
            }

            setEditableTag(null);
            savedEditableTag.current = null;
        }
    }, [isDeleteTagSuccess]);

    useEffect(() => {
        if (isDeleteTagError) toast.error(getResponseErrorMessage(deleteTagError.data, 'Tag delete error'));
    }, [isDeleteTagError]);

    const onChangeTag = () => {
        if (!editableTag) return;

        if (!compare(editableTag.data, savedEditableTag.current)) {
            editTag({
                id: editableTag.data.id,
                data: {
                    name: editableTag.data.name,
                    color: editableTag.data.color
                }
            });
        }

        setEditableTag(null);
        savedEditableTag.current = null;
    };

    const optionsRef = useNewClickOutside(() => {
        if (isOpened) {
            onChangeTag();
            setIsOpened(false);
        }
    });

    useEffect(() => {
        if (isOpened) inputRef.current.focus();
    }, [isOpened]);

    const onAddTag = () => addTag({ data: { name: searchByValue, color: TAG_COLORS[0] } });

    const onKeyUp = ({ keyCode }) => {
        if (keyCode === KEY_CODE.ENTER) onAddTag();
    };

    const onInputClick = () => {
        if (editableTag) return onChangeTag();
        setIsOpened(true);
    };

    const onDeleteTag = selectedTag => {
        const tagIndex = tagList.findIndex(el => el === selectedTag.id);

        const result = [...tagList];
        result.splice(tagIndex, 1);

        onChange(result);
    };

    const onSelectTag = selectedTag => {
        if (editableTag) return onChangeTag();

        const tagIndex = tagList.findIndex(el => el === selectedTag.id);

        if (tagIndex >= 0) onDeleteTag(selectedTag);
        else onChange([...tagList, selectedTag.id]);

        clearSearchBy();
    };

    const onSettingsKeyUp = ({ keyCode }) => {
        if (keyCode === KEY_CODE.ENTER) onChangeTag();
    };

    const onFullDeleteTag = () =>
        showModal({
            title: 'Delete tag?',
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(editableTag.data.name, true)}
                    entity={'tag'}
                    onConfirm={() => {
                        hideModal();
                        deleteTag({ id: editableTag.data.id });
                    }}
                />
            )
        });

    return (
        <div className={cn('form-label tag-input', className)} ref={componentRef}>
            <div className="j4">
                <div className="mr5">{label}</div>
                {hint && <HintIcon hint={txt.hints[hint] || hint} type={hintType} />}
            </div>

            <div className="tag-input__content" ref={optionsRef}>
                <div className="tag-input__field">
                    <div className="tag-input__field-content">
                        {tagsToRender.map(item => (
                            <div key={item.id} className="tag-input__tag" style={{ backgroundColor: item.color }}>
                                <span>{item.name}</span>

                                <div
                                    className="tag-input__icon tag-input__icon--delete"
                                    onClick={() => onDeleteTag(item)}
                                >
                                    <Icon.X size={15} />
                                </div>
                            </div>
                        ))}

                        <input
                            className="tag-input__input"
                            ref={inputRef}
                            placeholder={txt.placeholders.typeOrSelectTagsFromList}
                            onChange={setSearchByForInput}
                            value={searchByValue}
                            onClick={onInputClick}
                            onKeyUp={onKeyUp}
                        />
                    </div>

                    <div
                        className={cn('tag-input__chevron', { 'tag-input__chevron--opened': isOpened })}
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        <Icon.ChevronDown size={15} />
                    </div>

                    {(isAllTagsFetching || isAddTagLoading || isEditTagLoading || isDeleteTagLoading) && (
                        <div className="tag-input__loader">
                            <Loader type="cell" />
                        </div>
                    )}
                </div>

                {isOpened && (
                    <div className="tag-input__options">
                        <div className="tag-input__options-container">
                            {filteredItems.length > 0 && searchByValue && (
                                <span className="tag-input__options-title">Select an option or create one</span>
                            )}

                            {filteredItems.map(item => (
                                <div
                                    key={item.id}
                                    className={cn('tag-input__option', {
                                        'tag-input__option--active': editableTag?.data?.id === item.id
                                    })}
                                    onClick={() => onSelectTag(item)}
                                >
                                    <div className="tag-input__option--selected">
                                        {tagList.includes(item.id) && (
                                            <div className="tag-input__icon">
                                                <Icon.Check size={15} />
                                            </div>
                                        )}
                                    </div>

                                    <div className="tag-input__option-label" style={{ backgroundColor: item.color }}>
                                        {item.name}
                                    </div>

                                    <div
                                        className="tag-input__icon tag-input__icon--more"
                                        onClick={e => {
                                            e.stopPropagation();

                                            const position = e.currentTarget.getBoundingClientRect().bottom;
                                            const position2 = componentRef.current.getBoundingClientRect().bottom;

                                            const { innerHeight: windowInnerHeight } = window;

                                            const offset = position - position2;

                                            if (editableTag?.data?.id === item.id) {
                                                setEditableTag(null);
                                                savedEditableTag.current = null;
                                            } else {
                                                setEditableTag({
                                                    data: item,
                                                    offset,
                                                    invert: windowInnerHeight - position < 170
                                                });
                                                savedEditableTag.current = item;
                                            }
                                        }}
                                    >
                                        <Icon.MoreHorizontal size={15} />
                                    </div>
                                </div>
                            ))}

                            {searchByValue ? (
                                <div className="tag-input__option" onClick={onAddTag}>
                                    <span>Create</span>

                                    <div className="tag-input__option-label" style={{ backgroundColor: TAG_COLORS[0] }}>
                                        {searchByValue}
                                    </div>

                                    {/* <div
                                    className="tag-input__icon tag-input__icon--more"
                                    onClick={e => e.stopPropagation()}
                                >
                                    <Icon.MoreHorizontal size={15} />
                                </div> */}
                                </div>
                            ) : null}
                        </div>

                        {editableTag && (
                            <div
                                className="tag-input__settings"
                                style={{
                                    top: editableTag.invert ? editableTag.offset - 200 : editableTag.offset
                                }}
                            >
                                <div className="tag-input__settings-container">
                                    <TextInput
                                        autoFocus
                                        value={editableTag.data.name}
                                        onKeyUp={onSettingsKeyUp}
                                        onChange={({ target: { value: newName } }) =>
                                            setEditableTag({
                                                data: { ...editableTag.data, name: newName },
                                                offset: editableTag.offset
                                            })
                                        }
                                    />

                                    <div className="tag-input__delete" onClick={onFullDeleteTag}>
                                        <Icon.Trash2 size={15} />
                                        <span>Delete</span>
                                    </div>
                                </div>

                                <hr />

                                <div className="tag-input__settings-container">
                                    <span className="tag-input__settings-title">Colors</span>

                                    <div className="tag-input__colors">
                                        {TAG_COLORS.map((el, i) => (
                                            <div className="tag-input__color" key={el}>
                                                <input
                                                    type="radio"
                                                    name="color"
                                                    value={el}
                                                    id={`color-${i}`}
                                                    onChange={({ target: { value: newColor } }) =>
                                                        setEditableTag({
                                                            data: { ...editableTag.data, color: newColor },
                                                            offset: editableTag.offset
                                                        })
                                                    }
                                                />

                                                <label htmlFor={`color-${i}`} style={{ backgroundColor: el }}>
                                                    {editableTag.data.color === el && <Icon.Check size={16} />}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TagInput;
