import React from 'react';
import cn from 'classnames';
import { ArrowRight, Check } from 'react-feather';

import Langs from '../../Langs';

import Spinner from '../Overall/Icon/icons/Spinner';
import LogoImg from '../../../../media/images/logo.svg';

import './auth-form.scss';

export const AuthBtn = React.memo(({ children, isLoading, type, ...rest }) => (
    <button
        data-test="auth-form_authorization-btn"
        className="form-button form-button--full-width j5 mt25"
        type="button"
        {...rest}
    >
        {isLoading ? (
            <div style={{ position: 'absolute' }}>
                <Spinner size={24} className="mr5" />
            </div>
        ) : (
            <>
                <span>{children}</span>
                {type === 'ok' ? <Check size={16} className="ml8" /> : <ArrowRight size={16} className="ml8" />}
            </>
        )}
    </button>
));

const AuthForm = ({
    onExit,
    questionLabel,
    exitLabel,
    title,
    className,
    childrenClassName = 'col-6 form__col form__col--white',
    children
}) => {
    const txt = Langs[global.lng];

    return (
        <div className="auth-form">
            <div className={cn('container form__wrapper', className)}>
                <div className="row form__row">
                    <section className="col-6 form__col">
                        <div className="form-title form-title--logo">
                            <img src={LogoImg} width="150" height="76" alt="Logo" />
                        </div>

                        <p className="color--white">{txt.auth.trackNameHelpsText}</p>

                        {questionLabel && <div className="form-account">{questionLabel}</div>}

                        {onExit && (
                            <button data-test="auth-form_exit-btn" className="form-button" onClick={onExit}>
                                {exitLabel}
                            </button>
                        )}
                    </section>

                    <section className={childrenClassName}>
                        {title && <h1 className="title title--centered mb25 mt5">{title}</h1>}
                        {children}
                    </section>
                </div>
            </div>
        </div>
    );
};

export default AuthForm;
