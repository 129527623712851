import React, { useMemo } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';

import Langs from '../../../../Langs';

import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import Switcher from '../../../Overall/UI/Switcher/Switcher';
import HintIcon from '../../../Overall/Forms/Inputs/HintIcon';

const MAX_PARAMETERS_LENGTH = 20;

const placeholders = {
    '{external_id}': {
        name: 'e.g. External ID',
        query: 'e.g external_id',
        token: 'e.g {external_id}'
    },
    '{cost}': {
        name: 'e.g. Cost',
        query: 'e.g cost',
        token: 'e.g {cost}'
    },
    '{var1}': {
        name: 'e.g. Creative ID',
        query: 'e.g creative_id',
        token: 'e.g {creative_id}'
    },
    '{var2}': {
        name: 'e.g. Site ID',
        query: 'e.g site_id',
        token: 'e.g {site_id}'
    },
    default: {
        name: 'Type name',
        query: 'Placeholder',
        token: 'Placeholder'
    }
};

const TSParameters = ({ parameters: items, updateTrafficSource, isSendCkick, errorsRef }) => {
    const txt = Langs[global.lng];

    const parameters = items.map(param => ({
        id: `{${param.internalToken}}`,
        name: param.name,
        query: param.queryKey,
        token: param.tsToken,
        isTracked: param.isTracked
    }));

    const errors = useMemo(() => {
        const result = {};
        parameters?.forEach((parameter, id) => {
            if (parameter.isTracked) {
                Object.keys(parameter).forEach(el => {
                    if (['name', 'query', 'token'].includes(el) && !parameter[el]) {
                        result[id] = { ...result?.[id], [el]: ' ' };
                    }
                });
            }
        });

        errorsRef.current.parameters = Object.keys(result).length !== 0;

        return result;
    }, [parameters]);

    const change = (e, id, key, val) => {
        const replaceableIdx = id;

        // eslint-disable-next-line no-nested-ternary
        const newItem = { ...val, [key]: e.target ? (e.target.value === '' ? null : e.target.value) : e };

        if (key === 'query') {
            if ((!val.query && !val.token) || val.token?.trim() === `{${val.query}}` || !val.token) {
                newItem.token = newItem.query ? `{${newItem.query}}` : null;
            }
        }
        const isAllEmpty = !newItem.name && !newItem.query && !newItem.token;

        if (key !== 'isTracked') {
            newItem.isTracked = !isAllEmpty;
        }

        const result = {
            internalToken: newItem.id.replace(/[{}]/g, ''),
            name: newItem.name,
            queryKey: newItem.query,
            tsToken: newItem.token,
            isTracked: newItem.isTracked
        };

        const newParameters = [...items.slice(0, replaceableIdx), result, ...items.slice(replaceableIdx + 1)];
        updateTrafficSource({ variables: newParameters });
    };

    const handleRemoveParam = id => {
        const newParameters = items.filter(el => `{${el.internalToken}}` !== id);
        updateTrafficSource({ variables: newParameters });
    };

    const handleAddParameter = e => {
        e.preventDefault();
        const newParameters = [
            ...items,
            {
                internalToken: `var${parameters.length - 1}`,
                name: null,
                queryKey: null,
                tsToken: null,
                isTracked: false
            }
        ];

        updateTrafficSource({ variables: newParameters });
    };

    const tsParametersHeaderItems = [
        {
            label: txt.labels.parameters,
            hint: txt.hints.parameters(MAX_PARAMETERS_LENGTH)
        },
        {
            label: txt.labels.name,
            hint: txt.hints.name
        },
        {
            label: txt.labels.queryParameter,
            hint: txt.hints.queryParameter
        },
        {
            label: txt.labels.tsToken,
            hint: txt.hints.tsToken
        },
        {
            label: txt.labels.track
        }
    ];

    const renderHeader = () => (
        <div className="ts-params__tr">
            {tsParametersHeaderItems.map(({ label, hint }, idx) => (
                <div key={idx} className="ts-params__th">
                    {hint ? (
                        <div className="j4">
                            <div className="mr5">{label}</div>
                            <HintIcon hint={hint} type={label === txt.labels.tsToken ? 'left' : 'top'} />
                        </div>
                    ) : (
                        label
                    )}
                </div>
            ))}
            <div className="ts-params__th ts-params__th--btn" />
        </div>
    );

    const renderCell = (id, val, key) => {
        const isImportant = val.id === '{external_id}' || val.id === '{cost}';
        const transformId = val.id.replace(/[{}]/gi, '');

        switch (key) {
            case 'id':
                return (
                    <div
                        className={cn('ts-params__label', 'j4', {
                            'ts-params__label--important': isImportant
                        })}
                    >
                        <div className="mr5">{val[key]}</div> {isImportant && <HintIcon hint={txt.hints[val[key]]} />}
                    </div>
                );
            case 'isTracked':
                return (
                    <Switcher
                        dataTest={`ts-parameters_${transformId}-${key}`}
                        isSwitchOn={val[key]}
                        onChange={e => change(e, id, key, val)}
                        // disabled={isImportant}
                    />
                );
            default:
        }
        return (
            <TextInput
                dataTest={`ts-parameters_${transformId}-${key}-input`}
                onChange={e => change(e, id, key, val)}
                value={val[key]}
                disabled={isImportant && (key === 'name' || key === 'isTracked')}
                placeholder={placeholders[val.id] ? placeholders[val.id][key] : placeholders.default[key]}
                error={isSendCkick && errors?.[id]?.[key]}
            />
        );
    };

    return (
        <div>
            <div className="ts-params">
                {renderHeader()}
                <div className="mb10" />
                {parameters?.map((val, id) => (
                    <div className="ts-params__tr" key={id}>
                        {Object.keys(val).map((key, idx) => (
                            <div className="ts-params__td" key={idx}>
                                {renderCell(id, val, key)}
                            </div>
                        ))}
                        <div className="ts-params__td">
                            {id > 1 && id === parameters.length - 1 && (
                                <div className="ts-params__btn">
                                    <Icon.Trash2
                                        size={16}
                                        className="color--froly"
                                        onClick={() => handleRemoveParam(val.id)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {parameters?.length < 22 && (
                <a
                    data-test="ts-parameters_add-parameter-link"
                    className="j4 mt10 create-ts-content__item"
                    href="#addParameter"
                    onClick={handleAddParameter}
                >
                    <Icon.Plus size={16} className="mr5" />
                    {txt.labels.addParameter}
                </a>
            )}
        </div>
    );
};

export default TSParameters;
