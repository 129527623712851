import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const flowsApiTagTypes = {
    flows: 'flows'
};

const flowsApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(flowsApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getFlowsList: builder.query({
            query: params => `/flows${params}`,
            keepUnusedDataFor: 0,
            transformResponse: response => ({
                items: response.items.map(el => transform.flows.importList(el)),
                total: response.total,
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: flowsApiTagTypes.flows, id: 'LIST' },
                { type: flowsApiTagTypes.flows, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getFlowById: builder.query({
            query: id => `/flows/${id}`,
            transformResponse: response => transform.flows.import(response),
            providesTags: (result, error, id) => [
                { type: flowsApiTagTypes.flows, id },
                { type: flowsApiTagTypes.flows, id: 'ALL_ITEMS' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getFlowsAllNames: builder.query({
            query: () => `/flows/all/names`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: flowsApiTagTypes.flows, id: 'ALL_NAMES_LIST' },
                { type: flowsApiTagTypes.flows, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getFlowsWithColumns: builder.query({
            query: params => `/flows/all/with-columns${params}`,
            providesTags: () => [
                { type: flowsApiTagTypes.flows, id: 'WITH_COLUMNS_LIST' },
                { type: flowsApiTagTypes.flows, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getFlowsChart: builder.query({
            query: params => `/flows/all/chart${params}`,
            providesTags: () => [
                { type: flowsApiTagTypes.flows, id: 'CHART' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        addFlow: builder.mutation({
            query: ({ data }) => ({
                url: `/flows`,
                method: 'POST',
                body: transform.flows.export(data)
            }),
            invalidatesTags: (result, error, { data }) => {
                const addedCampaigns = data.campaignsToAdd?.map(el => ({
                    type: 'campaigns',
                    id: el.value
                }));

                if (!error) {
                    return [
                        { type: flowsApiTagTypes.flows, id: 'ALL' },
                        { type: 'entityAllNames', id: 'flows' },
                        ...addedCampaigns
                    ];
                } else return [];
            }
        }),

        editFlow: builder.mutation({
            query: ({ id, data }) => ({
                url: `/flows/${id}`,
                method: 'PUT',
                body: transform.flows.export(data)
            }),
            invalidatesTags: (result, error, { id, data }) => {
                const addedCampaigns = data.campaignsToAdd?.map(el => ({
                    type: 'campaigns',
                    id: el.value
                }));

                const currentCampaigns = data.campaigns.map(el => ({
                    type: 'campaigns',
                    id: el.value
                }));

                const currentFlowsWithAction = data.campaigns
                    .filter(el => el.action)
                    .map(el => ({
                        type: flowsApiTagTypes.flows,
                        id: el.actionFlowId
                    }));

                const isResetCampaignsAllModal =
                    data.campaignsToAdd?.length || data.campaigns?.filter(el => el.action)?.length;

                const tagsList = [
                    { type: flowsApiTagTypes.flows, id },
                    { type: flowsApiTagTypes.flows, id: 'ALL' },
                    { type: 'entityAllNames', id: 'flows' },
                    ...addedCampaigns,
                    ...currentCampaigns,
                    ...currentFlowsWithAction
                ];

                if (isResetCampaignsAllModal) tagsList.push({ type: 'campaigns', id: 'ALL_MODAL_LIST' });

                if (!error) return tagsList;
                else return [];
            }
        }),

        deleteFlow: builder.mutation({
            query: ({ id, data }) => ({
                url: `/flows/${id}/delete`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, { id, data }) => {
                const addedCampaigns = data.campaigns?.map(el => ({
                    type: 'campaigns',
                    id: el.id
                }));

                const tagsList = [
                    { type: flowsApiTagTypes.flows, id },
                    { type: flowsApiTagTypes.flows, id: 'ALL' },
                    { type: 'entityAllNames', id: 'flows' },
                    ...addedCampaigns
                ];

                if (data.campaigns?.length) tagsList.push({ type: 'campaigns', id: 'ALL_MODAL_LIST' });

                if (!error) return tagsList;
                else return [];
            }
        }),

        restoreFlow: builder.mutation({
            query: ({ id }) => ({
                url: `/flows/${id}/restore`,
                method: 'PATCH'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: flowsApiTagTypes.flows, id },
                        { type: flowsApiTagTypes.flows, id: 'ALL' },
                        { type: flowsApiTagTypes.flows, id: 'CHART' }
                    ];
                else return [];
            }
        })
    })
});

export const {
    useGetFlowsListQuery,
    useGetFlowByIdQuery,
    useLazyGetFlowByIdQuery,
    useGetFlowsAllNamesQuery,
    useGetFlowsWithColumnsQuery,
    useGetFlowsChartQuery,
    useAddFlowMutation,
    useEditFlowMutation,
    useDeleteFlowMutation,
    useRestoreFlowMutation
} = flowsApi;
