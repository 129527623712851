import React, { useState, useMemo, useEffect } from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import { NEW_PAYMENT_METHOD } from '../../../../Constants/index';
import { getCardErrors } from '../../../../redux/operations';
import {
    getHiddenCardNumber,
    removeTrialModalLastShowTime,
    getResponseErrorMessage
} from '../../../../Functions/utils';
import useCreditCardValidation from '../../../../Functions/hooks/useCreditCardValidation';

import { useChangeProjectPlanMutation } from '../../../../redux/api/projectApi';

import Btn from '../../../Overall/UI/Btn/Btn';
import JustAlert from '../../../../components/just-alert/just-alert';
import AddCard from '../../../Overall/AddCard/AddCard';
import RadioInput from '../../../Overall/Forms/Inputs/RadioInput';

import Visa from '../../../../../../media/images/paymentMethods/visa.svg';
import MasterCard from '../../../../../../media/images/paymentMethods/mastercard.svg';
import AmericanExpress from '../../../../../../media/images/paymentMethods/americanexpress.svg';
import Discover from '../../../../../../media/images/paymentMethods/discover.svg';
import PayPal from '../../../../../../media/images/paymentMethods/paypal-light-large.svg';
import CardDefault from '../../../../../../media/images/paymentMethods/cardicon.svg';

import './change-plan-payment-component.scss';

const icons = {
    Visa,
    MasterCard,
    AmericanExpress,
    Discover,
    PayPal
};

const ChangePlanPaymentComponent = ({
    className,
    paymentMethodList,
    nextPlanId,
    onConfirm,
    amountForButton,
    backToInvoiceDetails,
    failedAutoPaymentMethodId,
    activePaymentMethod,
    setActivePaymentMethod,
    setIsProcessing,
    userEmail
}) => {
    const txt = Langs[global.lng];

    const isNewCardMethodActive = activePaymentMethod?.id === NEW_PAYMENT_METHOD;

    const [isPayNewCard, setIsPayNewCard] = useState(isNewCardMethodActive);
    const [isTouch, setIsTouch] = useState(false);
    const [responseError, setResponseError] = useState({});

    const [
        cardInfo,
        cardErrors,
        onCardNumberChange,
        onCardDateChange,
        onCardDateBlur,
        onCardCodeChange,
        onCardSaveChange
    ] = useCreditCardValidation();

    const [changePlan, { isSuccess: isChangePlanSuccess, isError: isChangePlanError, error: changePlanError }] =
        useChangeProjectPlanMutation();

    useEffect(() => {
        if (isChangePlanSuccess) {
            removeTrialModalLastShowTime(userEmail);
            onConfirm();
        }
    }, [isChangePlanSuccess]);

    useEffect(() => {
        if (isChangePlanError) {
            setResponseError({ responseError: getResponseErrorMessage(changePlanError.data, txt.toasts.saveError) });
            setIsProcessing(false);
        }
    }, [isChangePlanError]);

    const onNewPaymentMethodClick = () => {
        setIsPayNewCard(true);
        setActivePaymentMethod({ id: NEW_PAYMENT_METHOD });
        setResponseError({});
        if (isTouch) setIsTouch(false);
    };

    const onPaymentMethodChange = ({ target: { value } }) => {
        if (activePaymentMethod?.id === value) return;

        if (value === NEW_PAYMENT_METHOD) {
            setActivePaymentMethod({ id: value });
            return;
        } else if (isPayNewCard) setIsPayNewCard(false);

        setResponseError({});
        if (isTouch) setIsTouch(false);
        setActivePaymentMethod(paymentMethodList.find(el => el.id === value));
    };

    const frontErrorList = Object.values(cardErrors).filter(error => !!error);
    const backErrorList = Object.values(responseError).filter(error => !!error);
    const allErrors = [...frontErrorList, ...backErrorList];

    const onPayButtonClick = async () => {
        setIsTouch(true);
        setResponseError({});

        if (!frontErrorList.length || (activePaymentMethod && !isNewCardMethodActive)) {
            setIsProcessing(true);

            const requestBody = {
                id: nextPlanId,
                paymentMethodId: activePaymentMethod && !isNewCardMethodActive ? activePaymentMethod?.id : null,
                card: null
            };

            if (!activePaymentMethod || isNewCardMethodActive) {
                requestBody.card = {
                    cardNumber: cardInfo.cardNumber,
                    expirationDate: `${cardInfo.cardDate.slice(0, 2)}/20${cardInfo.cardDate.slice(-2)}`,
                    cardCode: cardInfo.cardCode,
                    save: cardInfo.isSave
                };
            }

            changePlan(requestBody);
        }
    };

    const getDefaultMethod = () => {
        if (activePaymentMethod && !isNewCardMethodActive) {
            const isExpired = !!getCardErrors({
                cardNumber: '',
                cardDate: activePaymentMethod?.expirationDate
            })?.cardDate;

            if (isExpired) return paymentMethodList[0]?.id;
        }

        return activePaymentMethod?.id;
    };

    const paymentItems = useMemo(() => {
        const items = paymentMethodList.map(method => {
            const isExpired = !!getCardErrors({
                cardNumber: '',
                cardDate: method.expirationDate
            })?.cardDate;

            return {
                value: method.id,
                disabled: isExpired,
                rightElement: method.expirationDate,
                errorText: failedAutoPaymentMethodId === method.id ? txt.labels.autoPaymentError : null,
                label: (
                    <div className="payment-modal__item">
                        <img
                            src={icons[method.cardType] || CardDefault}
                            width="36"
                            height="24"
                            alt={method.cardType || 'Card'}
                        />

                        {method.name === method.cardNumber ? (
                            getHiddenCardNumber(method.cardNumber)
                        ) : (
                            <div className="payment-modal__card-info">
                                <span className="payment-modal__card-name">{method.name}</span>
                                <span className="payment-modal__card-number">
                                    {getHiddenCardNumber(method.cardNumber)}
                                </span>
                            </div>
                        )}
                    </div>
                )
            };
        });

        if (isPayNewCard || isNewCardMethodActive) {
            items.push({
                value: NEW_PAYMENT_METHOD,
                customClass: 'payment-modal__new-card',
                label: (
                    <>
                        <p className="payment-modal__add-card-title">Pay with a new card</p>

                        <AddCard
                            cardInfo={cardInfo}
                            onCardNumberChange={onCardNumberChange}
                            onCardDateChange={onCardDateChange}
                            onCardCodeChange={onCardCodeChange}
                            onCardSaveChange={onCardSaveChange}
                            onCardDateBlur={onCardDateBlur}
                            setResponseError={setResponseError}
                            isHiddenAlerts
                            errors={cardErrors}
                            isTouch={isTouch}
                            autoFocus={isNewCardMethodActive}
                            isAddSaveCheckBox
                        />
                    </>
                )
            });
        }

        return items;
    }, [paymentMethodList, isPayNewCard, isNewCardMethodActive, cardInfo]);

    const getErrorsText = errors => {
        if (errors.length === 1) {
            return errors[0];
        }

        return (
            <ul className="add-card__alert-list">
                {errors.map((el, idx) => (
                    <li key={el + idx}>{el}</li>
                ))}
            </ul>
        );
    };

    const printErrors = () => {
        const result = [];

        if (backErrorList.length) result.push(...backErrorList);

        if (isTouch && isNewCardMethodActive) result.push(...frontErrorList);

        return result.length ? <JustAlert type="danger">{getErrorsText(result)}</JustAlert> : null;
    };

    return (
        <div className={cn('payment-modal', className)}>
            {paymentMethodList && paymentMethodList.length ? (
                <div>
                    <RadioInput
                        isHighlighted
                        value={getDefaultMethod()}
                        onChange={onPaymentMethodChange}
                        items={paymentItems}
                        className="payment-modal__payment-method"
                    />

                    {!isPayNewCard && (
                        <Btn onClick={onNewPaymentMethodClick} type="link" className="payment-modal__add-card">
                            {txt.titles.newPaymentMethod}
                        </Btn>
                    )}
                </div>
            ) : (
                <AddCard
                    cardInfo={cardInfo}
                    onCardNumberChange={onCardNumberChange}
                    onCardDateChange={onCardDateChange}
                    onCardCodeChange={onCardCodeChange}
                    onCardDateBlur={onCardDateBlur}
                    errors={allErrors}
                    isTouch={isTouch}
                />
            )}

            {activePaymentMethod && printErrors()}

            <div className="chage-plan-modal__buttons-block">
                <Btn onClick={backToInvoiceDetails} type="link" className="chage-plan-modal__edit-billing-info-button">
                    {txt.buttons.editBillingInfo}
                </Btn>

                <Btn
                    onClick={onPayButtonClick}
                    type="filled"
                    className="chage-plan-modal__next-button"
                    disabled={isTouch && frontErrorList.length && (isNewCardMethodActive || !activePaymentMethod)}
                >
                    {txt.buttons.payAndSubscribe(amountForButton)}
                </Btn>
            </div>
        </div>
    );
};

export default ChangePlanPaymentComponent;
