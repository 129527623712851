const reportTemplate = {
    filters: [],
    groups: {},
    pagination: {
        page: 1,
        pages: 1,
        perPage: 50
    },
    search: '',
    sorting: {
        key: 'name',
        type: 'ASC'
    },
    columnFilters: [],
    columnsConfig: {
        isColoredRows: false,
        isVerticalBorders: false,
        columns: [],
        columnsWidths: []
    },

    // ??
    conversionsStatus: 'all',
    conversionsTimestamp: 'postback',
    filterType: 'clickId',
    filterSearch: ''
};

export default reportTemplate;
