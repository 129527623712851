import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import ManualCostUpdateModal from '../../../../Modules/Modals/manual-cost-update-modal/manual-cost-update-modal';

const UpdateCostButton = ({ chapter, activeRow, isDisabled, onClick, type }) => {
    const txt = Langs[global.lng];

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: txt.labels.manualCostUpdate,
                body: props => <ManualCostUpdateModal {...props} item={activeRow} />
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__update-cost-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.updateCost}
        </Btn>
    );
};

export default UpdateCostButton;
