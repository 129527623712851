import React, { useMemo } from 'react';

import moment from 'moment-timezone';
import cn from 'classnames';

import Langs from '../../../../Langs';
import { ALERT_TYPE, VERIFY_STATUS } from '../../../../Constants';

import { getUrlError, getLandingProtectScript } from '../../../../redux/operations';
import { checkUrlCharacters } from '../../../../Functions/utils';

import useFocus from '../../../../Functions/hooks/useFocus';

import ClipboardField from '../../../Overall/Forms/ClipboardField';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import Alert from '../../../Overall/UI/Alert/Alert';
import Switcher from '../../../Overall/UI/Switcher/Switcher';
import CopyFieldLabeled from '../../../Overall/UI/CopyField/CopyFieldLabeled';
import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';

const LanderSetup = ({
    offersNum,
    verifyStatusess,
    hasProtectLandingPage,
    hasRedirectlessScript,
    isMatchLanderUrl,
    clickUrlPage,
    campaignsCount,
    defaultTrackingDomain,
    landingProtection,
    updateLander,
    isNeedValidate
}) => {
    const txt = Langs[global.lng];

    const [clickUrlPageInputRef, setClickUrlPageInputFocus] = useFocus();

    const trackingDomain = useMemo(
        () => `${defaultTrackingDomain?.domain?.ssl ? 'https://' : 'http://'}${defaultTrackingDomain.domain?.name}`,
        [defaultTrackingDomain]
    );

    const redirectlessScript = useMemo(
        () => `<script src="${trackingDomain}/scripts/pixel.js?v=${moment().format('X')}"></script>`,
        [trackingDomain]
    );

    const landerScript = useMemo(
        () => `<script src="${trackingDomain}/scripts/lander-script.js?v=${moment().format('X')}"></script>`,
        [trackingDomain]
    );

    const isJavaScriptLandingProtectionScriptType = landingProtection.landingProtectionScriptType === 'javascript';

    const numberOfOffers = [...Array(offersNum).keys()].map(el => el + 1);
    const hasVerificatedUrls = !!verifyStatusess?.statuses?.some(el => el.status !== VERIFY_STATUS.NOT_VERIFIED);

    const getVerificationStatus = status => {
        const verificationStatusText = {
            error: txt.labels.missing,
            verified: txt.labels.verified
        };

        return (
            <span
                className={cn('text-small font-weight-normal text-uppercase', {
                    'color--green-haze': status === VERIFY_STATUS.VERIFIED,
                    'color--froly': status === VERIFY_STATUS.ERROR || status === VERIFY_STATUS.NOT_VERIFIED || !status
                })}
            >
                {verificationStatusText[status] || txt.labels.notVerifiedYet}
            </span>
        );
    };

    const getVerificationUrlStatus = idx => {
        if (!hasVerificatedUrls) return null;

        return getVerificationStatus(verifyStatusess?.statuses?.[idx]?.status);
    };

    return (
        <ModalContentContainer title={txt.labels.landingPageSetup}>
            <div className="mt16 create-lander__lander-script">
                <Switcher
                    label={
                        <div className="create-lander__lander-script-title">
                            Lander script <span>(required)</span>
                        </div>
                    }
                    hint={txt.hints.clickUrlClassic}
                    className="mb8"
                    isSwitchOn={true}
                    onChange={() => {}}
                >
                    <div className="create-lander__verification-status create-lander__verification-status--absolute">
                        {getVerificationStatus(verifyStatusess?.clickUrlScript)}
                    </div>

                    <CopyFieldLabeled
                        field={{
                            label: txt.labels.clickUrlScript,
                            text: landerScript
                        }}
                    />

                    <div className="create-lander__lander-script-descr mt8">
                        {txt.texts.copyAndPasteScriptIntoLanderPage}
                    </div>
                </Switcher>
            </div>

            <div className="mt16">
                <Switcher
                    dataTest="create-lander_protect-landing-page"
                    label={txt.labels.protectLandingPage}
                    hint={txt.hints.protectLandingPage}
                    className="mb4"
                    isSwitchOn={hasProtectLandingPage}
                    onChange={value => updateLander({ hasProtectLandingPage: value })}
                >
                    {isJavaScriptLandingProtectionScriptType && (
                        <div className="create-lander__verification-status create-lander__verification-status--absolute">
                            {getVerificationStatus(verifyStatusess?.landingPageProtectScript)}
                        </div>
                    )}
                    <CopyFieldLabeled
                        dataTest="create-lander_protect-landing-page"
                        field={{
                            label: txt.labels.protectScript,
                            text: getLandingProtectScript(
                                isJavaScriptLandingProtectionScriptType,
                                landingProtection.landingProtectionKey
                            )
                        }}
                    />
                </Switcher>
            </div>

            <div className="mt16">
                <Switcher
                    dataTest="create-lander_redirectless-script"
                    label={txt.labels.redirectlessScript}
                    hint={txt.hints.redirectlessScript}
                    className="mb4"
                    isSwitchOn={hasRedirectlessScript}
                    onChange={value => updateLander({ hasRedirectlessScript: value })}
                >
                    <div className="create-lander__verification-status create-lander__verification-status--absolute">
                        {getVerificationStatus(verifyStatusess?.redirectlessScript)}
                    </div>

                    <CopyFieldLabeled
                        dataTest="redirectless-script"
                        field={{
                            label: txt.labels.redirectlessScript,
                            text: redirectlessScript
                        }}
                    />
                </Switcher>
            </div>

            <div className="mt16">
                <Switcher
                    dataTest="create-lander_page-does-not-match-lander-url"
                    label={txt.labels.pageDoesNotMatchLanderURL}
                    hint={txt.hints.pageDoesNotMatchLanderURL}
                    className="mb8"
                    isSwitchOn={isMatchLanderUrl}
                    onChange={value => {
                        updateLander({ isMatchLanderUrl: value });
                        if (!isMatchLanderUrl) setTimeout(setClickUrlPageInputFocus, 0);
                    }}
                >
                    <TextInput
                        dataTest="create-lander_click-url-page-input"
                        className="mb15"
                        label={txt.labels.clickUrlPage}
                        ref={clickUrlPageInputRef}
                        error={isNeedValidate && getUrlError(clickUrlPage, txt.labels.clickUrlPage)}
                        value={clickUrlPage || 'https://'}
                        onChange={({ target: { value } }) => updateLander({ clickUrlPage: value })}
                        checkCorrectSymbol={checkUrlCharacters}
                    />

                    <Alert message={txt.alerts.clickUrlPageExample} />
                </Switcher>
            </div>

            <div className="mt16">
                {!!campaignsCount && (
                    <Alert
                        className="mb10"
                        type={ALERT_TYPE.WARNING}
                        message={txt.alerts.landingPageCampaignsCountWarn(campaignsCount)}
                    />
                )}

                <div className="j7">
                    <div className="create-lander__section-column-offers-number">
                        <TextInput
                            dataTest="create-lander_offers-number-input"
                            label={txt.labels.numberOfOffers}
                            type="number"
                            min={1}
                            max={50}
                            value={offersNum}
                            onChange={({ target: { value } }) => updateLander({ offersNum: value })}
                            disabled={!!campaignsCount}
                        />
                    </div>

                    <div className="create-lander__section-column-grow">
                        {numberOfOffers.map((el, idx) => {
                            return (
                                <ClipboardField
                                    key={el}
                                    dataTest="click-url-for-offer"
                                    className="mb8"
                                    label={`${txt.labels.clickUrlForOffer} ${el}`}
                                    rightLabel={getVerificationUrlStatus(idx)}
                                    type="link"
                                    value={`${trackingDomain}/click${offersNum > 1 ? `/${el}` : ''}`}
                                    readOnly
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </ModalContentContainer>
    );
};

export default LanderSetup;
