import React from 'react';
import cn from 'classnames';

import Langs from '../../../Langs';

const JustTableTh = ({ item, isControledWidth, width, gap }) => {
    const txt = Langs[global.lng];

    return (
        <div
            style={{ ...(gap ? { paddingRight: gap } : {}), ...(isControledWidth ? { width } : {}) }}
            className={cn('just-table__cell just-table__cell--th', item.className, {
                hidden: item.hidden
            })}
        >
            <div className={`th-item ${item.className || ''}`}>
                {item.label ? item.label || txt.labels[item.key] : ''}
            </div>
        </div>
    );
};

export default JustTableTh;
