import React from 'react';
import cn from 'classnames';

import './notice.scss';

export const NOTICE_TYPES = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger'
};

const Notice = ({ children, type }) => {
    return (
        <div
            className={cn('notice', {
                'notice--success': type === NOTICE_TYPES.SUCCESS,
                'notice--warning': type === NOTICE_TYPES.WARNING,
                'notice--danger': type === NOTICE_TYPES.DANGER
            })}
        >
            {children}
        </div>
    );
};

export default Notice;
