import React from 'react';

import Icon from '../../Icon';
import Btn from '../../UI/Btn/Btn';

export const TdUndo = ({ onClick, type = 'button', dataTest, disabled }) => {
    if (type === 'button') {
        return (
            <Btn dataTest={`td-actions_${dataTest}_undo-btn`} type="icon" onClick={onClick} disabled={disabled}>
                <Icon.RotateCcw className="td-actions--undo" />
            </Btn>
        );
    }
    return (
        <span className="td-actions td-actions--undo" onClick={onClick}>
            <Icon.RotateCcw />
        </span>
    );
};
