import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { USER_ROLES } from '../../../Constants';
import { showModal, hideModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import {
    useChangeNotificationEnableMutation,
    useChangeNotificationSystemMessageMutation,
    useDeleteNotificationMutation,
    useGetNotificationsQuery
} from '../../../redux/api/notificationsApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustTable from '../../../components/just-table/just-table';
import AddNotificationModal from '../../Modals/add-notification-modal/add-notification-modal';
import ConfirmDeleteModal from '../../Modals/confirm-delete-modal/confirm-delete-modal';
import Loader from '../../Loader';

import cols from './table-constructor';

import './notifications.scss';

const Notifications = () => {
    const txt = Langs[global.lng];

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const {
        data: notifications,
        isSuccess: isNotificationsLoaded,
        isFetching: isNotificationsFetching
    } = useGetNotificationsQuery();

    const getTemplateValuesComponents = inputRow => {
        const templateType = inputRow?.template;

        if (templateType === 'telegram' || templateType === 'webhook') {
            if (
                (templateType === 'telegram' && inputRow?.templateData?.chatIds?.length) ||
                (templateType === 'webhook' && inputRow?.templateData?.postParams?.length)
            ) {
                return templateType === 'telegram'
                    ? { key: inputRow?.value, value: [inputRow?.templateData?.chatIds?.length] || [] }
                    : { key: inputRow?.value, value: inputRow?.templateData?.postParams?.length };
            }

            return {};
        }

        return templateType === 'email'
            ? { key: inputRow?.value, value: inputRow?.templateData?.emails || [] }
            : { key: inputRow?.value, value: inputRow?.templateData?.toTel || [] };
    };

    const rows = notifications?.map(row => ({
        ...row,
        templateValuesComponents: getTemplateValuesComponents(row),
        actions: [{ type: 'editRow' }, { type: 'deleteRow' }]
    }));

    const colsWithRoles =
        userSettings?.role === USER_ROLES.USER
            ? cols.filter(el => el.key !== 'actions').filter(el => el.type !== 'switcher')
            : cols;

    const newNotificationModal = item => {
        showModal({
            title: item ? txt.labels.editNotification : txt.labels.newNotification,
            body: props => <AddNotificationModal {...props} item={item} />
        });
    };

    const [
        deleteNotification,
        {
            isLoading: isDeleteNotificationLoading,
            isSuccess: isDeleteNotificationSuccess,
            isError: isDeleteNotificationError,
            error: deleteNotificationError
        }
    ] = useDeleteNotificationMutation();

    useEffect(() => {
        if (isDeleteNotificationSuccess) {
            toast.success(txt.toasts.notificationWasDeleted);
            hideModal();
        }
    }, [isDeleteNotificationSuccess]);

    useEffect(() => {
        if (isDeleteNotificationError)
            toast.error(getResponseErrorMessage(deleteNotificationError.data, txt.errors.deleteError));
    }, [isDeleteNotificationError]);

    const [
        changeNotificationEnabled,
        {
            isLoading: isChangeNotificationEnabledLoading,
            isSuccess: isChangeNotificationEnabledSuccess,
            isError: isChangeNotificationEnabledError,
            error: changeNotificationEnabledError
        }
    ] = useChangeNotificationEnableMutation();

    useEffect(() => {
        if (isChangeNotificationEnabledSuccess) toast.success(txt.toasts.saved);
    }, [isChangeNotificationEnabledSuccess]);

    useEffect(() => {
        if (isChangeNotificationEnabledError)
            toast.error(getResponseErrorMessage(changeNotificationEnabledError.data, txt.errors.changeError));
    }, [isChangeNotificationEnabledError]);

    const [
        changeNotificationSystemMessage,
        {
            isLoading: isChangeNotificationSystemMessageLoading,
            isSuccess: isChangeNotificationSystemMessageSuccess,
            isError: isChangeNotificationSystemMessageError,
            error: changeNotificationSystemMessageError
        }
    ] = useChangeNotificationSystemMessageMutation();

    useEffect(() => {
        if (isChangeNotificationSystemMessageSuccess) toast.success(txt.toasts.saved);
    }, [isChangeNotificationSystemMessageSuccess]);

    useEffect(() => {
        if (isChangeNotificationSystemMessageError)
            toast.error(getResponseErrorMessage(changeNotificationSystemMessageError.data, txt.errors.changeError));
    }, [isChangeNotificationSystemMessageError]);

    const onActionClick = (type, item, value) => {
        switch (type) {
            case 'editRow':
                newNotificationModal(item);
                break;

            case 'deleteRow':
                showModal({
                    title: 'Delete notification?',
                    body: props => (
                        <ConfirmDeleteModal
                            {...props}
                            title={txt.texts.getWillBeArchivedSubtitle(item.name, true)}
                            entity={'notification'}
                            onConfirm={() => deleteNotification({ id: item.value })}
                        />
                    )
                });
                break;

            case 'isSystemMessage':
                changeNotificationSystemMessage({ data: value, id: item.value });
                break;

            case 'isEnabled':
                changeNotificationEnabled({ data: value, id: item.value });
                break;

            default:
                break;
        }
    };

    const isLoaded = isUserSettingsLoaded && isNotificationsLoaded;

    if (!isLoaded) return <Loader />;

    const isProcessing =
        isDeleteNotificationLoading ||
        isNotificationsFetching ||
        isChangeNotificationEnabledLoading ||
        isChangeNotificationSystemMessageLoading;

    const sectionHeader = (
        <SectionHeader
            title={txt.labels.notifications}
            button={{
                label: txt.buttons.addNotifications,
                onClick: () => newNotificationModal(),
                hidden: userSettings.role === USER_ROLES.USER
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="notifications">
            {notifications.length ? (
                <JustTable
                    cols={colsWithRoles}
                    rows={rows}
                    onActionClick={onActionClick}
                    isHeaderDecorate
                    isControledWidth
                />
            ) : null}

            {isProcessing && <Loader isFetching />}
        </Section>
    );
};

export default Notifications;
