import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { omitKeys, getResponseErrorMessage } from '../../../Functions/utils';
import { EMAIL_RULE, PUBLIC_WORKSPACE, USER_ROLES } from '../../../Constants';
import useFocus from '../../../Functions/hooks/useFocus';

import { useAddInvitationMutation } from '../../../redux/api/invitationsApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';

import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
// import Alert from '../../Overall/UI/Alert/Alert';
// import TagInput from '../../Overall/Forms/Inputs/TagInput';

import './invite-user-modal.scss';

const roles = Object.values(omitKeys(USER_ROLES, ['OWNER']));

const InviteUserModal = ({ onTouch, onClose }) => {
    const txt = Langs[global.lng];

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const inviteTemplate = {
        email: '',
        role: USER_ROLES.USER,
        isHideProfit: false,
        isAllowSharingReport: false,
        workspaces: [PUBLIC_WORKSPACE]
    };

    const [isSubmitTouched, setIsSubmitTouched] = useState(false);
    const [invite, setInvite] = useState(inviteTemplate);
    const isAllValid = EMAIL_RULE.test(invite.email);

    const [emailRef, setEmailFocus] = useFocus();

    const [
        addUser,
        { isLoading: isAddUserLoading, isSuccess: isAddUserSuccess, isError: isAddUserError, error: addUserError }
    ] = useAddInvitationMutation();

    useEffect(() => {
        if (isAddUserSuccess) {
            toast.success(txt.toasts.invitationSent);
            hideModal();
        }
    }, [isAddUserSuccess]);

    useEffect(() => {
        if (isAddUserError) toast.error(getResponseErrorMessage(addUserError.data, txt.toasts.saveError));
    }, [isAddUserError]);

    useEffect(() => {
        setTimeout(setEmailFocus, 0);
    }, []);

    const onChange = useCallback((prop, value) => {
        onTouch();

        setInvite(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const onSubmit = () => {
        setIsSubmitTouched(true);
        if (isAllValid) addUser(invite);
    };

    const isLoaded = isUserSettingsLoaded;

    return (
        <>
            <div className="invite-user-modal">
                {!isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <TextInput
                            dataTest="invite-user_email-input"
                            label={txt.labels.email}
                            ref={emailRef}
                            className="invite-user-modal__email"
                            value={invite.email}
                            onChange={({ target: { value } }) => onChange('email', value.trim())}
                            error={isSubmitTouched && !EMAIL_RULE.test(invite.email) && txt.errors.email}
                        />

                        <RadioInput
                            dataTest="invite-user_role"
                            label={txt.labels.role}
                            hint={txt.labels.role}
                            items={roles}
                            type="row"
                            value={invite.role}
                            onChange={({ target: { value } }) => onChange('role', value)}
                        />
                        {/*<TagInput
                            dataTest="invite-user"
                            label={txt.labels.assignPrivateWorkspace}
                            items={workspaces}
                            value={invite.workspaces}
                            onChange={value => handleChange('workspaces', value)}
                        />*/}
                        {/*<Alert message={txt.alerts.conflictOfHideProfit} type="warning" />*/}
                        {/*<CheckBoxInput
                            dataTest="invite-user_hide-profit-checkbox"
                            label={txt.labels.hideProfit}
                            value={invite.isHideProfit}
                            onChange={({ target: { checked } }) => handleChange('isHideProfit', checked)}
                        />*/}

                        {(userSettings.role === USER_ROLES.OWNER || userSettings.isAllowSharingReport) && (
                            <div className="invite-user-modal__row">
                                <CheckBoxInput
                                    label="Allow sharing reports"
                                    value={invite.isAllowSharingReport}
                                    onChange={({ target: { checked } }) => onChange('isAllowSharingReport', checked)}
                                />
                            </div>
                        )}

                        {isAddUserLoading && <Loader isFetching />}
                    </>
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.invite}
                onConfirm={onSubmit}
                isConfirmDisabled={(isSubmitTouched && !isAllValid) || isAddUserLoading}
                onReject={onClose}
            />
        </>
    );
};

export default InviteUserModal;
