import React, { useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';

import Btn from '../Btn/Btn';
import Icon from '../../Icon';

import './copy-btn.scss';

const CopyBtn = ({
    copyText,
    className,
    type = 'link',
    isLabelHidden = false,
    isIconHidden = false,
    dataTest = null
}) => {
    const txt = Langs[global.lng];
    const [label, setLabel] = useState(txt.buttons.copy);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(copyText)
            .then(() => {
                setLabel(txt.buttons.copied);
                setTimeout(setLabel, 1000, txt.buttons.copy);
            })
            .catch(() => {
                toast.error(txt.toasts.commonError);
            });
    };

    return (
        <Btn dataTest={dataTest} className={cn('copy-btn', className)} type={type} onClick={handleCopy}>
            {!isIconHidden && <Icon.Copy size={16} />}
            {!isLabelHidden && label}
        </Btn>
    );
};

export default CopyBtn;
