import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import ConfirmDeleteModal from '../../../../Modules/Modals/confirm-delete-modal/confirm-delete-modal';
import ArchiveFlowModal from '../../../../Modules/Modals/ArchiveFlowModal/ArchiveFlowModal';
import ArchiveCampaignsModal from '../../../../Modules/Modals/archive-campaigns-modal/archive-campaigns-modal';

const ArchiveButton = ({
    chapter,
    entityId,
    entityName,
    isDisabled,
    onClick,
    type,
    onArchiveConfirm,
    campaignsCount,
    selectedRows,
    rows
}) => {
    const txt = Langs[global.lng];

    const isShowStopIntegratedTSCampainsCheckbox = (() => {
        if (selectedRows) {
            return (
                selectedRows.integration?.isActive &&
                selectedRows.integrationData?.isSuccessful &&
                !selectedRows.integrationData.isActionDisable
            );
        }

        return false;
    })();

    const showArchiveModal = () => {
        showModal({
            title: `Archive ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(entityName)}
                    entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                    type="archive"
                    onConfirm={onArchiveConfirm}
                />
            )
        });
    };

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') return onClick();

        switch (chapter) {
            case 'flows':
                if (campaignsCount > 0) {
                    showModal({
                        title: txt.titles.selectFlowsActions,
                        className: 'ArchiveFlowModal',
                        isStartWithLoading: true,
                        body: props => <ArchiveFlowModal {...props} currentFlowValue={entityId} />
                    });
                } else showArchiveModal();
                break;

            case 'campaigns':
                showModal({
                    title: `Archive ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
                    body: props => (
                        <ArchiveCampaignsModal
                            {...props}
                            entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                            entityId={entityId}
                            entityName={entityName}
                            rows={rows}
                            isShowCheckbox={isShowStopIntegratedTSCampainsCheckbox}
                        />
                    )
                });
                break;

            default:
                showArchiveModal();
        }
    };

    return (
        <Btn
            dataTest={`table-control__archive-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {txt.buttons.archive}
        </Btn>
    );
};

export default ArchiveButton;
