import React from 'react';
import { connect } from 'react-redux';
import './td-types.scss';

const WorkspaceList = ({ items, workspaces }) => (
    <div className="j4">
        {items?.map((elem, index) => (
            <span className="mr5" key={elem}>
                {workspaces?.find(({ value }) => value === elem)?.label || ''}
                {items.length > index + 1 ? ',' : null}
            </span>
        ))}
    </div>
);
const Store = ({
    oldState: {
        overall: { workspaces }
    }
}) => ({ workspaces });

export default connect(Store)(WorkspaceList);
