import React, { useEffect, useMemo, useRef, useCallback } from 'react';

import Langs from '../../../../Langs';
import { OFFER_CAP_TYPE } from '../../../../Constants/index';
import { checkPositiveNumber } from '../../../../Functions/utils';
import { transform } from '../../../../redux/transformers';
import useDelayedFocus from '../../../../Functions/hooks/useDelayedFocus';

import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import Switcher from '../../../Overall/UI/Switcher/Switcher';
import RadioInput from '../../../Overall/Forms/Inputs/RadioInput';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';

const OfferCapSettings = React.memo(
    ({
        workspaceId,
        publicWorkspaceId,
        offerId,
        isCapEnabled,
        capType,
        conversionCap,
        clickCap,
        originalEditableOffer,
        updateOffer,
        timeZone,
        redirectOffer,
        isNeedValidate,
        offers,
        timezones
    }) => {
        const txt = Langs[global.lng];

        const isFirstRender = useRef(true);
        const capLastTypeRef = useRef('conversionOrClick');

        const [conversionCapInputRef, setIsConversionCapFocus] = useDelayedFocus();
        const [clickCapInputRef, setIsClickCapFocus] = useDelayedFocus();
        // const [redirectOfferInputRef, setIsRedirectOfferFocus] = useDelayedFocus();

        const isConversionCapType = capType === OFFER_CAP_TYPE.CONVERSION;
        const isClickCapType = capType === OFFER_CAP_TYPE.CLICK;
        const isConversionOrClickType = capType === OFFER_CAP_TYPE.CONVERSION_OR_CLICK;
        const isConversionsCapTypeAllow = isConversionCapType || isConversionOrClickType;
        const isClickCapTypeAllow = isClickCapType || isConversionOrClickType;

        const handleCapFocus = () => {
            if (isConversionCapType) setIsConversionCapFocus(true);
            if (isClickCapType) setIsClickCapFocus(true);
            if (isConversionOrClickType) {
                if (!conversionCap && clickCap) setIsClickCapFocus(true);
                else setIsConversionCapFocus(true);
            }
        };

        useEffect(() => {
            if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
            }

            handleCapFocus();

            const getCapValueByType = ({ isConversionLastType, isClickLastType }) => {
                const originalClickCap = originalEditableOffer.current?.clickCap;
                const originalConversionCap = originalEditableOffer.current?.conversionCap;

                if (isConversionCapType) {
                    return {
                        newConversionCap: originalConversionCap || clickCap || conversionCap,
                        newClickCap: originalClickCap
                    };
                }

                if (isClickCapType) {
                    return {
                        newConversionCap: originalConversionCap,
                        newClickCap: originalClickCap || conversionCap || clickCap
                    };
                }

                if (isConversionOrClickType) {
                    return {
                        newConversionCap:
                            isConversionLastType && originalConversionCap ? originalConversionCap : conversionCap,
                        newClickCap: isClickLastType && originalClickCap ? originalClickCap : clickCap
                    };
                }
            };

            const isConversionLastType = capLastTypeRef.current === OFFER_CAP_TYPE.CONVERSION;
            const isClickLastType = capLastTypeRef.current === OFFER_CAP_TYPE.CLICK;
            const { newConversionCap, newClickCap } = getCapValueByType({ isConversionLastType, isClickLastType });

            updateOffer({ conversionCap: newConversionCap, clickCap: newClickCap });

            capLastTypeRef.current = capType;
        }, [capType]);

        const capItems = useMemo(
            () => [
                {
                    label: 'conversionCap',
                    value: OFFER_CAP_TYPE.CONVERSION,
                    hint: txt.hints.offerCapOption(txt.labels.conversions.toLowerCase())
                },
                {
                    label: 'clickCap',
                    value: OFFER_CAP_TYPE.CLICK,
                    hint: txt.hints.offerCapOption(txt.labels.clicks.toLowerCase())
                },
                {
                    label: 'conversionOrClickCap',
                    value: OFFER_CAP_TYPE.CONVERSION_OR_CLICK,
                    hint: txt.hints.offerCapBothOptions
                }
            ],
            [txt]
        );

        const redirectOffers = useMemo(
            () =>
                offers
                    ?.map(el => transform.redirectOffersList.import(el))
                    .filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId)
                    .filter(
                        offer =>
                            (!offer.isArchived || offer.value === redirectOffer) &&
                            !offer?.isCapEnabled &&
                            offer.value !== offerId
                    ),
            [offers, workspaceId, publicWorkspaceId]
        );

        const onCapTypeChange = useCallback(({ target: { value } }) => updateOffer({ capType: value }), []);
        const onConversionCapChange = useCallback(({ target: { value } }) => updateOffer({ conversionCap: value }), []);
        const onClickCapChange = useCallback(({ target: { value } }) => updateOffer({ clickCap: value }), []);
        const onRedirectOfferChange = useCallback(({ target: { value } }) => updateOffer({ redirectOffer: value }), []);

        const onOfferCapEnableChange = useCallback(status => {
            updateOffer({ isCapEnabled: status });
            if (status) handleCapFocus();
        }, []);

        const onTimeZoneChange = useCallback(
            ({ target: { value } }) => {
                updateOffer({ timeZone: value });
                // if (!redirectOffer) setIsRedirectOfferFocus(true);
            },
            [redirectOffer]
        );

        return (
            <ModalContentContainer>
                <Switcher
                    dataTest="create-offer_offer-cap"
                    className="p0 font-weight-500"
                    label={txt.labels.offerCap}
                    hint={txt.hints.offerCap}
                    hintType="bottom"
                    isSwitchOn={isCapEnabled}
                    onChange={onOfferCapEnableChange}
                >
                    <RadioInput
                        dataTest="create-offer_conversion-or-click-cap"
                        type="row"
                        className="j4 mt15 mb15"
                        items={capItems}
                        value={capType}
                        onChange={onCapTypeChange}
                    />

                    <div className="create-offer__conversion-cap">
                        {isConversionsCapTypeAllow && (
                            <TextInput
                                type="number"
                                dataTest="create-offer_conversion-cap-input"
                                label={txt.labels.conversionCap}
                                value={conversionCap || ''}
                                ref={conversionCapInputRef}
                                hint={txt.hints.offerDefineCap(txt.labels.conversions.toLowerCase())}
                                onChange={onConversionCapChange}
                                checkCorrectSymbol={checkPositiveNumber}
                                error={
                                    isNeedValidate && !conversionCap && txt.errors.required(txt.labels.conversionCap)
                                }
                            />
                        )}

                        {isClickCapTypeAllow && (
                            <TextInput
                                type="number"
                                dataTest="create-offer_click-cap-input"
                                label={txt.labels.clickCap}
                                value={clickCap || ''}
                                ref={clickCapInputRef}
                                hint={txt.hints.offerDefineCap(txt.labels.clicks.toLowerCase())}
                                onChange={onClickCapChange}
                                checkCorrectSymbol={checkPositiveNumber}
                                error={isNeedValidate && !clickCap && txt.errors.required(txt.labels.clickCap)}
                            />
                        )}

                        <SelectInput
                            dataTest="create-offer_time-zone-select-input"
                            label={txt.labels.timeZone}
                            hint={txt.hints.offerTimeZone}
                            items={timezones}
                            value={timeZone}
                            onChange={onTimeZoneChange}
                            error={isNeedValidate && !timeZone && txt.errors.required(txt.labels.timeZone)}
                            optionsDirection="top"
                            isSearchable
                        />
                    </div>
                    {/* <div className="j4 mtb20">
                        <Label
                            label={txt.labels.priority}
                            hint={txt.hints.offerPriority}
                            className="mr30"
                        />
                        <RadioInput
                            dataTest="create-offer_priority"
                            type="row"
                            items={priorities}
                            value={offer.priority}
                            onChange={({ target: { value } }) => onChange(value, 'priority')}
                        />
                    </div> */}
                    <SelectInput
                        dataTest="create-offer_redirect-offer-select-input"
                        label={txt.labels.redirectOffer}
                        hint={txt.hints.redirectOffer}
                        hintType="bottom"
                        items={redirectOffers}
                        value={redirectOffer}
                        // inputSearchRef={redirectOfferInputRef}
                        onChange={onRedirectOfferChange}
                        error={isNeedValidate && !redirectOffer && txt.errors.required(txt.labels.redirectOffer)}
                        className="mt15"
                        optionsDirection="top"
                        isSearchable
                    />
                </Switcher>
            </ModalContentContainer>
        );
    }
);

OfferCapSettings.displayName = 'OfferCapSettings';
export default OfferCapSettings;
