import React, { useState, useCallback, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Edit3, Check, X } from 'react-feather';
import { toast } from 'react-toastify';

import Langs from '../../../../../../Langs';
import { getResponseErrorMessage } from '../../../../../../Functions/utils';
import { useChangeIntegrationCampaignBidMutation } from '../../../../../../redux/api/integrationsApi';

import Loader from '../../../../../Loader';
import useFocus from '../../../../../../Functions/hooks/useFocus';

const TdIntegrationBid = React.memo(
    ({
        trafficSourceId,
        trafficSourceCampaignId,
        bid,
        bidMode,
        currencies,
        defaultCurrency,
        disabled,
        rowId,
        similarCampaigns,
        campaignListRequestParams
    }) => {
        if (!trafficSourceCampaignId) return null;
        const txt = Langs[global.lng];

        const [isProcessing, setIsProcessing] = useState(false);
        const [isEdit, setIsEdit] = useState(false);
        const [editableValue, setEditableValue] = useState(bid);
        const savedInitialValue = useRef(bid);

        useEffect(() => {
            setEditableValue(bid);
            savedInitialValue.current = bid;
        }, [bid]);

        const [changeBid, { isError: isChangeBidError, error: changeBidError, isSuccess: isChangeBidSuccess }] =
            useChangeIntegrationCampaignBidMutation();

        useEffect(() => {
            if (isChangeBidSuccess) {
                toast.success(txt.toasts.saved);
                setIsProcessing(false);
                setIsEdit(false);
            }
        }, [isChangeBidSuccess]);

        useEffect(() => {
            if (isChangeBidError) {
                toast.error(getResponseErrorMessage(changeBidError.data, txt.toasts.saveError));
                setIsProcessing(false);
            }
        }, [isChangeBidError]);

        const onEditClick = useCallback(
            e => {
                if (disabled) return;
                e.preventDefault();
                e.stopPropagation();

                setIsEdit(true);
            },
            [disabled]
        );

        const onEditReject = useCallback(e => {
            e.preventDefault();
            e.stopPropagation();

            setIsEdit(false);
            setEditableValue(savedInitialValue.current);
        }, []);

        const onEditConfirm = e => {
            e.preventDefault();
            e.stopPropagation();

            setIsProcessing(true);
            changeBid({
                data: { trafficSourceId, trafficSourceCampaignId, price: parseFloat(editableValue), campaignId: rowId },
                rowId,
                similarCampaigns,
                campaignListRequestParams
            });
        };

        const [inputRef, setFocus] = useFocus();

        useEffect(() => {
            if (isEdit) setFocus();
        }, [isEdit]);

        const onBidChange = useCallback(({ target: { value: inputValue } }) => setEditableValue(inputValue), []);

        const currency = currencies?.find(el => el.value === defaultCurrency ?? 'USD');
        const currencySymbol = currency && currency.symbol !== currency.value ? currency.symbol : '';

        return !disabled ? (
            <div className="td-integration td-integration__editable">
                <div
                    className={cn('td-integration__editable-wrapper', {
                        'td-integration__editable-wrapper--edit': isEdit
                    })}
                >
                    <span className="td-integration__editable-mode">{bidMode?.toUpperCase()}</span>
                    <span className="td-integration__editable-currency">{currencySymbol}</span>

                    {isEdit ? (
                        <>
                            <input
                                value={editableValue}
                                onChange={onBidChange}
                                ref={inputRef}
                                onClick={e => e.stopPropagation()}
                            />

                            <div
                                className="td-integration__icon td-integration__editable-icon td-integration__editable-icon--group"
                                onClick={onEditConfirm}
                            >
                                <Check size={16} stroke="#000" />
                            </div>

                            <div
                                className="td-integration__icon td-integration__editable-icon td-integration__editable-icon--group"
                                onClick={onEditReject}
                            >
                                <X size={16} stroke="#000" />
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="td-integration__editable-value">{editableValue}</span>

                            <div
                                className={cn('td-integration__icon td-integration__editable-icon', {
                                    'td-integration--disabled': disabled
                                })}
                                onClick={onEditClick}
                            >
                                <Edit3 size={15} stroke="#2680c1" style={{ marginRight: 2 }} />
                            </div>
                        </>
                    )}
                </div>

                {isProcessing && <Loader type="cell" isFetching />}
            </div>
        ) : (
            <div className="td-integration td-integration__just">
                <span className="td-integration__editable-mode">{bidMode?.toUpperCase()}</span>
                <span className="td-integration__editable-currency">{currencySymbol}</span>
                <span className="td-integration__editable-value">{editableValue}</span>
            </div>
        );
    }
);

TdIntegrationBid.displayName = 'TdIntegrationBid';
export default TdIntegrationBid;
