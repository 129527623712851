import React from 'react';

import CloudOffIcon from '../../../../../media/images/cloud-off.svg';

import styles from './no-data.module.scss';

const NoData = ({ label, onClick }) => {
    return (
        <div className={styles.noData}>
            {onClick ? (
                <div className={styles.noData__button} onClick={onClick}>
                    Create new {label}
                </div>
            ) : (
                <div className={styles.noData__inner}>
                    <img src={CloudOffIcon} className="mr15" /> No data found
                </div>
            )}
        </div>
    );
};

export default NoData;
