import React from 'react';

import './switcher-is-not.scss';

const SwitcherIsNot = ({
    item: { value = false, text = ['is', 'is not'] } = {},
    onChange = () => null,
    className,
    dataTest,
    isNotText
}) => {
    const handleClick = () => {
        onChange(!value);
    };

    return (
        <div className={`switcherIsNot-container ${className}`}>
            <div className="df">
                <div
                    data-test={`switcher-is-not_${dataTest}-div`}
                    className={`switcherIsNot switcherIsNot__border${value ? '--on' : '--off'}`}
                    onClick={handleClick}
                >
                    <div className="switcherIsNot__on">{isNotText?.[0] || text[0]}</div>

                    <span
                        className={`switcherIsNot__switcher ${
                            value ? 'switcher__move bg--color--panache' : 'bg--light-pinky'
                        }`}
                    />

                    <div className="switcherIsNot__off">{isNotText?.[1] || text[1]}</div>
                </div>
            </div>
        </div>
    );
};

export default SwitcherIsNot;
