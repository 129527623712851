import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import reducer from './JS/redux/uniReducer';
import indexApi from './JS/redux/api/indexApi';
import authApi from './JS/redux/api/authApi';
import rtkQueryErrorLogger from './JS/redux/errorLogger';
import NameSpace from './JS/redux/api/name-space';

import appSlice from './JS/redux/app-slice';
import mainTableSlice from './JS/redux/main-table-slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
    reducer: {
        oldState: reducer,
        // new store
        [NameSpace.APP]: appSlice,
        [NameSpace.MAIN_TABLE]: mainTableSlice,
        [NameSpace.API]: indexApi.reducer,
        [NameSpace.AUTH_API]: authApi.reducer
    },
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            // FIXME: delete it after migrate to toolkit
            serializableCheck: {
                ignoredActions: ['Branch'],
                ignoredPaths: ['oldState.modal', 'oldState.absTooltip']
            },
            immutableCheck: false
        }).concat([indexApi.middleware, authApi.middleware, rtkQueryErrorLogger]);
    },
    enhancers: [sentryReduxEnhancer]
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept(['./JS/redux/uniReducer.js', './JS/redux/app-slice.js'], () => store.replaceReducer(reducer));
}

export default store;
