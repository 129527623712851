import React from 'react';

import Langs from '../../../../Langs';
import { showModal } from '../../../../redux/operations';
import { transformFromCamelCase } from '../../../../Functions/utils';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import ArchiveCampaignsModal from '../../../../Modules/Modals/archive-campaigns-modal/archive-campaigns-modal';

const MultiArchiveButton = ({ chapter, isDisabled, onClick, type, rowsForReport, rows }) => {
    const txt = Langs[global.lng];

    const isShowStopIntegratedTSCampainsCheckbox = (() => {
        if (rowsForReport) {
            return rowsForReport.some(rowId => {
                const item = rows.find(it => it.value === rowId);

                if (
                    item &&
                    item.integration?.isActive &&
                    item.integrationData?.isSuccessful &&
                    !item.integrationData?.isActionDisable
                ) {
                    return true;
                } else return false;
            });
        }

        return false;
    })();

    const onButtonClick = () => {
        if (onClick && typeof onClick === 'function') onClick();
        else {
            showModal({
                title: `Archive ${txt.singulars[chapter]?.toLowerCase() ?? 'element'}?`,
                body: props => (
                    <ArchiveCampaignsModal
                        {...props}
                        entity={txt.singulars[chapter]?.toLowerCase() ?? 'element'}
                        isShowCheckbox={isShowStopIntegratedTSCampainsCheckbox}
                        rowsForReport={rowsForReport}
                        rows={rows}
                        isMulti
                    />
                )
            });
        }
    };

    return (
        <Btn
            dataTest={`table-control__multi-archive-btn--${chapter}`}
            type={type}
            disabled={isDisabled}
            onClick={onButtonClick}
        >
            {`${txt.buttons.archive} ${transformFromCamelCase(chapter, ' ')}(${rowsForReport?.length})`}
        </Btn>
    );
};

export default MultiArchiveButton;
