import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const offersApiTagTypes = {
    offers: 'offers',
    offersUrlParams: 'offersUrlParams'
};

const offersApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(offersApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getOffersList: builder.query({
            query: params => `/offers${params}`,
            keepUnusedDataFor: 0,
            transformResponse: response => ({
                items: response.items.map(el => transform.offers.importList(el)),
                total: response.total,
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: offersApiTagTypes.offers, id: 'LIST' },
                { type: offersApiTagTypes.offers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getOfferById: builder.query({
            query: id => `/offers/${id}`,
            transformResponse: response => transform.offers.import(response),
            providesTags: (result, error, id) => [
                { type: offersApiTagTypes.offers, id },
                { type: offersApiTagTypes.offers, id: 'ALL_ITEMS' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getOffersAllNames: builder.query({
            query: () => `/offers/all/names`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: offersApiTagTypes.offers, id: 'ALL_NAMES_LIST' },
                { type: offersApiTagTypes.offers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getOffersWithColumns: builder.query({
            query: params => `/offers/all/with-columns${params}`,
            providesTags: () => [
                { type: offersApiTagTypes.offers, id: 'WITH_COLUMNS_LIST' },
                { type: offersApiTagTypes.offers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getOffersChart: builder.query({
            query: params => `/offers/all/chart${params}`,
            providesTags: () => [
                { type: offersApiTagTypes.offers, id: 'CHART' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getOffersUrlParams: builder.query({
            query: () => `/offers/dictionaries/url-params`,
            providesTags: () => [offersApiTagTypes.offersUrlParams, { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }],
            keepUnusedDataFor: 86400 //24h
        }),

        deleteOffer: builder.mutation({
            query: ({ id }) => ({
                url: `/offers/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: offersApiTagTypes.offers, id },
                        { type: offersApiTagTypes.offers, id: 'ALL' },
                        { type: offersApiTagTypes.offers, id: 'CHART' }
                    ];
                else return [];
            }
        }),

        restoreOffer: builder.mutation({
            query: ({ id }) => ({
                url: `/offers/${id}/restore`,
                method: 'PATCH'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: offersApiTagTypes.offers, id },
                        { type: offersApiTagTypes.offers, id: 'ALL' },
                        { type: offersApiTagTypes.offers, id: 'CHART' }
                    ];
                else return [];
            }
        })
    })
});

export const {
    useGetOffersListQuery,
    useGetOfferByIdQuery,
    useGetOffersAllNamesQuery,
    useGetOffersWithColumnsQuery,
    useGetOffersChartQuery,
    useGetOffersUrlParamsQuery,
    useDeleteOfferMutation,
    useRestoreOfferMutation
} = offersApi;
