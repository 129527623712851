import indexApi from './indexApi';
import { download } from '../../Functions/utils';

const downloadApi = indexApi.injectEndpoints({
    endpoints: builder => ({
        downloadFile: builder.mutation({
            queryFn: async ({ url, fileName }, api, extraOptions, baseQuery) => {
                const responseData = await baseQuery({
                    url: `/${url}`,
                    responseHandler: response => {
                        if (response.ok) return response.blob();
                        return response.json();
                    }
                });

                if (responseData.error) {
                    return {
                        data: {
                            isOk: false,
                            message: responseData.error.data?.errors?.message
                        }
                    };
                } else {
                    download(responseData.data, fileName);
                    return { data: { isOk: true } };
                }
            }
        })
    })
});

export const { useDownloadFileMutation } = downloadApi;
