import React from 'react';
import moment from 'moment-timezone';
import { Check, Clock } from 'react-feather';

const JustSupportAccessDate = ({ dateFrom, dateTo, isExpired }) => {
    const isCurrentDay = moment(dateTo).isSame(moment(dateFrom), 'days');

    const formattedDateFrom = moment(dateFrom).format('L LT');
    const formattedDateTo = isCurrentDay ? moment(dateTo).format('LT') : moment(dateTo).format('L LT');

    return (
        <div className="j4">
            {isExpired ? <Check size={16} stroke="#009329" /> : <Clock size={16} stroke="#9AA0B9" />}
            <span className="ml8 mr5">{formattedDateFrom}</span>-<span className="ml5">{formattedDateTo}</span>
        </div>
    );
};

export default JustSupportAccessDate;
