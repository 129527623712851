/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from 'react';
import produce from 'immer';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { omitKeys, transposeItems, clone, camelToKebabCase } from '../../../Functions/utils';
import { exportDateTransformer, transformKeyExceptionsForDownloadFile } from '../../../redux/transformers';
import { hideModal } from '../../../redux/operations';

import indexApi from '../../../redux/api/indexApi';
import { useGetStorageQuery, useChangeStorageMutation } from '../../../redux/api/storeApi';

import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import ColumnsListV from './ColumnsListV';
import DateRangeInput from '../../Overall/Forms/Inputs/DateRangeInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Btn from '../../Overall/UI/Btn/Btn';
import Loader from '../../Loader';

const COLLUMNS_IN_ROW = 4;

const ShowDownloadFileCheckboxAfterCtrlC = () => {
    const txt = Langs[global.lng];

    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();
    const [changeStorage] = useChangeStorageMutation();

    const isShowDownloadFile = storage?.common?.showModals?.DownloadFile ?? true;

    const onChange = ev => {
        const newStorage = clone(storage);
        newStorage.common.showModals.DownloadFile = ev.target.checked;

        changeStorage(newStorage);
    };

    return (
        <div className="download-csv__checkbox-wraper">
            <CheckBoxInput
                dataTest="download-csv_show-mdal-checkbox"
                label={txt.labels.getShowForCtrlC()}
                value={isShowDownloadFile}
                disabled={!isStorageLoaded}
                onChange={onChange}
            />
        </div>
    );
};

const DownloadFile = ({
    rows,
    columns: initialColumns,
    dateRange: initialDateRange,
    chapter,
    dataForRender,
    onClose,
    isHideOnlySelectedRows
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const selectedRows = rows
        .filter(item => item.isChecked && (chapter === 'reports' ? !item?.parents?.length : true))
        .map(item => item.value || item.id);

    const getVisibleColumnsQuantity = input => input.reduce((ac, item) => (!item.isHide ? ac + 1 : ac), 0);
    const initialVisibleColumnsQuantity = useMemo(() => getVisibleColumnsQuantity(initialColumns), [initialColumns]);

    const [isSelectedAll, setSelectAll] = useState({
        value: initialColumns?.length && initialVisibleColumnsQuantity === initialColumns.length,
        isStark: !(!initialVisibleColumnsQuantity || initialVisibleColumnsQuantity === initialColumns.length)
    });

    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const [dateRange, setDateRange] = useState(initialDateRange);
    const [isOnlySelectedRows, setIsOnlySelectedRows] = useState(false);

    const [columns, setColumns] = useState(
        initialColumns
            .filter(item => item.key !== 'checked')
            .filter(item => !item.isNotExported)
            .map(item => omitKeys(item, ['disabled', 'important']))
    );

    const onColumnVisbilityChange = (checked, field) => {
        setColumns(
            produce(draft => {
                const element = draft.find(el => el.key === field.key);
                element.isHide = !checked;
            })
        );
    };

    const toggleSelectAll = ({ target: { checked } }) => {
        setColumns(
            produce(draft => {
                draft.forEach(el => {
                    el.isHide = !checked;
                });
            })
        );
    };

    const onConfirm = async outputType => {
        setIsLocalLoading(true);

        // const mainQuery = getMainTableRequestQuery(chapter, { ...dataForRender, dateRange }, chapter);

        const parameters = {
            [`order-by[${dataForRender.sorting?.key}]`]: dataForRender.sorting?.type,

            'filter[dateFrom]': exportDateTransformer(dateRange.start),
            'filter[dateTo]': exportDateTransformer(dateRange.end),
            ...(dateRange.compare
                ? {
                      [`filter[compareDateFrom]`]: exportDateTransformer(dateRange.startPrevDate),
                      [`filter[compareDateTo]`]: exportDateTransformer(dateRange.endPrevDate)
                  }
                : {})
        };

        if (chapter === 'reports') {
            dataForRender.filters.forEach((el, idx) => {
                parameters['filter[dtype][]'] = el.type;
                parameters['filter[did][]'] = idx > 0 ? el.value : el?.value?.join(',');
            });

            dataForRender.groups.forEach((el, idx) => {
                parameters[`filter[level${idx + 1}]`] = el;
            });
        }

        const mainQuery = Object.entries(parameters)
            .map(([key, value]) => value && `${key}=${value}`)
            .filter(el => el);

        const selectedColumnsQuery = columns
            .filter(
                item => !!((chapter !== 'campaigns' && item.key !== 'ecpm') || chapter === 'campaigns') && !item.isHide
            )
            .sort((a, b) => a.order - b.order)
            .map(item => `columns[]=${transformKeyExceptionsForDownloadFile(item.key)}`);

        const selectedRowsQuery = isOnlySelectedRows ? selectedRows.map(item => `filter[exportIds][]=${item}`) : [];

        const selectedTypeQuery = `filter[exportFormat]=${outputType}`;

        const params = [...mainQuery, ...selectedColumnsQuery, ...selectedRowsQuery, selectedTypeQuery].reduce(
            (ac, item, index) => (typeof item === 'string' ? `${ac}${index === 0 ? '?' : '&'}${item}` : ac),
            ''
        );

        const status = await dispatch(
            indexApi.endpoints.downloadFile.initiate({
                url: `${camelToKebabCase(chapter)}/all/export${params}`,
                fileName: `${camelToKebabCase(chapter)}__${moment().format('YYYY-MM-DD__HH-mm-ss')}.${outputType}`
            })
        );

        if (status.data.isOk === false) {
            toast.error(status.data.message ?? txt.toasts.loadingError);
            setIsLocalLoading(false);
        } else hideModal();
    };

    const visibleItemsQuantity = getVisibleColumnsQuantity(columns);

    useEffect(() => {
        if (visibleItemsQuantity === columns.length)
            setSelectAll({
                value: true,
                isStark: false
            });
        if (!visibleItemsQuantity)
            setSelectAll({
                value: false,
                isStark: false
            });
        else if (visibleItemsQuantity !== columns.length && (isSelectedAll.value || !isSelectedAll.isStark))
            setSelectAll({
                value: false,
                isStark: true
            });
    }, [columns]);

    return (
        <>
            <div className="mx-auto">
                {isLocalLoading ? <Loader /> : null}

                <div className="columns-settings">
                    <div className="columns-item">
                        <div className="columns-settings__subheader columns-settings__subheader--small">
                            <CheckBoxInput
                                dataTest="export-file_select-all-checkbox"
                                className="exportFileModal__select-all-checkbox"
                                label={isSelectedAll.value ? txt.labels.unselectAll : txt.labels.selectAll}
                                value={isSelectedAll.value}
                                isStark={isSelectedAll.isStark}
                                onChange={toggleSelectAll}
                                disabled={isLocalLoading}
                            />

                            <div className="exportFileModal__date-range-block">
                                <span className="exportFileModal__date-range-label">
                                    <b>{txt.labels.period}</b>
                                </span>

                                <DateRangeInput
                                    className="exportFileModal__date-range-iput"
                                    label={txt.labels.timeRange}
                                    hint={txt.hints.timeRange}
                                    value={dateRange}
                                    onChange={e => setDateRange(e)}
                                    disabled={isLocalLoading}
                                    // isCompareHidden
                                    hideArrows
                                />
                            </div>

                            {!isHideOnlySelectedRows && (
                                <CheckBoxInput
                                    className="exportFileModal__row-checkbox"
                                    dataTest="export-file_only-checked-rows-checkbox"
                                    label={txt.labels.exportOnlySelectedRowsLabel(selectedRows?.length || 0)}
                                    disabled={!selectedRows?.length}
                                    value={isOnlySelectedRows}
                                    onChange={({ target: { checked } }) => setIsOnlySelectedRows(checked)}
                                />
                            )}
                        </div>

                        <div className="columns-settings__content">
                            <div className="columns-settings__multi-column">
                                <ColumnsListV
                                    fields={transposeItems(
                                        [...columns].sort((a, b) => a.order - b.order),
                                        COLLUMNS_IN_ROW
                                    )}
                                    onChange={onColumnVisbilityChange}
                                    mode="small"
                                    isLoading={isLocalLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="exportFileModal__custom-buttons-bar">
                <ModalButtonBar
                    rejectButtonText={txt.buttons.cancel}
                    confirmButtonText={txt.buttons.downloadCsv}
                    onConfirm={() => onConfirm('csv')}
                    onReject={onClose}
                    isLoading={isLocalLoading}
                />

                <Btn
                    dataTest="export-file_export-xlsx-button"
                    className="exportFileModal__export-xlsx-button"
                    onClick={() => onConfirm('xlsx')}
                    disabled={isLocalLoading}
                    type="filled"
                >
                    {txt.buttons.downloadXLSX}
                </Btn>
            </div>
        </>
    );
};

export const ShowDownloadFileCheckbox = ShowDownloadFileCheckboxAfterCtrlC;

export default DownloadFile;
