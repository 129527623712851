import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { produce } from 'immer';
import { toast } from 'react-toastify';

import Langs from '../../Langs';
import { KEYBOARD_SERVICE_KEY } from '../../Constants';
import { getResponseErrorMessage } from '../../Functions/utils';

import useKeyPress from '../../Functions/hooks/useKeyPress';

import { useChangePasswordMutation } from '../../redux/api/authApi';

import AuthForm, { AuthBtn } from '../../Modules/AuthForm/AuthForm';
import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';

const PageChangePassword = () => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const { token } = useParams();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [userData, setUserData] = useState({
        password: '',
        confirmPassword: ''
    });

    const updateUserData = useCallback((prop, value) => {
        setUserData(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const errors = {
        password: userData.password.length < 8 ? txt.errors.min(txt.labels.password, 8) : '',
        confirmPassword: userData.password !== userData.confirmPassword ? txt.errors.same(txt.labels.password) : ''
    };

    const isAllValid = Object.keys(errors).every(el => !errors[el]);

    const [
        changePassword,
        { isSuccess: isChangePasswordSuccess, isError: isChangePasswordError, error: changePasswordPasswordError }
    ] = useChangePasswordMutation();

    useEffect(() => {
        if (isChangePasswordSuccess) {
            toast.success(txt.toasts.passwordChanged);
            history.push('/login');
        }
    }, [isChangePasswordSuccess]);

    useEffect(() => {
        if (isChangePasswordError) {
            toast.error(getResponseErrorMessage(changePasswordPasswordError.data, txt.errors.passwordChangingError));
            setIsLoading(false);
        }
    }, [isChangePasswordError]);

    const onSubmit = () => {
        setIsSubmitted(true);

        if (isAllValid) {
            setIsLoading(true);
            changePassword({ token, data: userData });
        }
    };

    useKeyPress(KEYBOARD_SERVICE_KEY.ENTER, onSubmit);

    return (
        <AuthForm
            title={txt.auth.typeNewPass}
            questionLabel={txt.auth.alreadyHaveAcc}
            exitLabel={txt.auth.logIn}
            onExit={() => history.push('/login')}
        >
            <TextInput
                dataTest="change_password-input"
                value={userData.password}
                onChange={({ target: { value } }) => updateUserData('password', value)}
                icon="password"
                type="password"
                label={txt.labels.password}
                placeholder={txt.labels.password}
                error={isSubmitted && errors.password}
                disabled={isLoading}
                className="mb20"
            />

            <TextInput
                dataTest="change_confirm-password-input"
                value={userData.confirmPassword}
                onChange={({ target: { value } }) => updateUserData('confirmPassword', value)}
                icon="password"
                type="password"
                label={txt.labels.confirmPassword}
                placeholder={txt.labels.confirmPassword}
                error={isSubmitted && errors.confirmPassword}
                disabled={isLoading}
                className="mb20"
            />

            <AuthBtn disabled={isLoading || (isSubmitted && !isAllValid)} onClick={onSubmit} isLoading={isLoading}>
                {txt.auth.changePass}
            </AuthBtn>
        </AuthForm>
    );
};

export default PageChangePassword;
