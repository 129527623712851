import React from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';
import { USER_ROLES } from '../../../../../Constants';
import { showModal } from '../../../../../redux/operations';

import useDelayedInput from '../../../../../Functions/hooks/useDelayedInput';
import useReturnFocus from '../../../../../Functions/hooks/useReturnFocus';

import {
    changeMainTableFilters,
    changeMainTablePagination,
    changeMainTableSearch
} from '../../../../../redux/main-table-slice';

import indexApi from '../../../../../redux/api/indexApi';
import { sharedReportsApiTagTypes } from '../../../../../redux/api/sharedReportsApi';

import TableControlContainer from '../../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Overall/UI/Btn/Btn';
import { SelectInput } from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../../../Overall/Forms/Inputs/TextInput';
import TablePagination from '../../../../Overall/UI/TablePagination/TablePagination';
import CreateSharedReportModal from '../../../../Modals/create-shared-report-modal/create-shared-report-modal';
import ConfirmDeleteModal from '../../../../Modals/confirm-delete-modal/confirm-delete-modal';

const sharedReportAccessStatus = {
    ACTIVE: 'active',
    STOPPED: 'stopped',
    EXPIRED: 'expired'
};

const TableControls = ({
    chapter,
    isLoading,
    activeRow,
    search,
    filters,
    pagination,
    usersList,
    storage,
    userSettings,
    stopAccess,
    activateAccess,
    deleteSharedReport
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const contentSwitcher = 'shared-reports';

    const onEditReportClick = () => {
        showModal({
            title: txt.titles.editSharedReport,
            body: props => (
                <CreateSharedReportModal {...props} storage={storage} chapter={chapter} id={activeRow.value} />
            )
        });
    };

    const onStopAccessClick = () => stopAccess({ id: activeRow.value });
    const onActivateAccessClick = () => activateAccess({ id: activeRow.value });

    const onDeleteReportClick = () => {
        showModal({
            title: 'Delete report?',
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(activeRow.name, true)}
                    entity={'report'}
                    onConfirm={() => deleteSharedReport({ id: activeRow.value })}
                    autoClose={true}
                />
            )
        });
    };

    const onFilterChange = (value, key) => {
        dispatch(
            changeMainTableFilters({
                chapter,
                filterKey: key,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    };

    const [searchText, setSearchText, searchInputRef] = useDelayedInput(value => {
        dispatch(
            changeMainTableSearch({
                chapter,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    }, search);

    useReturnFocus(isLoading, searchInputRef);

    return (
        <TableControlContainer>
            <div className="table-control-container__links">
                <Btn
                    dataTest={`table-control_edit-btn_${contentSwitcher}`}
                    type="link"
                    disabled={isLoading || !activeRow}
                    onClick={onEditReportClick}
                >
                    {txt.buttons.editReport}
                </Btn>

                <Btn
                    dataTest={`table-control_stop-access-btn_${contentSwitcher}`}
                    type="link"
                    disabled={
                        isLoading ||
                        !activeRow ||
                        activeRow.status === sharedReportAccessStatus.STOPPED ||
                        activeRow.status === sharedReportAccessStatus.EXPIRED
                    }
                    onClick={onStopAccessClick}
                >
                    {txt.buttons.stopAccess}
                </Btn>

                <Btn
                    dataTest={`table-control_activate-access-btn_${contentSwitcher}`}
                    type="link"
                    disabled={
                        isLoading ||
                        !activeRow ||
                        activeRow.status === sharedReportAccessStatus.ACTIVE ||
                        activeRow.status === sharedReportAccessStatus.EXPIRED
                    }
                    onClick={onActivateAccessClick}
                >
                    {txt.buttons.activateAccess}
                </Btn>

                <Btn
                    dataTest={`table-control_delete-btn_${contentSwitcher}`}
                    type="link"
                    disabled={isLoading || !activeRow}
                    onClick={onDeleteReportClick}
                >
                    {txt.buttons.delete}
                </Btn>
            </div>

            <div className="table-control-container__filters">
                <SelectInput
                    dataTest={`table-control_actions-select-input_${contentSwitcher}`}
                    value={userSettings.role === USER_ROLES.USER ? userSettings.id : filters.userId ?? 'all'}
                    items={[{ value: 'all', label: 'All users' }, ...usersList]}
                    onChange={({ target: { value } }) => onFilterChange(value, 'userId')}
                    disabled={isLoading || userSettings.role === USER_ROLES.USER}
                />

                <SelectInput
                    dataTest={`table-control_actions-select-input_${contentSwitcher}`}
                    value={filters.status ?? 'all'}
                    items={[
                        { value: 'all', label: 'All access statuses' },
                        { value: sharedReportAccessStatus.ACTIVE, label: 'Active' },
                        { value: sharedReportAccessStatus.STOPPED, label: 'Stopped' },
                        { value: sharedReportAccessStatus.EXPIRED, label: 'Expired' }
                    ]}
                    onChange={({ target: { value } }) => onFilterChange(value, 'status')}
                    disabled={isLoading}
                />

                <TextInput
                    dataTest={`table-control_search_${contentSwitcher}-input`}
                    placeholder={txt.placeholders.search}
                    ref={searchInputRef}
                    value={searchText}
                    onChange={setSearchText}
                    disabled={isLoading}
                    reset
                />
            </div>

            <Btn
                dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                type="filled"
                disabled={isLoading}
                className="ml8"
                onClick={() =>
                    dispatch(
                        indexApi.util.invalidateTags([{ type: sharedReportsApiTagTypes.sharedReports, id: 'LIST' }])
                    )
                }
            >
                <RefreshCw />
                {txt.buttons.refresh}
            </Btn>

            <div className="table-control-container__pagination">
                <TablePagination
                    value={pagination}
                    onChange={data => {
                        dispatch(
                            changeMainTablePagination({
                                chapter,
                                value: data
                            })
                        );
                    }}
                    disabled={isLoading}
                />
            </div>
        </TableControlContainer>
    );
};

export default TableControls;
