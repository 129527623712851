import React from 'react';
import moment from 'moment-timezone';
import cn from 'classnames';

export const TdDateTypes = {
    DEFAULT: 'default',
    DATE_ONLY: 'dateOnly',
    COLUMN: 'column'
};

const TdDate = ({ item, type = TdDateTypes.DEFAULT, disabled }) => {
    const date = moment(item).format('L');
    const time = moment(item.split('+')?.[0]).format('LT');

    if (type === TdDateTypes.DATE_ONLY) {
        return (
            <span
                className={cn('mr5', {
                    'td-text--disabled': disabled
                })}
            >
                {moment(item).format('L')}
            </span>
        );
    }

    return (
        <div className={cn({ j4: type === TdDateTypes.DEFAULT }, { 'td-date--column': type === TdDateTypes.COLUMN })}>
            <span
                className={cn('mr5', {
                    'td-text--disabled': disabled
                })}
            >
                {date}
            </span>
            <span className="color--pause">{time}</span>
        </div>
    );
};

export default TdDate;
