import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import store from './store';
import App from './JS/App';

import './scss/styles.scss';

const language = window.navigator
    ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
    : 'en';
global.lng = language.substr(0, 2).toLowerCase();
if (global.lng !== 'en') {
    global.lng = 'en';
}

if (JSON.parse(process.env.IS_SENTRY ?? 'false')) {
    Sentry.init({
        dsn: 'https://01bba3ca68644ca484ad9b69343c3e32@o1072391.ingest.sentry.io/6230311', // process.env.SENTRY_DSN
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        normalizeDepth: 5
    });
}

// ========================================
const container = document.getElementById('app');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
);
