import indexApi from './indexApi';

export const projectApiTagTypes = {
    projectData: 'projectData',
    projectInfo: 'projectInfo',
    projectLandingProtection: 'projectLandingProtection',
    trackingUrls: 'trackingUrls',
    ipFiltering: 'ipFiltering',
    currentPlan: 'currentPlan',
    useNextPaymentMethod: 'useNextPaymentMethod',
    planTransition: 'planTransition'
};

export const projectApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(projectApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getProjectInfo: builder.query({
                query: () => `/projects/info`,
                providesTags: [projectApiTagTypes.projectInfo]
            }),

            getPreparationProjectInfo: builder.query({
                // Нужен для отключения pollingInterval. Иначе опрос будет происходить постоянно из-за одинакового кэша
                query: () => `/projects/info`
            }),

            //

            getLandingProtectionType: builder.query({
                query: () => `/projects/landing-protection`,
                providesTags: [projectApiTagTypes.projectLandingProtection]
            }),

            getTrackingUrls: builder.query({
                query: () => `/projects/tracking-urls`,
                providesTags: [projectApiTagTypes.trackingUrls]
            }),

            getIpFilteringStatus: builder.query({
                query: () => `/projects/ip-filtering`,
                providesTags: [projectApiTagTypes.ipFiltering]
            }),

            getProjectCurrentPlan: builder.query({
                query: () => `/projects/plan`,
                providesTags: [projectApiTagTypes.currentPlan]
            }),

            getUseNextPaymentMethodStatus: builder.query({
                query: () => `/projects/payment-method-ranking`,
                providesTags: [projectApiTagTypes.useNextPaymentMethod]
            }),

            getProjectPlanTransitionInfo: builder.query({
                query: planId => `/projects/plan/${planId}/transition-info`,
                providesTags: (result, error, planId) => [
                    { type: projectApiTagTypes.planTransition, id: planId },
                    { type: projectApiTagTypes.planTransition, id: 'LIST' }
                ]
            }),

            changeLandingProtectionScriptType: builder.mutation({
                query: value => ({
                    url: '/projects/landing-protection-script-type',
                    method: 'PATCH',
                    body: { landingProtectionScriptType: value }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [projectApiTagTypes.projectLandingProtection];
                    return [];
                }
            }),

            changeIpFilteringStatus: builder.mutation({
                query: value => ({
                    url: '/projects/ip-filtering',
                    method: 'PATCH',
                    body: { ipFiltering: value }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [projectApiTagTypes.ipFiltering];
                    return [];
                }
            }),

            changeUseNextPaymentMethodStatus: builder.mutation({
                query: value => ({
                    url: '/projects/payment-method-ranking',
                    method: 'PATCH',
                    body: { isEnabledPaymentMethodRanking: value }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [projectApiTagTypes.useNextPaymentMethod];
                    return [];
                }
            }),

            changeProjectPlan: builder.mutation({
                query: data => ({
                    url: '/projects/plan',
                    method: 'PATCH',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error)
                        return [
                            projectApiTagTypes.currentPlan,
                            projectApiTagTypes.projectInfo, // update balance
                            'paymentList',
                            { type: projectApiTagTypes.planTransition, id: 'LIST' },
                            { type: 'plans', id: 'LIST' },
                            { type: 'invoices', id: 'LIST' }
                        ];
                    return [];
                }
            }),

            checkPromocode: builder.mutation({
                query: promoCode => ({
                    url: `/projects/check/promo/${promoCode}`,
                    method: 'GET'
                })
            })
        })
    });

export const {
    useGetProjectInfoQuery,
    useGetPreparationProjectInfoQuery,
    useGetLandingProtectionTypeQuery,
    useGetTrackingUrlsQuery,
    useGetIpFilteringStatusQuery,
    useGetProjectCurrentPlanQuery,
    useGetUseNextPaymentMethodStatusQuery,
    useGetProjectPlanTransitionInfoQuery,
    useChangeLandingProtectionScriptTypeMutation,
    useChangeIpFilteringStatusMutation,
    useChangeUseNextPaymentMethodStatusMutation,
    useChangeProjectPlanMutation,
    useCheckPromocodeMutation
} = projectApi;
