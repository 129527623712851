import React, { useState } from 'react';
import cn from 'classnames';
import moment from 'moment-timezone';
import { CSSTransition } from 'react-transition-group';

import Langs from '../../../../../../Langs';
import { getFlowActionRadiosItems } from '../../../../../../redux/operations';

import ActionsGroup from '../../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import Label from '../../../../../Overall/Forms/Inputs/Label';
import SelectInput from '../../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Alert from '../../../../../Overall/UI/Alert/Alert';
import RadioInput from '../../../../../Overall/Forms/Inputs/RadioInput';

import '../flow-main-option.scss';

const FlowCampaignsListItem = ({ className, type, currentFlow, campaign, onChange, flows, commonSettings }) => {
    const txt = Langs[global.lng];

    const flowsList = flows
        .filter(item => item.id !== currentFlow)
        .map(item => ({ value: item.id ?? item.value, label: item.name ?? item.label }));

    const correctOriginalSettingsDate = campaign ? campaign.rotation?.originalSettingsAt?.split('+')[0] : null;
    const originalSettingsDate = campaign
        ? `${moment(correctOriginalSettingsDate).format('L')} ${moment(correctOriginalSettingsDate).format('LT')}`
        : null;

    const alertType = (() => {
        if (type === 'modal') return campaign.isDeleted ? 'secondary' : 'warning';
        return campaign.isDeleted ? 'danger' : 'secondary';
    })();

    let radioButtons = getFlowActionRadiosItems(campaign);
    if (!flowsList.length) radioButtons = radioButtons.filter(item => item.value !== 'change');

    const getInitialRadioValue = () => {
        if (type !== 'modal' || campaign.actionFlowId === currentFlow) return 'accept';
        return !campaign.actionFlowId ? 'original' : 'change';
    };

    const [radioValue, setRadioValue] = useState(getInitialRadioValue());
    const [changeToFlowId, setChangeToFlowId] = useState(
        type === 'modal' && campaign.actionFlowId && campaign.actionFlowId !== currentFlow
            ? campaign.actionFlowId
            : flowsList[0]?.value
    );

    const getActionProperties = (isDeleted, newRadioValue, changeToFlowIdValue) => {
        const actionFlowIdValue = (() => {
            if (type !== 'modal' && !isDeleted) return null;
            if (newRadioValue === 'accept') return currentFlow;
            return newRadioValue === 'original' ? null : changeToFlowIdValue;
        })();

        if (type === 'modal')
            return {
                isDeleted: !isDeleted,
                action: 'remove',
                actionFlowId: actionFlowIdValue
            };

        return {
            isDeleted,
            action: isDeleted ? 'remove' : null,
            actionFlowId: actionFlowIdValue
        };
    };

    const rightComponent = (() => {
        if (type === 'modal') return null;

        return campaign.isDeleted ? (
            <ActionsGroup
                onUndo={() => onChange({ ...campaign, ...getActionProperties(false, radioValue, changeToFlowId) })}
            />
        ) : (
            <ActionsGroup
                onDelete={() => onChange({ ...campaign, ...getActionProperties(true, radioValue, changeToFlowId) })}
            />
        );
    })();

    const getInfoText = () => {
        if (type !== 'modal') return null;

        if (campaign.rotation.originalSettingsAt && commonSettings.commonCheckbox)
            return txt.texts.getTextForArchiveFlowOriginAction(originalSettingsDate);

        return commonSettings.commonRadio === 'change'
            ? txt.texts.getTextForArchiveFlowChangeAction(
                  flowsList.find(item => item.value === commonSettings.commonDropdown).label
              )
            : txt.texts.getTextForArchiveFlowAcceptAction();
    };

    return (
        <div className={cn(className, 'flow-campaign')}>
            <Alert
                type={alertType}
                LeftComponent={<span>{campaign.displayName}</span>}
                RightComponent={rightComponent}
            />

            {type === 'modal' && !campaign.isDeleted && (
                <div className="flow-campaign__modal-info-block">
                    <span className="flow-campaign__modal-info">{getInfoText()}</span>
                </div>
            )}

            <CSSTransition
                unmountOnExit
                timeout={800}
                in={campaign.isDeleted}
                classNames="campaign-main-option__actions-animation"
            >
                <div>
                    <div className="flow-campaign__actions-wraper">
                        <Label
                            label={type === 'modal' ? txt.labels.actionAfterArchive : txt.labels.actionAfterDelete}
                            className="flow-campaign__actions-header"
                        />

                        <RadioInput
                            dataTest="create-edit-flow_actions"
                            label={null}
                            type="column"
                            items={radioButtons}
                            value={radioValue}
                            onChange={({ target: { value } }) => {
                                setRadioValue(value);
                                onChange({ ...campaign, ...getActionProperties(true, value, changeToFlowId) });
                            }}
                        />

                        {radioValue === 'change' && !!flowsList.length && (
                            <SelectInput
                                dataTest="create-edit-flow_action-change_value"
                                value={changeToFlowId}
                                onChange={({ target: { value } }) => {
                                    setChangeToFlowId(value);
                                    onChange({ ...campaign, ...getActionProperties(true, radioValue, value) });
                                }}
                                items={flowsList}
                                optionsDirection={'bottom'}
                                className="flow-campaign__change-flow-dropdown"
                                isSearchable
                            />
                        )}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default FlowCampaignsListItem;
