import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../Constants';
import { hideModal, showModal } from '../../../redux/operations';
import { getIntegrationIcon } from '../../Tools/integrations/get-integration-icon';

import {
    useGetTrafficSourcesTemplateQuery,
    useGetTrafficSourcesTemplateListQuery
} from '../../../redux/api/templatesApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import TSIntegrationModal from '../ts-integration-modal/ts-integration-modal';
import FacebookIntegrationModal from '../ts-integration-modal/components/facebook-integration-modal/facebook-integration-modal';
import GoogleIntegrationModal from '../ts-integration-modal/components/google-integration-modal/google-integration-modal';

import './add-integration-modal.scss';

const AddIntegrationModal = ({ onClose }) => {
    const txt = Langs[global.lng];

    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [isConfirmPressed, setIsConfirmPressed] = useState(false);

    const { data: templateList, isSuccess: isTemplateListLoaded } = useGetTrafficSourcesTemplateListQuery();

    const { data: templateItem, isSuccess: isTemplateItemLoaded } = useGetTrafficSourcesTemplateQuery(
        selectedTemplateId,
        { skip: !isConfirmPressed }
    );

    const templatesWithIntegration = templateList?.filter(el => el.isIntegrationAvailable);

    useEffect(() => {
        if (isTemplateItemLoaded) {
            hideModal();

            const ModalBody = (() => {
                if (templateItem.name === 'Facebook') return FacebookIntegrationModal;
                if (templateItem.name === 'Google Ads - Search/Display') return GoogleIntegrationModal;

                return TSIntegrationModal;
            })();

            const warningModalConfig = {
                title: txt.titles.closeWithoutSave(),
                message: txt.texts.warningModal(),
                confirmButtonText: 'yesCloseForm',
                confirmButtonType: 'danger'
            };

            showModal({
                title: txt.titles.integrateAccount,
                warningModalConfig,
                body: props => (
                    <ModalBody
                        {...props}
                        templateName={templateItem.name}
                        templateId={selectedTemplateId}
                        mode={ENTITY_MODE_STATUS.CREATE}
                        fields={templateItem.integrationAuthFields}
                    />
                )
            });
        }
    }, [isTemplateItemLoaded]);

    return (
        <>
            <div className="add-integration-modal">
                {isTemplateListLoaded ? (
                    <div className="add-integration-modal__items">
                        {templatesWithIntegration?.map(el => {
                            const Img = getIntegrationIcon(el.name.split(' - ')[0]);

                            return (
                                <div
                                    key={el.id}
                                    className={cn('add-integration-modal__item', {
                                        active: selectedTemplateId === el.id
                                    })}
                                    onClick={() => setSelectedTemplateId(el.id)}
                                >
                                    {Img && (
                                        <img
                                            src={Img}
                                            alt="Integration logo"
                                            className={`add-integration-modal__item--${el.name}`}
                                            width={112}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <Loader />
                )}

                {isConfirmPressed && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.next}
                onReject={onClose}
                isConfirmDisabled={!selectedTemplateId || isConfirmPressed}
                onConfirm={() => setIsConfirmPressed(true)}
            />
        </>
    );
};

export default AddIntegrationModal;
