import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import useNewClickOutside from '../../../Functions/hooks/useNewClickOutside';
import Langs from '../../../Langs';

import { useChangeReportsMutation } from '../../../redux/api/reportsApi';

import ButtonIcon from '../../../components/table-control-services/button-icon/button-icon';
import Icon from '../Icon';
import AddBookmarkFavorite from './components/add-bookmark-favorite/add-bookmark-favorite';
import { clone } from '../../../Functions/utils';

const TabItem = ({ reports, tab, tabs, refresh, scrollTo }) => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const location = useLocation();

    const [isTabPopupOpen, setIsTabPopupOpen] = useState(false);

    const [changeReport] = useChangeReportsMutation();

    const itemRef = useRef(null);

    const onStarClick = e => {
        e.preventDefault();

        if (!tab.isFavorite) setIsTabPopupOpen(true);
        else {
            const result = clone(reports);
            const index = result.findIndex(el => el.value === tab.id);

            if (index >= 0) {
                result[index].bookmark.isFavorite = false;
                changeReport(result);
            }
        }
    };

    const handleCloseTab = () => {
        const result = clone(reports);
        const index = result.findIndex(el => el.value === tab.id);
        if (index === -1) return;

        if (tab.isFavorite) result[index].bookmark.isActive = false;
        else result.splice(index, 1);

        changeReport(result);

        if (location.pathname === tab.path) {
            const currentIdx = tabs.findIndex(el => el.path === tab.path);
            history.push(currentIdx > 0 ? tabs[currentIdx - 1].path : '/home');
        }

        refresh();
    };

    const onAdded = label => {
        const result = clone(reports);
        const index = result.findIndex(el => el.value === tab.id);

        if (index >= 0) {
            result[index].bookmark.isFavorite = true;
            result[index].bookmark.label = label;
            changeReport(result);
        }

        setIsTabPopupOpen(false);
        refresh();
    };

    const handleMouseDown = ev => {
        if (ev.buttons === 4) {
            handleCloseTab(ev);
            return;
        }

        history.push(tab.path);
    };

    const reportId = location.pathname.split('/').pop();

    useEffect(() => {
        scrollTo(itemRef);
    }, [location.pathname]);

    const containerRef = useNewClickOutside(() => {
        if (isTabPopupOpen) setIsTabPopupOpen(false);
    });

    return (
        <div
            className={cn('menu-header-tabs__tab', {
                'menu-header-tabs__tab--active': reportId === tab.id
            })}
            ref={itemRef}
        >
            <div className="mr5 menu-header-tabs__text" onMouseDown={handleMouseDown}>
                <ButtonIcon size={16} type={tab.icon} className="mr5" />
                <span>{txt.labels[tab.id] || tab.label}</span>
            </div>

            <div className="df" ref={containerRef}>
                <div className="menu-header-tabs__star">
                    <Icon.Star
                        size={16}
                        className={tab.isFavorite ? 'bookmarks__star--filled' : 'bookmarks__star--empty'}
                        onClick={onStarClick}
                    />

                    {isTabPopupOpen && (
                        <AddBookmarkFavorite
                            onAdded={onAdded}
                            tabLabel={tab.label}
                            setIsTabPopupOpen={setIsTabPopupOpen}
                        />
                    )}
                </div>

                <Icon.X size={16} onClick={handleCloseTab} className="menu-header-tabs__close" />
            </div>
        </div>
    );
};

export default TabItem;
