export const topMenuConstructor = {
    mainMenu: [
        {
            label: 'dashboard',
            icon: 'Airplay'
        },
        {
            label: 'campaigns',
            icon: 'Crosshair'
        },
        {
            label: 'flows',
            icon: 'Shuffle'
        },
        {
            label: 'offers',
            icon: 'Flag'
        },
        {
            label: 'landers',
            icon: 'Clipboard'
        },
        {
            label: 'trafficSources',
            icon: 'Traffic'
        },
        {
            label: 'affiliateNetworks',
            icon: 'Users'
        }
        // {
        //     label: 'triggers',
        //     icon: 'Sliders'
        // }
    ],
    additionalMenu: [
        { label: 'paths' },
        { label: 'conversions' },
        {
            label: 'countryMenu',
            items: [{ label: 'country' }, { label: 'stateProvince' }, { label: 'city' }, { label: 'language' }]
        },
        {
            label: 'connection',
            items: [
                { label: 'remoteIp' },
                { label: 'connectionType' },
                { label: 'ispCarrier' },
                { label: 'mobileCarrier' },
                { label: 'proxy' }
            ]
        },
        {
            label: 'device',
            items: [{ label: 'deviceType' }, { label: 'brand' }, { label: 'model' }, { label: 'resolution' }]
        },
        {
            label: 'os',
            items: [{ label: 'osName' }, { label: 'osVersion' }]
        },
        {
            label: 'browser',
            items: [{ label: 'browserName' }, { label: 'browserVersion' }]
        },
        {
            label: 'period',
            items: [{ label: 'month' }, { label: 'dayOfMonth' }, { label: 'dayOfWeek' }, { label: 'hourOfDay' }]
        },
        {
            label: 'referer',
            items: [{ label: 'refererDomain' }, { label: 'refererUrl' }]
        },
        { label: 'errorLog' }
    ],
    groupsItems: [
        { value: 'campaigns', label: 'campaigns' },
        { value: 'offers', label: 'offers' },
        { value: 'landers', label: 'landers' },
        { value: 'trafficSources', label: 'trafficSources' },
        { value: 'affiliateNetworks', label: 'affiliateNetworks' },
        { value: 'flows', label: 'flows' },
        // { value: 'conversions', label: 'conversions' },
        { value: 'paths', label: 'paths' },
        { value: 'rules', label: 'rules' },
        {
            label: 'countryMenu',
            items: [
                { value: 'country', label: 'country' },
                { value: 'stateProvince', label: 'stateProvince' },
                { value: 'city', label: 'city' },
                { value: 'language', label: 'language' }
            ]
        },
        {
            label: 'connection',
            items: [
                { value: 'remoteIp', label: 'remoteIp' },
                { value: 'connectionType', label: 'connectionType' },
                { value: 'ispCarrier', label: 'ispCarrier' },
                { value: 'mobileCarrier', label: 'mobileCarrier' },
                { value: 'proxy', label: 'proxy' }
            ]
        },
        {
            label: 'device',
            items: [
                { value: 'deviceType', label: 'deviceType' },
                { value: 'brand', label: 'brand' },
                { value: 'model', label: 'model' },
                { value: 'resolution', label: 'resolution' }
            ]
        },
        {
            label: 'os',
            items: [
                { value: 'osName', label: 'osName' },
                { value: 'osVersion', label: 'osVersion' }
            ]
        },
        {
            label: 'browser',
            items: [
                { value: 'browserName', label: 'browserName' },
                { value: 'browserVersion', label: 'browserVersion' }
            ]
        },
        {
            label: 'period',
            items: [
                { value: 'month', label: 'month' },
                { value: 'dayOfMonth', label: 'dayOfMonth' },
                { value: 'dayOfWeek', label: 'dayOfWeek' },
                { value: 'hourOfDay', label: 'hourOfDay' }
            ]
        },
        {
            label: 'referer',
            items: [
                { value: 'refererDomain', label: 'refererDomain' },
                { value: 'refererUrl', label: 'refererUrl' }
            ]
        }
    ]
};
