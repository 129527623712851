import React from 'react';

const Tabs = ({ currentTab, onChange, className, tabs, type, children, dataTest }) => (
    <div className={`tabs dfc ${className}`}>
        <div className="tabs__inner">
            {tabs.map(item => (
                <div
                    data-test={`tab_${dataTest}_${item.id}_${type}`}
                    key={`tab__${item.id}`}
                    className={`${item.status === 'disabled' ? 'tab-disabled ' : null}
                        ${type === 'btn' ? 'tabsAsBtn-item ' : 'tabsAsLine-item '}
                        ${currentTab === item.id ? ' active' : ''}`}
                    onClick={() => onChange(item.id)}
                >
                    <span>{item.label}</span>

                    {item.count ? (
                        <span
                            className={`${
                                item.className === 'tabCount-revert' ? 'tabCount-revert tab-count' : 'tab-count'
                            }`}
                        >
                            {item.count}
                        </span>
                    ) : null}
                </div>
            ))}
        </div>
        <div className="tabs__body">{children}</div>
    </div>
);

export default Tabs;
