import React from 'react';
import cn from 'classnames';

const DropdownBalloon = ({ className, children }) => {
    const handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className={cn('dropdown-balloon', className)} onClick={handleClick}>
            <div className="dropdown-balloon__wrapper">{children}</div>
        </div>
    );
};

export default DropdownBalloon;
