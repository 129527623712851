import { v4 as uuid } from 'uuid';
import pathTemplate from './path-template';

const flowTemplate = {
    mainOptionValues: {
        name: '',
        workspaceId: '',
        group: 'dropdown',
        campaigns: [],
        campaignsToAdd: []
    },

    flowOptionValues: {
        trackingMethod: {
            type: null,
            value: 'redirect'
        },
        defaultRule: {
            paths: [{ ...pathTemplate, id: uuid() }]
        },
        rules: []
    }
};

export default flowTemplate;
