import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import produce from 'immer';

import Langs from '../../Langs';
import { HIDDEN_PROFIT_FILTER_METRICS, INTERVALS } from '../../Constants';
import { getActualDateRange } from '../../redux/operations';
import { omitKeys } from '../../Functions/utils';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetStorageQuery, useChangeStorageMutation } from '../../redux/api/storeApi';

import Icon from '../Overall/Icon';
import Btn from '../Overall/UI/Btn/Btn';
import ButtonsGroup from '../Overall/UI/ButtonsGroup/ButtonsGroup';
import SortOrderButtons from '../Overall/UI/SortOrderButtons/SortOrderButtons';
import DashboardTable from './DashboardTable/DashboardTable';
import DateRangeInput from '../Overall/Forms/Inputs/DateRangeInput';
import Indicators from './Indicators';
import Loader from '../Loader';
import DashboardChart from './DashboardChart';

import './dashboard.scss';

const intervals = Object.keys(omitKeys(INTERVALS, ['thisWeek', 'last3Days', 'last7Days', 'last14Days', 'lastYear']));
let buttonList = ['revenue', 'cost', 'profit', 'roi', 'visits', 'clicks', 'conversions'];

const Dashboard = () => {
    const txt = Langs[global.lng];

    const [isScrolled, setIsScrolled] = useState(false);
    const [isStartRefresh, setIsStartRefresh] = useState(false);

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();

    const [activeDashboardGrouping, setActiveDashboardGrouping] = useState(generalSettings?.dashboardGrouping);
    const [activeDashboardSorting, setActiveDashboardSorting] = useState(generalSettings?.dashboardSorting);
    const dateRange = storage?.common?.dateRange;

    useEffect(() => {
        if (generalSettings?.dashboardGrouping) setActiveDashboardGrouping(generalSettings.dashboardGrouping);
        if (generalSettings?.dashboardSorting) setActiveDashboardSorting(generalSettings.dashboardSorting);
    }, [generalSettings]);

    const [updateStorage, { isLoading: isStorageUpdate }] = useChangeStorageMutation();

    const onRefreshed = useCallback(() => setIsStartRefresh(false), []);
    const onDashboardGroupingChange = useCallback(item => setActiveDashboardGrouping(item), []);
    const onDashboardSortingChange = useCallback(item => setActiveDashboardSorting(item), []);
    const onDashboardDateChange = useCallback(
        key =>
            updateStorage(
                produce(storage, draft => {
                    draft.common.dateRange = { ...dateRange, intervalKey: key };
                })
            ),
        [storage]
    );
    const onDashboardCustomDateChange = useCallback(
        data =>
            updateStorage(
                produce(storage, draft => {
                    draft.common.dateRange = data;
                })
            ),
        [storage]
    );

    const isLoaded = isGeneralSettingsLoaded && isUserSettingsLoaded && isStorageLoaded;
    if (!isLoaded) return <Loader />;

    if (userSettings.isHideProfit) {
        buttonList = buttonList.filter(btn => !HIDDEN_PROFIT_FILTER_METRICS.includes(btn));

        if (HIDDEN_PROFIT_FILTER_METRICS.includes(activeDashboardGrouping)) {
            setActiveDashboardGrouping(buttonList[0]);
        }
    }

    const { intervalKey } = getActualDateRange(dateRange);

    return (
        <div className="dashboard">
            <div className={cn('dashboard-header', { 'dashboard-header--shadowed': isScrolled })}>
                <div className="dashboard-header__controls">
                    <span className="dashboard-header__title">{txt.labels.dashboard}</span>
                    <ButtonsGroup value={intervalKey} items={intervals} onChange={onDashboardDateChange} />
                    <DateRangeInput
                        value={dateRange}
                        onChange={onDashboardCustomDateChange}
                        isIntervalsHidden
                        isCompareHidden
                        isFocused={!intervals.includes(intervalKey)}
                    />
                </div>
                <div>
                    <Btn dataTest="dashboard_refresh-btn" type="white-refresh" onClick={() => setIsStartRefresh(true)}>
                        <Icon.RotateCcw />
                        {txt.buttons.refresh}
                    </Btn>
                </div>
            </div>
            <div
                className="dashboard-content"
                onScroll={({ target }) => {
                    setIsScrolled(target?.scrollTop > 0);
                }}
            >
                <div className="dashboard-charts">
                    <Indicators
                        dateRange={dateRange}
                        isHideProfit={userSettings.isHideProfit}
                        isFetching={isStorageUpdate}
                        isStartRefresh={isStartRefresh}
                        onRefreshed={onRefreshed}
                    />

                    <div className="dashboard-charts__charts">
                        <DashboardChart
                            dateRange={dateRange}
                            isHideProfit={userSettings.isHideProfit}
                            isFetching={isStorageUpdate}
                            isStartRefresh={isStartRefresh}
                            onRefreshed={onRefreshed}
                        />
                    </div>
                </div>
                <div className="dashboard-stats">
                    <div className="dashboard-stats__header">{txt.labels.statisticsBy}</div>
                    <ButtonsGroup
                        value={activeDashboardGrouping}
                        items={buttonList}
                        onChange={onDashboardGroupingChange}
                    />
                    <SortOrderButtons value={activeDashboardSorting} onChange={onDashboardSortingChange} />
                </div>
                <div className="dashboard-tables">
                    {['campaigns', 'trafficSources', 'countries', 'offers'].map(chapter => (
                        <DashboardTable
                            chapter={chapter}
                            dashboardSorting={activeDashboardSorting}
                            dashboardGrouping={activeDashboardGrouping}
                            dateRange={dateRange}
                            isFetching={isStorageUpdate}
                            rounding={storage.common?.rounding}
                            key={`dashboard-table-${chapter}`}
                            isStartRefresh={isStartRefresh}
                            onRefreshed={onRefreshed}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Dashboard);
