import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { getColumns } from '../../../components/main-table/services';
import { getParams, clone } from '../../../Functions/utils';
import { getActualDateRange } from '../../../redux/operations';
import { exportDateTransformer } from '../../../redux/transformers';

import { useGetUsersAllNamesQuery } from '../../../redux/api/usersApi';
import { useChangeStorageMutation } from '../../../redux/api/storeApi';
import { useGetEventLogQuery } from '../../../redux/api/eventLogApi';

import TableControls from './components/table-controls/table-controls';
import {
    changeMainTablePagination,
    changeMainTableSorting,
    selectEventLogTableData
} from '../../../redux/main-table-slice';
import MainTableItems from '../../MainTable/MainTableItems/MainTableItems';
import Loader from '../../Loader';

import './event-log.scss';

const EventLog = ({ chapter, storage }) => {
    const dispatch = useDispatch();

    const { data: users, isSuccess: isUsersLoaded } = useGetUsersAllNamesQuery();

    const { filters, sorting, pagination } = useSelector(selectEventLogTableData);

    const [dateRange, setDateRange] = useState(getActualDateRange(storage.common.dateRange));

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        [`filter[dateFrom]`]: exportDateTransformer(dateRange.start),
        [`filter[dateTo]`]: exportDateTransformer(dateRange.end)
    };

    Object.entries(filters).forEach(([key, value]) => {
        if (value !== 'all') parameters[`filter[${key}]`] = value;
    });

    const {
        data: eventLogs,
        isSuccess: isEventLogsLoaded,
        isFetching: isEventLogsFetching
    } = useGetEventLogQuery(getParams(parameters));

    useEffect(() => {
        if (eventLogs?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: eventLogs.pagination
                })
            );
        }
    }, [eventLogs]);

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.eventLog.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = eventLogs?.items || [];

    const isLoaded = isUsersLoaded && isEventLogsLoaded && !isEventLogsFetching;

    return (
        <>
            <div className={cn('table-container simple-main-table event-log')}>
                <div className={cn('main-table')}>
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            filters={filters}
                            pagination={pagination}
                            usersList={users ?? []}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                        />

                        <div className="main-table__visible-content">
                            {columns?.length > 0 ? (
                                <MainTableItems
                                    chapter={chapter}
                                    modifiedChapter={chapter}
                                    columns={columns}
                                    rows={rows}
                                    sorting={sorting}
                                    storage={storage}
                                    isLoading={!isLoaded}
                                    activeRow={null}
                                    onRowClick={() => null}
                                    onRowContextMenu={() => null}
                                    handleChange={onTableActionsChange}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventLog;
