import { useEffect, useRef } from 'react';

const useNewClickOutside = (hideComponent, options = {}) => {
    const ref = useRef(null);

    const checkCondition = target =>
        options.additionalComponent
            ? !ref.current?.contains(target) && !options.additionalComponent?.contains(target)
            : !ref.current?.contains(target);

    const handleClickOutside = ({ target }) => {
        if (checkCondition(target)) hideComponent();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        if (options.addCheckScroll) document.addEventListener('scroll', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            if (options.addCheckScroll) document.removeEventListener('scroll', handleClickOutside, true);
        };
    });

    return ref;
};

export default useNewClickOutside;
