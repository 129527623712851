import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { getHeight } from '../../../../../Functions/utils';
import { setState } from '../../../../../redux/operations';
import Langs from '../../../../../Langs';

import SelectItem from './SelectItem';

const ADDITIONAL_PADDING = 10;
const MIN_OPTIONS_BLOCK_HEIGHT = 100;
const INDENTATION = 4;

const getIsFitInViewport = el =>
    el?.getBoundingClientRect()?.top + getHeight(el) < document.documentElement.clientHeight;

const getFutureHeightOfOptionsBlock = el =>
    document.documentElement.clientHeight - el?.getBoundingClientRect()?.top - ADDITIONAL_PADDING;

const getTopPositionOfOptionsBlock = (el, mainContainer) =>
    mainContainer?.getBoundingClientRect()?.top - el?.offsetHeight - INDENTATION;

const OpenedPart = React.forwardRef((props, ref) => {
    const txt = Langs[global.lng];
    const {
        value,
        disabled,
        isHoverable,
        short,
        displayLabel = 'label',
        dataTest,
        optionsDirection = 'bottom',
        isSearchable,
        optionsType,
        isObjectUsed,
        handleItemClick,
        currentItems,
        isSelectedLabelEqualsToSearch,
        searchBy,
        isUseAbs,
        handleScroll,
        isLoadingMoreItems
    } = props;
    const { itemRef, parentRef, mainContainerRef } = ref?.current || {};
    const minWidth = mainContainerRef?.current ? mainContainerRef?.current?.offsetWidth : 0; // + INDENTATION;
    const [isUseTopPosition, setIsUseTopPosition] = useState(optionsDirection === 'top');
    const [styles, setStyles] = useState({ minWidth: `${minWidth}px` });

    const Item = ({ item, highlightedText }) =>
        isHoverable && (value === item?.value || value === item) ? null : (
            <SelectItem
                dataTest={`${dataTest}_${item?.value || item}`}
                label={
                    isObjectUsed ? txt?.labels[item?.[displayLabel]] || item?.[displayLabel] : txt?.labels[item] || item
                }
                item={item}
                isActive={isObjectUsed ? !isHoverable && value === item?.value : !isHoverable && value === item}
                disabled={disabled || item?.disabled}
                onItemClick={() => {
                    if (!item.disabled) handleItemClick(item);
                }}
                ref={value === item || value === item.value ? itemRef : null}
                highlightedText={highlightedText}
                icon={item?.icon || null}
                optionsType={optionsType}
            />
        );

    const Group = ({ item }) => (
        <>
            <div className="form-select-options__group-name">
                {txt?.labels[item?.[displayLabel]] || item?.[displayLabel]}
            </div>
            {item.items?.map((el, idx) => {
                return <Item item={el} key={idx} />;
            })}
        </>
    );

    useEffect(() => {
        return () => {
            setIsUseTopPosition(null);
            setStyles(null);
        };
    }, []);

    useEffect(() => {
        let newStyles = { ...styles };
        if (!getIsFitInViewport(parentRef?.current)) {
            if (getFutureHeightOfOptionsBlock(parentRef?.current) >= MIN_OPTIONS_BLOCK_HEIGHT) {
                newStyles = { ...newStyles, height: `${getFutureHeightOfOptionsBlock(parentRef?.current)}px` };
            } else if (isUseAbs)
                setState(getTopPositionOfOptionsBlock(parentRef?.current, mainContainerRef?.current), 'absTooltip/top');
            else setIsUseTopPosition(true);
        }
        setStyles(newStyles);
    }, [parentRef?.current]);

    return (
        <div
            ref={parentRef}
            className={cn('form-select-options', 'form-select-options--wide', {
                'form-select-options_abs': isUseAbs,
                'form-select-options--short': short,
                'form-select-options--hoverable': isHoverable,
                'form-select-options--options-bottom': !isUseAbs && !isUseTopPosition,
                'form-select-options--options-top': !isUseAbs && isUseTopPosition
            })}
            style={styles}
            onScroll={() => {
                if (handleScroll) handleScroll();
            }}
        >
            {currentItems.map((item, index) => {
                if (Object.prototype.hasOwnProperty.call(item, 'items')) {
                    return <Group item={item} key={index} />;
                }

                return (
                    <Item
                        item={item}
                        key={`${item?.value || item}_${index}`}
                        highlightedText={isSearchable && !isSelectedLabelEqualsToSearch ? searchBy : null}
                    />
                );
            })}

            {isLoadingMoreItems && <div className="multi-select-input__loader" />}
        </div>
    );
});

export default OpenedPart;
