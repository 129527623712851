import React from 'react';
import moment from 'moment-timezone';

import Langs from '../../../Langs';
import { MODAL_TYPES } from '../../../Constants';
import { getIntlFormattedValue } from '../../../Functions/utils';

import { useGetPlansQuery } from '../../../redux/api/plansApi';
import { useGetProjectCurrentPlanQuery } from '../../../redux/api/projectApi';

import { CloseBtn } from '../../Overall/UI/Btn/Btn';
import Plan from './components/plan/plan';
import Loader from '../../Loader';
import Notice, { NOTICE_TYPES } from '../../Overall/UI/Notice/Notice';

import './choose-plan-modal.scss';

const ChoosePlanModal = ({
    isLoading,
    onClose,
    onChoosePlan,
    isBlocking,
    isBlockedByEvents,
    blockedByEventsData,
    modalType,
    invoice
}) => {
    const txt = Langs[global.lng];

    const { data: planList, isSuccess: isPlanListLoaded } = useGetPlansQuery();
    const { data: currentPlan, isSuccess: isCurrentPlanLoaded } = useGetProjectCurrentPlanQuery();

    const isLoaded = !isLoading && isPlanListLoaded && isCurrentPlanLoaded;

    const getBlockingText = () => {
        if (isBlockedByEvents) {
            return (
                <Notice type={NOTICE_TYPES.DANGER}>
                    <span>
                        Your account is currently blocked because you have more than{' '}
                        {getIntlFormattedValue(blockedByEventsData.freeEvents)} events over Free plan limit. Сampaigns
                        were stopped on <b>{moment(blockedByEventsData.blockedAt).format('MMMM DD, YYYY')}.</b> Please
                        upgrade your plan.
                    </span>
                </Notice>
            );
        }

        if (isBlocking) {
            return (
                <Notice type={NOTICE_TYPES.DANGER}>
                    {modalType === MODAL_TYPES.BLOCKING
                        ? txt.labels.billing.blockAccount(
                              moment(invoice.blockDate).format('MMMM DD, YYYY'),
                              moment(invoice.blockDate).format('DD') - moment(invoice.period.dateFrom).format('DD')
                          )
                        : txt.alerts.renewPlanAlert}
                </Notice>
            );
        }

        return null;
    };

    return (
        <div className="chage-plan-modal">
            <div className="chage-plan-modal__header">
                <span className="chage-plan-modal__modal-title">{txt.labels.choosePlan}</span>
                {!isBlocking && <CloseBtn className="modal2__btn-close" onClick={onClose} />}
            </div>

            {isBlocking && <div className="chage-plan-modal__blocking-alert-block">{getBlockingText()}</div>}

            <div className="chage-plan-modal__body">
                {!isLoaded ? (
                    <Loader />
                ) : (
                    planList.map((item, idx) => {
                        const activePlanIndex = planList.findIndex(el => el.id === currentPlan.id);

                        return (
                            <Plan
                                key={item.id}
                                currentPlanIndex={idx}
                                activePlanIndex={activePlanIndex}
                                isActive={item.id === currentPlan.id}
                                plan={item}
                                onClick={() => onChoosePlan(idx, activePlanIndex, item)}
                                isBlocking={isBlocking}
                                isButtonDisabled={isBlockedByEvents && idx <= activePlanIndex}
                            />
                        );
                    })
                )}
            </div>

            <div className="chage-plan-modal__footer">
                <div className="chage-plan-modal__footer-wrapper">
                    <p className="chage-plan-modal__footer-text">
                        <span className="chage-plan-modal__footer-text-exception">*</span>
                        {txt.texts.billingAddPlanFooterText[0]}
                    </p>
                    <p className="chage-plan-modal__footer-text">
                        <span className="chage-plan-modal__footer-text-exception">**</span>
                        {txt.texts.billingAddPlanFooterText[1]}
                    </p>
                    <p className="chage-plan-modal__footer-text">{txt.texts.billingAddPlanFooterText[2]}</p>
                </div>
            </div>
        </div>
    );
};

export default ChoosePlanModal;
