import { useEffect, useRef, useState } from 'react';

const useDelayedFocus = () => {
    const htmlElRef = useRef(null);
    const [isFocus, setIsFocus] = useState(false);

    const setFocus = () => {
        if (htmlElRef.current) {
            htmlElRef.current.focus();
        }
    };

    useEffect(() => {
        if (isFocus) {
            setFocus();
            setIsFocus(false);
        }
    }, [isFocus]);

    return [htmlElRef, setIsFocus];
};

export default useDelayedFocus;
