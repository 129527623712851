import React from 'react';
import cn from 'classnames';

import { NavLink } from 'react-router-dom';

import Icon from '../../../Icon';
import { camelToKebabCase } from '../../../../../Functions/utils';

const MenuItem = ({ label, icon, isActive, dataTest, disabled, link, isReport, onClick }) => {
    const CurrentIcon = Icon[icon];

    const outputProps = {
        'data-test': dataTest,
        className: cn('menu-item', isActive && 'menu-item--active', disabled && 'menu-item--disabled'),
        ...(isReport ? { onClick } : {}),
        ...(!isReport ? { to: `/home/${camelToKebabCase(link)}` } : {})
    };

    const Component = inputProps => (!isReport ? <NavLink {...inputProps} /> : <div {...inputProps} />);

    return (
        <Component {...outputProps}>
            {CurrentIcon && <CurrentIcon size={16} className="menu-item__icon" />}
            {label}
        </Component>
    );
};

export default MenuItem;
