import React from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';
import { capitalize } from '../../../../Functions/utils';
import './td-types.scss';

const TdStatus = ({ item, disabled }) => {
    const renderStatus = () => {
        if (!item) return null;
        const status = capitalize(item);

        switch (item) {
            case 'active':
                return <span className="color--de-york">{status}</span>;
            case 'error':
                return <span className="color--froly">{status}</span>;
            case 'pending':
                return (
                    <div className="df">
                        <span className="color--sea-buckthorn mr5">
                            <Icon.Clock size={16} />
                        </span>
                        <span
                            className={cn({
                                'td-text--disabled': disabled
                            })}
                        >
                            {status}
                        </span>
                    </div>
                );
            case 'paid':
                return <span className="color--de-york text-uppercase">{status}</span>;
            case 'open':
                return <span className="color--froly text-uppercase">{status}</span>;
            default:
                return null;
        }
    };

    return renderStatus();
};

export default TdStatus;
