import React, { useState } from 'react';

import Langs from '../../Langs';
import { MODAL_TYPES } from '../../Constants/index';

import ModalButtonBar from '../Overall/Modal/ModalButtonBar/ModalButtonBar';
import CheckBoxInput from '../Overall/Forms/Inputs/CheckBoxInput';

import './modals.scss';

const ArchiveModal = ({
    onConfirm,
    onClose,
    confirmButtonText,
    subtitle,
    type = 'archive',
    isMulti,
    chapter,
    isShowCheckbox
}) => {
    const txt = Langs[global.lng];
    const [localLoading, setLocalLoading] = useState(false);
    const [isStopIntegratedTS, setIsStopIntegratedTS] = useState(false);

    const getArchiveModalText = entity => {
        if (type === MODAL_TYPES.RESTORE) return entity.restoreElement(isMulti);
        if (type === MODAL_TYPES.DOMAIN) return entity.deleteDomain;
        if (type === MODAL_TYPES.CONVERSION) return entity.deleteConversion;
        if (type === MODAL_TYPES.ARCHIVE) return entity.archiveElement(isMulti);
        else return entity.deleteElement(isMulti);
    };

    const modalBodyText = getArchiveModalText(txt.texts);
    const defaultConfirmButtonText = getArchiveModalText(txt.buttons);

    const onConfirmClick = () => {
        setLocalLoading(true);

        const options = {
            ...(chapter === 'campaigns' && type === 'archive' && isShowCheckbox ? { isStopIntegratedTS } : {})
        };

        return onConfirm(options)?.then(close => {
            setLocalLoading(false);
            return close?.ok ?? close;
        });
    };

    return (
        <>
            <div className="archive-modal">
                <div className="archive-modal__content">
                    {subtitle && <div className="archive-modal__subtitle">{subtitle}</div>}
                    {modalBodyText && <div>{modalBodyText}</div>}

                    {chapter === 'campaigns' && type === 'archive' && isShowCheckbox && (
                        <>
                            <div className="mt10">
                                Attention, you want to archive a campaign, but it has an Active status in the traffic
                                source. To stop or pause a campaign in the traffic source, select the check.
                            </div>

                            <CheckBoxInput
                                className="mt10"
                                label="Stop or pause a campaign in the traffic source"
                                value={isStopIntegratedTS}
                                onChange={({ target: { checked } }) => setIsStopIntegratedTS(checked)}
                            />
                        </>
                    )}
                </div>
            </div>
            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={confirmButtonText || defaultConfirmButtonText}
                onConfirm={onConfirmClick}
                confirmButtonType="danger"
                onReject={onClose}
                isLoading={localLoading}
            />
        </>
    );
};

export default ArchiveModal;
