import { useRef } from 'react';

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        if (htmlElRef.current) {
            // const { length } = htmlElRef.current.value;
            htmlElRef.current.focus();
            // htmlElRef.current.setSelectionRange(length, length);
        }
    };
    return [htmlElRef, setFocus];
};

export default useFocus;
