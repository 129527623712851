import React, { useEffect, useState, useCallback } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { produce } from 'immer';

import Langs from '../../Langs';
import { ALERT_TYPE, EMAIL_RULE } from '../../Constants';
import { getIntlFormattedValue, getResponseErrorMessage } from '../../Functions/utils';

import useFocus from '../../Functions/hooks/useFocus';

import { useCheckPromocodeMutation } from '../../redux/api/projectApi';
import { useRegisterMutation } from '../../redux/api/authApi';

import AuthForm, { AuthBtn } from '../../Modules/AuthForm/AuthForm';
import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';
import { AccordionLink } from '../../Modules/Overall/Accordion/Accordion';
import Btn from '../../Modules/Overall/UI/Btn/Btn';
import Icon from '../../Modules/Overall/Icon';
import JustAlert from '../../components/just-alert/just-alert';

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY || '6LeVRe8ZAAAAAG1sznsJA74ayCBXNaRfxXYWZu2D';

const PageRegister = () => {
    const txt = Langs[global.lng];

    const history = useHistory();

    const params = new URLSearchParams(window.location.search);
    const clickId = params.get('clickid');
    const inputPromo = params.get('promo');

    document.title = txt.auth.createYourAccPageTitle;

    const promoInitial = { promoSum: null, isRemoved: false, errorText: '' };

    const [promoRef, setPromoFocus] = useFocus();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [promo, setPromo] = useState(promoInitial);

    const isPromoValid = Number.isFinite(promo.promoSum);

    const [userData, setUserData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        promo: '',
        recaptcha: false
    });

    const errors = {
        firstname: !userData.firstname ? txt.errors.required(txt.labels.firstname) : '',
        lastname: !userData.lastname ? txt.errors.required(txt.labels.lastname) : '',
        email: !EMAIL_RULE.test(userData.email.toLowerCase()) ? txt.errors.email : '',
        recaptcha: !userData.recaptcha ? txt.errors.reCaptcha : ''
    };

    const isAllValid = Object.keys(errors).every(el => !errors[el]);

    const updateUserData = useCallback((prop, value) => {
        setUserData(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    // useEffect(() => {
    //     setPromo({ ...promoInitial, isRemoved: promo.isRemoved });
    // }, [userData.promo]);

    const [
        checkPromoCode,
        {
            data: checkPromoCodeData,
            isSuccess: isCheckPromoCodeSuccess,
            isError: isCheckPromoCodeError,
            error: checkPromoCodeError
        }
    ] = useCheckPromocodeMutation();

    useEffect(() => {
        if (isCheckPromoCodeSuccess) {
            if (!userData.promo) updateUserData('promo', inputPromo);
            setPromo({ ...promoInitial, promoSum: checkPromoCodeData.promoSum });
        }
    }, [isCheckPromoCodeSuccess]);

    useEffect(() => {
        if (isCheckPromoCodeError) {
            const errorText = getResponseErrorMessage(checkPromoCodeError.data, txt.errors.promoCodeCheckError);
            setPromo({ ...promoInitial, errorText });
        }
    }, [isCheckPromoCodeError]);

    const checkPromoCodeFn = () => {
        const code = userData.promo?.trim();
        if (code) checkPromoCode(code);
    };

    const [register, { isSuccess: isRegisterSuccess, isError: isRegisterError, error: registerError }] =
        useRegisterMutation();

    useEffect(() => {
        if (isRegisterSuccess) setIsRegistered(true);
    }, [isRegisterSuccess]);

    useEffect(() => {
        if (isRegisterError) {
            toast.error(getResponseErrorMessage(registerError.data, txt.errors.error));
            setIsLoading(false);
        }
    }, [isRegisterError]);

    const onSubmit = () => {
        setIsSubmitted(true);

        if (isAllValid) {
            setIsLoading(true);

            register({
                firstName: userData.firstname,
                lastName: userData.lastname,
                email: userData.email,
                promo: userData.promo,
                ...(clickId ? { postbackCid: clickId } : {})
            });
        }
    };

    const onVerify = value => {
        updateUserData('recaptcha', !!value);
        if (!!value && isSubmitted) setIsSubmitted(false);
    };

    useEffect(() => {
        if (JSON.parse(process.env.IS_PROD ?? 'false')) {
            window.platgtag('js', new Date());
            window.platgtag('config', 'UA-199965376-1');
        }
    }, []);

    useEffect(() => {
        if (inputPromo) checkPromoCode(inputPromo);
    }, []);

    if (isRegistered) {
        return (
            <AuthForm
                title={txt.texts.youHaveBeenRegistered}
                questionLabel={txt.auth.alreadyHaveAcc}
                exitLabel={txt.auth.logIn}
                onExit={() => history.push('/login')}
            >
                {txt.texts.checkYourEmailForFurtherInstructions}
            </AuthForm>
        );
    }

    return (
        <div className="register-form">
            <AuthForm
                title={txt.auth.createYourAcc}
                questionLabel={txt.auth.alreadyHaveAcc}
                exitLabel={txt.auth.logIn}
                onExit={() => history.push('/login')}
            >
                <TextInput
                    dataTest="register_first-name-input"
                    value={userData.firstname}
                    onChange={({ target: { value } }) => updateUserData('firstname', value)}
                    icon="login"
                    label={txt.labels.firstname}
                    placeholder={txt.placeholders.firstname}
                    error={isSubmitted && errors.firstname}
                    disabled={isLoading}
                    className="mb20"
                />

                <TextInput
                    dataTest="register_last-name-input"
                    value={userData.lastname}
                    onChange={({ target: { value } }) => updateUserData('lastname', value)}
                    icon="login"
                    label={txt.labels.lastname}
                    placeholder={txt.placeholders.lastname}
                    error={isSubmitted && errors.lastname}
                    disabled={isLoading}
                    className="mb20"
                />

                <TextInput
                    dataTest="register_email-input"
                    value={userData.email.trim()}
                    onChange={({ target: { value } }) => updateUserData('email', value)}
                    icon="email"
                    label={txt.labels.email}
                    placeholder={txt.labels.email}
                    error={isSubmitted && errors.email}
                    disabled={isLoading}
                />

                <AccordionLink
                    label={txt.labels.iHavePromoCode}
                    className="register-form__accordion-link"
                    setFocus={setPromoFocus}
                    isOpened={!!inputPromo}
                >
                    {!isPromoValid && (
                        <div className="rel">
                            <TextInput
                                dataTest="register_promo-input"
                                className="register-form__promo-input"
                                ref={promoRef}
                                value={userData.promo.trim()}
                                onChange={({ target: { value } }) => updateUserData('promo', value)}
                                icon="promo"
                                label={txt.labels.promo}
                                placeholder={txt.placeholders.promo}
                                error={isSubmitted && errors.promo}
                                disabled={isLoading}
                            />

                            <Btn className="register-form__promo-input-btn" type="link" onClick={checkPromoCodeFn}>
                                {txt.buttons.apply}
                            </Btn>

                            {promo.isRemoved && (
                                <div
                                    className={cn('register-form__promo-removed', {
                                        'register-form__promo-removed--error': errors.promo
                                    })}
                                >
                                    {txt.texts.promoCodeRemoved}
                                </div>
                            )}

                            {promo.errorText && <JustAlert type={ALERT_TYPE.WARNING}>{promo.errorText}</JustAlert>}
                        </div>
                    )}

                    {isPromoValid && (
                        <div className="register-form__promo-applied">
                            <Icon.Gift className="register-form__promo-applied-icon" stroke="#78c58d" size={16} />

                            <div>
                                <div className="register-form__promo-applied-code">
                                    {txt.texts.promoCodeApplied(userData.promo)}
                                </div>
                                <div className="register-form__promo-applied-credit">
                                    {txt.texts.promoCodeCredit(getIntlFormattedValue(promo.promoSum))}
                                </div>
                            </div>

                            <Btn
                                type="link"
                                onClick={() => {
                                    setPromo({ ...promoInitial, isRemoved: true });
                                    updateUserData('promo', '');
                                }}
                            >
                                {txt.buttons.remove}
                            </Btn>
                        </div>
                    )}
                </AccordionLink>

                <div className="mt16 form-terms">
                    {txt.auth.termsText}&nbsp;
                    <a
                        data-test="register_terms-link"
                        className="form-link"
                        href="https://plat.com/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {txt.auth.termsLink}
                    </a>
                </div>

                <div className="form-recaptcha mt10">
                    <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onVerify} />
                    <div className="form-label--error">{isSubmitted && errors.recaptcha}</div>
                </div>

                <AuthBtn disabled={isLoading || (isSubmitted && !isAllValid)} onClick={onSubmit} isLoading={isLoading}>
                    {txt.auth.createAcc}
                </AuthBtn>
            </AuthForm>
        </div>
    );
};

export default PageRegister;
