import React from 'react';

import Icon from '../../Icon';
import Btn from '../Btn/Btn';

import './sort-order-buttons.scss';

const SortOrderButtons = React.memo(({ value = 'DESC', onChange }) => {
    const states = ['DESC', 'ASC'];

    return (
        <div className="sort-buttons">
            {states.map(state => (
                <Btn
                    dataTest={`sort-order-buttons_${state}-btn`}
                    key={state}
                    icon="true"
                    type={value === state && 'filled'}
                    onClick={() => onChange(state)}
                >
                    <Icon.BarChart />
                </Btn>
            ))}
        </div>
    );
});

export default SortOrderButtons;
