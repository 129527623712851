import React, { useMemo } from 'react';
import cn from 'classnames';

import { checkPositivity } from '../../../Functions/utils';

import MainTableTd from './MainTableTd';

const MainTableTr = React.memo(
    ({
        chapter,
        row,
        cols,
        level = 0,
        bgStyle,
        activeRow,
        isSharedReportPage,
        isVerticalBorders,
        currencies,
        generalSettings,
        storage,
        onRowClick,
        onRowContextMenu,
        onCheckBoxChange,
        handleTreeToggle,
        onChange
    }) => {
        const hasImportantCols = cols.some(col => col.important);
        const hasAnyChild = row?.children?.length > 0;

        const handleRowClick = ({ target: { classList, type, tagName } }, cliskedRow) => {
            if (
                !(
                    classList.contains('td-checkbox') ||
                    classList.contains('check-container__mark') ||
                    classList.contains('campaign-row__warning-icon') ||
                    type === 'checkbox' ||
                    tagName === 'svg' ||
                    tagName === 'line'
                )
            ) {
                onRowClick(cliskedRow);
            }
        };

        const importantCols = useMemo(() => cols.filter(col => col.important && !col.isHide), [cols]);
        const simpleCols = useMemo(() => cols.filter(col => !col.important && !col.isHide), [cols]);

        return (
            <div
                className={cn(
                    'items-table__tr',
                    `items-table__tr--level-${level}`,
                    {
                        'items-table__tr--active':
                            activeRow?.value === row.value &&
                            activeRow?.parentId === row.parentId &&
                            activeRow?.subParentId === row.subParentId
                    },
                    { 'items-table__tr--checked': row.isChecked }
                )}
                style={{ backgroundColor: bgStyle }}
                onClick={e => handleRowClick(e, { ...row, level })}
                onContextMenu={e => onRowContextMenu(e, { ...row, level })}
            >
                {hasImportantCols && (
                    <div className="td-important">
                        <div style={{ backgroundColor: bgStyle }}>
                            <div
                                className={cn(
                                    'items-table__td',
                                    'td-color',
                                    `td-color--${checkPositivity(row.statistic?.profit ?? row.profit)}`
                                )}
                            />

                            {importantCols.map(({ key: cellKey, cellClassName, type, rounding, important }, colIdx) => (
                                <div
                                    className={cn(
                                        'items-table__td',
                                        cellClassName,
                                        {
                                            'items-table__td--vertical-border': isVerticalBorders && !important
                                        },
                                        {
                                            'items-table__td--important-last': colIdx + 1 === importantCols.length
                                        }
                                    )}
                                    key={`td_${cellKey}_${colIdx}`}
                                >
                                    <MainTableTd
                                        chapter={chapter}
                                        type={type}
                                        row={row}
                                        cellKey={cellKey}
                                        rounding={rounding}
                                        hasAnyChild={hasAnyChild}
                                        isSharedReportPage={isSharedReportPage}
                                        currencies={currencies}
                                        generalSettings={generalSettings}
                                        storage={storage}
                                        handleTreeToggle={handleTreeToggle}
                                        onCheckBoxChange={onCheckBoxChange}
                                        onChange={onChange}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {simpleCols.map(({ key: cellKey, cellClassName, type, subtype, rounding, important }, colIdx) => (
                    <div
                        className={cn('items-table__td', cellClassName, {
                            'items-table__td--vertical-border': isVerticalBorders && !important
                        })}
                        key={`td_${cellKey}_${colIdx}`}
                    >
                        <MainTableTd
                            chapter={chapter}
                            type={type}
                            subtype={subtype}
                            row={row}
                            cellKey={cellKey}
                            rounding={rounding}
                            hasAnyChild={hasAnyChild}
                            isSharedReportPage={isSharedReportPage}
                            currencies={currencies}
                            generalSettings={generalSettings}
                            storage={storage}
                            handleTreeToggle={handleTreeToggle}
                            onCheckBoxChange={onCheckBoxChange}
                            onChange={onChange}
                        />
                    </div>
                ))}

                <div className="items-table__td items-table__resize-td" />
            </div>
        );
    }
);

MainTableTr.displayName = 'MainTableTr';
export default MainTableTr;
