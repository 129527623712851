import indexApi from './indexApi';

export const dictionariesApi = indexApi.injectEndpoints({
    endpoints: builder => ({
        getBrowsers: builder.query({
            query: () => `/dictionaries/browsers`,
            keepUnusedDataFor: 82800 //23h
        }),

        getOs: builder.query({
            query: () => `/dictionaries/os`,
            keepUnusedDataFor: 82800 //23h
        }),

        getBrands: builder.query({
            query: () => `/dictionaries/brands`,
            keepUnusedDataFor: 82800 //23h
        }),

        getConnectionTypes: builder.query({
            query: () => `/dictionaries/connection-types`,
            keepUnusedDataFor: 82800 //23h
        }),

        getConnectionProxy: builder.query({
            query: () => `/dictionaries/connection-proxy`,
            keepUnusedDataFor: 82800 //23h
        }),

        getDeviceTypes: builder.query({
            query: () => `/dictionaries/device-types`,
            keepUnusedDataFor: 82800 //23h
        })
    })
});

export const {
    useGetBrowsersQuery,
    useGetBrandsQuery,
    useGetOsQuery,
    useGetConnectionTypesQuery,
    useGetConnectionProxyQuery,
    useGetDeviceTypesQuery
} = dictionariesApi;
