import React, { useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Langs from '../../../Langs';
import { Dispatch } from '../../../redux/rsd';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import ItemsTable from '../../Overall/ItemsTable/ItemsTable';
import { botFiltersTable } from './constructor';
import SettingsHeader from '../../Settings/UserSettings/SettingsHeader';
import SettingsSection from '../../Settings/SettingsSection/SettingsSection';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ConditionsModal from './ConditionsModal';
import { showModal } from '../../../redux/operations';

const AntiFraudKit = ({ tools, onUpdateRank1, templates, overall }) => {
    const txt = Langs[global.lng];
    const [isSwitched, setIsSwitched] = useState(true);
    const params = { templates, overall };
    const rows = tools['anti-fraud-kit'].rows.map(row => ({
        ...row,
        actions: row.isDeleted ? [{ type: 'undo' }] : [{ type: 'editRow' }, { type: 'deleteRow' }]
    }));
    const [fields, setFields] = useState({
        filterType: 'markAsBot',
        filterTypeInput: '',
        filterTypeRadios: [
            {
                value: 'markAsBot',
                label: 'markAsBot',
                hint: 'markAsBot'
            },
            {
                value: 'redirectBot',
                label: 'redirectBot',
                hint: 'redirectBot'
            },
            {
                value: 'closeConnection',
                label: 'closeConnection',
                hint: 'closeConnection'
            }
        ]
    });
    const { filterTypeRadios, filterType, filterTypeInput } = fields;

    const onChange = (prop, value) => {
        //onUpdateRank1(value, 'tools', 'anti-fraud-kit');
        setFields({ ...fields, [prop]: value });
    };

    const onSubmit = tool => {
        if (tool.value) {
            const rowIndex = rows.findIndex(item => item.value === tool.value);
            onUpdateRank1(
                [...rows.slice(0, rowIndex), tool, ...rows.slice(rowIndex + 1)],
                'tools',
                'anti-fraud-kit',
                'rows'
            );
        } else {
            onUpdateRank1([...rows, { ...tool, value: uuid() }], 'tools', 'anti-fraud-kit', 'rows');
        }
    };

    const onActionClick = (type, item) => {
        const selectedIndex = rows.findIndex(el => el.value === item.value);
        switch (type) {
            case 'editRow':
                showModal({
                    headerType: 'primary',
                    noHeader: true,
                    body: props => <ConditionsModal {...props} onSubmit={onSubmit} item={item} params={params} />
                });
                break;
            case 'deleteRow':
                onUpdateRank1(
                    [...rows.slice(0, selectedIndex), { ...item, isDeleted: true }, ...rows.slice(selectedIndex + 1)],
                    'tools',
                    'anti-fraud-kit',
                    'rows'
                );
                break;
            case 'undo':
                onUpdateRank1(
                    [...rows.slice(0, selectedIndex), { ...item, isDeleted: false }, ...rows.slice(selectedIndex + 1)],
                    'tools',
                    'anti-fraud-kit',
                    'rows'
                );
                break;
            default:
                break;
        }
    };

    const openAddConditionsModal = () => {
        showModal({
            headerType: 'primary',
            noHeader: true,
            body: props => <ConditionsModal {...props} onSubmit={onSubmit} params={params} />
        });
    };

    return (
        <>
            <div className="section--shadowed mb25">
                <div className="settings-wrapper settings-wrapper--wide">
                    <SettingsHeader
                        dataTest="anti-fraud-kit_bot-filters"
                        switcherLabel={txt.labels.botFilters}
                        isSwitcherShown
                        btnLabel={txt.buttons.addConditions}
                        onClick={() => openAddConditionsModal()}
                        isSwitchOn={isSwitched}
                        onChange={() => setIsSwitched(!isSwitched)}
                    />
                    <div className="wide">
                        <SettingsSection className="wide">
                            <div className="mb20">{txt.texts.antiFraudKit}</div>

                            <div className="antiFraudKit__filterType">
                                <RadioInput
                                    dataTest="add-domain-modal_custom-domain-destination"
                                    label={txt.labels.filterType}
                                    hint={txt.hints.filterType}
                                    items={filterTypeRadios}
                                    value={filterType}
                                    onChange={({ target: { value } }) => onChange('filterType', value)}
                                />

                                <TextInput
                                    dataTest="anti-fraud-kit_filter-type-input"
                                    value={filterTypeInput}
                                    onChange={({ target: { value } }) => onChange('filterTypeInput', value)}
                                    placeholder={txt.labels[filterType]}
                                />
                            </div>
                        </SettingsSection>

                        <ItemsTable
                            cols={botFiltersTable}
                            rows={rows}
                            onActionClick={onActionClick}
                            className="thead-decorate"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const Store = ({ oldState: { tools, templates, overall } }) => ({
    tools,
    templates,
    overall
});

export default connect(Store, Dispatch)(AntiFraudKit);
