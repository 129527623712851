import React, { useMemo } from 'react';

import { getIntegrationIcon } from '../../../../Tools/integrations/get-integration-icon';

const JustTdIntegrationName = ({ text }) => {
    const integrationName = text.split(' - ')[0];

    const Img = useMemo(() => getIntegrationIcon(integrationName, true), [integrationName]);

    return (
        <div className="j4">
            {Img && <img src={Img} className="mr5" width={16} height={14} />}
            <span>{text}</span>
        </div>
    );
};

export default JustTdIntegrationName;
