import React, { useState } from 'react';
import cn from 'classnames';
import { Lock } from 'react-feather';
import moment from 'moment-timezone';
import { MODAL_TYPES } from '../../../Constants';

import Langs from '../../../Langs';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Notice, { NOTICE_TYPES } from '../../Overall/UI/Notice/Notice';
import AddBillingInfoModal from '../add-billing-info-modal/add-billing-info-modal';
import AddPaymentMethodModal from '../add-payment-method-modal/add-payment-method-modal';
import LimitProgressBar from '../../Overall/LimitProgerssBar/LimitProgressBar';
import EventLimitModal from './modals/event-limit-modal';
import UpdatePaymentModal from './modals/update-payment-modal/update-payment-modal';
import UpdateBillingInfoModal from './modals/update-billing-info-modal/update-billing-info-modal';

import icon from '../../../../../media/images/lock-red.svg';

import './block-modal.scss';

const BlockModal = ({ data, isOwner, type, onClose, userEmail }) => {
    const txt = Langs[global.lng];

    const [isAddBillingInfo, setIsAddBillingInfo] = useState(false);
    const [isAddPaymentMethod, setIsAddPaymentMethod] = useState(false);

    const confirmInvoiceDetails = isOk => {
        if (isOk) {
            setIsAddBillingInfo(false);
            setIsAddPaymentMethod(true);
        }
    };

    const confirmPaymentMethod = () => onClose(null, true);

    switch (type) {
        case MODAL_TYPES.TRIAL:
            return (
                <div className="block-modal">
                    <div
                        className={cn('block-modal__remainder', {
                            'block-modal__remainder--not-expired': data.daysLeft > 0
                        })}
                    >
                        <LimitProgressBar
                            amount={data.daysLeft > 0 && data.hoursLeft > 0 ? data.daysLeft + 1 : data.daysLeft}
                            totalAmount={data.days}
                            isReverseType
                        >
                            {data.daysLeft === 0 && <Lock stroke="#ffcad6" />}
                            <div className="block-modal__remainder-value">
                                {data.daysLeft > 0 && data.hoursLeft > 0 ? data.daysLeft + 1 : data.daysLeft}
                            </div>
                            <div className="block-modal__remainder-text">{txt.texts.daysLeft}</div>
                        </LimitProgressBar>
                    </div>

                    <div className="block-modal__container">
                        <h2 className="block-modal__title">{txt.titles.updPaymentMethod}</h2>

                        <p className="block-modal__text">{txt.labels.blockModals.trial.youHaveDays(data.days)}</p>

                        {isOwner || (!isOwner && data.daysLeft === 0) ? <hr /> : null}

                        <p className="block-modal__text">{txt.labels.blockModals.trial.paymentMethodRequired}</p>

                        {!isOwner && data.daysLeft > 0 && (
                            <>
                                <hr />

                                <p className="block-modal__text">
                                    {txt.labels.blockModals.trial.contactOwnerToUpdate(data.ownerEmail)}
                                </p>
                            </>
                        )}

                        {data.daysLeft === 0 && !isAddBillingInfo && !isAddPaymentMethod && (
                            <Notice type={NOTICE_TYPES.WARNING}>
                                {!isOwner && data.daysLeft === 0
                                    ? txt.labels.blockModals.trial.contactOwnerToUpdateForce(data.ownerEmail)
                                    : txt.labels.blockModals.trial.updateToAccess}
                            </Notice>
                        )}

                        {isAddBillingInfo && (
                            <AddBillingInfoModal
                                className="block-modal__billing-info"
                                handleConfirmation={confirmInvoiceDetails}
                                isIntegrated={{
                                    addBackBtn: true,
                                    onBackBtnClick: () => {
                                        setIsAddBillingInfo(false);
                                    }
                                }}
                            />
                        )}

                        {isAddPaymentMethod && (
                            <AddPaymentMethodModal
                                onAdded={confirmPaymentMethod}
                                userEmail={userEmail}
                                isIntegrated={{
                                    onBtnClick: () => {
                                        setIsAddBillingInfo(true);
                                        setIsAddPaymentMethod(false);
                                    }
                                }}
                            />
                        )}

                        {!isAddBillingInfo && !isAddPaymentMethod && !(data.daysLeft === 0 && !isOwner) ? (
                            <ModalButtonBar
                                rejectButtonText={data.daysLeft > 0 && txt.buttons.close}
                                onReject={onClose}
                                onConfirm={() => setIsAddBillingInfo(true)}
                                confirmButtonText={isOwner && txt.titles.updPaymentMethod}
                            />
                        ) : null}
                    </div>
                </div>
            );

        case MODAL_TYPES.EVENTS_LIMIT:
            return <EventLimitModal data={data} isOwner={isOwner} onClose={onClose} userEmail={userEmail} />;

        case MODAL_TYPES.OVERDUE:
        case MODAL_TYPES.BLOCKING:
            return (
                <div className="block-modal">
                    <div className="block-modal__icon">
                        <img src={icon} width="24" height="24" alt="lock" />
                    </div>

                    <div className="block-modal__container block-modal__container--large">
                        <h2 className="block-modal__title">
                            {type === MODAL_TYPES.BLOCKING
                                ? txt.labels.billing.accountBlocked
                                : txt.labels.billing.accountOverdue}
                        </h2>

                        {type === MODAL_TYPES.BLOCKING && (
                            <Notice type={NOTICE_TYPES.DANGER}>
                                {txt.labels.billing.stopDate(
                                    moment(data.blockDate).format('MMMM DD, YYYY'),
                                    moment(data.blockDate).format('DD') - moment(data.period.dateFrom).format('DD')
                                )}
                            </Notice>
                        )}

                        <p className="block-modal__text mt16">
                            {txt.labels.billing.contactOwner(
                                data.ownerEmail,
                                moment(data.period.dateFrom).format('MMMM DD, YYYY'),
                                moment(data.period.dateTo).format('MMMM DD, YYYY')
                            )}
                        </p>

                        {type === MODAL_TYPES.OVERDUE && (
                            <>
                                <hr />

                                <p className="block-modal__text">
                                    {txt.labels.billing.blockDate(
                                        moment(data.blockDate).format('MMMM DD, YYYY'),
                                        moment(data.blockDate).format('DD') - moment(data.period.dateFrom).format('DD')
                                    )}
                                </p>
                            </>
                        )}
                    </div>
                </div>
            );

        case MODAL_TYPES.UPDATE_PAYMENT:
            return <UpdatePaymentModal data={data} onClose={onClose} />;

        case MODAL_TYPES.UPDATE_BILLING_INFO:
            return <UpdateBillingInfoModal data={data} isOwner={isOwner} onClose={onClose} />;

        default:
            throw new Error('Wrong modal type');
    }
};

export default BlockModal;
