import React, { useState } from 'react';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';

import Loader from '../../Loader';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './confirm-delete-modal.scss';

const ConfirmDeleteModal = ({ onConfirm, onClose, confirmButtonText, title, body, entity, type, autoClose }) => {
    const txt = Langs[global.lng];
    const [localLoading, setLocalLoading] = useState(false);

    const defaultConfirmButtonText = `Yes, ${type ?? 'delete'} ${entity ?? 'element'}`;

    const onConfirmClick = () => {
        onConfirm();
        if (autoClose) hideModal();
        else setLocalLoading(true);
    };

    return (
        <>
            <div className="confirm-delete-modal">
                <div className="confirm-delete-modal__content">
                    {title && <div className="confirm-delete-modal__title">{title}</div>}
                    {body && <div className="confirm-delete-modal__content">{body}</div>}
                </div>

                {localLoading && <Loader isFetching type={!body ? 'cell' : ''} />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={confirmButtonText || defaultConfirmButtonText}
                confirmButtonType="danger"
                onConfirm={onConfirmClick}
                onReject={onClose}
                isConfirmDisabled={localLoading}
            />
        </>
    );
};

export default ConfirmDeleteModal;
