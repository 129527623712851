import React from 'react';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../Constants';

import { changeMainTablePageStatus } from '../../../redux/main-table-slice';

import ClipboardField from '../../Overall/Forms/ClipboardField';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

const CampaignLinks = ({ onClose, id, links, chapter }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    return (
        <>
            <div className="campaignLinks">
                {links.map((item, index) => (
                    <ClipboardField
                        dataTest={`campaign-links_${index}`}
                        key={index}
                        label={item.label}
                        value={item.link}
                        hint={item.hint}
                    />
                ))}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.edit}
                onConfirm={() => {
                    dispatch(
                        changeMainTablePageStatus({
                            chapter,
                            value: { mode: ENTITY_MODE_STATUS.EDIT, value: id, type: chapter }
                        })
                    );

                    return true;
                }}
                onReject={onClose}
            />
        </>
    );
};

export default CampaignLinks;
