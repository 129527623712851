import React, { useState } from 'react';

import Langs from '../../../Langs';
import { uppercase } from '../../../Functions/utils';

import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import Loader from '../../Loader';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './campaign-cost-error-modal.scss';

const CampaignCostErrorModal = ({ onConfirm, onClose, isChangeCostSupport, costInfo }) => {
    const txt = Langs[global.lng];

    const [isProcessing, setIsProcessing] = useState(false);
    const [activeAction, setActiveAction] = useState(isChangeCostSupport ? null : 'apply');

    const getRadioItems = () => {
        return [
            {
                value: 'apply',
                label: (
                    <>
                        Apply{' '}
                        <span>
                            {uppercase(costInfo.bidMode)} ${costInfo.bidAmount}
                        </span>{' '}
                        to Campaign
                    </>
                )
            },
            {
                value: isChangeCostSupport ? 'submit' : 'unknow',
                disabled: !isChangeCostSupport,
                label: (
                    <>
                        Submit
                        <span>
                            {uppercase(costInfo.costModel)} ${costInfo.costPrice}
                        </span>{' '}
                        to TS Campaign {!isChangeCostSupport ? '(not supported by TS)' : ''}
                    </>
                )
            }
            // {
            //     value: 'skip',
            //     label: 'skip'
            // }
        ];
    };

    return (
        <>
            <div className="campaign-cost-error-modal">
                <div>
                    <b>Campaign cost</b> and <b>TS Campaign</b> cost (or cost model) are not equal:
                </div>

                <div>
                    <span className="campaign-cost-error-modal__row-title">Campaign:</span>

                    <span className="campaign-equal-block__value">
                        {uppercase(costInfo.costModel)} ${costInfo.costPrice}
                    </span>
                </div>

                <div>
                    <span className="campaign-cost-error-modal__row-title">TS Campaign:</span>

                    <span className="campaign-equal-block__value">
                        {uppercase(costInfo.bidMode)} ${costInfo.bidAmount}
                    </span>
                </div>

                <RadioInput
                    className="mt8"
                    value={activeAction}
                    items={getRadioItems()}
                    onChange={({ target: { value } }) => setActiveAction(value)}
                />

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                isConfirmDisabled={!activeAction}
                confirmButtonType="danger"
                onReject={onClose}
                onConfirm={() => {
                    setIsProcessing(true);
                    onConfirm(activeAction);
                }}
            />
        </>
    );
};

export default CampaignCostErrorModal;
