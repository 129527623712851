import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Langs from '../../../Langs';

import Icon from '../Icon';
import Btn from '../UI/Btn/Btn';

import './accordion.scss';

const AccordionSelf = props => {
    const { title, opened = false, children } = props;

    const [isOpened, setOpened] = useState(opened);

    const handleClick = e => {
        e.preventDefault();
        setOpened(!isOpened);
    };

    return (
        <div className="accordion-self">
            <div
                className={cn('accordion-self__title', isOpened && 'accordion-self__title--opened')}
                onClick={handleClick}
            >
                {title}
                <i />
            </div>
            <CSSTransition in={isOpened} timeout={300} classNames="accordion-self__content" unmountOnExit>
                <div>{children}</div>
            </CSSTransition>
        </div>
    );
};

const AccordionTokens = props => {
    const txt = Langs[global.lng];
    const { children } = props;

    const [isOpened, setOpened] = useState(false);

    return (
        <div className={cn('accordion-tokens', isOpened && 'accordion-tokens--opened')}>
            {children}
            <div className="accordion-tokens__more-btn" onClick={() => setOpened(s => !s)}>
                {isOpened ? txt.buttons.less : txt.buttons.showAll}
            </div>
        </div>
    );
};

const AccordionLink = ({ label, title, children, className, setFocus, isOpened: initialIsOpened }) => {
    const [isOpened, setIsOpened] = useState(initialIsOpened ?? false);

    useEffect(() => {
        if (setFocus && isOpened) setFocus();
    }, [isOpened]);

    return (
        <>
            <div className={cn('accordion-link', className)}>
                <Btn dataTest="accordion-link-btn" type="link" onClick={() => setIsOpened(s => !s)}>
                    {label} {isOpened ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
                </Btn>
                <div className="accordion-link__title">{title}</div>
            </div>

            <CSSTransition in={isOpened} timeout={300} classNames="accordion-link__content" unmountOnExit>
                <div>{children}</div>
            </CSSTransition>
        </>
    );
};

const AccordionTitle = ({ label, children = [], topComponent: TopComponent, type, noOptions }) => {
    const txt = Langs[global.lng];
    const [isOpened, setIsOpened] = useState();

    useEffect(() => {
        setIsOpened(children?.some(child => !!child?.ref?.current?.isOpened));
    }, [children]);

    const onClick = () => {
        // eslint-disable-next-line no-unused-expressions
        children?.forEach(child => child?.ref?.current?.onOpened(!isOpened));
        setIsOpened(!isOpened);
    };

    return (
        <>
            <div className={cn('accordion-title', type && 'accordion-title--' + type)}>
                {TopComponent}
                <div className="accordion-title__body">
                    <div className="accordion-title__label" onClick={onClick}>
                        {isOpened ? <Icon.ChevronDown size={16} /> : <Icon.ChevronRight size={16} />}
                        <span>{txt.labels[label] || label}</span>
                    </div>
                    {noOptions ? null : (
                        <div className="accordion-title__options">
                            <div>{txt.labels.weight}</div>
                            <div>{txt.labels.actions}</div>
                        </div>
                    )}
                </div>
                {children}
            </div>
        </>
    );
};

export { AccordionSelf, AccordionTokens, AccordionLink, AccordionTitle };
