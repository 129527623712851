const trafficSourceTemplate = {
    id: null,
    workspaceId: '',
    name: '',
    costCurrency: 'USD',

    isPostbackUrl: false,
    isAutoGeneratePostbackUrl: false,
    postbackUrl: 'https://',

    isPostbackApproved: true,
    isPostbackNew: false,
    isPostbackDeclined: true,
    isPostbackChargeback: true,

    isPixelUrl: false,
    pixelUrl: null,

    isImpressionTracking: false,
    isDirectTracking: false,

    isIntegration: null,
    integrationId: null,

    variables: [
        {
            internalToken: 'external_id',
            name: 'External ID',
            queryKey: '',
            tsToken: '',
            isTracked: true
        },
        {
            internalToken: 'cost',
            name: 'Cost',
            queryKey: '',
            tsToken: '',
            isTracked: true
        },
        {
            internalToken: 'var1',
            name: '',
            queryKey: '',
            tsToken: '',
            isTracked: false
        }
    ]
    // postbackPct: 100,
    // payoutPct: 100,
    // trafficLossPct: 0
};

export default trafficSourceTemplate;
