import React from 'react';

import ColumnsField from './ColumnsField';

const ColumnsListV = props => {
    const { fields, onChange, mode = 'standard', isLoading } = props;
    return (
        <>
            {fields
                .filter(item => item.key !== 'checked')
                .map(field => (
                    <ColumnsField
                        key={field.key || field.id}
                        isLoading={isLoading}
                        field={field}
                        onChange={onChange}
                        mode={mode}
                    />
                ))}
        </>
    );
};

export default ColumnsListV;
