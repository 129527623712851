export const botFiltersTable = [
    {
        key: 'name',
        label: 'Name'
    },
    {
        key: 'criteria',
        label: 'Using Conditions',
        type: 'criteria'
    },
    {
        key: 'actions',
        label: 'Actions',
        type: 'actions'
    }
];
