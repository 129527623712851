export default [
    {
        key: 'name',
        label: 'Filter name'
    },
    {
        key: 'ip',
        label: 'IP / IP range',
        type: 'textArray'
    },
    {
        key: 'userAgent',
        label: 'User agent',
        type: 'textArray'
    },
    {
        key: 'actions',
        label: 'Actions',
        type: 'actions'
    }
];
