import React, { useState, useEffect, useRef } from 'react';

import Langs from '../../../Langs';

import { useGetStorageQuery } from '../../../redux/api/storeApi';
import { useGetCountriesQuery, useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetReportsQuery } from '../../../redux/api/reportsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import ActualWeightsModalLander from './ActualWeightsModalLander';
import Icon from '../../Overall/Icon';
import Loader from '../../Loader';

import './actual-weights-modal.scss';

const ActualWeightsModalItemsLabel = ({ children = [], initialIsOpened }) => {
    const txt = Langs[global.lng];
    const [isOpened, setIsOpened] = useState(initialIsOpened);

    useEffect(() => {
        setIsOpened(children?.some(child => !!child?.ref?.current?.isOpened));
    }, [children]);

    const onClick = () => {
        // eslint-disable-next-line no-unused-expressions
        children?.forEach(child => child?.ref?.current?.onOpened(!isOpened));
        setIsOpened(!isOpened);
    };

    return (
        <>
            <div className="accordion-title__body">
                <div className="accordion-title__label" onClick={onClick}>
                    {isOpened ? <Icon.ChevronDown size={16} /> : <Icon.ChevronRight size={16} />}
                    <span>{txt.labels.landingPages}</span>
                </div>

                <span className="color--grey-gull actual-weights-modal__title-right">Weight</span>
            </div>

            {children}
        </>
    );
};

const ActualWeightsModal = ({ onClose, landers, offers, currentCampaignInfo, pathInfo }) => {
    const txt = Langs[global.lng];
    const ref = useRef();

    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery();
    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: reports, isSuccess: isReportsLoaded } = useGetReportsQuery();

    const [isOpened, setIsOpened] = useState(false);

    const isLoaded = isStorageLoaded && isCountriesLoaded && isCurrenciesLoaded && isReportsLoaded;

    return isLoaded ? (
        <>
            <div className="actual-weights-modal">
                <ActualWeightsModalItemsLabel initialIsOpened={isOpened}>
                    {landers.map(lander => {
                        return (
                            <ActualWeightsModalLander
                                lander={lander}
                                offers={offers}
                                ref={{ ...ref }}
                                className="alert--weights-modal"
                                setIsOpened={setIsOpened}
                                key={lander.value}
                                currentCampaignInfo={currentCampaignInfo}
                                pathInfo={pathInfo}
                                storage={storage}
                                countries={countries}
                                currencies={currencies}
                                reports={reports}
                            />
                        );
                    })}
                </ActualWeightsModalItemsLabel>
            </div>

            <ModalButtonBar rejectButtonText={txt.buttons.close} onReject={onClose} />
        </>
    ) : (
        <Loader />
    );
};

export default ActualWeightsModal;
