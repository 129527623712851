import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import produce from 'immer';
import { Cpu } from 'react-feather';

import { checkPositivity, deepEvery, getIntlFormattedValue, toggleNestedRows } from '../../../Functions/utils';
import Langs from '../../../Langs';
import { Tag } from '../../Overall/Forms/Inputs/TagInput';

import Icon from '../../Overall/Icon';
import Popover from '../../Overall/UI/Popover/Popover';
import Tooltip from '../../Overall/UI/Tooltip/Tooltip';
import MainTableHeaderFilter from './MainTableHeaderFilter';
import MainTableResizer from './MainTableResizer';
import { TdChange, TdCheckbox, TdProfit, TdText } from './TdTypes';
import TdMoney from './TdTypes/td-money/TdMoney';

const FilterIcon = React.memo(({ isFilled }) => (
    <Icon.Filter
        className={cn('ml5 df filter-icon', {
            'filter-icon--filled': isFilled
        })}
        size={13}
    />
));

const MainTableHeader = props => {
    const {
        cols,
        rows,
        total,
        onChange,
        sorting,
        setSorting,
        columnFilters = [],
        setColumnFilters,
        columnWidths = [],
        setColumnWidths,
        showTotalCheck,
        chapter,
        isVerticalBorders,
        isLoading,
        currencies,
        generalSettings,
        setRows
    } = props;
    const txt = Langs[global.lng];
    const hasImportantCols = cols.some(col => col.important);

    const isTotalHasProps = total && !!Object.keys(total).length;

    const handleSelectAll = useCallback(
        ({ target: { checked } }) => {
            rows.forEach(row => {
                // row.isChecked = checked
                toggleNestedRows(row, checked);
            });
            onChange();
        },
        [rows]
    );

    const hasAnyChildren = rows?.some(row => row.children?.length > 0);

    const isAllCollapsed = !rows.some(el => el.isOpened);
    const isAllUncollapsed = deepEvery(rows, el => !el.children || (el.children && el.isOpened));

    const handleCollapseAll = isOpened => {
        const changeIsOpen = list => {
            list.forEach(row => {
                row.isOpened = isOpened;
                if (row.children) changeIsOpen(row.children);
            });
        };

        setRows(produce(draft => changeIsOpen(draft)));
    };

    const handleSelectNone = useCallback(
        ({ target: { checked } }) => {
            if (checked) {
                rows.forEach(row => {
                    // row.isChecked = !checked
                    toggleNestedRows(row, false);
                });
                onChange();
            }
        },
        [rows]
    );

    const isAllRowsChecked = useMemo(() => deepEvery(rows, ({ isChecked }) => isChecked) && rows.length > 0, [rows]);
    const isNoneRowsChecked = useMemo(() => deepEvery(rows, ({ isChecked }) => !isChecked), [rows]);

    const getFilterValue = val => columnFilters.find(({ key }) => val === key);
    const handleDeleteFilter = (key, prop) => {
        const restFilters = columnFilters.filter(({ key: filterKey }) => key !== filterKey);
        if (prop) {
            const modifiedFilter = {
                ...columnFilters.find(({ key: filterKey }) => key === filterKey),
                [prop]: ''
            };
            if (modifiedFilter.minValue || modifiedFilter.maxValue) {
                setColumnFilters([...restFilters, modifiedFilter]);
            } else setColumnFilters(restFilters);
        } else {
            setColumnFilters(restFilters);
        }
    };
    const handleApplyFilter = filter =>
        setColumnFilters([...columnFilters.filter(({ key }) => filter.key !== key), filter]);

    const handleColResize = (key, width) => {
        const resizableCells = Array.from(document.querySelectorAll('div[data-key]'));

        let allColumnsWidths = resizableCells.map(cell => ({
            key: cell.dataset.key,
            width: cell.clientWidth + (cell.dataset.key === 'important' ? 1 : 0)
        }));

        const index = allColumnsWidths.findIndex(el => el.key === key);

        if (index >= 0) {
            allColumnsWidths.splice(index, 1, { key, width });
        } else allColumnsWidths = [...allColumnsWidths, { key, width }];

        setColumnWidths(allColumnsWidths);
    };

    const getColumnStyle = key => {
        const width = columnWidths?.find(col => col.key === key)?.width;
        return width ? { width, minWidth: width } : {};
    };

    const renderTh = (label, type) => {
        switch (type) {
            case 'checkField':
                return (
                    <TdCheckbox chapter={chapter} id="select-all" value={isAllRowsChecked} onChange={handleSelectAll} />
                );
            default:
                return <TdText value={label} />;
        }
    };

    const handleClickTh = key => {
        let newSorting;
        if (key !== sorting?.key) newSorting = { key, type: 'DESC' };
        else
            newSorting = {
                key,
                type: sorting?.type === 'ASC' ? 'DESC' : 'ASC'
            };
        setSorting(newSorting);
    };

    const renderThCollection = (columns, isImportant) => {
        const filteredCols = columns.filter(col => col.important === isImportant && !col.isHide);

        return filteredCols.map(
            ({ key, apiKey, label, type, className, filter, important, sortable, isIntegrationCell }, colIdx) => {
                const { minValue, maxValue } = getFilterValue(key) || {};

                return (
                    <div
                        className={cn(
                            'items-table__th',
                            {
                                'items-table__th--important-last': isImportant && colIdx + 1 === filteredCols.length
                            },
                            className
                        )}
                        key={`th_${colIdx}`}
                        style={getColumnStyle(key)}
                        data-key={!important ? key : null}
                    >
                        <div className={'items-table__th-content'}>
                            {isIntegrationCell && <Cpu size={15} stroke="#9AA0B9" style={{ marginRight: 4 }} />}

                            <div
                                onClick={() => {
                                    if (key !== 'checked' && sortable) handleClickTh(apiKey || key);
                                }}
                                className={cn('items-table__th-label', { 'items-table__th-label--sortable': sortable })}
                            >
                                {sorting?.key &&
                                    (sorting?.key === apiKey || sorting?.key === key) &&
                                    (sorting?.type !== (type === 'date' ? 'DESC' : 'ASC') ? (
                                        <Icon.ArrowDown size={16} className="color--froly" />
                                    ) : (
                                        <Icon.ArrowUp size={16} className="color--froly" />
                                    ))}
                                {renderTh(label, type)}
                            </div>
                            {filter && (
                                <Popover
                                    Component={MainTableHeaderFilter}
                                    prop={{
                                        key,
                                        handleApplyFilter,
                                        handleDeleteFilter,
                                        getFilterValue,
                                        chapter
                                    }}
                                >
                                    <FilterIcon isFilled={!!(minValue || maxValue)} />
                                </Popover>
                            )}
                            {getFilterValue(key) && (
                                <>
                                    {minValue && (
                                        <Tag
                                            type={`filter${maxValue ? '' : '-greater-equal'}`}
                                            className="ml5"
                                            label={getIntlFormattedValue(minValue)}
                                            onDeleteItem={() => handleDeleteFilter(key, 'minValue')}
                                        />
                                    )}
                                    {minValue && maxValue && <span className="ml5"> - </span>}
                                    {maxValue && (
                                        <Tag
                                            type={`filter${minValue ? '' : '-less-equal'}`}
                                            className="ml5"
                                            label={getIntlFormattedValue(maxValue)}
                                            onDeleteItem={() => handleDeleteFilter(key, 'maxValue')}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                        {!important && <MainTableResizer onChange={width => handleColResize(key, width)} />}
                    </div>
                );
            }
        );
    };

    const renderTotalTd = (value, type, key, rounding) => {
        if (value === undefined) return null;
        const formattedValue =
            typeof value === 'number'
                ? new Intl.NumberFormat('en-EN', {
                      minimumFractionDigits: rounding || 0,
                      maximumFractionDigits: rounding || 0
                  }).format(value)
                : value;
        switch (type) {
            case 'change':
            case 'changeOnlyProfit':
                return <TdChange value={value} onlyProfit={type === 'changeOnlyProfit'} />;
            case 'money':
            case 'percentage':
                return (
                    <TdMoney
                        value={value}
                        rounding={rounding}
                        isPercentage={type === 'percentage'}
                        currencies={currencies}
                        defaultCurrency={generalSettings?.defaultCurrency}
                    />
                );
            default:
                switch (key) {
                    case 'profit':
                        return <TdProfit value={value} rounding={rounding} />;
                    default:
                        return (
                            <span className={cn(`text--${checkPositivity(value)}`)}>
                                <TdText value={formattedValue} />
                            </span>
                        );
                }
        }
    };

    const renderTotal = columns =>
        columns
            .filter(col => !col.important && !col.isHide)
            .map(({ key, className, cellClassName, type, rounding }, colIdx) => {
                return (
                    <div
                        className={cn(
                            'items-table__td',
                            'td-important',
                            'td-important--total-right',
                            isVerticalBorders ? 'td-important--total-right_vertical-border' : '',
                            className,
                            cellClassName
                        )}
                        key={`td_${colIdx}`}
                    >
                        {renderTotalTd(total[key], type, key, rounding)}
                    </div>
                );
            });

    return (
        <>
            <div className="items-table__resize-marker" />

            <div className={cn('items-table__tr', { 'items-table__tr--shadow-bottom': !total })}>
                {hasImportantCols && (
                    <div
                        className="td-important td-important--header"
                        style={getColumnStyle('important')}
                        data-key="important"
                    >
                        <div className="items-table__th  items-table__th--placeholder" />
                        {renderThCollection(cols, true)}
                        <MainTableResizer onChange={width => handleColResize('important', width)} />
                    </div>
                )}
                {renderThCollection(cols)}
                <div className="items-table__th items-table__resize-td" />
            </div>

            {total && rows?.length > 0 && isTotalHasProps && !isLoading && (
                <div className="items-table__tr">
                    {hasImportantCols && (
                        <div className="items-table__td td-important td-important--total">
                            <div>
                                <div className="td-important-options">
                                    {showTotalCheck && (
                                        <TdCheckbox
                                            chapter={chapter}
                                            id="select-none"
                                            value={isNoneRowsChecked}
                                            onChange={handleSelectNone}
                                            className={cn({
                                                'td-checkbox--indent': chapter === 'reports' && !hasAnyChildren
                                            })}
                                        />
                                    )}
                                    {chapter === 'reports' && hasAnyChildren && (
                                        <div className="td-important-options__collapse">
                                            <Tooltip message={txt.labels.collapseAll} disabled={isAllCollapsed}>
                                                <Icon.PlusSquare
                                                    onClick={() => handleCollapseAll(false)}
                                                    size={14}
                                                    className={cn('td-name__icon', {
                                                        'td-name__icon--disabled': isAllCollapsed
                                                    })}
                                                />
                                            </Tooltip>
                                            <Tooltip message={txt.labels.uncollapseAll} disabled={isAllUncollapsed}>
                                                <Icon.MinusSquare
                                                    onClick={() => handleCollapseAll(true)}
                                                    size={14}
                                                    className={cn('td-name__icon', {
                                                        'td-name__icon--disabled': isAllUncollapsed
                                                    })}
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                                <div>{txt.labels.total}</div>
                            </div>
                        </div>
                    )}
                    {renderTotal(cols)}
                    <div className="items-table__td td-important td-important--total-right items-table__resize-td" />
                </div>
            )}
        </>
    );
};

export default React.memo(MainTableHeader);
