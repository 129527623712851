import React, { useState } from 'react';
import JustTable from './JustTable';

// DEPRECATED: Этот компонент больше не поддерживается, использовать или заменить на <JustTable /> JS\components\just-table\just-table.js

const ItemsTableBodyConstructor = props => {
    const { cols, rows, onChange, onActionClick, currencies } = props;

    const [overflow, setOverflow] = useState(false);
    const switchOverflow = isOpen => {
        setOverflow(isOpen);
    };

    return (
        <div className={`scrollable-table-box ${overflow ? 'overflowX' : null}`}>
            <JustTable
                cols={cols}
                rows={rows}
                onChange={onChange}
                onActionClick={onActionClick}
                overflow={switchOverflow}
                currencies={currencies}
            />
        </div>
    );
};

export default ItemsTableBodyConstructor;
