import React, { useCallback } from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import { hideModal } from '../../../../redux/operations';
import Btn from '../../UI/Btn/Btn';
import Alert from '../../UI/Alert/Alert';

import './modal-button-bar.scss';

const ModalButtonBar = ({
    rejectButtonText,
    confirmButtonText,
    onReject,
    onConfirm,
    confirmButtonType,
    selectedItemsCount,
    isConfirmDisabled,
    isRejectDisabled,
    className,
    leftSettings,
    isLoading,
    separator,
    addAlert
}) => {
    const txt = Langs[global.lng];

    const handleConfirm = useCallback(async () => {
        if (await onConfirm()) {
            hideModal();
        }
    }, [onConfirm]);

    return (
        <div className={cn(className, 'modal-button-bar')}>
            {addAlert && (
                <Alert
                    className={cn('modal-button-bar__alert', addAlert?.alertClassName)}
                    message={addAlert?.alertMessage}
                    type={addAlert?.alertType}
                />
            )}

            {leftSettings ? <span className="modal-button-bar-left">{leftSettings}</span> : null}
            {rejectButtonText && (
                <Btn
                    dataTest={`modal-button-bar_${rejectButtonText}-btn`}
                    onClick={onReject}
                    disabled={isLoading || isRejectDisabled}
                >
                    {txt.buttons[rejectButtonText] || rejectButtonText}
                </Btn>
            )}
            {separator && <span className="modal-button-bar__separator">{separator}</span>}
            {confirmButtonText && (
                <Btn
                    dataTest={`modal-button-bar_${confirmButtonText}-btn`}
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled || isLoading}
                    type={confirmButtonType || 'filled'}
                >
                    {txt.buttons[confirmButtonText] || confirmButtonText}
                    {`${selectedItemsCount ? ` (${selectedItemsCount})` : ''}`}
                </Btn>
            )}
        </div>
    );
};

export default ModalButtonBar;
