import React from 'react';
import { Redirect } from 'react-router-dom';

import StartRedirect from './Pages/StartRedirect';
import Home from './Pages/Home';
// import Demo from './Pages/Demo';
import Report from './Pages/Report';
import Settings from './Pages/page-settings/page-settings';
import Users from './Pages/page-users/page-users';
import Tools from './Pages/page-tools/page-tools';
// import NewItem from './Modules/Items/NewItem/NewItem';
import PageLogin from './Pages/page-login/page-login';
import PageRegister from './Pages/page-register/page-register';
import PageRegisterInvite from './Pages/page-register-invite/page-register-invite';
import PageForgot from './Pages/page-forgot/page-forgot';
import PageChangePassword from './Pages/page-change-password/page-change-password';
import PagePreparation from './Pages/page-preparation/page-preparation';
import PageSharedReport from './Pages/page-shared-report/page-shared-report';

const getRoutes = (isAuthenticated, refererPathname, referer = '/') => {
    const routes = [];

    if (isAuthenticated) {
        routes.push({
            path: '/',
            exact: true,
            name: 'StartRedirect',
            component: StartRedirect
        });
        routes.push({ path: '/preparation', exact: true, name: 'Preparation', component: PagePreparation });
        // routes.push({ path: '/home', exact: true, name: 'Dashboard', component: Home });
        routes.push({
            path: '/home/:path/:action?',
            name: 'HomeByChapter',
            component: Home
        });
        routes.push({
            path: '/reports/:reportValue',
            name: 'ReportsByValue',
            component: Report
        });
        routes.push({
            path: '/settings/:chapter?/:modal?',
            name: 'Settings',
            component: Settings
        });
        routes.push({ path: '/tools/:chapter?', name: 'Tools', component: Tools });
        routes.push({ path: '/users/:chapter?', name: 'Users', component: Users });
        routes.push({ path: '/sharedreport/:id', name: 'SharedReport', component: PageSharedReport });
        routes.push({ path: '*', name: 'Unknown', component: () => <Redirect to={referer} /> });
        // routes.push({ path: '/test', name: 'Demo', component: Demo });
        // routes.push({ path: '/table', name: 'GuideLine', component: GuideLine });
    } else {
        routes.push({ path: '/change-password/:token', name: 'ChangePass', component: PageChangePassword });
        routes.push({ path: '/login/:code?', name: 'Login', component: PageLogin });
        routes.push({ path: '/register/:projectId/:id', name: 'RegisterInvite', component: PageRegisterInvite });
        routes.push({ path: '/register', exact: true, name: 'Register', component: PageRegister });
        routes.push({ path: '/forgot', name: 'Forgot', component: PageForgot });
        routes.push({ path: '/preparation', exact: true, name: 'Preparation', component: PagePreparation });
        routes.push({ path: '/sharedreport/:id', name: 'SharedReport', component: PageSharedReport });
        routes.push({
            path: '*',
            name: 'Other',
            component: () => <Redirect to={{ pathname: '/login', state: { referer: refererPathname } }} />
        });
    }
    return routes;
};

export default getRoutes;
