import cn from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import * as Icon from 'react-feather';

const Calendar = props => {
    const {
        date,
        setDate,
        startDate,
        endDate,
        allowPrev = true,
        allowNext = true,
        onClick,
        onHover,
        startPrevDate,
        endPrevDate,
        limitRange,
        isFutureSelect,
        availableInterval,
        isAllowFuture
    } = props;

    const [days, setDays] = useState({});

    const title = useMemo(() => `${moment(date).format('MMM')} ${moment(date).format('YYYY')}`, [date]);

    useEffect(() => {
        const result = {};
        const day = moment(date).startOf('month');
        const daysInMonth = day.daysInMonth();
        for (let d = 0; d < daysInMonth; d += 1) {
            let week = day.week();
            if (day.month() === 11 && week === 1) week = 53;
            if (day.month() === 0 && week === 53) week = 0;
            if (!Object.prototype.hasOwnProperty.call(result, week)) {
                result[week] = {};
            }
            result[week][day.weekday()] = { date: moment(day) };
            day.add(1, 'd');
        }
        setDays(result);
    }, [date]);

    const renderDay = (week, dayOfWeek) => {
        const day = days[week][dayOfWeek];

        const isNotAvailableDay =
            day &&
            availableInterval &&
            (day.date.isBefore(moment(availableInterval.start), 'day') ||
                day.date.isAfter(moment(availableInterval.end).subtract(1, 'hour'), 'day'));

        const isFutureDay = day && day.date.isAfter(moment(), 'day') && !isAllowFuture;

        const isPastDay = limitRange
            ? day && day.date.isBefore(moment().subtract(limitRange, 'days'), 'day')
            : day && day.date.isBefore(moment(), 'day');

        const isRangeEnd =
            day && (day.date.isSame(startDate, 'day') || day.date.isSame(moment(endDate).subtract(1, 'hour'), 'day'));

        const isRangeInside =
            day &&
            startDate &&
            endDate &&
            day.date.isAfter(startDate, 'day') &&
            day.date.isBefore(moment(endDate).subtract(1, 'hour'), 'day');

        const isPrevRangeEnd =
            day &&
            (day.date.isSame(startPrevDate, 'day') || day.date.isSame(moment(endPrevDate).subtract(1, 'day'), 'day'));

        const isPrevRangeInside =
            day &&
            startPrevDate &&
            endPrevDate &&
            day.date.isAfter(startPrevDate, 'day') &&
            day.date.isBefore(moment(endPrevDate).subtract(1, 'day'), 'day');

        const classNames = cn(
            'calendar__day',
            { 'calendar__day--clickable': day },
            { 'calendar__day--disabled': isFutureDay && !isFutureSelect },
            { 'calendar__day--disabled': isPastDay && isFutureSelect },
            { 'calendar__day--disabled': isNotAvailableDay },
            { 'calendar__day--range-end': isRangeEnd },
            { 'calendar__day--range-inside': isRangeInside },
            { 'calendar__day--prev-range-end': isPrevRangeEnd },
            { 'calendar__day--prev-range-inside': isPrevRangeInside }
        );

        const isClickAllow = day && ((isFutureDay && isFutureSelect) || (isPastDay && !isFutureSelect));

        return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <div
                key={`${week}_${dayOfWeek}`}
                className={classNames}
                onClick={day || isClickAllow ? () => onClick(day.date) : null}
                onMouseOver={day || isClickAllow ? () => onHover(day.date) : null}
                onMouseLeave={() => onHover(null)}
            >
                {day && day.date.date()}
            </div>
        );
    };

    const handlePrev = () => {
        setDate(moment(date).subtract(1, 'month'));
    };

    const handleNext = () => {
        setDate(moment(date).add(1, 'month'));
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <div className="calendar-header__prev">
                    {allowPrev && (
                        <div onClick={handlePrev}>
                            <Icon.ChevronLeft size={16} />
                        </div>
                    )}
                </div>
                <div className="calendar-header__title">{title}</div>
                <div className="calendar-header__next">
                    {allowNext && (
                        <div onClick={handleNext}>
                            <Icon.ChevronRight size={16} />
                        </div>
                    )}
                </div>
            </div>
            <div className="calendar__week">
                {[...Array(7).keys()].map(dayOfWeek => {
                    return (
                        <div key={`day-of-week_${dayOfWeek}`} className="calendar__day calendar__day--title">
                            {moment().weekday(dayOfWeek).format('dd').charAt(0)}
                        </div>
                    );
                })}
            </div>
            {Object.keys(days).map((week, index) => (
                <div key={`week_${index}`} className="calendar__week">
                    {[...Array(7).keys()].map(dayOfWeek => renderDay(week, dayOfWeek))}
                </div>
            ))}
        </div>
    );
};

export default Calendar;
