import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import Langs from '../../../../../Langs';
import { ENTITY_MODE_STATUS, PRIVATE_ACTIONS, USER_ROLES, WORKSPACE_TYPE } from '../../../../../Constants';
import { trafficSourceForNewItemTransformer } from '../../../../../redux/transformers';
import {
    showByValue,
    getOnlyASCIICodeText,
    camelize,
    getReplacedString,
    checkPositiveFloatNumber,
    clone,
    uppercase
} from '../../../../../Functions/utils';
import useFocus from '../../../../../Functions/hooks/useFocus';

import { useGetTrafficSourceIntegrationCampaignListQuery } from '../../../../../redux/api/integrationsApi';
import { useChangeStorageMutation } from '../../../../../redux/api/storeApi';
import { useGetUserSettingsQuery } from '../../../../../redux/api/settingsApi';

import Label from '../../../../Overall/Forms/Inputs/Label';
import RadioInput from '../../../../Overall/Forms/Inputs/RadioInput';
import SelectInput from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TagInput from '../../../../../components/inputs/tag-input/tag-input';
import TextInput from '../../../../Overall/Forms/Inputs/TextInput';
import Btn, { AddBtn, EditBtn } from '../../../../Overall/UI/Btn/Btn';
import JustAlert, { JUST_ALERT_TYPE } from '../../../../../components/just-alert/just-alert';
import PositionPicker from '../../../../Overall/UI/PositionPicker/PositionPicker';
import CopyFieldLabeled from '../../../../Overall/UI/CopyField/CopyFieldLabeled';
import PrivateElement from '../../../../PrivateElement/PrivateElement';
import showItemsModal from '../../../showItemsModal';

import './campaign-main-option.scss';

export const HIDE_REFERRER_DEFAULT_TYPES = [
    { value: 'without', label: 'without' },
    { value: 'meta_refresh', label: 'metaRefresh' },
    { value: 'double_meta_refresh', label: 'doubleMetaRefresh' }
];

const costModels = [
    { value: 'auto', label: 'auto', prop: '', hint: '', hasCurrency: false, alert: 'auto' },
    { value: 'cpc', label: 'cpc', prop: 'costPrice', hint: 'cpcCost', hasCurrency: true, alert: '' },
    { value: 'cpv', label: 'cpv', prop: 'costPrice', hint: 'cpvCost', hasCurrency: true, alert: '' },
    { value: 'cpm', label: 'cpm', prop: 'costPrice', hint: 'cpmCost', hasCurrency: true, alert: 'cpm' },
    { value: 'cpa', label: 'cpa', prop: 'costPrice', hint: 'cpaCost', hasCurrency: true, alert: 'cpa' },
    {
        value: 'rev_share',
        label: 'revShare',
        prop: 'percentageShare',
        hint: 'percentageShare',
        hasCurrency: false,
        alert: 'revShare'
    },
    { value: 'not_tracked', label: 'notTracked', prop: '', hint: '', hasCurrency: false, alert: '' }
];

const CampaignMainOption = ({
    className,
    values,
    values: {
        workspaceId,
        country,
        name,
        tags,
        trackingDomain,
        referrerDefaultHideType,
        trafficSource: activeTrafficSource,
        trafficSourceCampaignId,
        costModel,
        notes,
        links
    },
    onChange,
    isNeedValidate,
    mode,
    trackingDomains,
    trafficSourceData,
    campaignCostEqualStatus,
    setCampaignCostEqualStatus,
    campaignCostInitialEqual,
    setCampaignCostInitialEqual,
    onWorkspaceChange,
    children,
    // onIsValidChange,
    trafficSourcesList,
    workspacesList,
    publicWorkspaceId,
    countries,
    storage
}) => {
    const txt = Langs[global.lng];
    const costModeProps = useMemo(() => costModels.find(({ value }) => value === costModel), [costModel]);
    const history = useHistory();
    const dispath = useDispatch();

    const [isTSIntegrCampListNotInclTSId, setIsTSIntegrCampListNotInclTSId] = useState(false);

    const { data: userSettings } = useGetUserSettingsQuery();
    const [changeStorage] = useChangeStorageMutation();

    const positionPickerValue = storage.common.positionPickerValue ?? 0;

    const [isCostModelDisabled, setIsCostModelDisabled] = useState(false);

    const [campaignNameRef, setCampaignNameFocus] = useFocus();

    useEffect(() => {
        if (mode === ENTITY_MODE_STATUS.CREATE && !name) setCampaignNameFocus();
    }, []);

    const trafficSources = useMemo(() => trafficSourceForNewItemTransformer(trafficSourcesList), [trafficSourcesList]);

    const workspaceAvailableTrafficSources = useMemo(
        () => trafficSources.filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId) ?? [],
        [trafficSources, workspaceId, publicWorkspaceId]
    );

    const {
        data: trafficSourceIntegrationCampaignList,
        isError: isTrafficSourceIntegrationCampaignListLoadError,
        isSuccess: isTrafficSourceIntegrationCampaignListLoadSucces,
        isFetching: isTrafficSourceIntegrationCampaignListFetching
    } = useGetTrafficSourceIntegrationCampaignListQuery(trafficSourceData?.integrationId, {
        skip: !activeTrafficSource || !trafficSourceData?.isIntegration || !trafficSourceData?.integrationId
    });

    const transformedTrafficSourceIntegrationCampaignList = trafficSourceIntegrationCampaignList?.map(el => ({
        ...el,
        value: el.id,
        label: el.name
    }));

    const currency = useMemo(
        () => trafficSources?.find(({ value }) => value === activeTrafficSource)?.currency || 'USD',
        [activeTrafficSource, trafficSources]
    );

    const namePrefix = useMemo(
        () =>
            `${
                activeTrafficSource ? showByValue(trafficSources, activeTrafficSource, 'label') + ' - ' : ''
            }${showByValue(countries, country)} - `,
        [activeTrafficSource, trafficSources, countries, country]
    );

    const handleNameChange = ({ target: { value } }) => {
        if (value.slice(0, namePrefix.length) === namePrefix) {
            const transformedValue = getOnlyASCIICodeText(value.replace(namePrefix, '')?.trimStart());
            onChange(namePrefix.length < value.length ? transformedValue : '', 'name');
        }
    };

    useEffect(() => {
        if (trafficSourceCampaignId && trafficSourceIntegrationCampaignList) {
            const item = trafficSourceIntegrationCampaignList.find(el => el.id === trafficSourceCampaignId);

            if (!item) {
                onChange(null, 'trafficSourceCampaignId');
                setIsTSIntegrCampListNotInclTSId(true);
                return;
            }

            const isEqualCostPrice = item.bidAmount + '' === values.costPrice + '';
            const isEqualCostMode = item.bidMode + '' === values.costModel + '';

            if (!isEqualCostPrice || !isEqualCostMode) {
                setCampaignCostEqualStatus({ isEqual: false, bidMode: item.bidMode, bidAmount: item.bidAmount });
                if (campaignCostInitialEqual === null) setCampaignCostInitialEqual(false);
            } else {
                setCampaignCostEqualStatus({ isEqual: true, bidMode: item.bidMode, bidAmount: item.bidAmount });
                if (campaignCostInitialEqual === null) setCampaignCostInitialEqual(true);
            }
        }
    }, [trafficSourceCampaignId, trafficSourceIntegrationCampaignList, values.costPrice, values.costModel]);

    const onTrafficSourceChange = ({ target: { value } }) => {
        onChange(null, [
            {
                prop: 'trafficSource',
                value
            },
            {
                prop: 'trafficSourceCampaignId',
                value: null
            },
            {
                prop: 'costModel',
                value: 'auto'
            },
            {
                prop: 'costPrice',
                value: null
            },
            {
                prop: 'initialCostPrice',
                value: null
            }
        ]);

        setIsCostModelDisabled(false);
    };

    const onIntegratedTSCampaignsChange = ({ target: { value } }) => {
        onChange(value, 'trafficSourceCampaignId');

        const item = trafficSourceIntegrationCampaignList.find(el => el.id === value);

        if (item.bidMode === 'cpm') {
            onChange(null, [
                {
                    prop: 'costModel',
                    value: 'cpm'
                },
                {
                    prop: 'initialCostModel',
                    value: 'cpm'
                },
                {
                    prop: 'costPrice',
                    value: item.bidAmount === 0 ? null : item.bidAmount + ''
                },
                {
                    prop: 'initialCostPrice',
                    value: item.bidAmount === 0 ? null : item.bidAmount + ''
                }
            ]);
            setIsCostModelDisabled(false);
        } else if (item.bidMode === 'cpc') {
            const costInfo = trafficSourceData.variables.find(el => el.internalToken === 'cost');

            if (costInfo && costInfo.isTracked) {
                onChange('auto', 'costModel');
                setIsCostModelDisabled(true);
            } else {
                onChange(null, [
                    {
                        prop: 'costModel',
                        value: 'cpc'
                    },
                    {
                        prop: 'initialCostModel',
                        value: 'cpc'
                    },
                    {
                        prop: 'costPrice',
                        value: item.bidAmount === 0 ? null : item.bidAmount + ''
                    },
                    {
                        prop: 'initialCostPrice',
                        value: item.bidAmount === 0 ? null : item.bidAmount + ''
                    }
                ]);
                setIsCostModelDisabled(false);
            }
        } else if (item.bidMode === 'cpa') {
            onChange(null, [
                {
                    prop: 'costModel',
                    value: 'cpa'
                },
                {
                    prop: 'initialCostModel',
                    value: 'cpa'
                },
                {
                    prop: 'costPrice',
                    value: item.bidAmount === 0 ? null : item.bidAmount + ''
                },
                {
                    prop: 'initialCostPrice',
                    value: item.bidAmount === 0 ? null : item.bidAmount + ''
                }
            ]);
            setIsCostModelDisabled(false);
        } else {
            //reset
            onChange(null, [
                {
                    prop: 'costModel',
                    value: 'auto'
                },
                {
                    prop: 'initialCostModel',
                    value: 'auto'
                },
                {
                    prop: 'costPrice',
                    value: null
                },
                {
                    prop: 'initialCostPrice',
                    value: null
                }
            ]);
            setIsCostModelDisabled(false);
        }
    };

    const showTrafficSourceModal = (modeType = ENTITY_MODE_STATUS.EDIT) => {
        const isEdited = modeType === ENTITY_MODE_STATUS.EDIT;
        const action = isEdited ? activeTrafficSource : 'new';

        showItemsModal(
            'trafficSources',
            action,
            { modeType },
            null,
            (data, body) => {
                onChange([], 'links');
                onTrafficSourceChange({ target: { value: isEdited ? data.value : body.id } });
            },
            dispath
        );
    };

    const onPositionPickerChange = value => {
        const newStorage = clone(storage);
        newStorage.common.positionPickerValue = value;

        changeStorage(newStorage);
    };

    useEffect(() => {
        if (userSettings && workspacesList && !workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            onChange(initialWorkspaceId, 'workspaceId');
        }
    }, [userSettings, workspacesList]);

    const filteredWorkspaceList = useMemo(() => {
        return workspacesList.filter(el => el.workspaceType !== WORKSPACE_TYPE.PUBLIC);
    }, [workspacesList, userSettings]);

    return (
        <div className={cn('new-item__content-main', 'campaign-main-option', className)}>
            <div className="campaign-main-option__content">
                <div className="campaign-main-option__line">
                    <Label label={txt.labels.workspace} hint={txt.hints.workspace(txt.singulars.campaigns, true)} />

                    <SelectInput
                        dataTest="campaign-main-options_workspace-select-input"
                        value={workspaceId}
                        items={filteredWorkspaceList}
                        onChange={({ target: { value } }) => onWorkspaceChange('campaigns', value)}
                    />
                </div>

                <div
                    className={cn('tracking-links-wrapper', { 'tracking-links-wrapper--bottom': positionPickerValue })}
                >
                    <div className="j46">
                        <Label label={txt.labels.tracking} className="campaign-main-option__title" />
                        <div>
                            <PositionPicker value={positionPickerValue} onChange={onPositionPickerChange} />
                        </div>
                    </div>

                    <div>
                        {links?.length ? (
                            <>
                                {links.map(({ link, label }) => (
                                    <CopyFieldLabeled
                                        key={link}
                                        dataTest={camelize(label)}
                                        className="mb3"
                                        field={{ label, text: link }}
                                    />
                                ))}
                            </>
                        ) : (
                            <JustAlert>{txt.alerts.saveCampaignToGenerateURLs}</JustAlert>
                        )}
                    </div>
                </div>

                <div>
                    <Label label={txt.labels.mainOptions} className="campaign-main-option__title" />
                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.trackingDomain} hint={txt.hints.trackingDomain} />
                        <div className="j4">
                            <SelectInput
                                dataTest="campaign-main-options_tracking-domain-select-input"
                                className="campaign-main-option__line--grow"
                                value={trackingDomain}
                                items={trackingDomains.map(el => ({ value: el.id, label: el.domain?.name }))}
                                onChange={e => onChange(e, 'trackingDomain')}
                            />
                            <PrivateElement forbiddenRoles={USER_ROLES.USER} action={PRIVATE_ACTIONS.HIDE}>
                                <Btn
                                    dataTest="campaign-main-option_add-domain-btn"
                                    className="ml5"
                                    onClick={() => history.push('/settings/domains')}
                                >
                                    {txt.buttons.addDomain}
                                </Btn>
                            </PrivateElement>
                        </div>
                    </div>

                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.trafficSource} hint={txt.hints.trafficSource} />

                        <SelectInput
                            dataTest="campaign-main-options_traffic-source-select-input"
                            value={activeTrafficSource}
                            items={workspaceAvailableTrafficSources}
                            placeholder={txt.placeholders.selectTrafficSource}
                            onChange={onTrafficSourceChange}
                            error={isNeedValidate && !activeTrafficSource ? 'Select a traffic source' : ''}
                            isSearchable
                        />

                        {activeTrafficSource || workspaceAvailableTrafficSources?.length ? (
                            <EditBtn
                                dataTest="campaign-main-option_traffic-source-edit-btn"
                                className="ml8 btn--edit"
                                disabled={!activeTrafficSource}
                                onClick={() => showTrafficSourceModal()}
                            />
                        ) : null}

                        <AddBtn
                            dataTest="campaign-main-option_traffic-source-add-btn"
                            className="ml8"
                            type="filled"
                            isCreateType
                            onClick={() => showTrafficSourceModal(ENTITY_MODE_STATUS.CREATE)}
                        >
                            {txt.labels.new}
                        </AddBtn>
                    </div>

                    {activeTrafficSource && trafficSourceData?.isIntegration && (
                        <div className="campaign-main-option__line j46">
                            <Label
                                label={txt.labels.trafficSourceIntegrated}
                                hint="Select your traffic source campaign. These users will be redirected to the offer website."
                            />

                            {isTrafficSourceIntegrationCampaignListLoadError ? (
                                <JustAlert type={JUST_ALERT_TYPE.DANGER}>
                                    Can&apos;t get list. Please check integration settings
                                </JustAlert>
                            ) : (
                                <SelectInput
                                    dataTest="campaign-main-options_traffic-source-campaign-select-input"
                                    value={trafficSourceCampaignId}
                                    items={transformedTrafficSourceIntegrationCampaignList ?? []}
                                    onChange={onIntegratedTSCampaignsChange}
                                    disabled={
                                        !trafficSourceIntegrationCampaignList ||
                                        !isTrafficSourceIntegrationCampaignListLoadSucces ||
                                        isTrafficSourceIntegrationCampaignListFetching
                                    }
                                    isSearchable
                                    error={
                                        (isNeedValidate || isTSIntegrCampListNotInclTSId) && !trafficSourceCampaignId
                                            ? 'Select a traffic source campaign'
                                            : ''
                                    }
                                />
                            )}
                        </div>
                    )}

                    <div className="campaign-main-option__line">
                        <Label
                            label={txt.labels.country}
                            hint={txt.hints.countryTag(txt.labels.campaigns.toLowerCase())}
                        />
                        <SelectInput
                            dataTest="campaign-main-options_country-select-input"
                            value={country}
                            items={countries}
                            onChange={e => onChange(e, 'country')}
                            isSearchable
                        />
                    </div>

                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.name} hint={txt.hints.campaignName} />
                        <TextInput
                            dataTest="campaign-main-option_campaign-name-input"
                            value={namePrefix + name}
                            ref={campaignNameRef}
                            onChange={handleNameChange}
                            error={isNeedValidate && !name?.trim() ? txt.errors.typeNameForCampaign : ''}
                        />
                    </div>

                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.tags} hint={txt.hints.addCampaignTags} />

                        <TagInput tagList={tags} onChange={e => onChange(e, 'tags')} />
                    </div>

                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.hideReferrerDefault} hint={txt.hints.hideReferrerDefault} />
                        <RadioInput
                            dataTest="campaign-main-option_referrer-default-hide-type"
                            type="row"
                            value={referrerDefaultHideType}
                            items={HIDE_REFERRER_DEFAULT_TYPES}
                            onChange={e => onChange(e, 'referrerDefaultHideType')}
                        />
                    </div>

                    <div className="campaign-main-option__line">
                        <Label label={txt.labels.costModel} hint={txt.hints.costModel} />
                        <div>
                            <div className="j4">
                                <SelectInput
                                    dataTest="campaign-main-options_cost-model-select-input"
                                    className="campaign-main-option__line--grow campaign-main-option__cost-model"
                                    // value={costModel === 'not_tracked' ? 'auto' : costModel || 'auto'}
                                    value={costModel || 'auto'}
                                    items={costModels}
                                    onChange={e => onChange(e, 'costModel')}
                                    style={{ width: '115px' }}
                                    disabled={isCostModelDisabled}
                                />
                                {costModeProps?.hint && (
                                    <TextInput
                                        dataTest="campaign-main-option_cost-model-input"
                                        className="campaign-main-option__cost-input"
                                        value={values[costModeProps.prop]}
                                        label={txt.labels[costModeProps.hint]}
                                        onChange={({ target: { value } }) => {
                                            onChange(getReplacedString(value, '[^\\d.]'), costModeProps.prop);
                                        }}
                                        error={isNeedValidate && !values[costModeProps.prop]}
                                        checkCorrectSymbol={checkPositiveFloatNumber}
                                        labelLeft
                                    />
                                )}
                                {costModeProps?.hasCurrency && (
                                    <Label label={currency} style={{ marginLeft: '-7px' }} />
                                )}
                            </div>

                            {costModeProps?.alert && (
                                <JustAlert className="mtb10">{txt.hints.costModels[costModeProps.alert]}</JustAlert>
                            )}

                            {!campaignCostEqualStatus.isEqual && costModel !== 'auto' && (
                                <JustAlert className="mtb10">
                                    <div className="campaign-equal-block__tooltip">
                                        <div>
                                            <b>Campaign cost</b> and <b>TS Campaign</b> cost (or cost model) are not
                                            equal:
                                        </div>

                                        <div>
                                            <b>Campaign:</b>{' '}
                                            <span className="campaign-equal-block__value">
                                                {uppercase(values.costModel)} ${values.costPrice}
                                            </span>
                                        </div>

                                        <div>
                                            <b>TS Campaign:</b>{' '}
                                            <span className="campaign-equal-block__value">
                                                {uppercase(campaignCostEqualStatus.bidMode)} $
                                                {campaignCostEqualStatus.bidAmount}
                                            </span>
                                        </div>
                                    </div>
                                </JustAlert>
                            )}

                            {/* {(values.costModel !== values.initialCostModel ||
                                values.costPrice !== values.initialCostPrice) &&
                                values.trafficSourceCampaignId && (
                                    <Alert
                                        message={'The value will be submitted to Traffic source after save'}
                                        type="warning"
                                        className="mtb10"
                                    />
                                )} */}
                        </div>
                    </div>

                    <div className="campaign-main-option__line campaign-main-option__line_column-direction">
                        <Label label={txt.labels.notes} />
                        <TextInput
                            dataTest="campaign-main-option_notes-textarea"
                            type="textarea"
                            value={notes}
                            onChange={e => onChange(e, 'notes')}
                        />
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default CampaignMainOption;
