import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { ENTITY_MODE_STATUS, INITIAL_SELECTED_METRICS } from '../../Constants';
import { getColumns } from '../../components/main-table/services';
import { getParams, clone, compare } from '../../Functions/utils';
import { getActualDateRange, getFiltersforRowsCheckboxes } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';
import checkNavigate from '../../Modules/Items/NewItem/check-navigate';

import useContextMenu from '../../Functions/hooks/useContextMenu';

import { useChangeStorageMutation } from '../../redux/api/storeApi';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetFlowsChartQuery, useGetFlowsListQuery } from '../../redux/api/flowsApi';

import {
    changeMainTablePagination,
    changeMainTableSorting,
    selectFlowsTableData,
    changeMainTableActiveRow,
    changeMainTableColumnFilters,
    changeMainTablePageStatus
} from '../../redux/main-table-slice';

import TableControls from './components/table-controls/table-controls';
import GeneralTable from '../../components/main-table/general-table';
import Charts from '../../Modules/Charts/Charts';
import NewItem from '../../Modules/Items/NewItem/NewItem';

import './screen-flows.scss';

const ScreenFlows = ({ chapter, storage, reports, className, action, locationState }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const tableParameters = useSelector(selectFlowsTableData);
    const { search, activeRow, sorting, pagination, columnFilters, chartStatus, pageStatus } = tableParameters;

    const [isLoading, setIsLoadig] = useState(true);
    const [rows, setRows] = useState([]);
    const [metrics, setMetrics] = useState(INITIAL_SELECTED_METRICS);
    const [contextMenuRef, contextMenuSettings, onMainTableBodyScroll, onRowContextMenu] = useContextMenu();

    const dateRange = useMemo(() => getActualDateRange(storage.common.dateRange), [storage]);
    const { displayMode } = storage[chapter];

    const parameters = {
        'filter[name]': search,
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        'filter[status]': displayMode,
        'filter[dateFrom]': exportDateTransformer(dateRange.start),
        'filter[dateTo]': exportDateTransformer(dateRange.end),
        ...(dateRange.compare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(dateRange.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(dateRange.endPrevDate)
              }
            : {}),
        ...columnFilters.reduce((acc, el) => {
            return {
                ...acc,
                ...(el.minValue ? { [`filter[${el.key}Min]`]: +el.minValue } : {}),
                ...(el.maxValue ? { [`filter[${el.key}Max]`]: +el.maxValue } : {})
            };
        }, {})
    };

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    const {
        data: flowsList,
        isSuccess: isFlowsListLoaded,
        isFetching: isFlowsListFetching
    } = useGetFlowsListQuery(getParams(parameters));

    useEffect(() => {
        if (!isFlowsListLoaded) return;

        if (activeRow) {
            const row = flowsList.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (flowsList?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: flowsList.pagination
                })
            );
        }

        setRows(clone(flowsList.items));

        if (isLoading) setIsLoadig(false);
    }, [flowsList]);

    const selectedRowsIds = getFiltersforRowsCheckboxes(rows).join('&');

    const {
        data: flowsChart,
        isSuccess: isFlowsChartLoaded,
        isFetching: isFlowsChartFetching
    } = useGetFlowsChartQuery(
        selectedRowsIds ? getParams(parameters).concat('&', selectedRowsIds) : getParams(parameters),
        { skip: !chartStatus.isOpen }
    );

    useEffect(() => {
        if (isFlowsListFetching && !isLoading) setIsLoadig(true);
        else if (isFlowsListLoaded && !isFlowsListFetching && isLoading) setIsLoadig(false);
    }, [isFlowsListFetching]);

    const columns = useMemo(
        () => getColumns(chapter, storage, { includeCompareColumns: dateRange.compare }).orderedColumns,
        [chapter, storage, dateRange]
    );

    const [changeStorage] = useChangeStorageMutation();

    const updateRows = useCallback(newRows => setRows(newRows), [rows]);

    const onRowClick = useCallback(
        row =>
            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: row.value === activeRow?.value ? null : row
                })
            ),
        [chapter, activeRow]
    );

    const onRowRightClick = useCallback(
        (e, row) => onRowContextMenu(e, row, activeRow, onRowClick),
        [activeRow, onRowClick, onRowContextMenu]
    );

    const onTableActionsChange = useCallback(
        (data, prop) => {
            switch (prop) {
                case 'sorting': {
                    dispatch(
                        changeMainTableSorting({
                            chapter,
                            value: data
                        })
                    );

                    dispatch(
                        changeMainTablePagination({
                            chapter,
                            value: { ...pagination, page: 1 }
                        })
                    );

                    break;
                }

                case 'columnWidths': {
                    const newStorage = clone(storage);
                    newStorage[chapter].columnWidths = data;
                    changeStorage(newStorage);

                    break;
                }

                case 'columnFilters': {
                    dispatch(
                        changeMainTableColumnFilters({
                            chapter,
                            value: data
                        })
                    );

                    dispatch(
                        changeMainTablePagination({
                            chapter,
                            value: { ...pagination, page: 1 }
                        })
                    );

                    break;
                }

                default:
                    throw new Error(`Invalid MainTable action, get "${prop}"`);
            }
        },
        [chapter, storage]
    );

    const updateDateRange = useCallback(
        newDateRange => {
            if (!compare(storage.common.dateRange, newDateRange)) {
                const newStorage = clone(storage);

                newStorage.common.dateRange = newDateRange;
                changeStorage(newStorage);

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
            }
        },
        [storage]
    );

    const changeDisplayMode = useCallback(
        value => {
            if (displayMode !== value) {
                const newStorage = clone(storage);

                newStorage[chapter].displayMode = value;
                changeStorage(newStorage);

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );

                if (activeRow) {
                    dispatch(
                        changeMainTableActiveRow({
                            chapter,
                            value: null
                        })
                    );
                }
            }
        },
        [displayMode, activeRow]
    );

    const setPageStatus = useCallback(value => dispatch(changeMainTablePageStatus({ chapter, value })), [chapter]);

    useEffect(() => {
        checkNavigate(chapter, action, pageStatus, locationState, setPageStatus, history);
    }, [action, pageStatus]);

    const isLoaded = !isLoading && isUserSettingsLoaded && isGeneralSettingsLoaded;

    const isEditMode = [ENTITY_MODE_STATUS.CREATE, ENTITY_MODE_STATUS.EDIT, ENTITY_MODE_STATUS.DUPLICATE].includes(
        pageStatus.mode
    );

    if (isEditMode) return <NewItem chapter={chapter} status={pageStatus} setStatus={value => setPageStatus(value)} />;

    return (
        <>
            <div className={cn('table-container screen-flows', className)}>
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            tableParameters={tableParameters}
                            dateRange={dateRange}
                            setDateRange={updateDateRange}
                            displayMode={displayMode}
                            changeDisplayMode={changeDisplayMode}
                            rows={rows}
                            columns={columns}
                            storage={storage}
                            reports={reports}
                            generalSettings={generalSettings}
                            userSettings={userSettings}
                            contextMenuRef={contextMenuRef}
                            contextMenuSettings={contextMenuSettings}
                        />

                        {chartStatus.isOpen && (
                            <Charts
                                datasets={flowsChart?.datasets}
                                labels={flowsChart?.labels}
                                onChange={data => setMetrics(data)}
                                metrics={metrics}
                                isLoading={!isFlowsChartLoaded || isFlowsChartFetching}
                            />
                        )}

                        <div className="main-table__visible-content" onScroll={onMainTableBodyScroll}>
                            <GeneralTable
                                chapter={chapter}
                                tableParameters={tableParameters}
                                dateRange={dateRange}
                                rows={rows}
                                activeRow={activeRow}
                                columns={columns}
                                columnFilters={columnFilters}
                                total={flowsList?.total?.statistic}
                                showTotalCheck={chartStatus.isOpen}
                                sorting={sorting}
                                isLoading={!isLoaded}
                                storage={storage}
                                generalSettings={generalSettings}
                                onRowClick={onRowClick}
                                onRowContextMenu={onRowRightClick}
                                onTableActionsChange={onTableActionsChange}
                                updateRows={updateRows}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScreenFlows;
