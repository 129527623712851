import { createSlice } from '@reduxjs/toolkit';
import NameSpace from './api/name-space';

const storePath = NameSpace.APP;

const initialState = {
    isAuthorized: null,
    isStartProjectAfterLogIn: false,
    isWithPromo: false,
    promocode: null
};

export const appSlice = createSlice({
    name: NameSpace.APP,
    initialState,
    reducers: {
        changeAppIsAuthorized: (state, action) => {
            state.isAuthorized = action.payload;
        },
        changeAppIsStartProjectAfterLogIn: (state, action) => {
            state.isStartProjectAfterLogIn = action.payload;
        },
        changeAppIsWithPromo: (state, action) => {
            state.isWithPromo = action.payload;
        },
        changeAppPromocode: (state, action) => {
            state.promocode = action.payload;
        }
    }
});

export const { changeAppIsAuthorized, changeAppIsStartProjectAfterLogIn, changeAppIsWithPromo, changeAppPromocode } =
    appSlice.actions;

export const selectAppData = state => state[storePath];

export default appSlice.reducer;
