import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { hideModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useDeleteFlowMutation, useGetFlowByIdQuery, useGetFlowsAllNamesQuery } from '../../../redux/api/flowsApi';
import { useGetCampaignsAllModalQuery } from '../../../redux/api/campaignsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import CommonActions from './CommonActions';
import CampaignItem from './CampaignItem';
import Loader from '../../Loader';

import '../modals.scss';

const ArchiveFlowModal = ({ onClose, currentFlowValue }) => {
    const txt = Langs[global.lng];

    const [isLoading, setIsLoading] = useState(true);
    const [flowCampaigns, setFlowCampaigns] = useState([]);

    const { data: flowData, isSuccess: isFlowDataLoaded } = useGetFlowByIdQuery(currentFlowValue);
    const { data: flows, isSuccess: isFlowsLoaded } = useGetFlowsAllNamesQuery();
    const { data: campaignsList, isSuccess: isCampaignsListLoaded } = useGetCampaignsAllModalQuery();

    const [commonRadio, setCommonRadio] = useState('accept');
    const [commonDropdown, setCommonDropdown] = useState(null);
    const [commonCheckbox, setCommonCheckbox] = useState(false);
    const [globalCampaignCheckbox, setGlobalCampaignCheckbox] = useState({ value: true, isStark: false });

    const flowsList = flows?.filter(item => item.value !== currentFlowValue);

    useEffect(() => {
        if (isFlowDataLoaded && isFlowsLoaded && isCampaignsListLoaded) {
            setFlowCampaigns(
                flowData?.campaigns?.map(item => {
                    const campaign = campaignsList?.find(el => el.value === item.value);

                    return {
                        value: item.value,
                        action: 'remove',
                        actionFlowId: currentFlowValue,
                        isDeleted: true,
                        displayName: campaign?.displayName,
                        rotation: campaign?.rotation
                    };
                })
            );

            setCommonDropdown(flows.filter(item => item.value !== currentFlowValue)[0]?.value);

            setIsLoading(false);
        }
    }, [flowData, flows, campaignsList]);

    const originalSettingsQuantity = useMemo(
        () => flowCampaigns?.filter(item => !!item.rotation.originalSettingsAt).length,
        [flowCampaigns?.length]
    );

    const changeCampaign = campaign =>
        setFlowCampaigns(flowCampaigns.map(item => (item.value === campaign.value ? campaign : item)));

    const getGlobalCampaignCheckboxValues = campaign => {
        const currentFlowCampaigns = campaign
            ? flowCampaigns.map(item => (item.value === campaign.value ? campaign : item))
            : flowCampaigns;

        const isDeletedCount = currentFlowCampaigns.reduce((ac, item) => (item.isDeleted ? ac + 1 : ac), 0);
        if (isDeletedCount === currentFlowCampaigns.length) return { value: true, isStark: false };
        return isDeletedCount === 0 ? { value: false, isStark: false } : { value: false, isStark: true };
    };

    const changeGlobalCheckbox = value => {
        const newValues = globalCampaignCheckbox.isStark
            ? { value: true, isStark: false }
            : { value, isStark: globalCampaignCheckbox.isStark };
        const newFlowCampaigns = flowCampaigns.map(item => ({ ...item, isDeleted: newValues.value }));

        setFlowCampaigns(newFlowCampaigns);
        setGlobalCampaignCheckbox(newValues);
    };

    const [
        deleteFlow,
        {
            isLoading: isDeleteFlowLoading,
            isSuccess: isDeleteFlowSuccess,
            isError: isDeleteFlowError,
            error: deleteFlowError
        }
    ] = useDeleteFlowMutation();

    useEffect(() => {
        if (isDeleteFlowSuccess) {
            toast.success(txt.toasts.getEntityWasSuccessfullyArchived(flowData.name));
            hideModal();
        }
    }, [isDeleteFlowSuccess]);

    useEffect(() => {
        if (isDeleteFlowError) toast.error(getResponseErrorMessage(deleteFlowError.data, txt.toasts.saveError));
    }, [isDeleteFlowError]);

    const onConfirm = () => {
        const getActualFlowId = el => {
            const commonActionFlowId = (() => {
                if (commonCheckbox && el.rotation.originalSettingsAt) return null;
                return commonRadio === 'change' ? commonDropdown : currentFlowValue;
            })();
            return el.isDeleted ? commonActionFlowId : el.actionFlowId;
        };

        deleteFlow({
            id: currentFlowValue,
            data: {
                id: currentFlowValue,
                campaigns: flowCampaigns.map(item => ({
                    id: item.value,
                    action: 'remove',
                    flowId: getActualFlowId(item)
                }))
            }
        });
    };

    return (
        <>
            <div className="archive-flow-modal__content">
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="archive-flow-modal__common-options-block">
                            <CheckBoxInput
                                className="archive-flow-modal__row-checkbox archive-flow-modal__row-checkbox-with-title"
                                dataTest="archive-flow-common-block"
                                label={txt.labels.campaigns}
                                value={globalCampaignCheckbox.value}
                                isStark={globalCampaignCheckbox.isStark}
                                onChange={({ target: { checked } }) => changeGlobalCheckbox(checked)}
                            />
                            <CommonActions
                                radioValue={commonRadio}
                                setRadio={setCommonRadio}
                                flowValue={commonDropdown}
                                setFlowValue={setCommonDropdown}
                                checkboxValue={commonCheckbox}
                                setCheckboxValue={setCommonCheckbox}
                                flowsList={flowsList}
                                originalSettingsQuantity={originalSettingsQuantity}
                                flowCampaignsQuantity={flowCampaigns?.length}
                                globalCampaignCheckbox={globalCampaignCheckbox}
                            />
                        </div>

                        <div className="archive-flow-modal__list-block">
                            {flowCampaigns.map(item => (
                                <CampaignItem
                                    key={item.value}
                                    flowsList={flowsList}
                                    campaign={item}
                                    currentFlowValue={currentFlowValue}
                                    onChange={changeCampaign}
                                    getGlobalCampaignCheckboxValues={getGlobalCampaignCheckboxValues}
                                    setGlobalCampaignCheckbox={setGlobalCampaignCheckbox}
                                    commonSettings={{ commonRadio, commonDropdown, commonCheckbox }}
                                />
                            ))}
                        </div>

                        {isDeleteFlowLoading && <Loader isFetching />}
                    </>
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onConfirm={onConfirm}
                confirmButtonType="danger"
                onReject={onClose}
                isLoading={isLoading}
            />
        </>
    );
};

export default ArchiveFlowModal;
