import indexApi from './indexApi';
import { transform } from '../transformers';

const ipFilteringApiTagTypes = {
    ipFiltering: 'ipFiltering'
};

const ipFilteringApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(ipFilteringApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getIpFilters: builder.query({
                query: () => `/ip-filters`,
                transformResponse: response => response.map(el => transform.ipFilters.import(el)),
                providesTags: () => [ipFilteringApiTagTypes.ipFiltering]
            }),

            addIpFilter: builder.mutation({
                query: ({ data }) => ({
                    url: `/ip-filters`,
                    method: 'POST',
                    body: transform.ipFilters.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [ipFilteringApiTagTypes.ipFiltering];
                    return [];
                }
            }),

            editIpFilter: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/ip-filters/${id}`,
                    method: 'PUT',
                    body: transform.ipFilters.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [ipFilteringApiTagTypes.ipFiltering];
                    return [];
                }
            }),

            deleteIpFilter: builder.mutation({
                query: ({ id }) => ({
                    url: `/ip-filters/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [ipFilteringApiTagTypes.ipFiltering];
                    return [];
                }
            })
        })
    });

export const { useGetIpFiltersQuery, useAddIpFilterMutation, useEditIpFilterMutation, useDeleteIpFilterMutation } =
    ipFilteringApi;
