import React, { useEffect } from 'react';

import Langs from '../../../../Langs';
import { getOnlyASCIICodeText } from '../../../../Functions/utils';
import useFocus from '../../../../Functions/hooks/useFocus';

import ModalContentContainer from '../../../Overall/Modal/ModalContentContainer';
import TextInput from '../../../Overall/Forms/Inputs/TextInput';
import TagInput from '../../../../components/inputs/tag-input/tag-input';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';

const AffiliateNetworkSettings = React.memo(
    ({ workspaceId, tags, name, isNeedValidate, updateAffiliateNetwork, isCustomTemplate, workspacesList }) => {
        const txt = Langs[global.lng];

        const [nameInputRef, setNameFocus] = useFocus();

        useEffect(() => {
            if (isCustomTemplate && !name) setTimeout(setNameFocus, 0);
        }, [name]);

        const handleNameChange = ({ target: { value } }) => {
            const transformedValue = getOnlyASCIICodeText(value?.trimStart());
            updateAffiliateNetwork({ name: transformedValue || '' });
        };

        return (
            <ModalContentContainer>
                <div className="j4 affiliate-network__container">
                    <SelectInput
                        dataTest="affiliate-network_workspace-select-input"
                        label={txt.labels.workspace}
                        value={workspaceId}
                        items={workspacesList}
                        hint={txt.hints.workspace(txt.labels.affiliateNetwork)}
                        onChange={({ target: { value } }) => updateAffiliateNetwork({ workspaceId: value })}
                        className="affiliate-network__workspace"
                    />

                    <TagInput
                        label="Select or start entering tags"
                        hint="Select or start entering tags"
                        tagList={tags}
                        onChange={newTags => updateAffiliateNetwork({ tags: newTags })}
                    />
                </div>

                <div className="mt15">
                    <TextInput
                        dataTest="affiliate-network_name-input"
                        ref={nameInputRef}
                        label={txt.labels.name}
                        value={name}
                        onChange={handleNameChange}
                        error={isNeedValidate && !name?.trim() && txt.errors.required(txt.labels.name)}
                    />
                </div>
            </ModalContentContainer>
        );
    }
);

AffiliateNetworkSettings.displayName = 'AffiliateNetworkSettings';
export default AffiliateNetworkSettings;
