import { providesList } from '../services';
import indexApi from './indexApi';
import { projectApiTagTypes } from './projectApi';

const supportAccessApiTagTypes = {
    supportAccessSchedule: 'supportAccessSchedule'
};

export const supportAccessApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(supportAccessApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getSupportAccessSchedule: builder.query({
                query: () => `/projects/support-access/schedule`,
                providesTags: result => providesList(result, supportAccessApiTagTypes.supportAccessSchedule)
            }),

            addSupportAccessSchedule: builder.mutation({
                query: ({ data }) => ({
                    url: `/projects/support-access/schedule`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: supportAccessApiTagTypes.supportAccessSchedule, id: 'LIST' }];
                }
            }),

            editSupportAccessSchedule: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/projects/support-access/schedule/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: supportAccessApiTagTypes.supportAccessSchedule, id: 'LIST' },
                            { type: supportAccessApiTagTypes.supportAccessSchedule, id }
                        ];
                }
            }),

            changeSupportAccess: builder.mutation({
                query: ({ data }) => ({
                    url: '/users/support-access',
                    method: 'PATCH',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [projectApiTagTypes.projectInfo];
                }
            }),

            deleteSupportAccessSchedule: builder.mutation({
                query: ({ id }) => ({
                    url: `/projects/support-access/schedule/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: supportAccessApiTagTypes.supportAccessSchedule, id: 'LIST' }];
                }
            })
        })
    });

export const {
    useGetSupportAccessScheduleQuery,
    useAddSupportAccessScheduleMutation,
    useEditSupportAccessScheduleMutation,
    useChangeSupportAccessMutation,
    useDeleteSupportAccessScheduleMutation
} = supportAccessApi;
