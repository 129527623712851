import React, { useState, useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { produce } from 'immer';
import { toast } from 'react-toastify';

import Langs from '../../Langs';
import { EMAIL_RULE, KEYBOARD_SERVICE_KEY } from '../../Constants';
import { getResponseErrorMessage } from '../../Functions/utils';

import useKeyPress from '../../Functions/hooks/useKeyPress';

import { useResetPasswordMutation } from '../../redux/api/authApi';

import AuthForm, { AuthBtn } from '../../Modules/AuthForm/AuthForm';
import TextInput from '../../Modules/Overall/Forms/Inputs/TextInput';
import Icon from '../../Modules/Overall/Icon';

const PageForgot = () => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const location = useLocation();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isResettingSuccess, setIsResettingSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [userData, setUserData] = useState({ email: location.state?.email || '' });

    const errors = { email: !EMAIL_RULE.test(userData.email.toLowerCase()) ? txt.errors.email : '' };

    const isAllValid = Object.keys(errors).every(el => !errors[el]);

    const updateUserData = useCallback((prop, value) => {
        setIsSubmitted(false);

        setUserData(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    const [
        resetPassword,
        { isSuccess: isResetPasswordSuccess, isError: isResetPasswordError, error: resetPasswordError }
    ] = useResetPasswordMutation();

    useEffect(() => {
        if (isResetPasswordSuccess) {
            setIsResettingSuccess(true);
            setIsLoading(false);
        }
    }, [isResetPasswordSuccess]);

    useEffect(() => {
        if (isResetPasswordError) {
            toast.error(getResponseErrorMessage(resetPasswordError.data, txt.errors.passwordResetError));
            setIsLoading(false);
        }
    }, [isResetPasswordError]);

    const onSubmit = () => {
        setIsSubmitted(true);

        if (isAllValid) {
            setIsLoading(true);
            resetPassword(userData.email);
        }
    };

    useKeyPress(KEYBOARD_SERVICE_KEY.ENTER, onSubmit);

    return (
        <AuthForm
            title={txt.auth.passwordReset}
            questionLabel={txt.auth.dontHaveAcc}
            exitLabel={txt.auth.signUp}
            onExit={() => history.push('/register')}
        >
            <Link data-test="forgot_back-link" to="/login" className="form-link j4 mb30">
                <Icon.ArrowLeft size={16} className="mr10" />
                {txt.buttons.back}
            </Link>

            {isResettingSuccess ? (
                <div className="forgot-email-sent">
                    {txt.texts.getTextEmailResetPasswordHasBeenSent(userData.email)}
                </div>
            ) : (
                <TextInput
                    dataTest="forgot_email-input"
                    value={userData.email}
                    onChange={({ target: { value } }) => updateUserData('email', value)}
                    icon="email"
                    label={txt.labels.email}
                    placeholder={txt.labels.email}
                    error={isSubmitted && errors.email}
                    disabled={isLoading}
                    className="mb20"
                />
            )}

            {isResettingSuccess ? (
                <AuthBtn type="ok" onClick={() => history.push('/login')}>
                    {txt.buttons.ok}
                </AuthBtn>
            ) : (
                <AuthBtn disabled={isLoading || (isSubmitted && !isAllValid)} onClick={onSubmit} isLoading={isLoading}>
                    {txt.auth.sendPwdResetLink}
                </AuthBtn>
            )}
        </AuthForm>
    );
};

export default PageForgot;
