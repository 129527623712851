export const trackingDomains = [
    {
        key: 'isDefault',
        label: 'Default',
        type: 'radio'
    },
    {
        key: 'domain',
        label: 'Domain',
        type: 'domain'
    },
    /*{
        key: 'workspaces',
        label: 'Assigned workspaces',
        type: 'list'
    },*/
    {
        key: 'dnsStatus',
        label: 'DNS Status',
        type: 'statusWithUpdating'
    },
    {
        key: 'destination',
        label: 'Index',
        type: 'index'
    },
    {
        key: 'actions',
        type: 'actions'
    }
];

export const redirectDomains = [
    {
        key: 'isDefault',
        label: 'Default',
        type: 'radio'
    },
    {
        key: 'domain',
        label: 'Domain',
        type: 'domain'
    },
    /*{
        key: 'workspaces',
        label: 'Assigned workspaces',
        type: 'list'
    },*/
    {
        key: 'dnsStatus',
        label: 'DNS Status',
        type: 'statusWithUpdating'
    },
    {
        key: 'actions',
        type: 'actions'
    }
];
