import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import Langs from '../../../Langs';
import { getMoneyIntlFormattedValue } from '../../../redux/operations';

import { useGetPlansQuery } from '../../../redux/api/plansApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetPaymentMethodListQuery } from '../../../redux/api/paymentApi';
import { useGetProjectPlanTransitionInfoQuery } from '../../../redux/api/projectApi';

import { CloseBtn } from '../../Overall/UI/Btn/Btn';
import Plan from '../choose-plan-modal/components/plan/plan';
import AddBillingInfoModal from '../add-billing-info-modal/add-billing-info-modal';
import ChangePlanPaymentComponent from '../change-plan-modal/components/change-plan-payment-component';
import Loader from '../../Loader';

const UpgradePlanModal = ({ planId, onClose, onBackToPlans, isEmptyInvoiceDetails }) => {
    const txt = Langs[global.lng];

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: planList, isSuccess: isPlanListLoaded } = useGetPlansQuery();
    const { data: paymentMethodList, isSuccess: isPaymentMethodListLoaded } = useGetPaymentMethodListQuery();
    const { data: transition, isSuccess: isTransitionLoaded } = useGetProjectPlanTransitionInfoQuery(planId);

    const [isEditBilling, setIsEditBilling] = useState(isEmptyInvoiceDetails);
    const [activePaymentMethod, setActivePaymentMethod] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (isPaymentMethodListLoaded) setActivePaymentMethod(paymentMethodList.find(el => el.isDefault));
    }, [paymentMethodList]);

    const onCloseModal = () => {
        toast.success(txt.toasts.planUpgrade);
        if (window.tidioChatApi) window.tidioChatApi.addVisitorTags([transition.nextPlan.name]);
        onClose(null, true);
    };

    const confirmInvoiceDetails = isOk => {
        if (isOk) setIsEditBilling(false);
    };

    const isLoaded =
        isUserSettingsLoaded &&
        isCurrenciesLoaded &&
        isPlanListLoaded &&
        isTransitionLoaded &&
        isPaymentMethodListLoaded;

    return (
        <div className="chage-plan-modal chage-plan-modal__inner">
            <div className="chage-plan-modal__header">
                <span className="chage-plan-modal__modal-title">{txt.labels.upgradePlan}</span>
                <CloseBtn className="modal2__btn-close" onClick={onClose} />
            </div>

            <div className="chage-plan-modal__body chage-plan-modal__body_next-step">
                {!isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <div className="chage-plan-modal__selectable-plan">
                            <Plan
                                plan={planList.find(el => el.id === planId)}
                                buttonText={txt.buttons.backToPlans}
                                onClick={onBackToPlans}
                            />
                        </div>

                        <div className="chage-plan-modal__details-container">
                            {isEditBilling ? (
                                <AddBillingInfoModal
                                    className="chage-plan-modal__billing-info"
                                    isIntegrated
                                    handleConfirmation={confirmInvoiceDetails}
                                />
                            ) : (
                                <>
                                    <div className="chage-plan-modal__description-block">
                                        <div className="chage-plan-modal__description-block-plan">
                                            <div>
                                                <span>{transition.nextPlan.name}</span>
                                                <span>
                                                    {getMoneyIntlFormattedValue(
                                                        transition.nextPlan.monthlyPayment,
                                                        transition.nextPlan.paymentCurrency,
                                                        currencies
                                                    )}
                                                </span>
                                            </div>
                                            <span>
                                                {`${txt.texts.youWillPay} ${moment(
                                                    transition.transition.nextBillingDate
                                                ).format('ll')}`}
                                            </span>
                                        </div>

                                        <div className="chage-plan-modal__description-block-separator" />

                                        <div className="chage-plan-modal__description-block-amount">
                                            <div>
                                                <span>
                                                    <b>{txt.texts.paymentAmountToday}</b>
                                                </span>
                                                <span>
                                                    <b>
                                                        {getMoneyIntlFormattedValue(
                                                            transition.transition.paymentAmount,
                                                            transition.transition.paymentCurrency ||
                                                                transition.nextPlan.paymentCurrency,
                                                            currencies
                                                        )}
                                                    </b>
                                                </span>
                                            </div>
                                            <span>
                                                {txt.texts.youHaveAlreadyPaid(
                                                    getMoneyIntlFormattedValue(
                                                        transition.currentPlan.monthlyPayment,
                                                        transition.currentPlan.paymentCurrency,
                                                        currencies
                                                    ),
                                                    transition.currentPlan.name
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    <ChangePlanPaymentComponent
                                        className="chage-plan-modal__payment-modal"
                                        paymentMethodList={paymentMethodList}
                                        nextPlanId={transition.nextPlan.id}
                                        onConfirm={onCloseModal}
                                        amountForButton={getMoneyIntlFormattedValue(
                                            transition.transition.paymentAmount,
                                            transition.transition.paymentCurrency ||
                                                transition.nextPlan.paymentCurrency,
                                            currencies
                                        )}
                                        backToInvoiceDetails={() => setIsEditBilling(true)}
                                        activePaymentMethod={activePaymentMethod}
                                        setActivePaymentMethod={setActivePaymentMethod}
                                        setIsProcessing={setIsProcessing}
                                        userEmail={userSettings?.email}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}

                {isProcessing && <Loader isFetching />}
            </div>
        </div>
    );
};

export default UpgradePlanModal;
