import moment from 'moment-timezone';
import { PUBLIC_WORKSPACE, WORKSPACE_TYPE } from '../Constants';
import Langs from '../Langs';
import { camelToSnakeCase, clone, omitKeys, snakeToCamelCase, generateString } from '../Functions/utils';

const getLangs = () => Langs[global.lng];

export const transformKeyExceptionsForDownloadFile = key => {
    const exceptions = {
        id: 'incrementId',
        displayName: 'name',
        postback: 'postbackTimestamp',
        visit: 'visitTimestamp',
        campaign: 'campaignId',
        trafficSource: 'trafficSourceId',
        offerName: 'offerId',
        rotationName: 'rotation'
    };
    return Object.keys(exceptions).includes(key) ? exceptions[key] : key;
};

export const landersForNewItemTransformer = el =>
    el.map(props => ({
        value: props.id,
        id: props.incrementId,
        workspaceId: props.workspaceId,
        name: props.name,
        countryTag: props.countryTag,
        verify: props.verify,
        numberOfOffers: props.landingPageSetup.numberOfOffers,
        isMatchLanderUrl: props.landingPageSetup.isClickUrlNotMatchLanderUrl,
        clickUrlPage: props.landingPageSetup.clickUrl,
        isClickUrlClassic: props.landingPageSetup.isClickUrlClassic,
        hasRedirectlessScript: props.landingPageSetup.isRedirectlessScript,
        hasProtectLandingPage: props.landingPageSetup.isLandingPageProtected,
        isArchived: props.isArchived
    }));

export const offersForNewItemTransformer = (offersList, affiliateNetworksList) =>
    offersList.map(props => ({
        value: props.id,
        id: props.incrementId,
        workspaceId: props.workspaceId,
        label: props.name,
        name: props.name,
        countryTag: props.countryTag,
        payoutType: snakeToCamelCase(props.payout.method),
        payout: props.payout.price,
        payoutCurrency: props.payout.currency,
        isCapEnabled: props.offerCAP.isEnabled,
        capType: snakeToCamelCase(props.offerCAP.capType),
        conversions: props.offerCAP.dayConversions,
        clicks: props.offerCAP.dayClicks,
        conversionCap: props.offerCAP.conversionCAP,
        clickCap: props.offerCAP.clickCAP,
        redirectOffer: props.offerCAP.offerId,
        timeZone: props.offerCAP.timezone,
        offerUrl: props.url,
        affiliateNetworkId: props.affiliateNetworkId,
        affiliateNetworkName: affiliateNetworksList?.find(item => item.value === props.affiliateNetworkId)?.label,
        isArchived: props.isArchived
    }));

export const trafficSourceForNewItemTransformer = data => {
    return data?.map(
        ({ id: value, name, currency, trackingOptions: { isDirectTracking }, isIntegration, workspaceId }) => ({
            value,
            name,
            label: generateString(
                name,
                [
                    { isInclude: isDirectTracking, value: getLangs().labels.directTracking },
                    { isInclude: isIntegration, value: getLangs().labels.integrated }
                ],
                {
                    prefix: '(',
                    postfix: ')',
                    joinSeparator: ', '
                }
            ),
            currency,
            directTracking: isDirectTracking,
            workspaceId
        })
    );
};

export const transform = {
    // REFACTORED:
    defaultRule: {
        import: props => {
            return {
                isPathOptimization: props.weightOptimizationIsEnabled,
                isRuleOptimizedData: !!props.optimization?.paths?.length,
                lastOptimizationAt: props.optimization?.updatedAt,

                optimizationSettings: {
                    visitsOptimizationType: props.visitsOptimizationType,
                    visitsOptimizationAlgorithm: props.algorithmOptimizationType,
                    visitsOptimizationValue: props.countVisits
                },

                paths: props.paths.map(path => ({
                    id: path.id,
                    name: path.name,
                    isReferHide: path.isReferHide,
                    isEnabled: path.isEnabled,
                    isOpened: true,
                    hideReferer: path.hideReferer,
                    weight: path.weight,
                    isOptimizationWeights: path.weightOptimization?.isEnabled,
                    weightsOptimization: snakeToCamelCase(path.weightOptimization?.mode),
                    weightsOptimizationMethod: path.weightOptimization?.calculationMethod,

                    optimizedWeightData: (() => {
                        if (!props.optimization) return null;
                        const pathWeightsData = props?.optimization?.paths.find(el => path.id === el.id);
                        if (pathWeightsData) return pathWeightsData;
                        else if (props?.optimization?.paths.length) return { weight: 0 };
                        return null;
                    })(),

                    landers: path.landers.map(el => ({
                        landerValue: el.landerId,
                        isEnabled: el.isEnabled,
                        weight: el.weight,
                        optimizedWeight: (() => {
                            const optimizedPath = props?.optimization?.paths?.find(it => path.id === it.id);
                            return optimizedPath?.landers?.find(it2 => it2.id === el.landerId)?.weight || 0;
                        })()
                    })),

                    offers: path.offers.map(el => ({
                        offerValue: el.offerId,
                        isEnabled: el.isEnabled,
                        weight: el.weight,
                        optimizedWeight: (() => {
                            const optimizedPath = props?.optimization?.paths?.find(it => path.id === it.id);
                            if (optimizedPath?.offers) {
                                return optimizedPath.offers?.find(it => it.id === el.offerId)?.weight || 0;
                            }
                            return optimizedPath?.landers?.[0]?.offers?.find(it2 => it2.id === el.offerId)?.weight || 0;
                        })()
                    }))
                }))
            };
        },

        export: props => {
            return {
                weightOptimizationIsEnabled: props.isPathOptimization,
                ...(props.optimizationSettings
                    ? {
                          visitsOptimizationType: props.optimizationSettings.visitsOptimizationType,
                          algorithmOptimizationType: props.optimizationSettings.visitsOptimizationAlgorithm,
                          countVisits: props.optimizationSettings.visitsOptimizationValue,
                          countVisitsCalculated: props.optimizationSettings.visitsOptimizationCalculatedValue
                      }
                    : {}),
                paths: props.paths
                    .filter(({ isDeleted }) => !isDeleted)
                    .map(item => ({
                        id: item.id,
                        name: item.name,
                        isReferHide: item.isReferHide,
                        isEnabled: item.isEnabled,
                        hideReferer: item.hideReferer,
                        weight: item.weight,
                        weightOptimization: {
                            isEnabled: item.isOptimizationWeights,
                            mode: camelToSnakeCase(item.weightsOptimization),
                            calculationMethod: item.weightsOptimizationMethod
                        },
                        landers: item.landers?.length
                            ? item.landers
                                  .filter(i => !i.isDeleted)
                                  .map(el => ({
                                      landerId: el.landerValue,
                                      isEnabled: el.isEnabled,
                                      weight: el.weight
                                  }))
                            : [],
                        offers: item.offers?.length
                            ? item.offers
                                  .filter(i => !i.isDeleted)
                                  .map(el => ({
                                      offerId: el.offerValue,
                                      isEnabled: el.isEnabled,
                                      weight: el.weight
                                  }))
                            : []
                    }))
            };
        }
    },

    // REFACTORED:
    rule: {
        import: rules => {
            return rules.map(
                ({
                    id,
                    name,
                    isEnabled: isRuleEnabled,
                    logicalRelation,
                    paths,
                    criteria,
                    weightOptimizationIsEnabled,
                    optimization,
                    visitsOptimizationType,
                    algorithmOptimizationType,
                    countVisits
                }) => ({
                    value: id,
                    name,
                    isRuleEnabled,
                    isOpened: true,
                    logicalRelation,
                    isPathOptimization: weightOptimizationIsEnabled,
                    isRuleOptimizedData: !!optimization?.paths?.length,
                    // calculationMethod: 'auto',
                    lastOptimizationAt: optimization?.updatedAt,

                    optimizationSettings: {
                        visitsOptimizationType,
                        visitsOptimizationAlgorithm: algorithmOptimizationType,
                        visitsOptimizationValue: countVisits
                    },

                    paths: paths.map(path => ({
                        id: path.id,
                        name: path.name,
                        isEnabled: path.isEnabled,
                        hideReferer: path.hideReferer,
                        weight: path.weight,
                        isReferHide: path.isReferHide,
                        isOpened: true,
                        isOptimizationWeights: path.weightOptimization?.isEnabled,
                        weightsOptimization: snakeToCamelCase(path.weightOptimization?.mode),
                        weightsOptimizationMethod: path.weightOptimization?.calculationMethod,

                        optimizedWeightData: (() => {
                            if (!optimization) return null;
                            const pathWeightsData = optimization?.paths.find(el => path.id === el.id);
                            if (pathWeightsData) return pathWeightsData;
                            else if (optimization?.paths.length) return { weight: 0 };
                            return null;
                        })(),

                        landers: path.landers.map(el => ({
                            landerValue: el.landerId,
                            isEnabled: el.isEnabled,
                            weight: el.weight,
                            optimizedWeight: (() => {
                                const optimizedPath = optimization?.paths?.find(it => path.id === it.id);
                                return optimizedPath?.landers?.find(it2 => it2.id === el.landerId)?.weight || 0;
                            })()
                        })),

                        offers: path.offers.map(el => ({
                            offerValue: el.offerId,
                            isEnabled: el.isEnabled,
                            weight: el.weight,
                            optimizedWeight: (() => {
                                const optimizedPath = optimization?.paths?.find(it => path.id === it.id);
                                if (optimizedPath?.offers) {
                                    return optimizedPath.offers?.find(it => it.id === el.offerId)?.weight || 0;
                                }
                                return (
                                    optimizedPath?.landers?.[0]?.offers?.find(it2 => it2.id === el.offerId)?.weight || 0
                                );
                            })()
                        }))
                    })),

                    criteria: Object.entries(criteria).map(([key, value]) => ({
                        key,
                        ...{ value: value.data, ...value }
                    }))
                })
            );
        },

        export: rules => {
            return rules
                .filter(rule => !rule.isDeleted)
                .map(item => ({
                    ...(item.value ? { id: item.value } : {}),
                    name: item.name,
                    isEnabled: item.isRuleEnabled,
                    logicalRelation: item.logicalRelation,
                    weightOptimizationIsEnabled: item.isPathOptimization,
                    ...(item.optimizationSettings
                        ? {
                              visitsOptimizationType: item.optimizationSettings.visitsOptimizationType,
                              algorithmOptimizationType: item.optimizationSettings.visitsOptimizationAlgorithm,
                              countVisits: item.optimizationSettings.visitsOptimizationValue,
                              countVisitsCalculated: item.optimizationSettings.visitsOptimizationCalculatedValue
                          }
                        : {}),
                    paths: item.paths
                        .filter(({ isDeleted }) => !isDeleted)
                        .map(child => ({
                            id: child.id,
                            name: child.name,
                            weight: child.weight,
                            hideReferer: child.hideReferer,
                            isEnabled: child.isEnabled,
                            isReferHide: child.isReferHide,
                            weightOptimization: {
                                isEnabled: child.isOptimizationWeights,
                                mode: camelToSnakeCase(child.weightsOptimization),
                                calculationMethod: child.weightsOptimizationMethod
                            },
                            landers: child.landers
                                .filter(i => !i.isDeleted)
                                .map(child2 => ({
                                    landerId: child2.landerValue,
                                    isEnabled: child2.isEnabled,
                                    weight: child2.weight
                                })),
                            offers: child.offers
                                .filter(i => !i.isDeleted)
                                .map(child2 => ({
                                    offerId: child2.offerValue,
                                    isEnabled: child2.isEnabled,
                                    weight: child2.weight
                                }))
                        })),
                    criteria: clone(item.criteria).reduce(
                        (res, crt) => ({
                            ...res,
                            [crt.key]: omitKeys({ ...crt, ...(crt.value ? { data: crt.value } : {}) }, [
                                'key',
                                'value',
                                'label',
                                'placeholder',
                                'tabId',
                                'items',
                                'isHidden',
                                'Component',
                                'getFlatValue',
                                'getCount'
                            ])
                        }),
                        {}
                    )
                }));
        }
    },

    // REFACTORED:
    trackingMethod: {
        import: props => ({
            value: props.trackingMethod,
            type: props.directType
        })
    },

    // REFACTORED:
    trafficSources: {
        importList: props => ({
            ...props,
            value: props.id,
            id: props.incrementId
        }),

        import: el => ({
            workspaceId: el.workspaceId,
            name: el.name,

            variables: el.variables,

            costCurrency: el.currency,

            isPostbackUrl: el.passingConversion?.postback.isEnabled,
            isAutoGeneratePostbackUrl: el.passingConversion?.postback.isAutoGenerate,
            postbackUrl: el.passingConversion?.postback.url,
            isPostbackApproved: el.passingConversion?.postback?.statuses?.includes('approved'),
            isPostbackNew: el.passingConversion?.postback?.statuses?.includes('new'),
            isPostbackDeclined: el.passingConversion?.postback?.statuses?.includes('declined'),
            isPostbackChargeback: el.passingConversion?.postback?.statuses?.includes('chargeback'),

            isPixelUrl: el.passingConversion?.pixel.isEnabled,
            pixelUrl: el.passingConversion?.pixel.url,

            isImpressionTracking: el.trackingOptions?.isImpressionTracking,
            isDirectTracking: el.trackingOptions?.isDirectTracking,

            isArchived: el.isArchived,

            isIntegration: el.isIntegration,
            templateId: el.templateId,
            integrationId: el.integration?.id,
            isIntegrationAvailable: !!el.integration?.clientName,
            ...(el.integration?.isActive ? { isIntegrationActive: el.integration?.isActive } : {}),

            createdAtUserId: el.createdAtUserId,
            updatedAtUserId: el.updatedAtUserId,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt,

            value: el.id,
            id: el.incrementId

            // postbackPct: 100,
            // payoutPct: 100,
            // trafficLossPct: el.trackingOptions?.trafficLossPercent,
        }),

        export: props => ({
            workspaceId: props.workspaceId,
            name: props.name,

            variables: props.variables,
            currency: props.costCurrency,

            isRestore: props.isRestore,

            isIntegration: props.isIntegration ?? false,
            integrationId: props.isIntegration ? props.integrationId : null,
            templateId: props.templateId,
            templateIsIntegrationAvailable: props.isIntegrationAvailable,

            passingConversion: {
                postback: {
                    isEnabled: props.isPostbackUrl,
                    isAutoGenerate: props.isAutoGeneratePostbackUrl,
                    url: props.isPostbackUrl ? props.postbackUrl : '',
                    statuses: [
                        props.isPostbackApproved && 'approved',
                        props.isPostbackNew && 'new',
                        props.isPostbackDeclined && 'declined',
                        props.isPostbackChargeback && 'chargeback'
                    ].filter(el => el)
                },
                pixel: {
                    isEnabled: props.isPixelUrl,
                    url: props.pixelUrl
                }
            },

            trackingOptions: {
                trafficLossPercent: Number(props.trafficLossPct) || 0,
                isImpressionTracking: !!props.isImpressionTracking,
                isDirectTracking: !!props.isDirectTracking
            }
        })
    },

    // REFACTORED:
    trafficSourcesTemplates: {
        import: el => ({
            id: el.id,
            templateId: el.id,
            name: el.name,
            templateName: el.name,

            isPostbackUrl: !!el.postback,
            postbackUrl: el.postback ?? 'https://',

            pixelUrl: el.pixel,

            integrationAuthFields: el.integrationAuthFields,
            isIntegrationAvailable: el.isIntegrationAvailable,

            variables: el.variables
        })
    },

    // REFACTORED:
    affiliateNetworks: {
        importList: props => ({
            ...props,
            value: props.id,
            id: props.incrementId
        }),

        import: el => ({
            value: el.id,
            id: el.incrementId,
            name: el.name,
            workspaceId: el.workspaceId,
            tags: el.tags,

            conversionTracking: {
                clickToken: el.conversionTracking.cidToken,
                clickParameter: el.conversionTracking.cidParameter,
                payoutToken: el.conversionTracking.payoutToken,
                transactionToken: el.conversionTracking.txIdToken,
                eventToken: el.conversionTracking.etToken,
                statusToken: el.conversionTracking.statusToken,
                urlFormat: el.conversionTracking.urlFormat
            },

            isDoublePostbackAccepted: el.additionalOptions?.isDoublePostback,
            usePostbackStatuses: el.additionalOptions?.postbackStatuses.isEnabled,
            isClickIdAppendToOffer: el.additionalOptions?.isAppendClickId,
            isPostbackIpWhiteListEnabled: el.additionalOptions?.postbackIpWhiteList.isEnabled,
            ipList: el.additionalOptions?.postbackIpWhiteList.data,

            postbackStatuses: {
                newStatus: {
                    key: 'new',
                    value: el.additionalOptions?.postbackStatuses.new
                },
                approvedStatus: {
                    key: 'approved',
                    value: el.additionalOptions?.postbackStatuses.approved
                },
                declinedStatus: {
                    key: 'declined',
                    value: el.additionalOptions?.postbackStatuses.declined
                },
                chargeBackStatus: {
                    key: 'chargeback',
                    value: el.additionalOptions?.postbackStatuses.chargeback
                },
                defaultStatus: el.additionalOptions?.postbackStatuses.default
            },

            createdAtUserId: el.createdAtUserId,
            updatedAtUserId: el.updatedAtUserId,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt,
            isArchived: el.isArchived
        }),

        export: el => ({
            name: el.name,
            workspaceId: el.workspaceId,
            tags: el.tags,

            conversionTracking: {
                cidToken: el.conversionTracking.clickToken,
                cidParameter: el.conversionTracking.clickParameter,
                payoutToken: el.conversionTracking.payoutToken,
                txIdToken: el.conversionTracking.transactionToken,
                etToken: el.conversionTracking.eventToken,
                statusToken: el.conversionTracking.statusToken,
                urlFormat: el.conversionTracking.urlFormat
            },

            additionalOptions: {
                isDoublePostback: el.isDoublePostbackAccepted,
                postbackStatuses: {
                    isEnabled: el.usePostbackStatuses,
                    new: el.postbackStatuses.newStatus.value,
                    approved: el.postbackStatuses.approvedStatus.value,
                    declined: el.postbackStatuses.declinedStatus.value,
                    changeback: el.postbackStatuses.chargeBackStatus.value,
                    default: el.postbackStatuses.defaultStatus
                },
                isAppendClickId: el.isClickIdAppendToOffer,
                postbackIpWhiteList: {
                    isEnabled: el.isPostbackIpWhiteListEnabled,
                    data: el.ipList
                }
            },

            isRestore: el.isRestore
        })
    },

    // REFACTORED:
    affiliateNetworksTemplates: {
        import: el => ({
            id: el.id,
            name: el.name,
            templateName: el.name,
            conversionTracking: {
                clickToken: el.conversionTracking.cidToken,
                clickParameter: el.conversionTracking.cidParameter,
                payoutToken: el.conversionTracking.payoutToken,
                transactionToken: el.conversionTracking.txIdToken,
                eventToken: el.conversionTracking.etToken,
                statusToken: el.conversionTracking.statusToken,
                urlFormat: el.conversionTracking.urlFormat
            }
        })
    },

    // REFACTORED:
    landers: {
        importList: props => ({
            ...props,
            value: props.id,
            id: props.incrementId,
            numberOfOffers: props.landingPageSetup?.numberOfOffers
        }),

        import: props => ({
            value: props.id,
            id: props.incrementId,
            workspaceId: props.workspaceId,
            tags: props.tags,
            countryTag: props.countryTag,
            name: props.name,
            landerUrl: props.url,

            hasProtectLandingPage: props.landingPageSetup.isLandingPageProtected,
            hasRedirectlessScript: props.landingPageSetup.isRedirectlessScript,
            isMatchLanderUrl: props.landingPageSetup.isClickUrlNotMatchLanderUrl,
            clickUrlPage: props.landingPageSetup.clickUrl || '',

            offersNum: props.landingPageSetup.numberOfOffers,
            campaignsCount: props.campaignsCount,
            verify: props.verify,

            isArchived: props.isArchived,

            createdAtUserId: props.createdAtUserId,
            updatedAtUserId: props.updatedAtUserId,
            createdAt: props.createdAt,
            updatedAt: props.updatedAt
        }),

        export: props => ({
            isRestore: props.isRestore,
            workspaceId: props.workspaceId,
            countryTag: props.countryTag.toUpperCase(),
            name: props.name,
            url: props.landerUrl,

            landingPageSetup: {
                numberOfOffers: props.offersNum,
                isClickUrlNotMatchLanderUrl: props.isMatchLanderUrl,
                clickUrl: props.clickUrlPage || '',
                isRedirectlessScript: props.hasRedirectlessScript,
                isLandingPageProtected: props.hasProtectLandingPage
            },

            tags: props.tags
        })
    },

    // REFACTORED:
    offers: {
        importList: props => ({
            ...props,
            value: props.id,
            id: props.incrementId,
            affiliateNetworkName: props.affiliateNetwork?.name,
            conversionCap: props.offerCAP.isEnabled ? props.offerCAP.conversionCAP : null,
            clickCap: props.offerCAP.isEnabled ? props.offerCAP.clickCAP : null
        }),

        import: props => ({
            value: props.id,
            id: props.incrementId,
            workspaceId: props.workspaceId,
            tags: props.tags,
            countryTag: props.countryTag,
            affiliateNetworkId: props.affiliateNetworkId,
            name: props.name,
            offerUrl: props.url,

            payoutType: snakeToCamelCase(props.payout.method),
            payoutCurrency: props.payout.currency,
            payout: props.payout.price,
            conversionUrlFormat: props.conversionUrlFormat,

            isCapEnabled: props.offerCAP.isEnabled,
            capType: snakeToCamelCase(props.offerCAP.capType),
            redirectOffer: props.offerCAP.offerId,
            timeZone: props.offerCAP.timezone,
            conversionCap: props.offerCAP.conversionCAP,
            clickCap: props.offerCAP.clickCAP,

            isReserved: props.isReserved,
            createdAt: props.createdAt,
            updatedAt: props.updatedAt,
            createdAtUserId: props.createdAtUserId,
            updatedAtUserId: props.updatedAtUserId,
            isArchived: props.isArchived
        }),

        export: props => ({
            isRestore: props.isRestore,
            workspaceId: props.workspaceId,
            tags: props.tags,
            affiliateNetworkId: props.affiliateNetworkId,
            name: props.name,
            url: props.offerUrl,
            countryTag: props.countryTag.toUpperCase(),

            payout: {
                method: camelToSnakeCase(props.payoutType),
                price: props.payoutType === 'auto' ? null : Number(props.payout),
                currency: props.payoutCurrency
            },
            conversionUrlFormat: props.conversionUrlFormat,

            offerCAP: {
                isEnabled: props.isCapEnabled,
                capType: camelToSnakeCase(props.capType),
                offerId: props.redirectOffer || null,
                timezone: props.isCapEnabled ? props.timeZone : null,
                conversionCAP: +props.conversionCap === 0 ? null : +props.conversionCap || null,
                clickCAP: +props.clickCap === 0 ? null : +props.clickCap || null
            }
        })
    },

    // REFACTORED:
    campaigns: {
        importList: props => ({
            ...props,
            id: props.incrementId,
            value: props.id,

            cpa: props.CPA,
            cpc: props.CPC,
            cpm: props.CPM,

            trafficSource: props.trafficSourceId,

            suspicious: {
                positive: 500,
                negative: 70
            }
        }),

        import: props => ({
            value: props.id,
            name: props.name,
            createdAtUserId: props.createdAtUserId,
            updatedAtUserId: props.updatedAtUserId,
            createdAt: props.createdAt,
            updatedAt: props.updatedAt,
            displayName: props.displayName,
            id: props.incrementId,
            workspaceId: props.workspaceId,
            trafficSource: props.trafficSourceId,
            trafficSourceCampaignId: props.trafficSourceCampaignId,
            referrerDefaultHideType: props.hideRefererByDefault,
            costModel: props.cost.mode,
            costPrice: props.cost.price,
            percentageShare: props.cost.percentageShare,
            country: props.countryTag,
            tags: props.tags,
            trackingDomain: props.domainId,
            notes: props.notes,
            flowValue: props.flowId || 'custom',
            originalSettingsAt: props.originalSettingsAt,
            flow: props.flow ? transform.flows.import(props.flow) : null,
            // createdAt: props.createdAt,
            isArchived: props.isArchived,
            isTrackingUrlChanged: props.isTrackingUrlChanged || false,
            links: props.links,
            integration: props.integration,

            trackingMethod: transform.trackingMethod.import(props),

            defaultRule: transform.defaultRule.import(props.defaultRule),

            rules: transform.rule.import(props.rules)
        }),

        export: props => ({
            id: props.value,
            incrementId: props.id,
            trafficSourceId: props.trafficSource,
            trafficSourceCampaignId: props.trafficSourceCampaignId,
            countryTag: props.country,
            isRestore: props.isRestore,
            tags: props.tags,
            name: props.name,
            flowId: props.flowValue === 'custom' ? null : props.flowValue,
            notes: props.notes,
            hideRefererByDefault: props.referrerDefaultHideType,

            cost: {
                mode: props.costModel,
                price: Number(props.costPrice),
                percentageShare: Number(props.percentageShare)
            },

            workspaceId: props.workspaceId ?? PUBLIC_WORKSPACE,
            domainId: props.trackingDomain,
            trackingMethod: props.trackingMethod.value,
            directType: props.trackingMethod.type,

            defaultRule: transform.defaultRule.export(props.defaultRule),

            rules: transform.rule.export(props.rules)
        })
    },

    campaignsList: {
        import: el => ({
            ...el,
            id: el.incrementId,
            value: el.id,

            cpa: el.CPA,
            cpc: el.CPC,
            cpm: el.CPM,

            trafficSource: el.trafficSourceId,

            suspicious: {
                positive: 500,
                negative: 70
            }
        })
    },

    // REFACTORED:
    flows: {
        importList: props => ({
            ...props,
            value: props.id,
            id: props.incrementId
        }),

        import: props => ({
            ...props,
            value: props.id,
            id: props.incrementId,

            campaigns: props.campaigns?.map(item => ({
                value: item.id,
                action: null,
                actionFlowId: null,
                isDeleted: false
            })),

            trackingMethod: transform.trackingMethod.import(props),

            defaultRule: transform.defaultRule.import(props.defaultRule),

            rules: transform.rule.import(props.rules)
        }),

        export: props => ({
            isRestore: props.isRestore,
            workspaceId: props.workspaceId,
            name: props.name,
            trackingMethod: props.trackingMethod.value,
            directType: props.trackingMethod.type,

            campaigns:
                [...props.campaigns, ...props.campaignsToAdd].map(item => ({
                    id: item.value,
                    action: item.action,
                    flowId: item.actionFlowId
                })) || [],

            defaultRule: transform.defaultRule.export(props.defaultRule),

            rules: transform.rule.export(props.rules)
        })
    },

    users: {
        import: el => ({
            value: el.id,
            id: el.id,
            email: el.email,
            firstName: el.firstName,
            lastName: el.lastName,
            name: `${el.firstName} ${el.lastName}`,
            role: el.role,
            workspaces: el.workspaces.map(workspace => workspace.id),
            isHideProfit: el.isHideProfit,
            isAllowSharingReport: el.isAllowSharingReport
        }),
        export: el => ({
            email: el.email,
            firstName: el.firstName,
            lastName: el.lastName,
            role: el.role,
            isAllowSharingReport: el.isAllowSharingReport,
            workspaces: el.workspaces,
            ...(el.password ? { password: el.password, confirmPassword: el.confirmPassword } : {}),
            ...(el.userPassword ? { updatedAtUserPassword: el.userPassword } : {}),
            isHideProfit: el.isHideProfit
        })
    },
    invitations: {
        import: el => ({
            ...(el.id ? { value: el.id } : {}),
            ...(el.email ? { email: el.email } : {}),
            ...(el.userRole ? { userRole: el.userRole } : {}),
            ...(el.workspaces ? { workspaces: el.workspaces } : {}),
            ...(el.isHideProfit ? { isHideProfit: el.isHideProfit } : {}),
            ...(el.isArchived ? { isArchived: el.isArchived } : {}),
            ...(el.createdAt ? { createdAt: el.createdAt } : {}),
            ...(el.status ? { status: el.status } : { status: 'pending' })
        }),
        export: el => ({
            email: el.email,
            role: el.userRole,
            workspaces: el.workspaces,
            isHideProfit: el.isHideProfit,
            isArchived: el.isArchived
        })
    },
    workspaces: {
        import: el => ({
            ...el,
            value: el.id,
            workers: el.users.map(item => item.name),
            domainsList: el.domains,
            domains: el.domains.map(item => item.name)
        })
    },
    settingUser: {
        import: ({ firstName, lastName, companyName = '', email, password = '' }) => ({
            firstName,
            lastName,
            companyName,
            email,
            password
        })
    },
    ipFilters: {
        // REFACTORED:
        import: props => ({
            id: props.id,
            name: props.name,
            ip: props.ip.join('\n'),
            userAgent: props.userAgent.join('\n')
        }),
        export: el => ({
            name: el.name,
            ip: el.ip.split('\n'),
            userAgent: el.userAgent.split('\n')
        })
    },
    eventLog: {
        // REFACTORED:
        import: el => ({
            changedAt: el.changedAt,
            actionType: el.actionType,
            entityId: el.entityId,
            entityType: el.entityType,
            entityIncrementId: el.entityIncrementId,
            entityName: el.entityName,
            userId: el.userId,
            email: el.userEmail,
            loginIpAddress: el.loginIpAddress,
            userName: `${el.userName} ${el?.isSupport ? `(${getLangs().labels.bySupport})` : ''}`,
            value: el.id
        })
    },
    customConversionEvents: {
        // REFACTORED:
        import: props => ({
            id: props.id,
            eventName: props.name,
            isConversions: props.includeInConversionColumn,
            isRevenue: props.includeInRevenueColumn,
            isPostback: props.sendPostback,
            isCost: props.includeInCostColumn,
            tags: props.values
        }),
        export: props => ({
            name: props.eventName,
            includeInConversionColumn: props.isConversions,
            includeInRevenueColumn: props.isRevenue,
            sendPostback: props.isPostback,
            includeInCostColumn: props.isCost,
            values: props.tags
        })
    },
    notifications: {
        // REFACTORED:
        import: props => ({
            value: props.id,
            isEnabled: props.isEnabled,
            isSystemMessage: props.isSystemMessage,
            name: props.name,
            template: props.template,
            templateData: {
                ...(props.values || props.templateData),
                ...(props.template === 'webhook'
                    ? {
                          postParams: (props.values?.postParams || props.templateData?.postParams).map(item => ({
                              name: item.param,
                              value: item.value
                          }))
                      }
                    : {})
            }
        }),
        export: props => ({
            name: props.name,
            template: props.template,
            isSystemMessage: props.isSystemMessage,
            [`${props.template}TemplateData`]: {
                ...props[`${props.template}TemplateData`],
                ...(props.template === 'webhook'
                    ? {
                          postParams: props[`${props.template}TemplateData`].postParams.map(item => ({
                              param: item.name,
                              value: item.value
                          }))
                      }
                    : {})
            }
        })
    },
    user: {
        import: props => ({
            token: props.access_token || props.token,
            tokenDate: props.expires_in || props.tokenDate,
            refresh: props.refresh_token || props.refresh
        })
        // export: props => ({})
    },
    invoice: {
        import: input => ({
            status: input.status,
            baseAmount: input.baseAmount,

            overEvents: input.overEvents || 'много',
            overUnits: input.overUnits,
            overUnitCost: input.overUnitCost,
            overAmount: input.overAmount,
            overPeriod: {
                dateFrom: input.overDateFrom,
                dateTo: input.overDateTo
            },
            totalAmount: input.totalAmount,
            period: {
                dateFrom: input.periodDateFrom,
                dateTo: input.dateTo
            },
            currency: input.currency
        })
    },
    integrations: {
        // REFACTORED:
        import: props => ({
            ...props,
            switcherText: props.isActive ? 'Active' : 'Paused',
            switcherTextColor: props.isActive ? '#00B031' : '#000'
        })
    },
    itemsListToSelect: {
        // REFACTORED:
        import: ({ id, name }) => ({ value: id, label: name })
    },
    timeZoneTransformer: {
        // REFACTORED:
        import: data => data.map(({ value, label, gmtOffset }) => ({ value, label: `${label} (${gmtOffset})` }))
    },
    redirectOffersList: {
        import: item => ({
            value: item.id,
            label: item.isArchived ? `${item.name} (${getLangs().labels.archived})` : item.name,
            id: item.incrementId,
            isCapEnabled: item.offerCAP?.isEnabled,
            isArchived: item.isArchived,
            workspaceId: item.workspaceId
        })
    },
    campaignsAllModal: {
        import: item => ({
            value: item.id,
            id: item.incrementId,
            workspaceId: item.workspaceId,
            name: item.name,
            displayName: item.displayName,
            trafficSourceId: item.trafficSource.id,
            trafficSourceName: item.trafficSource.name,
            rotation: {
                flowName: item.flow.name,
                flowId: item.flow.id,
                originalSettingsAt: item.originalSettingsAt
            },
            trackingMethod: {
                value: item.flow.id ? item.flow.trackingMethod : item.trackingMethod,
                type: item.flow.id ? item.flow.directType : item.directType
            }
        })
    },
    pagination: {
        // REFACTORED:
        import: el => ({ page: el.page, pages: el.pages, perPage: el['per-page'] })
        // export: el => ({ page: el.page ?? 1, pages: el.pages ?? 1, 'per-page': el.perPage ?? 50 })
    },

    reportStatistics: {
        // REFACTORED:
        import: item => ({
            ...item,
            value: item.id,
            isOpened: true,
            isChecked: false,
            isStark: false,

            children: item.children?.map(el => ({
                ...el,
                value: el.id,
                isOpened: true,
                isChecked: false,
                isStark: false,
                parentId: item.id,

                children: el.children?.map(it => ({
                    ...it,
                    value: it.id,
                    isOpened: true,
                    isChecked: false,
                    isStark: false,
                    parentId: item.id,
                    subParentId: el.id
                }))
            }))
        })
    },

    conversionList: {
        // REFACTORED:
        import: el => ({
            ...el,
            value: el.id,
            postback: el.postbackTimestamp,
            visit: el.visitTimestamp
        })
    },

    errorLog: {
        // REFACTORED:
        import: el => ({
            ...el,
            value: el.id,
            campaignName: el.campaign.name,
            campaignId: el.campaign.id
        })
    },

    userSettings: {
        // REFACTORED:
        import: el => ({
            ...el,
            publicWorkspaceId: el.workspaces.find(it => it.workspaceType === WORKSPACE_TYPE.PUBLIC)?.id,
            initialWorkspaceId: el.workspaces.find(it => it.workspaceType === WORKSPACE_TYPE.INITIAL)?.id,
            fullName: `${el.firstName} ${el.lastName}`
        })
    }
};

// export const itemsListTransformer = data => data?.map(({ id, code, name: label }) => ({ value: id || code, label }));

export const paymentMethodListTransformer = data => {
    return data
        .map(el => ({
            ...el,
            cardNumber: el.cardNumber.length > 4 ? el.cardNumber.slice(12) : el.cardNumber,
            expirationDate: el?.expirationDate.replace('/20', '/')
        }))
        .sort((a, b) => {
            if (a.isExpired === b.isExpired) return 0;
            return a.isExpired > b.isExpired ? 1 : -1;
        });
};

export const exportDateTransformer = date => moment(date).format('YYYY-MM-DDTHH:mm');
