import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const affiliateNetworksApiTagTypes = {
    affiliateNetworks: 'affiliateNetworks'
};

const affiliateNetworksApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(affiliateNetworksApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getAffiliateNetworksList: builder.query({
                query: params => `/affiliate-networks${params}`,
                keepUnusedDataFor: 0,
                transformResponse: response => ({
                    items: response.items.map(el => transform.affiliateNetworks.importList(el)),
                    total: response.total,
                    pagination: response.pagination
                }),
                providesTags: () => [
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'LIST' },
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getAffiliateNetworkById: builder.query({
                query: id => `/affiliate-networks/${id}`,
                transformResponse: response => transform.affiliateNetworks.import(response),
                providesTags: (result, error, id) => [
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id },
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL_ITEMS' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getAffiliateNetworksAllNames: builder.query({
                query: () => `/affiliate-networks/all/names`,
                transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
                providesTags: () => [
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL_NAMES_LIST' },
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getAffiliateNetworksWithColumns: builder.query({
                query: params => `/affiliate-networks/all/with-columns${params}`,
                providesTags: () => [
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'WITH_COLUMNS_LIST' },
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getAffiliateNetworksChart: builder.query({
                query: params => `/affiliate-networks/all/chart${params}`,
                providesTags: () => [
                    { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'CHART' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            deleteAffiliateNetwork: builder.mutation({
                query: ({ id }) => ({
                    url: `/affiliate-networks/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id },
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL' },
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'CHART' }
                        ];
                    else return [];
                }
            }),

            restoreAffiliateNetwork: builder.mutation({
                query: ({ id }) => ({
                    url: `/affiliate-networks/${id}/restore`,
                    method: 'PATCH'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id },
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'ALL' },
                            { type: affiliateNetworksApiTagTypes.affiliateNetworks, id: 'CHART' }
                        ];
                    else return [];
                }
            })
        })
    });

export const {
    useGetAffiliateNetworksListQuery,
    useGetAffiliateNetworkByIdQuery,
    useGetAffiliateNetworksAllNamesQuery,
    useGetAffiliateNetworksWithColumnsQuery,
    useGetAffiliateNetworksChartQuery,
    useDeleteAffiliateNetworkMutation,
    useRestoreAffiliateNetworkMutation
} = affiliateNetworksApi;
