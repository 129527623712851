import React from 'react';
import cn from 'classnames';

import './badge-round.scss';

const BadgeRound = ({ text, type, size, children, className }) => (
    <div
        className={cn('badge-round', { [`badge-round--${type}`]: type }, { [`badge-round--${size}`]: size }, className)}
    >
        {text || children}
    </div>
);

export default BadgeRound;
