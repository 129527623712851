import indexApi from './indexApi';
import { transform } from '../transformers';

const notificationsApiTagTypes = {
    notifications: 'notifications'
};

const notificationsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(notificationsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getNotifications: builder.query({
                query: () => `/notifications`,
                transformResponse: response => response.map(el => transform.notifications.import(el)),
                providesTags: () => [notificationsApiTagTypes.notifications]
            }),

            addNotification: builder.mutation({
                query: ({ data }) => ({
                    url: `/notifications`,
                    method: 'POST',
                    body: transform.notifications.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [notificationsApiTagTypes.notifications];
                    return [];
                }
            }),

            editNotification: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/notifications/${id}`,
                    method: 'PUT',
                    body: transform.notifications.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [notificationsApiTagTypes.notifications];
                    return [];
                }
            }),

            changeNotificationEnable: builder.mutation({
                query: ({ data, id }) => ({
                    url: `/notifications/${id}/enable`,
                    method: 'PUT',
                    body: { isEnabled: data }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [notificationsApiTagTypes.notifications];
                    return [];
                }
            }),

            changeNotificationSystemMessage: builder.mutation({
                query: ({ data, id }) => ({
                    url: `/notifications/${id}/system-message`,
                    method: 'PUT',
                    body: { isSystemMessage: data }
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [notificationsApiTagTypes.notifications];
                    return [];
                }
            }),

            deleteNotification: builder.mutation({
                query: ({ id }) => ({
                    url: `/notifications/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [notificationsApiTagTypes.notifications];
                    return [];
                }
            })
        })
    });

export const {
    useGetNotificationsQuery,
    useAddNotificationMutation,
    useEditNotificationMutation,
    useChangeNotificationEnableMutation,
    useChangeNotificationSystemMessageMutation,
    useDeleteNotificationMutation
} = notificationsApi;
