import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { USER_ROLES } from '../../../Constants';
import { showModal, hideModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useChangeIpFilteringStatusMutation, useGetIpFilteringStatusQuery } from '../../../redux/api/projectApi';
import { useDeleteIpFilterMutation, useGetIpFiltersQuery } from '../../../redux/api/ipFilteringApi';

import Section from '../../../components/section/section';
import SectionHeader from '../../../components/section-header/section-header';
import JustTable from '../../../components/just-table/just-table';
import ConfirmDeleteModal from '../../Modals/confirm-delete-modal/confirm-delete-modal';
import AddIpUaFilterModal from '../../Modals/add-ip-ua-filter-modal/add-ip-ua-filter-modal';
import Loader from '../../Loader';

import ipuaFiltering from './table-constructor';

import './ip-ua-filtering.scss';

const IPUAFiltering = () => {
    const txt = Langs[global.lng];

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: ipFilters, isSuccess: isIpFiltersLoaded, isFetching: isIpFiltersFetching } = useGetIpFiltersQuery();

    const {
        data: ipFilteringStatus,
        isSuccess: isIpFilteringStatusLoaded,
        isFetching: isIpFilteringStatusFetching
    } = useGetIpFilteringStatusQuery();

    const colsWithRoles =
        userSettings?.role !== USER_ROLES.OWNER ? ipuaFiltering.filter(el => el.key !== 'actions') : ipuaFiltering;

    const rows = ipFilters?.map(row => ({
        ...row,
        actions: [{ type: 'editRow' }, { type: 'deleteRow' }]
    }));

    const [
        changeIpFilteringStatus,
        {
            isLoading: isChangeIpFilteringStatusLoading,
            isError: isChangeIpFilteringStatusError,
            error: changeIpFilteringStatusError,
            isSuccess: isChangeIpFilteringStatusSuccess
        }
    ] = useChangeIpFilteringStatusMutation();

    useEffect(() => {
        if (isChangeIpFilteringStatusSuccess)
            toast.success(txt.toasts.ipFilteringToggled(!ipFilteringStatus.ipFiltering));
    }, [isChangeIpFilteringStatusSuccess]);

    useEffect(() => {
        if (isChangeIpFilteringStatusError)
            toast.error(getResponseErrorMessage(changeIpFilteringStatusError.data, txt.errors.changeError));
    }, [isChangeIpFilteringStatusError]);

    const [
        deleteFilter,
        {
            isLoading: isDeleteFilterLoading,
            isError: isDeleteFilterError,
            error: deleteFilterError,
            isSuccess: isDeleteFilterSuccess
        }
    ] = useDeleteIpFilterMutation();

    useEffect(() => {
        if (isDeleteFilterSuccess) {
            toast.success(txt.toasts.deleted);
            hideModal();
        }
    }, [isDeleteFilterSuccess]);

    useEffect(() => {
        if (isDeleteFilterError) toast.error(getResponseErrorMessage(deleteFilterError.data, txt.errors.deleteError));
    }, [isDeleteFilterError]);

    const showAddFilterModal = filter => {
        const warningModalConfig = {
            title: txt.titles.closeWithoutSave(txt.titles.edit),
            message: txt.texts.warningModal(txt.titles.edit),
            confirmButtonText: 'yesCloseForm',
            confirmButtonType: 'danger'
        };

        showModal({
            title: filter ? txt.titles.editFilteringRule : txt.titles.addFilteringRule,
            warningModalConfig,
            body: props => <AddIpUaFilterModal {...props} item={filter} />
        });
    };

    const onActionClick = async (type, item) => {
        switch (type) {
            case 'editRow':
                showAddFilterModal(item);

                break;
            case 'deleteRow':
                showModal({
                    title: 'Delete filter?',
                    body: props => (
                        <ConfirmDeleteModal
                            {...props}
                            title={txt.texts.getWillBeArchivedSubtitle(item.name, true)}
                            entity={'filter'}
                            onConfirm={() => deleteFilter({ id: item.id })}
                        />
                    )
                });

                break;
            default:
                break;
        }
    };

    const isLoaded = isIpFiltersLoaded && isIpFilteringStatusLoaded && isUserSettingsLoaded;
    const isProcessing =
        isChangeIpFilteringStatusLoading || isDeleteFilterLoading || isIpFilteringStatusFetching || isIpFiltersFetching;

    if (!isLoaded) return <Loader />;

    const sectionHeader = (
        <SectionHeader
            title={userSettings.role !== USER_ROLES.OWNER && txt.labels.ipuaFiltering}
            button={{
                label: txt.buttons.add,
                onClick: () => showAddFilterModal(),
                hidden: userSettings.role !== USER_ROLES.OWNER
            }}
            switcher={{
                label: txt.labels.ipuaFiltering,
                isOn: ipFilteringStatus.ipFiltering,
                onClick: value => changeIpFilteringStatus(value),
                hidden: userSettings.role !== USER_ROLES.OWNER
            }}
        />
    );

    return (
        <Section Header={sectionHeader} className="ip-ua-filtering">
            <div className="text-default-light">{txt.texts.ipuaFiltering}</div>

            {ipFilters.length ? (
                <JustTable
                    cols={colsWithRoles}
                    rows={rows}
                    onActionClick={onActionClick}
                    isHeaderDecorate
                    className="mt16"
                />
            ) : null}

            {isProcessing && <Loader isFetching />}
        </Section>
    );
};

export default IPUAFiltering;
