import { transform } from '../transformers';
import indexApi from './indexApi';

export const workspacesApiTagTypes = {
    workspaces: 'workspaces',
    workspacesStore: 'workspacesStore'
};

const workspacesApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(workspacesApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getWorkspacesList: builder.query({
            query: params => `/workspaces${params || ''}`,
            transformResponse: response => ({
                items: response.items.map(el => transform.workspaces.import(el)),
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: workspacesApiTagTypes.workspaces, id: 'LIST' },
                { type: workspacesApiTagTypes.workspaces, id: 'ALL' }
            ]
        }),

        getWorkspacesAllNames: builder.query({
            query: () => `/workspaces/all/names`,
            transformResponse: response =>
                response.map(el => ({ value: el.id, label: el.name, workspaceType: el.workspaceType })),
            providesTags: () => [
                { type: workspacesApiTagTypes.workspaces, id: 'ALL_NAMES_LIST' },
                { type: workspacesApiTagTypes.workspaces, id: 'ALL' }
            ]
        }),

        addWorkspace: builder.mutation({
            query: ({ data }) => ({
                url: `/workspaces`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [{ type: workspacesApiTagTypes.workspaces, id: 'ALL' }];
                else return [];
            }
        }),

        editWorkspace: builder.mutation({
            query: ({ data, id }) => ({
                url: `/workspaces/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [{ type: workspacesApiTagTypes.workspaces, id: 'ALL' }];
                else return [];
            }
        }),

        deleteWorkspace: builder.mutation({
            query: ({ id }) => ({
                url: `/workspaces/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [{ type: workspacesApiTagTypes.workspaces, id: 'ALL' }];
                else return [];
            }
        }),

        //

        getWorkspacesStoreList: builder.query({
            query: () => `/workspace-store`,
            providesTags: () => [workspacesApiTagTypes.workspacesStore]
        }),

        updateWorkspacesStoreList: builder.mutation({
            query: ({ data }) => ({
                url: `/workspace-store`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error)
                    return [
                        workspacesApiTagTypes.workspacesStore,
                        { type: 'campaigns', id: 'ALL' },
                        { type: 'flows', id: 'ALL' },
                        { type: 'offers', id: 'ALL' },
                        { type: 'landers', id: 'ALL' },
                        { type: 'trafficSources', id: 'ALL' },
                        { type: 'affiliateNetworks', id: 'ALL' }
                    ];
                else return [];
            }
        })
    })
});

export const {
    useGetWorkspacesListQuery,
    useGetWorkspacesAllNamesQuery,
    useAddWorkspaceMutation,
    useEditWorkspaceMutation,
    useDeleteWorkspaceMutation,
    //
    useGetWorkspacesStoreListQuery,
    useUpdateWorkspacesStoreListMutation
} = workspacesApi;
