import React from 'react';
import cn from 'classnames';
import FormActions from '../../../Forms/FormActions/FormActions';
import Langs from '../../../../../Langs';

import './days-of-week-picker.scss';

const DAYS_OF_WEEK = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];

export const getDaysOfWeekData = ({ value }) => value.map(day => Langs[global.lng].weekDays[day]).join(', ');
export const getDaysOfWeekCount = value => value.length;

const DaysOfWeekPicker = ({ label, onChange, isEnabled, isMatched, value }) => {
    const txt = Langs[global.lng];
    const handleClick = day =>
        onChange(value.includes(day) ? value.filter(item => item !== day) : [...value, day], 'value');

    return (
        <FormActions
            label={label}
            isEnabled={isEnabled}
            onChange={onChange}
            isMatched={isMatched}
            className="days-of-week-picker"
        >
            <div className={`days-of-week-picker__items mt10`}>
                {DAYS_OF_WEEK.map(day => (
                    <div
                        data-test={`days-of-week-picker_${day}-div`}
                        key={day + 'key'}
                        className={cn(
                            'days-of-week-picker__day',
                            value.includes(day) && `days-of-week-picker__day--${isMatched ? 'green' : 'red'}`
                        )}
                        onClick={() => handleClick(day)}
                    >
                        {txt.weekDays[day]}
                    </div>
                ))}
            </div>
        </FormActions>
    );
};

export default DaysOfWeekPicker;
