import React from 'react';
import moment from 'moment-timezone';

/*
    33s
    5m 45s
    1h 33m
    5d 16h
    1mth 15d
*/

const durationAsString = (start, end) => {
    if (new Date(end).getTime() - new Date(start).getTime() < 0) return '';

    const duration = moment.duration(moment(end).diff(moment(start)));

    const months = Math.floor(duration.asMonths());
    const monthsFormatted = months ? `${months}mth ` : '';
    duration.subtract(moment.duration(months, 'months'));

    const days = Math.floor(duration.asDays());
    const daysFormatted = days ? `${days}d ` : '';

    const hours = duration.hours();
    const hoursFormatted = hours ? `${hours}h ` : '';

    const minutes = duration.minutes();
    const minutesFormatted = minutes ? `${minutes}m ` : '';

    const seconds = duration.seconds();
    const secondsFormatted = `${seconds}s`;

    const timeFormatList = [monthsFormatted, daysFormatted, hoursFormatted, minutesFormatted, secondsFormatted];

    if (months) timeFormatList.splice(2);
    if (!months && days) timeFormatList.splice(3);
    if (!months && !days && hours) timeFormatList.splice(4);
    if (!months && !days && !hours && minutes) timeFormatList.splice(5);
    else timeFormatList.splice(6);

    return timeFormatList.join('').trimEnd();
};

export const TdTimeFormat = ({ dateStart, dateEnd }) => {
    return <span className="items-table__td-text">{durationAsString(dateStart, dateEnd)}</span>;
};

export default TdTimeFormat;
