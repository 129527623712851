import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const trafficSourcesApiTagTypes = {
    trafficSources: 'trafficSources',
    trafficSourcesUrlParams: 'trafficSourcesUrlParams'
};

export const trafficSourcesApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(trafficSourcesApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getTrafficSourcesList: builder.query({
                query: params => `/traffic-sources${params}`,
                keepUnusedDataFor: 0,
                transformResponse: response => ({
                    items: response.items.map(el => transform.trafficSources.importList(el)),
                    total: response.total,
                    pagination: response.pagination
                }),
                providesTags: () => [
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'LIST' },
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getTrafficSourceById: builder.query({
                query: id => `/traffic-sources/${id}`,
                transformResponse: response => transform.trafficSources.import(response),
                providesTags: (result, error, id) => [
                    { type: trafficSourcesApiTagTypes.trafficSources, id },
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL_ITEMS' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getTrafficSourcesWithColumns: builder.query({
                query: params => `/traffic-sources/all/with-columns${params}`,
                providesTags: () => [
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'WITH_COLUMNS_LIST' },
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getTrafficSourcesAllNames: builder.query({
                query: () => `/traffic-sources/all/names`,
                transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
                providesTags: () => [
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL_NAMES_LIST' },
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getTrafficSourcesChart: builder.query({
                query: params => `/traffic-sources/all/chart${params}`,
                providesTags: () => [
                    { type: trafficSourcesApiTagTypes.trafficSources, id: 'CHART' },
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            getTrafficSourcesTokens: builder.query({
                query: () => `/traffic-sources/dictionaries/url-params`,
                keepUnusedDataFor: 86400, //24h
                providesTags: () => [
                    trafficSourcesApiTagTypes.trafficSourcesUrlParams,
                    { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
                ]
            }),

            deleteTrafficSource: builder.mutation({
                query: ({ id }) => ({
                    url: `/traffic-sources/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: trafficSourcesApiTagTypes.trafficSources, id },
                            { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL' },
                            { type: trafficSourcesApiTagTypes.trafficSources, id: 'CHART' }
                        ];
                    else return [];
                }
            }),

            restoreTrafficSource: builder.mutation({
                query: ({ id }) => ({
                    url: `/traffic-sources/${id}/restore`,
                    method: 'PATCH'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: trafficSourcesApiTagTypes.trafficSources, id },
                            { type: trafficSourcesApiTagTypes.trafficSources, id: 'ALL' },
                            { type: trafficSourcesApiTagTypes.trafficSources, id: 'CHART' }
                        ];
                    else return [];
                }
            })
        })
    });

export const {
    useGetTrafficSourcesListQuery,
    useGetTrafficSourceByIdQuery,
    useGetTrafficSourcesWithColumnsQuery,
    useGetTrafficSourcesAllNamesQuery,
    useGetTrafficSourcesChartQuery,
    useGetTrafficSourcesTokensQuery,
    useDeleteTrafficSourceMutation,
    useRestoreTrafficSourceMutation
} = trafficSourcesApi;
