import indexApi from './indexApi';

const invoiceApiTagTypes = {
    invoices: 'invoices'
};

const invoiceApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(invoiceApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getInvoices: builder.query({
            query: params => `/invoices${params || ''}`,
            providesTags: () => [{ type: invoiceApiTagTypes.invoices, id: 'LIST' }]
        })
    })
});

export const { useGetInvoicesQuery } = invoiceApi;
