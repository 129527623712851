import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { DOMAIN_NAME_RULE } from '../../../Constants';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { hideModal } from '../../../redux/operations';
import useFocus from '../../../Functions/hooks/useFocus';
import {
    useAddDomainMutation,
    useEditDomainMutation,
    useCheckDomainCnameQuery,
    useGetSystemDomainsByTypeQuery
} from '../../../redux/api/domainsApi';
import { useGetCampaignsAllNamesQuery } from '../../../redux/api/campaignsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ClipboardField from '../../Overall/Forms/ClipboardField';
import Alert from '../../Overall/UI/Alert/Alert';
import Btn from '../../Overall/UI/Btn/Btn';
// import TagInput from '../../Overall/Forms/Inputs/TagInput';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Loader from '../../Loader';

import './add-domain-modal.scss';

const destinationRadios = [
    {
        value: 'type',
        label: 'Show 404 on the root page',
        type: '404_root'
    },
    {
        value: 'targetUrl',
        label: 'Redirect root to a URL',
        type: 'redirect_root'
    },
    {
        value: 'campaignId',
        label: 'Campaign',
        type: 'campaign'
    }
];

const AddDomainModal = ({ type, item, onClose }) => {
    const txt = Langs[global.lng];

    const [userDomainInputRef, setUserDomainFocus] = useFocus();

    const [isFetching, setIsFetching] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const [userDomain, setUserDomain] = useState(item ? item?.domain?.name : '');
    const [isStartCheckStatus, setIsStartCheckStatus] = useState(false);
    // const [selectedWorkspaces, setSelectedWorkspaces] = useState(item ? item?.workspaces : []);
    const [targetUrl, setTargetUrl] = useState(item?.destination?.targetUrl);
    const [campaignId, setCampaignId] = useState(item?.destination?.campaignId);
    const [dnsStatus, setDnsStatus] = useState(null);
    const [destinationValue, setDestinationValue] = useState(
        item ? destinationRadios?.find(el => el.type === item?.destination?.type).value : destinationRadios[0].value
    );

    const { data: defaultSystemDomain, isSuccess: isDefaultDomainsLoaded } = useGetSystemDomainsByTypeQuery(`${type}`, {
        skip: item
    });

    const { data: campaigns, isSuccess: isCampaignsLoaded } = useGetCampaignsAllNamesQuery();

    const defaultSystemDomainName = defaultSystemDomain?.domain?.name + '.';
    const cnameLayout = `${userDomain || 'your.domain.name'}. CNAME ${defaultSystemDomainName}`;

    const errors = {
        userDomain: !item && !DOMAIN_NAME_RULE.test(userDomain) ? txt.errors.incorrectValue : '',
        targetUrl: destinationValue === 'targetUrl' && !targetUrl ? txt.errors.required() : '',
        campaignId: destinationValue === 'campaignId' && !campaignId ? txt.errors.required() : ''
    };

    const isAllValid = useMemo(
        () => Object.keys(errors).every(el => !errors[el]),
        [destinationValue, userDomain, targetUrl, campaignId]
    );

    const [addDomain, { isSuccess: isAddDomainSucces, isError: isAddDomainError, error: addDomainError }] =
        useAddDomainMutation();

    const [editDomain, { isSuccess: isEditDomainSucces, isError: isEditDomainError, error: editDomainError }] =
        useEditDomainMutation();

    useEffect(() => {
        if (isAddDomainSucces || isEditDomainSucces) {
            toast.success(item ? txt.toasts.saved : txt.toasts.created);
            hideModal();
        }
    }, [isAddDomainSucces, isEditDomainSucces]);

    useEffect(() => {
        if (isAddDomainError) {
            toast.error(getResponseErrorMessage(addDomainError.data, txt.errors.saveError));
            setIsFetching(false);
        }
        if (isEditDomainError) {
            toast.error(getResponseErrorMessage(editDomainError.data, txt.errors.saveError));
            setIsFetching(false);
        }
    }, [isAddDomainError, isEditDomainError]);

    const onAddDomainClick = () => {
        setIsTouched(true);

        if (isAllValid) {
            setIsFetching(true);

            const data = {
                ...(!item ? { type } : {}),
                ...(!item ? { domain: userDomain } : {}),
                destination: {
                    type: destinationRadios?.find(el => el.value === destinationValue).type,
                    targetUrl: destinationValue === 'targetUrl' ? targetUrl : '',
                    campaignId
                }
                //   workspaces: !domain.workspaces.length ? [PUBLIC_WORKSPACE] : domain.workspaces
            };

            if (item) editDomain({ id: item.id, type, data });
            else addDomain({ type, data });
        }
    };

    const {
        data: domainCnameStatus,
        isSuccess: isDomainCnameSucces,
        isError: isDomainCnameError,
        error: domainCnameError
    } = useCheckDomainCnameQuery(
        {
            type,
            domain: userDomain
        },
        { skip: !isStartCheckStatus }
    );

    useEffect(() => {
        if (isDomainCnameSucces || isDomainCnameError) {
            setIsFetching(false);
            setIsStartCheckStatus(false);
        }

        if (isDomainCnameSucces) setDnsStatus(domainCnameStatus.cname);
        if (isDomainCnameError) toast.error(getResponseErrorMessage(domainCnameError.data, txt.toasts.commonError));
    }, [domainCnameStatus, isDomainCnameSucces, isDomainCnameError]);

    const checkDomainCname = () => {
        if (!errors.userDomain) {
            setIsFetching(true);
            setIsStartCheckStatus(true);
        } else {
            setIsTouched(true);
        }
    };

    const isLoaded = item ? isCampaignsLoaded /*&& isWorkspacesLoaded*/ : isCampaignsLoaded && isDefaultDomainsLoaded; // && isWorkspacesLoaded

    useEffect(() => {
        if (isLoaded && !userDomain) setUserDomainFocus();
    }, [isLoaded]);

    return (
        <>
            <div className={cn('add-domain-modal', { 'add-domain-modal--edit': item })}>
                {isLoaded ? (
                    <>
                        <div>
                            {!item && (
                                <>
                                    <div className="mb20">
                                        <div className="add-domain-modal__title">
                                            <span>1. {txt.texts.addCustomDomainItem2}</span>
                                        </div>

                                        <ClipboardField
                                            dataTest="create-cname-record"
                                            value={defaultSystemDomainName}
                                        />
                                    </div>

                                    <div className="mb20">
                                        <div className="add-domain-modal__title">
                                            <span>2. {txt.texts.addCustomDomainItem3}</span>
                                        </div>

                                        <ClipboardField dataTest="cname-record-look-like" value={cnameLayout} />

                                        <div className="mt8">
                                            <Alert message={txt.alerts.addCustomDomainInfo} type="warning" />
                                        </div>
                                    </div>

                                    <div className="mb20">
                                        <div className="add-domain-modal__title">
                                            <span>3. {txt.texts.addCustomDomainItem4}</span>
                                        </div>

                                        <div className="add-domain-modal__check-dns-row">
                                            <TextInput
                                                dataTest="add-domain-modal_domain-name-input"
                                                ref={userDomainInputRef}
                                                value={userDomain}
                                                error={isTouched && errors.userDomain}
                                                onChange={e => {
                                                    setUserDomain(e.target.value);
                                                    if (dnsStatus !== null) setDnsStatus(null);
                                                }}
                                                hint={txt.hints.yourDomain}
                                            />

                                            <Btn
                                                dataTest="add-domain-modal_check-dns-btn"
                                                type="filled"
                                                onClick={checkDomainCname}
                                            >
                                                {txt.buttons.checkDns}
                                            </Btn>
                                        </div>

                                        {dnsStatus === null ? null : (
                                            <Alert
                                                message={
                                                    <div>
                                                        <span className="text-medium">{userDomain}</span> &#8594;{' '}
                                                        {txt.alerts.getCheckDNSText(dnsStatus)}
                                                    </div>
                                                }
                                                type={dnsStatus ? 'success' : 'danger'}
                                                className="mt10"
                                            />
                                        )}
                                    </div>
                                </>
                            )}

                            <div className={cn({ mb15: !item })}>
                                {!item && (
                                    <div className="add-domain-modal__title">
                                        <span>4. {txt.texts.addCustomDomainItem5}</span>
                                    </div>
                                )}

                                {/* <div className={cn({ pl5: !item })}>
                                    <TagInput
                                        dataTest="add-domain-modal"
                                        label={txt.labels.workspace}
                                        hint={txt.hints.workspace(txt.labels.domain.toLowerCase())}
                                        value={domain.workspaces}
                                        error={errors.includes(validableValues.workspaces)}
                                        items={workspaces}
                                        onChange={e => setSelectedWorkspaces(e)}
                                    />
                                </div> */}
                            </div>

                            {type !== 'redirect' && (
                                <>
                                    <div className="mb8 add-domain-modal__radio-group">
                                        <RadioInput
                                            dataTest="add-domain-modal_custom-domain-destination"
                                            label={txt.labels.destination}
                                            hint={txt.hints.customDomainDestination}
                                            items={destinationRadios}
                                            value={destinationValue}
                                            onChange={({ target }) => setDestinationValue(target.value)}
                                        />
                                    </div>

                                    {destinationValue === 'targetUrl' && (
                                        <TextInput
                                            dataTest="add-domain-modal_target-url-input"
                                            value={targetUrl}
                                            error={isTouched && errors.targetUrl}
                                            onChange={({ target }) => setTargetUrl(target.value)}
                                            placeholder={txt.placeholders.enterTargetUrl}
                                        />
                                    )}

                                    {destinationValue === 'campaignId' && (
                                        <div className="add-domain-modal__select">
                                            <SelectInput
                                                dataTest="add-domain-modal_company-select-input"
                                                value={campaignId}
                                                items={campaigns}
                                                placeholder={txt.titles.selectCampaign}
                                                error={isTouched && errors.campaignId}
                                                onChange={({ target: { value } }) => setCampaignId(value)}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <Loader />
                )}

                {isFetching && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={item ? txt.buttons.save : txt.buttons.addDomain}
                onReject={onClose}
                onConfirm={onAddDomainClick}
            />
        </>
    );
};

export default AddDomainModal;
