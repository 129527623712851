import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import produce from 'immer';
import { toast } from 'react-toastify';
import { Link2 } from 'react-feather';

import Langs from '../../../Langs';
import { CHAPTER_REPORTS } from '../../../Constants/index';
import { exportDateTransformer } from '../../../redux/transformers';
import {
    getDateRangeFromQueryString,
    getResponseErrorMessage,
    sharedReportEntityTransformer,
    getSharedReportLink,
    transposeItems
} from '../../../Functions/utils';
import { getActualDateRange, hideModal, showModal } from '../../../redux/operations';
import { getColumns } from '../../../components/main-table/services';

import {
    useAddSharedReportMutation,
    useEditSharedReportMutation,
    useGetSharedReportByIdQuery
} from '../../../redux/api/sharedReportsApi';
import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetProjectInfoQuery } from '../../../redux/api/projectApi';

import useCopyToggle from '../../../Functions/hooks/useCopyToggle';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import DateRangeInput from '../../Overall/Forms/Inputs/DateRangeInput';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import Label from '../../Overall/Forms/Inputs/Label';
import SharedReportLink from '../shared-report-link-modal/shared-report-link-modal';
import Btn from '../../Overall/UI/Btn/Btn';
import ColumnsListV from '../../Settings/ColumnsSettings/ColumnsListV';

import './create-shared-report-modal.scss';

const HIDDEN_COLUMNS = ['checked', 'rotationName', 'id', 'integrationAction', 'domainName', 'trafficSourceName'];

const CreateSharedReportModal = ({
    onClose,
    chapter,
    systemDateRange,
    initialName,
    storage,
    id,
    reportGroups,
    reportFilters
}) => {
    const txt = Langs[global.lng];

    const { data: userSettings } = useGetUserSettingsQuery();
    const { data: projectInfo } = useGetProjectInfoQuery();

    const isEditMode = !!id;
    const isChapterReport = CHAPTER_REPORTS.includes(chapter);
    const displayMode = storage[chapter]?.displayMode;

    const columnsList = getColumns(chapter, storage, {
        includeIntegratedCampaignList: true,
        isHideProfit: userSettings.isHideProfit,
        includeAdditionalRevenue: projectInfo.isAdditionalRevenue
    }).orderedColumns.filter(el => {
        if (HIDDEN_COLUMNS.includes(el.key)) return false;
        return el;
    });

    const [columns, setColumns] = useState(isEditMode ? [] : columnsList);

    const [name, setName] = useState(initialName);

    const [isConfirmPressed, setIsConfirmPressed] = useState(false);

    const [isShowDate, setIsShowDate] = useState(true);
    const [isAllowCompare, setIsAllowCompare] = useState(false);
    const [isAllowEdit, setIsAllowEdit] = useState(false);

    const [reportPeriod, setReportPeriod] = useState(
        getActualDateRange({ ...systemDateRange }, { isAllowFuture: true })
    );
    const [reportAvailablePeriod, setReportAvailablePeriod] = useState({
        compare: false,
        start: moment().startOf('hour').format(),
        end: moment().add(1, 'day').startOf('day').format(),
        intervalKey: 'customDate'
    });

    const { data: report, isSuccess: isReportLoaded } = useGetSharedReportByIdQuery(id, { skip: !id });

    useEffect(() => {
        if (isReportLoaded) {
            setName(report.name);
            setIsShowDate(report.isShowPeriod);
            setIsAllowCompare(report.isCompareWithPreviousPeriod ?? false);
            setIsAllowEdit(report.isAllowEditPeriod ?? false);
            setReportAvailablePeriod({
                compare: false,
                start: report.availableDateFrom,
                end: report.availableDateTo,
                intervalKey: 'customDate'
            });

            const newReportPeriod = getDateRangeFromQueryString(report.query);

            setReportPeriod({ compare: true, ...newReportPeriod });

            const allColumns = getColumns(sharedReportEntityTransformer(report.entityType), storage, {
                includeIntegratedCampaignList: true,
                isHideProfit: userSettings.isHideProfit,
                includeAdditionalRevenue: projectInfo.isAdditionalRevenue
            }).orderedColumns.filter(el => {
                if (HIDDEN_COLUMNS.includes(el.key)) return false;
                return el;
            });

            const newColumns = allColumns.map(el => ({
                ...el,
                isHide: !report.columns.includes(el.key)
            }));

            setColumns(newColumns);
        }
    }, [report]);

    const onSelectAllClick = newStatus =>
        setColumns(columns.map(el => ({ ...el, isHide: el.disabled ? el.isHide : !newStatus })));

    const onColumnClick = (key, newStatus) =>
        setColumns(
            produce(draft => {
                const item = draft.find(el => el.key === key);
                if (item) item.isHide = newStatus;
            })
        );

    const [
        addSharedReport,
        {
            data: addedSharedReport,
            isSuccess: isAddSharedReportSuccess,
            isError: isAddSharedReportError,
            error: addSharedReportError
        }
    ] = useAddSharedReportMutation();

    useEffect(() => {
        if (isAddSharedReportSuccess) {
            hideModal();

            showModal({
                title: txt.titles.sharedReportLink,
                body: props => <SharedReportLink {...props} reportName={name} reportId={addedSharedReport.id} />
            });
        }
    }, [isAddSharedReportSuccess]);

    const [
        editSharedReport,
        { isSuccess: isEditSharedReportSuccess, isError: isEditSharedReportError, error: editSharedReportError }
    ] = useEditSharedReportMutation();

    useEffect(() => {
        if (isEditSharedReportSuccess) {
            hideModal();
            toast.success(txt.toasts.saved);
        }
    }, [isEditSharedReportSuccess]);

    useEffect(() => {
        if (isAddSharedReportError)
            toast.error(getResponseErrorMessage(addSharedReportError.data, txt.toasts.saveError));

        if (isEditSharedReportError)
            toast.error(getResponseErrorMessage(editSharedReportError.data, txt.toasts.saveError));

        if (isAddSharedReportError || isEditSharedReportError) setIsConfirmPressed(false);
    }, [isAddSharedReportError, isEditSharedReportError]);

    const onConfirm = () => {
        const calculatedPeriod = getActualDateRange({ ...reportPeriod, compare: true }, { isAllowFuture: true });

        const startFilter = `&filter[dateFrom]=${exportDateTransformer(calculatedPeriod.start)}`;
        const endFilter = `&filter[dateTo]=${exportDateTransformer(calculatedPeriod.end)}`;

        const startFilterCompare = `&filter[compareDateFrom]=${exportDateTransformer(calculatedPeriod.startPrevDate)}`;
        const endFilterCompare = `&filter[compareDateTo]=${exportDateTransformer(calculatedPeriod.endPrevDate)}`;

        const order = `&order-by[${chapter === 'campaigns' ? 'displayName' : 'name'}]=ASC`;
        const level = isChapterReport ? `&filter[level1]=${chapter}` : ``;
        const reportLevel = reportGroups ? reportGroups.map((el, i) => `&filter[level${i + 1}]=${el}`).join('') : '';

        const compareFilter = isAllowCompare ? `${startFilterCompare}${endFilterCompare}` : '';
        const displayFilter = displayMode ? `&filter[status]=${displayMode}` : '';

        const reportFiltersQuery = reportFilters
            ? '&' +
              [
                  reportFilters.map(el => `filter[dtype][]=${el.type}`),
                  reportFilters.map((el, idx) => `filter[did][]=${idx > 0 ? el.value : el?.value?.join(',')}`)
              ].join('&')
            : '';

        const selectedColumns = columns.filter(el => !el.isHide).map(el => el.key);
        const customColumns = storage.common.customConversions.filter(el => selectedColumns.includes(el.key));

        const data = {
            name,
            availableDateFrom: exportDateTransformer(reportAvailablePeriod.start),
            availableDateTo: exportDateTransformer(reportAvailablePeriod.end),
            isShowPeriod: isShowDate,
            isAllowEditPeriod: isAllowEdit,
            isCompareWithPreviousPeriod: isAllowCompare,
            columns: selectedColumns,
            customColumns,
            ...(isEditMode ? { dateFrom: calculatedPeriod.start } : {}),
            ...(isEditMode ? { dateTo: calculatedPeriod.end } : {}),
            ...(isEditMode ? { compareDateFrom: calculatedPeriod.startPrevDate } : {}),
            ...(isEditMode ? { compareDateTo: calculatedPeriod.endPrevDate } : {}),
            ...(!isEditMode ? { entityType: isChapterReport ? 'reports' : chapter } : {}),
            ...(!isEditMode
                ? {
                      query: `page=1&per-page=50${order}${startFilter}${endFilter}${reportFiltersQuery}${reportLevel}${level}${compareFilter}${displayFilter}`
                  }
                : {})
        };

        const request = isEditMode ? editSharedReport : addSharedReport;

        request({ data, id });
    };

    const [isCopied, setIsCopied] = useCopyToggle();

    const onReportCopyClick = () => {
        navigator.clipboard.writeText(getSharedReportLink(id)).then(() => {
            setIsCopied(true);
        });
    };

    const CopyReportLinkBtn = (
        <Btn onClick={onReportCopyClick}>
            <Link2 size={16} />
            {isCopied ? txt.buttons.linkCopied : txt.buttons.copyReportLink}
        </Btn>
    );

    const range = useMemo(() => {
        const { start, end } = reportPeriod;
        const startTime = moment(start).format('HH:mm');
        const endTime = moment(end).format('HH:mm');
        const firstPart = `${moment(start).format('ll')} ${startTime !== '00:00' ? `(${startTime})` : ''}`;
        const secondPart = `${(endTime !== '00:00' ? moment(end) : moment(end).subtract(1, 'days')).format('ll')}${
            endTime !== '00:00' ? ` (${endTime})` : ''
        }`;

        return `Min ${firstPart} - Max ${secondPart}`;
    }, [reportPeriod]);

    return (
        <>
            <div className="create-shared-report-modal">
                {isEditMode && !isReportLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <div className="create-shared-report-modal__name">
                            <TextInput
                                dataTest="traffic-source_name-input"
                                label={txt.labels.nameOfReport}
                                value={name}
                                onChange={({ target: { value } }) => setName(value)}
                            />

                            <div className="create-shared-report-modal__original-name">
                                <span>Original report name (path): </span> {initialName}
                            </div>
                        </div>

                        <div className="create-shared-report-modal__date">
                            <div className="create-shared-report-modal__date-item">
                                <Label label="Period of Shared Report" hint="Period of Shared Report" />

                                <DateRangeInput
                                    value={reportPeriod}
                                    onChange={data => setReportPeriod(data)}
                                    hideArrows
                                    isCompareHidden
                                    isAllowFuture
                                />
                            </div>

                            <div className="create-shared-report-modal__date-item">
                                <Label label="Available Period" hint="Available Period" />

                                <DateRangeInput
                                    value={reportAvailablePeriod}
                                    onChange={data => {
                                        setReportAvailablePeriod({
                                            ...data,
                                            intervalKey: 'customDate'
                                        });
                                    }}
                                    hideArrows
                                    hideIntervalLabel
                                    isCompareHidden
                                    isIntervalsHidden
                                    isPositionReverse
                                    isFutureSelect
                                    isFullRange
                                />
                            </div>
                        </div>

                        <div className="create-shared-report-modal__date-settings">
                            <CheckBoxInput
                                label="Show Period (Date and Time) in Shared Report"
                                value={isShowDate}
                                onChange={({ target: { checked } }) => {
                                    setIsShowDate(checked);
                                    if (!checked && isAllowCompare) setIsAllowCompare(false);
                                    if (!checked && isAllowEdit) setIsAllowEdit(false);
                                }}
                            />

                            <CheckBoxInput
                                label={`Allow edit period (${range})`}
                                value={isAllowEdit}
                                onChange={({ target: { checked } }) => setIsAllowEdit(checked)}
                                disabled={!isShowDate}
                            />

                            <CheckBoxInput
                                label="Allow compare with previous period"
                                value={isAllowCompare}
                                onChange={({ target: { checked } }) => setIsAllowCompare(checked)}
                                disabled={!isShowDate}
                            />
                        </div>

                        <div className="create-shared-report-modal__columns-header">
                            <div className="create-shared-report-modal__columns-header-title">Show columns</div>

                            <CheckBoxInput
                                label="Select all"
                                value={columns.filter(el => !el.disabled).every(el => !el.isHide)}
                                onChange={({ target: { checked } }) => onSelectAllClick(checked)}
                            />
                        </div>

                        <ColumnsListV
                            onChange={(checked, { key }) => onColumnClick(key, !checked)}
                            mode="small"
                            fields={transposeItems(
                                [...columns].sort((a, b) => a.order - b.order),
                                4
                            )}
                        />

                        {isConfirmPressed && <Loader isFetching />}
                    </>
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={isEditMode ? txt.buttons.save : txt.buttons.createLink}
                onReject={onClose}
                isConfirmDisabled={isConfirmPressed}
                leftSettings={isEditMode ? CopyReportLinkBtn : null}
                onConfirm={() => {
                    setIsConfirmPressed(true);
                    onConfirm();
                }}
            />
        </>
    );
};

export default CreateSharedReportModal;
