import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { hideModal, showModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';
import { changeMainTableActiveRow } from '../../../redux/main-table-slice';

import indexApi from '../../../redux/api/indexApi';
import { useDeleteCampaignsMutation, useMultiDeleteCampaignsMutation } from '../../../redux/api/campaignsApi';

import Loader from '../../Loader';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

import './archive-campaigns-modal.scss';

import ArchiveResultModal from '../ArchiveResultModal';

const ArchiveCampaignsModal = ({
    onClose,
    confirmButtonText,
    title,
    body,
    entity,
    isShowCheckbox,
    isMulti,
    entityName,
    entityId,
    rowsForReport,
    rows
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const [isStopIntegratedTS, setIsStopIntegratedTS] = useState(false);

    const [
        deleteCampaigns,
        {
            isLoading: isDeleteCampaignsLoading,
            isSuccess: isDeleteCampaignsSuccess,
            isError: isDeleteCampaignsError,
            error: deleteCampaignsError
        }
    ] = useDeleteCampaignsMutation();

    useEffect(() => {
        if (isDeleteCampaignsSuccess) {
            hideModal(); // close archive modal
            toast.success(txt.toasts.archived);

            dispatch(
                changeMainTableActiveRow({
                    chapter: 'campaigns',
                    value: null
                })
            );
        }
    }, [isDeleteCampaignsSuccess]);

    useEffect(() => {
        if (isDeleteCampaignsError) {
            hideModal(); // close archive modal
            toast.error(getResponseErrorMessage(deleteCampaignsError.data, txt.errors.archiveError));

            if (deleteCampaignsError.data.errors.options?.isBinded) {
                showModal({
                    title: txt.titles.archivingResult,
                    body: props => (
                        <ArchiveResultModal
                            {...props}
                            subtitle={txt.texts.getCouldNotBeArchivedSubtitle(entityName)}
                            archiveResultData={deleteCampaignsError.data.errors?.options}
                        />
                    )
                });
            }
        }
    }, [isDeleteCampaignsError]);

    const [
        multiDeleteCampaigns,
        {
            isLoading: isMultiDeleteCampaignsLoading,
            isSuccess: isMultiDeleteCampaignsSuccess,
            isError: isMultiDeleteCampaignsError,
            error: multiDeleteCampaignsError
        }
    ] = useMultiDeleteCampaignsMutation();

    useEffect(() => {
        if (isMultiDeleteCampaignsSuccess) {
            hideModal(); // close archive modal
            toast.success(txt.toasts.archived);

            dispatch(
                changeMainTableActiveRow({
                    chapter: 'campaigns',
                    value: null
                })
            );
        }
    }, [isMultiDeleteCampaignsSuccess]);

    useEffect(() => {
        if (isMultiDeleteCampaignsError) {
            hideModal(); // close archive modal
            toast.error(getResponseErrorMessage(multiDeleteCampaignsError.data, txt.errors.archiveError));

            if (multiDeleteCampaignsError.data.errors.options?.isBinded) {
                showModal({
                    title: txt.titles.archivingResult,
                    body: props => (
                        <ArchiveResultModal
                            {...props}
                            subtitle={txt.texts.getCouldNotBeArchivedSubtitle(entityName)}
                            archiveResultData={multiDeleteCampaignsError.data.errors?.options}
                        />
                    )
                });
            }
        }
    }, [isMultiDeleteCampaignsError]);

    const onConfirmClick = () => {
        if (isMulti) multiDeleteCampaigns({ data: rowsForReport });
        else deleteCampaigns({ id: entityId });

        if (isStopIntegratedTS) {
            const items = isMulti ? rowsForReport : entityId;

            const checkAndStop = item => {
                const element = rows.find(it => it.value === item);

                if (
                    element &&
                    element.integration?.isActive &&
                    element.integrationData?.isSuccessful &&
                    !element.integrationData?.isActionDisable
                ) {
                    dispatch(
                        indexApi.endpoints.changeIntegrationCampaignStatus.initiate({
                            status: 'pause',
                            data: {
                                campaignId: element.value,
                                trafficSourceId: element.trafficSourceId,
                                trafficSourceCampaignId: element.trafficSourceCampaignId
                            },
                            rowId: element.value,
                            similarCampaigns: element.integration.similarCampaigns,
                            skipUpdate: true
                        })
                    );
                }
            };

            if (Array.isArray(items)) items.forEach(rowId => checkAndStop(rowId));
            else checkAndStop(items);
        }
    };

    return (
        <>
            <div className="archive-campaigns-modal">
                <div className="archive-campaigns-modal__content">
                    {title && <div className="archive-campaigns-modal__title">{title}</div>}

                    {isMulti
                        ? rowsForReport.map(el => (
                              <div key={el}>
                                  {txt.texts.getWillBeArchivedSubtitle(rows.find(it => it.value === el)?.name)}
                              </div>
                          ))
                        : txt.texts.getWillBeArchivedSubtitle(entityName)}

                    <div className="archive-campaigns-modal__content">
                        <div className="mt8">{txt.texts.archiveElement(isMulti)}</div>
                    </div>

                    {isShowCheckbox && (
                        <>
                            <div className="mt10">
                                Attention, you want to archive a campaign, but it has an Active status in the traffic
                                source. To stop or pause a campaign in the traffic source, select the check.
                            </div>

                            <CheckBoxInput
                                className="mt10"
                                label="Stop or pause a campaign in the traffic source"
                                value={isStopIntegratedTS}
                                onChange={({ target: { checked } }) => setIsStopIntegratedTS(checked)}
                            />
                        </>
                    )}
                </div>

                {(isDeleteCampaignsLoading || isMultiDeleteCampaignsLoading) && (
                    <Loader isFetching type={!body ? 'cell' : ''} />
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={confirmButtonText || `Yes, archive ${entity ?? 'element'}`}
                confirmButtonType="danger"
                onConfirm={onConfirmClick}
                onReject={onClose}
                isConfirmDisabled={isDeleteCampaignsLoading || isMultiDeleteCampaignsLoading}
            />
        </>
    );
};

export default ArchiveCampaignsModal;
