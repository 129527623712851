import React from 'react';
import cn from 'classnames';

import { uppercase } from '../../Functions/utils';

import './just-alert.scss';

export const JUST_ALERT_TYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info'
};

const JustAlert = React.memo(({ type, children, className }) => {
    return (
        <div className={cn(`just-alert just-alert--${[JUST_ALERT_TYPE[uppercase(type)] ?? 'warning']}`, className)}>
            <div className="just-alert__content">{children}</div>
        </div>
    );
});

JustAlert.displayName = 'JustAlert';
export default JustAlert;
