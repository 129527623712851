import indexApi from './indexApi';
import { camelToKebabCase } from '../../Functions/utils';

const tagTypes = {
    indicators: 'indicators',
    chartData: 'chartData',
    dashboardData: 'dashboardData'
};

export const dashboardApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(tagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getIndicators: builder.query({
            query: params => `/dashboard/statistic-panel${params}`,
            providesTags: [tagTypes.indicators]
        }),
        getChartData: builder.query({
            query: params => `/dashboard/chart${params}`,
            providesTags: [tagTypes.chartData]
        }),
        getDashboardEntityData: builder.query({
            query: ({ entity, params }) => `/dashboard/${camelToKebabCase(entity)}${params}`,
            providesTags: (result, error, { entity }) => [{ type: tagTypes.dashboardData, id: entity }]
        })
    })
});

export const { useGetIndicatorsQuery, useGetChartDataQuery, useGetDashboardEntityDataQuery } = dashboardApi;
