import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { KEY_CODE } from '../../../../../../Constants';
import useToggle from '../../../../../../Functions/hooks/useToggle';
import TextInput from '../../../../../Overall/Forms/Inputs/TextInput';
import Icon from '../../../../../Overall/Icon';
import Btn from '../../../../../Overall/UI/Btn/Btn';
import ArrowPrevious from '../../../../../../../../media/images/arrow-previous.svg';
import Langs from '../../../../../../Langs';

export const Badge = ({ className, children, isSuccess, isSquare }) => (
    <div className={cn('badge', { 'badge--success': isSuccess }, { 'badge--square': isSquare }, className)}>
        {children}
    </div>
);

export const ClickUrls = ({ total = 1, value }) => (
    <div className="path-click-urls">
        <div className="path-click-urls__title">Click Urls</div>
        {[...Array(total).keys()].map(index => (
            <Badge key={index} isSuccess={value > index} isSquare>
                {index + 1}
            </Badge>
        ))}
    </div>
);

export const GhostOffer = ({ index, hasWarning }) => (
    <div className="ghost-offer">
        <div className="ghost-offer__title">Offer for click URL {index}</div>
        {hasWarning && (
            <div className="ghost-offer__description">
                Now <Badge>Click {index}</Badge> goes to the last offer <img src={ArrowPrevious} alt="" />
            </div>
        )}
    </div>
);

export const EditablePath = props => {
    const { name: initName, isEditing, toggleOffEditing, onChange, className, onClick = () => {} } = props;
    const [name, setName] = useState(initName);
    const applyRef = useRef(null);

    const handleClick = e => {
        e.stopPropagation();
    };

    useEffect(() => {
        setName(initName);
    }, [isEditing, initName]);

    const handleKeyUp = ({ keyCode }) => {
        if (keyCode === KEY_CODE.ENTER) {
            if (name.trim()) onChange(name.trim(), 'name');
            toggleOffEditing();
        }
    };

    const handleBlur = e => {
        if (applyRef.current === e.relatedTarget) {
            if (name.trim()) onChange(name.trim(), 'name');
        }
        toggleOffEditing();
        e.stopPropagation();
    };

    return (
        <div className={cn('editable-path', className)}>
            {!isEditing ? (
                <span onClick={onClick}>{name}</span>
            ) : (
                <div className="editable-path__content" onClick={handleClick}>
                    <div className="btn-group">
                        <TextInput
                            dataTest="path_path-name-input"
                            className="editable-path__content--grow"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            onBlur={handleBlur}
                            onKeyUp={handleKeyUp}
                            autoFocus
                        />
                        <Btn
                            dataTest="path_edit-name_apply-btn"
                            icon
                            ref={applyRef}
                            type="stroked"
                            className="path__icon--text"
                        >
                            <Icon.Check />
                        </Btn>
                        <Btn
                            dataTest="path_edit-name_cancel-btn"
                            icon
                            type="stroked"
                            onClick={toggleOffEditing}
                            className="path__icon--gray"
                        >
                            <Icon.X className="color--grey-gull" />
                        </Btn>
                    </div>
                </div>
            )}
        </div>
    );
};

export const EditPlainField = ({ value, text, onChange, className, isReadOnly }) => {
    const { isToggled: isEdited, toggleOff: unsetIsEdited, toggleOn: setIsEdited } = useToggle(false);
    const [state, setState] = useState(value);

    useEffect(() => {
        setState(value);
    }, [value, isEdited]);

    const handleKeyUp = ({ keyCode, target }) => {
        if (keyCode === KEY_CODE.ENTER) {
            const newValue = +target.value || 1;
            onChange(newValue);
            unsetIsEdited();
        }
    };

    return (
        <div className={cn('edit-plain-field', className)}>
            {isEdited && !isReadOnly ? (
                <TextInput
                    dataTest="path_edit-plan-input"
                    value={state}
                    onChange={e => setState(e.target.value)}
                    onBlur={unsetIsEdited}
                    onKeyUp={handleKeyUp}
                    autoFocus
                />
            ) : (
                <div
                    onClick={() => {
                        if (!isReadOnly) setIsEdited();
                    }}
                >
                    {!isReadOnly && (typeof text === 'function' ? text() : text)}
                    {isReadOnly && <span className="color--grey-gull">100.00%</span>}
                </div>
            )}
        </div>
    );
};

export const getAdviceWeight = (weight, items) =>
    (
        (100 / items.reduce((res, item) => (item.isDeleted || !item.isEnabled ? res : res + item.weight), 0)) *
        weight
    ).toFixed(2);

export const PathWeight = ({
    isOptimized,
    optimizedWeight,
    entityType,
    isShowEditableOptimizationInOffer = true,
    item,
    items,
    onChange
}) => {
    const txt = Langs[global.lng];

    return (
        <div className="path__alert-action">
            {isOptimized ? (
                isShowEditableOptimizationInOffer && (
                    <EditPlainField
                        value={item.weight}
                        className="path__edit-plain-field"
                        text={() => (
                            <>
                                <span>{item.weight}</span>
                                <span className="ml2 color--grey-gull">{`(${
                                    item.isEnabled ? getAdviceWeight(item.weight, items) : 0
                                }%)`}</span>
                            </>
                        )}
                        onChange={e => onChange(e, 'weight')}
                        isReadOnly={items.filter(el => !el.isDeleted).length <= 1}
                    />
                )
            ) : (
                <div>
                    {txt.labels.auto}{' '}
                    {entityType === 'campaigns' && (
                        <span className="color--grey-gull">
                            {optimizedWeight >= 0 ? `(${optimizedWeight.toFixed(2)}%)` : ''}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};
