import React from 'react';
import cn from 'classnames';

import './tooltip-container.scss';

const TooltipContainer = ({ children, style, isOpenTop }) => {
    return (
        <div style={style} className={cn('tooltip-container', { 'tooltip-container--top': isOpenTop })}>
            {children}
        </div>
    );
};

export default TooltipContainer;
