import indexApi from './indexApi';
import { paymentMethodListTransformer } from '../transformers';

const paymentApiTagTypes = {
    paymentList: 'paymentList'
};

const paymentApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(paymentApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getPaymentMethodList: builder.query({
            query: () => `/payment`,
            transformResponse: response => paymentMethodListTransformer(response),
            providesTags: () => [paymentApiTagTypes.paymentList]
        }),

        addPaymentMethod: builder.mutation({
            query: data => ({
                url: `/payment`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        }),

        addNewPaymentMethod: builder.mutation({
            query: data => ({
                url: `/payment/delete-all-and-create`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        }),

        changePaymentMethodPriority: builder.mutation({
            query: ({ list }) => ({
                url: `/payment/change-priority`,
                method: 'PATCH',
                body: { paymentMethodIds: list }
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        }),

        setDefaultPaymentMethod: builder.mutation({
            query: ({ id }) => ({
                url: `/payment/make-default/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        }),

        renamePaymentMethod: builder.mutation({
            query: ({ id, name }) => ({
                url: `/payment/rename/${id}`,
                method: 'PUT',
                body: { name }
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        }),

        deletePaymentMethod: builder.mutation({
            query: ({ id }) => ({
                url: `/payment/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error) => {
                if (!error) return [paymentApiTagTypes.paymentList];
                return [];
            }
        })
    })
});

export const {
    useGetPaymentMethodListQuery,
    useAddPaymentMethodMutation,
    useAddNewPaymentMethodMutation,
    useChangePaymentMethodPriorityMutation,
    useSetDefaultPaymentMethodMutation,
    useRenamePaymentMethodMutation,
    useDeletePaymentMethodMutation
} = paymentApi;
