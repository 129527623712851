import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import * as Icon from 'react-feather';
import InputMask from 'react-input-mask';

import Langs from '../../../../Langs';
import Switcher from '../../UI/Switcher/Switcher';
import HintIcon from './HintIcon';
// import { omitKeys } from '../../../../Functions/utils';

const INCREMENT_STEP = 1;
// const DEFAULT_MASK_CHAR = '_';
// const DEFAULT_FORMAT_CHARS = {
//     9: '[0-9]',
//     a: '[A-Za-z]',
//     '*': '[A-Za-z0-9]'
// };

const TextInput = React.forwardRef((props, ref) => {
    const txt = Langs[global.lng];

    const {
        label,
        className,
        errorClassName,
        type,
        min = 0,
        max,
        id,
        value,
        placeholder,
        require,
        labelRequire,
        onChange,
        onFocus,
        onBlur,
        onKeyPress,
        onKeyUp,
        disabled,
        error,
        hint,
        hintType,
        labelLeft,
        switcher,
        reset,
        icon,
        fixed,
        autoFocus,
        readOnly,
        textRight,
        maxLength,
        dataTest,
        checkCorrectSymbol,
        correctSymbolEventType,
        mask,
        maskChar,
        maskPlaceholder,
        formatChars,
        alwaysShowMask,
        beforeMaskedValueChange,
        beforeMaskedStateChange
    } = props;
    // const valueForRender = value || type === 'number' ? '0' : '';

    const [isVisibleField, setIsVisibleField] = useState(false);
    const [isValidValue, setIsValidValue] = useState(true);

    useEffect(() => {
        let timerId;
        if (!isValidValue) {
            timerId = setTimeout(setIsValidValue, 500, true);
        }
        return () => {
            if (timerId) clearTimeout(timerId);
        };
    }, [isValidValue]);

    const handleChange = useCallback(num => {
        let val = (+value || 0) + num;
        if (+val < min) val = min;
        if (+val > max) val = max;
        onChange({ target: { value: val } });
    });

    // const change = useCallback(e => {
    //     let {
    //         target: { value: val }
    //     } = e;
    //     if (type === 'number') {
    //         if (+val && +val !== 0 && val[0] === '0' && val[1] !== '.') val = val.slice(1);
    //         val = +val || 0;
    //         if (val < min) val = min;
    //         if (val > max) val = max;
    //         onChange({ target: { value: val } });
    //     } else onChange(e);
    // });

    // const getIsNullValue = val => {
    //     if (!mask) return false;
    //     if (!val) return true;

    //     const maskSymbols = Object.keys(omitKeys(formatChars || DEFAULT_FORMAT_CHARS, ['*']));
    //     const separator = maskSymbols.reduce((acc, symbol) => acc.replace(new RegExp(symbol, 'g'), ''), mask)[0];

    //     return val.split(separator).reduce((acc, part) => {
    //         const isNotEmpty = !!part.replace(new RegExp(maskChar || DEFAULT_MASK_CHAR, 'g'), '');
    //         return acc && isNotEmpty;
    //     }, true);
    // };

    const change = e => {
        let {
            target: { value: val }
        } = e;
        // const nullValue = getIsNullValue(val);

        if (checkCorrectSymbol && (correctSymbolEventType === 'change' || !correctSymbolEventType) && val !== '') {
            setIsValidValue(checkCorrectSymbol(val));
        }

        if (type === 'number') {
            val = val.replace(/\D/g, '');
            if (+val && +val !== 0 && val[0] === '0' && val[1] !== '.') val = val.slice(1);
            val = +val || 0;
            if (val < min) val = min;
            if (val > max) val = max;
        }
        // onChange({ target: { value: nullValue ? '' : val } });
        onChange({ target: { value: val } });
    };

    const handleKeyUp = e => {
        if (checkCorrectSymbol && correctSymbolEventType === 'keyup') {
            setIsValidValue(checkCorrectSymbol());
        }
        onKeyUp(e);
    };

    const handleKeyPress = e => {
        if (checkCorrectSymbol && correctSymbolEventType === 'keypress') {
            setIsValidValue(checkCorrectSymbol());
        }
        onKeyPress(e);
    };

    const handleReset = useCallback(() => {
        onChange({ target: { value: '' } });
    });

    const renderIncDec = () => (
        <div className={cn('buttons-inside-input', { inactive: disabled })}>
            <button
                data-test="text-input_minus-btn"
                className="buttons-inside-input__item"
                onClick={() => handleChange(-INCREMENT_STEP)}
            >
                <Icon.Minus size="16" />
            </button>
            <button
                data-test="text-input_plus-btn"
                className="buttons-inside-input__item"
                onClick={() => handleChange(INCREMENT_STEP)}
            >
                <Icon.Plus size="16" />
            </button>
        </div>
    );

    const renderResetBtn = () => (
        <div className="buttons-inside-input">
            <button
                data-test="text-input_reset-btn"
                className={cn('buttons-inside-input__item buttons-inside-input__item--borderless', {
                    'buttons-inside-input__item--disabled': disabled
                })}
                onClick={handleReset}
            >
                <Icon.X className="buttons-inside-icon" size="16" />
            </button>
        </div>
    );

    const renderLabel = () => (
        <>
            {textRight ? (
                <div className="j46 w100">
                    <span>{label}</span>
                    <span className="form-label-title-text-right">{textRight}</span>
                </div>
            ) : (
                <>
                    <div className="j4 mr5">
                        {switcher && (
                            <Switcher
                                dataTest="text-input_visible-field"
                                isSwitchOn={isVisibleField}
                                onChange={() => setIsVisibleField(!isVisibleField)}
                            />
                        )}
                        <span>{label}</span>
                    </div>
                    {labelRequire && <span className="form-label-title__label-required">*</span>}
                </>
            )}
        </>
    );

    const wrapperClassName = cn('form-label', { 'form-label__input--fixed': fixed }, className);

    const mainProps = {
        ...{ value: value ?? '' },
        ...(dataTest ? { key: dataTest } : {}),
        ...(dataTest ? { 'data-test': dataTest } : {}),
        ...(type ? { type: type === 'number' ? 'text' : type } : {}),
        ...(id ? { id } : {}),
        ...(placeholder ? { placeholder: txt?.placeholders[placeholder] || placeholder } : {}),
        ...(require ? { required: require } : {}),
        ...(disabled ? { disabled } : {}),
        ...(maxLength ? { maxLength } : {}),
        ...(autoFocus ? { autoFocus } : {}),
        ...(onFocus ? { onFocus } : {}),
        ...(onBlur ? { onBlur } : {}),
        ...(onChange ? { onChange: change } : {}),
        ...(onKeyUp ? { onKeyUp: handleKeyUp } : {}),
        ...(onKeyPress ? { onKeyPress: handleKeyPress } : {}),
        ...(readOnly ? { readOnly } : {})
    };

    const maskProps = {
        ...(maskChar ? { maskChar } : {}),
        ...(formatChars ? { formatChars } : {}),
        ...(maskPlaceholder ? { maskPlaceholder } : {}),
        ...(alwaysShowMask ? { alwaysShowMask } : {}),
        ...(beforeMaskedValueChange ? { beforeMaskedValueChange } : {}),
        ...(beforeMaskedStateChange ? { beforeMaskedStateChange } : {})
    };

    const onIconClick = e => {
        const element = e.target.parentElement.querySelector('input');
        const currentType = element.getAttribute('type');

        if (type === 'password') {
            element.setAttribute('type', `${currentType === 'password' ? 'text' : 'password'}`);
            e.target.classList[currentType === 'password' ? 'add' : 'remove']('active');
        }
    };

    const renderInput = () => {
        const inputClassName = cn(
            'form-input',
            { 'form-input--icon': icon },
            { 'form-input--error': error },
            { 'form-input--not-valid': !isValidValue }
        );

        return (
            <div className="rel text-input">
                {mask ? (
                    <InputMask
                        mask={mask}
                        {...mainProps}
                        {...maskProps}
                        className={inputClassName}
                        spellCheck="false"
                        inputRef={ref}
                    />
                ) : (
                    <input {...mainProps} className={inputClassName} spellCheck="false" ref={ref} />
                )}
                {type === 'number' ? renderIncDec() : ''}
                {reset && value ? renderResetBtn() : ''}
                {icon && (
                    <span className={cn('text-input__icon', { [`text-input--${icon}`]: icon })} onClick={onIconClick} />
                )}
            </div>
        );
    };

    const renderTextarea = () => (
        <textarea
            data-test={dataTest}
            className={cn(
                'form-input',
                'form-input--tall',
                { [`form-input--${icon}`]: icon },
                { 'form-input--error': error },
                { 'form-input--not-valid': !isValidValue }
            )}
            id={id}
            value={value || ''}
            placeholder={txt?.placeholders[placeholder] || placeholder}
            required={require}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange ? change : null}
            onKeyUp={onKeyUp ? handleKeyUp : null}
            onKeyPress={onKeyPress ? handleKeyPress : null}
            disabled={disabled}
            ref={ref}
            spellCheck="false"
            readOnly={readOnly ? true : null}
        />
    );

    return (
        <div className={wrapperClassName}>
            <div className={labelLeft ? 'j4 mr15' : null}>
                {label && (
                    <div className="j4 form-label-title">
                        {label ? renderLabel() : null}
                        {hint && <HintIcon hint={txt?.hints[hint] || hint} type={hintType} />}
                    </div>
                )}
                {(isVisibleField || !switcher) && (type === 'textarea' ? renderTextarea() : renderInput())}
            </div>
            {error && <div className={cn('form-label--error', errorClassName)}>{error}</div>}
        </div>
    );
});

TextInput.displayName = 'TextInput';
export default TextInput;
