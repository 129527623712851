import React from 'react';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import Langs from '../../../../../../Langs';
import { ALERT_TYPE, BADGE_TYPE, VERIFY_STATUS } from '../../../../../../Constants';
import { showByValue } from '../../../../../../Functions/utils';

import Alert from '../../../../../Overall/UI/Alert/Alert';
import ActionsGroup from '../../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import { ClickUrls, PathWeight } from './common';
import BadgeRound from '../../../../../Overall/UI/BadgeRound/BadgeRound';

const getStyle = (style, snapshot) =>
    !snapshot.isDropAnimating
        ? style
        : {
              ...style,
              transitionDuration: `0.05s`
          };

export const getIsLanderVerified = lander => {
    const getIsVerified = (isConsider, verifyProp) => {
        if (isConsider) return verifyProp === VERIFY_STATUS.VERIFIED;
        return true;
    };

    return (
        lander.verify?.statuses?.length &&
        lander.verify?.statuses?.every(el => el.status === VERIFY_STATUS.VERIFIED) &&
        getIsVerified(lander.isClickUrlClassic, lander.verify?.clickUrlScript) &&
        getIsVerified(lander.hasProtectLandingPage, lander.verify?.landingPageProtectScript) &&
        getIsVerified(lander.hasRedirectlessScript, lander.verify?.redirectlessScript)
    );
};

const Lander = ({
    lander,
    landers,
    countries,
    onLanderByIdChange,
    pathLanders,
    pathOffers,
    landerIndex,
    showSelectItemsModal,
    showCreateUpdateModal,
    hasManyClickUrls,
    onPathChange,
    isOptimizationWeights,
    isRuleOptimizedData,
    weightsOptimization,
    initialWeightOptimizationMethod,
    trackingMethod,
    lastChangeType,
    isOneItemChange,
    isOneItemDelete,
    entityType,
    isDraggable = false,
    isHideWeights,
    dataTest
}) => {
    const txt = Langs[global.lng];

    const landerItem = landers?.find(item => lander.landerValue === item.value);

    if (!landerItem) return null;

    const { value, name: landerName, countryTag, isDomainBanned, numberOfOffers } = landerItem || {};
    const isLanderOptimization = !isOptimizationWeights || weightsOptimization === 'offers';

    const onLanderChange = (data, prop) => onLanderByIdChange(data, prop, lander.landerValue);

    const DragWrapper = ({ children }) => {
        return isDraggable ? (
            <Draggable draggableId={lander.landerValue} index={landerIndex} key={value}>
                {(provided, snapshot) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getStyle(provided.draggableProps.style, snapshot)}
                        ref={provided.innerRef}
                    >
                        {children}
                    </div>
                )}
            </Draggable>
        ) : (
            <>{children}</>
        );
    };

    const getLanderWeight = () => {
        if (weightsOptimization === 'bothCombined') return -1;

        if (isRuleOptimizedData && initialWeightOptimizationMethod === weightsOptimization) {
            if (lander.optimizedWeight >= 0) return lander.optimizedWeight;
            else return -1;
        } else {
            if (!lander.isEnabled) return 0;
            return 100 / pathLanders.filter(el => el.isEnabled).length;
        }
    };

    return (
        <DragWrapper>
            <Alert
                disabled={lander.isDeleted}
                key={value}
                type={ALERT_TYPE.SUCCESS}
                alertMessage={landerItem.isArchived && txt.labels.archived}
                className={cn({ 'alert_lander--draggable': isDraggable, 'alert_lander--disabled': lander.isDeleted })}
                LeftComponent={
                    <>
                        <div
                            className={cn('flow-path__landers-offers', isDomainBanned && 'flow-path__alert-text--red')}
                            onClick={() => !lander.isDeleted && showSelectItemsModal('landers', lander.landerValue)}
                        >
                            <span>{`${showByValue(countries, countryTag)} - ${landerName}`}</span>

                            {landerItem.isArchived && (
                                <BadgeRound
                                    text={txt.labels.archived}
                                    type={BADGE_TYPE.DANGER}
                                    className="badge-round--clear-border ml5"
                                />
                            )}
                        </div>

                        {!lander.isDeleted && lander.isEnabled && hasManyClickUrls && (
                            <ClickUrls total={numberOfOffers} value={pathOffers.length} />
                        )}
                    </>
                }
                RightComponent={
                    // lander.isDeleted ? (
                    //     // <ActionsGroup
                    //     //     dataTest={dataTest}
                    //     //     onUndo={() => {
                    //     //         delete lander.isDeleted;
                    //     //         onPathChange();
                    //     //     }}
                    //     // />
                    // ) : (
                    <>
                        {!lander.isDeleted && !isHideWeights ? (
                            <PathWeight
                                isOptimized={isLanderOptimization}
                                optimizedWeight={getLanderWeight()}
                                entityType={entityType}
                                item={lander}
                                items={pathLanders}
                                onChange={onLanderChange}
                            />
                        ) : null}

                        <ActionsGroup
                            dataTest={dataTest}
                            className={lander.isDeleted ? 'path_alert-actions-group--deleted' : null}
                            onEdit={() => showCreateUpdateModal('landers', landerItem.value)}
                            isSwitchOn={lander.isEnabled}
                            onSwitch={
                                trackingMethod.value === 'redirect' && !lander.isDeleted
                                    ? e => onLanderChange(e, 'isEnabled')
                                    : null
                            }
                            onDelete={() => {
                                if (trackingMethod?.value === 'redirect') {
                                    if (lastChangeType) lastChangeType.current = 'landers';
                                    if (isOneItemChange) isOneItemChange.current = true;
                                    if (isOneItemDelete) isOneItemDelete.current = true;

                                    onPathChange(
                                        pathLanders.filter(el => lander.landerValue !== el.landerValue),
                                        'landers'
                                    );
                                } else onPathChange([], 'landers');
                            }}
                        />
                    </>
                    // )
                }
                warningMessage={getIsLanderVerified(landerItem) ? '' : txt.labels.notVerified}
            />
        </DragWrapper>
    );
};

export default Lander;
