import React from 'react';
import cn from 'classnames';

import ReportButton from '../report-button/report-button';
import MultiReportButton from '../multi-report-button/multi-report-button';

const ReportGroupButton = ({
    chapter,
    isDisabled,
    isHideDisabled,
    isActive,
    isJustButtonDisabled,
    isMultiButtonDisabled,
    onJustButtonClick,
    onMultiButtonClick,
    type,
    countRowsForReport
}) => {
    return (
        <div
            className={cn('table-control-report', {
                disabled: isDisabled,
                'hide-disabled': isHideDisabled,
                active: isActive
            })}
        >
            <div className="table-control-report_inner">
                <ReportButton
                    chapter={chapter}
                    isDisabled={isJustButtonDisabled}
                    onClick={onJustButtonClick}
                    type={type}
                />

                <MultiReportButton
                    chapter={chapter}
                    isDisabled={isMultiButtonDisabled}
                    onClick={onMultiButtonClick}
                    type={type}
                    countRowsForReport={countRowsForReport}
                />
            </div>
        </div>
    );
};

export default ReportGroupButton;
