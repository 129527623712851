import { useCallback, useState } from 'react';

const usePagination = initialPagination => {
    const [pagination, setNewPagination] = useState({
        page: initialPagination?.page ?? 1,
        pages: initialPagination?.pages ?? 1,
        perPage: initialPagination?.perPage ?? 10,
        'per-page': initialPagination?.perPage ?? 10
    });

    const setPage = useCallback(
        newPage => setNewPagination(oldPagination => ({ ...oldPagination, page: newPage })),
        []
    );

    const setPages = useCallback(
        newPages => setNewPagination(oldPagination => ({ ...oldPagination, pages: newPages })),
        []
    );

    const setPerPage = useCallback(
        newPerPage =>
            setNewPagination(oldPagination => ({ ...oldPagination, perPage: newPerPage, 'per-page': newPerPage })),
        []
    );

    const setPagination = useCallback(newPagination => {
        setNewPagination(oldPagination => ({
            ...oldPagination,
            page: newPagination?.page,
            pages: newPagination?.pages,
            perPage: newPagination?.perPage ?? newPagination?.['per-page'],
            'per-page': newPagination?.perPage ?? newPagination?.['per-page']
        }));
    }, []);

    const setCustomPagination = useCallback(newPagination => setNewPagination({ ...newPagination }), [pagination]);

    return {
        pagination,
        setPage,
        setPages,
        setPerPage,
        setPagination,
        setCustomPagination
    };
};

export default usePagination;
