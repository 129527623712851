import { v4 as uuid } from 'uuid';
import { checkDontReloadUrls } from '../redux/operations';
import {
    getLocalStorageSession,
    getSessionStorageSession,
    getSessionStorageTabId,
    getSessionStorageUserId,
    getSessionStorageToken,
    findUserIndex,
    getLastUserId,
    setSessionStorageSession,
    setLocalStorageSession,
    findTabIndex,
    getLocalStorageUserInfo,
    CREDENTIALS_KEY,
    LAST_USER_ID_KEY,
    logout
} from './utils';
import { SESSION_INFO_TYPES } from '../Constants';

window.addEventListener('beforeunload', () => {
    const session = getLocalStorageSession();
    if (!session?.length) return;

    const localStorageTime = parseInt(localStorage.getItem('lastTabCloseTime'), 10);
    const currentTime = new Date().getTime();

    if (localStorageTime && currentTime - localStorageTime < 50) {
        // browser close
        setLocalStorageSession(session.filter(el => el.isRemember === true));
    } else {
        // tab close || reload
        const userId = getSessionStorageUserId();
        if (!userId) return;

        const isRemember = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.IS_REMEMBER);

        if (!isRemember) {
            const userIndex = findUserIndex(userId);
            const tabIndex = findTabIndex(userIndex, getSessionStorageTabId());

            if (tabIndex !== -1) {
                session[userIndex].tabs.splice(tabIndex, 1);

                if (!session[userIndex].tabs.length) session[userIndex].isLastTabClosed = true;
                // console.log('!session[userIndex].tabs.length: ', !session[userIndex].tabs.length);
                // if (!session[userIndex].tabs.length) session.splice(userIndex, 1);
            }

            setLocalStorageSession(session);
        }
    }

    localStorage.setItem('lastTabCloseTime', JSON.stringify(new Date().getTime()));
});

document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
        const session = getLocalStorageSession();

        if (!session?.length) {
            if (checkDontReloadUrls(window.location.pathname)) return;

            // TODO: start debug //
            // localStorage.setItem(`logout ${new Date().getTime()}`, 'visibilitychange, !session?.length');
            // end debug //

            sessionStorage.removeItem(CREDENTIALS_KEY);
            window.location.reload();
            return;
        }

        const userId = getSessionStorageUserId();
        if (userId) {
            const localToken = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.TOKEN);
            const sessionToken = getSessionStorageToken();

            if (sessionToken && sessionToken !== localToken) window.location.reload();

            const userIndex = findUserIndex(userId);
            const tabId = getSessionStorageTabId();

            const isRemember = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.IS_REMEMBER);

            if (!isRemember && userIndex !== -1) {
                const isIssetTab = !!session[userIndex].tabs.find(el => el.tabId === tabId);

                if (!isIssetTab) {
                    session[userIndex].tabs.push({ tabId });
                    setLocalStorageSession(session);
                }
            }

            localStorage.setItem(LAST_USER_ID_KEY, JSON.stringify(userId));
        }

        // const isIssetUser = !!session?.find(el => el.userId === userId);

        // if (!isIssetUser) {
        //     // TODO: start debug //
        //     // localStorage.setItem(`logout ${new Date().getTime()}`, 'visibilitychange, !isIssetUser');
        //     // end debug //

        //     sessionStorage.removeItem(CREDENTIALS_KEY);
        //     window.location.reload();
        //     return;
        // }
    }
});

const checkSession = isSupportPath => {
    const session = getLocalStorageSession();
    if (!session?.length) {
        sessionStorage.removeItem(CREDENTIALS_KEY);
        return;
    }

    const isConnected = getSessionStorageUserId();

    if (!isConnected) {
        // new session
        const newTabId = uuid();
        const userId = getLastUserId();

        if (isSupportPath || !userId) return;

        const isRemember = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.IS_REMEMBER);

        if (isRemember === false) {
            const isLastTabClosed = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.IS_LAST_TAB_CLOSED);

            if (isLastTabClosed) {
                // TODO: start debug //
                // localStorage.setItem(`logout ${new Date().getTime()}`, 'checkSession, isLastTabClosed');
                // end debug //

                logout();
                return;
            }

            const lastActivityTime = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.LAST_ACTIVITY_TIME);
            const now = new Date().getTime();

            if (now - lastActivityTime > 1800000) {
                // last query > 30 min

                // TODO: start debug //
                // localStorage.setItem(`logout ${new Date().getTime()}`, 'checkSession, last query > 30 min');
                // end debug //

                logout();
                return;
            }

            const userIndex = findUserIndex(userId);

            if (userIndex !== -1) {
                session[userIndex].tabs.push({ tabId: newTabId });
                setLocalStorageSession(session);
            }
        }

        const token = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.TOKEN);

        if (token) setSessionStorageSession({ tabId: newTabId, userId, tokenToSync: token });
        else localStorage.removeItem(LAST_USER_ID_KEY);
    } else {
        // page reload
        const userId = getSessionStorageUserId();
        if (!userId) return;

        const userIndex = findUserIndex(userId);

        if (userIndex === -1) {
            // logout if no user

            // TODO: start debug //
            // localStorage.setItem(`logout ${new Date().getTime()}`, 'checkSession, userIndex === -1');
            // end debug //

            sessionStorage.removeItem(CREDENTIALS_KEY);
            window.location.reload();
            return;
        }

        const localToken = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.TOKEN);
        const sessionToken = getSessionStorageToken();

        if (localToken !== sessionToken) {
            // refresh token if invalid
            const tabSession = getSessionStorageSession();
            tabSession.tokenToSync = localToken;
            setSessionStorageSession(tabSession);
        }

        const isRemember = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.IS_REMEMBER);

        if (!isRemember) {
            const tabId = getSessionStorageTabId();
            const isIssetTab = session[userIndex].tabs.find(el => el.tabId === tabId);

            if (!isIssetTab) {
                // add a tab if it is not there
                session[userIndex].tabs.push({ tabId });
                session[userIndex].isLastTabClosed = false;
                setLocalStorageSession(session);
            }
        }
    }
};

export default checkSession;
