import { transform } from '../transformers';
import indexApi from './indexApi';

const invitationsApiTagTypes = { invitations: 'invitations' };

export const invitationsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(invitationsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getInvitationsList: builder.query({
                query: params => `/invitations${params || ''}`,
                transformResponse: response => ({
                    items: response.items.map(el => transform.invitations.import(el)),
                    pagination: response.pagination
                }),
                providesTags: () => [
                    { type: invitationsApiTagTypes.invitations, id: 'LIST' },
                    { type: invitationsApiTagTypes.invitations, id: 'ALL' }
                ]
            }),

            addInvitation: builder.mutation({
                query: data => ({
                    url: `/invitations`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error) => {
                    if (!error) {
                        return [{ type: invitationsApiTagTypes.invitations, id: 'LIST' }];
                    } else return [];
                }
            }),

            deleteInvitation: builder.mutation({
                query: ({ id }) => ({
                    url: `/invitations/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error) {
                        return [
                            { type: invitationsApiTagTypes.invitations, id },
                            { type: invitationsApiTagTypes.invitations, id: 'LIST' }
                        ];
                    } else return [];
                }
            })
        })
    });

export const { useGetInvitationsListQuery, useAddInvitationMutation, useDeleteInvitationMutation } = invitationsApi;
