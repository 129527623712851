import moment from 'moment-timezone';
import { swapKeyValue } from '../Functions/utils';

export const INTERVALS = {
    today: {
        start: () => moment().startOf('day'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    yesterday: {
        start: () => moment().subtract(1, 'day').startOf('day'),
        end: () => moment().startOf('day')
    },
    thisWeek: {
        start: () => moment().startOf('week'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    lastWeek: {
        start: () => moment().subtract(1, 'week').startOf('week'),
        end: () => moment().startOf('week')
    },
    last3Days: {
        start: () => moment().subtract(2, 'day').startOf('day'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    last7Days: {
        start: () => moment().subtract(6, 'day').startOf('day'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    last14Days: {
        start: () => moment().subtract(13, 'day').startOf('day'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    thisMonth: {
        start: () => moment().startOf('month'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    lastMonth: {
        start: () => moment().subtract(1, 'month').startOf('month'),
        end: () => moment().startOf('month')
    },
    last6Months: {
        start: () => moment().subtract(6, 'month').startOf('month'),
        end: () => moment().startOf('month')
    },
    thisYear: {
        start: () => moment().startOf('year'),
        end: () => moment().add(1, 'day').startOf('day')
    },
    lastYear: {
        start: () => moment().subtract(1, 'year').startOf('year'),
        end: () => moment().startOf('year')
    }
};

export const INTERVALS_WITH_FUTURE = {
    ...INTERVALS,
    thisWeek: {
        start: () => moment().startOf('week'),
        end: () => moment().endOf('week').add(1, 'day').startOf('day')
    },
    thisMonth: {
        start: () => moment().startOf('month'),
        end: () => moment().endOf('month').add(1, 'day').startOf('day')
    },
    thisYear: {
        start: () => moment().startOf('year'),
        end: () => moment().endOf('year').add(1, 'day').startOf('day')
    }
};

export const CUSTOM_INTERVAL_KEY = 'customDate';

export const PUBLIC_WORKSPACE = '33095ef7-3b5c-4dff-a1b5-f5075ee5a189';
export const LANDER_SCRIPT = `<script>document.addEventListener("DOMContentLoaded",function(){var e=new URLSearchParams(window.location.search).get("domain");e&&Array.from(document.querySelectorAll("a")).forEach(function(t){var n=null,r=t.getAttribute("href");try{n=new URL(r)}catch(e){return}"click"===n.pathname.split("/")[1]&&n.hostname!==e&&t.setAttribute("href",r.replace(n.hostname,e))})});</script>`;
export const INITIAL_SELECTED_METRICS = ['profit', 'cost'];
export const HIDDEN_PROFIT_FILTER_METRICS = ['revenue', 'profit'];
export const KEY_CODE = Object.freeze({ ENTER: 13 });

export const DISPLAY_MODE_STATUS = {
    ACTIVE: 'active',
    ARCHIVE: 'archive'
};
export const ENTITY_MODE_STATUS = {
    DEFAULT: 'default',
    CREATE: 'create',
    EDIT: 'edit',
    DUPLICATE: 'duplicate'
};
export const VERIFY_STATUS = {
    VERIFIED: 'verified',
    NOT_VERIFIED: 'not_verified',
    ERROR: 'error'
};
export const TRACKING_METHOD = {
    REDIRECT: 'redirect',
    DIRECT: 'direct',
    LANDER_OFFERS: 'lander_offers',
    OFFER: 'offer'
};
export const WORKSPACE_TYPE = {
    PUBLIC: 'Public',
    INITIAL: 'Initial',
    PRIVATE: 'Private'
};

export const MAIN_CHAPTERS = ['trafficSources', 'affiliateNetworks', 'campaigns', 'flows', 'landers', 'offers'];

export const CHAPTER_REPORTS = [
    'paths',
    'rules',
    'country',
    'stateProvince',
    'city',
    'language',
    'connectionType',
    'ispCarrier',
    'mobileCarrier',
    'proxy',
    'deviceType',
    'brand',
    'model',
    'resolution',
    'osName',
    'osVersion',
    'browserName',
    'browserVersion',
    'month',
    'dayOfMonth',
    'dayOfWeek',
    'hourOfDay',
    'refererDomain',
    'refererUrl'
];

export const ADD_VALUES_CHAPTERS = [
    'trafficSources',
    'affiliateNetworks',
    'campaigns',
    'flows',
    'landers',
    'offers',
    'reports',
    'chapter_reports'
];

export const HOME_AVAILABLE_CHAPTERS = ['dashboard', ...MAIN_CHAPTERS, 'conversions', ...CHAPTER_REPORTS, 'errorLog'];

export const SELECTED_ALL_VALUE = 'SELECTED_ALL_VALUE';

export const COLOR = {
    ROBINS_EGG_BLUE: '#00bbd4',
    GREY_GULL: '#9aa0b9',
    LAVENDER: '#bb6bd9',
    DODGER_BLUE: '#1eaeff',
    SEA_BUCKTHORN: '#f59a2f',
    FROLY: '#f06d8d',
    EMERALD: '#46d643',
    HAVELOCK_BLUE: '#6b81dd',
    RED: 'red',
    GREEN: 'green',
    BROWN: 'brown',
    GRAY: 'gray',
    BLUE: 'blue',
    ORANGE: 'orange'
};

export const METRIC_COLOR = {
    impressions: COLOR.ROBINS_EGG_BLUE,
    visits: COLOR.GREY_GULL,
    clicks: COLOR.LAVENDER,
    conversions: COLOR.DODGER_BLUE,
    revenue: COLOR.SEA_BUCKTHORN,
    cost: COLOR.FROLY,
    profit: COLOR.EMERALD,
    roi: COLOR.HAVELOCK_BLUE
};

export const API_VERSION = '/v1';

// FIXME: replace to 166 line
export const apiPaths = {
    affiliateNetworks: 'affiliate-networks',
    campaigns: 'campaigns',
    trafficSources: 'traffic-sources',
    flows: 'flows',
    landers: 'landers',
    offers: 'offers',
    reports: 'reports',
    triggers: 'triggers',
    users: 'users',
    currencies: 'currencies',
    countries: 'countries',
    timezones: 'timezones',
    invitations: 'invitations',
    workspaces: 'workspaces',
    dashboard: '',
    conversions: 'conversions'
};

export const API_PATH = {
    SETTINGS: 'settings',
    INVOICE_DETAILS: 'invoice-details'
};

export const apiChapters = swapKeyValue(apiPaths);

export const INITIAL_MODAL_STATE = {
    isVisible: false,
    title: '',
    message: '',
    buttonsConfig: null,
    body: null,
    className: '',
    warningModalConfig: null
};

export const EMAIL_RULE =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DOMAIN_NAME_RULE = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?[.]){1,3}[a-z0-9][a-z0-9-]{0,61}[a-z]$/i;

export const CREDIT_CARD_RULE = {
    Visa: new RegExp('^4[0-9]{3,}$'),
    MasterCard: new RegExp('^5[1-5][0-9]{2,}$'),
    AmericanExpress: new RegExp('^3[47][0-9]{2,}$'),
    Discover: new RegExp('^6[045][0-9]{2,}$')
};

export const APP_ROUTES = {
    HOME: '/home'
};

export const USER_ROLES = {
    OWNER: 'ROLE_OWNER',
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER'
};

export const BILLING_PLAN = {
    FREE: 'Free',
    BASE: 'Base',
    POPULAR: 'Popular',
    ENTERPRISE: 'Enterprise'
};

export const PRIVATE_ACTIONS = {
    HIDE: 'hide',
    DISABLE: 'disable',
    REDIRECT: 'redirect',
    REDIRECT_TO: 'redirectTo'
};

export const NEW_ITEM_WITH_COLUMNS = {
    flows: ['id', 'name', 'trackingMethod', 'workspaceId'],
    landers: [
        'id',
        'incrementId',
        'name',
        'countryTag',
        'landingPageSetupIsClickUrlNotMatchLanderUrl',
        'landingPageSetupIsClickUrlClassic',
        'landingPageSetupIsRedirectlessScript',
        'landingPageSetupIsLandingPageProtected',
        'landingPageSetupNumberOfOffers',
        'verify',
        'workspaceId'
    ],
    offers: [
        'id',
        'incrementId',
        'name',
        'countryTag',
        'offerCAPIsEnabled',
        'offerCAPCapType',
        'offerCAPDayConversions',
        'offerCAPDayClicks',
        'offerCAPConversionCAP',
        'offerCAPClickCAP',
        'offerCAPOfferId',
        'offerCAPTimezone',
        'payoutPrice',
        'payoutCurrency',
        'payoutMethod',
        'url',
        'affiliateNetworkId',
        'workspaceId'
    ],
    trafficSources: ['id', 'name', 'currency', 'trackingOptionsIsDirectTracking', 'isIntegration', 'workspaceId']
};

export const KEYBOARD_SERVICE_KEY = {
    ESCAPE: 'Escape',
    F1: 'F1',
    F2: 'F2',
    F3: 'F3',
    F4: 'F4',
    F5: 'F5',
    F6: 'F6',
    F7: 'F7',
    F8: 'F8',
    F9: 'F9',
    F10: 'F10',
    F11: 'F11',
    F12: 'F12',
    TAB: 'Tab',
    CAPS_LOCK: 'CapsLock',
    SHIFT: 'Shift',
    CONTROL: 'Control',
    META: 'Meta',
    OPTION: 'Option',
    ALT: 'Alt',
    CONTEXT_MENU: 'ContextMenu',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    NUM_LOCK: 'NumLock',
    BACKSPACE: 'Backspace',
    DELETE: 'Delete',
    ENTER: 'Enter',
    RETURN: 'Return',
    INSERT: 'Insert',
    HOME: 'Home',
    END: 'End',
    PAGE_UP: 'PageUp',
    PAGE_DOWN: 'PageDown',
    PRINT_SCREEN: 'PrintScreen',
    SCROLL_LOCK: 'ScrollLock',
    PAUSE: 'Pause'
};

export const ESCAPE_KEY = 27;
export const ENTER_KEY = 13;

export const MODAL_TYPES = {
    LOADING: 'loading',
    NEW_VERSION: 'newVersionModal',
    BLOCKING: 'blocking',
    TRIAL: 'trial',
    EVENTS_LIMIT: 'eventsLimit',
    OVERDUE: 'overdue',
    DOMAIN: 'domain',
    CONVERSION: 'conversion',
    ARCHIVE: 'archive',
    DELETE: 'delete',
    RESTORE: 'restore',
    OFFERS: 'offers',
    LANDERS: 'landers',
    CHOOSE_PLAN: 'choosePlan',
    ADD_NEW_PAYMENT_METHOD: 'addNewPaymentMethod',
    UPDATE_PAYMENT: 'updatePayment',
    UPDATE_BILLING_INFO: 'updateBillingInfo'
};

export const ALERT_TYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info',
    SECONDARY: 'secondary',
    TRANSPARENT: 'transparent'
};

export const BADGE_TYPE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger'
};

export const BADGE_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
};

export const PASSWORD_MIN_LENGTH = 8;

export const CARD_SYMBOLS_MIN_QUANTITY = 13;
export const CVV_SYMBOLS_QUANTITY = {
    DEFAULT: 4,
    COMMON: 3
};
export const CARD_SYMBOLS_MAX_QUANTITY = 18;
export const DATE_SYMBOLS_QUANTITY = 4;
export const MAX_CARD_VALIDITY_PERIOD = 5 * 365 * 24 * 60 * 60 * 1000;

export const NEW_PAYMENT_METHOD = 'new-card';

export const SESSION_INFO_TYPES = {
    IS_REMEMBER: 'isRemember',
    LAST_ACTIVITY_TIME: 'lastActivityTime',
    TOKEN: 'token',
    IS_LAST_TAB_CLOSED: 'isLastTabClosed',
    SUPPORT: 'support'
};

export const OFFER_CAP_TYPE = {
    CONVERSION: 'conversion',
    CLICK: 'click',
    CONVERSION_OR_CLICK: 'conversionOrClick'
};

export const URL_PARAMETER = {
    OPTIONAL: 'OPTIONAL',
    REPLACE: 'REPLACE'
};

export const TWO_DAYS_TIME = 172800000; // ms
export const CHECK_PROJECT_INTERVAL_TIME = 3600000; // ms
export const REMAINING_FREE_EVENTS = 10000; // events

export const LANDER_AND_OFFER_OPTIMIZATION_LIMIT = 25;
export const OFFER_PATH_LIMIT = 10;
export const LANDER_PATH_LIMIT = 10;

export const INTEGRATION_FEATURES = [
    'Synchronize cost for campaigns and sites',
    'Change bid for campaigns',
    'Pause/Resume campaigns'
];

export const MENU_TYPES = {
    CONTEXT_MENU: 'contextMenu'
};
