import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import Langs from '../../Langs';
import { getColumns } from '../../components/main-table/services';
import { getParams, clone, compare } from '../../Functions/utils';
import { getActualDateRange, showModal } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';

import useContextMenu from '../../Functions/hooks/useContextMenu';

import { useChangeStorageMutation } from '../../redux/api/storeApi';
import { useGetConversionListQuery } from '../../redux/api/conversionsApi';
import { useGetCampaignsAllNamesQuery } from '../../redux/api/campaignsApi';
import { useGetTrafficSourcesAllNamesQuery } from '../../redux/api/trafficSourcesApi';
import { useGetOffersAllNamesQuery } from '../../redux/api/offersApi';

import {
    changeMainTablePagination,
    changeMainTableSorting,
    changeMainTableActiveRow,
    selectConversionsTableData
} from '../../redux/main-table-slice';

import TableControls from './components/table-controls/table-controls';
import MainTableItems from '../../Modules/MainTable/MainTableItems/MainTableItems';
import ColumnsSettings from '../../Modules/Settings/ColumnsSettings/ColumnsSettings';

import './screen-conversions.scss';

const ScreenConversions = ({ chapter, storage, className, setExternalLoading, reportFilters = [] }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const tableParameters = useSelector(selectConversionsTableData);
    const { activeRow, filters, filterWithInput, sorting, pagination } = tableParameters;

    const [isLoading, setIsLoadig] = useState(true);
    const [rows, setRows] = useState([]);

    const dateRange = getActualDateRange(storage.common.dateRange);

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        [`filter[${filterWithInput.filter}]`]: filterWithInput.value,
        [`filter[dateFrom]`]: exportDateTransformer(dateRange.start),
        [`filter[dateTo]`]: exportDateTransformer(dateRange.end),
        ...(dateRange.compare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(dateRange.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(dateRange.endPrevDate)
              }
            : {})
    };

    Object.entries(filters).forEach(([key, value]) => {
        if (value !== 'all') parameters[`filter[${key}]`] = value;
    });

    const filtersParams = [
        ...reportFilters.map(el => `filter[dtype][]=${el.type}`),
        ...reportFilters.map((el, idx) => `filter[did][]=${idx > 0 ? el.value : el?.value?.join(',')}`)
    ].join('&');

    const { data: campaignsList, isSuccess: isCampaignsListLoaded } = useGetCampaignsAllNamesQuery();
    const { data: trafficSourcesList, isSuccess: isTrafficSourcesListLoaded } = useGetTrafficSourcesAllNamesQuery();
    const { data: offersList, isSuccess: isOffersListLoaded } = useGetOffersAllNamesQuery();

    const {
        data: conversionList,
        isSuccess: isConversionListLoaded,
        isFetching: isConversionListFetching
    } = useGetConversionListQuery(
        filtersParams ? getParams(parameters).concat('&', filtersParams) : getParams(parameters)
    );

    useEffect(() => {
        if (!isConversionListLoaded) return;

        if (activeRow) {
            const row = conversionList?.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (conversionList?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: conversionList.pagination
                })
            );
        }

        if (isCampaignsListLoaded && isTrafficSourcesListLoaded && isOffersListLoaded) {
            setRows(
                conversionList.items.map(el => ({
                    ...el,
                    campaign: campaignsList.find(it => it.value === el.campaignId)?.label ?? '',
                    trafficSource: trafficSourcesList.find(it => it.value === el.trafficSourceId)?.label ?? '',
                    offerName: offersList.find(it => it.value === el.offerId)?.label ?? ''
                }))
            );

            if (isLoading) setIsLoadig(false);
            if (setExternalLoading) setExternalLoading(false);
        }
    }, [conversionList, campaignsList, trafficSourcesList, offersList]);

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );

                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.conversions.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const onShowSettingsClick = () => {
        showModal({
            title: txt.labels.columnsSettings,
            headerType: 'primary',
            body: prop => (
                <ColumnsSettings
                    {...prop}
                    chapter={chapter}
                    columns={columns}
                    storage={storage}
                    isColoredRows={storage.common.isColoredRows}
                    isVerticalBorders={storage.common.isVerticalBorders}
                />
            )
        });
    };

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter,
                value: row.value === activeRow?.value ? null : row
            })
        );
    };

    const updateDateRange = newDateRange => {
        if (!compare(storage.common.dateRange, newDateRange)) {
            const newStorage = clone(storage);

            newStorage.common.dateRange = newDateRange;
            changeStorage(newStorage);

            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: { ...pagination, page: 1 }
                })
            );
        }
    };

    const [contextMenuRef, contextMenuSettings, onMainTableBodyScroll, onRowContextMenu] = useContextMenu();

    const isLoaded =
        isConversionListLoaded &&
        isCampaignsListLoaded &&
        isTrafficSourcesListLoaded &&
        isOffersListLoaded &&
        !isConversionListFetching &&
        !isLoading;

    return (
        <>
            <div className={cn('table-container screen-conversions', className)}>
                <div className="main-table">
                    <div className="main-table__wrapper compact-main-table">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            pagination={pagination}
                            activeRow={activeRow}
                            tableParameters={tableParameters}
                            filters={filters}
                            filterWithInput={filterWithInput}
                            dateRange={dateRange}
                            setDateRange={updateDateRange}
                            rows={rows}
                            columns={columns}
                            onShowSettingsClick={onShowSettingsClick}
                            contextMenuRef={contextMenuRef}
                            contextMenuSettings={contextMenuSettings}
                        />

                        <div className="main-table__visible-content" onScroll={onMainTableBodyScroll}>
                            <MainTableItems
                                chapter={chapter}
                                modifiedChapter={chapter}
                                columns={columns}
                                rows={rows}
                                sorting={sorting}
                                storage={storage}
                                isLoading={!isLoaded}
                                activeRow={activeRow}
                                onRowClick={onRowClick}
                                onRowContextMenu={(e, row) => onRowContextMenu(e, row, activeRow, onRowClick)}
                                handleChange={onTableActionsChange}
                                // TODO: Что-то решить с checkedRows
                                onChange={() => setRows([...rows])}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScreenConversions;
