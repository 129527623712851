import { useCallback, useState, useEffect } from 'react';
import { fixRegexPattern } from '../utils';

const useSearch = (data = [], getItemForCompare, ignoreItems) => {
    // FIXME: change to useNewSearch.js
    const [items, setItems] = useState(data);
    const [searchBy, setSearchBy] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const handleInputChange = useCallback(({ target }) => setSearchBy(target.value), []);
    const clearSearchBy = useCallback(() => setSearchBy(''), []);

    useEffect(
        (values = items) => {
            const regex = new RegExp(fixRegexPattern(searchBy), 'i');
            const regexTest = item => regex.test(item);

            if (values) {
                setFilteredItems(
                    values.filter(
                        item =>
                            ignoreItems?.some(ignoreItem => ignoreItem === item) ||
                            regexTest(getItemForCompare?.(item) || item)
                    )
                );
            }
        },
        [items, searchBy]
    );

    return {
        filteredItems,
        handleInputChange,
        setItems,
        searchBy,
        setSearchBy: val => setSearchBy(val?.toString()),
        clearSearchBy
    };
};

export default useSearch;
