import React from 'react';
import { Link2 } from 'react-feather';

import Langs from '../../../Langs';
import { getSharedReportLink } from '../../../Functions/utils';
import useCopyToggle from '../../../Functions/hooks/useCopyToggle';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './shared-report-link-modal.scss';

const SharedReportLink = ({ onClose, reportName, reportId }) => {
    const txt = Langs[global.lng];

    const [isCopied, setIsCopied] = useCopyToggle();

    return (
        <>
            <div className="shared-report-link-modal">
                <div className="archive-modal__content">
                    <p className="shared-report-link-modal__title">
                        Link for <b>{`“${reportName}”`}</b> successfully generated.
                    </p>
                    <p className="shared-report-link-modal__descr">
                        Click on Copy to grab the link and share it with the 3-rd parties without providing the access
                        to your account. The permanent link for this report is available in{' '}
                        <b>Tools -&gt; Shared Reports</b>.
                    </p>
                </div>
            </div>
            <ModalButtonBar
                rejectButtonText={txt.buttons.close}
                confirmButtonText={
                    <div className="shared-report-link-modal__confirm-btn">
                        <Link2 size={16} />
                        <div className="ml8">{isCopied ? 'Link copied!' : 'Copy Report Link'}</div>
                    </div>
                }
                onConfirm={() => {
                    navigator.clipboard.writeText(getSharedReportLink(reportId)).then(() => {
                        setIsCopied(true);
                    });
                }}
                onReject={onClose}
            />
        </>
    );
};

export default SharedReportLink;
