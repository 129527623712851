import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import Langs from '../../Langs';
import { capitalize, compare, clone } from '../../Functions/utils';
import { getActualDateRange } from '../../redux/operations';

import reportTemplate from '../../Pages/page-report/report-template';

export const reportClickHandler = (chapter, isMulti, settings) => {
    const txt = Langs[global.lng];

    const {
        generalSettings,
        dateRange,
        reports,
        rows,
        activeRows,
        checkedRows,
        activeRow,
        rowsForReport,
        history,
        changeReport
    } = settings;

    const isTabOpened = generalSettings.openingReport === 'in_focus';
    const actualDateRange = getActualDateRange(dateRange ?? {});

    const getRows = () => {
        if (isMulti) return rowsForReport;
        return activeRows.length ? activeRows : [checkedRows[0]];
    };

    const similarReport = reports
        .filter(el => el.bookmark)
        .find(
            el =>
                el.filters[0].type === chapter &&
                compare(el.filters[0].value, getRows()) &&
                moment(getActualDateRange(el.dateRange).start).diff(actualDateRange.start, 'minutes') === 0 &&
                moment(getActualDateRange(el.dateRange).end).diff(actualDateRange.end, 'minutes') === 0
        );

    if (similarReport) {
        if (!similarReport.bookmark.isActive) {
            const result = clone(reports);
            const index = result.findIndex(el => el.value === similarReport.value);
            if (index === -1) return;

            result[index].bookmark.isActive = true;

            changeReport(result);
        }

        history.push(similarReport.bookmark.path);
        return;
    }

    const value = uuid();
    const path = `/reports/${value}`;

    const multipleReportsCount =
        reports.filter(r => r.filters[0].type === chapter && r.filters[0].value.length > 1).length + 1;

    const getLabelText = () => {
        return isMulti
            ? `Multiple ${txt.labels[chapter].toLowerCase()}${
                  multipleReportsCount > 1 ? ` ${multipleReportsCount}` : ''
              }`
            : capitalize(activeRow?.name || rows.find(it => it.value === rowsForReport[0])?.name);
    };

    changeReport([
        ...reports,
        {
            ...reportTemplate,
            value,
            dateRange,
            filters: [{ type: chapter, value: getRows() }],
            groups: [chapter === 'campaigns' ? 'offers' : 'campaigns'],
            bookmark: {
                id: value,
                label: getLabelText(),
                name: getLabelText(),
                path,
                isFavorite: false,
                isActive: true,
                icon: chapter
            }
        }
    ]);

    if (isTabOpened) history.push(path);
};
