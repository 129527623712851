import { createSlice } from '@reduxjs/toolkit';
import { ENTITY_MODE_STATUS } from '../Constants';
import NameSpace from './api/name-space';

const storePath = NameSpace.MAIN_TABLE;

const initialState = {
    sharedReports: {
        activeRow: null,
        search: null,
        filters: {},
        sorting: {},
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    eventLog: {
        filters: {},
        sorting: {
            key: 'userName',
            type: 'DESC'
        },
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    users: {
        activeRow: null,
        sorting: {
            key: 'name',
            type: 'DESC'
        },
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    invitations: {
        activeRow: null,
        sorting: {
            key: 'email',
            type: 'DESC'
        },
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    workspaces: {
        activeRow: null,
        sorting: {},
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    conversions: {
        activeRow: null,
        filters: {
            timestamp: 'postback'
        },
        filterWithInput: {
            filter: 'clickId',
            value: ''
        },
        sorting: {
            key: 'incrementId',
            type: 'DESC'
        },
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    errorLog: {
        search: null,
        filters: {},
        sorting: {
            key: 'createdAt',
            type: 'DESC'
        },
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    reports: {
        activeRow: null,
        search: null,
        sorting: {},
        columnFilters: [],
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    campaigns: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        pageStatus: { mode: ENTITY_MODE_STATUS.DEFAULT, value: null, type: 'campaigns' },
        sorting: { key: 'incrementId', type: 'DESC' },
        columnFilters: [],
        pagination: { page: 1, pages: 1, 'per-page': 50 }
    },

    flows: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        pageStatus: { mode: ENTITY_MODE_STATUS.DEFAULT, value: null, type: 'flows' },
        sorting: { key: 'incrementId', type: 'DESC' },
        columnFilters: [],
        pagination: { page: 1, pages: 1, 'per-page': 50 }
    },

    offers: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        sorting: {
            key: 'incrementId',
            type: 'DESC'
        },
        columnFilters: [],
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    landers: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        sorting: {
            key: 'incrementId',
            type: 'DESC'
        },
        columnFilters: [],
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    trafficSources: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        sorting: {
            key: 'incrementId',
            type: 'DESC'
        },
        columnFilters: [],
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    },

    affiliateNetworks: {
        activeRow: null,
        search: null,
        chartStatus: { isOpen: false },
        sorting: {
            key: 'incrementId',
            type: 'DESC'
        },
        columnFilters: [],
        pagination: {
            page: 1,
            pages: 1,
            'per-page': 50
        }
    }
};

export const mainTableSlice = createSlice({
    name: NameSpace.MAIN_TABLE,
    initialState,
    reducers: {
        changeMainTableActiveRow: (state, action) => {
            state[action.payload.chapter].activeRow = action.payload.value;
        },
        changeMainTableSearch: (state, action) => {
            state[action.payload.chapter].search = action.payload.value;
        },
        changeMainTableChartStatus: (state, action) => {
            state[action.payload.chapter].chartStatus = action.payload.value;
        },
        changeMainTablePageStatus: (state, action) => {
            state[action.payload.chapter].pageStatus = action.payload.value;
        },
        changeMainTableFilters: (state, action) => {
            state[action.payload.chapter].filters[action.payload.filterKey] = action.payload.value;
        },
        changeMainTableColumnFilters: (state, action) => {
            state[action.payload.chapter].columnFilters = action.payload.value;
        },
        changeMainTableSorting: (state, action) => {
            state[action.payload.chapter].sorting = action.payload.value;
        },
        changeMainTablePagination: (state, action) => {
            state[action.payload.chapter].pagination = action.payload.value;
        },
        changeMainTableFilterWithInput: (state, action) => {
            state[action.payload.chapter].filterWithInput.filter = action.payload.filter;
            state[action.payload.chapter].filterWithInput.value = action.payload.value;
        }
    }
});

export const {
    changeMainTableActiveRow,
    changeMainTableSearch,
    changeMainTableChartStatus,
    changeMainTablePageStatus,
    changeMainTableFilters,
    changeMainTableColumnFilters,
    changeMainTableSorting,
    changeMainTablePagination,
    changeMainTableFilterWithInput
} = mainTableSlice.actions;

export const selectSharedReportsTableData = state => state[storePath].sharedReports;
export const selectEventLogTableData = state => state[storePath].eventLog;
export const selectUsersTableData = state => state[storePath].users;
export const selectInvitationsTableData = state => state[storePath].invitations;
export const selectWorkspacesTableData = state => state[storePath].workspaces;
export const selectConversionsTableData = state => state[storePath].conversions;
export const selectErrorLogTableData = state => state[storePath].errorLog;
export const selectReportsTableData = state => state[storePath].reports;
export const selectOffersTableData = state => state[storePath].offers;
export const selectLandersTableData = state => state[storePath].landers;
export const selectTrafficSourcesTableData = state => state[storePath].trafficSources;
export const selectAffiliateNetworksTableData = state => state[storePath].affiliateNetworks;
export const selectFlowsTableData = state => state[storePath].flows;
export const selectCampaignsTableData = state => state[storePath].campaigns;

export default mainTableSlice.reducer;
