import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../Langs';
import { getResponseErrorMessage } from '../../Functions/utils';

import useFocus from '../../Functions/hooks/useFocus';

import { useUploadConversionsMutation } from '../../redux/api/conversionsApi';

import JustAlert, { JUST_ALERT_TYPE } from '../../components/just-alert/just-alert';
import TextInput from '../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../Loader';

import './modals.scss';

const ConversionUpdateModal = ({ onClose }) => {
    const txt = Langs[global.lng];

    const [conversionUpdateID, setConversionUpdateID] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [clickIdsInputRef, setClickIdsFocus] = useFocus();

    const isAllValid = !!conversionUpdateID;

    useEffect(() => setTimeout(setClickIdsFocus, 0), []);

    const [
        uploadConversions,
        {
            isLoading: isUploadConversionsLoading,
            isSuccess: isUploadConversionsSuccess,
            isError: isUploadConversionsError,
            error: uploadConversionsError
        }
    ] = useUploadConversionsMutation();

    useEffect(() => {
        if (isUploadConversionsSuccess) {
            setIsSubmit(false);
            toast.success(txt.toasts.saved);
            setConversionUpdateID('');
        }
    }, [isUploadConversionsSuccess]);

    useEffect(() => {
        if (isUploadConversionsError) {
            setIsSubmit(false);
            toast.error(getResponseErrorMessage(uploadConversionsError.data, txt.toasts.notSaved));
        }
    }, [isUploadConversionsError]);

    const onSubmit = () => {
        setIsSubmit(true);

        if (isAllValid) uploadConversions(conversionUpdateID.trim());
    };

    return (
        <div className="conversions-update-wrapper">
            <div className="conversions-update">
                <JustAlert type={JUST_ALERT_TYPE.INFO}>
                    <div className="dfc">
                        <span>{txt.alerts.toolsConversionUpload}</span>
                        <span>{txt.alerts.toolsConversionUpload2}</span>

                        <div>
                            <ul>
                                <li>
                                    {txt.alerts.toolsConversionUpload3}
                                    <div className="mt5">
                                        <JustAlert className="bg--white pr10" type={JUST_ALERT_TYPE.INFO}>
                                            {txt.alerts.toolsConversionUpload4}
                                        </JustAlert>
                                    </div>
                                </li>
                                <li>{txt.alerts.toolsConversionUpload5}</li>
                                <li>{txt.alerts.toolsConversionUpload6}</li>
                                <li>{txt.alerts.toolsConversionUpload7}</li>
                            </ul>
                        </div>
                    </div>
                </JustAlert>

                <TextInput
                    dataTest="conversion-update-modal_click-ids-textarea"
                    className="mt15"
                    value={conversionUpdateID}
                    placeholder={txt.placeholders.clickIds}
                    type="textarea"
                    ref={clickIdsInputRef}
                    onChange={({ target: { value } }) => setConversionUpdateID(value)}
                    require
                    error={isSubmit && !conversionUpdateID && txt.errors.emptyConversions}
                />

                <ModalButtonBar
                    rejectButtonText={txt.buttons.cancel}
                    confirmButtonText={txt.buttons.update}
                    onReject={onClose}
                    onConfirm={onSubmit}
                    isConfirmDisabled={isSubmit && !isAllValid}
                    className="j5 mt15"
                />
            </div>

            {isUploadConversionsLoading && <Loader />}
        </div>
    );
};

export default ConversionUpdateModal;
