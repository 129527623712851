import React, { useState } from 'react';
import cn from 'classnames';

import useNewClickOutside from '../../../../../Functions/hooks/useNewClickOutside';

import Icon from '../../../Icon';
import BookmarkFavorite from '../bookmark-favorite/bookmark-favorite';

const Bookmarks = ({ favorites, reports }) => {
    const [isTabPopupOpen, setIsTabPopupOpen] = useState(false);

    const onStarClick = () => setIsTabPopupOpen(true);

    const containerRef = useNewClickOutside(() => {
        if (isTabPopupOpen) setIsTabPopupOpen(false);
    });

    return (
        <div className="bookmarks" ref={containerRef}>
            <div className="bookmarks__star" onClick={onStarClick}>
                <Icon.Star
                    size={16}
                    className={cn(favorites.length > 0 ? 'bookmarks__star--filled' : 'bookmarks__star--empty')}
                />
                <div>{favorites.length > 0 && favorites.length}</div>
            </div>

            {favorites.length > 0 && isTabPopupOpen && (
                <BookmarkFavorite favorites={favorites} reports={reports} setIsTabPopupOpen={setIsTabPopupOpen} />
            )}
        </div>
    );
};

export default Bookmarks;
