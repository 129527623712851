import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { CHAPTER_REPORTS, HOME_AVAILABLE_CHAPTERS } from '../Constants/index';
import { kebabToCamelCase } from '../Functions/utils';

import { useGetGeneralSettingsQuery } from '../redux/api/settingsApi';
import { useGetStorageQuery } from '../redux/api/storeApi';
import { useGetReportsQuery } from '../redux/api/reportsApi';

import TopMenu from '../Modules/Overall/TopMenu/TopMenu';
import Dashboard from '../Modules/Dashboard/Dashboard';
import showItemsModal from '../Modules/Items/showItemsModal';
import StartRedirect from './StartRedirect';
import ScreenConversions from '../Screens/screen-conversions/screen-conversions';
import ScreenErrorLog from '../Screens/screen-error-log/screen-error-log';
import ScreenReports from '../Screens/screen-reports/screen-reports';
import ScreenOffers from '../Screens/screen-offers/screen-offers';
import ScreenLanders from '../Screens/screen-landers/screen-landers';
import ScreenTrafficSources from '../Screens/screen-traffic-sources/screen-traffic-sources';
import ScreenAffiliateNetworks from '../Screens/screen-affiliate-networks/screen-affiliate-networks';
import ScreenFlows from '../Screens/screen-flows/screen-flows';
import ScreenCampaigns from '../Screens/screen-campaigns/screen-campaigns';

const Home = ({ modal }) => {
    const { path, action } = useParams();
    const { state: locationState } = useLocation();
    const history = useHistory();
    const dispath = useDispatch();

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: storage } = useGetStorageQuery();
    const { data: reports } = useGetReportsQuery();

    const chapter = kebabToCamelCase(path);

    useEffect(() => {
        if (
            isGeneralSettingsLoaded &&
            generalSettings.defaultHomeScreen === 'last_opened_report' &&
            chapter !== 'undefined'
        ) {
            localStorage.setItem('lastOpenedGlobalReport', chapter);
        }
    }, [chapter, generalSettings]);

    useEffect(() => {
        if (
            action &&
            !['campaigns', 'flows', 'triggers'].includes(chapter) &&
            !modal.some(item => item.id === chapter)
        ) {
            // if ['campaigns', 'flows', 'triggers'].includes(chapter) routing in Screen component
            showItemsModal(chapter, action, locationState, history, () => {}, dispath);
        }
    }, [action]);

    if (!HOME_AVAILABLE_CHAPTERS.includes(chapter)) return <StartRedirect />;

    const getContent = () => {
        if (CHAPTER_REPORTS.includes(chapter)) {
            return <ScreenReports chapter={chapter} storage={storage} reports={reports} />;
        }

        switch (chapter) {
            case 'dashboard':
                return <Dashboard />;

            case 'conversions':
                return <ScreenConversions chapter={chapter} storage={storage} />;

            case 'errorLog':
                return <ScreenErrorLog chapter={chapter} storage={storage} />;

            case 'campaigns':
                return (
                    <ScreenCampaigns
                        chapter={chapter}
                        storage={storage}
                        reports={reports}
                        action={action}
                        locationState={locationState}
                        modalsInStore={modal}
                    />
                );

            case 'flows':
                return (
                    <ScreenFlows
                        chapter={chapter}
                        storage={storage}
                        reports={reports}
                        action={action}
                        locationState={locationState}
                    />
                );

            case 'offers':
                return <ScreenOffers chapter={chapter} storage={storage} reports={reports} />;

            case 'landers':
                return <ScreenLanders chapter={chapter} storage={storage} reports={reports} />;

            case 'trafficSources':
                return <ScreenTrafficSources chapter={chapter} storage={storage} reports={reports} />;

            case 'affiliateNetworks':
                return <ScreenAffiliateNetworks chapter={chapter} storage={storage} reports={reports} />;

            default:
                throw new Error('Invalid chapter');
        }
    };

    return (
        <>
            <TopMenu chapter={chapter} />

            {getContent()}
        </>
    );
};

const Store = ({ oldState: { modal } }) => ({ modal });
export default connect(Store)(Home);
