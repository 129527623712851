import React, { useState } from 'react';

import Tabs from '../UI/Tabs/Tabs';

import './criteria-dialog.scss';

const criteriaModalTabs = [
    { id: 'enabled', label: 'Enabled', disabled: false },
    { id: 'geo', label: 'Geo', disabled: false },
    { id: 'time', label: 'Time', disabled: false },
    { id: 'connection', label: 'Connection', disabled: false },
    { id: 'device', label: 'Device', disabled: false },
    { id: 'referrer', label: 'Referrer', disabled: false },
    { id: 'variables', label: 'Variables', disabled: false },
    { id: 'other', label: 'Other', disabled: false }
];

const CriteriaTabs = ({ criteria, onChange, activeTabId = 'geo' }) => {
    const [activeTab, setActiveTab] = useState(activeTabId);

    const tabs = criteriaModalTabs?.map(tab => ({
        ...tab,
        count: criteria?.filter(
            ({ tabId, isEnabled, isHidden }) => (tabId === tab.id || tab.id === 'enabled') && isEnabled && !isHidden
        ).length
    }));

    const enabledItems = criteria?.filter(({ isEnabled, isHidden }) => isEnabled && !isHidden);
    const activeTabItems = activeTab === 'enabled' ? enabledItems : criteria.filter(({ tabId }) => tabId === activeTab);

    const onCriteriaByKeyChange = (data, prop, criteriaKey) => {
        const index = criteria.findIndex(el => el.key === criteriaKey);
        const newValues = [...criteria];

        let formattedCriteria = { ...criteria[index] };

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                formattedCriteria = { ...formattedCriteria, [el.prop]: el.value };
            });
        } else {
            formattedCriteria = { ...formattedCriteria, [prop]: data };
        }

        newValues.splice(index, 1, formattedCriteria);
        onChange(newValues, 'criteria');
    };

    return (
        <Tabs
            type="btn"
            tabs={tabs}
            currentTab={activeTab}
            onChange={setActiveTab}
            className="mt8 criteria-dialog__tabs"
            dataTest="criteria-dialog"
        >
            {activeTabItems.map(item => (
                <item.Component
                    {...item}
                    label={item.label}
                    onChange={(data, prop) => onCriteriaByKeyChange(data, prop, item.key)}
                />
            ))}
        </Tabs>
    );
};

export default CriteriaTabs;
