import { useCallback, useState } from 'react';
import { fixRegexPattern } from '../utils';

const useSearch = (items, getItemForCompare, ignoreItems) => {
    const [searchState, setSearchState] = useState('');

    const setSearchByForInput = useCallback(({ target }) => setSearchState(target?.value), []);
    const setSearchByValue = useCallback(val => setSearchState(val?.toString()), []);
    const clearSearchBy = useCallback(() => setSearchState(''), []);

    const filterItems = itemsToFilter => {
        if (!itemsToFilter?.length) return [];

        const regex = new RegExp(fixRegexPattern(searchState), 'i');
        const regexTest = item => regex.test(item);

        return itemsToFilter.filter(
            item => ignoreItems?.some(ignoreItem => ignoreItem === item) || regexTest(getItemForCompare?.(item) || item)
        );
    };

    const filteredItems = filterItems(items);

    return {
        filteredItems,
        searchByValue: searchState,

        setSearchByForInput,
        setSearchByValue,
        clearSearchBy
    };
};

export default useSearch;
