import React, { useCallback } from 'react';
import produce from 'immer';

import { toggleNestedRows, toggleStark } from '../../../Functions/utils';

import MainTableTr from './MainTableTr';

const MainTableBody = ({
    chapter,
    cols,
    rows,
    activeRow,
    coloredConfig,
    isVerticalBorders,
    isSharedReportPage,
    currencies,
    generalSettings,
    storage,
    onRowClick,
    onRowContextMenu,
    onChange,
    setRows
}) => {
    const MIN_PERCENTAGE = 15;

    const onCheckBoxChange = useCallback(
        item => {
            if (setRows) {
                setRows(
                    produce(draft => {
                        let target;

                        if (item.parentId) {
                            const index1 = draft.findIndex(el => el.value === item.parentId);

                            if (item.subParentId) {
                                const index2 = draft[index1].children.findIndex(el => el.value === item.subParentId);
                                target = draft[index1].children[index2].children.find(el => el.value === item.value);
                            } else {
                                target = draft[index1].children.find(el => el.value === item.value);
                            }
                        } else {
                            target = draft.find(el => el.value === item.value);
                        }

                        toggleNestedRows(target, !item.isChecked);

                        draft.forEach(row => {
                            if (row?.children?.length > 0) {
                                row.children.forEach(child => toggleStark(child));
                            }

                            toggleStark(row);
                        });
                    })
                );
            } else {
                // TODO: remove after migrate to rtk
                toggleNestedRows(item, !item.isChecked);

                rows.forEach(row => {
                    if (row?.children?.length > 0) {
                        row.children.forEach(child => toggleStark(child));
                    }
                    toggleStark(row);
                });

                onChange();
            }
        },
        [rows]
    );

    const handleTreeToggle = useCallback((e, item) => {
        e.stopPropagation();

        setRows(
            produce(draft => {
                let target;

                if (item.parentId) {
                    const index1 = draft.findIndex(el => el.value === item.parentId);
                    target = draft[index1].children.find(el => el.value === item.value);
                } else {
                    target = draft.find(el => el.value === item.value);
                }

                target.isOpened = !item.isOpened;
            })
        );
    }, []);

    const getRows = (rowsToRender, level = 0) => {
        return rowsToRender.map(row => {
            const getBgStyle = () => {
                const profit = row.statistic?.profit ?? row.profit;

                if (coloredConfig) {
                    const { positiveMin, positiveMax, negativeMin, negativeMax } = coloredConfig;
                    if (profit > 0) {
                        const difference = (positiveMax - positiveMin) / 100;
                        const opacityPercentage =
                            difference > 0
                                ? Math.floor((profit - positiveMin) / difference + MIN_PERCENTAGE) / 100
                                : 0.7;
                        return `rgba(187, 226, 198, ${opacityPercentage})`;
                    } else if (profit < 0) {
                        const difference = (Math.abs(negativeMin) - Math.abs(negativeMax)) / 100;
                        const opacityPercentage =
                            difference > 0
                                ? Math.floor((Math.abs(profit) - Math.abs(negativeMax)) / difference + MIN_PERCENTAGE) /
                                  100
                                : 0.7;
                        return `rgba(1255, 202, 214, ${opacityPercentage})`;
                    }
                }

                return null;
            };

            return (
                <React.Fragment key={row.value + level}>
                    <MainTableTr
                        chapter={chapter}
                        row={row}
                        cols={cols}
                        level={level}
                        bgStyle={getBgStyle()}
                        activeRow={activeRow}
                        isSharedReportPage={isSharedReportPage}
                        isVerticalBorders={isVerticalBorders}
                        currencies={currencies}
                        generalSettings={generalSettings}
                        storage={storage}
                        onRowClick={onRowClick}
                        onRowContextMenu={onRowContextMenu}
                        onCheckBoxChange={onCheckBoxChange}
                        handleTreeToggle={handleTreeToggle}
                        onChange={onChange}
                    />

                    {row?.isOpened && row?.children?.length > 0 && getRows(row.children, level + 1)}
                </React.Fragment>
            );
        });
    };

    return getRows(rows);
};

export default MainTableBody;
