import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import Langs from '../../Langs';
import { ENTITY_MODE_STATUS, INITIAL_SELECTED_METRICS } from '../../Constants';
import { getColumns } from '../../components/main-table/services';
import { getParams, clone, compare } from '../../Functions/utils';
import { getActualDateRange, getFiltersforRowsCheckboxes, showModal } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';
import checkNavigate from '../../Modules/Items/NewItem/check-navigate';

import useContextMenu from '../../Functions/hooks/useContextMenu';

import { useChangeStorageMutation } from '../../redux/api/storeApi';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../../redux/api/settingsApi';
import { useGetFlowsAllNamesQuery } from '../../redux/api/flowsApi';
import {
    useGetCampaignBidAlertQuery,
    useGetCampaignsChartQuery,
    useGetCampaignsListQuery
} from '../../redux/api/campaignsApi';
import { useGetDomainsByTypeQuery } from '../../redux/api/domainsApi';
import { useGetTrafficSourcesAllNamesQuery } from '../../redux/api/trafficSourcesApi';
import { useGetProjectInfoQuery } from '../../redux/api/projectApi';

import {
    changeMainTablePagination,
    changeMainTableSorting,
    selectCampaignsTableData,
    changeMainTableActiveRow,
    changeMainTableColumnFilters,
    changeMainTablePageStatus
} from '../../redux/main-table-slice';

import TableControls from './components/table-controls/table-controls';
import GeneralTable from '../../components/main-table/general-table';
import Charts from '../../Modules/Charts/Charts';
import NewItem from '../../Modules/Items/NewItem/NewItem';
import SyncCampaignCostModal from '../../Modules/Modals/sync-campaign-cost-modal/sync-campaign-cost-modal';

import './screen-campaigns.scss';

const ScreenCampaigns = ({ chapter, storage, reports, className, action, locationState, modalsInStore }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();
    const history = useHistory();

    const tableParameters = useSelector(selectCampaignsTableData);
    const { search, activeRow, sorting, pagination, columnFilters, chartStatus, pageStatus } = tableParameters;

    const [isLoading, setIsLoadig] = useState(true);
    const [rows, setRows] = useState([]);
    const [metrics, setMetrics] = useState(INITIAL_SELECTED_METRICS);
    const [contextMenuRef, contextMenuSettings, onMainTableBodyScroll, onRowContextMenu] = useContextMenu();

    const dateRange = useMemo(() => getActualDateRange(storage.common.dateRange), [storage]);
    const { displayMode } = storage[chapter];

    const parameters = {
        'filter[displayName]': search,
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        'filter[status]': displayMode,
        'filter[dateFrom]': exportDateTransformer(dateRange.start),
        'filter[dateTo]': exportDateTransformer(dateRange.end),
        ...(dateRange.compare
            ? {
                  [`filter[compareDateFrom]`]: exportDateTransformer(dateRange.startPrevDate),
                  [`filter[compareDateTo]`]: exportDateTransformer(dateRange.endPrevDate)
              }
            : {}),
        ...columnFilters.reduce((acc, el) => {
            return {
                ...acc,
                ...(el.minValue ? { [`filter[${el.key}Min]`]: +el.minValue } : {}),
                ...(el.maxValue ? { [`filter[${el.key}Max]`]: +el.maxValue } : {})
            };
        }, {})
    };

    const { data: projectInfo } = useGetProjectInfoQuery();
    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: trackingDomains, isSuccess: isTrackingDomainsLoaded } = useGetDomainsByTypeQuery('tracking');
    const { data: flowsAllNamesList, isSuccess: isFlowsAllNamesListLoaded } = useGetFlowsAllNamesQuery();
    const { data: bidAlertData, isSuccess: isBidAlertDataListLoaded } = useGetCampaignBidAlertQuery();
    const { data: trafficSourcesAllNamesList, isSuccess: isTrafficSourcesAllNamesListLoaded } =
        useGetTrafficSourcesAllNamesQuery();

    const campaignListRequestParams = getParams(parameters);

    const {
        data: campaignsList,
        isSuccess: isCampaignsListLoaded,
        isFetching: isCampaignsListFetching
    } = useGetCampaignsListQuery(campaignListRequestParams);

    useEffect(() => {
        if (
            !isCampaignsListLoaded ||
            !isTrackingDomainsLoaded ||
            !isTrafficSourcesAllNamesListLoaded ||
            !isFlowsAllNamesListLoaded ||
            !isBidAlertDataListLoaded
        )
            return;

        if (activeRow) {
            const row = campaignsList.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (campaignsList?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: campaignsList.pagination
                })
            );
        }

        const getSimilarCampaigns = (trafficSourceCampaignId, integrationId, itemId) => {
            const result = [];

            campaignsList.items.forEach(el => {
                if (
                    el.integration?.isActive &&
                    el.trafficSourceCampaignId === trafficSourceCampaignId &&
                    itemId !== el.value
                ) {
                    result.push(el.value);
                }
            });

            return result;
        };

        setRows(
            campaignsList.items.map(item => ({
                ...item,
                rotationName: item.flowId
                    ? `${txt.labels.flow}: ${flowsAllNamesList.find(el => el.value === item.flowId)?.label}`
                    : txt.labels.campaignSettings,
                domainName: trackingDomains?.find(el => el.id === item.domainId)?.domain?.name,
                trafficSourceName: trafficSourcesAllNamesList?.find(el => el.value === item.trafficSource)?.label,
                integration: {
                    ...item.integration,
                    similarCampaigns:
                        item.trafficSourceCampaignId && item.integration
                            ? getSimilarCampaigns(item.trafficSourceCampaignId, item.integration.id, item.value)
                            : [],
                    campaignListRequestParams,
                    bidAlertData: bidAlertData?.find(el => el.campaignId === item.value)
                }
            }))
        );

        if (isLoading) setIsLoadig(false);
    }, [campaignsList, trackingDomains, trafficSourcesAllNamesList, flowsAllNamesList, bidAlertData]);

    const selectedRowsIds = getFiltersforRowsCheckboxes(rows).join('&');

    const {
        data: campaignsChart,
        isSuccess: isCampaignsChartLoaded,
        isFetching: isCampaignsChartFetching
    } = useGetCampaignsChartQuery(
        selectedRowsIds ? campaignListRequestParams.concat('&', selectedRowsIds) : campaignListRequestParams,
        { skip: !chartStatus.isOpen }
    );

    useEffect(() => {
        if (isCampaignsListFetching && !isLoading) setIsLoadig(true);
        else if (isCampaignsListLoaded && !isCampaignsListFetching && isLoading) setIsLoadig(false);
    }, [isCampaignsListFetching]);

    useEffect(() => {
        // && rows.length > 0
        if (bidAlertData && bidAlertData.length > 0) {
            const isModalOpened = modalsInStore.find(el => el.id === 'syncCampaignCostModal');

            if (!isModalOpened && !action && displayMode !== 'archive') {
                showModal({
                    id: 'syncCampaignCostModal',
                    title: 'Action required',
                    body: props => <SyncCampaignCostModal {...props} bidAlertData={bidAlertData} />
                });
            }
        }
    }, [bidAlertData]);

    const integratedCampaignList = useMemo(() => {
        if (isCampaignsListLoaded) {
            return campaignsList.items
                .map(el => {
                    if (el?.trafficSourceCampaignId && el.integration?.isActive) return el.value;
                    return null;
                })
                .filter(el => el);
        } else return [];
    }, [campaignsList]);

    const columns = useMemo(
        () =>
            getColumns(chapter, storage, {
                includeIntegratedCampaignList: integratedCampaignList?.length && displayMode !== 'archive',
                isHideProfit: userSettings.isHideProfit,
                includeAdditionalRevenue: projectInfo.isAdditionalRevenue,
                includeCompareColumns: dateRange.compare
            }).orderedColumns,
        [chapter, storage, dateRange, integratedCampaignList]
    );

    const [changeStorage] = useChangeStorageMutation();

    const updateRows = useCallback(newRows => setRows(newRows), [rows]);

    const onRowClick = useCallback(
        row =>
            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: row.value === activeRow?.value ? null : row
                })
            ),
        [chapter, activeRow]
    );

    const onRowRightClick = useCallback(
        (e, row) => onRowContextMenu(e, row, activeRow, onRowClick),
        [activeRow, onRowClick, onRowContextMenu]
    );

    const onTableActionsChange = useCallback(
        (data, prop) => {
            switch (prop) {
                case 'sorting': {
                    dispatch(
                        changeMainTableSorting({
                            chapter,
                            value: data
                        })
                    );

                    dispatch(
                        changeMainTablePagination({
                            chapter,
                            value: { ...pagination, page: 1 }
                        })
                    );

                    break;
                }

                case 'columnWidths': {
                    const newStorage = clone(storage);
                    newStorage[chapter].columnWidths = data;
                    changeStorage(newStorage);

                    break;
                }

                case 'columnFilters': {
                    dispatch(
                        changeMainTableColumnFilters({
                            chapter,
                            value: data
                        })
                    );

                    dispatch(
                        changeMainTablePagination({
                            chapter,
                            value: { ...pagination, page: 1 }
                        })
                    );

                    break;
                }

                default:
                    throw new Error(`Invalid MainTable action, get "${prop}"`);
            }
        },
        [chapter, storage]
    );

    const updateDateRange = useCallback(
        newDateRange => {
            if (!compare(storage.common.dateRange, newDateRange)) {
                const newStorage = clone(storage);

                newStorage.common.dateRange = newDateRange;
                changeStorage(newStorage);

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
            }
        },
        [storage]
    );

    const changeDisplayMode = useCallback(
        value => {
            if (displayMode !== value) {
                const newStorage = clone(storage);

                newStorage[chapter].displayMode = value;
                changeStorage(newStorage);

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );

                if (activeRow) {
                    dispatch(
                        changeMainTableActiveRow({
                            chapter,
                            value: null
                        })
                    );
                }
            }
        },
        [displayMode, activeRow]
    );

    const setPageStatus = useCallback(value => dispatch(changeMainTablePageStatus({ chapter, value })), [chapter]);

    useEffect(() => {
        checkNavigate(chapter, action, pageStatus, locationState, setPageStatus, history);
    }, [action, pageStatus]);

    const isLoaded = !isLoading && isUserSettingsLoaded && isGeneralSettingsLoaded;

    const isEditMode = [ENTITY_MODE_STATUS.CREATE, ENTITY_MODE_STATUS.EDIT, ENTITY_MODE_STATUS.DUPLICATE].includes(
        pageStatus.mode
    );

    if (isEditMode) return <NewItem chapter={chapter} status={pageStatus} setStatus={value => setPageStatus(value)} />;

    return (
        <>
            <div className={cn('table-container screen-campaigns', className)}>
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            tableParameters={tableParameters}
                            dateRange={dateRange}
                            setDateRange={updateDateRange}
                            displayMode={displayMode}
                            changeDisplayMode={changeDisplayMode}
                            rows={rows}
                            columns={columns}
                            storage={storage}
                            reports={reports}
                            isAdditionalRevenue={projectInfo.isAdditionalRevenue}
                            generalSettings={generalSettings}
                            userSettings={userSettings}
                            contextMenuRef={contextMenuRef}
                            contextMenuSettings={contextMenuSettings}
                        />

                        {chartStatus.isOpen && (
                            <Charts
                                datasets={campaignsChart?.datasets}
                                labels={campaignsChart?.labels}
                                onChange={data => setMetrics(data)}
                                metrics={metrics}
                                isLoading={!isCampaignsChartLoaded || isCampaignsChartFetching}
                            />
                        )}

                        <div className="main-table__visible-content" onScroll={onMainTableBodyScroll}>
                            <GeneralTable
                                chapter={chapter}
                                tableParameters={tableParameters}
                                dateRange={dateRange}
                                rows={rows}
                                activeRow={activeRow}
                                columns={columns}
                                columnFilters={columnFilters}
                                total={campaignsList?.total?.statistic}
                                showTotalCheck={chartStatus.isOpen}
                                sorting={sorting}
                                isLoading={!isLoaded}
                                storage={storage}
                                generalSettings={generalSettings}
                                onRowClick={onRowClick}
                                onRowContextMenu={onRowRightClick}
                                onTableActionsChange={onTableActionsChange}
                                updateRows={updateRows}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScreenCampaigns;
