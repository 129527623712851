import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS } from '../../../Constants';
import { hideModal } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import useFocus from '../../../Functions/hooks/useFocus';

import { useGetUsersAllNamesOnlyRoleUsersQuery } from '../../../redux/api/usersApi';
// import { useGetDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useAddWorkspaceMutation, useEditWorkspaceMutation } from '../../../redux/api/workspacesApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import Loader from '../../Loader';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';

import './create-workspace-modal.scss';

const workspaceTemplate = {
    name: '',
    users: [],
    domains: []
};

const CreateWorkspaceModal = ({ onTouch, onClose, item, mode }) => {
    const txt = Langs[global.lng];
    const isEditMode = mode === ENTITY_MODE_STATUS.EDIT;

    const { data: userList, isSuccess: isUserListLoaded } = useGetUsersAllNamesOnlyRoleUsersQuery();

    // const { data: trackingDomains, isSuccess: isTrackingDomainsLoaded } = useGetDomainsByTypeQuery('tracking');
    // const { data: directDomains, isSuccess: isDirectDomainsLoaded } = useGetDomainsByTypeQuery('redirect');
    // const allDomains = trackingDomains?.concat(directDomains);

    const [isSubmitTouched, setIsSubmitTouched] = useState(false);

    const [workspace, setWorkspace] = useState(
        isEditMode ? { name: item.name, users: item.users, domains: item.domainsList } : workspaceTemplate
    );

    const isAllValid = workspace.name; // && workspace.domains.length;

    const [nameRef, setNameFocus] = useFocus();

    const onChange = useCallback((prop, value) => {
        onTouch();

        setWorkspace(
            produce(draft => {
                draft[prop] = value;
            })
        );
    }, []);

    useEffect(() => {
        if (isUserListLoaded && isEditMode) {
            onChange(
                'users',
                item.users.filter(el => userList?.find(it => it.value === el.id))
            );
        }
    }, [userList]);

    const [
        addWorkspace,
        {
            isLoading: isAddWorkspaceLoading,
            isSuccess: isAddWorkspaceSuccess,
            isError: isAddWorkspaceError,
            error: addWorkspaceError
        }
    ] = useAddWorkspaceMutation();

    useEffect(() => {
        if (isAddWorkspaceSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isAddWorkspaceSuccess]);

    useEffect(() => {
        if (isAddWorkspaceError) toast.error(getResponseErrorMessage(addWorkspaceError.data, txt.toasts.saveError));
    }, [isAddWorkspaceError]);

    const [
        editWorkspace,
        {
            isLoading: isEditWorkspaceLoading,
            isSuccess: isEditWorkspaceSuccess,
            isError: isEditWorkspaceError,
            error: editWorkspaceError
        }
    ] = useEditWorkspaceMutation();

    useEffect(() => {
        if (isEditWorkspaceSuccess) {
            toast.success(txt.toasts.saved);
            hideModal();
        }
    }, [isEditWorkspaceSuccess]);

    useEffect(() => {
        if (isEditWorkspaceError) toast.error(getResponseErrorMessage(editWorkspaceError.data, txt.toasts.saveError));
    }, [isEditWorkspaceError]);

    const onSubmit = () => {
        setIsSubmitTouched(true);

        if (isAllValid) {
            const payload = {
                ...(isEditMode ? { id: item.id } : {}),
                data: {
                    name: workspace.name,
                    userIds: workspace.users.map(el => el.id),
                    domainIds: workspace.domains.map(el => el.id)
                }
            };

            const request = isEditMode ? editWorkspace : addWorkspace;

            request(payload);
        } else {
            toast.error('Check required fields');
        }
    };

    const onSelectAllClick = (target, allList) => {
        let result = [];

        if (workspace[target].length < allList.length) {
            result = allList;
        }

        onChange(target, result);
    };

    const onCheckboxClick = (target, element, isIncluded) => {
        const result = [...workspace[target]];

        if (isIncluded) {
            const index = result.findIndex(el => el.id === element.id);
            if (index >= 0) result.splice(index, 1);
        } else {
            result.push(element);
        }

        onChange(target, result);
    };

    const isLoaded = isUserListLoaded; // && isTrackingDomainsLoaded && isDirectDomainsLoaded;

    useEffect(() => {
        if (isLoaded) setTimeout(setNameFocus, 0);
    }, [isLoaded]);

    // const filteredUsers = isEditMode ? item.users.filter(el => userList?.find(it => it.value === el.id)) : [];

    return (
        <>
            <div className="create-workspace-modal">
                <div className="create-workspace-modal__container">
                    {!isLoaded ? (
                        <Loader />
                    ) : (
                        <>
                            <TextInput
                                label={txt.labels.name}
                                ref={nameRef}
                                className="create-workspace-modal__name"
                                value={workspace.name}
                                onChange={({ target: { value } }) => onChange('name', value.trim())}
                                error={isSubmitTouched && !workspace.name && txt.errors.required('Name')}
                            />

                            <div className="create-workspace-modal__lists">
                                <div className="create-workspace-modal__block">
                                    <CheckBoxInput
                                        label="Users"
                                        className="create-workspace-modal__block-title"
                                        value={workspace.users.length === userList.length}
                                        isStark={workspace.users.length > 0 && workspace.users.length < userList.length}
                                        onChange={() =>
                                            onSelectAllClick(
                                                'users',
                                                userList.map(el => ({ id: el.value, name: el.label }))
                                            )
                                        }
                                    />

                                    <div className="create-workspace-modal__items">
                                        {userList.map(el => {
                                            const isIncluded = !!workspace.users.find(it => el.value === it.id);

                                            return (
                                                <CheckBoxInput
                                                    key={el.value}
                                                    label={el.label}
                                                    value={isIncluded}
                                                    className="create-workspace-modal__item"
                                                    onChange={() => {
                                                        onCheckboxClick(
                                                            'users',
                                                            { id: el.value, name: el.label },
                                                            isIncluded
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>

                                {/* <div className="create-workspace-modal__block">
                                    <CheckBoxInput
                                        label={
                                            <>
                                                Domains{' '}
                                                <span className="create-workspace-modal__block-title--info">
                                                    (required)
                                                </span>
                                            </>
                                        }
                                        className="create-workspace-modal__block-title"
                                        value={workspace.domains.length === allDomains.length}
                                        isStark={
                                            workspace.domains.length > 0 && workspace.domains.length < allDomains.length
                                        }
                                        onChange={() =>
                                            onSelectAllClick(
                                                'domains',
                                                allDomains.map(el => ({ id: el.id, mame: el.domain.name }))
                                            )
                                        }
                                    />

                                    <div className="create-workspace-modal__items">
                                        {allDomains.map(el => {
                                            const isIncluded = !!workspace.domains.find(it => el.id === it.id);

                                            return (
                                                <CheckBoxInput
                                                    key={el.id}
                                                    label={el.domain.name}
                                                    value={isIncluded}
                                                    className="create-workspace-modal__item"
                                                    onChange={() => {
                                                        onCheckboxClick(
                                                            'domains',
                                                            { id: el.id, mame: el.domain.name },
                                                            isIncluded
                                                        );
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                </div> */}
                            </div>

                            {(isAddWorkspaceLoading || isEditWorkspaceLoading) && <Loader isFetching />}
                        </>
                    )}
                </div>
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                onConfirm={onSubmit}
                isConfirmDisabled={isSubmitTouched && !isAllValid}
                onReject={onClose}
            />
        </>
    );
};

export default CreateWorkspaceModal;
