import React, { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
// import cn from 'classnames';

import Langs from '../../../../../Langs';
import { TRACKING_METHOD } from '../../../../../Constants';
import { getNotDangerouslyHTML } from '../../../../../Functions/utils';
import { AccordionTitle } from '../../../../Overall/Accordion/Accordion';
import Label from '../../../../Overall/Forms/Inputs/Label';
import Alert from '../../../../Overall/UI/Alert/Alert';
// import TdDate from '../../../../Overall/ItemsTable/TdTypes/TdDate';
// import SelectInput from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Icon from '../../../../Overall/Icon';
import Btn, { AddBtn } from '../../../../Overall/UI/Btn/Btn';
import Switcher from '../../../../Overall/UI/Switcher/Switcher';
import FlowPath from '../FlowPath/FlowPath';
import Rule from './Rule/Rule';
import FlowValueSelectInput from './FlowValueSelectInput/FlowValueSelectInput';
import Loader from '../../../../Loader';
import RadioInput from '../../../../Overall/Forms/Inputs/RadioInput';
import Path from '../FlowPath/Path/Path';
import ActionsGroup from '../../../../Overall/CriteriaDialog/components/ActionsGroup/ActionsGroup';
import { getHtmlTitle } from '../../../../Overall/CriteriaDialog/CriteriaDialog';

import ruleTemplate from '../../templates/rule-template';

import './flow-rules.scss';

const FlowRules = ({
    values,
    rules,
    onChange,
    trackingMethod,
    isDirectTracking,
    setTrackingMethod,
    trafficSourceValue,
    flowsList,
    flowValue,
    workspaceId,
    publicWorkspaceId,
    originalSettingsAt,
    type,
    mode,
    isFlowRuleProcessing,
    handleFlowChange,
    handleLoadOriginalSettings,
    activeRule,
    onRuleClick,
    campaignsCount,
    campaignsToAddCount,
    currentCampaignInfo,
    referrerDefaultHideType,
    landersList,
    offersList,
    affiliateNetworksList,
    trafficSourcesList,
    countries,
    currencies
}) => {
    const txt = Langs[global.lng];

    const { defaultRule, calculationMethod } = values;
    const [copiedData, setCopiedData] = useState(null);
    const ref = useRef();
    const flowRef = useRef(null);

    const [isProcessing, setIsProcessing] = useState(false);

    const trackingMethods = [
        { value: TRACKING_METHOD.REDIRECT, label: txt.labels.redirect, hint: txt.hints.redirect },
        { value: TRACKING_METHOD.DIRECT, label: txt.labels.direct, hint: txt.hints.direct }
    ];

    const trafficSource = useMemo(
        () => trafficSourcesList?.find(item => item.id === trafficSourceValue),
        [trafficSourcesList, trafficSourceValue]
    );

    const handleAddRule = () => {
        onChange(
            [
                ...rules,
                {
                    ...ruleTemplate,
                    value: uuid(),
                    isOpened: true
                }
            ],
            'rules'
        );
    };

    const handleRemoveRule = rule => {
        onChange([...rules.filter(item => item.value !== rule.value)], 'rules');
    };

    const handleMoveRule = (rule, isMoveUp) => {
        const newRulesList = [...rules];
        const ruleIndex = newRulesList.findIndex(item => rule.value === item.value);
        let newIndex;

        if (ruleIndex === 0) {
            newIndex = isMoveUp ? newRulesList.length - 1 : ruleIndex + 1;
        } else if (ruleIndex === newRulesList.length - 1) {
            newIndex = isMoveUp ? ruleIndex - 1 : 0;
        } else {
            newIndex = isMoveUp ? ruleIndex - 1 : ruleIndex + 1;
        }

        newRulesList.splice(newIndex, 0, newRulesList.splice(ruleIndex, 1)[0]);
        onChange(newRulesList, 'rules');
    };

    const handleCopyRules = storeRules => {
        const copiedRules = storeRules
            .filter(el => !el.isDeleted)
            .map(item => ({
                ...item,
                criteria: item.criteria.map(({ key, isEnabled, isMatched, isIncludedEmpty, value, items }) => ({
                    key,
                    isEnabled,
                    isMatched,
                    value,
                    items,
                    isIncludedEmpty
                }))
            }));
        localStorage.setItem('rules', JSON.stringify(copiedRules));
        setCopiedData(copiedRules);
    };

    const handlePasteRule = () => {
        if (!copiedData || !Array.isArray(copiedData)) {
            localStorage.removeItem('rules');
            setCopiedData(null);
            return;
        }

        onChange(
            [
                ...rules,
                ...copiedData.map(rule => ({
                    ...rule,
                    value: uuid(),
                    ...(trackingMethod?.value === TRACKING_METHOD.DIRECT
                        ? {
                              paths: rule.paths.map((r, idx) => ({
                                  ...r,
                                  isEnabled: idx === 0,
                                  landers: [],
                                  value: uuid()
                              }))
                          }
                        : {
                              paths: rule.paths.map(r => ({ ...r, value: uuid() }))
                          })
                }))
            ],
            'rules'
        );
    };

    const handleClearBuffer = () => {
        localStorage.removeItem('rules');
        setCopiedData(null);
    };

    const updateLocalStoreData = ({ newValue }) => setCopiedData(newValue);

    useEffect(() => {
        setCopiedData(JSON.parse(localStorage.getItem('rules')));
        window.addEventListener('storage', updateLocalStoreData, false);

        return () => {
            window.removeEventListener('storage', updateLocalStoreData, false);
        };
    }, []);

    const getTrackingMethodRadioButton = methods => {
        const { value: currentTrackingMethodValue, type: currentTrackingMethodType } = trackingMethod;

        if (campaignsCount || campaignsToAddCount) {
            return methods.map(el => ({
                ...el,
                disabled: el.value !== currentTrackingMethodValue && el.value !== currentTrackingMethodType
            }));
        }

        return methods;
    };

    const pathOptimizationValues = (() => {
        const pathOptimizationItems = [...rules, defaultRule];
        const pathOptimizationList = [...pathOptimizationItems.map(item => item.isPathOptimization)];
        const optimizationPathCount = pathOptimizationList.filter(item => item).length;
        const handlePathOptimizationChange = () => {
            onChange(null, [
                {
                    prop: 'defaultRule',
                    value: { ...defaultRule, isPathOptimization: !optimizationPathCount }
                },
                {
                    prop: 'rules',
                    value: rules.map(el => ({ ...el, isPathOptimization: !optimizationPathCount }))
                }
            ]);
        };

        return {
            pathsCount: pathOptimizationList.length,
            optimizationPathCount,
            isPathsOptimized: !!optimizationPathCount,
            handlePathOptimizationChange
        };
    })();

    const getAllRules = () => {
        const rulesContainer = flowRef.current.querySelector('.flow-rules__path-container .accordion-title');
        if (!rulesContainer) return [];

        const allRules = Array.from(rulesContainer.children);
        allRules.shift(); // remove accordion title

        return allRules;
    };

    const setStickableOffset = () => {
        const allRules = getAllRules();
        const index = allRules.findIndex(el => el.classList.contains('alert--active')) + 1;

        for (let i = index; i < allRules.length; i++) {
            allRules[i].querySelector('.stickable').classList.add('stickable--offset-sticky');
        }
    };

    const resetStickableOffset = () => {
        getAllRules().forEach(el => el.querySelector('.stickable').classList.remove('stickable--offset-sticky'));
    };

    const getActiveRuleElement = () =>
        flowRef.current.querySelector('.flow-rules__path-container .alert--active .alert');

    const getGhostElementContainer = () => flowRef.current.querySelector('.flow-rules__active-rule');

    const handleRuleScroll = () => {
        const element = getActiveRuleElement();

        if (!element) return;

        const { top: topBorder, bottom: bottomBorder } = flowRef.current.getBoundingClientRect();
        const { top: elementTopPosition, bottom: elementBottomPosition } = element.getBoundingClientRect();

        const ghostElementContainer = getGhostElementContainer();
        const ghostElement = ghostElementContainer.querySelector('.alert--active');

        if (elementBottomPosition > bottomBorder) {
            ghostElement.style.top = `calc(${bottomBorder - topBorder}px - 64px)`;
            ghostElementContainer.classList.add('flow-rules__active-rule--open');
        } else if (elementTopPosition < bottomBorder && elementTopPosition > topBorder) {
            ghostElementContainer.classList.remove('flow-rules__active-rule--open');
            resetStickableOffset();
        } else if (elementTopPosition < topBorder) {
            ghostElement.style.top = '-16px';
            ghostElementContainer.classList.add('flow-rules__active-rule--open');
            setStickableOffset();
        }
    };

    const onGhostRuleElementClick = () => getActiveRuleElement().parentElement.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (activeRule === null) {
            resetStickableOffset();
            getGhostElementContainer().classList.remove('flow-rules__active-rule--open');
        } else {
            handleRuleScroll();
        }
    }, [activeRule]);

    const transformedFlowsList = useMemo(
        () => [
            { label: 'Custom', value: 'custom', disabled: !!originalSettingsAt },
            ...flowsList
                .filter(el => el.workspaceId === workspaceId || el.workspaceId === publicWorkspaceId)
                .map(el => ({
                    value: el.id,
                    label: `${el.name}${el.trackingMethod === 'direct' ? ` (${txt.labels.directTracking})` : ''}`
                }))
        ],
        [flowsList, workspaceId, publicWorkspaceId, originalSettingsAt]
    );

    const onFlowPathChange = (data, prop) => {
        const newValues = { ...values.defaultRule, [prop]: data };
        onChange(newValues, 'defaultRule');
    };

    const onRuleByIdChange = (data, prop, ruleId) => {
        const index = rules.findIndex(el => el.value === ruleId);
        const newValues = [...rules];

        let formattedRule = { ...rules[index] };

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                formattedRule = { ...formattedRule, [el.prop]: el.value };
            });
        } else {
            formattedRule = { ...formattedRule, [prop]: data };
        }

        newValues.splice(index, 1, formattedRule);
        onChange(newValues, 'rules');
    };

    return (
        <div className="new-item__content-additional flow-rules" ref={flowRef} onScroll={handleRuleScroll}>
            <div className="flow-rules__active-rule">
                {activeRule ? (
                    <div className="flow-rules__active-rule-inner" onClick={onGhostRuleElementClick}>
                        <Alert
                            disabled={activeRule.rule.isDeleted}
                            type="warning"
                            isActive
                            onChange={() => {}}
                            LeftComponent={
                                <div
                                    className="criteria-dialog__title flow-rules__left-component"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            getNotDangerouslyHTML(activeRule.rule.name) ||
                                            getHtmlTitle(activeRule.rule.criteria, activeRule.rule.logicalRelation)
                                                ?.title ||
                                            txt.labels.newRule
                                    }}
                                />
                            }
                            RightComponent={
                                activeRule.rule.isDeleted ? (
                                    <div className="not-interactive">
                                        <ActionsGroup onUndo={() => {}} />
                                    </div>
                                ) : (
                                    <>
                                        <div className="rule__alert-action--arrows">
                                            <Btn dataTest="rule_arrowDown-btn" type="pure" disabled>
                                                <Icon.ArrowDown />
                                            </Btn>
                                            <Btn dataTest="rule_arrowUp-btn" type="pure" disabled>
                                                <Icon.ArrowUp />
                                            </Btn>
                                        </div>
                                        <div className="not-interactive">
                                            <ActionsGroup
                                                isSwitchOn={activeRule.rule.isRuleEnabled}
                                                onSwitch={() => {}}
                                                onCopy={() => {}}
                                                onDelete={() => {}}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            opened={false}
                        />
                    </div>
                ) : null}
            </div>

            {type !== 'flows' && (
                <div className="flow-rules__start-props">
                    {flowValue !== 'custom' && originalSettingsAt && mode === 'edit' ? (
                        <Alert
                            className="mt5 mb15 flows-dropdown-alert"
                            message={txt.alerts.campaignHasOriginalSettings(moment(originalSettingsAt).format('L LT'))}
                            RightComponent={
                                <span
                                    className="ml15 df no-wrap flows-dropdown-alert__button"
                                    onClick={handleLoadOriginalSettings}
                                >
                                    {txt.buttons.loadOriginalSettings}
                                </span>
                            }
                        />
                    ) : null}

                    <FlowValueSelectInput
                        values={values}
                        workspaceId={workspaceId}
                        flowsList={transformedFlowsList}
                        flowValue={flowValue}
                        onChange={onChange}
                        handleFlowChange={handleFlowChange}
                        setIsProcessing={setIsProcessing}
                    />
                </div>
            )}

            <div className="flow-rules__flows-container">
                <div className="mt20">
                    <div className="j4 mb10">
                        <span className="text-uppercase mr5 text-medium">{txt.labels.trackingMethod}</span>
                    </div>
                    <div className="tracking-radios">
                        <RadioInput
                            dataTest="flow-rules-tracking-method"
                            items={getTrackingMethodRadioButton(trackingMethods)}
                            value={trackingMethod?.value}
                            onChange={({ target: { value } }) => {
                                setTrackingMethod({
                                    value,
                                    type:
                                        value === TRACKING_METHOD.DIRECT
                                            ? trackingMethod.type || TRACKING_METHOD.LANDER_OFFERS
                                            : null
                                });
                            }}
                            type="row"
                            isHighlighted
                        />
                        {trackingMethod?.value === TRACKING_METHOD.DIRECT && (
                            <>
                                <Icon.ArrowRight size={16} color="#9AA0B9" />
                                <RadioInput
                                    items={[
                                        {
                                            value: TRACKING_METHOD.LANDER_OFFERS,
                                            label: txt.labels.landerOffers
                                        },
                                        {
                                            value: TRACKING_METHOD.OFFER,
                                            label: txt.labels.offer,
                                            hint: txt.hints.offerTrackingMethod
                                        }
                                    ]}
                                    value={trackingMethod?.type}
                                    onChange={({ target: { value } }) =>
                                        setTrackingMethod({
                                            ...trackingMethod,
                                            type: value
                                        })
                                    }
                                    type="row"
                                />
                            </>
                        )}
                    </div>
                    {trafficSource?.trackingOptions?.isDirectTracking && !isDirectTracking ? (
                        <Alert message={txt.jsx.errorOfMismatchOfDirectTracking(trafficSource)} type="danger" />
                    ) : null}
                    {campaignsCount || campaignsToAddCount ? (
                        <Alert message={txt.alerts.changeTrackingMethod} type="warning" />
                    ) : null}
                </div>

                {trackingMethod.type !== TRACKING_METHOD.OFFER && (
                    <>
                        <div className="mt20">
                            <Label
                                label={txt.labels.trafficDistributionAiPaths}
                                hint={txt.hints.trafficDistributionAiPaths}
                                className="flow-rules__title"
                            />
                            <div className="j46 flow-rules__optimization-path">
                                <Switcher
                                    dataTest="flow-rules_path-optimization"
                                    label={`${
                                        pathOptimizationValues.optimizationPathCount
                                            ? pathOptimizationValues.optimizationPathCount +
                                              '/' +
                                              pathOptimizationValues.pathsCount
                                            : txt.labels.no
                                    } ${txt.labels.setsPathsOptimized}`}
                                    isSwitchOn={pathOptimizationValues.isPathsOptimized}
                                    onChange={pathOptimizationValues.handlePathOptimizationChange}
                                />
                                {/* {pathOptimizationValues.isPathsOptimized && (
                                            <div className="j4">
                                                <Label
                                                    label={txt.labels.calculationMethod}
                                                    hint={txt.hints.calculationMethod}
                                                />
                                                <SelectInput
                                                    dataTest="flow-rules_calculation-method-select-input"
                                                    items={componentItems.calculateMethods}
                                                    value="auto"
                                                    onChange={() => ({})}
                                                    className="flow-rules__calculation-method"
                                                />
                                            </div>
                                        )} */}
                            </div>
                        </div>

                        <Label
                            // label={txt.labels.defaultRule}
                            // hint={txt.hints.defaultRule}
                            className="flow-rules__title flow-rules__line mt15 mb15"
                        />
                    </>
                )}

                {trackingMethod.value === TRACKING_METHOD.DIRECT && (
                    <Path
                        path={defaultRule.paths[0]}
                        onChange={onFlowPathChange}
                        onPathByIdChange={(data, prop) => {
                            const newValues = [...defaultRule.paths];
                            newValues.splice(0, 1, { ...defaultRule.paths[0], [prop]: data });
                            onFlowPathChange(newValues, 'paths');
                        }}
                        trackingMethod={trackingMethod}
                        workspaceId={workspaceId}
                        publicWorkspaceId={publicWorkspaceId}
                        isPathOutside
                        landersList={landersList}
                        offersList={offersList}
                        affiliateNetworksList={affiliateNetworksList}
                        countries={countries}
                        currencies={currencies}
                    />
                )}

                {(trackingMethod?.value === TRACKING_METHOD.REDIRECT ||
                    trackingMethod?.type === TRACKING_METHOD.LANDER_OFFERS) && (
                    <>
                        <FlowPath
                            paths={defaultRule.paths}
                            calculationMethod={calculationMethod}
                            referrerDefaultHideType={referrerDefaultHideType}
                            workspaceId={workspaceId}
                            publicWorkspaceId={publicWorkspaceId}
                            onChange={onFlowPathChange}
                            isPathOptimization={defaultRule.isPathOptimization}
                            isRuleOptimizedData={defaultRule.isRuleOptimizedData}
                            trackingMethod={trackingMethod}
                            currentCampaignInfo={currentCampaignInfo}
                            lastOptimizationAt={defaultRule.lastOptimizationAt}
                            optimizationSettings={defaultRule.optimizationSettings}
                            entityType={type}
                            landersList={landersList}
                            offersList={offersList}
                            affiliateNetworksList={affiliateNetworksList}
                            countries={countries}
                            currencies={currencies}
                        />

                        <div className="j46 mt25 mb8">
                            <div className="j4">
                                <Label
                                    label={txt.labels.ruleBasedPaths}
                                    hint={txt.hints.ruleBasedPaths}
                                    className="flow-rules__title flow-rules__title--orange"
                                />
                                {rules?.length > 0 && (
                                    <Btn
                                        dataTest="flow-rules_copy-btn"
                                        type="pure"
                                        className="ml5"
                                        onClick={() => handleCopyRules(rules)}
                                    >
                                        <Icon.Copy />
                                    </Btn>
                                )}
                            </div>
                            <div className="j4">
                                <Btn
                                    dataTest="flow-rules_paste-rule-btn"
                                    type="filled"
                                    className="btn--short"
                                    disabled={!copiedData}
                                    onClick={handlePasteRule}
                                >
                                    {txt.labels.pasteRule}
                                </Btn>
                                <Btn
                                    dataTest="flow-rules_clear-buffer-btn"
                                    className="ml8 btn--short"
                                    disabled={!copiedData}
                                    onClick={handleClearBuffer}
                                >
                                    {txt.labels.clearBuffer}
                                </Btn>
                            </div>
                        </div>

                        <div className="flow-rules__path-container">
                            <AccordionTitle label={txt.labels.rules} type="yellow">
                                {rules.map((rule, index) => (
                                    <Rule
                                        index={index}
                                        ref={{ ...ref }}
                                        isActive={activeRule?.rule === rule}
                                        key={rule.value}
                                        rule={rule}
                                        defaultPaths={defaultRule?.paths}
                                        trackingMethod={trackingMethod}
                                        workspaceId={workspaceId}
                                        publicWorkspaceId={publicWorkspaceId}
                                        onRuleClick={onRuleClick}
                                        onRemoveRule={() => handleRemoveRule(rule)}
                                        onMoveRule={handleMoveRule}
                                        onChange={onRuleByIdChange}
                                        onCopyRule={() => handleCopyRules([rule])}
                                        referrerDefaultHideType={referrerDefaultHideType}
                                        entityType={type}
                                        landersList={landersList}
                                        offersList={offersList}
                                        affiliateNetworksList={affiliateNetworksList}
                                        countries={countries}
                                        currencies={currencies}
                                    />
                                ))}
                            </AccordionTitle>
                        </div>

                        <AddBtn dataTest="flow-rules_rule-btn" className="mt8" type="filled" onClick={handleAddRule}>
                            {txt.labels.rule}
                        </AddBtn>
                    </>
                )}
            </div>

            {(isFlowRuleProcessing || isProcessing) && <Loader isFetching />}
        </div>
    );
};

export default FlowRules;
