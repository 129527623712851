import React from 'react';
import { useHistory } from 'react-router-dom';

import Langs from '../../Langs';
import { camelToKebabCase } from '../../Functions/utils';

import CloudOffIcon from '../../../../media/images/cloud-off.svg';

import './no-data.scss';

const NoData = ({ chapter, isNew }) => {
    const txt = Langs[global.lng];

    const history = useHistory();

    return (
        <div className="no-data">
            {isNew ? (
                <div className="no-data__button" onClick={() => history.push(`/home/${camelToKebabCase(chapter)}/new`)}>
                    Create new {txt.singulars[chapter] || ''}
                </div>
            ) : (
                <div className="no-data__inner">
                    <img src={CloudOffIcon} className="mr15" /> No data found
                </div>
            )}
        </div>
    );
};

export default NoData;
