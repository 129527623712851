import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Pause, Play } from 'react-feather';

import { toast } from 'react-toastify';
import Langs from '../../../../../../Langs';
import { getResponseErrorMessage } from '../../../../../../Functions/utils';

import { useChangeIntegrationCampaignStatusMutation } from '../../../../../../redux/api/integrationsApi';

import Btn from '../../../../../Overall/UI/Btn/Btn';
import Loader from '../../../../../Loader';

const TdIntegrationAction = React.memo(
    ({
        trafficSourceId,
        trafficSourceCampaignId,
        integrationStatus,
        disabled,
        rowId,
        similarCampaigns,
        campaignListRequestParams
    }) => {
        const txt = Langs[global.lng];

        const [isProcessing, setIsProcessing] = useState(false);
        const [status, setStatus] = useState(integrationStatus);

        useEffect(() => setStatus(integrationStatus), [integrationStatus]);

        const [
            changeStatus,
            { isError: isChangeStatusError, error: changeStatusError, isSuccess: isChangeStatusSuccess }
        ] = useChangeIntegrationCampaignStatusMutation();

        useEffect(() => {
            if (isChangeStatusSuccess) {
                toast.success(txt.toasts.saved);
                setIsProcessing(false);
            }
        }, [isChangeStatusSuccess]);

        useEffect(() => {
            if (isChangeStatusError) {
                toast.error(getResponseErrorMessage(changeStatusError.data, txt.toasts.saveError));
                setIsProcessing(false);
            }
        }, [isChangeStatusError]);

        const onStatusChange = e => {
            e.preventDefault();
            e.stopPropagation();

            setIsProcessing(true);

            changeStatus({
                status: status ? 'pause' : 'run',
                data: { trafficSourceId, trafficSourceCampaignId, campaignId: rowId },
                rowId,
                similarCampaigns,
                campaignListRequestParams
            });
        };

        return (
            <div className={cn('j4 td-integration', { 'td-integration--disabled': disabled })}>
                <Btn className="ml4" type="clear-link" disabled={disabled || isProcessing} onClick={onStatusChange}>
                    {status ? <Pause size={15} stroke="#f06d8d" /> : <Play size={15} stroke="#00b031" />}
                    {status ? txt.buttons.pause : txt.buttons.run}
                </Btn>

                {isProcessing && <Loader type="cell" isFetching />}
            </div>
        );
    }
);

TdIntegrationAction.displayName = 'TdIntegrationAction';
export default TdIntegrationAction;
