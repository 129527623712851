import React from 'react';

import { topMenuConstructor } from '../../../Modules/Overall/TopMenu/constructors/topMenuConstructor';
import Icon from '../../../Modules/Overall/Icon';

const ButtonIcon = React.memo(({ type, size, color, className }) => {
    const { mainMenu } = topMenuConstructor;

    if (!mainMenu.map(el => el.label).includes(type)) return null;

    const CurrentIcon = Icon[mainMenu.find(el => el.label === type).icon];

    return <CurrentIcon size={size} color={color} className={className} />;
});

export default ButtonIcon;
