import React from 'react';
import Icon from '../../Icon';
import Tooltip from '../../UI/Tooltip/Tooltip';

const HintIcon = ({ hint, type, className, iconType }) => (
    <Tooltip message={hint} type={type} className={className}>
        {iconType === 'alert' ? (
            <Icon.AlertTriangle size={16} className="tooltip__icon--alert" />
        ) : (
            <Icon.HelpCircle size={17} className="tooltip__icon--hint" />
        )}
    </Tooltip>
);

export default React.memo(HintIcon);
