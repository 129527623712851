import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { fixRegexPattern, getParams } from '../../../Functions/utils';
import { getActualDateRange } from '../../../redux/operations';

import indexApi from '../../../redux/api/indexApi';
import { useGetConversionLogQuery, conversionsApiTagTypes } from '../../../redux/api/conversionsApi';
import { exportDateTransformer } from '../../../redux/transformers';

import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Alert from '../../Overall/UI/Alert/Alert';
import Loader from '../../Loader';
import DateRangeInput from '../../Overall/Forms/Inputs/DateRangeInput';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import Btn from '../../Overall/UI/Btn/Btn';
import Icon from '../../Overall/Icon';
import TdDate, { TdDateTypes } from '../../Overall/ItemsTable/TdTypes/TdDate';

import './conversions-logs-modal.scss';

const ConversionsLogsModal = ({ onClose, clickId, initialDateRange }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');
    const [type, setType] = useState('all');
    const [dateRange, setDateRange] = useState(getActualDateRange(initialDateRange));

    const parameters = {
        'filter[dateFrom]': exportDateTransformer(dateRange.start),
        'filter[dateTo]': exportDateTransformer(dateRange.end)
    };

    const {
        data: conversionLogsList,
        isSuccess: isConversionLogsListLoaded,
        isFetching: isConversionLogsListFetching
    } = useGetConversionLogQuery({
        id: clickId,
        params: getParams(parameters)
    });

    const filteredData = useMemo(() => {
        if (!isConversionLogsListLoaded) return [];

        const regexExpr = new RegExp(fixRegexPattern(searchValue.trim()), 'i');

        return conversionLogsList
            .filter(el => (type === 'all' ? true : el.logType === type.toUpperCase()))
            .filter(el => (searchValue.trim() !== '' ? regexExpr.test(el.id) : true));
    }, [conversionLogsList, type, searchValue]);

    return (
        <>
            <div className="conversions-logs-wrapper">
                <div className="conversions-logs">
                    <div className="conversions-logs__filter">
                        <TextInput
                            placeholder="Search"
                            className="conversions-logs-search"
                            disabled={isConversionLogsListFetching}
                            value={searchValue}
                            onChange={({ target: { value } }) => setSearchValue(value)}
                            reset
                        />

                        <RadioInput
                            type="row"
                            value={type}
                            items={[
                                { value: 'all', label: 'All types' },
                                { value: 'in', label: 'IN' },
                                { value: 'out', label: 'OUT' }
                            ]}
                            onChange={({ target: { value } }) => setType(value)}
                            className="conversions-radios"
                            disabled={isConversionLogsListFetching}
                        />

                        <DateRangeInput
                            value={dateRange}
                            onChange={date => setDateRange(getActualDateRange(date))}
                            isUseAbs
                            disabled={isConversionLogsListFetching}
                            isCompareHidden
                        />

                        <Btn
                            type="success"
                            onClick={() => {
                                dispatch(
                                    indexApi.util.invalidateTags([
                                        { type: conversionsApiTagTypes.conversionsLog, id: clickId }
                                    ])
                                );
                            }}
                            disabled={isConversionLogsListFetching}
                        >
                            <Icon.RefreshCw />
                            {txt.buttons.refresh}
                        </Btn>
                    </div>

                    <div className="conversions-logs__header">
                        <div className="logs-row">
                            <div className="logs-row__arrow" />
                            <div className="logs-row__id">Conversion ID</div>
                            <div className="logs-row__url">URL</div>
                            <div className="logs-row__date">Log Time</div>
                            <div className="logs-row__ip">IP</div>
                        </div>
                    </div>

                    {isConversionLogsListFetching ? (
                        <Loader />
                    ) : (
                        <div className="conversions-logs__content">
                            {filteredData.map(item => (
                                <Alert
                                    key={item.id}
                                    type="transparent"
                                    LeftComponent={
                                        <div className="logs-row">
                                            <div className="logs-row__arrow">
                                                {item.logType === 'OUT' ? (
                                                    <Icon.ArrowUp color="#F06D8D" />
                                                ) : (
                                                    <Icon.ArrowDown color="#00B031" />
                                                )}
                                            </div>

                                            <div className="logs-row__id">{item.id}</div>

                                            <div className="logs-row__url">
                                                <div>{item.url}</div>
                                            </div>

                                            <div className="logs-row__date">
                                                <TdDate type={TdDateTypes.COLUMN} item={item.logTime} />
                                            </div>

                                            <div className="logs-row__ip">{item.ipAddress}</div>
                                        </div>
                                    }
                                    onChange={() => {}}
                                >
                                    <div className="conversions-logs-alert-content">
                                        <pre>{JSON.stringify(item, null, 2)}</pre>
                                    </div>
                                </Alert>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <ModalButtonBar rejectButtonText={txt.buttons.cancel} onReject={onClose} />
        </>
    );
};

export default ConversionsLogsModal;
