import indexApi, { RESET_TAG, RESET_TAG_TYPE } from './indexApi';
import { transform } from '../transformers';

export const landersApiTagTypes = {
    landers: 'landers',
    landersUrlParams: 'landersUrlParams'
};

const landersApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(landersApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getLandersList: builder.query({
            query: params => `/landers${params}`,
            keepUnusedDataFor: 0,
            transformResponse: response => ({
                items: response.items.map(el => transform.landers.importList(el)),
                total: response.total,
                pagination: response.pagination
            }),
            providesTags: () => [
                { type: landersApiTagTypes.landers, id: 'LIST' },
                { type: landersApiTagTypes.landers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getLanderById: builder.query({
            query: id => `/landers/${id}`,
            transformResponse: response => transform.landers.import(response),
            providesTags: (result, error, id) => [
                { type: landersApiTagTypes.landers, id },
                { type: landersApiTagTypes.landers, id: 'ALL_ITEMS' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getLandersAllNames: builder.query({
            query: () => `/landers/all/names`,
            transformResponse: response => response.map(el => transform.itemsListToSelect.import(el)),
            providesTags: () => [
                { type: landersApiTagTypes.landers, id: 'ALL_NAMES_LIST' },
                { type: landersApiTagTypes.landers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getLandersWithColumns: builder.query({
            query: params => `/landers/all/with-columns${params}`,
            providesTags: () => [
                { type: landersApiTagTypes.landers, id: 'WITH_COLUMNS_LIST' },
                { type: landersApiTagTypes.landers, id: 'ALL' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.SOFT },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getLandersChart: builder.query({
            query: params => `/landers/all/chart${params}`,
            providesTags: () => [
                { type: landersApiTagTypes.landers, id: 'CHART' },
                { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }
            ]
        }),

        getLandersUrlParams: builder.query({
            query: () => `/landers/dictionaries/url-params`,
            providesTags: () => [landersApiTagTypes.landersUrlParams, { type: RESET_TAG, id: RESET_TAG_TYPE.HARD }],
            keepUnusedDataFor: 86400 //24h
        }),

        deleteLander: builder.mutation({
            query: ({ id }) => ({
                url: `/landers/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: landersApiTagTypes.landers, id },
                        { type: landersApiTagTypes.landers, id: 'ALL' },
                        { type: landersApiTagTypes.landers, id: 'CHART' }
                    ];
                else return [];
            }
        }),

        restoreLander: builder.mutation({
            query: ({ id }) => ({
                url: `/landers/${id}/restore`,
                method: 'PATCH'
            }),
            invalidatesTags: (result, error, { id }) => {
                if (!error)
                    return [
                        { type: landersApiTagTypes.landers, id },
                        { type: landersApiTagTypes.landers, id: 'ALL' },
                        { type: landersApiTagTypes.landers, id: 'CHART' }
                    ];
                else return [];
            }
        }),

        checkLanderVerify: builder.mutation({
            query: ({ data }) => ({
                url: `/landers/verify`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const {
    useGetLandersListQuery,
    useGetLanderByIdQuery,
    useGetLandersAllNamesQuery,
    useGetLandersWithColumnsQuery,
    useGetLandersChartQuery,
    useGetLandersUrlParamsQuery,
    useDeleteLanderMutation,
    useRestoreLanderMutation,
    useCheckLanderVerifyMutation
} = landersApi;
