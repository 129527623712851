import React from 'react';
import cn from 'classnames';

export const TdText = props => {
    const { item, columnOptions, disabled } = props;
    if (!item && item !== 0) return null;

    let text;

    if (typeof item !== 'object') {
        text = item;
    } else {
        text = item.value;
    }

    return (
        <span
            className={cn(columnOptions.summaryClassName, item.className, {
                'td-text--disabled': disabled
            })}
        >
            {text}
        </span>
    );
};
