import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import Langs from '../../../Langs';
import { MODAL_TYPES } from '../../../Constants';
import { getMoneyIntlFormattedValue } from '../../../redux/operations';
import { getIntlFormattedValue } from '../../../Functions/utils';

import { useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetPaymentMethodListQuery } from '../../../redux/api/paymentApi';
import { useGetPlansQuery } from '../../../redux/api/plansApi';
import { useGetProjectCurrentPlanQuery, useGetProjectPlanTransitionInfoQuery } from '../../../redux/api/projectApi';

import Btn from '../../Overall/UI/Btn/Btn';
import Plan from '../choose-plan-modal/components/plan/plan';
import AddBillingInfoModal from '../add-billing-info-modal/add-billing-info-modal';
import ChangePlanPaymentComponent from '../change-plan-modal/components/change-plan-payment-component';
import Notice, { NOTICE_TYPES } from '../../Overall/UI/Notice/Notice';
import Loader from '../../Loader';

const RenewPlanModal = ({ modalType, invoice, onClose, onBackToPlans, planId, isEmptyInvoiceDetails, isLoading }) => {
    const txt = Langs[global.lng];

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: currentPlan, isSuccess: isCurrentPlanLoaded } = useGetProjectCurrentPlanQuery();
    const { data: planList, isSuccess: isPlanListLoaded } = useGetPlansQuery();
    const { data: paymentMethodList, isSuccess: isPaymentMethodListLoaded } = useGetPaymentMethodListQuery();

    const { data: transition, isSuccess: isTransitionLoaded } = useGetProjectPlanTransitionInfoQuery(
        planId ?? currentPlan?.id,
        { skip: !planId && !currentPlan?.id }
    );

    const plan = planId ? planList?.find(el => el.id === planId) : currentPlan;

    const [isEditBilling, setIsEditBilling] = useState(isEmptyInvoiceDetails);
    const [activePaymentMethod, setActivePaymentMethod] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => setIsEditBilling(isEmptyInvoiceDetails), [isEmptyInvoiceDetails]);

    useEffect(() => {
        if (isPaymentMethodListLoaded) setActivePaymentMethod(paymentMethodList.find(el => el.isDefault));
    }, [paymentMethodList]);

    const onCloseModal = () => {
        toast.success(txt.toasts.planUpgrade);
        if (window.tidioChatApi) window.tidioChatApi.addVisitorTags([transition.nextPlan.name]);
        onClose(null, true);
    };

    const confirmInvoiceDetails = isOk => {
        if (isOk) setIsEditBilling(false);
    };

    const isLoaded =
        isUserSettingsLoaded &&
        isCurrenciesLoaded &&
        isCurrentPlanLoaded &&
        isPlanListLoaded &&
        isPaymentMethodListLoaded &&
        isTransitionLoaded &&
        !isLoading;

    return (
        <div className={cn('chage-plan-modal chage-plan-modal__inner')}>
            <div className="chage-plan-modal__header">
                <span className="chage-plan-modal__modal-title">{txt.labels.renewPlan}</span>
            </div>

            {isLoaded && (
                <div className="chage-plan-modal__blocking-alert-block">
                    <Notice type={NOTICE_TYPES.DANGER}>
                        {modalType === MODAL_TYPES.BLOCKING
                            ? txt.labels.billing.blockAccount(
                                  moment(invoice.blockDate).format('MMMM DD, YYYY'),
                                  moment(invoice.blockDate).format('DD') - moment(invoice.period.dateFrom).format('DD')
                              )
                            : txt.alerts.renewPlanAlert}
                    </Notice>
                </div>
            )}

            <div className={cn('chage-plan-modal__body chage-plan-modal__body_next-step')}>
                {!isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <div className="chage-plan-modal__selectable-plan">
                            <Plan plan={plan} buttonText={txt.buttons.backToPlans} onClick={onBackToPlans} isBlocking />
                        </div>

                        <div className="chage-plan-modal__details-container">
                            {isEditBilling ? (
                                <AddBillingInfoModal
                                    className="chage-plan-modal__billing-info"
                                    isIntegrated
                                    handleConfirmation={confirmInvoiceDetails}
                                />
                            ) : (
                                <>
                                    <div className="chage-plan-modal__description-block">
                                        {invoice?.overAmount > 0 && (
                                            <div className="chage-plan-modal__description-block-plan">
                                                <div>
                                                    <span>
                                                        {txt.titles.overageEvents(
                                                            getIntlFormattedValue(invoice?.overEvents)
                                                        )}
                                                    </span>
                                                    <span>
                                                        {getMoneyIntlFormattedValue(
                                                            invoice.overAmount,
                                                            invoice.currency,
                                                            currencies
                                                        )}
                                                    </span>
                                                </div>
                                                <span>
                                                    {txt.texts.billingPeriod(
                                                        moment(invoice.overPeriod.dateFrom).format('ll'),
                                                        moment(invoice.overPeriod.dateTo).format('ll'),
                                                        invoice.overUnitCost
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        <div className="chage-plan-modal__description-block-plan">
                                            <div>
                                                <span>{plan.name}</span>
                                                <span>
                                                    {getMoneyIntlFormattedValue(
                                                        plan.monthlyPayment,
                                                        plan.paymentCurrency,
                                                        currencies
                                                    )}
                                                </span>
                                            </div>
                                            <span>
                                                {txt.texts.billingPeriod(
                                                    moment(invoice.period.dateFrom).format('ll'),
                                                    moment(invoice.period.dateTo).format('ll')
                                                )}
                                            </span>
                                        </div>
                                        <div className="chage-plan-modal__description-block-change-subscription">
                                            <Btn
                                                onClick={onBackToPlans}
                                                type="link"
                                                className="chage-plan-modal__description-block-change-subscription-button"
                                                disabled={isLoading}
                                            >
                                                {txt.buttons.canChangeSubscription}
                                            </Btn>
                                        </div>
                                        <div className="chage-plan-modal__description-block-separator" />
                                        <div className="chage-plan-modal__description-block-amount">
                                            <div>
                                                <span>
                                                    <b>{txt.texts.paymentAmountToday}</b>
                                                </span>
                                                <span>
                                                    <b>
                                                        {getMoneyIntlFormattedValue(
                                                            +plan.monthlyPayment + +invoice.overAmount,
                                                            plan.paymentCurrency,
                                                            currencies
                                                        )}
                                                    </b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <ChangePlanPaymentComponent
                                        className="chage-plan-modal__payment-modal"
                                        paymentMethodList={paymentMethodList}
                                        nextPlanId={plan.id}
                                        onConfirm={onCloseModal}
                                        amountForButton={getMoneyIntlFormattedValue(
                                            +plan.monthlyPayment + +invoice.overAmount,
                                            plan.paymentCurrency,
                                            currencies
                                        )}
                                        backToInvoiceDetails={() => setIsEditBilling(true)}
                                        failedAutoPaymentMethodId={invoice?.paymentMethodId}
                                        activePaymentMethod={activePaymentMethod}
                                        setActivePaymentMethod={setActivePaymentMethod}
                                        setIsProcessing={setIsProcessing}
                                        userEmail={userSettings?.email}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}

                {isProcessing && <Loader isFetching />}
            </div>
        </div>
    );
};

export default RenewPlanModal;
