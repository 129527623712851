const offerTemplate = {
    value: null,
    id: null,
    workspaceId: '',
    tags: [],
    countryTag: 'GO',
    affiliateNetworkId: null,
    name: '',
    offerUrl: 'https://',

    payoutType: 'auto',
    payoutCurrency: 'USD',
    payout: null,
    conversionUrlFormat: 's2s_postback_url',

    isCapEnabled: false,
    capType: 'conversion',
    redirectOffer: null,
    timeZone: null,
    conversionCap: null,
    clickCap: null,

    isReserved: false,
    createdAt: null,
    updatedAt: null,
    createdAtUserId: null,
    updatedAtUserId: null,
    isRestore: false,
    isArchived: false
};

export default offerTemplate;
