import { useCallback, useState, useEffect, useRef } from 'react';

const useDelayedInput = (fn, initialText, timeout = 500) => {
    const [inputText, setInputText] = useState(initialText);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const onInputTextChange = useCallback(({ target: { value } }) => setInputText(value), []);
    const inputRef = useRef(null);

    useEffect(() => {
        let timeoutId;

        if (inputText !== undefined && (!isFirstRender || inputText !== '')) {
            timeoutId = setTimeout(() => fn(inputText), timeout);
            setIsFirstRender(false);
        }

        return () => clearTimeout(timeoutId);
    }, [inputText]);

    return [inputText, onInputTextChange, inputRef];
};

export default useDelayedInput;
