import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getActualDateRange, hideModal } from '../../../redux/operations';
import { checkPositiveFloatNumber, getReplacedString, getResponseErrorMessage } from '../../../Functions/utils';
import { exportDateTransformer } from '../../../redux/transformers';

import useFocus from '../../../Functions/hooks/useFocus';

import { useGetGeneralSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetTimezonesQuery, useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useUpdateCampaignCostMutation } from '../../../redux/api/campaignsApi';

import Loader from '../../Loader';
import DateRangeInput from '../../Overall/Forms/Inputs/DateRangeInput';
import RadioInput from '../../Overall/Forms/Inputs/RadioInput';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import JustAlert, { JUST_ALERT_TYPE } from '../../../components/just-alert/just-alert';

const ManualCostUpdateModal = ({ item, onClose }) => {
    // FIXME: нужно рефакторить всё
    const txt = Langs[global.lng];
    const TIME_RANGE = 30;

    const { data: generalSettings } = useGetGeneralSettingsQuery();
    const { data: timezones, isSuccess: isTimezonesLoaded } = useGetTimezonesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();

    const [costRadio, setCostRadio] = useState('restore');
    const [costNumber, setCostNumber] = useState(null);
    const [currency, setCurrency] = useState(generalSettings?.defaultCurrency);
    const [timezone, setTimezone] = useState(generalSettings?.reportTimeZone);
    const [timeRange, setTimeRange] = useState(getActualDateRange({ intervalKey: 'today' }));

    const [costNumberInputRef, setCostNumberFocus] = useFocus();

    useEffect(() => {
        if (!costNumber) setTimeout(setCostNumberFocus, 0);
    }, []);

    const [
        updateCampaignCost,
        {
            isLoading: isUpdateCampaignCostLoading,
            isSuccess: isUpdateCampaignCostSuccess,
            isError: isUpdateCampaignCostError,
            error: updateCampaignCostError
        }
    ] = useUpdateCampaignCostMutation();

    useEffect(() => {
        if (isUpdateCampaignCostSuccess) {
            hideModal();
            toast.success(txt.toasts.updateCostStarted);
        }
    }, [isUpdateCampaignCostSuccess]);

    useEffect(() => {
        if (isUpdateCampaignCostError) {
            hideModal();
            toast.error(getResponseErrorMessage(updateCampaignCostError.data, txt.errors.saveError));
        }
    }, [isUpdateCampaignCostError]);

    const handleConfirm = () => {
        const dateRange = getActualDateRange(timeRange);

        updateCampaignCost({
            id: item.value,
            data: {
                dateFrom: exportDateTransformer(dateRange.start),
                dateTo: exportDateTransformer(dateRange.end),
                costAction: costRadio,
                cost: Number(costNumber),
                costCurrency: currency,
                timezone
            }
        });
    };

    const isDataLoaded = isTimezonesLoaded && isCurrenciesLoaded;

    return (
        <>
            <div className="manualCostUpdate">
                {!isDataLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <JustAlert type={JUST_ALERT_TYPE.INFO}>{txt.jsx.manualCostUpdate(TIME_RANGE)}</JustAlert>

                        <div>
                            <span className="strong mr10">{txt.titles.campaignName}:</span>
                            <span>{item.name}</span>
                        </div>

                        <DateRangeInput
                            label={txt.labels.timeRange}
                            hint={txt.hints.timeRange}
                            value={timeRange}
                            onChange={e => setTimeRange(e)}
                            limitRange={TIME_RANGE}
                            isIntervalsHidden
                            isCompareHidden
                            hideArrows
                        />

                        <SelectInput
                            dataTest="manual-cost-update_timezone-select-input"
                            label={txt.labels.timeZone}
                            hint={txt.hints.timeZone}
                            items={timezones}
                            value={timezone}
                            onChange={({ target: { value } }) => setTimezone(value)}
                            isSearchable
                        />

                        <div className="mb15 j1">
                            <RadioInput
                                dataTest="mannual-cost-update_cost"
                                type="row"
                                value={costRadio}
                                items={['restore', 'update']}
                                label={txt.labels.cost}
                                hint={txt.hints.cost}
                                onChange={({ target: { value } }) => setCostRadio(value)}
                            />

                            <TextInput
                                dataTest="manual-cost-update_count-input"
                                value={getReplacedString(costNumber, '[^\\d.]')}
                                className="changeRoutingModal-count"
                                ref={costNumberInputRef}
                                onChange={({ target: { value } }) => setCostNumber(value)}
                                checkCorrectSymbol={checkPositiveFloatNumber}
                            />

                            <SelectInput
                                dataTest="manual-cost-update_currency-select-input"
                                className="manualCostUpdate-count"
                                items={currencies}
                                value={currency}
                                onChange={({ target: { value } }) => setCurrency(value)}
                                isSearchable
                            />
                        </div>

                        {isUpdateCampaignCostLoading && <Loader isFetching />}
                    </>
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                onConfirm={handleConfirm}
                onReject={onClose}
            />
        </>
    );
};

export default ManualCostUpdateModal;
