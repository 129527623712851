import React from 'react';

import { showModal } from '../../redux/operations';

import ArchiveResultModal from '../../Modules/Modals/ArchiveResultModal';

const useQueryResultModal = () => {
    const showQueryResultModal = (entity, archiveResultData, title) => {
        showModal({
            title,
            body: props => <ArchiveResultModal {...props} subtitle={entity} archiveResultData={archiveResultData} />
        });
    };

    return showQueryResultModal;
};

export default useQueryResultModal;
