import cn from 'classnames';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../../Icon';
import Tooltip from '../Tooltip/Tooltip';

import './alert.scss';

const Alert = React.forwardRef(
    (
        {
            message,
            title,
            type,
            className,
            LeftComponent,
            RightComponent,
            children,
            opened,
            warningMessage,
            alertMessage,
            disabled,
            // onToggle,
            onChange,
            isWarning,
            isActive
        },
        ref
    ) => {
        const [isOpened, setOpened] = useState(opened);
        const animatedRef = useRef(false);

        useEffect(() => {
            setOpened(opened);
        }, [opened]);

        useEffect(() => {
            if (disabled) setOpened(false);
        }, [disabled]);

        const onOpened = isOpen => {
            if (disabled) {
                setOpened(false);
            } else {
                setOpened(isOpen);
            }
        };

        useImperativeHandle(ref, () => ({ isOpened, onOpened }));

        const handleClick = e => {
            animatedRef.current = true;
            e.preventDefault();
            setOpened(!isOpened);
            if (onChange) onChange(!isOpened);
        };

        const classNames = cn(
            'alert',
            className,
            type && 'alert_' + type,

            { 'alert--accordion': children },
            { 'alert--white': LeftComponent },
            { 'alert--no-radius': RightComponent },
            { 'alert--disabled': disabled },
            { opened: isOpened }
        );

        // TODO:
        // useEffect(() => {
        //     if (onToggle) onToggle(isOpened);
        // }, [isOpened]);

        return (
            <div className={isActive ? 'alert--active alert--box' : 'alert--box'}>
                <div className={classNames}>
                    {(alertMessage || warningMessage || isWarning) && (
                        <div className="alert__icon">
                            {warningMessage && (
                                <Tooltip message={warningMessage} type="bottom">
                                    <Icon.AlertTriangle size={16} className="alert__icon--triangle" />
                                </Tooltip>
                            )}
                            {alertMessage && (
                                <Tooltip message={alertMessage} type="bottom">
                                    <Icon.Alert size={16} className="alert__icon--filled" />
                                </Tooltip>
                            )}
                            {isWarning && <Icon.AlertTriangle size={16} className="alert__icon--triangle" />}
                        </div>
                    )}
                    {children && <i onClick={children && !disabled ? handleClick : null} />}
                    <div
                        className={cn(
                            { alert__left: LeftComponent || RightComponent },
                            message && 'alert__message',
                            children && 'alert-accordion__title',
                            { opened: isOpened }
                        )}
                        onClick={children && !disabled ? handleClick : null}
                    >
                        {LeftComponent || (
                            <>
                                {title && <span className="alert__title">{title}</span>}
                                {message}
                            </>
                        )}
                    </div>
                    {RightComponent}
                </div>
                <CSSTransition
                    in={isOpened}
                    timeout={animatedRef.current ? 300 : 0}
                    classNames="alert-accordion-children"
                    onExited={() => {
                        animatedRef.current = false;
                    }}
                    unmountOnExit
                >
                    <div>{children}</div>
                </CSSTransition>
            </div>
        );
    }
);

Alert.displayName = 'Alert';

export default Alert;
