import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Langs from '../../../Langs';
import { MODAL_TYPES } from '../../../Constants';
import { showModal, hideModal } from '../../../redux/operations';
import { camelToKebabCase, kebabToCamelCase, getParams } from '../../../Functions/utils';
import usePagination from '../../../Functions/hooks/usePagination';

import { useGetInvoicesQuery } from '../../../redux/api/invoiceApi';
import { useGetInvoiceDetailsQuery } from '../../../redux/api/invoiceDetailsApi';
import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetPaymentMethodListQuery } from '../../../redux/api/paymentApi';
import {
    useGetProjectCurrentPlanQuery,
    useGetProjectInfoQuery,
    useGetUseNextPaymentMethodStatusQuery
} from '../../../redux/api/projectApi';

import CurrentPlan from './current-plan/current-plan';
import InvoiceDetails from './invoice-details/invoice-details';
import PaymentMethod from './payment-method/payment-method';
import Invoices from './invoices/invoices';
import Loader from '../../Loader';
import JustAlert from '../../../components/just-alert/just-alert';
import ChagePlanModal from '../../Modals/change-plan-modal/change-plan-modal';
import AddPaymentMethodModal from '../../Modals/add-payment-method-modal/add-payment-method-modal';

import './billing-settings.scss';

const BillingAndSubscription = ({ modal }) => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const { modal: modalParam } = useParams();

    const { pagination: invoicesPagination, setPagination } = usePagination({ perPage: 50 });

    const { data: projectInfo, isSuccess: isProjectInfoLoaded } = useGetProjectInfoQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();

    const {
        data: currentPlan,
        isSuccess: isCurrentPlanLoaded,
        isFetching: isCurrentPlanFetching
    } = useGetProjectCurrentPlanQuery();

    const {
        data: invoiceDetails,
        isSuccess: isInvoiceDetailsLoaded,
        isFetching: isInvoiceDetailsFetching
    } = useGetInvoiceDetailsQuery();

    const {
        data: useNextPaymentMethodStatus,
        isSuccess: isUseNextPaymentMethodStatusLoaded,
        isFetching: isUseNextPaymentMethodStatusFetching
    } = useGetUseNextPaymentMethodStatusQuery();

    const {
        data: paymentMethodList,
        isSuccess: isPaymentMethodListLoaded,
        isFetching: isPaymentMethodListFetching
    } = useGetPaymentMethodListQuery();

    const parameters = {
        page: invoicesPagination.page,
        pages: invoicesPagination.pages,
        'per-page': invoicesPagination.perPage
    };

    const queryParameters = getParams(parameters);

    const {
        data: invoices,
        isSuccess: isInvoicesLoaded,
        isFetching: isInvoicesFetching
    } = useGetInvoicesQuery(queryParameters);

    useEffect(() => {
        if (isInvoicesLoaded) setPagination(invoices.pagination);
    }, [invoices]);

    const showCurrentModal = modalType => {
        if (modalType) history.push(`/settings/billing-and-subscription/${camelToKebabCase(modalType)}`);
    };

    const onCloseModal = () => {
        setTimeout(() => {
            history.push('/settings/billing-and-subscription');
        }, 0);
    };

    const isLoaded =
        isCurrentPlanLoaded &&
        isInvoicesLoaded &&
        isCurrenciesLoaded &&
        isInvoiceDetailsLoaded &&
        isUseNextPaymentMethodStatusLoaded &&
        isProjectInfoLoaded &&
        isPaymentMethodListLoaded;

    useEffect(() => {
        if (isLoaded) {
            const modalType = kebabToCamelCase(modalParam);

            switch (modalType) {
                case MODAL_TYPES.CHOOSE_PLAN:
                    showModal({
                        id: MODAL_TYPES.CHOOSE_PLAN,
                        noHeader: true,
                        onClose: onCloseModal,
                        body: () => <ChagePlanModal />
                    });
                    break;

                case MODAL_TYPES.ADD_NEW_PAYMENT_METHOD:
                    showModal({
                        id: MODAL_TYPES.ADD_NEW_PAYMENT_METHOD,
                        title: txt.titles.addPaymentMethod,
                        onClose: onCloseModal,
                        body: props => (
                            <AddPaymentMethodModal
                                {...props}
                                checkInvoiceDetails
                                invoiceDetails={invoiceDetails}
                                onClose={onCloseModal}
                                userEmail={projectInfo?.ownerEmail}
                                onAdded={() => history.push('/settings/billing-and-subscription')}
                            />
                        )
                    });
                    break;

                default:
                    if (
                        modal.some(m => m.id === MODAL_TYPES.CHOOSE_PLAN || m.id === MODAL_TYPES.ADD_NEW_PAYMENT_METHOD)
                    ) {
                        hideModal();
                    }
            }
        }
    }, [modalParam, isLoaded]);

    return isLoaded ? (
        <>
            {projectInfo.isUnlimited && (
                <JustAlert type="success" className="unlimited-info-alert mb25">
                    <span className="alert__title">{txt.titles.unlimitedBillingAlertTitle}</span>
                    {txt.alerts.unlimitedBillingAlertMessages}
                </JustAlert>
            )}

            <CurrentPlan
                currentPlan={currentPlan}
                isUnlimited={projectInfo.isUnlimited}
                showModalByType={showCurrentModal}
                isFetching={isCurrentPlanFetching}
            />

            <InvoiceDetails invoiceDetails={invoiceDetails} isFetching={isInvoiceDetailsFetching} />

            <PaymentMethod
                isFetching={isUseNextPaymentMethodStatusFetching || isPaymentMethodListFetching}
                balance={projectInfo.balance}
                paymentMethodList={paymentMethodList}
                showCurrentModal={showCurrentModal}
                useNextPaymentMethodStatus={useNextPaymentMethodStatus.isEnabledPaymentMethodRanking}
            />

            <Invoices
                invoices={invoices.items}
                pagination={invoicesPagination}
                setPagination={setPagination}
                isFetching={isInvoicesFetching}
                currencies={currencies}
            />
        </>
    ) : (
        <Loader />
    );
};

const Store = ({ oldState: { modal } }) => ({ modal });
export default connect(Store)(BillingAndSubscription);
