/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import cn from 'classnames';
import { Line } from 'react-chartjs-2';

import Langs from '../../Langs';
import { COLOR, METRIC_COLOR, INITIAL_SELECTED_METRICS } from '../../Constants';
import { capitalize } from '../../Functions/utils';

import CheckBoxInput from '../Overall/Forms/Inputs/CheckBoxInput';
import Loader from '../Loader';

import './charts.scss';

const getColor = (label, index) => {
    const colorProp = label.includes('compare') ? label.slice(7).toLowerCase() : label;
    return METRIC_COLOR[colorProp] || Object.values(COLOR)[index];
};

const Metrics = React.memo(({ datasets = [], onChange, metrics, type }) => {
    const txt = Langs[global.lng];

    const handleChange = (label, checked) => {
        if (checked) onChange([...metrics, label]);
        else onChange(metrics.filter(el => el !== label));
    };

    const labels = datasets.map(({ label }) => label);
    const hasComparationValues = labels.some(l => l.includes('compare'));

    return (
        <div className="metrics">
            <div className="metrics__header">{txt.labels.metrics}</div>
            <div className={cn('metrics__body', { 'metrics__body--one-column': !hasComparationValues })}>
                {labels.map((label, idx) => {
                    return (
                        <React.Fragment key={`${label}_${idx}`}>
                            <div>
                                <CheckBoxInput
                                    dataTest={`charts_${type}_${label}-checkbox`}
                                    label={txt.labels[label] || label.toUpperCase()}
                                    value={metrics.includes(label)}
                                    onChange={({ target: { checked } }) => handleChange(label, checked)}
                                    boxStyle={
                                        metrics.includes(label)
                                            ? {
                                                  backgroundColor: getColor(label, idx),
                                                  borderColor: getColor(label, idx)
                                              }
                                            : {}
                                    }
                                    isStriped={label.includes('compare')}
                                />
                            </div>
                            {hasComparationValues &&
                                !label.includes('compare') &&
                                !labels.some(l => l.includes(`compare${capitalize(label)}`)) && <div />}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
});

const Charts = ({
    datasets = [],
    labels = [],
    onChange = () => {},
    metrics = INITIAL_SELECTED_METRICS,
    isLoading,
    type
}) => {
    const txt = Langs[global.lng];

    const [data, setData] = useState({});
    const ref = useRef();

    useEffect(() => {
        ref?.current?.chartInstance?.update();
        ref?.current?.chartInstance?.resize();
    }, [data]);

    useEffect(() => {
        if (datasets?.length && labels?.length) {
            const newDatasets = datasets?.map((dataset, idx) => {
                const { label } = dataset;

                return {
                    label: txt.labels[dataset.label] || dataset.label,
                    data: [...dataset.data],
                    fill: false,
                    ...(dataset.label.includes('compare') ? { borderDash: [10, 5] } : {}),
                    borderColor: getColor(label, idx),
                    backgroundColor: type === 'dashboard' ? 'white' : getColor(label, idx),
                    // hidden: dataset.hidden !== undefined ? dataset.hidden : true
                    hidden: !metrics.includes(dataset.label)
                };
            });

            const newData = {
                labels,
                datasets: newDatasets
            };

            setData(newData);
        }
    }, [datasets, metrics]);

    useEffect(() => {
        Chart.pluginService.register({
            beforeDatasetsDraw(chart) {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const activePoint = chart.controller.tooltip._active[0];
                    const { ctx } = chart;
                    const { x } = activePoint.tooltipPosition();
                    const topY = chart.scales['y-axis-0'].top;
                    const bottomY = chart.scales['y-axis-0'].bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = COLOR.GREY_GULL;
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });
    }, []);

    if (!datasets) return null;

    const customTooltips = tooltipModel => {
        let tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.className = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div></div>';
            // document.getElementsByClassName('charts')[0].appendChild(tooltipEl);
            document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        const getBody = bodyItem => bodyItem.lines;

        if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '';
            titleLines.forEach(title => {
                innerHtml += '<div class="chartjs-tooltip__header">' + title + '</div>';
            });

            bodyLines.forEach((body, i) => {
                const bodyItems = body[0].split(':');
                const tooltipColors = tooltipModel.labelColors[i];
                let style = 'background-color:' + tooltipColors.borderColor + ';';
                if (bodyItems[0].includes('Previous'))
                    style = `background: white;border: 2px solid ${tooltipColors.borderColor};`;
                const span = '<span class="chartjs-tooltip__marker" style="' + style + '"></span>';
                innerHtml +=
                    '<div class="chartjs-tooltip__line"><div>' +
                    span +
                    bodyItems[0] +
                    '</div><div>' +
                    Number(bodyItems[1]).toLocaleString('en-EN') +
                    '</div></div>';
            });

            const tableRoot = tooltipEl.querySelector('div');
            tableRoot.innerHTML = innerHtml;
        }

        const position = ref.current.chartInstance.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';

        const tooltipInterval = 25;

        if (position.width - (tooltipModel.caretX + tooltipEl.clientWidth + tooltipInterval) <= 0) {
            tooltipEl.style.left =
                Math.floor(
                    position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.clientWidth + tooltipInterval)
                ) + 'px';
        } else {
            tooltipEl.style.left =
                Math.floor(position.left + window.pageXOffset + tooltipModel.caretX + tooltipInterval) + 'px';
        }

        tooltipEl.style.top =
            Math.floor(position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.clientHeight / 2) + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        legend: { display: false },
        tooltips: {
            mode: 'index',
            intersect: false,
            enabled: false,
            custom: customTooltips
        },
        scales: {
            xAxes: [
                {
                    gridLines: { display: false },
                    ticks: { fontSize: 10, fontColor: COLOR.GREY_GULL }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontSize: 10,
                        fontColor: COLOR.GREY_GULL,
                        callback: value => value.toLocaleString('en-EN')
                    }
                }
            ]
        },
        elements: {
            point: type === 'dashboard' ? { radius: 3.5, hoverRadius: 3.5 } : { radius: 3, hoverRadius: 3 },
            line: { borderWidth: 2 }
        }
    };

    const renderContent = () => {
        if (isLoading) return <Loader />;

        return !datasets?.length || !labels?.length ? null : (
            <>
                <div className="charts__chart">
                    <Line options={options} data={data} ref={ref} />
                </div>

                <div className="charts__metrics">
                    <Metrics datasets={datasets} onChange={onChange} metrics={metrics} type={type} />
                </div>
            </>
        );
    };

    return <div className={cn('charts', type && `charts--${type}`)}>{renderContent()}</div>;
};

export default React.memo(Charts);
