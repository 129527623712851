import React from 'react';
import { toast } from 'react-toastify';

import Langs from '../../Langs';
import { showModal } from '../../redux/operations';
import { camelToKebabCase, getResponseErrorMessage } from '../../Functions/utils';
import { ENTITY_MODE_STATUS } from '../../Constants';

import useQueryResultModal from '../../Functions/hooks/useQueryResultModal';

import api from '../../redux/api/indexApi';

import CreateOffer from './create-offer/create-offer';
import CreateLander from './create-lander/create-lander';
import CreateTrafficSource from './create-traffic-sources/create-traffic-sources';
import CreateAffiliateNetwork from './create-affiliate-network/create-affiliate-network';
import EditUserModal from '../Modals/edit-user-modal/edit-user-modal';
import InviteUserModal from '../Modals/invite-user-modal/invite-user-modal';

// eslint-disable-next-line no-unused-vars
import * as api2 from '../../redux/api/mainEntityApi';

const showItemsModal = (chapter, action, locationState, history, confirmCallback, dispath) => {
    const txt = Langs[global.lng];

    const showQueryResultModal = useQueryResultModal();

    const isNew = action === 'new';

    const mode = (() => {
        if (locationState?.mode) return locationState.mode;
        return isNew ? ENTITY_MODE_STATUS.CREATE : ENTITY_MODE_STATUS.EDIT;
    })();

    const id = (() => {
        if (isNew && mode === ENTITY_MODE_STATUS.DUPLICATE) return locationState.id;
        if (isNew) return null;
        return action;
    })();

    let title;
    let ModalBody;
    let itemName = 'entity';

    switch (chapter) {
        case 'offers':
            title = isNew ? 'createOffer' : 'editOffer';
            itemName = txt.labels.offer;
            ModalBody = CreateOffer;
            break;

        case 'landers':
            title = isNew ? 'createLander' : 'editLander';
            itemName = txt.labels.lander;
            ModalBody = CreateLander;
            break;

        case 'trafficSources':
            title = isNew ? 'createTrafficSource' : 'editTrafficSource';
            itemName = txt.labels.trafficSource;
            ModalBody = CreateTrafficSource;
            break;

        case 'affiliateNetworks':
            title = isNew ? 'createAffiliateNetwork' : 'editAffiliateNetwork';
            itemName = txt.labels.affiliateNetwork;
            ModalBody = CreateAffiliateNetwork;
            break;

        case 'editUser':
            title = 'editUser';
            itemName = txt.labels.user;
            ModalBody = EditUserModal;
            break;

        case 'inviteUser':
            title = txt.labels.inviteUser;
            itemName = txt.labels.inviteUser;
            ModalBody = InviteUserModal;
            break;

        default:
            return null;
    }

    const handleConfirm = async data => {
        let shouldModalClose = false;
        const editableEntity = chapter === 'reports' && locationState?.chapter ? locationState?.chapter : chapter;

        const preparedData =
            editableEntity === 'affiliateNetworks' ? { ...data, ipList: data.ipList?.filter(item => !!item) } : data;

        await dispath(
            api.endpoints[isNew ? 'addMainEntityItem' : 'editMainEntityItem'].initiate({
                entity: editableEntity,
                id: data.value,
                data: preparedData,
                transformer: editableEntity
            })
        ).then(res => {
            if (res.error) {
                toast.error(getResponseErrorMessage(res.error?.data, txt.errors.saveError));

                if (res.error?.data?.errors?.options) {
                    showQueryResultModal(
                        txt.texts.getCouldNotBeSavedByWorkspaseSubtitle(data.name),
                        res.error?.data?.errors?.options,
                        txt.titles.savingResult
                    );
                }
            } else {
                if (confirmCallback) confirmCallback(data, res?.data);
                toast.success(txt.singulars[editableEntity] + txt.toasts.wasSaved);
                shouldModalClose = true;
            }
        });

        if (shouldModalClose && history) history.push(`/home/${camelToKebabCase(chapter)}`);

        return shouldModalClose;
    };

    const warningModalConfig = {
        title: txt.titles.closeWithoutSave(itemName.toLowerCase()),
        message: txt.texts.warningModal(itemName.toLowerCase()),
        confirmButtonText: 'yesCloseForm',
        confirmButtonType: 'danger',
        onReject: () => {
            if (history) history.push(`/home/${camelToKebabCase(chapter)}`);
        }
    };

    return showModal({
        id: chapter,
        title,
        headerType: 'primary',
        warningModalConfig,
        onReject: () => {
            if (history) history.push(`/home/${camelToKebabCase(chapter)}`);
        },
        body: props => <ModalBody {...props} id={id} mode={mode} chapter={chapter} onConfirm={handleConfirm} />
    });
};

export default showItemsModal;
