import indexApi from './indexApi';
import { transform } from '../transformers';

const integrationsApiTagTypes = {
    integrations: 'integrations',
    trafficSourcesIntegration: 'trafficSourceIntegration'
};

export const integrationsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(integrationsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getIntegrations: builder.query({
                query: () => `/integrations`,
                transformResponse: response => response.map(el => transform.integrations.import(el)),
                providesTags: () => [{ type: integrationsApiTagTypes.integrations, id: 'LIST' }]
            }),

            changeIntegrationDataTransfer: builder.mutation({
                query: ({ body }) => ({
                    url: `/integrations/switch-data-transfer`,
                    method: 'PATCH',
                    body
                }),
                invalidatesTags: (result, error, { body }) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.integrations, id: body.integrationId },
                            { type: integrationsApiTagTypes.integrations, id: 'LIST' }
                        ];
                }
            }),

            deleteIntegration: builder.mutation({
                query: ({ id }) => ({
                    url: `/integrations/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error, { id }) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.integrations, id },
                            { type: integrationsApiTagTypes.integrations, id: 'LIST' },
                            { type: 'trafficSourceIntegrationList', id: 'LIST' },
                            { type: 'trafficSources', id: 'ALL_ITEMS' }
                        ];
                }
            }),

            checkIntegrationOAuthCode: builder.query({
                query: ({ id, code }) => `/integrations/oauth2/${id}?code=${code}`,
                keepUnusedDataFor: 0
            }),

            getIntegrationAccounts: builder.query({
                query: id => `/integrations/account/${id}`,
                transformResponse: response =>
                    response.map(el => ({ label: el.name || el.account_id, value: el.account_id }))
            }),

            getIntegrationPostbackUrl: builder.query({
                query: integrationId => `/integrations/postback-link-generate/${integrationId}`,
                keepUnusedDataFor: 0
            }),

            //

            getTSIntegrationById: builder.query({
                query: id => `/integrations/traffic-source/${id}`,
                providesTags: (result, error, id) => [{ type: integrationsApiTagTypes.trafficSourcesIntegration, id }]
            }),

            addTSIntegration: builder.mutation({
                query: ({ data }) => ({
                    url: `/integrations/traffic-source`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error, { data }) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.integrations, id: 'LIST' },
                            { type: 'trafficSourceIntegrationList', id: data.templateId }
                        ];
                }
            }),

            editTSIntegration: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/integrations/traffic-source/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: (result, error, { id, data }) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.trafficSourcesIntegration, id },
                            { type: integrationsApiTagTypes.integrations, id: 'LIST' },
                            { type: 'trafficSourceIntegrationList', id: data.templateId },
                            { type: integrationsApiTagTypes.integrations, id: 'TS_INTEGRATIONS_CAMPAIGNS_LIST' }
                        ];
                }
            }),

            getTrafficSourceIntegrationCampaignList: builder.query({
                query: id => `/integrations/traffic-source/campaign/list/${id}`,
                keepUnusedDataFor: 0,
                providesTags: () => [
                    { type: integrationsApiTagTypes.integrations, id: 'TS_INTEGRATIONS_CAMPAIGNS_LIST' }
                ]
            }),

            changeIntegrationCampaignStatus: builder.mutation({
                query: ({ status, data }) => ({
                    url: `/integrations/campaign/${status}`,
                    method: 'PUT',
                    body: data
                }),
                async onQueryStarted(
                    { rowId, similarCampaigns, campaignListRequestParams, skipUpdate },
                    { dispatch, queryFulfilled }
                ) {
                    try {
                        await queryFulfilled;

                        if (!skipUpdate) {
                            const query = dispatch(
                                indexApi.endpoints.getCampaignIntegrationStatistics.initiate({ id: rowId })
                            );

                            const result = await query;

                            dispatch(
                                indexApi.util.updateQueryData('getCampaignsList', campaignListRequestParams, draft => {
                                    [rowId, ...(similarCampaigns ?? [])].forEach(el => {
                                        const element = draft.items.find(it => it.value === el);

                                        if (element) {
                                            element.integrationData.status = result.data?.status;
                                            element.integrationData.isActive = !element.integrationData.isActive;
                                        }
                                    });

                                    return draft;
                                })
                            );

                            query.unsubscribe();
                        }

                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                invalidatesTags: (result, error) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.integrations, id: 'TS_INTEGRATIONS_CAMPAIGNS_LIST' }
                            // { type: 'campaignIntegrationStatistics', id: rowId },
                            // ...similarCampaigns.map(el => ({ type: 'campaignIntegrationStatistics', id: el }))
                        ];
                }
            }),

            changeIntegrationCampaignBid: builder.mutation({
                query: ({ data }) => ({
                    url: '/integrations/campaign/bid',
                    method: 'PATCH',
                    body: data
                }),
                async onQueryStarted(
                    { data, rowId, similarCampaigns, campaignListRequestParams },
                    { dispatch, queryFulfilled }
                ) {
                    try {
                        await queryFulfilled;

                        dispatch(
                            indexApi.util.updateQueryData('getCampaignsList', campaignListRequestParams, draft => {
                                [rowId, ...(similarCampaigns ?? [])].forEach(el => {
                                    const element = draft.items.find(it => it.value === el);

                                    if (element) element.integrationData.bidAmount = data.price;
                                });

                                return draft;
                            })
                        );

                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                invalidatesTags: (result, error) => {
                    if (!error)
                        return [
                            { type: integrationsApiTagTypes.integrations, id: 'TS_INTEGRATIONS_CAMPAIGNS_LIST' }
                            // { type: 'campaignIntegrationStatistics', id: rowId },
                            // ...similarCampaigns.map(el => ({ type: 'campaignIntegrationStatistics', id: el }))
                        ];
                }
            }),

            changeIntegrationCampaignBidJust: builder.mutation({
                query: ({ data }) => ({
                    url: '/integrations/campaign/bid',
                    method: 'PATCH',
                    body: data
                }),
                invalidatesTags: (result, error, { data }) => {
                    if (!error)
                        return [
                            { type: 'campaignIntegrationStatistics', id: 'ALL_INTEGRATION_STATISTICS' },
                            { type: integrationsApiTagTypes.integrations, id: 'TS_INTEGRATIONS_CAMPAIGNS_LIST' },
                            { type: 'campaigns', id: 'LIST' },
                            { type: 'campaigns', id: data.campaignId }
                        ];
                }
            })
        })
    });

export const {
    useGetIntegrationsQuery,
    useChangeIntegrationDataTransferMutation,
    useDeleteIntegrationMutation,
    useGetIntegrationAccountsQuery,
    useGetIntegrationPostbackUrlQuery,
    //
    useGetTSIntegrationByIdQuery,
    useAddTSIntegrationMutation,
    useEditTSIntegrationMutation,
    //
    useGetTrafficSourceIntegrationCampaignListQuery,
    useChangeIntegrationCampaignStatusMutation,
    useChangeIntegrationCampaignBidMutation,
    useChangeIntegrationCampaignBidJustMutation
} = integrationsApi;
