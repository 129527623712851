import React from 'react';

export const FACEBOOK_OAUTH_KEY = 'faceboook-integratoin-auth';
export const GOOGLE_OAUTH_KEY = 'google-integratoin-auth';

const CheckOAuthStatus = ({ type }) => {
    const params = new URLSearchParams(window.location.search);

    if (type === 'facebook') {
        const savedIntegrationId = params.get('state');
        const integrationAuthError = params.get('error');
        const integrationAuthErrorReason = params.get('error_reason');
        const integrationAuthCode = params.get('code');

        if (integrationAuthCode) {
            localStorage.setItem(
                FACEBOOK_OAUTH_KEY,
                JSON.stringify({
                    isOk: !!integrationAuthCode,
                    integrationId: savedIntegrationId,
                    code: integrationAuthCode,
                    error: integrationAuthError,
                    reason: integrationAuthErrorReason
                })
            );
        }
    }

    if (type === 'google') {
        const savedIntegrationId = params.get('state');
        const integrationAuthError = params.get('error');
        const integrationAuthCode = params.get('code');

        if (integrationAuthCode) {
            localStorage.setItem(
                GOOGLE_OAUTH_KEY,
                JSON.stringify({
                    isOk: !!integrationAuthCode,
                    integrationId: savedIntegrationId,
                    code: integrationAuthCode,
                    error: integrationAuthError
                })
            );
        }
    }

    return (
        <div style={{ fontSize: '18px', fontWeight: 500, padding: 20 }}>
            Please close this tab if it doesn&apos;t close automatically!
        </div>
    );
};

export default CheckOAuthStatus;
