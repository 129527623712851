
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/img/signUp.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);

___CSS_LOADER_EXPORT___.push([module.id, ".auth-form{display:flex;align-items:center;min-height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover}.auth-form .forgot-email-sent{margin-bottom:35px;font-weight:300}.auth-form .forgot-email-sent b{display:block;font-weight:500;text-align:center}.auth-form .forgot-email-sent+.form-button{margin-top:0!important}.auth-form .title{line-height:20px}", "",{"version":3,"sources":["webpack://src/JS/Modules/AuthForm/%3Cinput%20css%208%3E"],"names":[],"mappings":"AAAA,WAAW,YAAY,CAAC,kBAAkB,CAAC,gBAAgB,CAAC,wDAAgD,CAAC,0BAA0B,CAAC,qBAAqB,CAAC,8BAA8B,kBAAkB,CAAC,eAAe,CAAC,gCAAgC,aAAa,CAAC,eAAe,CAAC,iBAAiB,CAAC,2CAA2C,sBAAsB,CAAC,kBAAkB,gBAAgB","sourcesContent":[".auth-form{display:flex;align-items:center;min-height:100vh;background-image:url(/src/assets/img/signUp.jpg);background-position:center;background-size:cover}.auth-form .forgot-email-sent{margin-bottom:35px;font-weight:300}.auth-form .forgot-email-sent b{display:block;font-weight:500;text-align:center}.auth-form .forgot-email-sent+.form-button{margin-top:0!important}.auth-form .title{line-height:20px}"],"sourceRoot":""}]);

export default ___CSS_LOADER_EXPORT___;
