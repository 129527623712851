const useScrollTo = (contentRef, className = '.form-input--error') => {
    const scroll = () => {
        setTimeout(() => {
            const parent = contentRef.current;
            const parentPos = parent?.getBoundingClientRect();
            const firstFounded = document.querySelector(className)?.getBoundingClientRect();
            const top = +firstFounded?.top - +parentPos?.top;

            parent?.scrollTo({
                top: top + parent.scrollTop - 15,
                behavior: 'smooth'
            });
        }, 200);
    };

    return scroll;
};

export default useScrollTo;
