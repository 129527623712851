import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Langs from '../../../../Langs';
import useToggle from '../../../../Functions/hooks/useToggle';

import './tooltip.scss';

const Tooltip = ({ children, message, type = 'top', link, className, disabled }) => {
    const txt = Langs[global.lng];
    const { isToggled: isVisible, toggleOn: setIsVisible, toggleOff: unsetVisible } = useToggle();
    const [tooltipType, setTooltipType] = useState(type);
    const tooltipMessageContainerRef = useRef(null);

    useLayoutEffect(() => {
        if (window.innerWidth < tooltipMessageContainerRef.current?.getBoundingClientRect()?.right + 24) {
            if (type === 'top') setTooltipType('left');
            if (type === 'bottom') setTooltipType('bottom-left');
        }
    }, [isVisible, message]);

    return (
        <div className={cn('tooltip', className)} onMouseEnter={setIsVisible} onMouseLeave={unsetVisible}>
            {children}
            {message && !disabled && (
                <CSSTransition
                    in={isVisible}
                    timeout={500}
                    classNames={{
                        enterActive: 'tooltip__container--visible',
                        exitActive: 'tooltip__container--hidden'
                    }}
                    unmountOnExit
                    onExited={() => setTooltipType(type)}
                >
                    <div
                        ref={tooltipMessageContainerRef}
                        className={cn('tooltip__container', {
                            [`tooltip__container--${tooltipType}`]: tooltipType
                        })}
                    >
                        <span className="tooltip__message">
                            {message}
                            {link && (
                                <>
                                    {' '}
                                    <a data-test="tooltip_link" href={link} className="tooltip__link">
                                        {txt.hints.readMore}
                                    </a>
                                </>
                            )}
                        </span>
                    </div>
                </CSSTransition>
            )}
        </div>
    );
};

export default React.memo(Tooltip);
