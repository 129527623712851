import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Trash2 } from 'react-feather';
import { toast } from 'react-toastify';

import Langs from '../../../../../Langs';
import { getHiddenCardNumber, getResponseErrorMessage } from '../../../../../Functions/utils';
import { showModal } from '../../../../../redux/operations';

import {
    useDeletePaymentMethodMutation,
    useRenamePaymentMethodMutation,
    useSetDefaultPaymentMethodMutation
} from '../../../../../redux/api/paymentApi';

import { EditablePath } from '../../../../Items/NewItem/components/FlowPath/Path/common';
import ConfirmDeleteModal from '../../../../Modals/confirm-delete-modal/confirm-delete-modal';

import Visa from '../../../../../../../media/images/paymentMethods/visa.svg';
import MasterCard from '../../../../../../../media/images/paymentMethods/mastercard.svg';
import AmericanExpress from '../../../../../../../media/images/paymentMethods/americanexpress.svg';
import Discover from '../../../../../../../media/images/paymentMethods/discover.svg';
import PayPal from '../../../../../../../media/images/paymentMethods/paypal-light-large.svg';
import CardDefault from '../../../../../../../media/images/paymentMethods/cardicon.svg';

const icons = {
    Visa,
    MasterCard,
    AmericanExpress,
    Discover,
    PayPal
};

const PaymentItem = ({ method, isDragabble, setIsProcessing }) => {
    const txt = Langs[global.lng];

    const [isEditing, setIsEditing] = useState(false);

    const [
        renamePaymentMethod,
        {
            isSuccess: isRenamePaymentMethodSuccess,
            isError: isRenamePaymentMethodError,
            error: renamePaymentMethodError
        }
    ] = useRenamePaymentMethodMutation();

    useEffect(() => {
        if (isRenamePaymentMethodSuccess) toast.success(txt.toasts.saved);
    }, [isRenamePaymentMethodSuccess]);

    useEffect(() => {
        if (isRenamePaymentMethodError) {
            toast.error(getResponseErrorMessage(renamePaymentMethodError.data, txt.toasts.saveError));
            setIsProcessing(false);
        }
    }, [isRenamePaymentMethodError]);

    const onRenamePaymentMethod = name => {
        setIsProcessing(true);

        renamePaymentMethod({
            id: method.id,
            name: name.trim() === getHiddenCardNumber(method.cardNumber) ? method.cardNumber : name
        });
    };

    const [
        deletePaymentMethod,
        {
            isSuccess: isDeletePaymentMethodSuccess,
            isError: isDeletePaymentMethodError,
            error: deletePaymentMethodError
        }
    ] = useDeletePaymentMethodMutation();

    useEffect(() => {
        if (isDeletePaymentMethodSuccess) toast.success(txt.toasts.saved);
    }, [isDeletePaymentMethodSuccess]);

    useEffect(() => {
        if (isDeletePaymentMethodError) {
            toast.error(getResponseErrorMessage(deletePaymentMethodError.data, txt.toasts.saveError));
            setIsProcessing(false);
        }
    }, [isDeletePaymentMethodError]);

    const onDeletePaymentMethod = () => {
        showModal({
            title: txt.texts.areYouSure,
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.youWontBeAbleRevert}
                    entity={'payment method'}
                    autoClose={true}
                    onConfirm={() => {
                        setIsProcessing(true);
                        deletePaymentMethod({ id: method.id });
                    }}
                />
            )
        });
    };

    const [
        setDefaultPaymentMethod,
        {
            isSuccess: isSetDefaultPaymentMethodSuccess,
            isError: isSetDefaultPaymentMethodError,
            error: setDefaultPaymentMethodError
        }
    ] = useSetDefaultPaymentMethodMutation();

    useEffect(() => {
        if (isSetDefaultPaymentMethodSuccess) toast.success(txt.toasts.saved);
    }, [isSetDefaultPaymentMethodSuccess]);

    useEffect(() => {
        if (isSetDefaultPaymentMethodError) {
            toast.error(getResponseErrorMessage(setDefaultPaymentMethodError.data, txt.toasts.saveError));
            setIsProcessing(false);
        }
    }, [isSetDefaultPaymentMethodError]);

    const onSetDefaultPaymentMethod = e => {
        e.preventDefault();
        setIsProcessing(true);
        setDefaultPaymentMethod({ id: method.id });
    };

    return (
        <>
            <div className="payment-method__img">
                <img
                    src={icons[method.cardType] || CardDefault}
                    width="36"
                    height="24"
                    alt={method.cardType || 'Card'}
                />
            </div>

            <div className="payment-method__name">
                <EditablePath
                    isEditing={isEditing}
                    className={cn(isEditing ? 'editable-path--wide' : 'editable-path--link')}
                    name={method.name === method.cardNumber ? getHiddenCardNumber(method.name) : method.name}
                    onChange={onRenamePaymentMethod}
                    toggleOffEditing={() => setIsEditing(false)}
                    onClick={() => setIsEditing(true)}
                />
                {!isEditing && getHiddenCardNumber(method.name) !== getHiddenCardNumber(method.cardNumber) && (
                    <span className="payment-method__name--inactive">{getHiddenCardNumber(method.cardNumber)}</span>
                )}
            </div>

            <div
                className={cn('payment-method__expires', {
                    'payment-method__expires--expired': method.isExpired
                })}
            >
                {method?.expirationDate && <>Expired on {method?.expirationDate}</>}
            </div>

            {!isDragabble && (
                <div>
                    {method.isDefault ? (
                        <span>Default</span>
                    ) : (
                        !method.isExpired && (
                            <a href="#makeDefault" onClick={onSetDefaultPaymentMethod}>
                                Make default
                            </a>
                        )
                    )}
                </div>
            )}

            <div className="payment-method__delete">
                {!method.isDefault ? (
                    <Trash2 size={16} className="td-actions--delete" onClick={onDeletePaymentMethod} />
                ) : (
                    isDragabble && <span>Default</span>
                )}
            </div>
        </>
    );
};

export default PaymentItem;
