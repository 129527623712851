const landerTemplate = {
    value: null,
    id: null,
    workspaceId: '',
    tags: [],
    countryTag: 'GO',
    name: '',
    landerUrl: 'https://',
    tokens: [],

    hasProtectLandingPage: false,
    hasRedirectlessScript: false,
    isMatchLanderUrl: false,
    clickUrlPage: '',

    campaignsCount: 0,
    offersNum: 1,
    verify: {
        clickUrlScript: 'not_verified',
        landingPageProtectScript: 'not_verified',
        redirectlessScript: 'not_verified',
        statuses: [],
        date: null
    },

    isArchived: false,
    isRestore: false,

    createdAt: null,
    updatedAt: null,
    createdAtUserId: null,
    updatedAtUserId: null
};

export default landerTemplate;
