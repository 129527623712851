import React, { useState } from 'react';
import cn from 'classnames';

import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Langs from '../../../../../Langs';

import { camelToKebabCase } from '../../../../../Functions/utils';

const HiddenGroupMenuItem = ({ label, items, isReport, onChange }) => {
    const txt = Langs[global.lng].labels;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div key={label} className="top-menu__hidden-wrapper">
            <div
                className={cn('top-menu__hidden-item', { 'top-menu__hidden-item--active': isOpen })}
                onClick={() => setIsOpen(!isOpen)}
            >
                {txt[label] || label}
            </div>

            <div className="top-menu__hidden-submenu">
                <CSSTransition in={isOpen} timeout={300} classNames="switcher" unmountOnExit>
                    <div>
                        {items.map(el => {
                            const title = txt[el.label] || el.label;

                            const props = {
                                key: el.label,
                                className: 'top-menu__hidden-submenu-item',
                                onClick: isReport ? () => onChange(el.label) : () => {},
                                ...(!isReport ? { to: `/home/${camelToKebabCase(el.label)}` } : {})
                            };

                            return !isReport ? <NavLink {...props}>{title}</NavLink> : <div {...props}>{title}</div>;
                        })}
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

const HiddenMenu = ({ menu, isReport, onChange }) => {
    const txt = Langs[global.lng].labels;

    return (
        <div className="top-menu__hidden" onClick={e => e.stopPropagation()}>
            {menu.map(({ label, items }) => {
                if (items) {
                    return (
                        <HiddenGroupMenuItem
                            key={label}
                            label={label}
                            items={items}
                            isReport={isReport}
                            onChange={onChange}
                        />
                    );
                }

                return isReport ? (
                    <div key={label} onClick={() => onChange(label)} className="top-menu__hidden-item">
                        {txt[label] || label}
                    </div>
                ) : (
                    <NavLink key={label} to={`/home/${camelToKebabCase(label)}`} className="top-menu__hidden-item">
                        {txt[label] || label}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default HiddenMenu;
