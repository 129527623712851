import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';

import Langs from '../../../../Langs';
import { ENTITY_MODE_STATUS, INTEGRATION_FEATURES } from '../../../../Constants';
import { showModal } from '../../../../redux/operations';

import { useGetTrafficSourceIntegrationListQuery } from '../../../../redux/api/templatesApi';

import { AddBtn } from '../../../Overall/UI/Btn/Btn';
import SelectInput from '../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import Switcher from '../../../Overall/UI/Switcher/Switcher';
import TSIntegrationModal from '../../../Modals/ts-integration-modal/ts-integration-modal';
import FacebookIntegrationModal from '../../../Modals/ts-integration-modal/components/facebook-integration-modal/facebook-integration-modal';
import GoogleIntegrationModal from '../../../Modals/ts-integration-modal/components/google-integration-modal/google-integration-modal';
import IntegrationFeatures from '../../../../components/integration-features/integration-features';
import Loader from '../../../Loader';

import './ts-integration.scss';

const TSIntegration = ({
    modalFields,
    templateName,
    templateId,
    isIntegration,
    selectedIntegration,
    updateTrafficSource,
    isSendCkick,
    originalEditableTrafficSource
}) => {
    const txt = Langs[global.lng];

    const { data: integrations, isFetching: isIntegrationsFetching } = useGetTrafficSourceIntegrationListQuery(
        templateId,
        { skip: !templateId }
    );

    useEffect(() => {
        if (isIntegration === null && integrations && integrations.length) {
            updateTrafficSource({ isIntegration: true });
            originalEditableTrafficSource.current = { ...originalEditableTrafficSource.current, isIntegration: true };
        }
    }, [integrations]);

    const showNewIntegrationModal = mode => {
        const ModalBody = (() => {
            if (templateName === 'Facebook') return FacebookIntegrationModal;
            if (templateName === 'Google Ads - Search/Display') return GoogleIntegrationModal;

            return TSIntegrationModal;
        })();

        const warningModalConfig = {
            title: txt.titles.closeWithoutSave(),
            message: txt.texts.warningModal(),
            confirmButtonText: 'yesCloseForm',
            confirmButtonType: 'danger'
        };

        showModal({
            title: txt.titles.integrateAccount,
            warningModalConfig,
            body: props => (
                <ModalBody
                    {...props}
                    templateName={templateName}
                    templateId={templateId}
                    integrationId={selectedIntegration}
                    mode={mode}
                    fields={modalFields}
                    onChange={updateTrafficSource}
                />
            )
        });
    };

    const NewIntegrationBtn = useMemo(() => {
        return (
            <AddBtn
                dataTest="campaign-main-option_traffic-source-add-btn"
                className={cn({ ml8: integrations?.length })}
                type="filled"
                isCreateType
                onClick={() => showNewIntegrationModal(ENTITY_MODE_STATUS.CREATE)}
            >
                {txt.buttons.newIntegration}
            </AddBtn>
        );
    }, [integrations?.length, templateId]);

    const onIsIntegrationChange = status => {
        updateTrafficSource({
            isIntegration: status,
            ...(!status ? { integrationId: null, isAutoGeneratePostbackUrl: false, postbackUrl: 'https://' } : {})
        });
    };

    return (
        <div className="ts-integration">
            <IntegrationFeatures
                title={`${txt.titles.integrationAvailable}. ${txt.titles.integrationAllowYouTo}`}
                features={INTEGRATION_FEATURES}
            />

            {integrations?.length ? (
                <Switcher
                    dataTest="traffic-source_postback-url"
                    isSwitchOn={isIntegration}
                    onChange={onIsIntegrationChange}
                    label={`${txt.labels.integration} (${templateName})`}
                >
                    <div className="ts-integration__item mt10">
                        {integrations?.length ? (
                            <>
                                <SelectInput
                                    dataTest="campaign-main-options_traffic-source-select-input"
                                    className={isSendCkick && isIntegration && !selectedIntegration ? 'mb10' : ''}
                                    value={selectedIntegration}
                                    items={integrations}
                                    placeholder={txt.placeholders.selectTrafficSource}
                                    onChange={({ target: { value } }) => updateTrafficSource({ integrationId: value })}
                                    error={
                                        isSendCkick &&
                                        isIntegration &&
                                        !selectedIntegration &&
                                        txt.errors.required(txt.labels.integration)
                                    }
                                />

                                {/* <EditBtn
                                    dataTest="campaign-main-option_traffic-source-edit-btn"
                                    className="ml8 btn--edit"
                                    disabled={!selectedIntegration}
                                    onClick={() => showNewIntegrationModal(ENTITY_MODE_STATUS.EDIT)}
                                /> */}
                            </>
                        ) : null}

                        {NewIntegrationBtn}
                    </div>
                </Switcher>
            ) : (
                <div className="ts-integration__item mt10">{NewIntegrationBtn}</div>
            )}

            {isIntegrationsFetching && <Loader isFetching />}
        </div>
    );
};

export default TSIntegration;
