import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { clone, getResponseErrorMessage } from '../../../Functions/utils';
import { showModal, hideModal } from '../../../redux/operations';

import { useGetImportListQuery, useSendImportAuthMutation } from '../../../redux/api/importApi';

import Loader from '../../Loader';
import SelectInput from '../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import ImportSettingsModal from '../../Modals/import-settings-modal/ImportSettingsModal';
import Section from '../../../components/section/section';
import SectionHeader from '../../Settings/SettingsSection/SectionHeader';

import './tools-import.scss';

const ToolsImport = () => {
    const txt = Langs[global.lng];

    const [isLoading, setIsLoading] = useState(true);
    const [trackerList, setTrackerList] = useState([]);
    const [tracker, setTracker] = useState(null);
    const [isTouched, setIsTouched] = useState(false);

    const { data: importList, isSuccess: isImportListLoaded } = useGetImportListQuery();

    useEffect(() => {
        if (isImportListLoaded) {
            setTrackerList(clone(importList));
            setTracker(importList[0]?.value);
            setIsLoading(false);
        }
    }, [importList]);

    const isValid =
        trackerList
            ?.find(item => item.value === tracker)
            ?.authFields?.reduce(
                (ac, item) => ac && (item.validation ? new RegExp(item.validation).test(item.value) : !!item.value),
                true
            ) || false;

    const onAuthFieldChange = (value, label) => {
        setTrackerList(
            trackerList.map(item =>
                item.value !== tracker
                    ? item
                    : {
                          ...item,
                          authFields: item.authFields.map(el =>
                              el.label !== label
                                  ? el
                                  : {
                                        ...el,
                                        value
                                    }
                          )
                      }
            )
        );
    };

    const clearValuesAuthFields = () => {
        setTrackerList(
            trackerList?.map(item =>
                item.value === tracker
                    ? {
                          ...item,
                          authFields: item.authFields.map(el => ({ ...el, value: '' }))
                      }
                    : item
            )
        );
    };

    const showImportSettingsModal = () => {
        showModal({
            title: txt.labels.import,
            className: 'import-settings-modal',
            onReject: () => {}, // fetchData(`import/${tracker}/clear`),
            body: props => (
                <ImportSettingsModal
                    {...props}
                    tracker={tracker}
                    onConfirm={() => {
                        clearValuesAuthFields();
                        setIsTouched(false);
                        hideModal();
                    }}
                    onClose={() => {
                        // setIsTouched(false);
                        hideModal();
                    }}
                />
            )
        });
    };

    const [
        sendImportAuth,
        {
            isLoading: isSendImportAuthLoading,
            isSuccess: isSendImportAuthSuccess,
            isError: isSendImportAuthError,
            error: sendImportAuthError
        }
    ] = useSendImportAuthMutation();

    useEffect(() => {
        if (isSendImportAuthSuccess) showImportSettingsModal();
    }, [isSendImportAuthSuccess]);

    useEffect(() => {
        if (isSendImportAuthError) {
            toast.error(getResponseErrorMessage(sendImportAuthError.data, txt.errors.badCredentials));
        }
    }, [isSendImportAuthError]);

    const onImportSettingsClick = () => {
        setIsTouched(true);

        if (isValid) {
            sendImportAuth({
                tracker,
                data: trackerList
                    .find(item => item.value === tracker)
                    .authFields.reduce((ac, item) => ({ ...ac, [item.requestKey]: item.value }), {})
            });
        }
    };

    const renderAuthField = useMemo(
        () =>
            trackerList
                ? trackerList
                      ?.find(item => item.value === tracker)
                      ?.authFields.map(
                          el =>
                              (
                                  <TextInput
                                      key={el.label.replace(/\s/g, '-')}
                                      dataTest={`tools-import_auth-field_${el.label.replace(/\s/g, '-')}`}
                                      label={el.label}
                                      placeholder={el.placeholder}
                                      value={el.value}
                                      error={
                                          isTouched &&
                                          !(el.validation ? new RegExp(el.validation).test(el.value) : el.value)
                                      }
                                      onChange={({ target: { value } }) => onAuthFieldChange(value, el?.label)}
                                      className="mb15"
                                  />
                              ) || null
                      )
                : null,
        [trackerList, tracker, isTouched]
    );

    useEffect(() => {
        setIsTouched(false);
        if (tracker && trackerList) clearValuesAuthFields();
    }, [tracker]);

    const isLoaded = !isLoading;
    const isProcessing = isSendImportAuthLoading;

    if (!isLoaded) return <Loader />;

    const sectionHeader = <SectionHeader title={txt.labels.importFrom} />;

    return (
        <Section Header={sectionHeader} className="tools-import">
            {importList.length ? (
                <>
                    <SelectInput
                        dataTest="tools-import_tracker-input"
                        label="Tracker"
                        value={tracker || ''}
                        items={trackerList}
                        onChange={({ target: { value } }) => setTracker(value)}
                        className="mb15"
                    />

                    {tracker && trackerList && trackerList?.length && renderAuthField}

                    <ModalButtonBar
                        confirmButtonText={txt.buttons.importSettings}
                        onConfirm={onImportSettingsClick}
                        className="j5 mt15"
                    />
                </>
            ) : null}

            {isProcessing && <Loader isFetching />}
        </Section>
    );
};

export default ToolsImport;
