import React from 'react';

import Langs from '../../../../../Langs';

import MenuItem from '../menu-item/menu-item';

const MainMenu = ({ menu, activeItem, exceptions = [], isReport, onChange, isLoading }) => {
    const txt = Langs[global.lng].labels;

    return menu.map(({ label, icon }) =>
        !exceptions.includes(label) ? (
            <MenuItem
                dataTest={`top-menu_${label}-div`}
                key={label}
                label={txt[label] || label}
                link={label}
                icon={icon}
                isActive={label === activeItem}
                disabled={isLoading}
                isReport={isReport}
                onClick={() => onChange(label)}
            />
        ) : null
    );
};

export default MainMenu;
