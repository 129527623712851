import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, OFFER_CAP_TYPE, USER_ROLES, WORKSPACE_TYPE } from '../../../Constants';
import { getCreatedUpdatedUserNames, getUrlError } from '../../../redux/operations';
import { getParams, compare } from '../../../Functions/utils';
import useToggle from '../../../Functions/hooks/useToggle';
import useScrollTo from '../../../Functions/hooks/useScrollTo';
import offerTemplate from './offer-template';

import {
    useGetAffiliateNetworkByIdQuery,
    useGetAffiliateNetworksWithColumnsQuery
} from '../../../redux/api/affiliateNetworksApi';
import {
    useGetOfferByIdQuery,
    useGetOffersUrlParamsQuery,
    useGetOffersWithColumnsQuery
} from '../../../redux/api/offersApi';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from '../../../redux/api/settingsApi';
import { useGetTimezonesQuery, useGetCountriesQuery, useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useGetDefaultDomainsByTypeQuery } from '../../../redux/api/domainsApi';
import { useGetWorkspacesAllNamesQuery } from '../../../redux/api/workspacesApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import Loader from '../../Loader';
import CreatedUpdatedUserInfo from '../../Overall/CreatedUpdatedUserInfo/CreatedUpdatedUserInfo';
import OfferSettings from './components/offer-settings';
import OfferConversionTracking from './components/offer-conversion-tracking';
import OfferCapSettings from './components/offer-cap-settings';

import './create-offer.scss';

const CreateOffer = ({ id, mode, onConfirm, onTouch, onClose }) => {
    const txt = Langs[global.lng];
    const dispatch = useDispatch();

    const contentRef = useRef();
    const scrollTo = useScrollTo(contentRef);

    const [offer, setOffer] = useState({ ...offerTemplate });
    const [isLoading, setIsLoading] = useState(!!id);
    const [isFetching, setIsFetching] = useState(false);
    const originalEditableOffer = useRef({ ...offerTemplate });
    const { isToggled: isNeedValidate, toggleOn: setIsNeedValidate } = useToggle();

    const updateOffer = useCallback(updatedFields => {
        setOffer(prevOffer => ({ ...prevOffer, ...updatedFields }));
    }, []);

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery();
    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery();

    useEffect(() => {
        if (isGeneralSettingsLoaded) {
            updateOffer({ timeZone: generalSettings?.reportTimeZone });

            originalEditableOffer.current = {
                ...originalEditableOffer.current,
                timeZone: generalSettings?.reportTimeZone
            };
        }
    }, [generalSettings]);

    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: timezones, isSuccess: isTimezonesLoaded } = useGetTimezonesQuery();

    const { data: affiliateNetworks, isSuccess: isAffiliateNetworksLoaded } = useGetAffiliateNetworksWithColumnsQuery(
        getParams(['id', 'name', 'workspaceId'])
    );

    const { data: defaultTrackingDomain, isSuccess: isDefaultTrackingDomainLoaded } =
        useGetDefaultDomainsByTypeQuery('tracking');

    const { data: redirectOffersList, isSuccess: isRedirectOffersListLoaded } = useGetOffersWithColumnsQuery(
        getParams(['id', 'incrementId', 'name', 'offerCAPIsEnabled', 'workspaceId'])
    );

    const { data: offerTokens, isSuccess: isOfferTokensLoaded } = useGetOffersUrlParamsQuery();

    const { data: offerData, isSuccess: isOfferDataLoaded } = useGetOfferByIdQuery(id, { skip: !id });

    const { data: workspacesList, isSuccess: isWorkspacesListLoaded } = useGetWorkspacesAllNamesQuery();

    useEffect(() => {
        (async () => {
            if (isOfferDataLoaded) {
                const createdUpdatedUserNames = await getCreatedUpdatedUserNames(offerData, dispatch);

                const isNeedReplaceWorkspaceId =
                    mode === ENTITY_MODE_STATUS.DUPLICATE &&
                    userSettings.role !== USER_ROLES.OWNER &&
                    offerData.workspaceId === userSettings.publicWorkspaceId;

                const resultOffer = {
                    ...offerData,
                    timeZone: offerData.timeZone ?? generalSettings?.reportTimeZone,
                    value: mode === ENTITY_MODE_STATUS.DUPLICATE ? null : offerData.value,
                    workspaceId: isNeedReplaceWorkspaceId ? userSettings.initialWorkspaceId : offerData.workspaceId,
                    ...createdUpdatedUserNames,
                    ...(mode === ENTITY_MODE_STATUS.DUPLICATE ? { name: `${offerData.name} Copy` } : {}),
                    ...(mode === ENTITY_MODE_STATUS.EDIT && offerData.isArchived ? { isRestore: true } : {})
                };

                setOffer(resultOffer);
                originalEditableOffer.current = resultOffer;
                setIsLoading(false);
            }
        })();
    }, [offerData]);

    useEffect(() => {
        if (isUserSettingsLoaded && isWorkspacesListLoaded && !offer.workspaceId) {
            const initialWorkspaceId =
                workspacesList.find(el => el.value === userSettings.initialWorkspaceId)?.value ?? '';

            updateOffer({ workspaceId: initialWorkspaceId });
            originalEditableOffer.current = { ...originalEditableOffer.current, workspaceId: initialWorkspaceId };
        }
    }, [userSettings, workspacesList]);

    const { data: affiliateNetworkData, isFetching: isAffiliateNetworkDataFetching } = useGetAffiliateNetworkByIdQuery(
        offer?.affiliateNetworkId,
        { skip: !offer?.affiliateNetworkId }
    );

    const isCreateMode = mode === ENTITY_MODE_STATUS.CREATE;

    const validateCap = () => {
        const isConversionCapType = offer.capType === OFFER_CAP_TYPE.CONVERSION;
        const isClickCapType = offer.capType === OFFER_CAP_TYPE.CLICK;

        if (isConversionCapType) return offer.conversionCap;
        if (isClickCapType) return offer.clickCap;
        return offer.conversionCap && offer.clickCap;
    };

    const isOfferValid = useMemo(
        () =>
            offer.name?.trim() &&
            offer.offerUrl &&
            !getUrlError(offer.offerUrl) &&
            (!offer.isCapEnabled || (validateCap() && offer.timeZone)) &&
            (offer.payoutType === 'auto' || (offer.payout && offer.payout > 0)) &&
            (offer.isCapEnabled ? !!offer.redirectOffer : true),
        [offer]
    );

    const getConfirmButtonText = () => {
        const isHideSaveButtons =
            mode === ENTITY_MODE_STATUS.EDIT &&
            userSettings.role === USER_ROLES.USER &&
            offerData?.workspaceId === userSettings.publicWorkspaceId;

        if (isHideSaveButtons) return null;
        if (mode === ENTITY_MODE_STATUS.EDIT && offer?.isArchived) return txt.buttons.saveAndRestore;
        if (id) return txt.buttons.save;
        return txt.buttons.apply;
    };

    useEffect(() => {
        onTouch(!compare(offer, originalEditableOffer.current));
    }, [offer]);

    const onModalConfirm = async () => {
        setIsNeedValidate();
        scrollTo();

        if (isOfferValid) {
            setIsFetching(true);

            const ok = await onConfirm(offer);
            if (!ok) setIsFetching(false);

            return ok;
        }
    };

    const filteredWorkspaceList = useMemo(() => {
        if (workspacesList && userSettings?.role === USER_ROLES.USER) {
            return workspacesList.map(el => {
                if (el.workspaceType === WORKSPACE_TYPE.PUBLIC) return { ...el, disabled: true };
                return el;
            });
        }

        return workspacesList;
    }, [workspacesList, userSettings]);

    const isLoaded =
        isGeneralSettingsLoaded &&
        isUserSettingsLoaded &&
        isCountriesLoaded &&
        isCurrenciesLoaded &&
        isTimezonesLoaded &&
        isAffiliateNetworksLoaded &&
        isDefaultTrackingDomainLoaded &&
        isRedirectOffersListLoaded &&
        isOfferTokensLoaded &&
        isWorkspacesListLoaded &&
        // (offer?.affiliateNetworkId ? isAffiliateNetworkDataLoaded : true) &&
        (id ? isOfferDataLoaded : true);

    return (
        <>
            <div className="create-offer" ref={contentRef}>
                <div className="create-offer__content">
                    {isLoading || !isLoaded ? (
                        <Loader />
                    ) : (
                        <>
                            <OfferSettings
                                workspaceId={offer.workspaceId}
                                publicWorkspaceId={userSettings.publicWorkspaceId}
                                offerTags={offer.tags}
                                offerName={offer.name}
                                offerUrl={offer.offerUrl}
                                countryTag={offer.countryTag}
                                affiliateNetworkId={offer.affiliateNetworkId}
                                redirectOffer={offer.redirectOffer}
                                selectedAffNetwork={offer.affiliateNetworkId ? affiliateNetworkData : null}
                                isCreateMode={isCreateMode}
                                updateOffer={updateOffer}
                                isNeedValidate={isNeedValidate}
                                workspacesList={filteredWorkspaceList}
                                offerTokens={offerTokens}
                                countries={countries}
                                affiliateNetworks={affiliateNetworks}
                            />

                            <OfferConversionTracking
                                payout={offer.payout}
                                payoutType={offer.payoutType}
                                payoutCurrency={offer.payoutCurrency}
                                conversionUrlFormat={offer.conversionUrlFormat}
                                selectedAffNetwork={offer.affiliateNetworkId ? affiliateNetworkData : null}
                                updateOffer={updateOffer}
                                isNeedValidate={isNeedValidate}
                                currencies={currencies}
                                defaultTrackingDomain={defaultTrackingDomain}
                            />

                            <OfferCapSettings
                                workspaceId={offer.workspaceId}
                                publicWorkspaceId={userSettings.publicWorkspaceId}
                                offerId={offer.value}
                                isCapEnabled={offer.isCapEnabled}
                                capType={offer.capType}
                                conversionCap={offer.conversionCap}
                                clickCap={offer.clickCap}
                                originalEditableOffer={originalEditableOffer}
                                updateOffer={updateOffer}
                                timeZone={offer.timeZone}
                                redirectOffer={offer.redirectOffer}
                                isNeedValidate={isNeedValidate}
                                offers={redirectOffersList}
                                timezones={timezones}
                            />
                        </>
                    )}

                    {(isFetching || isAffiliateNetworkDataFetching) && <Loader isFetching />}
                </div>
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={getConfirmButtonText()}
                isConfirmDisabled={(isNeedValidate && !isOfferValid) || isFetching}
                isLoading={isLoading}
                leftSettings={
                    id && offer?.createdAtUserId ? (
                        <CreatedUpdatedUserInfo
                            createdUserName={offer.createdUserName}
                            updatedUserName={offer.updatedUserName}
                            createdAt={offer.createdAt}
                            updatedAt={offer.updatedAt}
                        />
                    ) : null
                }
                onConfirm={onModalConfirm}
                onReject={onClose}
            />
        </>
    );
};

export default CreateOffer;
