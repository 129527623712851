import React from 'react';

import Langs from '../../Langs';
import ModalButtonBar from '../Overall/Modal/ModalButtonBar/ModalButtonBar';

import './modals.scss';

const MessageModal = ({
    onConfirm,
    onClose,
    subtitle,
    message,
    confirmButtonType,
    confirmButtonText,
    isConfirmButtonHidden,
    rejectButtonText,
    leftSettings
}) => {
    const txt = Langs[global.lng];
    return (
        <>
            <div className="message-modal">
                <div className="message-modal__content">
                    {subtitle && <span className="message-modal__subtitle">{subtitle}</span>}
                    {message}
                </div>
            </div>
            <ModalButtonBar
                rejectButtonText={rejectButtonText || txt.buttons.cancel}
                confirmButtonText={!isConfirmButtonHidden && (confirmButtonText || txt.buttons.apply)}
                onConfirm={() => onConfirm()}
                confirmButtonType={confirmButtonType}
                onReject={onClose}
                leftSettings={leftSettings}
            />
        </>
    );
};

export default MessageModal;
