import React from 'react';
// import { getIntlFormattedValue } from '../../../../Functions/utils';

export const TdPayout = React.memo(({ payout, /*rounding,*/ currency }) => {
    const currencySymbol = currency && currency.symbol !== currency.value ? currency.symbol : '';
    // const formattedValue = typeof payout?.price === 'number' ? getIntlFormattedValue(payout?.price, rounding) : '';

    return (
        <span className="items-table__td-text">
            {payout?.method === 'manual' || payout?.method === 'by_offer_click'
                ? `${currencySymbol}${payout?.price}${!currencySymbol ? ' ' + payout?.currency : ''}`
                : 'auto'}
        </span>
    );
});

TdPayout.displayName = 'TdPayout';
