import React from 'react';
import cn from 'classnames';

import { getIntlFormattedValue } from '../../../../Functions/utils';

export const TdPrice = ({ defaultCurrency, item, columnOptions, disabled, currencies }) => {
    if (!item && item !== 0) return null;

    const currency = currencies?.find(el => el.value === defaultCurrency);
    const { symbol, value } = currency;

    const formattedValue = getIntlFormattedValue(item, 2);

    return (
        <span
            className={
                columnOptions?.summaryClassName || disabled
                    ? cn(columnOptions?.summaryClassName, {
                          'td-text--disabled': disabled
                      })
                    : null
            }
        >
            {symbol.length <= 2 ? `${symbol}${formattedValue}` : `${formattedValue} ${value}`}
        </span>
    );
};

export default TdPrice;
