import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useSearch from '../../../../../Functions/hooks/useNewSearch';
import Langs from '../../../../../Langs';
import FormActions from '../../../Forms/FormActions/FormActions';
import CheckBoxInput from '../../../Forms/Inputs/CheckBoxInput';
import { Tag } from '../../../Forms/Inputs/TagInput';
import TextInput from '../../../Forms/Inputs/TextInput';

import './step-by-step-form.scss';

const CUSTOM_KEY = 'All';

const getStepByStepTags = (value, items) =>
    items
        .reduce((result, item) => {
            const selectedChildren = item.versions?.filter(child => value.includes(child)) || [];
            result.push(item.versions?.length === selectedChildren.length ? item.key : selectedChildren);
            return result;
        }, [])
        .flat();

export const getStepByStepFormData = ({ value, items }) => getStepByStepTags(value, items).join(', ');

export const getStepByStepFormCount = (value, items) => getStepByStepTags(value, items).length;

const StepByStepForm = ({ value, items, isEnabled, isMatched, onChange, label }) => {
    const txt = Langs[global.lng];
    const [activeParent, setActiveParent] = useState([]);
    const [editValue, setEditValue] = useState(value.join(', '));
    const [activeTags, setActiveTags] = useState([]);
    const [parents, setParents] = useState(items);

    const parentsUpdate = () => {
        const newActiveTags = [];

        setParents(
            parents.map(parent => {
                const selectedChildren = parent.versions?.filter(child => value.includes(child)) || [];
                const isParentSelected = parent.versions?.length === selectedChildren.length;

                newActiveTags.push(isParentSelected ? parent.key : selectedChildren);

                return {
                    ...parent,
                    isSelected: isParentSelected,
                    isStark: !!selectedChildren.length && parent.versions?.length > selectedChildren.length
                };
            })
        );

        setActiveTags(newActiveTags.flat());
    };

    const onParentChange = parent => {
        const getResult = () => {
            if (parent.isSelected) {
                return [
                    ...value
                        ?.filter(item => !parent.versions?.includes(item))
                        .filter(item => item !== `${parent.key} ${CUSTOM_KEY}`)
                ];
            }

            if (parent.isStark) {
                return [
                    ...value
                        ?.filter(item => !parent.versions?.includes(item))
                        .filter(item => item !== `${parent.key} ${CUSTOM_KEY}`)
                ];
            }

            return [...value, `${parent.key} ${CUSTOM_KEY}`, ...parent?.versions];
        };

        const result = getResult();
        onChange(result, 'value');
    };

    const onChildChange = child => {
        const getResult = () => {
            if (value.includes(child)) {
                return [
                    ...value.filter(item => item !== child).filter(item => item !== `${activeParent.key} ${CUSTOM_KEY}`)
                ];
            }

            const isAllChildsAdded = activeParent.versions.every(item => [...value, child].includes(item));

            if (isAllChildsAdded) {
                return [...value, child, `${activeParent.key} ${CUSTOM_KEY}`];
            }

            return [...value, child];
        };

        const result = getResult();
        onChange(result, 'value');
    };

    const onDeleteTag = tag => {
        const parentMatch = parents.find(parent => parent.key === tag);
        onChange(
            [
                ...value
                    .filter(item => !(item === tag || parentMatch?.versions?.includes(item)))
                    .filter(item => item !== `${tag} ${CUSTOM_KEY}`)
            ],
            'value'
        );
    };

    const {
        filteredItems: filteredParents,
        setSearchByForInput: handleSearchByParents,
        searchByValue: parentsSearchBy
    } = useSearch(parents, parent => parent.key);

    const {
        filteredItems: filteredChildren,
        setSearchByForInput: handleSearchByChildren,
        searchByValue: childrenSearchBy
    } = useSearch(activeParent?.versions, item => item.key);

    useEffect(() => parentsUpdate(), [value]);

    const handleListConfirm = () => {
        onChange(
            editValue.split(/[,\n]/)?.reduce((res, item) => {
                if (item) res.push(item.trim());
                return res;
            }, []),
            'value'
        );
    };

    const handleListChange = () => {
        setEditValue(value.join(', '));
    };

    const handleClear = () => {
        onChange([], 'value');
        setEditValue('');
    };

    return (
        <div className="step-by-step-form">
            <FormActions
                label={label}
                isEnabled={isEnabled}
                onChange={onChange}
                handleEditConfirm={handleListConfirm}
                isMatched={isMatched}
                onClear={handleClear}
                handleListChange={handleListChange}
                textFieldValue={editValue}
                onTextFieldEdit={({ target: { value: fieldValue } }) => setEditValue(fieldValue)}
            >
                <div className="step-by-step-form__container">
                    <div>
                        <TextInput
                            dataTest="step-by-step-form_parents-search-input"
                            placeholder={txt.placeholders.search}
                            onChange={handleSearchByParents}
                            value={parentsSearchBy}
                        />
                        <div className="step-by-step-form__items">
                            {filteredParents.map(parent => (
                                <div
                                    className={classNames(
                                        'df',
                                        'step-by-step-form__roll-item',
                                        activeParent.key === parent.key && 'step-by-step-form__roll-item--active'
                                    )}
                                    key={'id' + parent.key}
                                >
                                    <CheckBoxInput
                                        dataTest={`step-by-step-form_${parent.id}-checkbox`}
                                        onChange={event => onParentChange(parent, event)}
                                        value={parent.isSelected}
                                        isStark={parent.isStark}
                                    />
                                    <span
                                        className="step-by-step-form__parent-label"
                                        onClick={() => setActiveParent(parent)}
                                    >
                                        {parent.key}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <TextInput
                            dataTest="step-by-step-form_children-search-input"
                            placeholder={txt.placeholders.search}
                            onChange={handleSearchByChildren}
                            value={childrenSearchBy}
                        />
                        <div className="step-by-step-form__items">
                            {filteredChildren?.map(child => (
                                <div key={child} className="step-by-step-form__roll-item">
                                    <CheckBoxInput
                                        dataTest={`step-by-step-form_${child.id}-checkbox`}
                                        label={child.replace(activeParent.key, '').trim()}
                                        onChange={event => onChildChange(child, event)}
                                        value={value.includes(child)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        {activeTags.length ? (
                            <div className="step-by-step-form__tags">
                                {activeTags.map(item => (
                                    <Tag
                                        key={item}
                                        onDeleteItem={() => onDeleteTag(item)}
                                        label={item}
                                        type={isMatched ? 'confirm' : 'error'}
                                    />
                                ))}
                            </div>
                        ) : (
                            <span className="step-by-step-form__message">{txt.labels.selectOneItem}</span>
                        )}
                    </div>
                </div>
            </FormActions>
        </div>
    );
};

export default StepByStepForm;
