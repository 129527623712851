import React from 'react';
import { NavLink } from 'react-router-dom';

import Langs from '../../Langs';

import './page-header-links.scss';

const PageHeaderLinks = ({ link, items }) => {
    const txt = Langs[global.lng];

    return (
        <div className="page-header-links">
            {items?.map(item => (
                <NavLink
                    key={item}
                    to={`/${link}/${item}`}
                    className="page-header-links__link"
                    activeClassName="page-header-links__link--active"
                >
                    {txt.labels[item] || item}
                </NavLink>
            ))}
        </div>
    );
};

export default PageHeaderLinks;
