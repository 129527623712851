import indexApi from './indexApi';
import { transform } from '../transformers';

export const templatesApiTagTypes = {
    trafficSourceIntegrationList: 'trafficSourceIntegrationList',
    affiliateNetworksTemplates: 'affiliateNetworksTemplates',
    trafficSourceTemplates: 'trafficSourceTemplates'
};

export const templatesApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(templatesApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getAffiliateNetworksTemplateList: builder.query({
                query: () => `/affiliate-network-templates`,
                transformResponse: response => response.map(el => transform.affiliateNetworksTemplates.import(el)),
                providesTags: [{ type: templatesApiTagTypes.affiliateNetworksTemplates, id: 'LIST' }]
            }),

            getTrafficSourcesTemplateList: builder.query({
                query: () => `/traffic-source-templates`,
                transformResponse: response =>
                    response
                        .filter(({ name }) => name !== 'TrafficStars')
                        .filter(({ name }) => name !== 'Google Ads - Search/Display')
                        .map(el => transform.trafficSourcesTemplates.import(el)),
                providesTags: [{ type: templatesApiTagTypes.trafficSourceTemplates, id: 'LIST' }]
            }),

            getTrafficSourcesTemplate: builder.query({
                query: templateId => `/traffic-source-templates/${templateId}`,
                transformResponse: response => transform.trafficSourcesTemplates.import(response),
                providesTags: (result, error, templateId) => [
                    { type: templatesApiTagTypes.trafficSourceTemplates, id: templateId },
                    { type: templatesApiTagTypes.trafficSourceTemplates, id: 'ALL_ITEMS' }
                ]
            }),

            getTrafficSourceIntegrationList: builder.query({
                query: id => `/traffic-source-templates/integrations/${id}`,
                transformResponse: response => response.map(el => ({ ...el, value: el.id, label: el.name })),
                providesTags: (result, error, id) => [
                    { type: templatesApiTagTypes.trafficSourceIntegrationList, id: 'LIST' },
                    { type: templatesApiTagTypes.trafficSourceIntegrationList, id }
                ]
            })
        })
    });

export const {
    useGetAffiliateNetworksTemplateListQuery,
    useGetTrafficSourcesTemplateListQuery,
    useGetTrafficSourcesTemplateQuery,
    useGetTrafficSourceIntegrationListQuery
} = templatesApi;
