import { v4 as uuid } from 'uuid';
import pathTemplate from './path-template';

const ruleTemplate = {
    name: '',
    isRuleEnabled: true,
    logicalRelation: 'and',
    isPathOptimization: false,
    calculationMethod: 'auto',
    paths: [{ ...pathTemplate, id: uuid() }],
    criteria: [
        { key: 'timeDayOfWeek', isEnabled: false, isMatched: true, value: [] },
        { key: 'timeTime', isEnabled: false, isMatched: true, from: '', to: '' },
        { key: 'timeTimezone', isEnabled: false, isMatched: true, value: '' },
        { key: 'geoCountry', isEnabled: false, isMatched: true, value: [] },
        { key: 'geoStateOrRegion', isEnabled: false, isMatched: true, value: [] },
        { key: 'geoCity', isEnabled: false, isMatched: true, value: [] },
        { key: 'geoLanguage', isEnabled: false, isMatched: true, value: [] },
        { key: 'deviceType', isEnabled: false, isMatched: true, value: [] },
        { key: 'deviceBrandAndModel', isEnabled: false, isMatched: true, value: [] },
        {
            key: 'deviceOsNameAndVersion',
            isEnabled: false,
            isMatched: true,
            value: []
        },
        { key: 'deviceBrowserNameAndVersion', isEnabled: false, isMatched: true, value: [] },
        { key: 'connectionType', isEnabled: false, isMatched: true, value: [] },
        {
            key: 'connectionCarrier',
            isEnabled: false,
            isMatched: true,
            value: []
        },
        { key: 'connectionIsp', isEnabled: false, isMatched: true, value: [] },
        { key: 'connectionProxy', isEnabled: false, isMatched: true, value: [] },
        {
            key: 'refererReferer',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar1',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar2',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar3',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar4',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar5',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar6',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar7',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar8',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar9',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar10',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar11',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar12',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar13',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar14',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar15',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar16',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar17',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar18',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar19',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        {
            key: 'variableVar20',
            isEnabled: false,
            isMatched: true,
            isIncludedEmpty: false,
            value: []
        },
        // {
        //     key: 'uniqueVisit',
        //     isEnabled: false,
        //     isMatched: true,
        //     value: false
        // },
        { key: 'otherIp', isEnabled: false, isMatched: true, value: [] }
    ]
};

export default ruleTemplate;
