import React from 'react';
import cn from 'classnames';

import Langs from '../../../Langs';
import { checkPositivity, getIntlFormattedValue, capitalize } from '../../../Functions/utils';
import { BADGE_TYPE } from '../../../Constants';

import TdBadges from '../../Overall/ItemsTable/TdTypes/TdBadges';
import TdDate from '../../Overall/ItemsTable/TdTypes/TdDate';
import TdTimeFormat from './TdTypes/TdTimeFormat';
import TdMoney from './TdTypes/td-money/TdMoney';
import TdName from './TdTypes/TdName';
import TdBadgesHidingContainer from '../../Overall/ItemsTable/TdTypes/TdBadgesHidingContainer';
import { TdText, TdStatus, TdDoubleValue, TdCheckbox, TdProgressBar, TdChange, TdPayout, TdUrl } from './TdTypes';
import Icon from '../../Overall/Icon';
import BadgeRound from '../../Overall/UI/BadgeRound/BadgeRound';
import TdIntegration from './TdTypes/TdIntegration/TdIntegration';

const MainTableTd = React.memo(
    ({
        chapter,
        type,
        subtype,
        row,
        cellKey,
        rounding,
        hasAnyChild,
        isSharedReportPage,
        currencies,
        generalSettings,
        storage,
        handleTreeToggle,
        onCheckBoxChange,
        onChange
    }) => {
        const txt = Langs[global.lng];
        const value = row.statistic?.[cellKey] ?? row[cellKey];

        const formattedValue = typeof value === 'number' ? getIntlFormattedValue(value, rounding) : value;

        const handleMouseUp = () => {
            document.addEventListener(
                'mouseup',
                () => {
                    onChange();
                    localStorage.removeItem('activeCheckedElementStatus');
                },
                { once: true }
            );
        };

        const renderName = (valueL, rowL, hasAnyChildL) => {
            const hasRowChildren = rowL?.children?.length > 0;
            return (
                <div
                    className={cn('td-name', {
                        'td-name--children': hasRowChildren,
                        'td-name--no-any-children': !hasAnyChildL
                    })}
                >
                    <div>
                        {hasRowChildren && (
                            <span className="td-name__checkbox" onClick={e => handleTreeToggle(e, rowL)}>
                                {rowL?.isOpened ? (
                                    <Icon.MinusSquare size={14} className="td-name__icon" />
                                ) : (
                                    <Icon.PlusSquare size={14} className="td-name__icon" />
                                )}
                            </span>
                        )}

                        {!isSharedReportPage && (
                            <TdCheckbox
                                chapter={chapter}
                                key={rowL.valueL}
                                value={!!rowL.isChecked}
                                isStark={rowL.isStark}
                                onChange={() => onCheckBoxChange(rowL)}
                                type="clean"
                            />
                        )}

                        <TdText value={valueL} />
                    </div>
                    {rowL?.exist && <BadgeRound text={txt.texts.inSettings} type={BADGE_TYPE.SUCCESS} />}
                </div>
            );
        };

        switch (type) {
            case 'payout':
                return (
                    <TdPayout
                        payout={row.payout}
                        rounding={rounding}
                        currency={currencies?.find(el => el.value === row?.payout?.currency)}
                    />
                );
            case 'checkField':
                return (
                    <TdCheckbox
                        chapter={chapter}
                        id={row.value}
                        value={!!row.isChecked}
                        onChange={() => onCheckBoxChange(row)}
                        type={checkPositivity(row.statistic?.profit ?? row.profit)}
                        supportMultiMouseSelect
                        setRowIsChecked={isChecked => {
                            row.isChecked = isChecked;
                        }}
                        onMouseUp={handleMouseUp}
                    />
                );
            case 'profitProgress':
                return <TdProgressBar positive={value?.positive} negative={value?.negative} />;
            case 'date':
                return <TdDate item={value} />;
            case 'change':
            case 'changeOnlyProfit':
                return <TdChange value={value} rounding={rounding} onlyProfit={type === 'changeOnlyProfit'} />;
            case 'status':
                return <TdStatus value={value} />;
            case 'doubleValue':
                return <TdDoubleValue items={value} />;
            case 'badges':
                return <TdBadges items={value} />;
            case 'label':
                return <TdText value={txt.labels[value] || value} />;
            case 'workspaces':
                return <TdText value={value?.map(el => el.name).join(', ')} />;
            // case 'campaign':
            //     return <TdText value={campaigns.find(campaign => campaign.value === row.campaignId)?.label} />;
            // case 'trafficSource':
            //     return (
            //         <TdText
            //             value={trafficSources.find(trafficSource => trafficSource.value === row.trafficSourceId)?.label}
            //         />
            //     );
            // case 'offer':
            //     return <TdText value={offers.find(offer => offer.value === row.offerId)?.label} />;
            case 'timeFormat':
                return <TdTimeFormat dateStart={row.visitTimestamp} dateEnd={row.postbackTimestamp} />;
            case 'url':
                return <TdUrl value={value} />;
            // case 'errorLogCategory':
            //     return (
            //         <span className={cn(`text--${checkPositivity(value)}`)}>
            //             <TdText value={errorLogCategories?.[value]} />
            //         </span>
            //     );
            case 'money':
            case 'percentage':
                return (
                    <TdMoney
                        value={value}
                        rounding={rounding}
                        isPercentage={type === 'percentage'}
                        currencies={currencies}
                        defaultCurrency={generalSettings?.defaultCurrency}
                        isIntegrationCostUsed={generalSettings?.isIntegrationCostUsedForCalc}
                        cellKey={cellKey}
                        chapter={chapter}
                        integration={row.integration}
                        integrationData={row.integrationData}
                    />
                );
            case 'badgesHidingContainer':
                return (
                    <TdBadgesHidingContainer
                        items={value}
                        row={row}
                        parentWidth={storage[chapter]?.columnWidths?.find(item => item.key === cellKey)?.width}
                    />
                );
            case 'capitalize':
                return <TdText value={capitalize(formattedValue)} />;
            case 'displayName':
                return (
                    <TdName
                        chapter={chapter}
                        value={formattedValue}
                        row={row}
                        integrationData={row.integrationData}
                        onChange={onChange}
                        isSharedReportPage={isSharedReportPage}
                    />
                );
            case 'integration':
                return (
                    <TdIntegration
                        trafficSourceId={row?.trafficSourceId}
                        trafficSourceCampaignId={row?.trafficSourceCampaignId}
                        integration={row.integration}
                        integrationData={row.integrationData}
                        isIntegrationActive={row.integration?.isActive}
                        isIntegrationSimilarCampaigns={row.integration?.similarCampaigns}
                        isIntegrationCostUsed={generalSettings?.isIntegrationCostUsedForCalc}
                        rowId={row.value}
                        type={subtype}
                        cellKey={cellKey}
                        rounding={rounding}
                        currencies={currencies}
                        chapter={chapter}
                    />
                );

            default:
                if (chapter === 'reports' && cellKey === 'name') {
                    return renderName(value, row, hasAnyChild);
                } else if (chapter === 'refererUrl' && cellKey === 'name') {
                    return (
                        <div className="items-table__td--url">
                            <TdUrl value={value} />
                        </div>
                    );
                }

                return (
                    <span className={cn(`text--${checkPositivity(value)}`)}>
                        <TdText value={formattedValue} />
                    </span>
                );
        }
    }
);

MainTableTd.displayName = 'MainTableTd';
export default MainTableTd;
