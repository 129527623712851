import React from 'react';
import { ChevronLeft } from 'react-feather';

const ButtonPrev = ({ onClick, disabled }) => (
    <div
        className={`menu-header-tabs__tab scroll-wrapper__prev
            ${disabled ? 'scroll-wrapper__prev--disabled' : ''}`}
        onClick={disabled ? null : onClick}
    >
        <span className="icon">
            <ChevronLeft size={16} color="white" />
        </span>
    </div>
);

export default ButtonPrev;
