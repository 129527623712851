import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getResponseErrorMessage } from '../../../Functions/utils';
import useFocus from '../../../Functions/hooks/useFocus';

import {
    useAddCustomConversionMutation,
    useEditCustomConversionMutation
} from '../../../redux/api/customConversionsApi';

import ModalButtonBar from '../../Overall/Modal/ModalButtonBar/ModalButtonBar';
import TextInput from '../../Overall/Forms/Inputs/TextInput';
import TagInput from '../../Overall/Forms/Inputs/TagInput';
import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import JustAlert from '../../../components/just-alert/just-alert';
import Loader from '../../Loader';

import './add-custom-conversion-modal.scss';

const eventTemplate = {
    eventName: '',

    tags: [],

    isConversions: false,
    isRevenue: false,
    isPostback: false,
    isCost: false
};

const AddCustomConversionModal = ({ item, updateStorage, onClose }) => {
    const txt = Langs[global.lng];

    const [conversionEvent, setConversionEvent] = useState(item || eventTemplate);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    const [nameInputRef, setNameFocus] = useFocus();

    const isNameValid = !!conversionEvent.eventName;

    const updateEvent = useCallback(updatedFields => {
        setConversionEvent(prevEvent => ({ ...prevEvent, ...updatedFields }));
    }, []);

    useEffect(() => {
        if (!conversionEvent.eventName) setTimeout(setNameFocus, 0);
    }, []);

    const [
        addCustomConversion,
        {
            isSuccess: isAddCustomConversionSuccess,
            isError: isAddCustomConversionError,
            error: addCustomConversionError
        }
    ] = useAddCustomConversionMutation();

    const [
        editCustomConversion,
        {
            isSuccess: isEditCustomConversionSuccess,
            isError: isEditCustomConversionError,
            error: editCustomConversionError
        }
    ] = useEditCustomConversionMutation();

    useEffect(() => {
        if (isEditCustomConversionError)
            toast.error(getResponseErrorMessage(editCustomConversionError.data, txt.toasts.saveError));

        if (isAddCustomConversionError)
            toast.error(getResponseErrorMessage(addCustomConversionError.data, txt.toasts.saveError));

        if (isEditCustomConversionError || isAddCustomConversionError) setIsProcessing(false);
    }, [isEditCustomConversionError, isAddCustomConversionError]);

    useEffect(() => {
        if (isAddCustomConversionSuccess || isEditCustomConversionSuccess) updateStorage(txt.toasts.saved);
    }, [isAddCustomConversionSuccess, isEditCustomConversionSuccess]);

    const onModalConfirm = () => {
        setIsTouched(true);

        if (isNameValid) {
            setIsProcessing(true);

            const request = item ? editCustomConversion : addCustomConversion;

            request({
                ...(item ? { id: conversionEvent.id } : {}),
                data: conversionEvent
            });
        }
    };

    return (
        <>
            <div className="add-custom-conversion-modal">
                <JustAlert type="info" className="mb15">
                    <div className="mb15">{txt.settings.customConversionEvent}</div>
                    <span>{txt.settings.customConversionEventExample}</span>
                </JustAlert>

                <TextInput
                    label={txt.labels.eventName}
                    hint={txt.hints.customConversionEventName}
                    className="mb15"
                    ref={nameInputRef}
                    dataTest="custom-conversion-event_eventName-input"
                    require
                    error={isTouched && !conversionEvent.eventName && txt.errors.required(txt.labels.name)}
                    value={conversionEvent.eventName}
                    onChange={({ target: { value } }) => updateEvent({ eventName: value })}
                />

                <TagInput
                    label={txt.labels.valuesOfTheEtParameter}
                    hint={txt.hints.valuesOfTheEtParameter}
                    className="mb15"
                    dataTest="custom-conversion-event_tags-input"
                    isDropdownHidden
                    value={conversionEvent.tags}
                    onChange={value => updateEvent({ tags: value })}
                />

                <CheckBoxInput
                    label={txt.labels.includeConversions}
                    hint={txt.hints.includeConversions}
                    className="mb15"
                    dataTest="custom-conversion-event_isConversions-checkbox"
                    value={conversionEvent.isConversions}
                    onChange={({ target: { checked } }) => updateEvent({ isConversions: checked })}
                />

                <CheckBoxInput
                    label={txt.labels.includeRevenue}
                    hint={txt.hints.includeRevenue}
                    className="mb15"
                    dataTest="custom-conversion-event_isConversions-checkbox"
                    value={conversionEvent.isRevenue}
                    onChange={({ target: { checked } }) => updateEvent({ isRevenue: checked })}
                />

                <CheckBoxInput
                    label={txt.labels.sendPostbackToTrafficSource}
                    hint={txt.hints.sendPostbackToTrafficSource}
                    hintType="bottom"
                    className="mb15"
                    dataTest="custom-conversion-event_isConversions-checkbox"
                    value={conversionEvent.isPostback}
                    onChange={({ target: { checked } }) =>
                        updateEvent({ isPostback: checked, ...(!checked ? { isCost: false } : {}) })
                    }
                />

                <CheckBoxInput
                    label={txt.labels.includeInCostColumn}
                    hint={txt.hints.includeInCostColumn}
                    hintType="bottom"
                    className="mb15 ml15"
                    dataTest="custom-conversion-event_isConversions-checkbox"
                    disabled={!conversionEvent.isPostback}
                    value={conversionEvent.isCost}
                    onChange={useCallback(({ target: { checked } }) => updateEvent({ isCost: checked }), [])}
                />

                {isProcessing && <Loader isFetching />}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.save}
                onConfirm={onModalConfirm}
                isConfirmDisabled={isProcessing || (isTouched && !isNameValid)}
                onReject={onClose}
            />
        </>
    );
};

export default AddCustomConversionModal;
