import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getColumns } from '../../../components/main-table/services';
import { getParams, clone, compare, getResponseErrorMessage } from '../../../Functions/utils';

import { useChangeStorageMutation } from '../../../redux/api/storeApi';
import { useGetUsersListQuery, useDeleteUserMutation } from '../../../redux/api/usersApi';

import TableControls from './components/table-controls/table-controls';
import {
    changeMainTablePagination,
    changeMainTableSorting,
    changeMainTableActiveRow,
    selectUsersTableData
} from '../../../redux/main-table-slice';
import MainTableItems from '../../MainTable/MainTableItems/MainTableItems';
import Loader from '../../Loader';

import './users.scss';

const Users = ({ chapter, storage }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const { activeRow, sorting, pagination } = useSelector(selectUsersTableData);

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page']
    };

    const {
        data: users,
        isSuccess: isUsersLoaded,
        isFetching: isUsersFetching
    } = useGetUsersListQuery(getParams(parameters));

    useEffect(() => {
        if (activeRow) {
            const row = users?.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (users?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: users.pagination
                })
            );
        }
    }, [users]);

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter,
                value: row.value === activeRow?.value && compare(activeRow?.parents, row.parents) ? null : row
            })
        );
    };

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.users.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const [
        deleteUser,
        {
            isLoading: isDeleteUserLoading,
            isSuccess: isDeleteUserSuccess,
            isError: isDeleteUserError,
            error: deleteUserError
        }
    ] = useDeleteUserMutation();

    useEffect(() => {
        if (isDeleteUserSuccess) {
            toast.success(txt.toasts.deleted);

            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: null
                })
            );
        }
    }, [isDeleteUserSuccess]);

    useEffect(() => {
        if (isDeleteUserError) toast.error(getResponseErrorMessage(deleteUserError.data, txt.toasts.saveError));
    }, [isDeleteUserError]);

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = users?.items || [];

    const isLoaded = isUsersLoaded && !isUsersFetching && !isDeleteUserLoading;

    return (
        <>
            <div className="table-container simple-main-table users">
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            activeRow={activeRow}
                            pagination={pagination}
                            deleteUser={deleteUser}
                        />

                        <div className="main-table__visible-content">
                            {columns?.length > 0 ? (
                                <MainTableItems
                                    chapter={chapter}
                                    modifiedChapter={chapter}
                                    columns={columns}
                                    activeRow={activeRow}
                                    rows={rows}
                                    sorting={sorting}
                                    storage={storage}
                                    isLoading={!isLoaded}
                                    onRowClick={onRowClick}
                                    onRowContextMenu={() => null}
                                    handleChange={onTableActionsChange}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Users;
