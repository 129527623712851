import React, { useEffect, useMemo } from 'react';
import { RefreshCw, Plus, Download, Settings, Clock, Trash2 } from 'react-feather';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../../Langs';
import { MENU_TYPES } from '../../../../Constants/index';
import { hideModal, showModal } from '../../../../redux/operations';
import { getResponseErrorMessage, getReplacedString } from '../../../../Functions/utils';

import useDelayedInput from '../../../../Functions/hooks/useDelayedInput';
import useReturnFocus from '../../../../Functions/hooks/useReturnFocus';

import indexApi from '../../../../redux/api/indexApi';
import { conversionsApiTagTypes, useDeleteConversionsMutation } from '../../../../redux/api/conversionsApi';

import {
    changeMainTableFilters,
    changeMainTablePagination,
    changeMainTableActiveRow,
    changeMainTableFilterWithInput
} from '../../../../redux/main-table-slice';

import TableControlContainer from '../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Modules/Overall/UI/Btn/Btn';
import SelectInput from '../../../../Modules/Overall/Forms/Inputs/SelectInput/SelectInput';
import TablePagination from '../../../../Modules/Overall/UI/TablePagination/TablePagination';
import DateRangeInput from '../../../../Modules/Overall/Forms/Inputs/DateRangeInput';
import ConversionUpdateModal from '../../../../Modules/Modals/ConversionUpdateModal';
import DownloadFile, { ShowDownloadFileCheckbox } from '../../../../Modules/Settings/ColumnsSettings/DownloadFile';
import ConversionsLogsModal from '../../../../Modules/Modals/conversions-logs-modal/conversions-logs-modal';
import ConfirmDeleteModal from '../../../../Modules/Modals/confirm-delete-modal/confirm-delete-modal';
import TextInput from '../../../../Modules/Overall/Forms/Inputs/TextInput';
import ContextMenu from '../../../../components/context-menu/context-menu';

const TableControls = ({
    chapter,
    isLoading,
    pagination,
    activeRow,
    tableParameters,
    filters,
    filterWithInput,
    dateRange,
    setDateRange,
    rows,
    columns,
    onShowSettingsClick,
    contextMenuRef,
    contextMenuSettings
}) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const contentSwitcher = chapter;
    const checkedRows = useMemo(() => rows.filter(({ isChecked }) => isChecked).map(({ value }) => value), [rows]);
    const allSelectedRows = [...new Set([...checkedRows, ...[activeRow?.value].filter(el => el)])];

    const onFilterChange = (value, key) => {
        dispatch(
            changeMainTableFilters({
                chapter,
                filterKey: key,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    };

    const onFilterWithInputChange = (value, type) => {
        dispatch(
            changeMainTableFilterWithInput({
                chapter,
                filter: type === 'filter' ? value : filterWithInput.filter,
                value: type === 'value' ? value : filterWithInput.value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    };

    const [filterWithInputValue, setFilterWithInputValue] = useDelayedInput(
        val => onFilterWithInputChange(val, 'value'),
        filterWithInput.value || ''
    );

    const searchIpPrefix = 'IP: ';

    const [searchIp, setSearchIp, searchIpInputRef] = useDelayedInput(
        val => onFilterChange(val.replace(searchIpPrefix, ''), 'clientIp'),
        filters.clientIp || '',
        1000
    );

    useReturnFocus(isLoading, searchIpInputRef, rows);

    const onIpSearchChange = ({ target: { value } }) => {
        if (!value.length) {
            setTimeout(() => setSearchIp({ target: { value: searchIpPrefix } }), 0);
        }

        const re = new RegExp(`(${searchIpPrefix}\\.+)|(\\d{4,})|(\\.{2,})`);
        const isLessThreePoints = getReplacedString(value, `[${searchIpPrefix}\\d]`).length <= 3;
        const isHasPrefix = value.slice(0, searchIpPrefix.length) === searchIpPrefix;

        if (isHasPrefix && !re.test(value) && isLessThreePoints) {
            setSearchIp({ target: { value: getReplacedString(value, `[^${searchIpPrefix}\\d.]`) } });
        }
    };

    const onIpSearchFocus = () =>
        setSearchIp({
            target: {
                value: searchIp?.length > 0 ? searchIp : `${searchIpPrefix}${searchIp}`
            }
        });

    const onIpSearchBlur = () => {
        if (searchIp === searchIpPrefix) {
            setSearchIp({ target: { value: '' } });
        }
    };

    const [
        deleteConversions,
        { isSuccess: isDeleteConversionsSuccess, isError: isDeleteConversionsError, error: deleteConversionsError }
    ] = useDeleteConversionsMutation();

    useEffect(() => {
        if (isDeleteConversionsSuccess) {
            toast.success(txt.toasts.deleted);

            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: null
                })
            );

            hideModal();
        }
    }, [isDeleteConversionsSuccess]);

    useEffect(() => {
        if (isDeleteConversionsError)
            toast.error(getResponseErrorMessage(deleteConversionsError.data, txt.toasts.saveError));
    }, [isDeleteConversionsError]);

    const showConversionUpdateModal = () => {
        showModal({
            title: txt.titles.updateConversions,
            headerType: 'primary',
            body: props => <ConversionUpdateModal {...props} />
        });
    };

    const showDownloadFileModal = () => {
        showModal({
            title: txt.labels.downloadFile,
            className: 'exportFileModal',
            HeaderRightItem: ShowDownloadFileCheckbox,
            body: props => (
                <DownloadFile
                    {...props}
                    chapter={chapter}
                    columns={columns}
                    dataForRender={tableParameters}
                    dateRange={dateRange}
                    rows={rows}
                />
            )
        });
    };

    const showConversionsLogsModal = () => {
        showModal({
            title: txt.titles.conversionsLogs,
            body: props => <ConversionsLogsModal {...props} clickId={activeRow.clickId} initialDateRange={dateRange} />
        });
    };

    const showDeleteModal = () => {
        showModal({
            title: `Delete ${allSelectedRows.length > 1 ? 'converisons' : 'converison'}?`,
            body: props => (
                <ConfirmDeleteModal
                    {...props}
                    title={txt.texts.getWillBeArchivedSubtitle(
                        allSelectedRows.length > 1 ? 'Selected converisons' : activeRow?.id ?? allSelectedRows[0],
                        true
                    )}
                    entity={allSelectedRows.length > 1 ? 'converisons' : 'converison'}
                    onConfirm={() => deleteConversions(allSelectedRows)}
                />
            )
        });
    };

    const getLogsButton = type => (
        <Btn
            className="mr5"
            dataTest={`table-control_logs-btn_${contentSwitcher}`}
            type={type === MENU_TYPES.CONTEXT_MENU ? 'link' : ''}
            disabled={isLoading || !activeRow}
            onClick={showConversionsLogsModal}
        >
            {type !== MENU_TYPES.CONTEXT_MENU && <Clock className="color--grey-gull" />}
            {txt.buttons.logs}
        </Btn>
    );

    const getDeleteButton = type => (
        <Btn
            className="mr5"
            type={type === MENU_TYPES.CONTEXT_MENU ? 'link' : ''}
            dataTest={`table-control_delete-btn_${contentSwitcher}`}
            disabled={isLoading || (!activeRow && !checkedRows.length)}
            onClick={showDeleteModal}
        >
            {type !== MENU_TYPES.CONTEXT_MENU && <Trash2 className="color--grey-gull" />}
            {txt.buttons.delete}
        </Btn>
    );

    return (
        <>
            <TableControlContainer
                TopComponent={
                    <>
                        <SelectInput
                            className="table-control-header__conversions-status-dropdown"
                            dataTest="table-control-header_conversions-status-dropdown"
                            value={filters.status || 'all'}
                            items={[
                                { value: 'all', label: 'All statuses' },
                                { value: 'new', label: 'New' },
                                { value: 'approved', label: 'Approved' },
                                { value: 'declined', label: 'Declined' },
                                { value: 'chargeback', label: 'Chargeback' }
                            ]}
                            disabled={isLoading}
                            onChange={({ target: { value } }) => onFilterChange(value, 'status')}
                        />

                        <div className="table-control-header__twin">
                            <SelectInput
                                value={filterWithInput.filter || 'clickId'}
                                items={[
                                    { value: 'clickId', label: 'Click ID' },
                                    { value: 'externalId', label: 'External ID' },
                                    { value: 'transactionId', label: 'Transaction ID' }
                                ]}
                                disabled={isLoading}
                                onChange={({ target: { value } }) => onFilterWithInputChange(value, 'filter')}
                            />

                            <TextInput
                                dataTest="table-control-header_conversions-value-search-input"
                                placeholder={
                                    filterWithInput.filter === 'transactionId'
                                        ? txt.labels.searchByPartOfValue
                                        : txt.labels.searchByFullValue
                                }
                                value={filterWithInputValue}
                                onChange={setFilterWithInputValue}
                                disabled={isLoading}
                                reset
                            />
                        </div>

                        <TextInput
                            dataTest="table-control-header_conversions-ip-search-input"
                            placeholder={txt.labels.remoteIp}
                            ref={searchIpInputRef}
                            value={searchIp}
                            onChange={onIpSearchChange}
                            onFocus={onIpSearchFocus}
                            onBlur={onIpSearchBlur}
                            disabled={isLoading}
                            reset
                        />

                        <div className="table-control-header__twin">
                            <SelectInput
                                className="table-control-header__conversions-timestamp-dropdown"
                                dataTest="table-control-header_conversions-timestamp-dropdown"
                                value={filters.timestamp || 'postback'}
                                items={[
                                    { value: 'postback', label: 'Postback timestamp' },
                                    { value: 'visit', label: 'Visit timestamp' }
                                ]}
                                disabled={isLoading}
                                onChange={({ target: { value } }) => onFilterChange(value, 'timestamp')}
                            />

                            <DateRangeInput
                                value={dateRange}
                                onChange={data => setDateRange(data)}
                                disabled={isLoading}
                                isCompareHidden
                            />
                        </div>

                        <Btn
                            dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                            type="success"
                            disabled={isLoading}
                            onClick={() =>
                                dispatch(
                                    indexApi.util.invalidateTags([
                                        { type: conversionsApiTagTypes.conversions, id: 'LIST' }
                                    ])
                                )
                            }
                        >
                            <RefreshCw />
                            {txt.buttons.refresh}
                        </Btn>
                    </>
                }
            >
                <Btn
                    dataTest="table-control_update-btn_conversions"
                    className="btn--bold mr5"
                    type="filled"
                    disabled={isLoading}
                    onClick={showConversionUpdateModal}
                >
                    <Plus />
                    {txt.buttons.update} {txt.singulars[contentSwitcher]?.toLowerCase() || contentSwitcher}
                </Btn>

                <Btn
                    dataTest="table-control_export-btn_conversions"
                    className="mr5 table-control_export"
                    disabled={isLoading || !rows.length}
                    onClick={showDownloadFileModal}
                >
                    <Download className="color--grey-gull" />
                    {txt.buttons.export}
                </Btn>

                {getLogsButton()}

                {getDeleteButton()}

                <div className="table-control-container__pagination">
                    <TablePagination
                        value={pagination}
                        onChange={data => {
                            dispatch(
                                changeMainTablePagination({
                                    chapter,
                                    value: data
                                })
                            );
                        }}
                        disabled={isLoading}
                    />

                    <Btn
                        dataTest={`table-control_pagination-settings-btn_${contentSwitcher}`}
                        type="icon"
                        onClick={onShowSettingsClick}
                        disabled={isLoading}
                    >
                        <Settings />
                    </Btn>
                </div>
            </TableControlContainer>

            <ContextMenu
                contextMenuRef={contextMenuRef}
                contextMenuSettings={contextMenuSettings}
                buttons={[getLogsButton, getDeleteButton]}
            />
        </>
    );
};

export default TableControls;
