import React from 'react';
import { PieChart } from 'react-feather';

import Langs from '../../../../Langs';

import Btn from '../../../../Modules/Overall/UI/Btn/Btn';

const ChartButton = ({ chapter, isDisabled, onClick, type, isChartOpen }) => {
    const txt = Langs[global.lng];

    return (
        <Btn dataTest={`table-control__charts-btn--${chapter}`} type={type} disabled={isDisabled} onClick={onClick}>
            <PieChart />
            {isChartOpen ? txt.buttons.closeChart : txt.buttons.charts}
        </Btn>
    );
};

export default ChartButton;
