import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import './limit-progress-bar.scss';

const LimitProgressBar = ({ amount, totalAmount, isReverseType, children, className, radius = 59 }) => {
    const circumference = Math.round(2 * Math.PI * radius);
    const amountProgress = amount < totalAmount ? Math.round((amount / totalAmount) * 100) : 100; //%
    const color = {
        start: '#6b81dd',
        finish: '#f06d8d',
        meter: '#ffcad6',
        background: '#fff'
    };

    const [strokeDashoffset, setStrokeDashoffset] = useState(isReverseType ? String(0) : circumference);

    useEffect(() => {
        setStrokeDashoffset(`calc(${circumference} - (${circumference} * ${amountProgress}) / 100)`);
    }, [amountProgress]);

    return (
        <div className={cn('limit-progress-bar', { 'limit-progress-bar--reverse': isReverseType }, className)}>
            <div className="limit-progress-bar__progress-wrapper">
                <svg className="limit-progress-bar__progress" width="124" height="124" viewBox="0 0 124 124">
                    <defs>
                        {isReverseType ? (
                            <linearGradient id="limit-progress-bar-linear-gradient-reverse" x1="0" y1="1" x2="0" y2="0">
                                <stop offset="0" stopColor={color.finish} />
                                <stop offset="1" stopColor={color.start} />
                            </linearGradient>
                        ) : (
                            <linearGradient id="limit-progress-bar-linear-gradient" x1="0" y1="1" x2="0" y2="0">
                                <stop offset="0" stopColor={color.start} />
                                <stop offset="1" stopColor={color.finish} />
                            </linearGradient>
                        )}
                    </defs>
                    <circle
                        className="limit-progress-bar__meter"
                        cx={radius}
                        cy={radius}
                        r={radius}
                        stroke={color.meter}
                        strokeWidth="2"
                    />
                    <circle
                        className="limit-progress-bar__value"
                        cx={radius}
                        cy={radius}
                        r={radius}
                        strokeWidth="4"
                        stroke={`url(#limit-progress-bar-linear-gradient${isReverseType ? '-reverse' : ''})`}
                        style={{
                            strokeDasharray: circumference,
                            strokeDashoffset
                        }}
                    />
                </svg>
            </div>
            <div className="limit-progress-bar__content">{children}</div>
        </div>
    );
};

export default LimitProgressBar;
