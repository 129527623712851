import React from 'react';

const TdLink = props => {
    const { item, onClick, row } = props;

    return (
        <div className="j4">
            <span data-test={`td-link_${row.id}`} className="color--primary pointer" onClick={onClick}>
                {item}
            </span>
        </div>
    );
};

export default TdLink;
