import indexApi from './indexApi';
import { storage as storageTemplate, STORAGE_VERSION } from '../initialState/storage';
import { ADD_VALUES_CHAPTERS } from '../../Constants';
import { correctStorage } from '../operations';
import { compare } from '../../Functions/utils';

const storeApitagTypes = {
    storage: 'storage'
};

export const storeApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(storeApitagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getStorage: builder.query({
            providesTags: [storeApitagTypes.storage],
            async queryFn(arg, { dispatch }, extraOptions, fetchWithBQ) {
                const сonversionsColumns = await fetchWithBQ(`/custom-conversion-events/list/custom-column-action`);
                if (сonversionsColumns.error) return { error: сonversionsColumns.error };

                const storageData = await fetchWithBQ(`/store/storage`);
                if (storageData.error) return { error: storageData.error };

                const storage = storageData.data;
                const customConversionsColumns = сonversionsColumns.data;

                if (!storage) {
                    await dispatch(indexApi.endpoints.changeStorage.initiate(storageTemplate));
                    return { data: storageTemplate };
                }

                // reset
                if (!storage.storageControledVersion || storage.storageControledVersion < STORAGE_VERSION) {
                    Object.keys(storageTemplate).forEach(storageKey => {
                        if (ADD_VALUES_CHAPTERS.includes(storageKey)) {
                            const { columns } = storageTemplate[storageKey];

                            const orderedCustomConversionsColumns = customConversionsColumns.map((column, i) => {
                                return { ...column, order: columns.length + 1 + i };
                            });

                            storageTemplate[storageKey].columns = columns.concat(orderedCustomConversionsColumns);
                        }
                    });

                    storageTemplate.common.customConversions = customConversionsColumns;

                    return { data: storageTemplate };
                }

                const correctedStorage = correctStorage(storage, storageTemplate, customConversionsColumns);

                if (!compare(storage, correctedStorage)) {
                    await dispatch(indexApi.endpoints.changeStorage.initiate(correctedStorage));
                    return { data: correctedStorage };
                }

                return { data: storage };
            }
        }),

        changeStorage: builder.mutation({
            query: data => ({
                url: '/store/storage',
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error) => {
                if (error) return [storeApitagTypes.storage];
                else return [];
            },
            onQueryStarted(data, { dispatch, queryFulfilled }) {
                dispatch(indexApi.util.updateQueryData('getStorage', undefined, () => data));

                // FIXME: Remove it after migrate to RTQuery
                queryFulfilled.catch(() => {
                    // eslint-disable-next-line no-console
                    console.error('Failed to save in storage!');
                    // eslint-disable-next-line no-alert
                    alert('Something went wrong, the data is incorrect, the page will be refreshed');
                    window.location.reload();
                });
            }
        })
    })
});

export const { useGetStorageQuery, useChangeStorageMutation } = storeApi;
