import React from 'react';

import CheckBoxInput from '../../Overall/Forms/Inputs/CheckBoxInput';
import FlowCampaignsListItem from '../../Items/NewItem/components/FlowMainOption/FlowCampaignsListItem/FlowCampaignsListItem';

import '../modals.scss';

const CampaignItem = props => {
    const {
        flowsList,
        campaign,
        currentFlowValue,
        onChange,
        getGlobalCampaignCheckboxValues,
        setGlobalCampaignCheckbox,
        commonSettings
    } = props;
    const changeCheckboxValue = value => {
        const newCampaign = { ...campaign, isDeleted: value };
        onChange(newCampaign);
        setGlobalCampaignCheckbox(getGlobalCampaignCheckboxValues(newCampaign));
    };

    return (
        <div className="archive-flow-modal__row">
            <CheckBoxInput
                className="archive-flow-modal__row-checkbox"
                dataTest="archive-flow-row-checkbox"
                label={null}
                value={campaign.isDeleted}
                onChange={({ target: { checked } }) => changeCheckboxValue(checked)}
            />
            <FlowCampaignsListItem
                className="archive-flow-modal__row-campaign"
                type="modal"
                flows={flowsList}
                currentFlow={currentFlowValue}
                commonSettings={commonSettings}
                campaign={{ ...campaign, isDeleted: !campaign.isDeleted }}
                onChange={onChange}
            />
        </div>
    );
};

export default CampaignItem;
