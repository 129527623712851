import React from 'react';
import cn from 'classnames';

import RestoreButton from '../restore-button/restore-button';
import MultiRestoreButton from '../multi-restore-button/multi-restore-button';

const RestoreGroupButton = ({
    chapter,
    isDisabled,
    isHideDisabled,
    isActive,
    isJustButtonDisabled,
    isMultiButtonDisabled,
    rowsForReport,
    entityName,
    entityId,
    rows,
    type
}) => {
    return (
        <div
            className={cn('table-control-archive', {
                disabled: isDisabled,
                'hide-disabled': isHideDisabled,
                active: isActive
            })}
        >
            <div className="table-control-archive_inner">
                <RestoreButton
                    chapter={chapter}
                    entityId={entityId}
                    entityName={entityName}
                    isDisabled={isJustButtonDisabled}
                    type={type}
                />

                <MultiRestoreButton
                    chapter={chapter}
                    isDisabled={isMultiButtonDisabled}
                    type={type}
                    rowsForReport={rowsForReport}
                    rows={rows}
                />
            </div>
        </div>
    );
};

export default RestoreGroupButton;
