import React, { useMemo, useState } from 'react';

import Langs from '../../../Langs';
import { omitKeys } from '../../../Functions/utils';
import useSearch from '../../../Functions/hooks/useNewSearch';

import { useGetCountriesQuery, useGetCurrenciesQuery } from '../../../redux/api/systemApi';

import SelectInput from '../Forms/Inputs/SelectInput/SelectInput';
import TextInput from '../Forms/Inputs/TextInput';
import ModalButtonBar from '../Modal/ModalButtonBar/ModalButtonBar';
import SelectTableItems from './SelectTableItems';
import Loader from '../../Loader';

import { selectTableConstructor } from './constructors/selectTableConstructor';

import './select-table.scss';

const Filter = ({
    type,
    value,
    onChange,
    className,
    countries,
    includedCounryTagList,
    dataTest,
    unfilteredRows,
    columns,
    modalType,
    activeTrackingMethod
}) => {
    const txt = Langs[global.lng];
    const baseRows =
        modalType === 'campaignsForFlow'
            ? unfilteredRows.filter(item => item?.trackingMethod?.value === activeTrackingMethod)
            : unfilteredRows;

    /*if (type === 'tags') {
        return (
            <TagInput
                dataTest={`filter_${dataTest}`}
                label={txt.labels.selectStartEnteringTags}
                value={value || []}
                onChange={onChange}
                className={className}
            />
        );} */
    if (type === 'countryTag') {
        const countriesList = countries.filter(el => includedCounryTagList.includes(el.value));

        const items = [
            { value: 'ALL', label: txt.labels.all },
            ...countriesList.map(el => ({
                value: el.value,
                label: el.value === 'GO' ? 'Global' : el.label
            }))
        ];

        return (
            <SelectInput
                dataTest={`filter_${dataTest}_country-tag-select-input`}
                label={txt.labels.countryTag}
                placeholder={txt.labels.countryTag}
                value={value}
                onChange={onChange}
                items={items}
                className={className}
                isSearchable
            />
        );
    }

    if (type === 'rotationValue') {
        const items = [
            { value: 'all', label: txt.labels.all },
            { value: 'flow', label: txt.labels.flow },
            { value: 'campaign', label: txt.labels.campaignSettings },
            {
                label: txt.labels.flows,
                items: Array.from(new Set(baseRows.filter(item => !!item.rotationId).map(item => item.rotationId))).map(
                    item => ({
                        value: item,
                        label: baseRows.find(el => el.rotationId === item).rotationName.split('flow:')[0]
                    })
                )
            }
        ];

        return (
            <SelectInput
                dataTest={`filter_${dataTest}_rotation-value-select-input`}
                label={columns.find(item => item.key === 'rotationName').label}
                placeholder={null}
                value={value}
                onChange={onChange}
                items={items}
                className={className}
                // isSearchable
            />
        );
    }

    if (type.includes('possibleValues')) {
        const params = type.split(':');
        const valueKey = params[1],
            labelKey = params[2];

        const items = Array.from(new Set(baseRows.map(item => item[valueKey]))).map(item => ({
            value: item,
            label: baseRows.find(el => el[valueKey] === item)[labelKey]
        }));
        items.unshift({ value: 'all', label: txt.labels.all });
        return (
            <SelectInput
                dataTest={`filter_${dataTest}_possible-values-select-input`}
                label={columns.find(item => item.key === labelKey).label}
                placeholder={null}
                value={value}
                onChange={onChange}
                items={items}
                className={className}
                isSearchable
            />
        );
    }

    if (type === 'trackingMethod') {
        const items = [
            { value: 'redirect', label: txt.labels.redirect },
            { value: 'direct', label: txt.labels.direct }
        ];

        return (
            <SelectInput
                dataTest={`filter_${dataTest}_tracking-method-select-input`}
                label={txt.labels.trackingMethod}
                placeholder={null}
                value={value}
                onChange={onChange}
                items={items}
                className={className}
                disabled
            />
        );
    }

    return null;
};

const SelectTable = ({
    selectedIds: initialIds,
    type,
    rows: unfilteredRows,
    onConfirm,
    replacementId,
    noFilter,
    onClose,
    isLoading,
    limit,
    activeTrackingMethod,
    addAlert,
    itemsLimit
}) => {
    const txt = Langs[global.lng];

    const { data: countries, isSuccess: isCountriesLoaded } = useGetCountriesQuery();
    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();

    const { columns, filters, width, height } = selectTableConstructor[type];

    const initialFilterValues = {
        ...filters.reduce((ac, item) => (item === 'rotationValue' ? { ...ac, [item]: 'all' } : ac), {}),
        ...filters.reduce((ac, item) => (item.includes('possibleValues') ? { ...ac, [item]: 'all' } : ac), {}),
        ...(type === 'campaignsForFlow' ? { trackingMethod: activeTrackingMethod } : {})
    };

    const [filter, setFilter] = useState(initialFilterValues);

    const [selectedIds, setSelectedIds] = useState(
        replacementId ? useMemo(() => initialIds.filter(el => !initialIds.includes(el)), [initialIds]) : initialIds
    );

    const isAllValid = selectedIds.length > 0;

    const {
        filteredItems: rows,
        setSearchByForInput,
        searchByValue
    } = useSearch(
        replacementId
            ? useMemo(() => unfilteredRows.filter(el => !initialIds.includes(el.value)), [unfilteredRows])
            : unfilteredRows,
        ({ name }) => name
    );

    const filteredRows = useMemo(() => {
        return Object.keys(filter).reduce((acc, key) => {
            if (key === 'countryTag') {
                return acc.filter(el => el[key] === filter[key]);
            } else if (key === 'tags') {
                return acc.filter(el => filter[key].length === 0 || el[key]?.some(tag => filter[key].includes(tag)));
            } else if (key === 'rotationValue') {
                if (filter[key] === 'all') return acc;
                if (filter[key] === 'campaign') return acc.filter(el => el.rotationId === null);
                if (filter[key] === 'flow') return acc.filter(el => el.rotationId !== null);
                return acc.filter(el => el.rotationId === filter[key]);
            } else if (key.includes('possibleValues')) {
                const params = key.split(':');
                const valueKey = params[1];
                if (filter[key] === 'all') return acc;
                else return acc.filter(el => el[valueKey] === filter[key]);
            } else if (key === 'trackingMethod') {
                return acc.filter(el => el.trackingMethod.value === filter[key]);
            }
            return acc;
        }, rows);
    }, [filter, rows]);

    const includedCounryTagList = new Set(unfilteredRows.map(el => el.countryTag));

    const handleFilterChange = (e, key) => {
        if (key === 'countryTag' && e.target.value === 'ALL') setFilter(state => omitKeys(state, [key]));
        else if (key === 'tags') setFilter(state => ({ ...state, [key]: e }));
        else setFilter(state => ({ ...state, [key]: e.target.value }));
    };

    const isLoaded = isCountriesLoaded && isCurrenciesLoaded;

    return (
        <>
            <div className="select-table" style={{ width, height }}>
                {!isLoading && isLoaded ? (
                    <>
                        {!noFilter ? (
                            <div className="select-table__filter">
                                {type === 'campaignsForFlow' && (
                                    <Filter
                                        dataTest={type}
                                        type={'trackingMethod'}
                                        rows={rows}
                                        columns={columns}
                                        unfilteredRows={unfilteredRows}
                                        onChange={e => handleFilterChange(e, 'trackingMethod')}
                                        value={filter.trackingMethod}
                                        countries={countries}
                                    />
                                )}

                                <TextInput
                                    dataTest={`select-table_${type}_name-input`}
                                    label={txt.labels.name}
                                    placeholder={txt.placeholders.search}
                                    onChange={setSearchByForInput}
                                    value={searchByValue}
                                    reset
                                />

                                {filters?.map(filterType => (
                                    <div key={filterType} className={`select-table__filter--${filterType}`}>
                                        <Filter
                                            dataTest={type}
                                            type={filterType}
                                            rows={rows}
                                            columns={columns}
                                            unfilteredRows={unfilteredRows}
                                            onChange={e => handleFilterChange(e, filterType)}
                                            value={filter[filterType]}
                                            countries={countries}
                                            includedCounryTagList={[...includedCounryTagList]}
                                            modalType={type}
                                            activeTrackingMethod={activeTrackingMethod}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        <SelectTableItems
                            columns={columns}
                            rows={filteredRows}
                            ids={selectedIds}
                            changeIds={setSelectedIds}
                            replacementId={replacementId}
                            limit={limit}
                            countries={countries}
                            currencies={currencies}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </div>

            <ModalButtonBar
                rejectButtonText={txt.buttons.cancel}
                confirmButtonText={txt.buttons.apply}
                selectedItemsCount={replacementId ? null : selectedIds.length}
                isConfirmDisabled={!isAllValid || (!replacementId && selectedIds.length > itemsLimit)}
                onConfirm={() => onConfirm(selectedIds)}
                onReject={onClose}
                isLoading={isLoading}
                addAlert={
                    addAlert && !replacementId
                        ? {
                              alertClassName: 'select-table__modal-button-bar-alert',
                              alertType: selectedIds.length > itemsLimit ? 'danger' : 'warning',
                              alertMessage: `Max ${itemsLimit} ${type}`
                          }
                        : null
                }
            />
        </>
    );
};

export default SelectTable;
