import React from 'react';
import cn from 'classnames';

import JustTableTh from './components/just-table-th';
import JustTableTd from './components/just-table-td';

import './just-table.scss';

const JustTable = ({
    className,
    hideTableTh,
    header,
    cols,
    rows,
    onChange,
    onActionClick,
    isControledWidth,
    gap,
    timezones,
    isHeaderDecorate
}) => {
    const allSizeCount = cols.reduce((acc, el) => acc + (el.size ?? 1), 0);
    const getWidth = (size = 1) => (size * 100) / allSizeCount + '%';

    return (
        <div className={cn('just-table', className, { 'just-table--header-decorate': isHeaderDecorate })}>
            <div className="just-table__wrapper">
                <div className="just-table__row just-table__row-header">
                    {!hideTableTh
                        ? cols.map(item => (
                              <JustTableTh item={item} width={getWidth(item.size)} gap={gap} key={item.key} />
                          ))
                        : null}
                </div>

                {header && <span className="just-table__header">{header}</span>}

                {rows.map((row, index) => {
                    return (
                        <div
                            key={row.id || row.value || index}
                            className={cn('just-table__row', row.disabled ? 'just-table__row--disabled' : '')}
                        >
                            {cols.map((item, colIndex) => (
                                <div
                                    key={`row-${item.key}`}
                                    className={cn('just-table__cell', item.cellClassName, {
                                        'visibility-hidden': item.hidden
                                    })}
                                    style={{
                                        ...(isControledWidth ? { width: getWidth(item.size) } : {}),
                                        ...(gap ? { paddingRight: gap } : {})
                                    }}
                                >
                                    <JustTableTd
                                        params={{
                                            index,
                                            key: item.key,
                                            type: item.type,
                                            columnOptions: cols[colIndex]
                                        }}
                                        rows={rows}
                                        onChange={onChange}
                                        onActionClick={onActionClick}
                                        timezones={timezones}
                                    />
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default JustTable;
