import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import { getWidth } from '../../../../Functions/utils';

const TdHidingContainer = ({ data, containerClassName, counterClassName, parentWidth }) => {
    const hidingContainerRef = useRef(null);
    const curtainRef = useRef(null);
    const counterRef = useRef(null);
    const [sizes, setSizes] = useState(null);
    const childrenRefs = Array.from(curtainRef.current?.children || []);

    useEffect(() => {
        const result = {};

        const containerWidths = parentWidth ?? getWidth(hidingContainerRef?.current.parentElement);
        const leftPadding = parseInt(getComputedStyle(hidingContainerRef?.current.parentElement).paddingLeft, 10);
        const rightPadding = parseInt(getComputedStyle(hidingContainerRef?.current.parentElement).paddingRight, 10);
        const calculatedContainerWidth = containerWidths - leftPadding - rightPadding;

        // hidingContainerRef.current.parentElement.style.width = containerWidths + 'px';
        // hidingContainerRef.current.parentElement.style.minWidth = containerWidths + 'px';
        // hidingContainerRef.current.parentElement.style.maxWidth = containerWidths + 'px';

        result.calculatedContainerWidth = calculatedContainerWidth;

        const counterExternalWidth = getWidth(counterRef?.current, ['margin-left']) || data.value?.length > 1 ? 35 : 0;

        result.calculatedContentWidth = calculatedContainerWidth - counterExternalWidth;

        setSizes(result);
    }, [data, parentWidth]);

    const [params, setParams] = useState({
        curtainWidth: 0,
        unvisibleChildrensCount: 0
    });

    useEffect(() => {
        if (sizes) {
            let counter = 0;
            let totalWisibleWidth = 0;

            for (let i = 0; i < childrenRefs.length; i++) {
                const childWidth = getWidth(childrenRefs[i], ['margin-left']);

                if (totalWisibleWidth + childWidth < sizes.calculatedContentWidth) {
                    totalWisibleWidth += childWidth;
                    counter++;
                } else break;
            }

            if (!counter) {
                const halfFirstElementWidth = getWidth(childrenRefs[0], ['margin-left']) / 2;
                if (halfFirstElementWidth < sizes.calculatedContentWidth && childrenRefs[0]) {
                    totalWisibleWidth += sizes.calculatedContentWidth;
                    counter++;
                }
            }

            setParams({
                curtainWidth: totalWisibleWidth,
                unvisibleChildrensCount: childrenRefs.length - counter
            });
        }
    }, [sizes, parentWidth]);

    return (
        <div
            className={cn('hiding-container', containerClassName)}
            ref={hidingContainerRef}
            style={sizes ? { width: sizes.calculatedContainerWidth } : {}}
        >
            {data ? (
                <>
                    <div
                        className="hiding-container__curtain"
                        ref={curtainRef}
                        style={{ width: `${params.curtainWidth}px` }}
                    >
                        {data.value?.map((item, i) => {
                            return (
                                <div className="notifications__values-field-item" key={`child-container-${item}-${i}`}>
                                    {item}
                                </div>
                            );
                        })}
                    </div>

                    {params?.unvisibleChildrensCount > 0 && (
                        <span
                            ref={counterRef}
                            className={cn('hiding-container__counter ml8', counterClassName)}
                        >{`+${params?.unvisibleChildrensCount}`}</span>
                    )}
                </>
            ) : null}
        </div>
    );
};
export default TdHidingContainer;
