import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Langs from '../../../Langs';
import { getColumns } from '../../../components/main-table/services';
import { getParams, clone, compare, getResponseErrorMessage } from '../../../Functions/utils';

import { useChangeStorageMutation } from '../../../redux/api/storeApi';
import { useGetInvitationsListQuery, useDeleteInvitationMutation } from '../../../redux/api/invitationsApi';

import TableControls from './components/table-controls/table-controls';
import {
    changeMainTablePagination,
    changeMainTableSorting,
    changeMainTableActiveRow,
    selectInvitationsTableData
} from '../../../redux/main-table-slice';
import MainTableItems from '../../MainTable/MainTableItems/MainTableItems';
import Loader from '../../Loader';

import './invitations.scss';

const Invitations = ({ chapter, storage }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const { activeRow, sorting, pagination } = useSelector(selectInvitationsTableData);

    const parameters = {
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page']
    };

    const {
        data: invitations,
        isSuccess: isInvitationLoaded,
        isFetching: isInvitationFetching
    } = useGetInvitationsListQuery(getParams(parameters));

    useEffect(() => {
        if (activeRow) {
            const row = invitations?.items?.find(el => el.value === activeRow.value);

            if (row && !compare(activeRow, row)) {
                dispatch(
                    changeMainTableActiveRow({
                        chapter,
                        value: row
                    })
                );
            }
        }

        if (invitations?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: invitations.pagination
                })
            );
        }
    }, [invitations]);

    const onRowClick = row => {
        dispatch(
            changeMainTableActiveRow({
                chapter,
                value: row.value === activeRow?.value && compare(activeRow?.parents, row.parents) ? null : row
            })
        );
    };

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );
                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.invitations.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const [
        deleteInvitation,
        {
            isLoading: isDeleteInvitationLoading,
            isSuccess: isDeleteInvitationSuccess,
            isError: isDeleteInvitationError,
            error: deleteInvitationError
        }
    ] = useDeleteInvitationMutation();

    useEffect(() => {
        if (isDeleteInvitationSuccess) {
            toast.success(txt.toasts.deleted);

            dispatch(
                changeMainTableActiveRow({
                    chapter,
                    value: null
                })
            );
        }
    }, [isDeleteInvitationSuccess]);

    useEffect(() => {
        if (isDeleteInvitationError)
            toast.error(getResponseErrorMessage(deleteInvitationError.data, txt.toasts.saveError));
    }, [isDeleteInvitationError]);

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = invitations?.items || [];

    const isLoaded = isInvitationLoaded && !isInvitationFetching && !isDeleteInvitationLoading;

    return (
        <>
            <div className="table-container simple-main-table users">
                <div className="main-table">
                    <div className="main-table__wrapper">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            activeRow={activeRow}
                            pagination={pagination}
                            deleteInvitation={deleteInvitation}
                        />

                        <div className="main-table__visible-content">
                            {columns?.length > 0 ? (
                                <MainTableItems
                                    chapter={chapter}
                                    modifiedChapter={chapter}
                                    columns={columns}
                                    activeRow={activeRow}
                                    rows={rows}
                                    sorting={sorting}
                                    storage={storage}
                                    isLoading={!isLoaded}
                                    onRowClick={onRowClick}
                                    onRowContextMenu={() => null}
                                    handleChange={onTableActionsChange}
                                />
                            ) : (
                                <Loader />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Invitations;
