import React from 'react';

import Langs from '../../../Langs';

import CheckBoxInput from '../../../Modules/Overall/Forms/Inputs/CheckBoxInput';
import Switcher from '../../../Modules/Overall/UI/Switcher/Switcher';

import { TdActions, TdDelete, TdText } from '../../../Modules/Overall/ItemsTable/TdTypes';
import TdDomain from '../../../Modules/Overall/ItemsTable/TdTypes/TdDomain';
import JustTdIndex from '../../../Modules/Overall/ItemsTable/TdTypes/just-td-index/just-td-index';
import WorkspaceList from '../../../Modules/Overall/ItemsTable/TdTypes/WorkspaceList';
import TdRounding from '../../../Modules/Overall/ItemsTable/TdTypes/TdRounding';
import TdStatus from '../../../Modules/Overall/ItemsTable/TdTypes/TdStatus';
import TdDate, { TdDateTypes } from '../../../Modules/Overall/ItemsTable/TdTypes/TdDate';
import TdLink from '../../../Modules/Overall/ItemsTable/TdTypes/TdLink';
import TdBadges from '../../../Modules/Overall/ItemsTable/TdTypes/TdBadges';
import TdCriteria from '../../../Modules/Overall/ItemsTable/TdTypes/TdCriteria';
import { TdTextArray } from '../../../Modules/Overall/ItemsTable/TdTypes/TdTextArray';
import TdStatusWithUpdating from '../../../Modules/Overall/ItemsTable/TdTypes/TdStatusWithUpdating';
import TdHidingContainer from '../../../Modules/Overall/ItemsTable/TdTypes/TdHidingContainer';
import TdPrice from '../../../Modules/Overall/ItemsTable/TdTypes/TdPrice';
import JustTdIntegrationName from '../../../Modules/Overall/ItemsTable/TdTypes/just-td-integration-name/just-td-integration-name';
import TdSwitcherWithStatus from '../../../Modules/Overall/ItemsTable/TdTypes/td-switcher-with-status/td-switcher-with-status';
import RadioButton from '../../../Modules/Overall/Forms/Inputs/RadioButton';
import JustSupportAccessDate from '../../../Modules/Overall/ItemsTable/TdTypes/just-support-access-date/just-support-access-date';
import JustTdTimezone from '../../../Modules/Overall/ItemsTable/TdTypes/just-td-timezone/just-td-timezone';
import JustTdGenerate from '../../../Modules/Overall/ItemsTable/TdTypes/just-td-generate/just-td-generate';

const JustTableTd = ({ params, rows, onChange, onActionClick, timezones, currencies }) => {
    const { index, key, columnOptions = null } = params;
    const txt = Langs[global.lng];
    const row = rows[index];
    const item = row[key];

    const handleCheckboxChange = event => {
        if (typeof item === 'object') {
            item.value = event.target.checked;
        } else rows[index][key] = event.target.checked;
        const data = { index, key, isEnabled: event.target.checked };
        onChange(data);
    };

    // const handleRadioChange = event => {
    //     rows.forEach(line => {
    //         line[key] = false;
    //     });
    //     rows[index][key] = event.target.checked;
    //     onChange(rows[index], 'radioGroupEvent');
    // };

    // const handleUpdateStatus = async () => {
    //     return updateStatus();
    // };

    switch (params.type) {
        case 'actions':
            return (
                <div className="itemsTable-actions no-wrap">
                    <TdActions
                        row={row}
                        actions={item}
                        onActionClick={onActionClick}
                        disabled={row?.isLoading || row?.isLocalLoading}
                    />
                </div>
            );
        case 'radio':
            return (
                <RadioButton
                    dataTest={`table-td-item_${row.id || row.value}`}
                    isChecked={item}
                    onChange={({ target: { value } }) => onActionClick(key, row, value)}
                />
            );
        case 'integrationName':
            return <JustTdIntegrationName text={item} />;
        case 'domain':
            return <TdDomain item={item} dnsStatus={row?.dnsStatus} />;
        case 'statusWithUpdating':
            return (
                <TdStatusWithUpdating
                    item={item}
                    domainID={rows[index].id}
                    onActionClick={onActionClick}
                    rowIndex={index}
                />
            );
        case 'index':
            return <JustTdIndex item={item} campaignName={row.campaignName} />;
        case 'supportAccessDate':
            return <JustSupportAccessDate dateFrom={row.dateFrom} dateTo={row.dateTo} isExpired={row.isExpired} />;
        case 'supportAccessTimezone':
            return <JustTdTimezone timezone={item} timezones={timezones} className="just-td-timezone__light" />;
        //
        //
        //
        //

        case 'badges':
            return <TdBadges row={row} items={item} />;
        case 'checkbox':
            return (
                <div className="j5">
                    <CheckBoxInput
                        dataTest={`table-td-item_${key}-checkbox`}
                        onChange={handleCheckboxChange}
                        value={item}
                    />
                </div>
            );
        case 'criteria':
            return <TdCriteria items={item} row={row} />;
        case 'date':
            return <TdDate item={item} disabled={row?.isDeleted} />;
        case 'dateOnly':
            return <TdDate item={item} type={TdDateTypes.DATE_ONLY} disabled={row?.isDeleted} />;
        case 'switcher':
            return (
                <Switcher
                    dataTest={`table-td-item_${key}`}
                    isSwitchOn={item}
                    onChange={status => onActionClick(key, row, status)}
                    disabled={row?.isDeleted || row?.isLoading || row?.isLocalLoading}
                />
            );
        case 'switcherWithStatus':
            return (
                <TdSwitcherWithStatus
                    isSwitchOn={item}
                    text={row.switcherText}
                    onChange={status => onActionClick(key, row, status)}
                    isColored
                    color={row.switcherTextColor}
                />
            );

        case 'editableParam':
            return (
                <div className="j7 table__cell_min-height">
                    <TdActions
                        columnOptions={columnOptions}
                        actions={item.actions}
                        row={row}
                        onActionClick={onActionClick}
                    />
                    <TdText item={item} type={params.type} columnOptions={columnOptions} />
                </div>
            );
        case 'link':
            return <TdLink row={row} item={item} onClick={() => onActionClick('link', row)} />;
        case 'list':
            return <WorkspaceList items={item} />;

        case 'rounding':
            return (
                <div className="j7 table__cell_min-height">
                    <TdRounding
                        item={item}
                        rows={rows}
                        index={index}
                        params={params}
                        onChange={onChange}
                        columnOptions={columnOptions}
                    />
                </div>
            );
        case 'status':
            return <TdStatus item={item} disabled={row?.isDeleted} />;
        case 'deleteCustomCol':
            return <TdDelete rows={rows} index={index} onClick={onActionClick} />;
        case 'description':
            return (
                <TdText
                    item={txt.labels.columnsSettingsDesc[row.key] || ''}
                    type={params.type}
                    columnOptions={columnOptions}
                />
            );
        case 'label':
            return (
                <TdText
                    item={txt.labels[item] || item}
                    type={params.type}
                    columnOptions={columnOptions}
                    disabled={row?.isDeleted}
                />
            );
        case 'textArray':
            return (
                <TdTextArray item={item} type={params.type} columnOptions={columnOptions} disabled={row?.isDeleted} />
            );
        case 'hidingContainer':
            return (
                <TdHidingContainer
                    data={item}
                    counterClassName={columnOptions?.counterClassName}
                    containerClassName={columnOptions?.containerClassName}
                />
            );
        case 'price':
            return (
                <TdPrice
                    defaultCurrency={row?.currency || ''}
                    item={item}
                    columnOptions={columnOptions}
                    disabled={row?.isDeleted}
                    currencies={currencies}
                />
            );

        case 'generate':
            return <JustTdGenerate row={row} />;

        default:
            return <TdText item={item} type={params.type} columnOptions={columnOptions} disabled={row?.isDeleted} />;
    }
};

export default JustTableTd;
