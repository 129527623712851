import indexApi from './indexApi';

export const importApiTagTypes = {
    import: 'import'
};

const importApi = indexApi.enhanceEndpoints({ addTagTypes: Object.values(importApiTagTypes) }).injectEndpoints({
    endpoints: builder => ({
        getImportList: builder.query({
            query: () => `/import/tracker-list`,
            providesTags: () => [importApiTagTypes.import]
        }),

        sendImportAuth: builder.mutation({
            query: ({ tracker, data }) => ({
                url: `/import/${tracker}/auth`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const { useGetImportListQuery, useSendImportAuthMutation } = importApi;
