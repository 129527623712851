import React from 'react';

import { showModal } from '../../../../../redux/operations';

import FacebookPostbackUrkModal from '../../../../Modals/facebook-postback-url-modal/facebook-postback-url-modal';

import './just-td-generate.scss';

const JustTdGenerate = ({ row }) => {
    const integrationType = row.name?.split(' ')[0];

    const showPostbackModal = () => {
        showModal({
            title: `Facebook Postback URL`,
            body: props => <FacebookPostbackUrkModal {...props} integrationId={row.id} />
        });
    };

    switch (integrationType) {
        case 'Facebook':
            return (
                <div className="just-td-generate" onClick={showPostbackModal}>
                    Generate
                </div>
            );

        default:
            return null;
    }
};

export default JustTdGenerate;
