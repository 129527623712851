import { ENTITY_MODE_STATUS } from '../../../Constants';
import { camelToKebabCase } from '../../../Functions/utils';

const checkNavigate = (chapter, action, pageStatus, locationState, setPageStatus, history) => {
    if (action && (pageStatus.mode === ENTITY_MODE_STATUS.DEFAULT || pageStatus.mode === 'charts')) {
        const isNew = action === 'new';

        const mode = (() => {
            if (locationState?.mode) return locationState.mode;
            return isNew ? ENTITY_MODE_STATUS.CREATE : ENTITY_MODE_STATUS.EDIT;
        })();

        const id = (() => {
            if (isNew && mode === ENTITY_MODE_STATUS.DUPLICATE) return locationState.id;
            if (isNew) return null;
            return action;
        })();

        setPageStatus({ mode, value: id, type: chapter });
    }

    if (!action && pageStatus?.mode !== ENTITY_MODE_STATUS.DEFAULT) {
        if (pageStatus?.mode === 'charts') return;

        if (locationState?.mode === ENTITY_MODE_STATUS.DEFAULT) {
            setPageStatus({ mode: ENTITY_MODE_STATUS.DEFAULT, value: null, type: chapter });
            return;
        }

        history.push(
            `/home/${camelToKebabCase(pageStatus.type)}/${
                pageStatus.mode === ENTITY_MODE_STATUS.EDIT ? pageStatus.value : 'new'
            }`,
            {
                mode: pageStatus.mode,
                id: pageStatus.value
            }
        );
    }
};

export default checkNavigate;
