import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'focus-visible';

import Langs from './Langs';
import { transform } from './redux/transformers';
import getRoutes from './routes';
import {
    clearAccountInitialisation,
    showModal,
    hideModal,
    hideAllModals,
    checkDontReloadUrls
} from './redux/operations';
import {
    getCredentials,
    getLocalStorageUserInfo,
    setTrialModalLastShowTime,
    getTrialModalLastShowTime,
    removeTrialModalLastShowTime,
    getSessionStorageUserId,
    saveCredentials,
    logout,
    capitalize,
    getResponseErrorMessage
} from './Functions/utils';
import {
    MODAL_TYPES,
    USER_ROLES,
    TWO_DAYS_TIME,
    REMAINING_FREE_EVENTS,
    CHECK_PROJECT_INTERVAL_TIME,
    SESSION_INFO_TYPES
} from './Constants';
import connectWebSocket from './Functions/connectWebSocket';
import checkSession from './Functions/checkSession';
import CheckOAuthStatus from './Functions/checkOAuthStatus';

import indexApi from './redux/api/indexApi';
import authApi from './redux/api/authApi';
import { useGetGeneralSettingsQuery, useGetUserSettingsQuery } from './redux/api/settingsApi';
import { useGetProjectCurrentPlanQuery, useGetProjectInfoQuery } from './redux/api/projectApi';
import { useGetInvoiceDetailsQuery } from './redux/api/invoiceDetailsApi';
import { useGetReportsQuery } from './redux/api/reportsApi';
import { useGetStorageQuery } from './redux/api/storeApi';
import { useGetWorkspacesStoreListQuery } from './redux/api/workspacesApi';

import { changeAppIsAuthorized, changeAppIsWithPromo, selectAppData } from './redux/app-slice';

import AbsTooltip from './Modules/Overall/AbsTooltip/AbsTooltip';
import MessageModal from './Modules/Modals/MessageModal';
import ChagePlanModal from './Modules/Modals/change-plan-modal/change-plan-modal';
import BlockModal from './Modules/Modals/block-modal/block-modal';
import HeaderAvatar from './Modules/Overall/MenuHeader/HeaderAvatar';
import PromoBalanceModal from './Modules/Modals/PromoBalanceModal/PromoBalanceModal';
import Loader from './Modules/Loader';
import MenuHeader from './Modules/Overall/MenuHeader/MenuHeader';
import Modal, { modalForcedStyles } from './Modules/Overall/Modal/Modal';

// TODO:
import './Modules/MainTable/main-table.scss';
import './components/main-table/main-table.scss';
import './Modules/Items/Campaigns/campaigns.scss';

if (process.env.VERSION && process.env.WEBSOCKET_PATH) connectWebSocket();

checkSession(window.location.pathname.split('/')[1] === 'support');

const AppContent = React.memo(({ isUserAuthorized, isLoadMainDataSuccess, block }) => {
    const location = useLocation();
    const { pathname, state } = location;
    // TODO: add validation pathname

    const unAuthorizadPages = getRoutes(false, pathname, state?.referer).map(
        ({ component: Component, path, exact, name }) => (
            <Route key={name} path={path} exact={exact} name={name} render={props => <Component {...props} />} />
        )
    );

    const authorizadPages = getRoutes(true, pathname, state?.referer).map(
        ({ component: Component, path, exact, name }) => (
            <Route key={name} path={path} exact={exact} name={name} render={props => <Component {...props} />} />
        )
    );

    return (
        <div className="main" onKeyUpCapture={block} onClickCapture={block}>
            <Switch>{isUserAuthorized && isLoadMainDataSuccess ? authorizadPages : unAuthorizadPages}</Switch>
        </div>
    );
});

const App = ({ modal, absTooltip }) => {
    moment.locale(global.lng);

    const dispatch = useDispatch();

    const appData = useSelector(selectAppData);
    const { isAuthorized, isStartProjectAfterLogIn, isWithPromo, promocode } = appData;

    const [isLoading, setIsLoading] = useState(true);

    const [isAccountBlocked, setIsAccountBlocked] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [eventsLimit, setEventsLimit] = useState(null);
    const [trial, setTrial] = useState(null);
    const [isNeedAddBillingInfo, setIsNeedAddBillingInfo] = useState(false);
    const [isNeedUpdatePayment, setIsNeedUpdatePayment] = useState(false);

    const [isStartDataLoad, setIsStartDataLoad] = useState(false);
    const [isLoadMainDataSuccess, setIsLoadMainDataSuccess] = useState(false);

    const txt = Langs[global.lng];
    const history = useHistory();
    const location = useLocation();

    const userId = getSessionStorageUserId();
    const supportModeInfo = getLocalStorageUserInfo(userId, SESSION_INFO_TYPES.SUPPORT) || null;

    const isLoginPath = location.pathname.split('/')[1] === 'login';
    const loginLinkToken = isLoginPath ? location.pathname.split('/login/')[1] : '';

    const isRegisterPath = location.pathname.split('/')[1] === 'register';
    const registerLinkToken = isRegisterPath ? location.pathname.split('/')[3] : '';

    const isChangePasswordPath = location.pathname.split('/')[1] === 'change-password';
    const changePasswordLinkToken = isChangePasswordPath ? location.pathname.split('/')[1] : '';

    const isEmailConfirmPath = location.pathname.split('/')[1] === 'email-confirm';
    const emailConfirmLinkToken = isEmailConfirmPath ? location.pathname.split('/email-confirm/')[1] : '';

    const isSupportPath = window.location.pathname.split('/')[1] === 'support';

    const isSharedReportPath = location.pathname.split('/')[1] === 'sharedreport';

    const isFacebookAuthPath = window.location.pathname.split('/')[1] === 'facebook-auth-status';
    if (isFacebookAuthPath) return <CheckOAuthStatus type="facebook" />;

    const isGoogleAuthPath = window.location.pathname.split('/')[1] === 'google-auth-status';
    if (isGoogleAuthPath) return <CheckOAuthStatus type="google" />;

    // const [isUseAsSupport] = useState(
    //     isSupportPath || !!getLocalStorageUserInfo(getSessionStorageUserId(), SESSION_INFO_TYPES.SUPPORT)
    // );

    // requests

    const { data: generalSettings, isSuccess: isGeneralSettingsLoaded } = useGetGeneralSettingsQuery(undefined, {
        skip: !isStartDataLoad
    });

    const { data: userSettings, isSuccess: isUserSettingsLoaded } = useGetUserSettingsQuery(undefined, {
        skip: !isStartDataLoad
    });

    const { data: currentPlan, isSuccess: isCurrentPlanLoaded } = useGetProjectCurrentPlanQuery(undefined, {
        skip: !isStartDataLoad
    });

    const { data: invoiceDetails, isSuccess: isInvoiceDetailsLoaded } = useGetInvoiceDetailsQuery(undefined, {
        skip: !isStartDataLoad || !userSettings || userSettings.role !== USER_ROLES.OWNER
    });

    const { data: reports, isSuccess: isReportsLoaded } = useGetReportsQuery(undefined, {
        skip: !isStartDataLoad
    });

    const { data: storage, isSuccess: isStorageLoaded } = useGetStorageQuery(undefined, {
        skip: !isStartDataLoad
    });

    const { data: workspacesInStore, isSuccess: isWorkspacesInStoreLoaded } = useGetWorkspacesStoreListQuery(
        undefined,
        {
            skip: !isStartDataLoad
        }
    );

    const {
        data: projectInfo,
        isSuccess: isProjectInfoLoaded,
        isUninitialized: isProjectInfoUninitialized
    } = useGetProjectInfoQuery(undefined, {
        skip: !isAuthorized || isSharedReportPath || isEmailConfirmPath,
        pollingInterval: CHECK_PROJECT_INTERVAL_TIME // TODO: use websocket
    });

    // end requests

    const showBlockingModal = (data, type, force) => {
        const userRole = userSettings?.role;
        const userEmail = userSettings?.email;

        const getOnClose = () => {
            switch (type) {
                case MODAL_TYPES.TRIAL:
                    return (e, isAdded) => {
                        if (!isAdded) {
                            setTrialModalLastShowTime(userEmail);
                            hideModal();
                        }

                        setTrial(null);
                    };

                case MODAL_TYPES.EVENTS_LIMIT:
                    return () => setEventsLimit(null);

                case MODAL_TYPES.BLOCKING:
                case MODAL_TYPES.OVERDUE:
                    return () => setInvoice(null);

                case MODAL_TYPES.UPDATE_PAYMENT:
                    return () => {
                        setIsNeedUpdatePayment(null);
                        setIsAccountBlocked(false);
                    };

                case MODAL_TYPES.UPDATE_BILLING_INFO:
                    return isOk => {
                        if (isOk) {
                            setIsAccountBlocked(false);
                            setIsNeedAddBillingInfo(false);
                            hideModal();
                        }
                    };

                default:
                    return hideModal;
            }
        };

        const isPayModal =
            (type === MODAL_TYPES.BLOCKING || type === MODAL_TYPES.OVERDUE) && userRole === USER_ROLES.OWNER;

        showModal({
            id: type,
            noHeader: true,
            onClose: force ? () => {} : getOnClose(),
            ...(isPayModal ? { className: 'chage-plan-modal' } : {}),
            force,
            body: () =>
                isPayModal ? (
                    <ChagePlanModal
                        isBlocking
                        modalType={type}
                        invoice={data}
                        onPlanChanged={getOnClose()}
                        customClose={hideModal}
                    />
                ) : (
                    <BlockModal
                        data={data}
                        isOwner={userRole === USER_ROLES.OWNER}
                        type={type}
                        userEmail={userEmail}
                        onClose={getOnClose()}
                    />
                )
        });
    };

    const checkUserBlock = skipCheckPromocode => {
        // const isSupport = getLocalStorageUserInfo(getSessionStorageUserId(), SESSION_INFO_TYPES.SUPPORT);
        // if (isSupport) return;

        if (!skipCheckPromocode && isWithPromo) {
            const onPromoBalanceModalClose = () => {
                dispatch(changeAppIsWithPromo(false));
                hideModal();
                checkUserBlock(true);
            };

            showModal({
                title: txt.titles.billingInfo,
                noHeader: true,
                noClose: true,
                onClose: onPromoBalanceModalClose,
                body: () => (
                    <PromoBalanceModal
                        onClose={onPromoBalanceModalClose}
                        balance={promocode.reduce((acc, curr) => +curr.promoSum + acc, 0)}
                    />
                )
            });

            return;
        }

        if (projectInfo?.isUnlimited) {
            if (userSettings.role === USER_ROLES.OWNER && !invoiceDetails) {
                setIsNeedAddBillingInfo(true);
            }

            return removeTrialModalLastShowTime(userSettings?.email);
        }

        if (projectInfo?.invoice) {
            return setInvoice({
                ...projectInfo.invoice,
                ownerEmail: projectInfo.ownerEmail,
                isFreePlan: !!projectInfo.trial,
                isBlocked: projectInfo.isTrafficBlocked
            });
        }

        if (projectInfo?.remainingFreeEvents <= REMAINING_FREE_EVENTS && projectInfo?.trial) {
            return setEventsLimit({
                ownerEmail: projectInfo.ownerEmail,
                isBlocked: projectInfo.isTrafficBlocked,
                blockedAt: projectInfo.updatedAt
            });
        }

        if (projectInfo?.trial) return setTrial({ ...projectInfo.trial, ownerEmail: projectInfo.ownerEmail });

        if (!projectInfo?.trial && !projectInfo?.hasInvoiceDetails) return setIsNeedAddBillingInfo(true);

        if (projectInfo?.paymentMethodForUpdate) return setIsNeedUpdatePayment(true);
    };

    useEffect(() => {
        const checkInvoiceDetails = userSettings?.role !== USER_ROLES.OWNER;

        if (
            isStartDataLoad &&
            isGeneralSettingsLoaded &&
            isUserSettingsLoaded &&
            isCurrentPlanLoaded &&
            isReportsLoaded &&
            isStorageLoaded &&
            isWorkspacesInStoreLoaded &&
            (checkInvoiceDetails ? true : isInvoiceDetailsLoaded) &&
            !isLoadMainDataSuccess
        ) {
            clearAccountInitialisation();
            if (isLoading) setIsLoading(false);
            setIsLoadMainDataSuccess(true);

            checkUserBlock();

            if (location.pathname.split('/')[1] === 'preparation') history.push('/');

            if (window.tidioChatApi) {
                window.tidioChatApi.setVisitorData({
                    distinct_id: userSettings.id,
                    email: userSettings.email,
                    name: `${capitalize(userSettings.firstName)} ${capitalize(userSettings.lastName)}`,
                    tags: [currentPlan.name]
                });
            }

            if (JSON.parse(process.env.IS_SENTRY ?? 'false')) {
                Sentry.setUser({
                    id: userSettings.id,
                    email: userSettings.email,
                    username: userSettings.fullName,
                    ip_address: '{{auto}}'
                });
            }

            document.title = txt.auth.platClicks;
        }
    }, [
        isStartDataLoad,
        generalSettings,
        userSettings,
        currentPlan,
        invoiceDetails,
        isLoadMainDataSuccess,
        reports,
        storage,
        workspacesInStore
    ]);

    const showAuthLogOutModal = () => {
        showModal({
            title: txt.errors.error,
            body: props => (
                <MessageModal
                    {...props}
                    subtitle={txt.errors.logOutFirst}
                    message={txt.texts.needToLogOut}
                    isConfirmButtonHidden
                />
            )
        });
    };

    const checkStart = async () => {
        if (isEmailConfirmPath && emailConfirmLinkToken) {
            await dispatch(indexApi.endpoints.confirmUserSettingsEmail.initiate(emailConfirmLinkToken)).then(res => {
                if (res.data === null) {
                    removeTrialModalLastShowTime(getSessionStorageUserId());

                    const finalize = () => {
                        changeAppIsAuthorized(false);
                        history.push('/login');
                        toast.success('Email changed successfully');
                    };

                    if (isAuthorized) logout(finalize);
                    else finalize();
                } else {
                    toast.error(getResponseErrorMessage(res.error.data, txt.toasts.commonError));
                    history.push('/login');
                }
            });

            setIsLoading(false);
            return false;
        }

        if (isSupportPath) {
            const queryParams = new URLSearchParams(window.location.search);
            const code = queryParams.get('code');
            const supportId = queryParams.get('support-id');
            const userLogin = decodeURIComponent(queryParams.get('user'));
            const userRole = queryParams.get('userRole');

            await dispatch(
                authApi.endpoints.loginAsSupport.initiate({
                    supportId,
                    code
                })
            ).then(res => {
                if (res.isSuccess) {
                    const credentials = transform.user.import(res.data.clickToken);
                    const adminCredentials = transform.user.import(res.data.adminToken);

                    saveCredentials(
                        credentials,
                        userLogin,
                        false,
                        () => {
                            dispatch(changeAppIsAuthorized(!!credentials.token));
                            history.push('/');
                        },
                        { supportId, userLogin, userRole },
                        adminCredentials
                    );
                } else {
                    toast.error(getResponseErrorMessage(res.error.data, 'Access denied'));
                    history.push('/login');
                }
            });

            return false;
        }

        return true;
    };

    useEffect(() => {
        (async () => {
            if (isProjectInfoLoaded) {
                // user authorized
                if (projectInfo.status === 'waiting') {
                    setIsLoading(false);
                    localStorage.setItem('isStartInitialization', 'true');
                    history.push('/preparation');
                } else {
                    if (isLoadMainDataSuccess) return checkUserBlock();

                    const isContinueLoad = await checkStart();

                    if (isContinueLoad) {
                        setIsStartDataLoad(true);
                        localStorage.removeItem('isStartInitialization');
                    }
                }
            } else if (isProjectInfoUninitialized) {
                if (isAuthorized !== null) {
                    // user unauthorized, project not start
                    const isContinueLoad = await checkStart();

                    if (isContinueLoad) setIsLoading(false);
                }
            } else {
                // await load projectInfo
            }
        })();
    }, [projectInfo, isAuthorized]);

    useEffect(() => {
        if (isAuthorized && !isStartProjectAfterLogIn) {
            const isServicePath =
                (isLoginPath && loginLinkToken) ||
                (isRegisterPath && registerLinkToken) ||
                (isChangePasswordPath && changePasswordLinkToken);

            if (isServicePath) showAuthLogOutModal();
        }
    }, [isAuthorized, isStartProjectAfterLogIn]);

    useEffect(() => {
        dispatch(changeAppIsAuthorized(!!getCredentials().token));
    }, []);

    const showEventsLimitBlockModal = () => {
        if (eventsLimit) {
            setIsAccountBlocked(true);
            showBlockingModal(
                {
                    ...eventsLimit,
                    freeEvents: currentPlan.eventsIncluded,
                    onModalPlanClose: showEventsLimitBlockModal
                },
                MODAL_TYPES.EVENTS_LIMIT,
                true
            );
        } else setIsAccountBlocked(false);
    };

    const updateModals = () => {
        if (isNeedAddBillingInfo) showBlockingModal(projectInfo, MODAL_TYPES.UPDATE_BILLING_INFO, true);

        if (invoice) {
            const isBlockingType = invoice.isFreePlan || invoice.isBlocked;
            return showBlockingModal(invoice, isBlockingType ? MODAL_TYPES.BLOCKING : MODAL_TYPES.OVERDUE, true);
        }

        if (eventsLimit) {
            return showBlockingModal(
                {
                    ...eventsLimit,
                    freeEvents: currentPlan.eventsIncluded,
                    onModalPlanClose: showEventsLimitBlockModal
                },
                MODAL_TYPES.EVENTS_LIMIT,
                true
            );
        }

        if (trial && trial.daysLeft === 0 && !checkDontReloadUrls(window.location.pathname)) {
            showBlockingModal(trial, MODAL_TYPES.TRIAL, true);
        }
    };

    const block = useCallback(
        e => {
            if (!isAccountBlocked) return;
            const modalNode = document.querySelector('.modal2');

            switch (e.type) {
                case 'click':
                case 'mousedown':
                    e.preventDefault();
                    e.stopPropagation();
                    if (!e.target.classList.contains('modal2') && !e.target.closest('.header-avatar--user-blocked')) {
                        if (modalNode) hideAllModals();
                        setTimeout(updateModals, 0);
                    }

                    break;

                case 'keyup':
                    if (!modalNode) {
                        e.preventDefault();
                        e.stopPropagation();
                        updateModals();
                    } else {
                        const computedStyles = window.getComputedStyle(modalNode);
                        const forcedStyles = Object.entries(modalForcedStyles);
                        const isCompare = forcedStyles.every(([key, value]) => computedStyles[key] === value);

                        if (!isCompare) {
                            e.preventDefault();
                            hideAllModals();
                            updateModals();
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        [isAccountBlocked]
    );

    useEffect(() => {
        const header = document.querySelector('.menu-header');

        if (isAccountBlocked && header) {
            header.addEventListener('mousedown', block);
            header.addEventListener('click', block);
        }

        return () => {
            if (header) {
                header.removeEventListener('mousedown', block);
                header.removeEventListener('click', block);
            }
        };
    }, [isAccountBlocked]);

    useEffect(() => {
        if (invoice) {
            setIsAccountBlocked(true);
            const isBlockingType = invoice.isFreePlan || invoice.isBlocked;
            showBlockingModal(invoice, isBlockingType ? MODAL_TYPES.BLOCKING : MODAL_TYPES.OVERDUE, true);
        } else setIsAccountBlocked(false);
    }, [invoice]);

    useEffect(showEventsLimitBlockModal, [eventsLimit]);

    useEffect(() => {
        if (trial) {
            if (trial.daysLeft === 0) {
                setIsAccountBlocked(true);
                showBlockingModal(trial, MODAL_TYPES.TRIAL, true);
                return;
            }

            const lastShowModalTime = getTrialModalLastShowTime(userSettings?.email);
            const timePassed = lastShowModalTime ? new Date().getTime() - lastShowModalTime : 0;

            if (!lastShowModalTime || timePassed >= TWO_DAYS_TIME) {
                showBlockingModal(trial, MODAL_TYPES.TRIAL, false);
            }
        } else setIsAccountBlocked(false);
    }, [trial]);

    useEffect(() => {
        if (isNeedAddBillingInfo) {
            setIsAccountBlocked(true);
            showBlockingModal(projectInfo, MODAL_TYPES.UPDATE_BILLING_INFO, true);
        }
    }, [isNeedAddBillingInfo]);

    useEffect(() => {
        if (isNeedUpdatePayment) {
            setIsAccountBlocked(true);
            showBlockingModal(projectInfo.paymentMethodForUpdate, MODAL_TYPES.UPDATE_PAYMENT, true);
        }
    }, [isNeedUpdatePayment]);

    useEffect(() => {
        if (isGeneralSettingsLoaded) moment.tz.setDefault(generalSettings.reportTimeZone);
    }, [generalSettings]);

    useEffect(() => {
        const isSupport = !!getLocalStorageUserInfo(getSessionStorageUserId(), SESSION_INFO_TYPES.SUPPORT);

        if (JSON.parse(process.env.IS_PROD ?? 'false')) {
            const script = document.createElement('script');

            script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-199965376-1';
            script.async = true;

            document.body.appendChild(script);
        }

        if (JSON.parse(process.env.IS_PROD ?? 'false') && !isSupport && !isSupportPath) {
            const script = document.createElement('script');

            script.src = '//code.tidio.co/tswkpxbxlf8bjtyrdsemuvivypczmiik.js';
            script.async = true;

            document.body.appendChild(script);
        }
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="AppInner">
            {modal.some(el => el.force) && <HeaderAvatar isUserBlocked supportModeInfo={supportModeInfo} />}

            {isAuthorized && isLoadMainDataSuccess && !checkDontReloadUrls(window.location.pathname) && (
                <MenuHeader
                    reports={reports}
                    supportModeInfo={supportModeInfo}
                    generalSettings={generalSettings}
                    balance={projectInfo.balance}
                />
            )}

            <AppContent isUserAuthorized={isAuthorized} isLoadMainDataSuccess={isLoadMainDataSuccess} block={block} />

            {modal?.map((el, idx) => (
                <Modal key={idx} modal={el} modalIndex={idx} modalLength={modal.length} />
            ))}

            {absTooltip ? <AbsTooltip /> : null}

            <ToastContainer
                transition={Slide}
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                icon={false}
            />
        </div>
    );
};

const Store = ({ oldState: { modal, absTooltip } }) => ({
    modal,
    absTooltip
});

export default connect(Store)(App);
