import React from 'react';
import cn from 'classnames';

import Langs from '../../../../../Langs';
import { getIntlFormattedValue } from '../../../../../Functions/utils';

import { useGetGeneralSettingsQuery } from '../../../../../redux/api/settingsApi';
import { useGetCurrenciesQuery } from '../../../../../redux/api/systemApi';

import Btn from '../../../../Overall/UI/Btn/Btn';

import './plan.scss';

const Plan = ({
    plan,
    currentPlanIndex,
    activePlanIndex,
    isActive,
    onClick,
    className,
    noButton,
    buttonText,
    isBlocking,
    isButtonDisabled
}) => {
    const txt = Langs[global.lng];

    const {
        name,
        monthlyPayment,
        eventsIncluded,
        overageCostFor1000,
        monthDataStorage,
        customDomains,
        dedicatedDomains,
        paymentCurrency,
        recommended,
        buttonType
    } = plan;

    const isRecommended = recommended && currentPlanIndex >= activePlanIndex;

    const { data: generalSettings } = useGetGeneralSettingsQuery();
    const { data: currencies } = useGetCurrenciesQuery();

    const initialCurrency = paymentCurrency || generalSettings?.defaultCurrency;
    const currency = currencies?.find(el => el.value === initialCurrency);
    const currencySymbol = currency && currency.symbol !== currency.value ? currency.symbol : '';

    const getCurrencyValue = value => (
        <>
            {currencySymbol && <span>{currencySymbol}</span>}
            {value}
            {!currencySymbol ? <span> {currency?.value}</span> : ''}
        </>
    );

    const currentButtonText = (() => {
        if (isActive) return txt.buttons.currentPlan;
        if (buttonText) return buttonText;
        return txt.buttons.choosePlan;
    })();

    return (
        <div className={cn('plan', { 'plan--active': isActive }, { 'plan--recommended': isRecommended }, className)}>
            <div className="plan__header">
                <div className="plan__title">{name}</div>

                <div className="plan__subtitle">
                    {isRecommended ? (
                        <>
                            <span>{txt.texts.recommended}</span> {txt.texts.forProfessionals}
                        </>
                    ) : (
                        <span>{txt.texts.forUsers}</span>
                    )}
                </div>
            </div>

            <div className="plan__price">
                {monthlyPayment === 0 ? (
                    txt.texts.free
                ) : (
                    <>
                        <>{getCurrencyValue(getIntlFormattedValue(monthlyPayment))}</>
                        <span>/{txt.labels.month.toLowerCase()}</span>
                    </>
                )}
            </div>

            <div className="plan__description">
                {Number.isFinite(eventsIncluded) && (
                    <div className="plan__description-item">
                        <div>{getIntlFormattedValue(eventsIncluded)}</div>
                        <div>
                            events<sup>*</sup> per month
                        </div>
                    </div>
                )}

                {Number.isFinite(overageCostFor1000) && (
                    <div className="plan__description-item">
                        <div>
                            {getCurrencyValue(getIntlFormattedValue(overageCostFor1000, 2))}
                            <> / </>
                            {getIntlFormattedValue(1000)}
                        </div>
                        <div>
                            overage<sup>**</sup>
                        </div>
                    </div>
                )}

                {(Number.isFinite(monthDataStorage) || monthDataStorage === null) && (
                    <div className="plan__description-item">
                        <div>{monthDataStorage === null ? txt.labels.unlim : `${monthDataStorage} months`}</div>
                        <div>data storage</div>
                    </div>
                )}

                {(Number.isFinite(customDomains) || customDomains === null) && (
                    <div className="plan__description-item">
                        <div>{customDomains === null ? txt.labels.unlim : customDomains}</div>
                        <div>custom domains</div>
                    </div>
                )}

                {(Number.isFinite(dedicatedDomains) || dedicatedDomains === null) && (
                    <div className="plan__description-item plan__description-item--ssl">
                        <div>{dedicatedDomains === null ? txt.labels.unlim : dedicatedDomains}</div>
                        <div>
                            dedicated domains <sup>SSL</sup>
                        </div>
                    </div>
                )}
            </div>

            {!noButton && (
                <div>
                    <Btn
                        dataTest="plan_choose-btn"
                        className="plan__choose-btn"
                        type={buttonType || 'danger'}
                        onClick={() => onClick(plan)}
                        disabled={(isActive && !isBlocking) || isButtonDisabled}
                    >
                        {currentButtonText}
                    </Btn>
                </div>
            )}
        </div>
    );
};

export default Plan;
