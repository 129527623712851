import React from 'react';

import Langs from '../../../../../Langs';

import './just-td-index.scss';

const JustTdIndex = ({ item, campaignName }) => {
    const { type, targetUrl } = item;

    const txt = Langs[global.lng];

    const indexType = (() => {
        switch (type) {
            case 'campaign':
                return `${txt.radioButtons.campaign}:`;
            case 'redirect_root':
                return `${txt.labels.redirect.toUpperCase()}:`;

            default:
                return null;
        }
    })();

    const value = (() => {
        switch (type) {
            case 'campaign':
                return campaignName;
            case 'redirect_root':
                return targetUrl;
            case '404_root':
                return txt.radioButtons.destination404;

            default:
                return null;
        }
    })();

    return (
        <div className="j4">
            {indexType && <span className="just-td-index__type mr5">{indexType}</span>}
            {value && <span>{value}</span>}
        </div>
    );
};

export default JustTdIndex;
