import React from 'react';

import TdHidingContainer from './TdHidingContainer';

const TdBadgesHidingContainer = ({ items, parentWidth, row }) => {
    if (!items) return null;

    return (
        <TdHidingContainer
            containerClassName="badge-square"
            counterClassName="badge-square__counter"
            data={{ key: row.value, value: items }}
            parentWidth={parentWidth}
        />
    );
};

export default TdBadgesHidingContainer;
