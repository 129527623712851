import React, { forwardRef } from 'react';

const Compare = forwardRef(({ color = 'currentColor', size = 16, ...rest }, ref) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            stroke={color}
            ref={ref}
            {...rest}
        >
            <rect x="1.5" y="2.5" width="4" height="11" rx="1.5" stroke={color} />
            <rect x="10.5" y="2.5" width="4" height="11" rx="1.5" fill={color} stroke={color} />
            <line x1="8" y1="2.5" x2="8" y2="13.5" stroke={color} strokeLinecap="round" />
        </svg>
    );
});

Compare.displayName = 'Compare';

export default Compare;
