import indexApi from './indexApi';
import { transform } from '../transformers';

const customConversionsApiTagTypes = {
    customConversions: 'customConversions'
};

export const customConversionsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(customConversionsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getCustomConversionList: builder.query({
                query: () => `/custom-conversion-events`,
                transformResponse: response => response.map(el => transform.customConversionEvents.import(el)),
                providesTags: () => [{ type: customConversionsApiTagTypes.customConversions, id: 'LIST' }]
            }),

            getCustomConversionColumnsList: builder.query({
                query: () => `/custom-conversion-events/list/custom-column-action`,
                keepUnusedDataFor: 0
            }),

            addCustomConversion: builder.mutation({
                query: ({ data }) => ({
                    url: `/custom-conversion-events`,
                    method: 'POST',
                    body: transform.customConversionEvents.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: customConversionsApiTagTypes.customConversions, id: 'LIST' }];
                }
            }),

            editCustomConversion: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/custom-conversion-events/${id}`,
                    method: 'PUT',
                    body: transform.customConversionEvents.export(data)
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: customConversionsApiTagTypes.customConversions, id: 'LIST' }];
                }
            }),

            deleteCustomConversion: builder.mutation({
                query: ({ id }) => ({
                    url: `/custom-conversion-events/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error) => {
                    if (!error) return [{ type: customConversionsApiTagTypes.customConversions, id: 'LIST' }];
                }
            })
        })
    });

export const {
    useGetCustomConversionListQuery,
    useGetCustomConversionColumnsListQuery,
    useAddCustomConversionMutation,
    useEditCustomConversionMutation,
    useDeleteCustomConversionMutation
} = customConversionsApi;
