import indexApi from './indexApi';

const domainsApiTagTypes = {
    domainsByTypetracking: 'domainsByTypeTracking',
    domainsByTyperedirect: 'domainsByTypeRedirect',
    systemDomainsByType: 'systemDomainsByType',
    defaultDomainsByType: 'defaultDomainsByType'
};

export const domainsApi = indexApi
    .enhanceEndpoints({ addTagTypes: Object.values(domainsApiTagTypes) })
    .injectEndpoints({
        endpoints: builder => ({
            getDomainsByType: builder.query({
                query: type => `/domains/${type}`,
                providesTags: (result, error, type) => {
                    if (!result) return [{ type: domainsApiTagTypes[`domainsByType${type}`], id: 'LIST' }];

                    return [
                        ...result.map(el => ({ type: domainsApiTagTypes[`domainsByType${type}`], id: el.id })),
                        { type: domainsApiTagTypes[`domainsByType${type}`], id: 'LIST' }
                    ];
                }
            }),

            getSystemDomainsByType: builder.query({
                query: type => `/domains/system/${type}`,
                keepUnusedDataFor: 86400, //24h
                providesTags: (result, error, type) => [{ type: domainsApiTagTypes.systemDomainsByType, id: type }]
            }),

            getDefaultDomainsByType: builder.query({
                query: type => `/domains/default/${type}`,
                providesTags: (result, error, type) => [{ type: domainsApiTagTypes.defaultDomainsByType, id: type }]
            }),

            checkDomainCname: builder.query({
                query: ({ type, domain }) => `/domains/check-cname/${type}/${domain}`,
                keepUnusedDataFor: 0
            }),

            addDomain: builder.mutation({
                query: ({ data }) => ({
                    url: '/domains',
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error, { type }) => {
                    if (!error) return [{ type: domainsApiTagTypes[`domainsByType${type}`], id: 'LIST' }];
                }
            }),

            editDomain: builder.mutation({
                query: ({ id, data }) => ({
                    url: `/domains/${id}`,
                    method: 'PUT',
                    body: data
                }),
                invalidatesTags: (result, error, { type }) => {
                    if (!error) return [{ type: domainsApiTagTypes[`domainsByType${type}`], id: 'LIST' }];
                }
            }),

            changeDefaultDomain: builder.mutation({
                query: ({ id }) => ({
                    url: `/domains/${id}/change-default`,
                    method: 'PATCH'
                }),
                invalidatesTags: (result, error, { type }) => {
                    if (!error) return [{ type: domainsApiTagTypes.defaultDomainsByType, id: type }];
                }
            }),

            checkDomainStatus: builder.mutation({
                query: ({ id }) => ({
                    url: `/domains/${id}/check-status`,
                    method: 'POST'
                })
            }),

            deleteDomain: builder.mutation({
                query: ({ id }) => ({
                    url: `/domains/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: (result, error, { type }) => {
                    if (!error) return [{ type: domainsApiTagTypes[`domainsByType${type}`], id: 'LIST' }];
                }
            })
        })
    });

export const {
    useGetDomainsByTypeQuery,
    useGetSystemDomainsByTypeQuery,
    useGetDefaultDomainsByTypeQuery,
    useCheckDomainCnameQuery,
    useAddDomainMutation,
    useEditDomainMutation,
    useChangeDefaultDomainMutation,
    useCheckDomainStatusMutation,
    useDeleteDomainMutation
} = domainsApi;
