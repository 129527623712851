import React from 'react';
import cn from 'classnames';

// import loader from '../../assets/img/loader.svg';

const Loader = ({ type, className, sticky, isFetching, isLoad }) => {
    return (
        <div
            className={cn('loader-box j5', className, {
                sticky,
                'loader-box--fetching': isFetching,
                'loader-box--load': isLoad
            })}
        >
            {/* <img src={loader} alt="loadbar" /> */}
            <div className={cn('lds-ring', type && `lds-ring--${type}`)}>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loader;
