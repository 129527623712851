export default {
    tags: [],
    workspaceId: '',
    name: '',

    isDoublePostbackAccepted: false,
    usePostbackStatuses: false,
    isClickIdAppendToOffer: false,
    isPostbackIpWhiteListEnabled: false,
    ipList: [],

    postbackStatuses: {
        newStatus: {
            key: 'new',
            value: ''
        },
        approvedStatus: {
            key: 'approved',
            value: ''
        },
        declinedStatus: {
            key: 'declined',
            value: ''
        },
        chargeBackStatus: {
            key: 'chargeback',
            value: ''
        },
        defaultStatus: 'approved'
    }
};
