import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { clone, compare, getResponseErrorMessage, camelToKebabCase } from '../../../Functions/utils';
import { showModal, hideModal, getTrackingMethodName } from '../../../redux/operations';
import Langs from '../../../Langs';
import { ENTITY_MODE_STATUS, TRACKING_METHOD } from '../../../Constants';

import useNewClickOutside from '../../../Functions/hooks/useNewClickOutside';
import useQueryResultModal from '../../../Functions/hooks/useQueryResultModal';

import {
    useAddCampaignMutation,
    useEditCampaignMutation,
    useGetCampaignBidAlertQuery,
    useGetCampaignIntegrationStatisticsQuery
} from '../../../redux/api/campaignsApi';
import { useAddFlowMutation, useEditFlowMutation, useLazyGetFlowByIdQuery } from '../../../redux/api/flowsApi';
import indexApi from '../../../redux/api/indexApi';

import CriteriaDialog from '../../Overall/CriteriaDialog/CriteriaDialog';
import Btn from '../../Overall/UI/Btn/Btn';
import CampaignMainOption from './components/CampaignMainOption/CampaignMainOption';
import FlowMainOption from './components/FlowMainOption/FlowMainOption';
import FlowRules from './components/FlowRules/FlowRules';
import MessageModal from '../../Modals/MessageModal';
import CampaignCostErrorModal from '../../Modals/campaign-cost-error-modal/campaign-cost-error-modal';
import ChangeWorkspaceWarningModal from '../../Modals/change-workspace-warning-modal/change-workspace-warning-modal';

// import TriggersMainOption from './components/TriggersOptions/TriggersMainOption';
// import TriggersFlowRules from './components/TriggersOptions/TriggersFlowRules';
import getCriteriaConstructor from './criteria-constructor';
import pathTemplate from './templates/path-template';

import './new-item.scss';

const NewFlowCampaign = ({
    status,
    setStatus,
    chapter,
    isProcessing,
    setIsProcessing,
    actionState,
    updateActionState,
    originalValues,
    flowValues,
    campaignsInFlow,
    updateFlowValues,
    mainOptionValues,
    updateMainOptionValues,
    trafficSourceData,
    trackingMethod,
    setTrackingMethod,
    closeNewItemComponent,
    publicWorkspaceId,

    trackingDomains,
    flowsList,
    landersList,
    offersList,
    affiliateNetworksList,
    trafficSourcesList,
    workspacesList,
    storage,
    countries,
    currencies,
    timezones,
    languages,
    browsers,
    brands,
    os,
    connectionTypes,
    connectionProxy,
    deviceTypes
}) => {
    const txt = Langs[global.lng];

    const history = useHistory();
    const dispatch = useDispatch();
    const entity = status.type;

    const [isTouched, setIsTouched] = useState(false);

    const [activeRule, setActiveRule] = useState(null);
    const [oldRule, setOldRule] = useState(null);
    const [isCriteriaWarnigModalOpened, setIsCriteriaWarnigModalOpened] = useState(false);

    const [campaignCostEqualStatus, setCampaignCostEqualStatus] = useState({ isEqual: true });
    const [campaignCostInitialEqual, setCampaignCostInitialEqual] = useState(null);

    const showQueryResultModal = useQueryResultModal();

    const rulesWithCriteria = useMemo(
        () =>
            flowValues.rules.map(rule => ({
                ...rule,
                criteria: rule.criteria.map(item => ({
                    ...item,
                    ...getCriteriaConstructor({
                        countries,
                        languages,
                        timezones,
                        connectionTypes,
                        connectionProxy,
                        deviceTypes,
                        brands,
                        os,
                        browsers
                    }).find(({ key }) => key === item.key)
                }))
            })),
        [
            flowValues.rules,
            countries,
            languages,
            timezones,
            connectionTypes,
            connectionProxy,
            deviceTypes,
            brands,
            os,
            browsers
        ]
    );

    const selectedRule = rulesWithCriteria.find(el => el.value === activeRule?.rule?.value);

    const isValid = (() => {
        if (!mainOptionValues?.name) return false;

        if (entity === 'campaigns' && !mainOptionValues.trafficSource) return false;

        if (entity === 'campaigns' && mainOptionValues.trafficSource) {
            const isIntegratedTs = trafficSourcesList.find(
                el => el.id === mainOptionValues.trafficSource
            )?.isIntegration;

            if (isIntegratedTs && !mainOptionValues.trafficSourceCampaignId) return false;
        }

        return true;
    })();

    const resetActionState = () => {
        updateActionState([
            { prop: 'isPress', value: false },
            { prop: 'isSave', value: false },
            { prop: 'isClose', value: false },
            { prop: 'isRestore', value: false }
        ]);
    };

    const { data: bidAlertData } = useGetCampaignBidAlertQuery(undefined, {
        skip: chapter !== 'campaigns' || !status.value || !mainOptionValues.isIntegrationActive
    });

    const resetAlert = async () => {
        if (!bidAlertData) return;

        const item = bidAlertData.find(el => el.campaignId === status.value);

        if (item) {
            await dispatch(
                indexApi.endpoints.changeCampaignsCostFromIntegration.initiate({
                    ids: [item.id],
                    campaignIds: [status.value]
                })
            );
        }
    };

    const onSave = async entityId => {
        await resetAlert();

        toast.success(txt.singulars[entity] + txt.toasts.wasSaved);
        if (entityId && !actionState.isClose) {
            setIsProcessing(false);
            resetActionState();
            setStatus({ mode: ENTITY_MODE_STATUS.EDIT, value: entityId, type: chapter });
            history.push(`/home/${camelToKebabCase(entity)}/${entityId}`);
        } else if (!actionState.isClose) {
            setIsProcessing(false);
            resetActionState();
        } else {
            updateActionState([
                { prop: 'isPress', value: true },
                { prop: 'isSave', value: false },
                { prop: 'isClose', value: true },
                { prop: 'isRestore', value: false }
            ]);
        }
    };

    const onFlowValuesChange = (data, prop) => {
        // FIXME:
        let value;
        if (data?.target) {
            value = data.target.type === 'checkbox' ? data.target.checked : data.target.value;
        } else {
            value = data;
        }

        updateFlowValues(prop, value);

        if (flowValues.flowValue !== 'custom') updateFlowValues('flowValue', 'custom');
    };

    const onMainOptionValuesChange = (data, prop) => {
        // FIXME:
        let value;
        if (data?.target) {
            value = data.target.type === 'checkbox' ? data.target.checked : data.target.value;
        } else {
            value = data;
        }

        updateMainOptionValues(prop, value);
    };

    const [
        addCampaign,
        { data: addedCampaign, isSuccess: isAddCampaignSuccess, isError: isAddCampaignError, error: addCampaignError }
    ] = useAddCampaignMutation();

    const [editCampaign, { isSuccess: isEditCampaignSuccess, isError: isEditCampaignError, error: editCampaignError }] =
        useEditCampaignMutation();

    useEffect(() => {
        if (isAddCampaignSuccess || isEditCampaignSuccess) {
            setIsTouched(false);
            onSave(isAddCampaignSuccess ? addedCampaign.id : null);
        }
    }, [isAddCampaignSuccess, isEditCampaignSuccess]);

    useEffect(() => {
        if (isAddCampaignError) toast.error(getResponseErrorMessage(addCampaignError.data, txt.toasts.saveError));
        if (isEditCampaignError) toast.error(getResponseErrorMessage(editCampaignError.data, txt.toasts.saveError));

        if (isAddCampaignError || isEditCampaignError) {
            resetActionState();
            setIsProcessing(false);

            if (addCampaignError?.data?.errors?.options || editCampaignError?.data?.errors?.options) {
                showQueryResultModal(
                    txt.texts.getCouldNotBeSavedByWorkspaseSubtitle(mainOptionValues.name),
                    addCampaignError?.data?.errors?.options || editCampaignError?.data?.errors?.options,
                    txt.titles.savingResult
                );
            }
        }
    }, [isAddCampaignError, isEditCampaignError]);

    const [addFlow, { data: addedFlow, isSuccess: isAddFlowSuccess, isError: isAddFlowError, error: addFlowError }] =
        useAddFlowMutation();

    const [editFlow, { isSuccess: isEditFlowSuccess, isError: isEditFlowError, error: editFlowError }] =
        useEditFlowMutation();

    useEffect(() => {
        if (isAddFlowSuccess || isEditFlowSuccess) {
            setIsTouched(false);
            onSave(isAddFlowSuccess ? addedFlow.id : null);
        }
    }, [isAddFlowSuccess, isEditFlowSuccess]);

    useEffect(() => {
        if (isAddFlowError) toast.error(getResponseErrorMessage(addFlowError.data, txt.toasts.saveError));
        if (isEditFlowError) toast.error(getResponseErrorMessage(editFlowError.data, txt.toasts.saveError));

        if (isAddFlowError || isEditFlowError) {
            resetActionState();
            setIsProcessing(false);

            if (addFlowError?.data?.errors?.options || editFlowError?.data?.errors?.options) {
                showQueryResultModal(
                    txt.texts.getCouldNotBeSavedByWorkspaseSubtitle(mainOptionValues.name),
                    addFlowError?.data?.errors?.options || editFlowError?.data?.errors?.options,
                    txt.titles.savingResult
                );
            }
        }
    }, [isAddFlowError, isEditFlowError]);

    const { data: integrationData } = useGetCampaignIntegrationStatisticsQuery(
        { id: status.value },
        {
            skip: chapter !== 'campaigns' || !status.value || !mainOptionValues.isIntegrationActive
        }
    );

    const onSyncCost = async (syncAction, saveOptions) => {
        const continueSave = async () => {
            await resetAlert();

            setCampaignCostEqualStatus({ isEqual: true });

            updateActionState([
                { prop: 'isPress', value: true },
                { prop: 'isSave', value: saveOptions.isSave ?? false },
                { prop: 'isClose', value: saveOptions.isClose ?? false }
            ]);
        };

        switch (syncAction) {
            case 'apply':
                if (saveOptions.isSave) {
                    onMainOptionValuesChange(null, [
                        {
                            prop: 'costModel',
                            value: campaignCostEqualStatus.bidMode
                        },
                        {
                            prop: 'initialCostModel',
                            value: campaignCostEqualStatus.bidMode
                        },
                        {
                            prop: 'costPrice',
                            value:
                                campaignCostEqualStatus.bidAmount === 0 ? null : campaignCostEqualStatus.bidAmount + ''
                        },
                        {
                            prop: 'initialCostPrice',
                            value:
                                campaignCostEqualStatus.bidAmount === 0 ? null : campaignCostEqualStatus.bidAmount + ''
                        }
                    ]);

                    setCampaignCostInitialEqual(true);

                    continueSave();
                } else {
                    await dispatch(
                        indexApi.endpoints.changeCampaignCost.initiate({
                            id: status.value,
                            data: {
                                cost: {
                                    mode: campaignCostEqualStatus.bidMode,
                                    price: Number(campaignCostEqualStatus.bidAmount)
                                }
                            }
                        })
                    );

                    await resetAlert();

                    closeNewItemComponent();
                }

                break;

            case 'submit':
                await dispatch(
                    indexApi.endpoints.changeIntegrationCampaignBidJust.initiate({
                        data: {
                            trafficSourceId: mainOptionValues.trafficSource,
                            trafficSourceCampaignId: mainOptionValues.trafficSourceCampaignId,
                            price: Number(mainOptionValues.costPrice),
                            campaignId: status.value
                        }
                    })
                );

                continueSave();
                break;

            default:
                continueSave();
                break;
        }

        hideModal();
    };

    const handleSave = params => {
        const { hasDuplicate, saveMode } = params || {};
        const isTrackingMethodChange =
            getTrackingMethodName(flowValues.initialTrackingMethod) !== getTrackingMethodName(trackingMethod);

        if (isValid) {
            if (status.value && isTrackingMethodChange && !saveMode) {
                const onCreateDuplicate = () => {
                    hideModal();
                    handleSave({
                        hasDuplicate: originalValues.name === mainOptionValues.name,
                        saveMode: ENTITY_MODE_STATUS.DUPLICATE
                    });
                };

                const onConfirm = () => {
                    updateFlowValues('initialTrackingMethod', trackingMethod);
                    handleSave({ saveMode: 'overwrite' });
                    return true;
                };

                const item = txt.singulars[entity]?.toLowerCase();

                showModal({
                    title: txt.titles.trackingMethodChanged,
                    body: props => (
                        <MessageModal
                            {...props}
                            message={txt.texts.trackingUrlsChanged(item)}
                            confirmButtonText={txt.buttons.save}
                            rejectButtonText={txt.buttons.cancel}
                            onConfirm={onConfirm}
                            onClose={() => {
                                resetActionState();
                                hideModal();
                            }}
                            confirmButtonType="danger"
                            leftSettings={<Btn onClick={onCreateDuplicate}>{txt.buttons.saveAsNew(item)}</Btn>}
                        />
                    )
                });

                return;
            }

            setIsProcessing(true);

            const data = {
                ...mainOptionValues,
                ...flowValues,
                name: `${mainOptionValues.name}${hasDuplicate ? ' Copy' : ''}`,
                ...(entity === 'flows'
                    ? { campaigns: [...mainOptionValues.campaigns, ...mainOptionValues.campaignsToAdd] }
                    : []),
                trackingMethod,
                ...(actionState.isRestore ? { isRestore: actionState.isRestore } : {})
            };

            const isCreate = !status.value || saveMode === ENTITY_MODE_STATUS.DUPLICATE;

            const requestData = {
                data,
                ...(!isCreate ? { id: status.value } : {})
            };

            const request = (() => {
                if (entity === 'campaigns') return isCreate ? addCampaign : editCampaign;
                if (entity === 'flows') return isCreate ? addFlow : editFlow;
            })();

            request(requestData);
        }
    };

    const [getSelectedFlowData, { isFetching: selectedFlowFetching }] = useLazyGetFlowByIdQuery();

    const onFlowChange = async ({ target: { value } }) => {
        if (value === 'custom') {
            updateFlowValues(
                [
                    {
                        prop: 'defaultRule',
                        value: {
                            paths: [{ ...pathTemplate, id: uuid() }]
                        }
                    },
                    {
                        prop: 'rules',
                        value: []
                    },
                    {
                        prop: 'flowValue',
                        value: 'custom'
                    }
                ],
                null
            );

            return;
        }

        if (value !== flowValues.flowValue) {
            setActiveRule(null);
            if (oldRule) setOldRule(null);

            const flowData = await getSelectedFlowData(value, true);

            if (flowData.data) {
                const selectedFlowData = flowData.data;

                updateMainOptionValues('links', []);

                updateFlowValues(
                    [
                        {
                            prop: 'defaultRule',
                            value: selectedFlowData.defaultRule
                        },
                        {
                            prop: 'rules',
                            value: selectedFlowData.rules
                        },
                        {
                            prop: 'flowValue',
                            value: selectedFlowData.value
                        },
                        {
                            prop: 'trackingMethod',
                            value: selectedFlowData.trackingMethod
                        },
                        {
                            prop: 'initialTrackingMethod',
                            value: selectedFlowData.trackingMethod
                        },
                        {
                            prop: 'originalSettingsAt',
                            value: flowValues?.originalSettingsAt
                        }
                    ],
                    null,
                    selectedFlowData.trackingMethod
                );

                setTrackingMethod(selectedFlowData.trackingMethod);
            }
        }
    };

    const onLoadOriginalSettingsClick = () => {
        const original = originalValues.current;

        updateMainOptionValues('links', []);

        updateFlowValues(
            [
                {
                    prop: 'defaultRule',
                    value: original.defaultRule
                },
                {
                    prop: 'rules',
                    value: original.rules
                },
                {
                    prop: 'flowValue',
                    value: 'custom'
                },
                {
                    prop: 'trackingMethod',
                    value: original.trackingMethod
                },
                {
                    prop: 'initialTrackingMethod',
                    value: original.trackingMethod
                }
            ],
            null,
            original.trackingMethod
        );

        setTrackingMethod(original.trackingMethod);
    };

    useEffect(() => {
        if (actionState.isPress) {
            if (!campaignCostEqualStatus.isEqual && mainOptionValues.costModel !== 'auto') {
                if (campaignCostInitialEqual && !actionState.isSave) {
                    return closeNewItemComponent();
                }

                showModal({
                    title: 'Action required',
                    body: props => (
                        <CampaignCostErrorModal
                            {...props}
                            onConfirm={action =>
                                onSyncCost(action, { isSave: actionState.isSave, isClose: actionState.isClose })
                            }
                            isChangeCostSupport={!integrationData?.isBidDisable ?? false}
                            costInfo={{
                                costModel: mainOptionValues.costModel,
                                costPrice: mainOptionValues.costPrice,
                                bidMode: campaignCostEqualStatus.bidMode,
                                bidAmount: campaignCostEqualStatus.bidAmount
                            }}
                        />
                    )
                });

                return resetActionState();
            }

            if (actionState.isClose && !actionState.isSave && !actionState.isRestore) {
                return closeNewItemComponent();
            }

            setIsTouched(true);

            if (actionState.isSave) {
                if (isValid) handleSave();
                else resetActionState();
            }
        }
    }, [actionState]);

    const MainOptionComponent = entity === 'campaigns' ? CampaignMainOption : FlowMainOption;

    const onRuleByIdChange = (data, prop, ruleId) => {
        const index = flowValues.rules.findIndex(el => el.value === ruleId);
        const newValues = [...flowValues.rules];

        let formattedRule = { ...flowValues.rules[index] };

        if (Array.isArray(prop)) {
            prop.forEach(el => {
                formattedRule = { ...formattedRule, [el.prop]: el.value };
            });
        } else {
            formattedRule = { ...formattedRule, [prop]: data };
        }

        newValues.splice(index, 1, formattedRule);
        onFlowValuesChange(newValues, 'rules');
    };

    const handleCriteriaDialogClose = callback => {
        const returnToDefaultSettings = () => {
            const ruleIndex = flowValues?.rules.findIndex(el => el.value === oldRule.value);

            if (ruleIndex !== -1) {
                const newValues = [...flowValues.rules];
                newValues.splice(ruleIndex, 1, oldRule);

                onFlowValuesChange(newValues, 'rules');

                setActiveRule(null);
                setOldRule(null);
            }
        };

        if (!compare(selectedRule, oldRule)) {
            const onClose = () => {
                hideModal();
                setIsCriteriaWarnigModalOpened(false);
            };

            showModal({
                title: txt.titles.closeWithoutSave(txt.labels.rule),
                onClose,
                body: props => (
                    <MessageModal
                        {...props}
                        message={txt.texts.warningModal(txt.labels.rule)}
                        confirmButtonText={txt.buttons.yesCloseForm}
                        rejectButtonText={txt.buttons.cancel}
                        onConfirm={() => {
                            returnToDefaultSettings();
                            onClose();
                            if (callback) callback();
                        }}
                        onClose={onClose}
                        confirmButtonType="danger"
                    />
                )
            });

            setIsCriteriaWarnigModalOpened(true);
        } else {
            setActiveRule(null);
            setOldRule(null);
            if (callback) callback();
        }
    };

    const onRuleClick = ({ rule, activeTabId }) => {
        if (oldRule) {
            if (oldRule.value !== rule.value) {
                handleCriteriaDialogClose(() => {
                    setActiveRule({ rule, activeTabId });
                    setOldRule(clone(rule));
                });
            } else {
                setActiveRule({ rule, activeTabId });
            }
        } else {
            setActiveRule({ rule, activeTabId });
            setOldRule(clone(rule));
        }
    };

    const criteriaDialogRef = useNewClickOutside(() => {
        if (activeRule && !isCriteriaWarnigModalOpened) {
            handleCriteriaDialogClose();
        }
    });

    const onWorkspaceChange = (target, value) => {
        const isDefaultPathsContainsLandersOrOffers = flowValues.defaultRule.paths.some(
            el => el.landers.length > 0 || el.offers.length > 0
        );

        const isRulesContainsLandersOrOffers = flowValues.rules.some(rule =>
            rule.paths.some(el => el.landers.length > 0 || el.offers.length > 0)
        );

        const isNeedReset = (() => {
            if (target === 'campaigns') {
                return (
                    mainOptionValues.trafficSource ||
                    flowValues.flowValue !== 'custom' ||
                    isDefaultPathsContainsLandersOrOffers ||
                    isRulesContainsLandersOrOffers
                );
            }

            if (target === 'flows') {
                return (
                    mainOptionValues.campaignsToAdd.length ||
                    isDefaultPathsContainsLandersOrOffers ||
                    isRulesContainsLandersOrOffers
                );
            }

            return false;
        })();

        if (isNeedReset) {
            const onConfirm = () => {
                if (target === 'campaigns') {
                    onMainOptionValuesChange(null, [
                        {
                            prop: 'workspaceId',
                            value
                        },
                        {
                            prop: 'trafficSource',
                            value: undefined
                        },
                        {
                            prop: 'trafficSourceCampaignId',
                            value: null
                        }
                    ]);
                }

                if (target === 'flows') {
                    onMainOptionValuesChange(null, [
                        {
                            prop: 'workspaceId',
                            value
                        },
                        {
                            prop: 'campaignsToAdd',
                            value: []
                        }
                    ]);
                }

                onFlowValuesChange(null, [
                    {
                        prop: 'defaultRule',
                        value: {
                            ...flowValues.defaultRule,
                            paths: flowValues.defaultRule.paths.map(el => ({ ...el, landers: [], offers: [] }))
                        }
                    },
                    {
                        prop: 'rules',
                        value: flowValues.rules.map(rule => ({
                            ...rule,
                            paths: rule.paths.map(it => ({ ...it, landers: [], offers: [] }))
                        }))
                    }
                ]);

                hideModal();
            };

            showModal({
                title: 'Warning',
                body: props => <ChangeWorkspaceWarningModal {...props} onConfirm={onConfirm} target={target} />
            });
        } else onMainOptionValuesChange(value, 'workspaceId');
    };

    return (
        <>
            <MainOptionComponent
                className={activeRule ? 'campaign-main-option_content-overflow-auto' : null}
                values={mainOptionValues}
                campaignsInFlow={campaignsInFlow}
                onChange={onMainOptionValuesChange}
                isNeedValidate={isTouched}
                mode={status?.mode}
                trackingMethod={trackingMethod}
                trackingDomains={trackingDomains}
                trafficSourceData={trafficSourceData}
                campaignCostEqualStatus={campaignCostEqualStatus}
                setCampaignCostEqualStatus={setCampaignCostEqualStatus}
                campaignCostInitialEqual={campaignCostInitialEqual}
                setCampaignCostInitialEqual={setCampaignCostInitialEqual}
                onWorkspaceChange={onWorkspaceChange}
                flowsList={flowsList}
                trafficSourcesList={trafficSourcesList}
                workspacesList={workspacesList}
                publicWorkspaceId={publicWorkspaceId}
                entityId={status.value}
                countries={countries}
                storage={storage}
            >
                {activeRule && (
                    <CriteriaDialog
                        value={{ rule: selectedRule, activeTabId: activeRule.activeTabId }}
                        ref={criteriaDialogRef}
                        onChange={onRuleByIdChange}
                        onClose={() => handleCriteriaDialogClose()}
                        onApply={() => {
                            setActiveRule(null);
                            if (oldRule) setOldRule(null);
                        }}
                    />
                )}
            </MainOptionComponent>
            <FlowRules
                values={flowValues}
                rules={rulesWithCriteria}
                onChange={onFlowValuesChange}
                trackingMethod={trackingMethod}
                isDirectTracking={trackingMethod?.value === TRACKING_METHOD.DIRECT}
                setTrackingMethod={newTrackingMethod => {
                    updateMainOptionValues('links', []);
                    updateFlowValues(
                        [
                            { prop: 'originalSettingsAt', value: flowValues?.originalSettingsAt },
                            { prop: 'trackingMethod', value: newTrackingMethod }
                        ],
                        null,
                        newTrackingMethod
                    );
                    setTrackingMethod(newTrackingMethod);
                }}
                trafficSourceValue={mainOptionValues?.trafficSource}
                type={entity}
                mode={status.mode}
                flowsList={flowsList}
                flowValue={flowValues?.flowValue || 'custom'}
                workspaceId={mainOptionValues?.workspaceId}
                publicWorkspaceId={publicWorkspaceId}
                originalSettingsAt={flowValues?.originalSettingsAt}
                handleFlowChange={onFlowChange}
                isLocalLoading={isProcessing}
                setIsLocalLoading={setIsProcessing}
                handleLoadOriginalSettings={onLoadOriginalSettingsClick}
                activeRule={activeRule}
                onRuleClick={onRuleClick}
                isFlowRuleProcessing={selectedFlowFetching}
                campaignsCount={mainOptionValues?.campaigns?.length}
                campaignsToAddCount={mainOptionValues?.campaignsToAdd?.length}
                currentCampaignInfo={{ id: status.value, name: mainOptionValues.name }}
                referrerDefaultHideType={mainOptionValues.referrerDefaultHideType}
                landersList={landersList}
                offersList={offersList}
                affiliateNetworksList={affiliateNetworksList}
                trafficSourcesList={trafficSourcesList}
                countries={countries}
                currencies={currencies}
            />
        </>
    );
};

export default NewFlowCampaign;
