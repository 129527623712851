import React from 'react';
import useToggle from '../../../../Functions/hooks/useToggle';
import Langs from '../../../../Langs';
import Btn from '../../UI/Btn/Btn';
import Switcher from '../../UI/Switcher/Switcher';
import SwitcherIsNot from '../../UI/SwitcherIsNot/SwitcherIsNot';
import CheckBoxInput from '../Inputs/CheckBoxInput';
import TextInput from '../Inputs/TextInput';

import './form-actions.scss';

const FormActions = ({
    textFieldValue,
    onClear,
    handleListChange,
    handleEditConfirm,
    onTextFieldEdit,
    children,
    isEnabled,
    label,
    isMatched,
    className,
    onChange,
    placeholder,
    isIncludedEmpty,
    isNotText,
    inputLabel
}) => {
    const txt = Langs[global.lng];
    const { isToggled: isFormEdit, toggleOn: setIsFormEdit, toggleOff: unsetIsFormEdit } = useToggle();

    const onListEdit = () => {
        setIsFormEdit();
        handleListChange();
    };

    const onEditConfirm = () => {
        unsetIsFormEdit();
        handleEditConfirm();
    };

    return (
        <Switcher
            dataTest={`form-actions_${label}`}
            label={label}
            isSwitchOn={isEnabled}
            onChange={e => onChange(e, 'isEnabled')}
            className={className}
        >
            <div className="form-actions">
                <div className="form-actions__control-group">
                    <div className="j4">
                        <SwitcherIsNot
                            dataTest={`form-actions_${label}`}
                            item={{ value: isMatched, label }}
                            onChange={e => onChange(e, 'isMatched')}
                            isNotText={isNotText}
                        />
                        {isIncludedEmpty !== undefined && (
                            <CheckBoxInput
                                dataTest="form-actions_include-empty-referrer-checkbox"
                                className="ml15"
                                label={inputLabel}
                                onChange={({ target: { checked } }) => onChange(checked, 'isIncludedEmpty')}
                                value={isIncludedEmpty}
                            />
                        )}
                    </div>
                    <div className="form-actions__button-group">
                        {handleListChange &&
                            (isFormEdit ? (
                                <>
                                    <Btn
                                        dataTest={`form-actions_${label}_close-btn`}
                                        type="link"
                                        onClick={unsetIsFormEdit}
                                    >
                                        {txt.buttons.close}
                                    </Btn>
                                    <Btn
                                        dataTest={`form-actions_${label}_apply-btn`}
                                        type="filled"
                                        onClick={onEditConfirm}
                                    >
                                        {txt.buttons.apply}
                                    </Btn>
                                </>
                            ) : (
                                <Btn dataTest={`form-actions_${label}_list-view-btn`} type="link" onClick={onListEdit}>
                                    {txt.buttons.listView}
                                </Btn>
                            ))}
                        {onClear && (
                            <Btn dataTest={`form-actions_${label}_clear-btn`} type="link" onClick={onClear}>
                                {txt.buttons.clear}
                            </Btn>
                        )}
                    </div>
                </div>
            </div>
            {isFormEdit || (!children && textFieldValue !== undefined) ? (
                <TextInput
                    dataTest={`form-actions_${label}-textarea`}
                    type="textarea"
                    className="form-actions__edit-field"
                    value={textFieldValue}
                    onChange={onTextFieldEdit}
                    onBlur={handleListChange ? undefined : handleEditConfirm}
                    placeholder={placeholder || 'enterValue'}
                />
            ) : (
                children
            )}
        </Switcher>
    );
};

export default FormActions;
