import React from 'react';
import cn from 'classnames';

import TopIcon from './top.svg';
import BottomIcon from './bottom.svg';

import './position-picker.scss';

const PositionPicker = ({ value = 0, onChange }) => {
    return (
        <div className="position-picker">
            <div className="position-picker__title">Position</div>
            <img
                className={cn('position-picker__btn', { 'position-picker__btn--active': value === 1 })}
                onClick={() => onChange(1)}
                src={BottomIcon}
            />
            <img
                className={cn('position-picker__btn', { 'position-picker__btn--active': value === 0 })}
                onClick={() => onChange(0)}
                src={TopIcon}
            />
        </div>
    );
};

export default PositionPicker;
