import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import Langs from '../../../Langs';
import { getMoneyIntlFormattedValue } from '../../../redux/operations';
import { getResponseErrorMessage } from '../../../Functions/utils';

import { useGetPlansQuery } from '../../../redux/api/plansApi';
import { useGetCurrenciesQuery } from '../../../redux/api/systemApi';
import { useChangeProjectPlanMutation, useGetProjectPlanTransitionInfoQuery } from '../../../redux/api/projectApi';

import Btn, { CloseBtn } from '../../Overall/UI/Btn/Btn';
import Plan from '../choose-plan-modal/components/plan/plan';
import Loader from '../../Loader';
import Alert from '../../Overall/UI/Alert/Alert';
import AddBillingInfoModal from '../add-billing-info-modal/add-billing-info-modal';

const DowngradePlanModal = ({ planId, onClose, onBackToPlans, isEmptyInvoiceDetails }) => {
    const txt = Langs[global.lng];

    const { data: currencies, isSuccess: isCurrenciesLoaded } = useGetCurrenciesQuery();
    const { data: planList, isSuccess: isPlanListLoaded } = useGetPlansQuery();
    const { data: transition, isSuccess: isTransitionLoaded } = useGetProjectPlanTransitionInfoQuery(planId);

    const [isEditBilling, setIsEditBilling] = useState(isEmptyInvoiceDetails);

    const [
        changePlan,
        {
            isLoading: isChangePlanLoading,
            isSuccess: isChangePlanSuccess,
            isError: isChangePlanError,
            error: changePlanError
        }
    ] = useChangeProjectPlanMutation();

    useEffect(() => {
        if (isChangePlanSuccess) {
            toast.success(txt.toasts.planDowngrade);
            onClose(null, true);
        }
    }, [isChangePlanSuccess]);

    useEffect(() => {
        if (isChangePlanError) toast.error(getResponseErrorMessage(changePlanError.data, txt.toasts.saveError));
    }, [isChangePlanError]);

    const confirmInvoiceDetails = isOk => {
        if (isOk) setIsEditBilling(false);
    };

    const isLoaded = isCurrenciesLoaded && isPlanListLoaded && isTransitionLoaded;

    return (
        <div className="chage-plan-modal chage-plan-modal__inner">
            <div className="chage-plan-modal__header">
                <span className="chage-plan-modal__modal-title">{txt.labels.downgradePlan}</span>
                <CloseBtn className="modal2__btn-close" onClick={onClose} />
            </div>

            <div className="chage-plan-modal__body chage-plan-modal__body_next-step">
                {!isLoaded ? (
                    <Loader />
                ) : (
                    <>
                        <div className="chage-plan-modal__selectable-plan">
                            <Plan
                                plan={planList.find(el => el.id === planId)}
                                buttonText={txt.buttons.backToPlans}
                                onClick={onBackToPlans}
                            />
                        </div>

                        <div className="chage-plan-modal__details-container chage-plan-modal__details-container_space-between">
                            {isEditBilling ? (
                                <AddBillingInfoModal
                                    className="chage-plan-modal__billing-info"
                                    isIntegrated
                                    handleConfirmation={confirmInvoiceDetails}
                                />
                            ) : (
                                <>
                                    <div className="chage-plan-modal__description-block">
                                        <div className="chage-plan-modal__description-block-plan chage-plan-modal__description-block-plan__margin-more">
                                            <div>
                                                <span>{transition.nextPlan.name}</span>
                                                <span>
                                                    {getMoneyIntlFormattedValue(
                                                        transition.nextPlan.monthlyPayment,
                                                        transition.nextPlan.paymentCurrency,
                                                        currencies
                                                    )}
                                                </span>
                                            </div>
                                            <span>
                                                {`${txt.texts.youWillPay} ${moment(
                                                    transition.transition.nextBillingDate
                                                ).format('ll')}`}
                                            </span>
                                        </div>

                                        <Alert type="warning" message={txt.alerts.planWillBeApplied} />
                                    </div>

                                    <div className="chage-plan-modal__buttons-block">
                                        <Btn
                                            onClick={() => setIsEditBilling(true)}
                                            type="link"
                                            className="chage-plan-modal__edit-billing-info-button"
                                        >
                                            {txt.buttons.editBillingInfo}
                                        </Btn>

                                        <Btn
                                            onClick={() => changePlan({ id: transition.nextPlan.id })}
                                            type="filled"
                                            className="chage-plan-modal__next-button"
                                        >
                                            {txt.buttons.scheduleDowngrade}
                                        </Btn>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}

                {isChangePlanLoading && <Loader isFetching />}
            </div>
        </div>
    );
};

export default DowngradePlanModal;
