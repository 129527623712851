import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { getColumns } from '../../components/main-table/services';
import { getParams, clone, compare } from '../../Functions/utils';
import { getActualDateRange } from '../../redux/operations';
import { exportDateTransformer } from '../../redux/transformers';

import { useChangeStorageMutation } from '../../redux/api/storeApi';
import { useGetErrorLogCategoriesQuery, useGetErrorLogQuery } from '../../redux/api/errorLogApi';

import {
    changeMainTablePagination,
    changeMainTableSorting,
    selectErrorLogTableData
} from '../../redux/main-table-slice';

import TableControls from './components/table-controls/table-controls';
import MainTableItems from '../../Modules/MainTable/MainTableItems/MainTableItems';

import './screen-error-log.scss';

const ScreenErrorLog = ({ chapter, storage, className }) => {
    const dispatch = useDispatch();

    const tableParameters = useSelector(selectErrorLogTableData);
    const { search, filters, sorting, pagination } = tableParameters;

    const dateRange = getActualDateRange(storage.common.dateRange);

    const parameters = {
        'filter[url]': search,
        [`order-by[${sorting.key}]`]: sorting.type,
        page: pagination.page,
        'per-page': pagination['per-page'],
        [`filter[dateFrom]`]: exportDateTransformer(dateRange.start),
        [`filter[dateTo]`]: exportDateTransformer(dateRange.end)
    };

    Object.entries(filters).forEach(([key, value]) => {
        if (value !== 'all') parameters[`filter[${key}]`] = value;
    });

    const {
        data: errorLogList,
        isSuccess: isErrorLogListLoaded,
        isFetching: isErrorLogListFetching
    } = useGetErrorLogQuery(getParams(parameters));

    useEffect(() => {
        if (!isErrorLogListLoaded) return;

        if (errorLogList?.pagination) {
            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: errorLogList.pagination
                })
            );
        }
    }, [errorLogList]);

    const { data: errorLogCategories, isSuccess: isErrorLogCategoriesLoaded } = useGetErrorLogCategoriesQuery();

    const [changeStorage] = useChangeStorageMutation();

    const onTableActionsChange = (data, prop) => {
        switch (prop) {
            case 'sorting':
                dispatch(
                    changeMainTableSorting({
                        chapter,
                        value: data
                    })
                );

                dispatch(
                    changeMainTablePagination({
                        chapter,
                        value: { ...pagination, page: 1 }
                    })
                );

                break;

            case 'columnWidths': {
                const newStorage = clone(storage);
                newStorage.errorLog.columnWidths = data;
                changeStorage(newStorage);

                break;
            }

            default:
                break;
        }
    };

    const updateDateRange = newDateRange => {
        if (!compare(storage.common.dateRange, newDateRange)) {
            const newStorage = clone(storage);

            newStorage.common.dateRange = newDateRange;
            changeStorage(newStorage);

            dispatch(
                changeMainTablePagination({
                    chapter,
                    value: { ...pagination, page: 1 }
                })
            );
        }
    };

    const columns = useMemo(() => getColumns(chapter, storage).orderedColumns, [chapter, storage]);

    const rows = useMemo(() => {
        if (isErrorLogListLoaded && isErrorLogCategoriesLoaded) {
            return errorLogList?.items.map(el => ({
                ...el,
                category: errorLogCategories?.[el.category] ?? el.category
            }));
        }

        return [];
    }, [errorLogList, errorLogCategories]);

    const isLoaded = isErrorLogListLoaded && !isErrorLogListFetching && isErrorLogCategoriesLoaded;

    return (
        <>
            <div className={cn('table-container screen-error-log', className)}>
                <div className="main-table">
                    <div className="main-table__wrapper compact-main-table compact-main-table--important-single-cell">
                        <TableControls
                            chapter={chapter}
                            isLoading={!isLoaded}
                            pagination={pagination}
                            errorLogCategories={errorLogCategories}
                            tableParameters={tableParameters}
                            filters={filters}
                            dateRange={dateRange}
                            setDateRange={updateDateRange}
                            rows={rows}
                            columns={columns}
                        />

                        <div className="main-table__visible-content">
                            <MainTableItems
                                chapter={chapter}
                                modifiedChapter={chapter}
                                columns={columns}
                                rows={rows}
                                sorting={sorting}
                                storage={storage}
                                isLoading={!isLoaded}
                                onRowClick={() => {}}
                                onRowContextMenu={() => {}}
                                handleChange={onTableActionsChange}
                                onChange={() => {}}
                                errorLogCategories={errorLogCategories}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScreenErrorLog;
