import React from 'react';
import { RefreshCw } from 'react-feather';
import { useDispatch } from 'react-redux';

import Langs from '../../../../../Langs';

import { changeMainTableFilters, changeMainTablePagination } from '../../../../../redux/main-table-slice';

import indexApi from '../../../../../redux/api/indexApi';
import { eventLogApiTagTypes } from '../../../../../redux/api/eventLogApi';

import TableControlContainer from '../../../../../components/table-control-container/table-control-container';
import Btn from '../../../../Overall/UI/Btn/Btn';
import SelectInput from '../../../../Overall/Forms/Inputs/SelectInput/SelectInput';
import TablePagination from '../../../../Overall/UI/TablePagination/TablePagination';
import DateRangeInput from '../../../../Overall/Forms/Inputs/DateRangeInput';

const TableControls = ({ chapter, isLoading, filters, pagination, usersList, dateRange, setDateRange }) => {
    const txt = Langs[global.lng];

    const dispatch = useDispatch();

    const contentSwitcher = 'eventLog';

    const onFilterChange = (value, key) => {
        dispatch(
            changeMainTableFilters({
                chapter,
                filterKey: key,
                value
            })
        );

        dispatch(
            changeMainTablePagination({
                chapter,
                value: { ...pagination, page: 1 }
            })
        );
    };

    return (
        <TableControlContainer>
            <div className="table-control-container__filters">
                <SelectInput
                    dataTest={`table-control_actions-select-input_${contentSwitcher}`}
                    value={filters.userId ?? 'all'}
                    items={[{ value: 'all', label: 'All users' }, ...usersList]}
                    onChange={({ target: { value } }) => onFilterChange(value, 'userId')}
                    className="event-log__filter-item"
                    disabled={isLoading}
                />

                <SelectInput
                    dataTest="table-control_actions-select-input_events"
                    value={filters.actionType ?? 'all'}
                    items={[
                        { value: 'all', label: 'All actions' },
                        { value: 'created', label: 'Created' },
                        { value: 'updated', label: 'Updated' },
                        { value: 'archived', label: 'Archived' },
                        { value: 'restored', label: 'Restored' },
                        { value: 'login', label: 'Login' }
                    ]}
                    onChange={({ target: { value } }) => onFilterChange(value, 'actionType')}
                    className="event-log__filter-item"
                    disabled={isLoading}
                />

                <SelectInput
                    dataTest="table-control_entities-select-input_events"
                    value={filters.entityType ?? 'all'}
                    items={[
                        {
                            value: 'all',
                            label: 'All entities'
                        },
                        {
                            value: 'Campaign',
                            label: 'Campaigns'
                        },
                        {
                            value: 'Flow',
                            label: 'Flows'
                        },
                        {
                            value: 'Lander',
                            label: 'Landers'
                        },
                        {
                            value: 'Offer',
                            label: 'Offers'
                        },
                        {
                            value: 'TrafficSource',
                            label: 'Traffic Sources'
                        },
                        {
                            value: 'AffiliateNetwork',
                            label: 'Affiliate Networks'
                        },
                        {
                            value: 'Account',
                            label: 'Account'
                        }
                    ]}
                    onChange={({ target: { value } }) => onFilterChange(value, 'entityType')}
                    className="event-log__filter-item"
                    disabled={isLoading}
                />

                <DateRangeInput
                    value={dateRange}
                    onChange={data => setDateRange(data)}
                    isCompact
                    disabled={isLoading}
                />
            </div>

            <Btn
                dataTest={`table-control_refresh-btn_${contentSwitcher}`}
                type="filled"
                disabled={isLoading}
                className="ml8"
                onClick={() =>
                    dispatch(indexApi.util.invalidateTags([{ type: eventLogApiTagTypes.eventLog, id: 'LIST' }]))
                }
            >
                <RefreshCw />
                {txt.buttons.refresh}
            </Btn>

            <div className="table-control-container__pagination">
                <TablePagination
                    value={pagination}
                    onChange={data => {
                        dispatch(
                            changeMainTablePagination({
                                chapter,
                                value: data
                            })
                        );
                    }}
                    disabled={isLoading}
                />
            </div>
        </TableControlContainer>
    );
};

export default TableControls;
