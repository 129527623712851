import React from 'react';
import cn from 'classnames';

import './page-content.scss';

const PageContent = ({ children, className }) => {
    return <div className={cn('page-content', className)}>{children}</div>;
};

export default PageContent;
